import React from 'react';
import * as Normalizers from 'lib/normalizers';

import { TextField, TextFieldProps } from './text-input.component';

const NumberField = ({
    normalize,
    inputMode = 'numeric',
    ...rest
}: TextFieldProps) => (
    <TextField
        normalize={normalize || Normalizers.numberField}
        inputMode={inputMode}
        {...rest}
    />
);

export { NumberField };
