import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Flex } from 'reflexbox/styled-components';
import { useIntl } from 'react-intl';

import { Typograph, TypographI18nKeys } from 'components/typograph';
import { PageContainer } from 'components/page-container';
import styled from 'styled-components/macro';

import { withSizesHoc as WithSizes, WithSizesProps } from 'hocs/with-sizes.hoc';
import { log } from 'utils/logging';

type StateProps = {
    lang: string;
    path: string;
};

type Links = Array<{
    url?: string;
    localizedUrl?: string;
    newPage: boolean;
    name: TypographI18nKeys;
}>;

const LINKS: Links = [
    {
        name: 'pagenotfound.newMortgage',
        url: '/getaquote/newmortgage/start',
        newPage: false
    },
    {
        name: 'pagenotfound.renewMortgage',
        url: '/getaquote/renewal/start',
        newPage: false
    },
    {
        name: 'pagenotfound.aboutNesto',
        localizedUrl: 'pagenotfound.aboutNesto.link',
        newPage: false
    },
    {
        name: 'pagenotfound.learnAboutMortgage',
        localizedUrl: 'pagenotfound.learnAboutMortgage.link',
        newPage: false
    },
    {
        name: 'userMenu.career',
        localizedUrl: 'pagenotfound.career.link',
        newPage: false
    },
    {
        name: 'getAQuote.termsOfService',
        localizedUrl: 'pagenotfound.termsOfService.link',
        newPage: false
    }
];

const Row = styled(Flex)`
    border-bottom: 1px solid ${props => props.theme.colors.mercury};
`;

const PageNotFoundView = ({ path }: WithSizesProps & StateProps) => {
    const intl = useIntl();

    useEffect(() => {
        log({
            error: '404 page rendered',
            tag: 'Not Found',
            data: {
                path
            }
        });
    }, [path]);
    return (
        <PageContainer flexDirection="column" alignItems="center">
            <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="flex-end"
                mt={[5]}
            >
                <Box width={[1, 2 / 3]}>
                    <Typograph
                        fontSize={[6]}
                        fontWeight="bold"
                        mb={[4]}
                        tx="pagenotfound.oopsTitle"
                    />
                    <Typograph fontSize={[5]} tx="pagenotfound.text" />
                </Box>
                <Flex flexDirection="column" width={[1, 1 / 3]}>
                    {LINKS.map(link => (
                        <a
                            href={
                                link.url ??
                                intl.formatMessage({ id: link.localizedUrl })
                            }
                            target={link.newPage ? '_blank' : undefined}
                            rel="noreferrer"
                            data-test-id={link.name}
                            key={link.name}
                        >
                            <Row
                                justifyContent="space-between"
                                flexDirection="row"
                                alignItems="left"
                                pt={10}
                                pb={10}
                            >
                                <Flex
                                    flexDirection="column"
                                    alignItems="right"
                                    pl={[2, 0]}
                                >
                                    <Flex flexDirection="row">
                                        <Typograph
                                            fontSize={[3]}
                                            color="textBlack"
                                            tx={link.name}
                                        />
                                    </Flex>
                                </Flex>
                            </Row>
                        </a>
                    ))}
                </Flex>
            </Flex>
        </PageContainer>
    );
};

export const PageNotFound = compose(
    connect<StateProps>((state: any) => ({
        lang: state.locale.lang,
        path: state.router.location.pathname
    })),
    WithSizes
)(PageNotFoundView) as React.FC<{}>;
