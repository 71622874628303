import React from 'react';
import styled from 'styled-components/macro';
import { Typograph, TypographI18nKeys } from 'components/typograph';

export const Error = styled(Typograph)`
    padding-right: 16px;
    padding-left: 16px;
    margin-top: 6px;
    font-size: ${({ theme }) => theme.fontSizes[0]};
    font-style: italic;
    color: ${props => props.theme.input.error.color};
`;

type Props = {
    meta?: {
        touched: boolean;
        error?: TypographI18nKeys;
    };
    name?: string;
    errorValues?: any;
    children?: React.ReactChild;
    showErrorOnTouched?: boolean;
};

const showError = (meta, showErrorOnTouched = false): boolean => {
    const { touched, error } = meta;
    if (error === 'validation.errors.isRequired' && !touched) return false;

    if (error === 'validation.errors.monthOrYearRequired' && !touched)
        return false;

    if (error === 'application.refinanceMortgageInvalidValue' && !touched)
        return false;
    if (error === 'validation.errors.invalidDate' && !touched) return false;
    if (error === 'getAQuote.refinanceFundsErrorExceed' && !touched)
        return false;

    if (showErrorOnTouched && !touched) {
        return false;
    }

    return !!error;
};

export const InputError = ({
    children,
    meta,
    errorValues = {},
    name = 'message',
    showErrorOnTouched = false
}: Props) => (
    <>
        {meta && showError(meta, showErrorOnTouched) && (
            <Error
                tx={meta.error}
                values={errorValues}
                data-test-id={`form-error-${name}`}
            >
                {children}
            </Error>
        )}
    </>
);
