import { takeLatest, all, put, debounce, takeEvery } from 'redux-saga/effects';

import { LOCATION_CHANGE_TYPE } from 'constants/actionConstants';

import { errorNotification } from 'reducers/notifications.redux';
import { log } from 'utils/logging';
import { StartupTypes } from 'reducers/startup.redux';

// NOTE: Split this into multiple files later.
/* RATES */
import { RatesTypes } from 'reducers/rates.redux';
import * as RatesSagas from 'sagas/rates.sagas';

/* Mortgage Wizards */
import { WizardTypes } from 'reducers/mortgage-wizards.redux';
import * as WizardSagas from 'sagas/mortgage-wizard.sagas';

/* Auth */
import * as AuthSagas from 'sagas/auth.sagas';

/* Session */
import { SessionTypes } from 'reducers/session.redux';
import * as SessionSagas from 'sagas/session.sagas';

/* Account */
import { AccountTypes } from 'reducers/account.redux';
import * as AccountSagas from 'sagas/account.sagas';

/* analytics */
import * as AnalyticsSagas from 'sagas/analytics.sagas';
import * as AnalyticsEventsSagas from 'sagas/analytics-events.sagas';

/* dashboard */
import { DashboardTypes } from 'reducers/dashboard.redux';
import * as DashboardSagas from 'sagas/dashboard.sagas';

/* application */
import { ApplicationsTypes } from 'reducers/application.redux';
import { Types as ApplicationsStateTypes } from 'reducers/applications-state.redux';
import * as ApplicationSagas from 'sagas/application.sagas';

/* Documents */
import { DocumentsTypes } from 'reducers/documents.redux';
import * as DocumentsSagas from 'sagas/documents.sagas';

/* locale */
/* dashboard */
import { LocaleTypes } from 'reducers/locale.redux';
import * as LocaleSagas from 'sagas/locale.sagas';

import { startup } from 'sagas/startup.sagas';

/* UI */
import { UITypes } from 'reducers/ui.redux';
import * as UISagas from 'sagas/ui.sagas';

import { Types as ProductSelectionTypes } from 'reducers/product-selection.redux';
import * as ProductSelection from 'sagas/product-selection.sagas';

import { Types as ClosingDocumentsTypes } from 'reducers/closing-documents.redux';
import * as ClosingDocuments from 'sagas/closing-documents.sagas';

export const sagas = function*() {
    try {
        yield all([
            debounce(
                500,
                RatesTypes.FETCH_QUOTES_MATRIX_REQUEST,
                RatesSagas.onFetchQuotesMatrixRequest
            ),
            takeLatest(
                RatesTypes.FETCH_QUOTES_MATRIX_FAILURE,
                RatesSagas.selectRateFailure
            ),
            takeLatest(
                WizardTypes.SUBMIT_NEW_ANONYMOUS,
                WizardSagas.onSubmitNewAnonymous
            ),
            takeLatest(
                WizardTypes.SUBMIT_RENEWAL_ANONYMOUS,
                WizardSagas.onSubmitRenewalAnonymous
            ),
            takeLatest(
                WizardTypes.SUBMIT_REFINANCE_ANONYMOUS,
                WizardSagas.onSubmitRefinanceAnonymous
            ),
            takeLatest(WizardTypes.SUBMIT_NEW, WizardSagas.onSubmitNew),
            takeLatest(WizardTypes.SUBMIT_RENEWAL, WizardSagas.onSubmitRenewal),
            takeLatest(
                WizardTypes.SUBMIT_REFINANCE,
                WizardSagas.onSubmitRefinance
            ),
            takeLatest(
                WizardTypes.CRUNCHING_COMPLETED,
                WizardSagas.crunchingCompleted
            ),
            takeLatest(StartupTypes.STARTUP, startup),
            takeLatest(LocaleTypes.CHANGE_LANGUAGE, LocaleSagas.changeLanguage),
            takeLatest(
                AccountTypes.CREATE_ACCOUNT_REQUEST,
                AccountSagas.onCreateAccount
            ),
            takeLatest(AccountTypes.LOGIN_REQUEST, AccountSagas.onLoginRequest),
            takeLatest(
                AccountTypes.UPDATE_USER_INFORMATION,
                AccountSagas.updateUserInformation
            ),
            takeLatest(
                AccountTypes.UPDATE_USER_POSTAL_CODE,
                AccountSagas.updateUserPostalCode
            ),
            takeLatest(
                AccountTypes.GET_BROKER_ACCOUNT_REQUEST,
                AccountSagas.getBrokerAccountRequest
            ),
            takeLatest(
                AccountTypes.BROKER_LOGIN_REQUEST,
                AccountSagas.onBrokerLoginRequest
            ),
            debounce(
                1000,
                AccountTypes.RESET_ANALYTICS_FOR_BROKER,
                AccountSagas.resetAnalyticsForBroker
            ),
            takeLatest(
                AccountTypes.BEHALF_LOGIN_REQUEST,
                AccountSagas.onBehalfLoginRequest
            ),
            takeLatest(
                AccountTypes.BEHALF_LOGOUT_REQUEST,
                AccountSagas.onBehalfLogoutRequest
            ),
            takeLatest(
                AccountTypes.REFRESH_TOKEN_SUCCESS,
                AuthSagas.setAuthToken
            ),
            takeLatest(AccountTypes.LOGOUT, AuthSagas.onLogout),
            takeLatest(
                SessionTypes.SET_ACTIVE_APPLICATION_ID,
                SessionSagas.onSetActiveApplicationId
            ),
            takeLatest(
                SessionTypes.SET_ACTIVE_APPLICATION_ID,
                ApplicationSagas.onSetActiveApplicationId
            ),
            takeLatest(
                AccountTypes.PASSWORD_RESET_REQUEST,
                AccountSagas.onPasswordResetRequest
            ),
            takeLatest(
                AccountTypes.PASSWORD_CHANGE_REQUEST,
                AccountSagas.onPasswordChangeRequest
            ),
            takeLatest(
                AccountTypes.ACTIVATE_ACCOUNT_REQUEST,
                AccountSagas.onActivateAccountRequest
            ),
            takeLatest(
                AccountTypes.CREATE_ACCOUNT_SUCCESS,
                AccountSagas.onCreateOrGetAccountSuccess
            ),
            takeLatest(
                AccountTypes.GET_ACCOUNT_SUCCESS,
                AccountSagas.onCreateOrGetAccountSuccess
            ),
            takeLatest(
                AccountTypes.LOGIN_SUCCESS,
                AccountSagas.onCreateOrGetAccountSuccess
            ),
            takeLatest(
                AccountTypes.REFRESH_TOKEN_SUCCESS,
                AccountSagas.onCreateOrGetAccountSuccess
            ),
            takeLatest(
                AccountTypes.GET_ACCOUNT_TRACKING,
                AccountSagas.onGetAccountTracking
            ),
            takeEvery(
                AccountTypes.ACCOUNT_TRACKING,
                AccountSagas.onAccountTracking
            ),
            takeEvery(
                AccountTypes.ADD_CALL_PREFERENCES,
                AccountSagas.onAddCallPreferences
            ),
            takeLatest(
                AccountTypes.GET_COMMUNICATION_PREFERENCES,
                AccountSagas.onGetCommunicationPreferences
            ),
            takeLatest(
                AccountTypes.UPDATE_COMMUNICATION_PREFERENCES,
                AccountSagas.onUpdateCommunicationPreferences
            ),
            takeLatest(
                AccountTypes.GET_ACCOUNT_DELETION_STATE,
                AccountSagas.onGetAccountDeletionState
            ),
            takeLatest(
                WizardTypes.ANALYTICS_QUESTION_VIEWED,
                AnalyticsSagas.onWizardFormViewed
            ),
            takeLatest(
                WizardTypes.ANALYTICS_QUESTION_ANSWERED,
                AnalyticsSagas.onWizardFormAnswered
            ),
            takeLatest(
                AnalyticsSagas.AnalyticTypes.TRACK_LOGIN_SUCCESS,
                AnalyticsSagas.trackLoginSuccess
            ),
            takeLatest(
                AnalyticsSagas.AnalyticTypes.TRACK_VALID_PHONE_NUMBER,
                AnalyticsSagas.trackValidPhoneNumber
            ),
            takeLatest(
                DashboardTypes.FETCH_DASHBOARD_REQUEST,
                DashboardSagas.onFetchDashboardRequest
            ),
            takeLatest(
                ApplicationsTypes.CREATE_APPLICATIONS_REQUEST,
                ApplicationSagas.createApplication
            ),
            takeLatest(
                ApplicationsTypes.FETCH_APPLICATIONS_REQUEST,
                ApplicationSagas.onFetchApplicationsRequest
            ),
            takeLatest(
                ApplicationsTypes.FETCH_APPLICATIONS_SUCCESS,
                ApplicationSagas.onFetchApplicationsSuccess
            ),
            takeLatest(
                ApplicationsTypes.FETCH_APPLICATION_SUMMARY,
                ApplicationSagas.onFetchApplicationSummary
            ),
            takeLatest(
                ApplicationsTypes.FETCH_APPLICATION_ADVISOR,
                ApplicationSagas.onFetchApplicationAdvisor
            ),
            takeLatest(
                ApplicationsTypes.SUBMIT_APPLICANT_INFORMATION,
                ApplicationSagas.submitApplicantInformation
            ),
            takeLatest(
                ApplicationsTypes.SUBMIT_APPLICATION_FILOGIX,
                ApplicationSagas.submitApplicationFilogix
            ),
            takeLatest(
                ApplicationsTypes.SAVE_REGISTERED_ADDRESS,
                ApplicationSagas.saveRegisteredAddress
            ),
            takeLatest(
                ApplicationsTypes.DELETE_REGISTERED_ADDRESS,
                ApplicationSagas.deleteRegisteredAddress
            ),
            takeLatest(
                ApplicationsTypes.SEARCH_REALTOR_ACCOUNTS,
                ApplicationSagas.getAccountsByRealtorRole
            ),
            takeLatest(
                ApplicationsTypes.SUBMIT_APPLICANT_OTHER_INCOME,
                ApplicationSagas.updateApplicantOtherIncomes
            ),
            takeLatest(
                ApplicationsTypes.DELETE_APPLICANT_EXTRA_INCOME,
                ApplicationSagas.deleteApplicantOtherIncomes
            ),
            takeLatest(
                ApplicationsTypes.DELETE_EMPLOYMENT,
                ApplicationSagas.deleteEmployment
            ),
            takeLatest(
                ApplicationsTypes.REMOVE_ADDRESS,
                ApplicationSagas.removeAddress
            ),
            takeLatest(
                ApplicationsTypes.UPDATE_TARGET_PROPERTY,
                ApplicationSagas.updateTargetProperty
            ),
            takeLatest(
                ApplicationsTypes.SAVE_EMPLOYMENT,
                ApplicationSagas.saveEmployment
            ),
            takeLatest(
                ApplicationsTypes.SAVE_INCOME,
                ApplicationSagas.saveIncome
            ),
            takeLatest(
                ApplicationsTypes.SET_CURRENT_INCOME,
                ApplicationSagas.setCurrentIncome
            ),
            takeLatest(
                ApplicationsTypes.UPDATE_OTHER_PROPERTIES,
                ApplicationSagas.updateOtherProperties
            ),
            takeLatest(
                ApplicationsTypes.DELETE_OTHER_PROPERTY,
                ApplicationSagas.deleteOtherProperty
            ),
            takeLatest(
                ApplicationsTypes.CREATE_OTHER_PROPERTY,
                ApplicationSagas.createOtherProperty
            ),
            takeLatest(
                ApplicationsTypes.DELETE_ASSET,
                ApplicationSagas.onDeleteAsset
            ),
            takeLatest(
                ApplicationsTypes.UPDATE_ASSET,
                ApplicationSagas.onUpdateAsset
            ),
            takeLatest(
                ApplicationsTypes.UPDATE_PROPERTY_ASSETS,
                ApplicationSagas.updatePropertyAssets
            ),
            takeLatest(
                ApplicationsTypes.SAVE_BANKING_DETAILS,
                ApplicationSagas.saveBankingDetails
            ),
            takeLatest(
                AnalyticsSagas.AnalyticTypes.SAVE_APPLICATIONS_SUCCESS,
                AnalyticsSagas.onSaveApplicationSuccess
            ),
            takeLatest(
                AnalyticsSagas.AnalyticTypes.TRACK_CREATE_ACCOUNT_SUCCESS,
                AnalyticsSagas.trackCreateAccountSuccess
            ),
            takeLatest(
                ApplicationsTypes.SUBMIT_APPLICATIONS_REQUEST,
                ApplicationSagas.onSubmitApplicationRequest
            ),
            takeLatest(
                ApplicationsTypes.SET_APPLICANT_OTHER_INCOMES_SPECIFIED,
                ApplicationSagas.setApplicantOtherIncomesSpecified
            ),
            takeLatest(
                ApplicationsTypes.SET_APPLICANT_OTHER_PROPERTIES_SPECIFIED,
                ApplicationSagas.setApplicantOtherPropertiesSpecified
            ),
            takeLatest<AnalyticsEventsSagas.AnalyticEvent>(
                // @ts-ignore
                AnalyticsEventsSagas.AnalyticEventTypes.ANALYTIC_EVENT,
                AnalyticsEventsSagas.onAnalyticEvent
            ),
            takeLatest(LOCATION_CHANGE_TYPE, AccountSagas.onRouteChange),
            takeLatest(
                AnalyticsSagas.AnalyticTypes.START_FLOW,
                AnalyticsSagas.trackStartFlowEvent
            ),
            takeLatest(
                ApplicationsTypes.UPDATE_RENEWING_PROPERTY,
                ApplicationSagas.updateRenewingProperty
            ),

            takeLatest(
                DocumentsTypes.DOCUMENTS_REQUEST,
                DocumentsSagas.documentsRequest
            ),
            takeLatest(
                DocumentsTypes.ALL_DOCUMENTS_REQUEST,
                DocumentsSagas.allDocumentsRequest
            ),
            takeLatest(
                DocumentsTypes.DOCUMENTS_BOOST_SEND,
                DocumentsSagas.sendDocumentBoostEmail
            ),
            takeLatest(
                DocumentsTypes.DOCUMENTS_BOOST_INFO_REQUEST,
                DocumentsSagas.getLastDocumentBoostEmail
            ),
            takeLatest(
                DocumentsTypes.DOCUMENTS_ERROR,
                DocumentsSagas.documentsError
            ),
            takeLatest(
                DocumentsTypes.DOCUMENTS_COUNTS_REQUEST,
                DocumentsSagas.documentsCountsRequest
            ),
            takeLatest(
                DocumentsTypes.DOCUMENTS_COUNTS_ERROR,
                DocumentsSagas.documentsCountsError
            ),
            takeLatest(
                DocumentsTypes.FILE_UPLOAD_REQUEST,
                DocumentsSagas.fileUploadRequest
            ),
            takeLatest(
                DocumentsTypes.FILE_UPLOAD_SUCCESS,
                DocumentsSagas.fileUploadSuccess
            ),
            takeLatest(
                DocumentsTypes.FILE_UPLOAD_ERROR,
                DocumentsSagas.fileUploadError
            ),
            takeLatest(
                DocumentsTypes.FILE_DELETE_REQUEST,
                DocumentsSagas.fileDeleteRequest
            ),
            takeLatest(
                DocumentsTypes.FILE_DELETE_SUCCESS,
                DocumentsSagas.fileDeleteSuccess
            ),
            takeLatest(
                DocumentsTypes.FILE_DELETE_ERROR,
                DocumentsSagas.fileDeleteError
            ),
            takeLatest(
                DocumentsTypes.GET_DOCUMENT_TYPES_REQUEST,
                DocumentsSagas.getDocumentTypes
            ),
            takeLatest(
                DocumentsTypes.FILE_DOWNLOAD_REQUEST,
                DocumentsSagas.fileDownloadRequest
            ),
            takeLatest(
                DocumentsTypes.FILE_DOWNLOAD_ERROR,
                DocumentsSagas.fileDownloadError
            ),
            takeLatest(
                DocumentsTypes.FILE_PREVIEW_REQUEST,
                DocumentsSagas.filePreviewRequest
            ),
            takeLatest(
                DocumentsTypes.FILE_PREVIEW_ERROR,
                DocumentsSagas.filePreviewError
            ),
            takeLatest(
                DocumentsTypes.FILE_DOWNLOAD_PDF_REQUEST,
                DocumentsSagas.fileDownloadPDFRequest
            ),
            takeLatest(
                DocumentsTypes.FILE_DOWNLOAD_PDF_ERROR,
                DocumentsSagas.fileDownloadPDFError
            ),
            takeLatest(
                DocumentsTypes.DOCUMENT_PREVIEW_REQUEST,
                DocumentsSagas.documentPreviewRequest
            ),
            takeLatest(
                DocumentsTypes.DOCUMENT_PREVIEW_ERROR,
                DocumentsSagas.documentPreviewError
            ),
            takeLatest(
                DocumentsTypes.DOCUMENT_DOWNLOAD_PDF_REQUEST,
                DocumentsSagas.documentDownloadPDFRequest
            ),
            takeLatest(
                DocumentsTypes.DOCUMENT_DOWNLOAD_PDF_ERROR,
                DocumentsSagas.documentDownloadPDFError
            ),
            takeLatest(
                DocumentsTypes.UPDATE_DOCUMENT_REQUEST,
                DocumentsSagas.updateDocumentRequest
            ),
            takeLatest(
                DocumentsTypes.UPDATE_DOCUMENT_STATE_REQUEST,
                DocumentsSagas.updateDocumentStateRequest
            ),
            takeLatest(
                DocumentsTypes.UPDATE_DOCUMENT_STATE_ERROR,
                DocumentsSagas.updateDocumentStateError
            ),
            takeLatest(
                DocumentsTypes.CREATE_MESSAGE_REQUEST,
                DocumentsSagas.createMessageRequest
            ),
            takeLatest(
                DocumentsTypes.CREATE_MESSAGE_SUCCESS,
                DocumentsSagas.messageActionSuccess
            ),
            takeLatest(
                DocumentsTypes.CREATE_MESSAGE_ERROR,
                DocumentsSagas.documentError
            ),
            takeLatest(
                DocumentsTypes.EDIT_MESSAGE_REQUEST,
                DocumentsSagas.editMessageRequest
            ),
            takeLatest(
                DocumentsTypes.EDIT_MESSAGE_SUCCESS,
                DocumentsSagas.messageActionSuccess
            ),
            takeLatest(
                DocumentsTypes.EDIT_MESSAGE_ERROR,
                DocumentsSagas.documentError
            ),
            takeLatest(
                DocumentsTypes.DELETE_MESSAGE_REQUEST,
                DocumentsSagas.deleteMessageRequest
            ),
            takeLatest(
                DocumentsTypes.DELETE_MESSAGE_SUCCESS,
                DocumentsSagas.messageActionSuccess
            ),
            takeLatest(
                DocumentsTypes.DELETE_MESSAGE_ERROR,
                DocumentsSagas.documentError
            ),
            takeLatest(
                ApplicationsTypes.TRANSITION_TO_NEXT_PAGE,
                ApplicationSagas.transitionToNextPage
            ),
            takeLatest(
                ApplicationsTypes.OTHER_PROPERTIES_TRANSITION_TO_NEXT_PAGE,
                ApplicationSagas.otherPropertiesTransitionToNextPage
            ),
            takeLatest(
                ApplicationsTypes.CREATE_COAPPLICANT,
                ApplicationSagas.createCoapplicant
            ),
            takeLatest(
                ApplicationsTypes.DELETE_COAPPLICANT,
                ApplicationSagas.deleteCoapplicant
            ),
            takeLatest(UITypes.UPDATE_PARTNER, UISagas.updatePartner),
            takeLatest(
                ApplicationsTypes.MANUAL_ACCOUNT_PARTNER_UPDATE,
                ApplicationSagas.updateAccountWithPartnerAndSubPartner
            ),
            takeLatest(
                UITypes.UPDATE_LEAD_REFERRAL,
                UISagas.updateLeadReferral
            ),
            takeLatest(
                UITypes.UPDATE_UNI_POSTAL_CODE,
                UISagas.updateUniPostalCodeFlow
            ),
            takeLatest(
                ProductSelectionTypes.STEP_REQUEST,
                ProductSelection.step
            ),
            takeLatest(
                ProductSelectionTypes.STEP_INFO_REQUEST,
                ProductSelection.stepInfoRequest
            ),
            takeLatest(
                ProductSelectionTypes.SELECTION_REQUEST,
                ProductSelection.productSelection
            ),
            takeLatest(
                ProductSelectionTypes.STEP_ERROR,
                ProductSelection.productSelectionError
            ),
            takeLatest(
                ProductSelectionTypes.STEP_INFO_ERROR,
                ProductSelection.productSelectionError
            ),
            takeLatest(
                ProductSelectionTypes.SELECTION_ERROR,
                ProductSelection.productSelectionError
            ),
            takeLatest(
                ClosingDocumentsTypes.CLOSING_DOCUMENTS_INFOS,
                ClosingDocuments.getClosingDocuments
            ),
            takeLatest(
                ClosingDocumentsTypes.CLOSING_DOCUMENTS_SAVE_INFOS,
                ClosingDocuments.saveClosingDocuments
            ),
            takeLatest(
                ClosingDocumentsTypes.CLOSING_DOCUMENTS_MULTIPLE,
                ClosingDocuments.getMultiple
            ),
            takeLatest(
                ClosingDocumentsTypes.CLOSING_DOCUMENTS_MULTIPLE_SUCCESS,
                ClosingDocuments.getMultipleSuccess
            ),
            takeLatest(
                ClosingDocumentsTypes.CLOSING_DOCUMENTS_MULTIPLE_FAILED,
                ClosingDocuments.getMultipleFailed
            ),
            takeLatest(
                ApplicationsTypes.UPLOAD_MLS_TARGET_INFO,
                ApplicationSagas.uploadMLSTargetProperty
            ),
            takeLatest(
                ApplicationsStateTypes.ADVISOR_BOOKED_AT,
                ApplicationSagas.onSetAdvisorBooked
            ),
            takeLatest(
                ApplicationsStateTypes.APPLICANT_IN_SALES_FORCE_QUEUE_REQUEST,
                ApplicationSagas.onSetApplicantInSalesForceQueue
            ),
            takeLatest(
                ApplicationsStateTypes.APPLICANT_IN_SALES_FORCE_SPEAKS_FRENCH_REQUEST,
                ApplicationSagas.onSetApplicantComfortableSpeakingFrench
            ),
            takeLatest(
                ApplicationsStateTypes.APPLICATION_SUBMITTED_BY_AGENT_REQUEST,
                ApplicationSagas.onSetApplicationSubmittedByAgent
            ),
            takeLatest(
                ApplicationsTypes.UPDATE_SUBPARTNER_ID,
                ApplicationSagas.onUpdateSubpartnerId
            ),
            takeLatest(
                ApplicationsTypes.DELETE_APPLICATION,
                ApplicationSagas.onDeleteApplication
            )
        ]);
    } catch (error) {
        log({ error: `error occured in root saga ${error}` });
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    }
};
