import React from 'react';
import { Flex } from 'reflexbox/styled-components';

import { CardWithLabel, Typograph } from '@nesto/design-system';
import { ModalContent } from './modal-content.component';
import { Button } from 'product-selection/components/buttons/button.component';

type Props = {
    children?: React.ReactNode;
    title?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm: (props?: any) => void;
    close: () => void;
};

export const ConfirmationModal = ({
    close,
    onConfirm,
    title,
    confirmButtonText,
    cancelButtonText,
    children
}: Props) => (
    <ModalContent close={close}>
        <CardWithLabel
            width={['310px', '100%', '620px']}
            p={['30px']}
            pb={['40px']}
        >
            <Flex
                width={['100%', '100%', '310px']}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mt={3}
                mx="auto"
            >
                {children}
                {title && (
                    <Typograph
                        tx={title}
                        fontSize={3}
                        fontWeight={700}
                        mb={5}
                        textAlign="center"
                    />
                )}
                <Button
                    boxShadow="outline"
                    label={confirmButtonText || 'yes'}
                    minWidth="50%"
                    onClick={() => {
                        onConfirm();
                        close();
                    }}
                />
                <Button
                    variant="ghost"
                    boxShadow="outline"
                    label={cancelButtonText || 'no'}
                    mt={2}
                    minWidth="50%"
                    onClick={close}
                />
            </Flex>
        </CardWithLabel>
    </ModalContent>
);
