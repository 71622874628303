import { RootState } from 'reducers/index';

export const getHasClosingDocumentsInfos = (state: RootState) =>
    !!state.closingDocuments?.infos;

export const getClosingDocumentsInfos = (state: RootState) =>
    state.closingDocuments?.infos;

export const getIsLoading = (state: RootState) =>
    !!state.closingDocuments?.loading;

export const getSuccess = (state: RootState) => state.closingDocuments?.success;

export const getError = (state: RootState) => state.closingDocuments?.error;
