import { Icon as arrowRight } from './icon-arrow-right';

const Icons = {
    arrowRight
};

export type IconTypes = 'arrowRight';

const getIcon = (key: IconTypes) => Icons[key];

export { getIcon };
