import { useDispatch } from 'react-redux';

import { analyticEvent, AnalyticEvent } from 'sagas/analytics-events.sagas';

export const useAnalyticEvent = () => {
    const dispatch = useDispatch();

    const track = (
        name: AnalyticEvent['name'],
        payload: AnalyticEvent['payload']
    ) => dispatch(analyticEvent(name, payload));

    return track;
};
