import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { WrappedFieldProps } from 'redux-form';
import { default as Select } from 'react-select';
import styled, { withTheme, css } from 'styled-components/macro';

import { InputError } from 'components/inputs/input-error/input-error.component';
import { normalizeInputNameForE2E } from 'utils/e2e-utilities';
import { compose } from 'redux';
import { Theme } from '@nesto/themes';
import { withSizesHoc as WithSizes, WithSizesProps } from 'hocs/with-sizes.hoc';
import { readOnlyInput } from '../shared/styles';
import { ReadOnlyValue } from '../mocked-input';
import { useOptionalLabelGenerator } from 'utils/hooks/useOptionalLabelGenerator';

type Props = {
    options: Array<{
        label: string;
        value: string | number;
    }>;
    dataTestId?: string;
    isSearchable?: boolean;
    isClearable?: boolean;
    useOnBlur?: boolean;
    multi?: boolean;
    className?: string;
    styleType?: string;
    isRequired: boolean;
    sort?: boolean;
    translate?: boolean;
    disabled?: boolean;
    placeholder?: string;
    icon?: {
        src: string;
        width: number;
        height: number;
    };
    preset?: string;
    showScrollBar?: boolean;
    useClassStyle?: boolean;
    menuPlacement?: 'top' | 'auto';
    stylesOverrides?: any;
    setPlaceholderInnerHtml?: true;
    theme: Theme;
    onSubmit?: (value: any) => void;
    showErrorOnTouched?: boolean;
    readOnly?: boolean;
    isOptional?: boolean;
    frOptionalLabelGender?: 'masculine' | 'feminine';
    withNoBorder?: boolean;
};

export const mapStylesToSelect = (
    theme,
    classStyle: 'disabled' | 'error' | 'valid' | 'default',
    showScrollBar?: boolean,
    preset?: string,
    stylesOverrides?: any,
    icon?: {
        src: string;
        width: number;
        height: number;
    },
    withNoBorder = false
) => ({
    option: (base, data) => ({
        ...base,
        fontSize: theme.fontSizes[2],
        outline: 'none',
        color: data.isDisabled ? theme.colors.grey50 : theme.colors.tundora,
        margin: 0,
        ':hover': !data.isDisabled
            ? {
                  color: theme.colors.textBlack,
                  backgroundColor: theme.colors.alabaster,
                  fontWeight: '600'
              }
            : undefined,
        fontFamily: theme.fontFamily,
        borderBottom: `1px solid ${props => props.theme.borderColor.default}`,
        padding: '16px 14px'
    }),
    menu: base => ({
        ...base,
        margin: '-2px 0px 0px 0px',
        borderTop: '0px',
        padding: 0,
        zIndex: theme.zIndex.sky,
        overflowY: 'scroll',
        minWidth: '200px'
    }),
    menuList: base => ({
        ...base,
        maxHeight: showScrollBar ? '250px' : '100%',
        overflow: 'auto',
        paddingTop: 14,
        paddingBottom: 0
    }),
    control: (base, state) => ({
        ...base,
        fontFamily: theme.fontFamily,
        fontSize: theme.fontSizes[2],
        borderRadius: 6,
        ...{
            ...(state.isFocused
                ? {
                      color: theme.input.disabled.color,
                      backgroundColor: theme.input.disabled.background,
                      borderColor: theme.input.disabled.borderColor
                  }
                : {
                      color: theme.input[classStyle].color,
                      backgroundColor: theme.input[classStyle].background,
                      borderColor: theme.input[classStyle].borderColor
                  })
        },
        minHeight: preset === 'SMALL' ? 48 : 60,
        paddingLeft: 10,
        paddingRight: 10,
        transition: 'all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)',
        cursor: state.isDisabled ? 'not-allowed' : 'default',

        ...{
            ...(withNoBorder
                ? {
                      border: 0,
                      // This line disable the blue border
                      boxShadow: 'none'
                  }
                : {})
        },

        ':hover': {
            color: theme.input.hover.color,
            borderColor: theme.input.hover.borderColor
        },

        ':before': {
            content: icon ? `''` : 'none',
            marginRight: 22,
            background: `url(${icon ? icon.src : ''}) no-repeat`,
            backgroundSize: 'cover',
            width: icon ? icon.width : 0,
            height: icon ? icon.height : 0
        }
    }),
    input: base => ({
        ...base,
        fontFamily: theme.fontFamily,
        '& input': {
            font: 'inherit'
        }
    }),
    valueContainer: base => ({
        color: theme.colors.blazeOrange
    }),
    placeholder: (base, data) => ({
        ...base,
        color: data.isDisabled
            ? theme.input.disabled.color
            : theme.colors.tundora
    }),
    singleValue: base => ({
        ...base,
        fontFamily: theme.fontFamily,
        fontSize: theme.fontSizes[2],
        color: theme.input[classStyle].color,
        maxWidth: 'calc(100% - 55px)'
    }),
    indicatorSeparator: base => ({
        display: 'none'
    }),
    ...stylesOverrides
});

const mapStylesToPresetsLabel = ({ preset, theme }) => {
    const transitionLeft = '11px';
    switch (preset) {
        case 'SMALL':
            return css`
                font-size: ${theme.fontSizes[1]};
                transform: translate(${transitionLeft}, 4px) scale(0.75);
                opacity: 1;
            `;
        default:
            return css`
                font-size: ${theme.fontSizes[2]};
                opacity: 1;
                transform: translate(${transitionLeft}, 10px) scale(0.75);
            `;
    }
};
const Placeholder = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    top: 0;
    opacity: 0;
    color: ${props => props.theme.colors.boulder};
    left: 0;
    position: absolute;
    padding: 0;
    font-family: ${props => props.theme.fontFamily};
    transform-origin: top left;
    transition: all 0s linear 300ms, opacity 300ms;
    ${mapStylesToPresetsLabel}
`;

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
    if (multi && typeof value === 'string') return [];

    const filteredOptions = options.filter(option =>
        multi ? value.indexOf(option.value) !== -1 : option.value === value
    );

    return multi ? filteredOptions : filteredOptions[0];
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func: (arg: string) => void) {
    return function handleSingleChange(value) {
        func(value ? value.value : '');
    };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func: (arg: string) => void) {
    return function handleMultiHandler(values) {
        func(values.map(value => value.value));
    };
}

export function translateOptions(
    options: Array<{ label: string; value: string | number }>,
    intl: any
) {
    return options.map(option => ({
        ...option,
        label: intl.formatMessage({ id: option.label })
    }));
}

export const returnClass = (meta, disabled, useClassStyle = true, readOnly) => {
    if (!useClassStyle || readOnly) {
        return 'default';
    }

    if (disabled) {
        return 'disabled';
    }

    if (meta.visited && meta.error) {
        return 'error';
    }

    if (meta.visited && meta.valid) {
        return 'valid';
    }

    return 'default';
};

type WrapperProps = {
    disabled: boolean;
    readOnly?: boolean;
};

const SelectWrapper = styled.div<WrapperProps>`
    width: 100%;
    display: inline-flex;
    padding: 0px;
    flex-direction: column;
    vertical-align: top;
    position: relative;
    .react-select__menu-list div:last-child {
        border-bottom: 0px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `};

    ${({ readOnly }) =>
        readOnly &&
        css`
            background-color: transparent !important;
            * {
                background-color: #f8f8f8 !important;
            }
        `};
    ${readOnlyInput}
`;

const RFReactSelectView = ({
    theme,
    input,
    options,
    dataTestId,
    isClearable = false,
    multi = false,
    useOnBlur = false,
    disabled = false,
    showScrollBar = false,
    useClassStyle = true,
    menuPlacement = 'auto',
    isRequired,
    translate,
    sort,
    icon,
    placeholder,
    preset,
    intl,
    meta,
    stylesOverrides,
    setPlaceholderInnerHtml,
    isMobile,
    onSubmit,
    showErrorOnTouched = false,
    isOptional = true,
    frOptionalLabelGender = 'feminine',
    readOnly,
    withNoBorder = false
}: Props & WrappedComponentProps & WrappedFieldProps & WithSizesProps) => {
    const { name, value, onBlur, onChange = () => {}, onFocus } = input;
    const newOptions = !isRequired
        ? options.concat({ label: 'select.none', value: '' })
        : options;
    const translatedOptions = translate
        ? translateOptions(newOptions, intl)
        : options;

    const otherOptions = translatedOptions.filter(
        option => option.value === 'OTHER'
    );
    const optionsWithoutOther = translatedOptions.filter(
        option => option.value !== 'OTHER'
    );

    const sortedOptions = sort
        ? optionsWithoutOther.sort((a, b) => a.label.localeCompare(b.label))
        : optionsWithoutOther;

    const finalOptions = [...sortedOptions, ...otherOptions];

    const transformedValue: any = transformValue(value, finalOptions, multi);

    const onKeyPress = event => {
        if (onSubmit && event.key === 'Enter') {
            onSubmit(transformedValue);
        }
    };

    const classStyle = returnClass(meta, disabled, useClassStyle, readOnly);

    const placeholderLabelText = placeholder
        ? placeholder
        : intl.formatMessage({
              id: 'select.defaultPlaceholder'
          });

    const placeholderValue = useOptionalLabelGenerator({
        labelText: placeholderLabelText,
        isOptional: !isRequired && isOptional,
        frOptionalLabelGender
    });

    return (
        <SelectWrapper
            data-test-id={
                dataTestId || `${normalizeInputNameForE2E(name || '')}`
            }
            disabled={disabled}
            readOnly={readOnly}
        >
            {readOnly ? (
                <ReadOnlyValue
                    theme={theme}
                    value={value !== '' ? transformedValue : null}
                />
            ) : (
                <Select
                    menuIsOpen={readOnly ? false : undefined}
                    inputId={`select_input_${normalizeInputNameForE2E(
                        name || ''
                    )}`}
                    id={`select_${normalizeInputNameForE2E(name || '')}`}
                    classNamePrefix="react-select"
                    isSearchable={!isMobile}
                    valueKey="value"
                    name={name}
                    value={value !== '' ? transformedValue : null}
                    multi={multi}
                    isMulti={multi}
                    isClearable={isClearable}
                    isDisabled={disabled}
                    instanceId={normalizeInputNameForE2E(name || '')}
                    options={finalOptions}
                    noOptionsMessage={() =>
                        intl.formatMessage({ id: 'select.noOptions' })
                    }
                    placeholder={
                        setPlaceholderInnerHtml ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: placeholderValue
                                }}
                            />
                        ) : (
                            placeholderValue
                        )
                    }
                    blurInputOnSelect={true}
                    menuPlacement={menuPlacement}
                    onChange={
                        multi
                            ? multiChangeHandler(onChange)
                            : singleChangeHandler(onChange)
                    }
                    onBlur={() =>
                        useOnBlur && onBlur && onBlur(transformedValue)
                    }
                    onFocus={onFocus}
                    onKeyDown={onKeyPress}
                    className={normalizeInputNameForE2E(name || '')}
                    styles={mapStylesToSelect(
                        theme,
                        classStyle,
                        showScrollBar,
                        preset,
                        stylesOverrides,
                        icon,
                        withNoBorder
                    )}
                    theme={Theme => ({
                        ...Theme,
                        borderRadius: 0,
                        colors: {
                            ...Theme.colors,
                            primary25: theme.colors.alabaster,
                            primary: theme.colors.seashell
                        }
                    })}
                />
            )}

            {placeholder && input.value ? (
                <Placeholder
                    preset={preset}
                    data-test-id={`select_label-${normalizeInputNameForE2E(
                        input.name || 'select-field'
                    )}`}
                >
                    <span dangerouslySetInnerHTML={{ __html: placeholder }} />
                </Placeholder>
            ) : null}
            <InputError
                meta={meta}
                name={name}
                showErrorOnTouched={showErrorOnTouched}
            />
        </SelectWrapper>
    );
};

export const RFReactSelect = compose<React.FC<Props & any>>(
    injectIntl,
    withTheme,
    WithSizes
)(RFReactSelectView);
