import { Size } from './types';
import { useSizes } from './useSizes';
import { useTheme } from './useTheme';

export const useIsDesktop = () => {
    const theme = useTheme();
    const isDesktop = ({ width }: Size) =>
        width > Number(theme.breakpoints[2].replace('px', ''));

    const sizes = useSizes({ isDesktop });

    return sizes.isDesktop || false;
};
