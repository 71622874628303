import React from 'react';
import styled from 'styled-components/macro';
import { Flex } from 'reflexbox/styled-components';
import posed from 'react-pose';

import { withSizesHoc as WithSizes, WithSizesProps } from 'hocs/with-sizes.hoc';

const Container = styled(Flex)`
    z-index: ${props => props.theme.zIndex.heaven};
    min-width: auto;
    position: relative;
`;

const PosedContainer = posed(Container)({
    open: {
        x: '0px',
        transition: { type: 'tween', duration: 200, ease: 'linear' }
    },
    closed: {
        x: '-120%',
        transition: { type: 'tween', duration: 200, ease: 'linear' }
    }
});

const Wrapper = styled.div`
    width: 100%;
`;

type Props = {
    isOpen: boolean;
    children: any;
    onClick: () => void;
};

const NavigationContainerView = ({
    children,
    isOpen,
    onClick,
    isDesktopAndGreater
}: Props & WithSizesProps) => (
    <PosedContainer
        pose={isDesktopAndGreater || isOpen ? 'open' : 'closed'}
        onClick={event => {
            if (event.target === event.currentTarget) {
                onClick();
            }
        }}
    >
        <Wrapper>{children}</Wrapper>
    </PosedContainer>
);

export const NavigationContainer = WithSizes(NavigationContainerView) as any;
