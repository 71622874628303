import React, { useEffect } from 'react';
import { Field } from 'redux-form';

type Props = {
    name: string;
    value: string | number | boolean;
};

const DefaultInput = ({ input, value }) => {
    useEffect(() => {
        if (value !== input.value) {
            input.onChange(value);
        }
    }, [value, input]);
    return null;
};

export const DefaultValueField = ({ name, value }: Props) => (
    <Field
        name={name}
        component={({ input }) => <DefaultInput input={input} value={value} />}
    />
);
