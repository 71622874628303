import { call, put, select } from 'redux-saga/effects';
import { RatesTypes } from 'reducers/rates.redux';
import { apiClient } from 'services/api';
import { log } from 'utils/logging';
import { terms } from 'models/QualifiedRate';
import {
    getRatesFilterFormData,
    getRatesFilter
} from 'reducers/rates.selectors';
import { propertyTypes } from 'types/properties/property-types';
import { getFeatureFlagState } from 'components/feature-flagger/hooks';
import { getUserKey } from 'components/feature-flagger/getUserKey';
import { errorNotification } from 'reducers/notifications.redux';
import { Timings } from 'types/application/application';
import { downPaymentRatios } from 'constants/ratesConstants';

export enum quoteTypes {
    NEW = 'NEW',
    RENEWAL = 'RENEWAL',
    REFINANCE = 'REFINANCE'
}

export enum productTypes {
    VARIABLE = 'VARIABLE',
    FIXED = 'FIXED'
}

export type RatesResponse = {
    id: number;
    monthly: number;
    maxCashbackAmount: number;
    providerLogo: string;
    providerName: string;
    rate: number;
    rateType: productTypes;
    term: terms;
};

export type Filters = {
    regionCode: string; // @TODO: create enum of possible region codes here
    quoteType: quoteTypes;
    ownerOccupied: string;
    productType: productTypes;
    renewalSchedule: string;
    renewalScheduleDate?: string;
    mortgageAmount: number;
    propertyValue: number;
    timing: Timings;
    propertyType: propertyTypes;
    term: terms;
    amortization?: number;
    downPaymentAmount?: number;
    downPaymentRatio?: downPaymentRatios | boolean;
    lender?: string;
    lenderOther?: string;
    additionalFundAmount?: number;
    postalCode?: any;
    acceptanceDate?: string;
};

export type Params = {
    regionCode: string;
    transactionType: quoteTypes;
    ownerOccupied: boolean;
    productType: productTypes;
    downPaymentAmount?: number;
    downPaymentRatio?: downPaymentRatios;
    renewalSchedule?: string;
    renewalScheduleDate?: string;
    mortgageAmount?: number;
    additionalFundAmount?: number;
    propertyValue?: number;
    interestLevel?: Timings;
    propertyType?: propertyTypes;
    productTerm?: terms;
    amortization?: number;
    anonymousAccountId?: string;
    lender?: string;
    lenderOther?: string;
    postalCode?: string;
    acceptanceDate?: string;
    creditScoreQuality?: string;
};

export function* onFetchQuotesMatrixRequest({ filters }) {
    try {
        const {
            regionCode,
            productType,
            quoteType,
            renewalSchedule,
            renewalScheduleDate,
            propertyType,
            mortgageAmount,
            propertyValue,
            timing,
            term,
            ownerOccupied,
            amortization,
            downPaymentRatio,
            downPaymentAmount,
            additionalFundAmount,
            lender,
            lenderOther,
            postalCode,
            acceptanceDate,
            creditScoreQuality
        } = yield select(getRatesFilterFormData);

        //if there is a postal code in query Params we will load it from there
        const ratesFilter = yield select(getRatesFilter);

        const PostalCodeValue =
            filters?.postalCode || ratesFilter?.postalCode || postalCode;

        const isAnonymousRates = yield getFeatureFlagState(
            'anonymous-rates-page'
        );
        const anonymousId = getUserKey();

        let params: Params = {
            productTerm: term,
            amortization,
            productType,
            renewalSchedule,
            renewalScheduleDate,
            regionCode,
            postalCode: PostalCodeValue,
            propertyValue,
            additionalFundAmount,
            transactionType: quoteType,
            ownerOccupied: ownerOccupied === 'true',
            anonymousAccountId: isAnonymousRates ? anonymousId : undefined,
            creditScoreQuality
        };

        if (quoteType === quoteTypes.RENEWAL) {
            if (!mortgageAmount) {
                return;
            }

            params = {
                ...params,
                lender,
                lenderOther,
                mortgageAmount,
                downPaymentRatio
            };
        }

        if (quoteType === quoteTypes.REFINANCE) {
            if (!mortgageAmount) {
                return;
            }

            params = {
                ...params,
                lender,
                lenderOther,
                mortgageAmount,
                additionalFundAmount,
                downPaymentRatio: downPaymentRatios.UNKNOWN
            };
        }

        if (quoteType === quoteTypes.NEW) {
            if (!downPaymentAmount || !propertyValue) {
                return;
            }

            params = {
                ...params,
                propertyType,
                downPaymentAmount: Math.ceil(downPaymentAmount),
                propertyValue,
                interestLevel: timing,
                acceptanceDate
            };
        }

        const { data, ok } = yield call(apiClient.fetchQuotesMatrix, params);
        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: RatesTypes.FETCH_QUOTES_MATRIX_SUCCESS,
            matrix: data
        });
    } catch (error) {
        yield put({
            type: RatesTypes.FETCH_QUOTES_MATRIX_FAILURE,
            error
        });
    }
}

export function* selectRateFailure(error: any) {
    try {
        log({ error });

        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}
