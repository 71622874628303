export const EVENTS = {
    QUESTION_VIEWED: 'question viewed',
    QUESTION_ANSWERED: 'updated answer',
    BEGIN_PROCESS: 'start flow'
};

type TRACK_PAYLOAD = {
    question_id?: string;
    question_name?: string;
    location?: string;
    language?: string;
    type?: string;
    redux?: any;
};

export enum PhoneNumberValidity {
    Valid = 'valid',
    Invalid = 'invalid'
}

export const track = (event: string, payload: TRACK_PAYLOAD | any): void => {
    try {
        window.dataLayer.push({
            ...payload,
            event
        });
    } catch (e) {
        console.log('error in dataLayer push', e);
    }
};
