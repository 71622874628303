import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import styled from 'styled-components';
import { normalizeInputNameForE2E } from 'utils/e2e-utilities';
import { Theme } from '@nesto/themes';

import { InputError } from 'components/inputs/input-error/input-error.component';

type Props = {
    placeholder?: string;
    preset?: string;
    theme: Theme;
    showErrorOnTouched?: boolean;
    disabled?: boolean;
};

type SelectProps = {
    classStyle: 'disabled' | 'error' | 'valid' | 'default';
};

const mapStylesToPresetsSelect = ({
    preset,
    classStyle,
    theme
}: SelectProps & Props) => {
    switch (preset) {
        case 'SMALL':
            return `
                height: 130px;
                font-size: ${theme.fontSizes[1]};
                border-color: ${theme.input[classStyle].borderColor};
                color: ${theme.input[classStyle].color};
                padding: 16px 12px;
                background-color: ${theme.input[classStyle].background};
            `;
        default:
            return `
                height: 160px;
                font-size: ${theme.fontSizes[2]};
                border-color: ${theme.input[classStyle].borderColor};
                color: ${theme.input[classStyle].color};
                padding: ${theme.space[2]}px;
                background-color: ${theme.input[classStyle].background};
            `;
    }
};

export const StyledTextarea = styled.textarea`
    border-radius: 6px;
    outline: none;
    width: 100%;
    &:hover {
        border-color: ${props => props.theme.colors.coldGray};
        background-color: ${props => props.theme.colors.white};
    }
    ${mapStylesToPresetsSelect}
`;

export const returnClass = meta => {
    if (meta.touched && meta.error) {
        return 'error';
    }
    if (meta.touched && meta.valid) {
        return 'valid';
    }
    return 'default';
};

const TextArea = ({
    meta,
    input,
    placeholder,
    preset,
    showErrorOnTouched = false,
    disabled = false
}: Props & WrappedFieldProps) => {
    const classStyle = returnClass(meta);

    return (
        <>
            <StyledTextarea
                data-test-id={`textarea-${normalizeInputNameForE2E(
                    input.name || 'textarea'
                )}`}
                classStyle={classStyle}
                placeholder={placeholder}
                preset={preset}
                disabled={disabled}
                {...input}
            />
            <InputError
                meta={meta}
                name={input.name}
                showErrorOnTouched={showErrorOnTouched}
            />
        </>
    );
};

export { TextArea };
