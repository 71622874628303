import {
    Box as CustomBox,
    Flex as CustomFlex
} from 'reflexbox/styled-components';
import { BoxType } from 'custom-reflexbox';

/** Properly typed Reflexbox <Box> component */
const Box: BoxType = CustomBox;
/** Properly typed Reflexbox <Flex> component */
const Flex: BoxType = CustomFlex;

export { Box, Flex };
