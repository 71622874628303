import React from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from 'reflexbox/styled-components';

import { Actions as SidebarActions } from 'reducers/ui.redux';
import { Logo } from 'components/sidebar/components/logo.component';
import { Burger } from './mobile-header.styles';

type DispatchProps = {
    toggleSidebar: (showSidebar?: boolean) => void;
};

type Props = {
    children?: React.ReactNode;
};

const HeaderView = ({ toggleSidebar, children }: Props & DispatchProps) => (
    <header>
        <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Logo />

            <Box ml="auto" onClick={toggleSidebar}>
                <Burger />
            </Box>
        </Flex>

        {children}
    </header>
);

export const Header = connect<{}, DispatchProps>(null, {
    toggleSidebar: SidebarActions.updateSidebar
})(HeaderView) as React.FC<{}>;
