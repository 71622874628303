import { createActions } from 'reduxsauce';
import qs from 'qs';

/* ------------- Selectors ------------- */

export const getCurrentStepFromRouter = (state: any) => {
    const { pathname } = state.router.location;
    const splitPath = pathname.split('/');
    return splitPath[splitPath.length - 1];
};

export const getAllParamsFromRouter = (state: any) => {
    const { search } = state.router.location;
    const params =
        search &&
        qs.parse(search, {
            ignoreQueryPrefix: true
        });

    return {
        applicant:
            params && params.applicant ? 'coapplicant' : 'main applicant',
        isSubjectProperty: !(params && params.property),
        ...params
    };
};

export const getCurrentPath = (state: any): string => {
    const { pathname } = state.router.location;
    return pathname;
};

export const getReferalUrl = (reduxState: any): string | undefined => {
    const { state } = reduxState.router.location;
    return state && state.from && state.from.pathname;
};

export const getReferalSearch = (reduxState: any): string | undefined => {
    const { state } = reduxState.router.location;
    return state && state?.search;
};

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
    {
        startup: null,
        end: null
    },
    { prefix: '[STARTUP] ' }
);

export const StartupTypes = Types;
export { Creators as StartupActions };
