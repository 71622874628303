import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import map from 'ramda/es/map';

import { LOCALE_SRC } from 'constants/appConstants';
import { Language } from 'types/language';
import { getUserLanguage } from 'reducers/account.selectors';
import { Actions } from 'reducers/locale.redux';
import { messages } from 'messages';
import { escapeHtmlMessages } from 'utils/intl';

type Props = {
    children: any;
    locale: Language;
    initialUserLocale: Language;
    dispatch: (cb: any) => void;
};

const getMessages = (locale: Language): { [key: string]: string } =>
    map(value => (value ? escapeHtmlMessages(value) : ''), messages[locale]);

const ConnectedIntlProviderView: React.FC<Props> = ({
    locale,
    dispatch,
    initialUserLocale,
    children
}) => {
    useEffect(() => {
        if (initialUserLocale) {
            dispatch(
                Actions.updateLocaleAndMessages(
                    initialUserLocale,
                    getMessages(initialUserLocale),
                    LOCALE_SRC.USER_SELECTION
                )
            );
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <IntlProvider
            locale={locale}
            key={locale}
            messages={getMessages(locale)}
        >
            {children}
        </IntlProvider>
    );
};

// By keeping the IntlProvider connected to state, it will re-render dynamically when the user chooses to change locales
export const ConnectedIntlProvider = connect((state: any) => {
    const { dispatch } = state;
    const { location } = state.router;
    const { lang, localeSource } = state.locale;
    const language = getUserLanguage(state);

    return {
        locale: language || lang,
        dispatch,
        localeSource,
        location
    };
})(ConnectedIntlProviderView);
