export enum AccountDeletionStateType {
    NoApplications = 'NO_APPLICATIONS',
    ApplicationsDeletable = 'APPLICATIONS_DELETABLE',
    ApplicationsNotDeletable = 'APPLICATIONS_NOT_DELETABLE',
    FundedMortgages = 'FUNDED_MORTGAGES',
    Unknown = 'UNKNOWN'
}

export interface AccountDeletionState {
    accountId: number;
    deletableState: AccountDeletionStateType;
    isDeletable: boolean;
}

export interface AccountDeletionFormValues {
    reason: AccountDeletionReason;
    comments: string;
}

export enum AccountDeletionReason {
    FundedOtherInstitution = 'FUNDED_OTHER_INSTITUTION',
    NestoDifficultToUse = 'NESTO_DIFFICULT_TO_USE',
    NotEffective = 'NOT_EFFECTIVE',
    WindowShopping = 'WINDOW_SHOPPING',
    Other = 'OTHER',
    NoReason = 'NO_REASON'
}
