import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { LDUser, LDClient } from 'launchdarkly-js-client-sdk';
import {
    convertFeatureFlagProgressToObject,
    convertFeatureFlagProgressToString
} from 'components/feature-flagger/helpers';
import { getIsPhoneNumberReal } from 'utils/mortgage-utils';
import * as Validators from 'lib/validators';

import { ldKey as key } from 'components/feature-flagger/helpers';
import { EmploymentPartialSavingVariant } from 'constants/appConstants';
import { analyticEvent } from 'sagas/analytics-events.sagas';

export const useLDIdentify = (ldUser: LDUser) => {
    const ldclient = useLDClient();

    if (window.ldclient === undefined) {
        window.ldclientIdentified = false;

        // Add client to gloabal window object
        window.ldclient = ldclient as LDClient;
    }

    useEffect(() => {
        if (process.env.NODE_ENV === 'test') return;

        if (window.ldclientIdentified === false && ldUser.ip) {
            window.ldclientIdentified = true;
            ldclient?.identify({
                key,
                ...ldUser
            });
        }
    }, [ldUser, ldclient]);
};

const waitUntilReady = async () => {
    if (process.env.NODE_ENV === 'test') return;

    await window.ldclient?.waitUntilReady();
};

export const getFeatureFlag = (flag: string) => {
    if (process.env.NODE_ENV === 'test') return true;

    return window.ldclient.variation(flag);
};

type DefaultFlagValue = boolean;

/**
 * Get feature flag value from LaunchDarkly outside of react component. i.e in sagas
 * Do no use this function before initializing the ldclient in provider
 * @param flag
 * @returns
 */
export const getFeatureFlagState = async <T = DefaultFlagValue>(
    flag: string
): Promise<T> => {
    await waitUntilReady();
    return getFeatureFlag(flag);
};

export const useFeatureFlag = <T = DefaultFlagValue>(
    flag: string
): T | undefined => {
    const flags = useFlags();
    const variation = flags?.[flag];

    const dispatch = useDispatch();
    if (variation !== undefined) {
        const flagsAndVariationsString = localStorage.getItem(
            'featureFlagsProgess'
        );

        const flagsAndVariations = flagsAndVariationsString
            ? convertFeatureFlagProgressToObject(flagsAndVariationsString)
            : {};

        if (Object.keys(flagsAndVariations).includes(flag)) {
            // Do not send an event if the flag:variation
            // already exists in 'featureFlagsProgress'
        } else {
            flagsAndVariations[flag] = String(variation);

            const LDTest = convertFeatureFlagProgressToString(
                flagsAndVariations
            );
            // GA-4
            dispatch(
                analyticEvent('launch_darkly_test', {
                    event_location_category: 'launchdarkly tests',
                    flag_name: flag,
                    info: flagsAndVariations[flag],
                    feature_flag_tests: LDTest
                })
            );
            localStorage.setItem('featureFlagsProgess', LDTest);
        }
    }

    return variation;
};

// TODO? Why this is into the feature flagger? Doesn't seem to be related at all
/**
 *
 * @param phone - phone number
 * @param validateIsPhoneNumberRealFlag - flag to validate if phone number is real
 * @returns
 */
export const useIsPhoneNumberValid = (
    phone: string | undefined,
    validateIsPhoneNumberRealFlag: boolean = false
): boolean => {
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);
    useEffect(() => {
        const handler = setTimeout(() => {
            const fetchValidPhoneNumber = async () => {
                const isPhoneNumber = phone
                    ? Validators.phoneNumberRegex.test(phone)
                    : !!phone;

                if (isPhoneNumber && validateIsPhoneNumberRealFlag) {
                    const formattedPhoneNumber = `1${phone?.replace(
                        /[^\d]/g,
                        ''
                    )}`;
                    const isPhoneNumberReal = await getIsPhoneNumberReal(
                        formattedPhoneNumber,
                        isPhoneNumber
                    );

                    setIsPhoneNumberValid(isPhoneNumberReal);
                }
            };

            fetchValidPhoneNumber();
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [phone, validateIsPhoneNumberRealFlag]);

    return isPhoneNumberValid;
};

export const usePartialSavingOptionalFields = (): boolean => {
    const isEmploymentPartialSaving = useFeatureFlag<
        EmploymentPartialSavingVariant
    >('employment-partial-saving');

    const showPartialSavingOptionalFields = useMemo(
        () =>
            isEmploymentPartialSaving &&
            isEmploymentPartialSaving !==
                EmploymentPartialSavingVariant.FLAG_OFF
                ? ![
                      EmploymentPartialSavingVariant.PARTIAL_SAVING_HIDE,
                      EmploymentPartialSavingVariant.PARTIAL_SAVING_OPTIONAL
                  ].includes(isEmploymentPartialSaving)
                : true,
        [isEmploymentPartialSaving]
    );

    return showPartialSavingOptionalFields;
};

export const usePartialSavingFlow = (): boolean => {
    const isEmploymentPartialSaving = useFeatureFlag<
        EmploymentPartialSavingVariant
    >('employment-partial-saving');

    const showPartialSavingOptionalFields = useMemo(
        () =>
            isEmploymentPartialSaving &&
            isEmploymentPartialSaving !==
                EmploymentPartialSavingVariant.FLAG_OFF,
        [isEmploymentPartialSaving]
    );

    return showPartialSavingOptionalFields || false;
};
