// tslint:disable
import numeral from 'numeral';

export const getAsNumber = (
    value: string | number | undefined
): number | undefined => {
    return typeof value === 'string'
        ? numeral(value.replace(/[^0-9.]/g, '')).value()
        : value;
};

export const numberField = (value: string) => {
    return value && getAsNumber(value);
};

export const formatNumberDecimals = (
    value: string | number | undefined
): string | undefined => (value ? maxTwoDecimal(value) : '');

export const numberFieldNoDecimals = (value: string) => {
    return value && getAsNumber(value)?.toFixed(0);
};

export const currencyField = (max: number) => (value: string) => {
    const asNum = getAsNumber(value);
    return value && normalizeWithMax(max)(String(asNum));
};

export const normalizePercentage = (value: string, previousValue: string) => {
    const twoDecimalNumberRegex = /^\d*(\.\d{0,2})?$/g;

    if (!twoDecimalNumberRegex.test(value)) {
        return previousValue;
    }

    if (+value > 100) {
        return previousValue;
    }

    return value;
};

const maxTwoDecimal = (value: number | string) => {
    // change toString() cannot replace on a number
    value = value ? value.toString().replace(/[^0-9.]/g, '') : ''; // Remove all chars except numbers and .

    // Create an array with sections split by .
    const sections = value.split('.');

    // Remove any leading 0s apart from single 0
    if (sections[0] !== '0' && sections[0] !== '00') {
        sections[0] = sections[0].replace(/^0+/, '');
    } else {
        sections[0] = '0';
    }

    // If numbers exist after first .
    if (sections[1]) {
        // Join first two sections and truncate end section to length 2
        return (
            numeral(sections[0]).format('0,0') + '.' + sections[1].slice(0, 2)
        );
    } else if (value.indexOf('.') !== -1) {
        // If original value had a decimal place at the end, add it back
        return numeral(sections[0]).format('0,0') + '.';
    } else {
        // Otherwise, return only section
        return numeral(sections[0]).format('0,0');
    }
};

export const formatMoney = (value: number | string): string => {
    // Numeral will remove "." and the end and block user to enter it
    return value ? `$ ${maxTwoDecimal(value)}` : '';
};

export const formatPercent = (value: number | string): string => {
    // Numeral will remove "." and the end and block user to enter it
    return value ? `% ${maxTwoDecimal(value)}` : '';
};

// TODO change this to format like 999 999.9999
export const formatNumber = (value: string): string => {
    return maxTwoDecimal(value);
};

export const formatPostalCode = (value: string): string => {
    value = value ? value.toString().replace(/[^a-zA-Z\d]/g, '') : '';

    return upper(value);
};

export const normalizePostalCode = (maxLength: number) => (
    value: string,
    previousValue: string
) => {
    if (value.length > maxLength) {
        return previousValue;
    }

    return value;
};

export const formatZipCode = (value: string): string => {
    value = value ? value.toString().replace(/[^-\d]/g, '') : '';

    return value;
};

export const maxWithDecimal = (maxvalue: number) => (
    value: number | string,
    previousValue: string
): string => {
    const onlyNums = (val): string =>
        val ? val.toString().replace(/[^0-9.]/g, '') : '';
    const onlyNumsString = onlyNums(value);

    if (+onlyNumsString > maxvalue) {
        return previousValue;
    }

    return onlyNumsString;
};

export const normalizeYears = (value: string, previousValue: string) => {
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length > 4) {
        return previousValue;
    }
    return onlyNums;
};

export const normalizeMonths = (value: string, previousValue: string) => {
    const onlyNums = getAsNumber(value);
    if (onlyNums && onlyNums > 12) {
        return previousValue;
    }
    return onlyNums;
};

export const normalizeMaxNumber = (max: number) => (
    value: string,
    previousValue: string
) => {
    const onlyNums = getAsNumber(value);
    if (onlyNums && onlyNums >= max) {
        return previousValue;
    }
    return onlyNums;
};

export const normalizeDays = (value: string, previousValue: string) => {
    const onlyNums = getAsNumber(value);
    if (onlyNums && onlyNums > 31) {
        return previousValue;
    }
    return onlyNums;
};

export const normalizePhone = (value: string, previousValue: string) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return onlyNums + '-';
        }
        if (onlyNums.length === 6) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
        }
    }
    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
    }
    return (
        onlyNums.slice(0, 3) +
        '-' +
        onlyNums.slice(3, 6) +
        '-' +
        onlyNums.slice(6, 10)
    );
};

export const normalizeMLS = (value: string, previousValue: string) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length > 8) {
        return previousValue;
    }
    return getAsNumber(onlyNums);
};

export const upper = (value: string) => value && value.toUpperCase();
export const lower = (value: string) => value && value.toLowerCase();

export const capitalizeText = (value: string): string => {
    return capitalize(value);
};

export const capitalize = (value: string) =>
    value &&
    value
        .toLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));

export const lessThanOtherField = (otherField: string) => (
    value: number,
    previousValue: number,
    allValues: any
) => (value < allValues[otherField] ? value : previousValue);

export const greaterThanOtherField = (otherField: string) => (
    value: number,
    previousValue: number,
    allValues: any
) => (value > allValues[otherField] ? value : previousValue);

export const normalizeMoney = (value = 0): string =>
    value ? numeral(value).format('$ 0,0[.]00') : '$ 0';

export const normalizeWithMax = (max: number | string) => (value: string) => {
    const valueAsNumber = getAsNumber(value);
    const maxValue = typeof max === 'string' ? getAsNumber(value) : max;
    return valueAsNumber && maxValue && valueAsNumber >= maxValue
        ? maxValue
        : valueAsNumber;
};

export const normalizeWithMin = (min: number) => (value: number) =>
    value >= min ? value : min;

export const chain = (normalizeFuncts: Function[]) => (value: any) =>
    normalizeFuncts.reduce((acc, fn) => fn(acc), value);

export const normalizeToBool = (value: string) => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
};

export const normalizeToStringFromBool = (value: boolean) => {
    if (typeof value === 'boolean') return value ? 'true' : 'false';
    return value;
};
