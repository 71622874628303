import React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import styled, { withTheme, ThemeProps } from 'styled-components/macro';
import { FontSizeProps, SpaceProps, FontWeightProps } from 'styled-system';
import * as H from 'history';

import { WithLink } from 'components/with-link';
import { Typograph, TypographI18nKeys } from 'components/typograph';
import { TickLight as CheckMark } from 'assets/media/TickLight';

import { normalizeInputNameForE2E } from 'utils/e2e-utilities';
import { Theme } from '@nesto/themes';
import { useAnalyticEvent } from 'utils/hooks';

const StyledTabTitleContainer = styled(Flex)`
    border-bottom: 1px solid ${props => props.theme.colors.mercury};
    padding-right: ${props => props.theme.space[3]}px;
    &:hover {
        background: ${({ theme }) => theme.colors.grey25};
    }
`;

const CursorBox = styled(Flex)`
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    width: 100%;
    align-items: baseline;
`;

type Props = {
    label?: TypographI18nKeys;
    dataTestId?: string;
    trackingLabel?: string;
    children?: any;
    active?: boolean;
    to?: H.LocationDescriptor;
    trackingEventType?: string;
    isComplete?: boolean;
    isCurrent?: boolean;
    onClick?: (event: MouseEvent) => void;
    disabled?: boolean;
};

const TabTitleView = ({
    label,
    trackingLabel,
    children,
    fontSize,
    fontWeight,
    onClick,
    active = false,
    to,
    isComplete,
    isCurrent,
    trackingEventType = 'tab-title',
    theme,
    dataTestId,
    disabled,
    ...styleProps
}: Props &
    FontSizeProps &
    FontWeightProps &
    SpaceProps &
    ThemeProps<Theme>) => {
    const analyticEvent = useAnalyticEvent();

    if (disabled) {
        return (
            <StyledTabTitleContainer
                data-test-id={`tab-title-${dataTestId || label}`}
                alignItems="center"
                {...styleProps}
            >
                <CursorBox onClick={onClick} width="100%" disabled={true}>
                    <Typograph
                        width="100%"
                        py={2}
                        color={theme.fontColor.disabled}
                        tx={children ? undefined : label}
                        fontSize={fontSize || '15px'}
                        fontWeight={isCurrent ? 'bold' : fontWeight}
                    >
                        {children}
                    </Typograph>
                </CursorBox>
            </StyledTabTitleContainer>
        );
    }

    return (
        <WithLink
            to={isComplete || active ? to : undefined}
            onClick={() => {
                analyticEvent('change_section', {
                    main_section_name: trackingEventType,
                    event_location_category: 'navigation',
                    info: trackingLabel || label
                });
            }}
        >
            <StyledTabTitleContainer
                data-test-id={`tab-title-${dataTestId || label}`}
                alignItems="center"
                {...styleProps}
            >
                <CursorBox
                    onClick={onClick}
                    width="100%"
                    disabled={!(active || isComplete)}
                >
                    <Typograph
                        width="100%"
                        py={2}
                        color={
                            active || isComplete
                                ? theme.fontColor.default
                                : theme.fontColor.disabled
                        }
                        tx={children ? undefined : label}
                        fontSize={fontSize || '15px'}
                        fontWeight={isCurrent ? 'bold' : fontWeight}
                    >
                        {children}
                    </Typograph>
                </CursorBox>
                {isComplete && (
                    <Box ml={1}>
                        <CheckMark
                            dataTestId={normalizeInputNameForE2E(
                                `label-tick-${label || children}`
                            )}
                            fillColor={theme.brand.accent}
                        />
                    </Box>
                )}
            </StyledTabTitleContainer>
        </WithLink>
    );
};

export const TabTitle = withTheme(TabTitleView);
