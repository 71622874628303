import React, { ChangeEvent } from 'react';
import { Box } from 'reflexbox/styled-components';
import { Field } from 'redux-form';
import { WidthProps, SpaceProps, FontSizeProps } from 'styled-system';
import { RadioSwitch } from 'components/radio-switch';

export type Props = {
    name: string;
    defaultValue?: string;
    selectedValue?: string;
    options: Array<{
        label: string;
        value: string;
        icon?: React.ReactElement<any>;
    }>;
    onChange?: (
        event?: ChangeEvent<any>,
        newValue?: any,
        previousValue?: any
    ) => void;
    preset?: 'DEFAULT' | 'SECONDARY';
};

type StyledProps = SpaceProps & FontSizeProps & WidthProps;

export type RadioSwitchFieldProps = Props & StyledProps;

const RadioSwitchField = ({
    name,
    options,
    defaultValue,
    selectedValue,
    onChange,
    preset = 'DEFAULT',
    ...rest
}: RadioSwitchFieldProps) => (
    <Box mt={[3]} width="100%" {...rest}>
        <Field
            name={name}
            options={options}
            defaultValue={defaultValue}
            selectedValue={selectedValue}
            onChange={onChange}
            component={RadioSwitch as any}
            preset={preset}
        />
    </Box>
);

export { RadioSwitchField };
