import * as React from 'react';
import withSizes, { Sizes } from 'react-sizes';
import { theme } from 'theme';

export type WithSizesProps = {
    isMobile: boolean;
    isDesktopAndGreater: boolean;
    isGtIpadPortrait: boolean;
    windowWidth: number;
    windowHeight: number;
};

const mapSizesToProps = ({ width, height }: Sizes) => ({
    isMobile: width <= +theme.breakpoints[1].replace('px', ''),
    isDesktopAndGreater: width >= 992,
    isGtIpadPortrait: width >= 769,
    windowWidth: width,
    windowHeight: height
});

export const withSizesHoc = <P extends WithSizesProps>(
    ComposedComponent: React.ComponentType<P>
): React.ComponentType<P & WithSizesProps> => {
    const hoc: React.ComponentType<P & WithSizesProps> = (
        props: P & WithSizesProps
    ) => <ComposedComponent {...props} />;

    // @ts-ignore
    return withSizes(mapSizesToProps)(hoc);
};
