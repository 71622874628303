import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components/macro';
import { Flex } from 'reflexbox/styled-components';

type Props = {
    children: any;
    isOpen: boolean;
    [rest: string]: any;
};

const Content = styled(Flex)`
    overflow: hidden;
`;

const PosedContent = posed(Content)({
    closed: { height: 0 },
    open: { height: 'auto' }
});

export const AccordionContent = ({
    isOpen = false,
    children,
    ...rest
}: Props) => (
    <PosedContent
        flexDirection="column"
        pose={isOpen ? 'open' : 'closed'}
        withParent={false}
        {...rest}
    >
        {children}
    </PosedContent>
);
