import React from 'react';
import styled from 'styled-components/macro';

import { theme } from 'theme';
import { TickLight as Tick } from 'assets/media/TickLight';
import { ActiveStates } from 'reducers/sidebar.selectors';
import { navigationState } from 'components/sidebar/sidebar.component';

const SidebarIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    padding: 0px;
    z-index: ${props => props.theme.zIndex.floor};
    border-radius: 100px;
    text-align: center;
`;

const ActiveNavigationIcon = styled(SidebarIcon)`
    background: ${props => props.theme.brand.accent};
    color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.brand.accent};
`;

const UnreachedNavigationIcon = styled(SidebarIcon)`
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.mercury};
    border: 1px solid ${props => props.theme.colors.mercury};
`;

const CompletedNavigationIcon = styled(SidebarIcon)`
    background: ${props => props.theme.brand.accent};
    color: ${props => props.theme.brand.accent};
    border: 1px solid ${props => props.theme.brand.accent};
`;

type NavigationIconProps = {
    children: React.ReactNode;
    activeState: ActiveStates | string;
    className?: string;
} & React.HTMLProps<HTMLElement>;

export const NavigationIcons = ({
    className,
    children,
    activeState
}: NavigationIconProps) =>
    ({
        [navigationState.ACTIVE_STATE]: (
            <ActiveNavigationIcon className={className}>
                {children}
            </ActiveNavigationIcon>
        ),
        [navigationState.UNREACHED_STATE]: (
            <UnreachedNavigationIcon className={className}>
                {children}
            </UnreachedNavigationIcon>
        ),
        [navigationState.COMPLETED_STATE]: (
            <CompletedNavigationIcon className={className}>
                <Tick size={11} fillColor={theme.colors.white} />
            </CompletedNavigationIcon>
        )
    }[activeState] ?? null);
