import { push } from 'connected-react-router';
import qs from 'qs';
import { call, put, select } from 'redux-saga/effects';

import { getFeatureFlagState } from 'components/feature-flagger/hooks';
import { PrettoFeatureFlag } from 'components/get-a-quote/pretto/pretto.types';
import { getAccount } from 'reducers/account.selectors';
import { isLoggedIn } from 'reducers/auth.selectors';
import { selectLocaleRegion } from 'reducers/locale.redux';
import { Actions as mortgageActions } from 'reducers/mortgage-wizards.redux';
import {
    selectFromFormName,
    selectNewMortgageWizard,
    selectRefinanceWizard,
    selectRenewalWizard
} from 'reducers/mortgage-wizards.selectors';
import { selectPrettoForm } from 'reducers/ui.selectors';
import { updateGAQFlow } from 'redux-store/mortgage-wizard/get-a-quote-flow.reducer';
import { onCreateAccount } from 'sagas/account.sagas';
import { quoteTypes } from 'sagas/rates.sagas';
import { log } from 'utils/logging';

export function* onSubmitNewAnonymous() {
    const {
        propertyType,
        ownerOccupied,
        timing,
        propertyValue,
        downPaymentAmount,
        contact
    } = yield select(selectNewMortgageWizard);

    const account = yield select(getAccount);
    const region = yield select(selectLocaleRegion);
    const userContact = contact || account;
    const regionCode =
        (contact && contact.province) || account.region || region;

    const identity = {
        ...userContact,
        province: regionCode,
        region: regionCode,
        cfMortgageType: propertyType,
        cfPropertyValue: propertyValue,
        cfDownPayment: downPaymentAmount,
        cfProvince: regionCode,
        cfOwnerOccupied: ownerOccupied,
        cfTransactionPeriod: timing
    };
    try {
        if (
            !propertyType ||
            ownerOccupied === undefined ||
            !timing ||
            !propertyValue ||
            !downPaymentAmount
        ) {
            return yield put(
                push({
                    pathname: '/getaquote/newmortgage/ownerOccupied'
                })
            );
        }

        yield call(onCreateAccount, { userInfo: identity });

        yield put(mortgageActions.submitNewSuccess());
    } catch (error) {
        log({ error: `submit new get a quote: ${error}` });
        yield put(mortgageActions.submitNewFailure());
    }
}

/**
 * Saga that is triggered whenever one of the get_a_quote flows is submitted (final wizard form step).
 * This calls the API to fetch all the rates to show based on the answers in the flow.
 */
export function* onSubmitNew({ formName }) {
    let selectedNewMortgage = yield select(
        formName ? selectFromFormName(formName) : selectNewMortgageWizard
    );

    const isPrettoFlow = yield getFeatureFlagState<PrettoFeatureFlag>(
        'pretto-gaq'
    );

    if (isPrettoFlow === 'on') {
        const prettoValues = yield select(selectPrettoForm);

        selectedNewMortgage = { ...selectedNewMortgage, ...prettoValues };
    }

    const {
        propertyType,
        ownerOccupied,
        timing,
        propertyValue,
        downPaymentAmount,
        contact,
        acceptanceDate,
        creditScoreQuality
    } = selectedNewMortgage;

    yield put(updateGAQFlow({ ...selectedNewMortgage, formName: formName }));

    const account = yield select(getAccount);
    const region = yield select(selectLocaleRegion);
    const regionCode =
        (contact && contact.province) || account.region || region;

    const userContact = contact || account;
    const postalCode = userContact?.postalCode;
    const identity = {
        ...userContact,
        province: regionCode,
        region: regionCode,
        postalCode,
        cfMortgageType: propertyType,
        cfPropertyValue: propertyValue,
        cfDownPayment: downPaymentAmount,
        cfProvince: regionCode,
        cfOwnerOccupied: ownerOccupied,
        cfTransactionPeriod: timing,
        formName: formName
    };

    try {
        const isUserLoggedIn = yield select(isLoggedIn);
        const isAnonymousRates = yield getFeatureFlagState(
            'anonymous-rates-page'
        );

        if (!isAnonymousRates && !isUserLoggedIn) {
            yield call(onCreateAccount, { userInfo: identity });
        }

        if (
            !propertyType ||
            ownerOccupied === undefined ||
            !timing ||
            !propertyValue ||
            !downPaymentAmount
        ) {
            return yield put(
                push({
                    pathname: '/getaquote/newmortgage/ownerOccupied'
                })
            );
        }

        yield put(
            push({
                pathname: '/rates',
                search: qs.stringify({
                    ownerOccupied,
                    propertyValue,
                    downPaymentAmount,
                    propertyType,
                    timing,
                    regionCode,
                    postalCode,
                    quoteType: quoteTypes.NEW,
                    acceptanceDate,
                    creditScoreQuality
                })
            })
        );

        yield put(mortgageActions.submitNewSuccess());
    } catch (error) {
        log({ error: `submit new get a quote: ${error}` });
        yield put(mortgageActions.submitNewFailure());
    }
}

export function* onSubmitRenewalAnonymous() {
    const {
        ownerOccupied,
        renewalSchedule,
        downPaymentRatio,
        mortgageAmount,
        contact,
        lender,
        lenderOther,
        propertyValue
    } = yield select(selectRenewalWizard);

    const account = yield select(getAccount);
    const region = yield select(selectLocaleRegion);
    const userContact = contact || account;
    const regionCode =
        (contact && contact.province) || account.region || region;

    const identity = {
        ...userContact,
        province: regionCode,
        region: regionCode,
        cfProvince: regionCode,
        cfOwnerOccupied: ownerOccupied,
        cfTransactionPeriod: renewalSchedule,
        cfDownPayment: downPaymentRatio,
        cfBalance: mortgageAmount,
        cfLender: lender,
        cfLenderOther: lenderOther,
        cfPropertyValue: propertyValue
    };

    try {
        if (
            !downPaymentRatio ||
            !renewalSchedule ||
            !mortgageAmount ||
            !propertyValue
        ) {
            return yield put(
                push({
                    pathname: '/getaquote/renewal/ownerOccupied'
                })
            );
        }

        yield call(onCreateAccount, { userInfo: identity });

        yield put(mortgageActions.submitNewSuccess());
    } catch (error) {
        log({ error: `submit new get a quote: ${error}` });
        yield put(mortgageActions.submitNewFailure());
    }
}

export function* onSubmitRenewal({ formName }) {
    let selectedRenewal = yield select(selectRenewalWizard);

    const isPrettoFlow = yield getFeatureFlagState('pretto-gaq');

    if (isPrettoFlow === 'on') {
        const prettoValues = yield select(selectPrettoForm);

        selectedRenewal = { ...selectedRenewal, ...prettoValues };
    }

    const {
        ownerOccupied,
        renewalSchedule,
        renewalScheduleDate,
        downPaymentRatio,
        mortgageAmount,
        contact,
        lender,
        lenderOther,
        propertyValue,
        creditScoreQuality
    } = selectedRenewal;
    const account = yield select(getAccount);
    const region = yield select(selectLocaleRegion);
    const userContact = contact || account;
    const regionCode =
        (contact && contact.province) || (account && account.region) || region;
    const postalCode = userContact?.postalCode;

    const identity = {
        ...userContact,
        province: regionCode,
        region: regionCode,
        postalCode,
        cfProvince: regionCode,
        cfOwnerOccupied: ownerOccupied,
        cfTransactionPeriod: renewalSchedule,
        cfTransactionPeriodDate: renewalScheduleDate,
        cfDownPayment: downPaymentRatio,
        cfBalance: mortgageAmount,
        cfLender: lender,
        cfLenderOther: lenderOther,
        cfPropertyValue: propertyValue,
        formName: formName
    };

    yield put(updateGAQFlow({ ...selectedRenewal, formName: formName }));

    try {
        const isUserLoggedIn = yield select(isLoggedIn);
        const isAnonymousRates = yield getFeatureFlagState(
            'anonymous-rates-page'
        );
        if (!isAnonymousRates && !isUserLoggedIn) {
            yield call(onCreateAccount, { userInfo: identity });
        }
        if (
            !downPaymentRatio ||
            !renewalSchedule ||
            // we don't need this as the value of `renewalSchedule`
            // is setted by onChange | submit of renewalSchedule step
            // !renewalScheduleDate ||
            !mortgageAmount ||
            !propertyValue
        ) {
            return yield put(
                push({
                    pathname: '/getaquote/renewal/ownerOccupied'
                })
            );
        }

        // use will be redirected to rates page with the query params
        yield put(
            push({
                pathname: '/rates',
                search: qs.stringify({
                    ownerOccupied,
                    mortgageAmount,
                    propertyValue,
                    renewalSchedule,
                    renewalScheduleDate,
                    downPaymentRatio,
                    regionCode,
                    lender,
                    lenderOther: lender === 'OTHER' ? lenderOther : undefined,
                    postalCode,
                    quoteType: quoteTypes.RENEWAL,
                    creditScoreQuality
                })
            })
        );
        yield put(mortgageActions.submitRenewalSuccess());
    } catch (error) {
        log({ error: `submit renewal get a quote: ${error}` });
        yield put(mortgageActions.submitRenewalFailure());
    }
}

export function* onSubmitRefinanceAnonymous() {
    const {
        ownerOccupied,
        mortgageAmount,
        contact,
        lender,
        lenderOther,
        propertyValue,
        additionalFundAmount
    } = yield select(selectRefinanceWizard);

    const account = yield select(getAccount);
    const region = yield select(selectLocaleRegion);
    const userContact = contact || account;
    const regionCode =
        (contact && contact.province) || account.region || region;

    const identity = {
        ...userContact,
        province: regionCode,
        region: regionCode,
        cfProvince: regionCode,
        cfOwnerOccupied: ownerOccupied,
        cfBalance: mortgageAmount,
        cfAdditionalFund: additionalFundAmount,
        cfLender: lender,
        cfLenderOther: lenderOther,
        cfPropertyValue: propertyValue
    };

    try {
        if (!mortgageAmount || !propertyValue) {
            return yield put(
                push({
                    pathname: '/getaquote/refinance/ownerOccupied'
                })
            );
        }

        yield call(onCreateAccount, { userInfo: identity });

        yield put(mortgageActions.submitNewSuccess());
    } catch (error) {
        log({ error: `submit new get a quote: ${error}` });
        yield put(mortgageActions.submitNewFailure());
    }
}

export function* onSubmitRefinance({ formName }) {
    let selectedRefinance = yield select(selectRefinanceWizard);
    const isPrettoFlow = yield getFeatureFlagState('pretto-gaq');

    if (isPrettoFlow === 'on') {
        const prettoValues = yield select(selectPrettoForm);

        selectedRefinance = { ...selectedRefinance, ...prettoValues };
    }

    const {
        ownerOccupied,
        mortgageAmount,
        contact,
        lender,
        lenderOther,
        propertyValue,
        additionalFundAmount,
        creditScoreQuality
    } = selectedRefinance;
    const account = yield select(getAccount);
    const region = yield select(selectLocaleRegion);
    const userContact = contact || account;
    const regionCode =
        (contact && contact.province) || (account && account.region) || region;
    const postalCode = userContact?.postalCode;

    yield put(updateGAQFlow({ ...selectedRefinance, formName: formName }));

    const identity = {
        ...userContact,
        province: regionCode,
        region: regionCode,
        postalCode,
        cfProvince: regionCode,
        cfOwnerOccupied: ownerOccupied,
        cfBalance: mortgageAmount,
        cfAdditionalFund: additionalFundAmount,
        cfLender: lender,
        cfLenderOther: lenderOther,
        cfPropertyValue: propertyValue,
        formName: formName
    };

    try {
        const isUserLoggedIn = yield select(isLoggedIn);
        const isAnonymousRates = yield getFeatureFlagState(
            'anonymous-rates-page'
        );

        if (!isAnonymousRates && !isUserLoggedIn) {
            yield call(onCreateAccount, { userInfo: identity });
        }

        if (!mortgageAmount || !propertyValue) {
            return yield put(
                push({
                    pathname: '/getaquote/refinance/ownerOccupied'
                })
            );
        }

        yield put(
            push({
                pathname: '/rates',
                search: qs.stringify({
                    ownerOccupied,
                    mortgageAmount,
                    propertyValue,
                    regionCode,
                    lender,
                    additionalFundAmount,
                    lenderOther: lender === 'OTHER' ? lenderOther : undefined,
                    postalCode,
                    quoteType: quoteTypes.REFINANCE,
                    creditScoreQuality
                })
            })
        );

        yield put(mortgageActions.submitRefinanceSuccess());
    } catch (error) {
        log({ error: `submit refinance get a quote: ${error}` });
        yield put(mortgageActions.submitRefinanceFailure());
    }
}

export function* crunchingCompleted({ search }: { search: any }) {
    yield put(
        push({
            pathname: '/rates',
            search: qs.stringify(search)
        })
    );
}
