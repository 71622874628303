import React from 'react';
import { Box, Flex } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';

import { TabTitle } from 'components/tab-title/tab-title.component';
import { Accordion, AccordionContent } from 'components/accordion';
import { formatUrlsWithQueries } from 'utils/helpers';
import { connect } from 'react-redux';
import {
    memorizedGetAllApplicationApplicants,
    getDocumentsStep,
    memorizedGetAllApplicantsStepCounts,
    memorizedGetDocumentsStepCountsBadge,
    getIsAdditionalDocEnabled
} from 'reducers/sidebar.selectors';
import { Applicant as ApplicantBase } from 'models/application/Application';
import { getCurrentApplicationApplicantId } from 'reducers/application.selectors';
import { DocumentStep, ApplicantDocumentsCounts } from 'models/documents';
import { Typograph } from 'components/typograph';

type Props = {
    toggleSidebar?: () => void;
};

type Applicant = ApplicantBase & { isMainApplicant?: boolean };

type StateProps = {
    applicants: Applicant[];
    documentsStep: number;
    currentApplicantId: number;
    isAdditionalDocumentEnabled: boolean;
    criticalApplicantsStepCounts: {
        [applicantId: string]: ApplicantDocumentsCounts;
    };
    criticalStepCounts: number;
    additionalApplicantsStepCounts: {
        [applicantId: string]: ApplicantDocumentsCounts;
    };
    additionalStepCounts: number;
};

const formatTrackingLabel = (
    isMainApplicant: boolean | undefined,
    label: string
) => `${isMainApplicant ? 'main-applicant' : 'co-applicant'} ${label}`;

const badgeCount = (docCount: ApplicantDocumentsCounts): number =>
    docCount.emptyCount + docCount.brokerUnapprovedCount;

const DocumentsBadgeCounter = styled.div`
    background-color: ${props => props.theme.colors.textBlack};
    width: 15px;
    height: 15px;
    border-radius: 15px;

    color: ${props => props.theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes[0]};
    font-weight: 600;
    letter-spacing: 0.65px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const DocumentsNavigationRoutesView = ({
    toggleSidebar,
    applicants,
    documentsStep,
    currentApplicantId,
    isAdditionalDocumentEnabled,
    criticalApplicantsStepCounts,
    additionalApplicantsStepCounts,
    criticalStepCounts,
    additionalStepCounts
}: Props & StateProps) => (
    <Accordion initialState={`step.${documentsStep}`}>
        {(isOpenAt, toggleAt) => (
            <>
                {/* Critical Document */}
                <TabTitle
                    dataTestId="criticalDocuments"
                    trackingEventType="documents"
                    trackingLabel="criticalDocuments"
                    active
                    isCurrent={documentsStep === DocumentStep.Critical}
                    isComplete={criticalStepCounts === 0}
                    to={formatUrlsWithQueries('/application/documents', {
                        step: 1,
                        sectionName: 'application.documents'
                    })}
                >
                    <Flex alignItems="center">
                        <Box>
                            <Typograph tx="sidebar.criticalDocuments" />
                        </Box>
                        {isOpenAt !== 'step.1' && !!criticalStepCounts && (
                            <Flex ml="auto">
                                <DocumentsBadgeCounter>
                                    {criticalStepCounts}
                                </DocumentsBadgeCounter>
                            </Flex>
                        )}
                    </Flex>
                </TabTitle>
                {/* Accoridan Critical Document */}
                <AccordionContent isOpen={isOpenAt === 'step.1'}>
                    {(applicants || []).map(applicant => {
                        const appDocCount = badgeCount(
                            criticalApplicantsStepCounts[applicant.applicantId]
                        );

                        return (
                            <Box
                                key={`criticalDocuments.${applicant.applicantId}`}
                                data-test-id={`criticalDocuments-sidebar`}
                            >
                                <TabTitle
                                    dataTestId={`criticalDocuments.${applicant.applicantId}`}
                                    trackingEventType="documents"
                                    trackingLabel={formatTrackingLabel(
                                        applicant.isMainApplicant,
                                        'criticalDocuments'
                                    )}
                                    ml={[3]}
                                    fontSize={[0]}
                                    active
                                    isCurrent={
                                        currentApplicantId ===
                                        applicant.applicantId
                                    }
                                    isComplete={
                                        criticalApplicantsStepCounts[
                                            applicant.applicantId
                                        ].allReceived
                                    }
                                    to={formatUrlsWithQueries(
                                        '/application/documents',
                                        {
                                            step: 1,
                                            applicant: applicant.applicantId,
                                            sectionName: 'application.documents'
                                        }
                                    )}
                                    onClick={toggleSidebar}
                                >
                                    <Flex alignItems="center">
                                        <Box>
                                            {applicant.firstName}{' '}
                                            {applicant.lastName}
                                        </Box>
                                        {!!appDocCount && (
                                            <Flex ml="auto">
                                                <DocumentsBadgeCounter>
                                                    {appDocCount}
                                                </DocumentsBadgeCounter>
                                            </Flex>
                                        )}
                                    </Flex>
                                </TabTitle>
                            </Box>
                        );
                    })}
                </AccordionContent>
                {/* Additional Document */}
                <TabTitle
                    dataTestId="additionalDocuments"
                    trackingEventType="documents"
                    trackingLabel="additionalDocuments"
                    active={isAdditionalDocumentEnabled}
                    isCurrent={documentsStep === DocumentStep.Additional}
                    isComplete={additionalStepCounts === 0}
                    to={
                        isAdditionalDocumentEnabled
                            ? formatUrlsWithQueries('/application/documents', {
                                  step: 2,
                                  sectionName: 'application.documents'
                              })
                            : undefined
                    }
                >
                    <Flex alignItems="center">
                        <Box>
                            <Typograph tx="sidebar.additionalDocuments" />
                        </Box>
                        {isAdditionalDocumentEnabled &&
                            isOpenAt !== 'step.2' &&
                            !!additionalStepCounts && (
                                <Flex ml="auto">
                                    <DocumentsBadgeCounter>
                                        {additionalStepCounts}
                                    </DocumentsBadgeCounter>
                                </Flex>
                            )}
                    </Flex>
                </TabTitle>
                {/* Accoridan Additional Document */}
                <AccordionContent isOpen={isOpenAt === 'step.2'}>
                    {(applicants || []).map(applicant => {
                        const appDocCount = badgeCount(
                            additionalApplicantsStepCounts[
                                applicant.applicantId
                            ]
                        );

                        return (
                            <Box
                                key={`additionalDocuments.${applicant.applicantId}`}
                                data-test-id={`additionalDocuments-sidebar-${applicant.applicantId}`}
                            >
                                <TabTitle
                                    dataTestId={`additionalDocuments.${applicant.applicantId}`}
                                    trackingEventType="documents"
                                    trackingLabel={formatTrackingLabel(
                                        applicant.isMainApplicant,
                                        'additionalDocuments'
                                    )}
                                    ml={[3]}
                                    fontSize={[0]}
                                    active
                                    isCurrent={
                                        currentApplicantId ===
                                        applicant.applicantId
                                    }
                                    isComplete={
                                        additionalApplicantsStepCounts[
                                            applicant.applicantId
                                        ].allReceived
                                    }
                                    to={formatUrlsWithQueries(
                                        '/application/documents',
                                        {
                                            step: 2,
                                            applicant: applicant.applicantId,
                                            sectionName: 'application.documents'
                                        }
                                    )}
                                    onClick={toggleSidebar}
                                >
                                    <Flex alignItems="center">
                                        <Box>
                                            {applicant.firstName}{' '}
                                            {applicant.lastName}
                                        </Box>
                                        {!!appDocCount && (
                                            <Flex ml="auto">
                                                <DocumentsBadgeCounter>
                                                    {appDocCount}
                                                </DocumentsBadgeCounter>
                                            </Flex>
                                        )}
                                    </Flex>
                                </TabTitle>
                            </Box>
                        );
                    })}
                </AccordionContent>
            </>
        )}
    </Accordion>
);

export const DocumentsNavigationRoutes = connect<StateProps>((state: any) => ({
    applicants: memorizedGetAllApplicationApplicants(state),
    documentsStep: getDocumentsStep(state),
    currentApplicantId: getCurrentApplicationApplicantId(state),
    isAdditionalDocumentEnabled: getIsAdditionalDocEnabled(state),
    criticalApplicantsStepCounts: memorizedGetAllApplicantsStepCounts(
        state,
        DocumentStep.Critical
    ),
    criticalStepCounts: memorizedGetDocumentsStepCountsBadge(
        state,
        DocumentStep.Critical
    ),
    additionalApplicantsStepCounts: memorizedGetAllApplicantsStepCounts(
        state,
        DocumentStep.Additional
    ),
    additionalStepCounts: memorizedGetDocumentsStepCountsBadge(
        state,
        DocumentStep.Additional
    )
}))(DocumentsNavigationRoutesView);
