import React from 'react';
import styled from 'styled-components';
import { Card, CardProps } from '@nesto/design-system';
import { Icon } from '@nesto/design-system/v2';

import { Box, Flex } from 'reflexbox/styled-components';
import {
    ModalBase,
    ModalProps
} from 'components/modal-app/modal-base.component';

const CardWrapper = styled(Box)`
    ${({ theme }) => theme.mediaQueries.small} {
        margin: 0 auto;
    }
    ${({ theme }) => theme.mediaQueries.medium} {
        display: flex;
    }
`;

const CloseButtonWrapper = styled(Flex)`
    position: absolute;
    top: ${({ theme }) => theme.space[3]}px;
    right: ${({ theme }) => theme.space[3]}px;
    justify-content: center;
    align-items: center;

    display: none;
    ${({ theme }) => theme.mediaQueries.small} {
        display: flex;
    }
`;

type Props = {
    id?: string;
    thin?: boolean;
    large?: boolean;
    cardProps?: CardProps;
};

export const ModalCard = ({
    id,
    children,
    showClose = true,
    cardProps,
    thin,
    large,
    ...modalProps
}: ModalProps & Props) => (
    <ModalBase showClose={showClose} {...modalProps}>
        {({ close }: { close: () => void }) => (
            <CardWrapper
                p={2}
                width="100%"
                height="100%"
                maxHeight={['calc(100vh - 75px)', 'unset']}
                overflow="scroll"
                data-test-id={`modal-card-${id}`}
            >
                <Flex
                    width={['100%', '100%', thin ? 425 : large ? 700 : 620]}
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    margin="0 auto"
                >
                    <Card width="100%" {...cardProps}>
                        {showClose && (
                            <CloseButtonWrapper>
                                <Icon
                                    name="close"
                                    size="24px"
                                    onClick={() => {
                                        close();
                                    }}
                                    css={{
                                        cursor: 'pointer'
                                    }}
                                />
                            </CloseButtonWrapper>
                        )}
                        {typeof children === 'function'
                            ? children({ close })
                            : children}
                    </Card>
                </Flex>
            </CardWrapper>
        )}
    </ModalBase>
);
