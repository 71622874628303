import * as R from 'ramda';
import { getFormValues } from 'redux-form';
import qs from 'qs';

import {
    DocumentType,
    DocumentTypes,
    DocumentList,
    DocumentCategory,
    DocumentSubCategory,
    mapDocumentCategoryToState,
    mapDocumentSubCategoryToState,
    DocumentEntityType,
    DocumentStep,
    BoostEmailInfo
} from 'models/documents';
import { ReduxState } from './documents.redux';
import { formatAddress } from 'utils/documents-helpers';
import { DOCUMENT_CREATE_FORM } from 'constants/appConstants';
import {
    getCurrentApplicationApplicantId,
    getActiveApplicationId
} from './application.selectors';
import { getCurrentSearchParams } from './account.selectors';

export const getDocuments = ({ documents }: ReduxState): DocumentList =>
    documents.documents;

export const getLastBoostSentInfo = ({
    documents
}: ReduxState): BoostEmailInfo => documents.lastBoostSentInfo;

export const getDocumentTypes = ({ documents }: ReduxState): DocumentTypes =>
    documents.types;

export const isLoading = ({ documents }: ReduxState): boolean =>
    documents.loading;

export const hasLoaded = ({ documents }: ReduxState): boolean =>
    documents.loaded;

export const getActiveApplicantId = ({
    documents
}: ReduxState): number | null => documents?.activeApplicant;

export const getHasFileTooBigError = ({ documents }: ReduxState): boolean =>
    documents.hasFileTooBigError;

const categoriesKeyStateMap = {
    [DocumentCategory.financials]: {
        undefined: {
            path: ['allAssets'],
            documentEntityType: DocumentEntityType.asset
        }, // <- grouped documents list subcategory is removed, squashed in undefined property by ramda
        [DocumentSubCategory.assets]: {
            path: ['allAssets'],
            documentEntityType: DocumentEntityType.asset
        },
        [DocumentSubCategory.liabilities]: {
            path: ['allAssets'],
            documentEntityType: DocumentEntityType.asset
        },
        [DocumentSubCategory.otherFinancials]: {
            path: ['allAssets'],
            documentEntityType: DocumentEntityType.asset
        }
    },
    [DocumentCategory.incomes]: {
        [DocumentSubCategory.incomes]: {
            path: ['income', 'employments'],
            documentEntityType: DocumentEntityType.incomeEmployment
        },
        [DocumentSubCategory.employments]: {
            path: ['income', 'employments'],
            documentEntityType: DocumentEntityType.incomeEmployment
        },
        [DocumentSubCategory.otherIncomes]: {
            path: ['income', 'others'],
            documentEntityType: DocumentEntityType.incomeOther
        },
        [DocumentSubCategory.pensions]: {
            path: ['income', 'employments'],
            documentEntityType: DocumentEntityType.incomePension
        },
        [DocumentSubCategory.selfEmployed]: {
            path: ['income', 'employments'],
            documentEntityType: DocumentEntityType.incomeEmployment
        }
    },
    [DocumentCategory.properties]: {
        [DocumentSubCategory.properties]: {
            path: ['properties'],
            documentEntityType: DocumentEntityType.property
        },
        [DocumentSubCategory.otherProperty]: {
            path: ['properties'],
            documentEntityType: DocumentEntityType.property
        },
        [DocumentSubCategory.otherProperties]: {
            path: ['properties'],
            documentEntityType: DocumentEntityType.property
        },
        [DocumentSubCategory.subjectProperty]: {
            path: ['property'],
            documentEntityType: DocumentEntityType.property
        }
    }
};

// @deprecated
const keysMapState = {
    ...mapDocumentCategoryToState,
    ...mapDocumentSubCategoryToState
};

// @deprecated
const entityPathIndexed = R.map(
    (key: string) => keysMapState[key] || R.toLower(key)
) as (path: string[]) => string[];

// Avoid returning full object if path is an empty array.
const entityIndexedPath = (path: string[]): any => {
    // @ts-ignore
    return R.pathOr(
        { path, documentEntityType: null },
        path.length === 0 ? ['~~NotExistsKey~~'] : path,
        categoriesKeyStateMap
    );
};

const applicationStateObject = R.pathOr([], ['application', 'applications']);

const applicantStateObject = (applicantId: number) =>
    R.pipe(applicationStateObject, R.pathOr({}, ['applicants', applicantId]));

const entityStateObject = (entityPath: string[]) =>
    R.pipe(R.pathOr([], entityPathIndexed(entityPath)));

const applicationProperty = R.pipe(
    applicationStateObject,
    R.pathOr({}, ['property'])
);

export const getDocumentEntity = (
    { category, subcategory, entityId },
    state: any
) => {
    const applicationId = getActiveApplicationId(state);
    const applicantId = getCurrentApplicationApplicantId(state);

    const { path, documentEntityType } = entityIndexedPath([
        category,
        subcategory
    ]);

    let zePath: any[] = [];
    if (subcategory === DocumentSubCategory.subjectProperty) {
        zePath = path;
    } else if (Array.isArray(path)) {
        zePath = ['applicants', applicantId, ...path];
    }

    const entities = R.path(
        ['application', 'applications', applicationId, ...zePath],
        state
    );

    let documentEntity: any;
    if (entities) {
        switch (category) {
            case DocumentCategory.financials:
                documentEntity = R.find(
                    R.propEq('id', +entityId),
                    entities as any
                );
                break;
            case DocumentCategory.incomes:
                documentEntity = R.find(
                    R.propEq('id', +entityId),
                    entities as any
                );
                break;
            case DocumentCategory.properties:
                documentEntity =
                    subcategory === DocumentSubCategory.subjectProperty
                        ? entities
                        : R.find(R.propEq('id', +entityId), entities as any);
                break;
        }
    }

    return { path, documentEntity, documentEntityType };
};

type ToSelectArgFunc = (obj: any) => any | undefined;
const toSelectOption = (
    getValue: ToSelectArgFunc,
    getLabel: ToSelectArgFunc
) => item => ({
    value: getValue(item),
    label: getLabel(item)
});

const getOptions = (
    getValue: ToSelectArgFunc,
    getLabel: ToSelectArgFunc,
    entityPath: string[],
    state: ReduxState
) => {
    const applicantId = getCurrentApplicationApplicantId(state);

    return R.pipe(
        applicantStateObject(applicantId),
        entityStateObject(entityPath),
        R.map(toSelectOption(getValue, getLabel))
    )(state);
};

const incomesEmploymentOptions = (
    category: string,
    subcategory: string,
    state: any
) => {
    const entityPath: string[] = [category, subcategory];
    const getValue = R.prop('id');
    const getLabel = R.path<string>(['employer', 'name']);

    return [
        {
            label: category,
            options: getOptions(getValue, getLabel, entityPath, state)
        }
    ];
};

const propertiesOptions = (category: string, state: any) => {
    const entityPath: string[] = [category];
    const getValue = R.prop('id');
    const getLabel = R.pipe(R.pathOr({}, ['address']), formatAddress);

    return [
        {
            label: 'Subject Property',
            options: [
                {
                    value: '0',
                    label: R.pipe(applicationProperty, getLabel)(state)
                }
            ]
        },
        {
            label: category,
            options: getOptions(getValue, getLabel, entityPath, state)
        }
    ];
};

export const getEntityOptionValues = (
    category: DocumentCategory | null,
    subcategory: DocumentSubCategory | null,
    state: ReduxState
): any[] => {
    if (category === null || subcategory === null) {
        return [];
    }

    if (
        category === DocumentCategory.incomes &&
        subcategory === DocumentSubCategory.employments
    ) {
        return incomesEmploymentOptions(category, subcategory, state);
    }
    if (category === DocumentCategory.properties) {
        return propertiesOptions(category, state);
    }

    return [];
};

export const getCreateDocumentValues = (
    state: ReduxState
): {
    documentType: DocumentType | undefined;
    year: number | undefined;
    entityId: number;
} => {
    const {
        document: { documentType, year, entityId }
    } =
        (getFormValues(DOCUMENT_CREATE_FORM)(state) as any) ||
        ({
            document: { documentType: undefined, year: undefined, entityId: 0 }
        } as any);

    return { documentType, year, entityId };
};

export const getFilePreviewUrl = (state: ReduxState): string | undefined =>
    R.path(['documents', 'filePreview', 'url'], state);

export const getDocumentPreviewUrl = (state: ReduxState): string | undefined =>
    R.path(['documents', 'documentPreview', 'url'], state);

export const getIsLoadingPreview = ({ documents }: ReduxState): boolean =>
    documents.loadingPreview;

export const getRouteDocumentStep = (state: ReduxState): DocumentStep => {
    const search: any = getCurrentSearchParams(state);
    // @ts-gnore
    const params: any = qs.parse(search, {
        ignoreQueryPrefix: true
    });

    return (params.step as DocumentStep) || DocumentStep.All;
};

export const getIsMissingDocuments = (
    state,
    step: DocumentStep = DocumentStep.All
): boolean | undefined =>
    R.path(['documents', 'counts', step, 'missingDocuments'], state);

export const getIsModalShown = (state: ReduxState): boolean =>
    R.path(['documents', 'isModalShown'], state) || false;

export const getSelectedDocumentIndex = (state: ReduxState): number | null =>
    state.documents.documentIndex;
