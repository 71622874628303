import { idPostfix } from './address-helpers';

export const normalizeInputNameForE2E = (name: string): string => {
    if (name === undefined && window.config.env !== 'production') {
        // tslint:disable-next-line no-console
        console.warn(
            `name must be not equal undefined normalizeInputNameForE2E: ${name}`
        );
    }

    return (
        name &&
        name
            .toString()
            .trim()
            .split('.')
            .filter(i => !i.includes(idPostfix))
            .join('_')
    );
};
