import numeral from 'numeral';
import {
    startOfMonth,
    isBefore,
    subYears,
    addYears,
    endOfDay,
    startOfDay,
    differenceInCalendarDays
} from 'date-fns';
import * as R from 'ramda';

import { getNestedProperty } from 'utils/reducerUtils';
import { isString } from 'components/form/utils';
import { TypographI18nKeys } from 'components/typograph';
import {
    MINIMUM_NEW_FINANCING_REQUIRED,
    MINIMUM_REFI_FINANCING_REQUIRED,
    PROVINCES,
    US_STATES
} from 'constants/appConstants';
import { TargetProperty } from 'models/application/Application';

export function convertCurrencyToNumber(value: string | number) {
    if (typeof value === 'number') return value;
    const cleanString = value.replace('$ ', '').replace(/,/g, '');
    return parseInt(cleanString, 10);
}

// eslint-disable-next-line no-useless-escape
const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// 1 uppercase letter, 1 lowercase letter, 12 character minimum length
const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,32})/;

// Phone number regex
// 223-456-7890
// (223) 456-7890
// 223 456 7890
// 223.456.7890
export const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?[0-9]\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

// Postal code regex (see https://regex101.com/r/Dthk4g/1)
// Canadian: H1H 1H1, H1H1H1, h0h0h0
const postalCodeRegex = /^(?:(?:[ABCEGHJKLMNPRSTVXY][0-9][A-Z] ?[0-9][A-Z][0-9]))$/i;
const shortPostalCodeRegex = /^(?:(?:[ABCEGHJKLMNPRSTVXY][0-9][A-Z]))$/i;

export const isPostalCode = (value: string): string | undefined =>
    value && !postalCodeRegex.test(value)
        ? 'validation.errors.invalidPostalCode'
        : undefined;

export const isShortPostalCode = (value: string) =>
    value && !shortPostalCodeRegex.test(value)
        ? 'validation.errors.invalidPostalCode'
        : undefined;

export const postalCodeRequiredLength = (postalCodeLength: number) => (
    value: string
) => {
    return value.length !== postalCodeLength
        ? 'validation.errors.invalidPostalCode'
        : undefined;
};

export const internationalPostalCode = (minLengthNumber: number) => (
    value: string
): TypographI18nKeys | undefined =>
    value && value.length < minLengthNumber
        ? 'validation.errors.invalidPostalCode'
        : undefined;

// US: 90210
// US Extended: 90210-1121
const zipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const isZipCode = (value: string): string | undefined =>
    value && !zipCodeRegex.test(value)
        ? 'validation.errors.invalidZipCode'
        : undefined;

export const isRequired = (value: any): TypographI18nKeys | undefined =>
    value || value === false ? undefined : 'validation.errors.isRequired';

export const isValidNumber = (value: any): TypographI18nKeys | undefined =>
    value || value > 0 ? undefined : 'validation.errors.isRequired';

export const isRequiredCheckedBox = (
    value: any
): TypographI18nKeys | undefined =>
    value === true ? undefined : 'validation.errors.isRequired';

export const maxLength = (maxLengthNumber: number) => (
    value: string
): TypographI18nKeys | undefined =>
    value && value.length > maxLengthNumber
        ? 'validation.errors.tooLong'
        : undefined;

export const min = (minNumber: number) => (value: string) => {
    const num = +value;

    return num < minNumber ? 'validation.errors.min' : undefined;
};

export const max = (maxNumber: number, years: undefined | boolean = true) => (
    value: number
): TypographI18nKeys | undefined => {
    const translation = years
        ? 'validation.errors.numberOfYears'
        : 'validation.errors.massiveNumber';
    return value >= maxNumber ? translation : undefined;
};

export const amortizationLeftYears = (maxAmortizationYears: number) => (
    value: number
): TypographI18nKeys | undefined => {
    if (!maxAmortizationYears || !value) {
        return undefined;
    }

    // if the amortization value is bigger than required amortization
    if (value > maxAmortizationYears) {
        return 'validation.errors.amortization.amortization';
    }
};

export const amortizationLeft = (
    maxAmortizationYears: number,
    startingDate: string,
    dateType: 'LAST_CLOSING_DATE' | 'PURCHASE_DATE'
) => (value: number): TypographI18nKeys | undefined => {
    if (
        !startingDate ||
        !isString(startingDate) ||
        !maxAmortizationYears ||
        !value
    ) {
        return undefined;
    }

    const [year, month, day] = startingDate.split('-');

    // we check for purchase date or last closing date
    // and we add the amortization years
    const initialDate = addYears(new Date(`${year}/${month}/${day}`), value);

    const currentDate = new Date();

    const translation =
        dateType === 'PURCHASE_DATE'
            ? 'validation.errors.amortization.purchaseDate'
            : 'validation.errors.amortization.lastClosingDate';

    return !isBefore(currentDate, initialDate) ? translation : undefined;
};

export const numberIsRequired = (value: any): TypographI18nKeys | undefined =>
    typeof value === 'number' ? undefined : 'validation.errors.isRequired';

export const isEmail = (value: string): TypographI18nKeys | undefined =>
    value && !emailRegexp.test(value)
        ? 'validation.errors.invalidEmail'
        : undefined;

export const emailExcluded = (excluded: string[]) => (
    value: string
): TypographI18nKeys | undefined =>
    excluded.includes(value) ? 'validation.errors.emailExcluded' : undefined;

export const isIn = (allowed: any[]) => (value: any): string | undefined =>
    allowed.includes(value) ? undefined : 'Required';

export const minDownPayment = (min: number) => (
    value: any
): TypographI18nKeys | undefined =>
    value && convertCurrencyToNumber(value) < min
        ? 'validation.errors.isRequired'
        : undefined;

export const maxDownPayment = (maxDownpaymentnumber: number) => (
    value: any
): TypographI18nKeys | undefined =>
    value && convertCurrencyToNumber(value) > maxDownpaymentnumber
        ? 'validation.errors.isRequired'
        : undefined;

export const ValidDownPaymentAmount = (maxDownpaymentnumber: number) => (
    value: any
): TypographI18nKeys | undefined =>
    value && convertCurrencyToNumber(value) > maxDownpaymentnumber
        ? 'validation.errors.validDownPayment'
        : undefined;

export const emailsMatch = (
    value: string,
    allValues: { email?: string; contact?: { email: string } }
): TypographI18nKeys | undefined => {
    const email = allValues?.contact?.email ?? allValues?.email;

    return value !== email ? 'validation.errors.emailsMustMatch' : undefined;
};

export const passwordsMatch = (
    value: string,
    allValues: { password: string }
): TypographI18nKeys | undefined =>
    value !== allValues.password
        ? 'validation.errors.passwordsMustMatch'
        : undefined;

export const passwordComplexity = (
    value: string
): TypographI18nKeys | undefined =>
    value && !strongPasswordRegex.test(value)
        ? 'validation.errors.passwordsTooWeak'
        : undefined;

// used in many choice component where we have an array of values
// and we check that at least one is valid
export const oneIsRequired = (
    _: any,
    allValues: { [s: string]: any },
    { formValue }: { formValue: string }
): TypographI18nKeys | undefined => {
    type FieldsValues = { [s: string]: boolean } | undefined;
    const fieldsValues: FieldsValues = getNestedProperty(formValue, allValues);

    if (!fieldsValues) {
        return 'validation.errors.missingAtLeastOne';
    }

    const values = Object.keys(fieldsValues)
        .map(key => fieldsValues[key])
        .filter(i => i === true);

    return values.length ? undefined : 'validation.errors.missingAtLeastOne';
};

export const isYearNotRequired = (
    value: number
): TypographI18nKeys | undefined =>
    value
        ? value.toString().length !== 4 || value < 1500
            ? 'validation.errors.isNotValidYear'
            : undefined
        : undefined;

export const isYear = (value: number): TypographI18nKeys | undefined =>
    value.toString().length !== 4 || value < 1500
        ? 'validation.errors.isNotValidYear'
        : undefined;

const getAsNumber = (value: string): number =>
    numeral(value.replace(/[^\d]/g, '')).value();

export const normalizeToPastYear = (value: string) => {
    if (!value) return undefined;

    const currentDate = new Date();
    const onlyNums = typeof value !== 'number' ? getAsNumber(value) : value;
    if (isYear(onlyNums)) {
        return 'validation.errors.isNotValidYear';
    }
    if (onlyNums > currentDate.getFullYear()) {
        return 'validation.errors.isNotValidYear';
    }
    return undefined;
};

type PairedValues = {
    amount: number;
    frequency: string;
};

export const isRequiredPaired = (
    _: any,
    allValues: { [s: string]: any },
    __: any,
    name: string
): TypographI18nKeys | undefined => {
    if (allValues && (name.includes('amount') || name.includes('frequency'))) {
        const pairedFieldName = name
            .split('.')
            .filter(item => !['amount', 'frequency'].includes(item))
            .join('.');

        const pairedValues = getNestedProperty(pairedFieldName, allValues) as
            | PairedValues
            | undefined;

        if (!pairedValues || pairedValues === undefined) return undefined;
        const { amount, frequency } = pairedValues;
        if ((amount && frequency) || (!amount && !frequency)) return undefined;

        if (amount && !frequency && `${pairedFieldName}.frequency` === name) {
            return 'validation.errors.missingFrequency';
        }
        if (!amount && frequency && `${pairedFieldName}.amount` === name) {
            return 'validation.errors.missingAmount';
        }
    }

    return undefined;
};

export const isPhoneNumber = (value: string): string | undefined =>
    value && !phoneNumberRegex.test(value)
        ? 'validation.errors.invalidPhone'
        : undefined;

export const realPhoneNumberValidator = (
    isPhoneNumberValid: boolean | undefined
) => {
    if (!isPhoneNumberValid) {
        return 'validation.errors.invalidPhone';
    }
};

export const isNotMoreThan = (otherFieldName: string) => (
    value,
    allValues
): string | undefined =>
    allValues[otherFieldName] >= value + 1
        ? 'validation.errors.cannotBelessThanTotalDownpaymentAmount'
        : undefined;

export const isValidDate = date =>
    date && typeof date === 'object'
        ? 'validation.errors.invalidDate'
        : undefined;

export const isTodayOrFutureDate = (value: any) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    // User entered value as start of the day
    // parsing of date strings with the Date constructor
    // is strongly discouraged due to browser differences and inconsistencies.
    // date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
    const [year, month, day] = value.split('-');
    const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

    const now = new Date();
    // Set as start of the day
    const today = new Date(now.setHours(0, 0, 0, 0));

    return valueAsDate < today
        ? 'validation.errors.dateShouldBeFuture'
        : undefined;
};

export const isTodayOrPastDate = (value: any) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    // User entered value as start of the day
    // parsing of date strings with the Date constructor
    // is strongly discouraged due to browser differences and inconsistencies.
    // date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
    const [year, month, day] = value.split('-');
    const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

    const currentDate = endOfDay(new Date());

    return isBefore(valueAsDate, currentDate)
        ? undefined
        : 'validation.errors.dateCannotBeInTheFuture';
};

export const isBeforeDate = (value: any) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    // User entered value as start of the day
    // parsing of date strings with the Date constructor
    // is strongly discouraged due to browser differences and inconsistencies.
    // date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
    const [year, month, day] = value.split('-');
    const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

    const now = new Date();
    // Set as start of the day
    const today = new Date(now.setHours(0, 0, 0, 0));

    return isBefore(valueAsDate, today)
        ? undefined
        : 'validation.errors.invalidDate';
};

export const isBeforeDefinedDate = (dateToCompare: number | Date) => (
    value: any
) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    // User entered value as start of the day
    // parsing of date strings with the Date constructor
    // is strongly discouraged due to browser differences and inconsistencies.
    // date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
    const [year, month, day] = value.split('-');
    const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

    const compareDate = endOfDay(dateToCompare);

    return isBefore(valueAsDate, compareDate)
        ? undefined
        : 'validation.errors.invalidDate';
};

export const dateExceedsOneYear = (startDate: number | Date) => (
    value: any
) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    // User entered value as start of the day
    // parsing of date strings with the Date constructor
    // is strongly discouraged due to browser differences and inconsistencies.
    // date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
    const [year, month, day] = value.split('-');
    const valueAsDate = startOfDay(new Date(+year, +month - 1, +day));

    return Math.abs(differenceInCalendarDays(startDate, valueAsDate)) <= 365
        ? undefined
        : 'validation.errors.dateExceedsOneYear';
};

export const isCurrentMonthOrFuture = (value: any) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    const currentDate = startOfMonth(Date.now());

    const [year, month, day] = value.split('-');
    const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

    const isBefore = valueAsDate < currentDate;

    return isBefore ? 'validation.errors.dateShouldBeFuture' : undefined;
};

export const minOneMonthRequired = ({
    years,
    months
}: {
    years: string;
    months: string;
}) => values => {
    const errors: Record<string, string> = {};

    const hasOneFieldFill = !!(values[years] || values[months]);

    if (
        !hasOneFieldFill ||
        (hasOneFieldFill &&
            (+values[years] || 0) + (+values[months] || 0) === 0)
    ) {
        const message = 'validation.errors.monthOrYearRequired';
        errors[years] = message;
        errors[months] = message;
    }

    return errors;
};

export const isValidMortgageAmount = (
    value: number,
    allValues: TargetProperty
): TypographI18nKeys | undefined =>
    value >= allValues.estimatedPropertyValue
        ? 'application.refinanceMortgageInvalidValue'
        : undefined;

export const validateRefinanceAmount = (
    mortgageBalance: number,
    additionalAmount: number,
    propertyValue: number
): boolean => {
    if (!mortgageBalance || !propertyValue) return false;

    const refinancePercentage =
        ((mortgageBalance + additionalAmount) / propertyValue) * 100;

    return refinancePercentage <= 80;
};

export const maxFirstNameLength = maxLength(64);
export const maxLastNameLength = maxLength(64);
export const maxEmailLength = maxLength(128);
export const atLeast18 = isBeforeDefinedDate(subYears(Date.now(), 18));

export const isValidMaturityDate = isBeforeDefinedDate(
    addYears(Date.now(), 10)
);

const pluckValues = R.pluck('value');
const validProvinces = pluckValues(PROVINCES);
const validStates = pluckValues(US_STATES);

export const isValidStateOrProvince = country => value => {
    const validValues = country === 'US' ? validStates : validProvinces;

    return validValues.includes(value)
        ? undefined
        : country === 'US'
        ? 'validation.errors.isNotValidState'
        : 'validation.errors.isNotValidProvince';
};

export const validateNewMortgage = (
    propertyValue: number,
    downPaymentAmount: number
) => propertyValue - downPaymentAmount >= MINIMUM_NEW_FINANCING_REQUIRED;

export const validateRenewalMinimum = (mortgageAmount: number) =>
    mortgageAmount >= MINIMUM_REFI_FINANCING_REQUIRED;

export const validateRefinanceMinimum = (
    mortgageAmount: number,
    additionalFundAmount: number
) =>
    !R.isNil(additionalFundAmount)
        ? mortgageAmount + additionalFundAmount >=
          MINIMUM_REFI_FINANCING_REQUIRED
        : true;

export const validPurchaseAmount = (downPaymentValue: number) => (
    value: any
): TypographI18nKeys | undefined => {
    if (!value) return;
    const formattedValue = convertCurrencyToNumber(value);

    if (formattedValue <= downPaymentValue) {
        return 'validation.errors.validPurchasePrice';
    }

    if (!validateNewMortgage(value, downPaymentValue)) {
        return 'application.refinanceMortgageInvalidValue';
    }
};

export const isValidMortgageRenewalMinimum = (value: number) =>
    !validateRenewalMinimum(value)
        ? 'application.refinanceMortgageInvalidValue'
        : undefined;

export const isValidMortgageRefinanceMinimum = (
    value: number,
    formValues: TargetProperty
) =>
    !validateRefinanceMinimum(value, formValues.additionalFundAmount)
        ? 'application.refinanceMortgageInvalidValue'
        : undefined;
