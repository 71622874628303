import React, { ReactNode, useState } from 'react';
import { AppearanceTypes, Id } from './toast';
import { ToastContainer } from './toast-container';
import { ToastController } from './toast-controller';
import { generateUEID, NOOP } from './utils';

type Options = {
    appearance: AppearanceTypes;
    autoDismiss?: boolean;
    id?: Id;
    onDismiss?: () => any;
    title?: string;
    delay?: number;
};
type AddFn = (
    content: ReactNode,
    options: Options,
    callback?: any
) => Id | undefined;
type RemoveFn = (id: Id, cb?: any) => void;
type UpdateFn = any;

type Context = {
    add: AddFn;
    remove: RemoveFn;
    removeAll: () => void;
    update: UpdateFn;
    toasts?: any[] /*Toast*/;
};

interface Toast extends Options {
    title?: string | undefined;
    content: React.ReactNode;
    id: Id;
}
export const ToastContext = React.createContext<Context>({
    add: () => {
        return '';
    },
    remove: () => {
        return;
    },
    removeAll: () => {
        return;
    },
    update: () => {
        return;
    },
    toasts: []
});
const { Consumer, Provider } = ToastContext;

export const ToastProvider = ({
    autoDismiss = false,
    autoDismissTimeout = 5000,
    placement = 'top-right',
    // TODO when adding custom Toast component and animation
    // components defaultComponents,
    // transitionDuration = 220,
    children
}: any) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const has = id => {
        if (!toasts.length) {
            return false;
        }

        return toasts.some(t => t.id === id);
    };

    const onDismiss = (id: Id, cb: any = NOOP) => () => {
        cb(id);
        remove(id);
    };

    const add = (content: ReactNode, options: Options, cb: any = NOOP) => {
        const id = options?.id || generateUEID();
        const callback = () => cb(id);

        // bail if a toast exists with this ID
        if (has(id)) {
            return;
        }

        const newToast = { content, id, ...options };
        setToasts(prevToasts => [...prevToasts, newToast]);

        callback();

        return id;
    };
    const remove = (id: Id, cb: any = NOOP) => {
        const callback = () => cb(id);

        // bail if NO toasts exists with this ID
        if (!has(id)) {
            return;
        }

        // BUG don't know why but `toasts` with autoDismiss only contain 1 item
        // so newToasts will always return an empty array `[]` when removed auto

        const newToasts = toasts.filter(t => t.id !== id);

        setToasts(newToasts);
        callback();
    };
    const removeAll = () => {
        if (!toasts.length) {
            return;
        }

        toasts.forEach(toast => remove(toast.id));
        return;
    };
    const update = () => {
        return;
    };

    const hasToasts = Boolean(toasts.length);

    return (
        <Provider value={{ add, remove, removeAll, update }}>
            {hasToasts ? (
                <ToastContainer placement={placement} hasToasts={hasToasts}>
                    {toasts.map(
                        ({
                            appearance,
                            autoDismiss: toastAutoDismiss,
                            content,
                            id,
                            delay = autoDismissTimeout,
                            onDismiss: toastOnDimiss,
                            ...unknownConsumerProps
                        }) => (
                            <ToastController
                                appearance={appearance}
                                autoDismiss={
                                    toastAutoDismiss !== undefined
                                        ? toastAutoDismiss
                                        : autoDismiss
                                }
                                autoDismissTimeout={delay}
                                // TODO
                                // component={Toast}
                                key={id}
                                onDismiss={onDismiss(id, toastOnDimiss)}
                                placement={placement}
                                // TODO state of animation
                                // transitionDuration={transitionDuration}
                                // transitionState={transitionState}
                                {...unknownConsumerProps}
                            >
                                {content}
                            </ToastController>
                        )
                    )}
                </ToastContainer>
            ) : (
                <ToastContainer placement={placement} hasToasts={hasToasts} />
            )}

            {children}
        </Provider>
    );
};

export const ToastConsumer = ({
    children
}: {
    children: (context: Context) => React.ReactNode;
}) => <Consumer>{context => children(context)}</Consumer>;
