import React from 'react';
import { configs, ConfigProps, Partner } from '@nesto/themes';

type ContextProps = {
    partner: Partner;
    config: ConfigProps;
};

export const PartnerContext = React.createContext<ContextProps>({
    partner: 'nesto',
    config: configs.nesto
});
export const PartnerProvider = PartnerContext.Provider;
export const PartnerConsumer = PartnerContext.Consumer;
