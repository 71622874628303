export const Routes = {
    root: '/',
    dev: '/dev',
    signUp: '/signup',
    login: '/login',
    brokersLogin: '/brokers-login',
    forgotPassword: '/forgot',
    changePassword: '/change-password',
    rates: '/rates',
    dashboard: '/dashboard',
    settings: '/settings',
    accountDeletion: '/settings/account-deletion',
    accountDeleted: '/account-deleted',
    application: '/application/:section',
    broker: '/broker',
    frenchCanadaRoot: '/ca-fr',
    frenchCanadaSignup: '/ca-fr/signup',
    frenchCanadaLogin: '/ca-fr/login',
    frenchCanadaForgotPassword: '/ca-fr/forgot',
    frenchCanadaChangePassword: '/ca-fr/change-password',
    frenchCanadaGetAQuoteRoot: '/ca-fr/getaquote',
    frenchCanadaGetAQuoteRefiRenew: '/ca-fr/getaquote/refi-renew',
    frenchCanadaGetAQuoteRenewal: '/ca-fr/getaquote/renewal/:step',
    frenchCanadaGetAQuoteRefinance: '/ca-fr/getaquote/refinance/:step',
    frenchCanadaGetAQuoteNewMortgage: '/ca-fr/getaquote/newmortgage/:step',
    frenchCanadaGetAQuoteUnified: '/ca-fr/getaquote-unified',
    frenchCanadaGetAQuoteRenewalUnified: '/ca-fr/getaquote/renewal-unified',
    frenchCanadaGetAQuoteRenewalUnifiedSf:
        '/ca-fr/getaquote-sf/renewal-unified',
    frenchCanadaGetAQuoteRefinanceUnified: '/ca-fr/getaquote/refinance-unified',
    frenchCanadaGetAQuoteRefinanceUnifiedSf:
        '/ca-fr/getaquote-sf/refinance-unified',
    frenchCanadaGetAQuoteNewMortgageUnified:
        '/ca-fr/getaquote/newmortgage-unified',
    frenchCanadaGetAQuoteNewMortgageUnifiedSf:
        '/ca-fr/getaquote-sf/newmortgage-unified',
    getAQuoteRoot: '/getaquote',
    getAQuoteRefiRenew: '/getaquote/refi-renew',
    getAQuoteRenewal: '/getaquote/renewal/:step',
    getAQuoteRenewalMobile: '/getaquote-mobile/renewal/:step',
    getAQuoteRefinance: '/getaquote/refinance/:step',
    getAQuoteRefinanceMobile: '/getaquote-mobile/refinance/:step',
    getAQuoteNewMortgage: '/getaquote/newmortgage/:step',
    getAQuoteNewMortgageMobile: '/getaquote-mobile/newmortgage/:step',
    getAQuoteUnified: '/getaquote-unified',
    getAQuoteRenewalUnified: '/getaquote/renewal-unified',
    getAQuoteRenewalUnifiedSf: '/getaquote-sf/renewal-unified',
    getAQuoteRefinanceUnified: '/getaquote/refinance-unified',
    getAQuoteRefinanceUnifiedSF: '/getaquote-sf/refinance-unified',
    getAQuoteNewMortgageUnified: '/getaquote/newmortgage-unified',
    getAQuoteNewMortgageUnifiedSf: '/getaquote-sf/newmortgage-unified',
    applicationEmploymentSituation: '/application/employment-situation',
    applicationEmploymenSelectCurrent: '/application/employment-select-current',
    applicationCommissionConsent: '/application/commission-consent',
    applicationLeadReferral: '/application/lead-referral',
    applicationBankruptcy: '/application/bankruptcy',
    applicationHasBankruptcy: '/application/has-bankruptcy',
    applicationApplicantInfo: '/application/applicant-info',
    applicationRegisteredAddress: '/application/registered-address',
    applicationOtherProperty: '/application/other-property',
    applicationPropertiesCount: '/application/number-of-properties',
    applicationHasTooManyProperties: '/application/has-too-many-properties',
    applicationOtherIncome: '/application/other-income',
    applicationBankingDetails: '/application/banking-details',
    applicationCoApplicant: '/application/co-applicant',
    applicationAssetsDownpayment: '/application/assets-downpayment',
    applicationExistingPropertyDownpayment:
        '/application/existing-property-downpayment',
    applicationTargetProperty: '/application/target-property',
    creditScore: '/application/credit-score',
    creditScoreWarning: '/application/credit-score-warning',
    closingDocuments: '/application/documents/closing',
    closingFilogix: '/application/documents/closing/filogix',
    closingCurrentMortgage: '/application/documents/closing/current-mortgage',
    closingBorrowers: '/application/documents/closing/borrowers',
    closingAddress: '/application/documents/closing/address',
    closingMortgageDetails: '/application/documents/closing/mortgage-details',
    applicationDocuments: '/application/documents',
    applicationRenewingProperty: '/application/renewing-property',
    applicationSubmit: '/application/submit',
    applicationSubmitted: '/application/submitted',
    applicationCreateCoapplicant: '/application/create-coapplicant',
    applicationSelection: '/application/selection',
    // Product Selection
    productSelection: '/product-selection/:section',
    productSelectionSelection: '/product-selection/selection',
    productSelectionProgression: '/product-selection/progression',
    applicationDetails: '/product-selection/application-details',
    rateInformation: '/product-selection/rate-information',
    rateInformationProducts: '/product-selection/rate-information-products',
    amortization: '/product-selection/amortization',
    homeEquity: '/product-selection/home-equity',
    homeEquityProduct: '/product-selection/home-equity-product',
    restrictions: '/product-selection/restrictions',
    frequency: '/product-selection/frequency',
    termInformation: '/product-selection/term-information',
    prepaymentInformation: '/product-selection/prepayment-information',
    productSelectionSummary: '/product-selection/summary'
};

type ValueOf<T> = T[keyof T];
export type RoutesType = ValueOf<typeof Routes>;
