import { useEffect, useState } from 'react';

const cachedCss: string[] = [];

export function useCss(href: string) {
    // Keeping track of css loaded and error state
    const [state, setState] = useState({
        loaded: false,
        error: false
    });

    useEffect(() => {
        // If cachedCss array already includes href that means another instance ...
        // ... of this hook already loaded this css, so no need to load again.
        if (cachedCss.includes(href)) {
            setState({
                loaded: true,
                error: false
            });
        } else {
            cachedCss.push(href);

            // Create css
            const css = document.createElement('link');
            css.href = href;
            css.rel = 'stylesheet';

            // Css event listener callbacks for load and error
            const onCssLoad = () => {
                setState({
                    loaded: true,
                    error: false
                });
            };

            const onCssError = () => {
                // Remove from cachedCss we can try loading again
                const index = cachedCss.indexOf(href);
                if (index >= 0) cachedCss.splice(index, 1);
                css.remove();

                setState({
                    loaded: true,
                    error: true
                });
            };

            css.addEventListener('load', onCssLoad);
            css.addEventListener('error', onCssError);

            // Add css to document body
            document.body.appendChild(css);

            // Remove event listeners on cleanup
            return () => {
                css.removeEventListener('load', onCssLoad);
                css.removeEventListener('error', onCssError);
            };
        }
    }, [href]); // Only re-run effect if css href changes

    return [state.loaded, state.error];
}
