import React, { forwardRef, useRef, useState, useEffect } from 'react';
import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { ResponsiveValue, BorderProps } from 'styled-system';

import { Assign, ForwardRef } from 'types/components';

import {
    gutter,
    toastStates,
    TransitionState,
    AppearanceTypes,
    Placement
} from './toast';
import { BoxProps } from './box-props';

interface ToastElementOwnProps extends BorderProps {
    placement: Placement;
    transitionDuration?: number;
    transitionState?: TransitionState;
    appearance?: ResponsiveValue<Appearance>;
}

export type ToastElementProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    ToastElementOwnProps
>;

type Appearance = AppearanceTypes;

export const ToastElementStyled = styled<any>(Box)`
    display: flex;
    margin-bottom: 8px;
    min-height: 70px;
    transition: ${({ transitionDuration }) =>
        `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms`};
    ${({ placement, transitionState }) =>
        toastStates(placement)[transitionState]}

    background-color: #FFFFFF;
    box-shadow: 0 3px 8px rgb(0 0 0 / 18%);
    border-radius: 8px;
    max-width: 300px;
`;

type ToastElementType = BoxProps & ToastElementProps;

export const ToastElement: ForwardRef<
    HTMLDivElement,
    ToastElementProps
> = forwardRef(
    (
        {
            appearance = 'info',
            placement,
            transitionDuration,
            transitionState,
            ...rest
        }: ToastElementType,
        ref
    ) => {
        const [height, setHeight] = useState<number | string>('auto');
        const elementRef = useRef<any>(null);

        useEffect(() => {
            if (transitionState === 'entered') {
                const el = elementRef?.current;
                setHeight(el.offsetHeight + gutter);
            }
            if (transitionState === 'exiting') {
                setHeight(0);
            }
        }, [transitionState]);

        return (
            <Box ref={elementRef} style={{ height }}>
                <ToastElementStyled
                    appearance={appearance}
                    placement={placement}
                    transitionDuration={transitionDuration}
                    transitionState={transitionState}
                    ref={ref}
                    {...rest}
                />
            </Box>
        );
    }
);
