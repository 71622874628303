import * as R from 'ramda';

import { ApplicationState } from 'models/application/Application';
import { PartialRecord } from 'types/models';
import { AccountTypes } from './account.redux';
import { ApplicationsTypes } from './application.redux';

type ApplicationStateMetada = {
    advisorBooked?: boolean;
    states: PartialRecord<ApplicationState, Date>;
    salesforceQueueOutboundCallNow?: boolean;
    salesforceQueueComfortableSpeakingFrench?: boolean;
    salesforceApplicationSubmittedByAgent?: boolean;
};

/**
 * HOW data is structured
 * ```
 * applications: {
 *     [application.id]: {
 *         advisorBooked: true,
 *         states: {
 *             CREATED: new Date('2020-02-21T16:50:11Z'),
 *             SUBMITTED: new Date('2020-02-18T16:50:11Z')
 *             // [application.applicationState]: lastseen // firstSeenAt or last seen?
 *         }
 *     }
 * }
 * ```
 */
export type ReduxState = {
    applications: Record<number, ApplicationStateMetada>;
};

export const Types = {
    ADVISOR_BOOKED_AT: 'ADVISOR_BOOKED_AT',
    APPLICANT_IN_SALES_FORCE_QUEUE_REQUEST:
        'APPLICANT_IN_SALES_FORCE_QUEUE_REQUEST',
    APPLICANT_IN_SALES_FORCE_QUEUE: 'APPLICANT_IN_SALES_FORCE_QUEUE',
    APPLICANT_IN_SALES_FORCE_SPEAKS_FRENCH:
        'APPLICANT_IN_SALES_FORCE_SPEAKS_FRENCH',
    APPLICANT_IN_SALES_FORCE_SPEAKS_FRENCH_REQUEST:
        'APPLICANT_IN_SALES_FORCE_SPEAKS_FRENCH_REQUEST',
    APPLICATION_SUBMITTED_BY_AGENT: 'APPLICATION_SUBMITTED_BY_AGENT',
    APPLICATION_SUBMITTED_BY_AGENT_REQUEST:
        'APPLICATION_SUBMITTED_BY_AGENT_REQUEST'
};

const INITIAL_STATE: ReduxState = {
    applications: {}
};

const transform = state => data => ({
    [data.id]: {
        ...state[data.id],
        states: {
            [data.applicationState]: new Date()
        }
    }
});

const merge = R.mergeDeepRight; // mergeDeepRight for lastseen or mergeDeepLeft for first seen

const fromObject = R.curry((state, data) =>
    // @ts-ignore
    R.pipe(transform(state), merge(state))(data)
);

const fromCollection = R.curry((state, data) =>
    R.pipe(R.map(transform(state)), R.mergeAll, merge(state))(data)
);

/**
 * Transform data to redux state.applications
 *
 */
const toApplications = R.curry((state, data): ReduxState['applications'] =>
    R.ifElse(R.is(Array), fromCollection(state), fromObject(state))(data)
);

export const reducer = (state = INITIAL_STATE, action: any): ReduxState => {
    switch (action.type) {
        case ApplicationsTypes.CREATE_APPLICATIONS_SUCCESS:
            return {
                ...state,
                // @ts-ignore
                applications: toApplications(
                    state.applications,
                    action.applications || []
                )
            };
        case ApplicationsTypes.FETCH_APPLICATIONS_SUCCESS:
            return {
                ...state,
                // @ts-ignore
                applications: toApplications([], action.applications || [])
            };
        case Types.ADVISOR_BOOKED_AT:
            return {
                ...state,
                applications: {
                    ...state.applications,
                    [action.applicationId]: {
                        ...state.applications[action.applicationId],
                        advisorBooked: action.value
                    }
                }
            };
        case Types.APPLICANT_IN_SALES_FORCE_QUEUE:
            return {
                ...state,
                applications: {
                    ...state.applications,
                    [action.applicationId]: {
                        ...state.applications[action.applicationId],
                        salesforceQueueOutboundCallNow: action.addToQueue
                    }
                }
            };
        case Types.APPLICATION_SUBMITTED_BY_AGENT:
            return {
                ...state,
                applications: {
                    ...state.applications,
                    [action.applicationId]: {
                        ...state.applications[action.applicationId],
                        salesforceApplicationSubmittedByAgent:
                            action.salesforceApplicationSubmittedByAgent
                    }
                }
            };
        case Types.APPLICANT_IN_SALES_FORCE_SPEAKS_FRENCH:
            return {
                ...state,
                applications: {
                    ...state.applications,
                    [action.applicationId]: {
                        ...state.applications[action.applicationId],
                        salesforceQueueComfortableSpeakingFrench:
                            action.salesforceQueueComfortableSpeakingFrench
                    }
                }
            };
        case AccountTypes.LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const Actions = {
    setAdvisorBooked: (applicationId: number) => ({
        type: Types.ADVISOR_BOOKED_AT,
        applicationId
    }),
    getApplicantSalesForceQueue: (addToQueue: boolean) => ({
        type: Types.APPLICANT_IN_SALES_FORCE_QUEUE_REQUEST,
        addToQueue
    }),
    setApplicantSalesForceQueue: (
        applicationId: number,
        addToQueue: boolean
    ) => ({
        type: Types.APPLICANT_IN_SALES_FORCE_QUEUE,
        applicationId,
        addToQueue
    }),
    setApplicantComfortableSpeakingFrench: (
        applicationId: number,
        salesforceQueueComfortableSpeakingFrench: boolean
    ) => ({
        type: Types.APPLICANT_IN_SALES_FORCE_SPEAKS_FRENCH,
        applicationId,
        salesforceQueueComfortableSpeakingFrench
    }),
    setApplicantComfortableSpeakingFrenchRequest: (
        salesforceQueueComfortableSpeakingFrench: boolean
    ) => ({
        type: Types.APPLICANT_IN_SALES_FORCE_SPEAKS_FRENCH_REQUEST,
        salesforceQueueComfortableSpeakingFrench
    }),
    getApplicationSubmittedByAgent: (
        salesforceApplicationSubmittedByAgent: boolean
    ) => ({
        type: Types.APPLICATION_SUBMITTED_BY_AGENT_REQUEST,
        salesforceApplicationSubmittedByAgent
    }),
    setApplicationSubmittedByAgent: (
        applicationId: number,
        salesforceApplicationSubmittedByAgent: boolean
    ) => ({
        type: Types.APPLICATION_SUBMITTED_BY_AGENT,
        applicationId,
        salesforceApplicationSubmittedByAgent
    })
};
