export const clearServicingPortalStorage = (): void => {
    window.localStorage.removeItem('portal:token');

    for (let i = 0; i < localStorage.length; i++) {
        if (window.localStorage.key(i)?.startsWith('@@auth0spajs@@::')) {
            window.localStorage.removeItem(
                localStorage.key(i) || '@@auth0spajs@@::'
            );
        }
    }
};
