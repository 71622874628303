import { AccountTypes } from './account.redux';
import { createReducer, createActions } from 'reduxsauce';
import { Timings } from 'types/application/application';

export type DashboardStatuses =
    | 'QUOTE'
    | 'APPLICATION'
    | 'EXISTING_RENEWAL_QUOTE';

type Quote = {
    transactionType: string;
    interestLevel: Timings;
    propertyValue: number;
    mortgageAmount: number;
    downPaymentAmount: number;
    additionalFundAmount: number;
    insuranceRequired: boolean;
    insurancePremiumAmount: number;
    insurancePremiumTaxAmount: number;
};

export type DashboardState = {
    loaded: boolean;
    loading: boolean;
    dashboardState: {
        quote: Quote | null;
        application: {
            type: string;
            state: any;
        } | null;
        state: DashboardStatuses;
    };
    error: any;
};

export type ReduxState = {
    dashboard: DashboardState;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions(
    {
        fetchDashboardRequest: null,
        fetchDashboardSuccess: ['dashboardState'],
        fetchDashboardFailure: ['error']
    },
    {
        prefix: '[DASHBOARD]'
    }
);

export { Creators as Actions };

export const DashboardTypes = Types;

export const INITIAL_STATE: DashboardState = {
    loaded: false,
    loading: false,
    error: null,
    dashboardState: {
        quote: null,
        application: null,
        state: 'QUOTE'
    }
};

const fetchDashboardRequest = (state: DashboardState) => ({
    ...state,
    loading: true
});

const fetchDashboardSuccess = (
    state: DashboardState,
    { dashboardState }: any
) => ({
    ...state,
    error: null,
    loaded: true,
    loading: false,
    dashboardState
});

const fetchDashboardFailure = (
    state: DashboardState,
    { error }: { error: any }
) => ({
    ...state,
    loading: false,
    loaded: false,
    error
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.FETCH_DASHBOARD_REQUEST]: fetchDashboardRequest,
    [Types.FETCH_DASHBOARD_SUCCESS]: fetchDashboardSuccess,
    [Types.FETCH_DASHBOARD_FAILURE]: fetchDashboardFailure,
    [AccountTypes.LOGOUT]: () => INITIAL_STATE
});
