import React from 'react';

export class MobileHeightHack extends React.Component {
    // this lovely hack exists for this reason
    // https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html
    componentDidMount() {
        window.addEventListener('resize', this.updateAppHeight);
        this.updateAppHeight();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateAppHeight);
    }

    updateAppHeight = () => {
        const doc = document.documentElement;
        doc && doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    render() {
        return this.props.children;
    }
}
