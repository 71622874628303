import nanoid from 'nanoid';

const localStoageKey = 'lcdClientKey';

export const getUserKey = (): string => {
    const { localStorage } = window;
    const key = localStorage.getItem(localStoageKey);

    if (key) return key;

    // 36 characters long as thats the longest legnth the BE supports
    // the longer the length the lower the probability of the collision
    const uuid = nanoid(36);
    localStorage.setItem(localStoageKey, uuid);

    return uuid;
};
