import styled from 'styled-components/macro';
import { HeightProps } from 'styled-system';
import {
    space,
    width,
    height,
    maxWidth,
    SpaceProps,
    WidthProps,
    MaxHeightProps,
    MaxWidthProps
} from 'styled-system';
import { getIcon, IconTypes } from './icons';

type Props = {
    src: string;
    onMouseEnter?: any;
    onMouseLeave?: any;
    alt?: string;
    icon?: IconTypes;
    onClick?: any;
    style?: any;
};

const Icon: any = styled.img`
    display: block;
    // 👇 https://stackoverflow.com/questions/37609642/why-does-flexbox-stretch-my-image
    align-self: center;
    ${space};
    ${width};
    ${height}
    ${maxWidth};
`;

const IconComponent = (
    props: Props &
        SpaceProps &
        WidthProps &
        HeightProps &
        MaxHeightProps &
        MaxWidthProps
) => {
    if (props.icon) {
        const SVGIcon = getIcon(props.icon);
        return <SVGIcon />;
    }
    return (
        <Icon
            data-test-id={props.alt || 'image'}
            {...props}
            src={props.src}
            alt={props.alt}
        />
    );
};

export { IconComponent };
