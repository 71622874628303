import { Routes } from 'app-root/routing/routes';
import {
    APP_TRACKER_DEFAULT_VALUES,
    APP_TRACKER_STATE_VALUES,
    DefaultAppTrackerProps,
    UNDER_REVIEW_MESSAGES,
    UNDER_REVIEW_MESSAGES_NO_DOCS
} from 'constants/applicationTrackerConstants';
import { LocationDescriptorObject } from 'history';
import {
    Application,
    Address,
    Property,
    ApplicationState
} from 'models/application/Application';

export const isMortgage = (application: Application) =>
    application.applicationState === 'FUNDED';

export const isMortgageFundedByNesto = (application: Application) =>
    isMortgage(application) &&
    application.mortgage?.product?.lenderName === 'Nesto';

export const applicationHasAddress = (application: Application) =>
    !!application.property.address.streetNumber;

export const getApplicationIdentifier = (
    application?: Application
): string | number => {
    if (!application) {
        return '';
    }

    if (applicationHasAddress(application)) {
        return formatAddress(application.property.address);
    }

    return application.id;
};

export const formatPropertyAddress = (property: Property): string => {
    const propertyAddress = {
        ...property.address,
        state: '',
        country: '',
        addressLine: ''
    };

    return formatAddress(propertyAddress);
};

export const formatAddress = (address?: Address): string => {
    if (!address) return '';

    const returns: string[] = [];
    let unit = '';

    if (address.unit?.length) {
        unit = `${address.unit}-`;
    }

    returns.push(`${unit}${address.streetNumber} ${address.street}`);
    returns.push(address.city);

    return returns.join(', ');
};

export const formatAddressWithState = (address?: Address): string => {
    if (!address) return '';

    return `${formatAddress(address)}, ${address.stateCode}`;
};

export const getApplicationTrackerValues = (
    applicationState: ApplicationState | undefined,
    allDocumentsCount: number,
    receivedDocumentsCount: number,
    missingDocuments: boolean
) => {
    if (!applicationState) return {};

    let trackerContent =
        APP_TRACKER_DEFAULT_VALUES[APP_TRACKER_STATE_VALUES[applicationState]];

    // UNDER REVIEW has multiple message depending on the state and if it has documents or not
    if (APP_TRACKER_STATE_VALUES[applicationState] === 'underReview') {
        trackerContent = {
            ...trackerContent,
            text: missingDocuments
                ? UNDER_REVIEW_MESSAGES[applicationState]
                : UNDER_REVIEW_MESSAGES_NO_DOCS[applicationState],
            documents: missingDocuments
                ? allDocumentsCount - receivedDocumentsCount
                : undefined,
            totalDocs: missingDocuments ? allDocumentsCount : undefined
        };
    }

    if (APP_TRACKER_STATE_VALUES[applicationState] === 'submitted') {
        trackerContent = {
            ...trackerContent,
            text: missingDocuments
                ? 'applicationSubmitted.message'
                : 'applicationSubmitted.messageNoDocs',
            documents: missingDocuments
                ? allDocumentsCount - receivedDocumentsCount
                : undefined,
            totalDocs: missingDocuments ? allDocumentsCount : undefined
        };
    }

    if (APP_TRACKER_STATE_VALUES[applicationState] === 'submittedLender') {
        trackerContent = {
            ...trackerContent,
            text: missingDocuments
                ? 'submittedToLender.message'
                : 'submittedToLender.messageNoDocs',
            documents: missingDocuments
                ? allDocumentsCount - receivedDocumentsCount
                : undefined,
            totalDocs: missingDocuments ? allDocumentsCount : undefined
        };
    }

    if (APP_TRACKER_STATE_VALUES[applicationState] === 'documents') {
        trackerContent = {
            ...trackerContent,
            text: missingDocuments
                ? 'remainingDocument.message'
                : 'remainingDocument.messageNoDocs',
            documents: missingDocuments
                ? allDocumentsCount - receivedDocumentsCount
                : undefined,
            totalDocs: missingDocuments ? allDocumentsCount : undefined
        };
    }

    if (['NOTARY_ALERTED', 'COMPLETE'].includes(applicationState)) {
        trackerContent = {
            ...trackerContent,
            link:
                '/application/documents?step=1&sectionName=application.documents',
            linkText: 'applicationUnderReview.link'
        };
    }

    if (['EXPIRED', 'CLOSED'].includes(applicationState)) {
        trackerContent = {
            ...trackerContent,
            messageTitle: 'applicationClosed.title',
            text: 'applicationClosed.message'
        };
    }

    return {
        ...APP_TRACKER_DEFAULT_VALUES,
        [APP_TRACKER_STATE_VALUES[applicationState]]: trackerContent
    };
};

export const getActiveTrackerValue = (
    applicationState: ApplicationState | undefined
): DefaultAppTrackerProps | undefined =>
    APP_TRACKER_DEFAULT_VALUES[
        APP_TRACKER_STATE_VALUES[applicationState || '']
    ];

export const getRedirectUrl = (url: string): LocationDescriptorObject => {
    let location: LocationDescriptorObject = {
        pathname: Routes.root
    };

    const attemptLocation: LocationDescriptorObject = JSON.parse(url) ?? {};

    const redirectToRoot = [
        Routes.closingDocuments,
        Routes.applicationSelection,
        Routes.getAQuoteRoot,
        '/prequalify',
        Routes.rates,
        Routes.login
    ];

    const shouldRedirectToRoot = redirectToRoot.some(route =>
        attemptLocation.pathname?.startsWith(route)
    );
    if (!shouldRedirectToRoot) {
        location = attemptLocation;
    }

    return location;
};
