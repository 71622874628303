import {
    CallCenterHours,
    AdvisorOperatingHours,
    FEDERAL_HOLIDAYS,
    NestoDate,
    PROVINCIAL_HOLIDAYS,
    RegionMapTimeZones,
    HOLIDAYS_OPEN_DATES,
    HOLIDAYS_CLOSED_DATES
} from 'constants/appConstants';
import {
    getHours,
    isFriday,
    addDays,
    isWeekend,
    setMinutes,
    setSeconds
} from 'date-fns';
import { Region } from 'types/application/application';
import { convertTimeZone } from 'utils/localization-utils';
export const getUserHours = (region: Region) => {
    const userDateConverted = convertTimeZone(
        new Date(),
        RegionMapTimeZones[region]
    );
    const currentHour = getHours(userDateConverted);
    return { userDateConverted, currentHour };
};

export const isWithinOpenHours = (region = Region.QC): boolean => {
    const { userDateConverted, currentHour } = getUserHours(region);

    const isHoliday = HOLIDAYS_OPEN_DATES.some(holiday =>
        compareDates(userDateConverted, holiday)
    );

    if (isHoliday) {
        const isOpen = currentHour >= 9 && currentHour < 17;
        return isOpen;
    }

    const isHolidayClosure = HOLIDAYS_CLOSED_DATES.some(holiday =>
        compareDates(userDateConverted, holiday)
    );

    if (isHolidayClosure) {
        return false;
    }

    const isOpen = isWeekend(userDateConverted)
        ? currentHour >= CallCenterHours.WeekendStart &&
          currentHour < CallCenterHours.WeekendEnd
        : currentHour >= CallCenterHours.WeekdayStart &&
          currentHour < CallCenterHours.WeekdayEnd;

    return isOpen;
};

export const getPreferredCallDay = (region = Region.QC) => {
    const { userDateConverted, currentHour } = getUserHours(region);

    const today = setMinutes(setSeconds(new Date(), 0), 0);

    const tomorrow = addDays(today, 1);

    if (isWeekend(userDateConverted)) {
        // if it is after the start hour we return tomorrow
        if (currentHour >= CallCenterHours.WeekendStart && currentHour < 24) {
            return tomorrow;
        }
    }

    // if it is after the start hour we return tomorrow
    if (currentHour >= CallCenterHours.WeekdayStart && currentHour < 24) {
        return tomorrow;
    }

    // by default we return same day
    return today;
};

export const mortgageBuilderQueueOpen = (region: Region) => {
    const { userDateConverted } = getUserHours(region);
    const isHoliday = isHolidayFromLocation(region);

    const isDateWeekend = isWeekend(userDateConverted);

    const offHours = isOffHours(region);

    return !isDateWeekend && !isHoliday && !offHours;
};

export const compareDates = (userDate: Date, date: NestoDate): boolean =>
    userDate.getDate() === date.day && userDate.getMonth() + 1 === date.month;

export const isHolidayFromLocation = (province: Region) => {
    const { userDateConverted } = getUserHours(province);

    let holidays = FEDERAL_HOLIDAYS;

    const supportedProvinces = Object.keys(PROVINCIAL_HOLIDAYS);
    const provinceSupported = supportedProvinces.includes(province);

    if (provinceSupported) {
        holidays = [
            ...FEDERAL_HOLIDAYS,
            ...PROVINCIAL_HOLIDAYS[Region[province]]
        ];
    }

    return holidays.some(holiday => compareDates(userDateConverted, holiday));
};

export const isOffHours = (region = Region.QC): boolean => {
    const { currentHour, userDateConverted } = getUserHours(region);

    if (isFriday(userDateConverted)) {
        const isLunchTime =
            currentHour === AdvisorOperatingHours.GeneralMeeting;
        const fridayHours =
            currentHour >= AdvisorOperatingHours.FridayOpen &&
            currentHour < AdvisorOperatingHours.FridayClose;

        const openOnFriday = !isLunchTime && fridayHours;

        return !openOnFriday;
    }
    const isOfficeHours =
        currentHour >= AdvisorOperatingHours.WeekdayOpen &&
        currentHour < AdvisorOperatingHours.WeekdayClose;

    return !isOfficeHours;
};

export const getIsHighVolumeRegion = (region = 'QC'): boolean =>
    ['ON', 'QC', 'AB', 'BC'].includes(region);
