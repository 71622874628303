export enum DocumentEvent {
    brokerApprove = 'BROKER_APPROVE',
    brokerEnableEmpty = 'BROKER_ENABLE_EMPTY',
    brokerEnableReceived = 'BROKER_ENABLE_RECEIVED',
    brokerDisable = 'BROKER_DISABLE',
    brokerUnapprove = 'BROKER_UNAPPROVE',
    lenderAccept = 'LENDER_ACCEPT',
    lenderReject = 'LENDER_REJECT',
    uploadFile = 'UPLOAD_FILE',
    deleteFile = 'DELETE_FILE',
    deleteAllFiles = 'DELETE_ALL_FILES'
}
