import { Box } from 'reflexbox';
import { css } from 'styled-components';

import { gutter } from './toast';

const placements = {
    'top-left': css`
        top: 0;
        left: 0;
    `,
    'top-center': css`
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    `,
    'top-right': css`
        top: 0;
        right: 0;
    `,
    'bottom-left': css`
        bottom: 0;
        left: 0;
    `,
    'bottom-center': css`
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    `,
    'bottom-right': css`
        bottom: 0;
        right: 0;
    `
};

export const ToastContainer = ({
    placement,
    hasToasts,
    children,
    ...rest
}: any) => (
    <Box
        css={`
            box-sizing: border-box;
            max-height: 100%;
            overflow: hidden;
            padding: ${gutter}px;
            pointer-events: ${hasToasts ? null : 'none'};
            position: fixed;
            z-index: 3000;
            ${placements[placement]}
        `}
        {...rest}
    >
        {children}
    </Box>
);
