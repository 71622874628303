import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FontSizeProps, SpaceProps } from 'styled-system';
import styled from 'styled-components';

import { Typograph } from 'components/typograph';
import { Actions as LocalActions } from 'reducers/locale.redux';
import {
    getUsersOtherLanguage,
    getUserLanguage
} from 'reducers/account.selectors';
import { Language } from 'types/language';

type DispatchProps = {
    changeLanguage: () => void;
};

type StateProps = {
    otherLang: Language;
    currentLang: Language;
};

const StyledContainer = styled.div`
    cursor: pointer;
    /*
    * Fixing margin collapse
    * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
    */
    display: inline-block;
`;

const LanguageSwitcherView = ({
    changeLanguage,
    otherLang,
    ...rest
}: FontSizeProps & SpaceProps & StateProps & DispatchProps) => (
    <StyledContainer onClick={changeLanguage} data-test-id="toggle-language">
        <Typograph fontSize={[0]} {...rest}>
            {otherLang.toUpperCase()}
        </Typograph>
    </StyledContainer>
);

export const LanguageSwitcher = compose(
    connect<StateProps, DispatchProps>(
        (state: any) => ({
            otherLang: getUsersOtherLanguage(state),
            currentLang: getUserLanguage(state)
        }),
        {
            changeLanguage: LocalActions.changeLanguage
        }
    )
)(LanguageSwitcherView);
