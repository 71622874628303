import React from 'react';
import { Redirect, useLocation } from 'react-router';

import { Layout } from 'components/layout/application-layout.component';
import { BaseRoute, RouteProps } from './BaseRoute';
import { Routes } from './routes';
import { connect } from 'react-redux';
import { isLoggedIn } from 'reducers/auth.selectors';
import {
    getIsConsentNeeded,
    getIsLeadReferralNeeded
} from 'reducers/ui.selectors';
import { getHasActiveApplication } from 'reducers/application.selectors';

type StateProps = {
    isUserLoggedIn: boolean;
    isConsentNeeded: boolean;
    isLeadReferralNeeded: boolean;
    hasActiveApplication: boolean;
};

const ApplicationRouteView = ({
    isUserLoggedIn,
    isConsentNeeded,
    isLeadReferralNeeded,
    hasActiveApplication,
    ...rest
}: RouteProps & StateProps) => {
    const { pathname } = useLocation();
    // If not app selected application is redirecting client to
    // `/application/selection` if not checked page was redirected between
    // consent page and app selection in a infinite loop
    if (isUserLoggedIn && hasActiveApplication) {
        if (
            isConsentNeeded &&
            pathname !== Routes.applicationCommissionConsent &&
            pathname !== Routes.applicationSelection
        ) {
            return <Redirect to={Routes.applicationCommissionConsent} />;
        }

        if (
            isLeadReferralNeeded &&
            pathname !== Routes.applicationLeadReferral &&
            pathname !== Routes.applicationSelection
        ) {
            return <Redirect to={Routes.applicationLeadReferral} />;
        }
    }

    return (
        <Layout>
            <BaseRoute {...rest} />
        </Layout>
    );
};

export const ApplicationRoute = connect<StateProps>((state: any) => ({
    isUserLoggedIn: isLoggedIn(state),
    isConsentNeeded: getIsConsentNeeded(state),
    hasActiveApplication: getHasActiveApplication(state),
    isLeadReferralNeeded: getIsLeadReferralNeeded(state)
}))(ApplicationRouteView) as React.FC<RouteProps>;
