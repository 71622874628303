import { TranslationKeys } from 'messages';

export const getDownPaymentPercentage = (
    propertyValue?: number,
    downPayment?: number,
    roundResult = true
): number => {
    if (!propertyValue || !downPayment) return 0;
    const percentage = (downPayment / propertyValue) * 100;
    return roundResult ? Math.round(percentage * 100) / 100 : percentage;
};

export const getDownPaymentValue = (
    propertyValue: number,
    downPaymentPercentage: number
): number => {
    if (!propertyValue || !downPaymentPercentage) return 0;

    const downPayment = (downPaymentPercentage * propertyValue) / 100;

    return downPayment;
};

export const isViableDownPayment = (
    propertyValue?: number,
    downPayment?: number,
    isRentalProperty?: boolean
): boolean => {
    if (!propertyValue || !downPayment) return false;

    const downPaymentPercentage = (downPayment / propertyValue) * 100;
    const downPaymentRemainder = (propertyValue - 500000) * 0.1;

    if (isRentalProperty && downPaymentPercentage < 20) return false;

    if (propertyValue > 500000) {
        // 1 mil plus
        if (propertyValue >= 1000000 && downPaymentPercentage < 20)
            return false;

        // more than 500k
        if (25000 + downPaymentRemainder > downPayment) return false;
    }

    // less than 500k
    if (propertyValue <= 500000 && downPaymentPercentage < 5) return false;

    return true;
};

export const getDownPaymentInformation = (
    propertyValue?: number,
    downPayment?: number,
    isRentalProperty?: boolean
): TranslationKeys | undefined => {
    if (!propertyValue || !downPayment) return undefined;

    const downPaymentRemainder = (propertyValue - 500000) * 0.1;
    const downPaymentPercentage = getDownPaymentPercentage(
        propertyValue,
        downPayment
    );

    if (isRentalProperty && downPaymentPercentage < 20) {
        return 'prequalify.downpaymentWarnRental';
    }

    // 1 mil plus
    if (propertyValue >= 1000000 && downPaymentPercentage < 20) {
        return 'prequalify.downpaymentWarnMillionPlus';
    }

    // less than 500k
    if (propertyValue <= 500000) {
        if (downPaymentPercentage < 5) {
            return 'prequalify.downpaymentWarnMin5Percent';
        }
        if (downPaymentPercentage < 20) {
            return 'prequalify.downpaymentWarnInsuranceCosts';
        }
    }

    // more than 500k
    if (25000 + downPaymentRemainder > downPayment) {
        return 'prequalify.downpaymentWarnInsuranceCostsSpecial';
    }

    if (downPaymentPercentage < 20) {
        return 'prequalify.downpaymentWarnInsuranceCosts';
    }

    return undefined;
};
