import { propertyTypes } from 'types/properties';
import { Asset } from 'models/application/application-assets';
import { RegisteredAddressType } from 'constants/appConstants';
import { Product, ProductPurpose } from 'models/product';
import { RecurringPayment } from 'types/application/application';
import { ServicingAsset } from 'models/servicing-asset';

export type Property = {
    address: Address;
};
export type ConstructionType = 'EXISTING_BUILDING' | 'NEW_BUILDING';
export type PropertyType =
    | 'DETACHED'
    | 'SEMI_DETACHED'
    | 'DUPLEX_DETACHED | DUPLEX_SEMI_DETACHED'
    | 'ROW_HOUSING'
    | 'APARTMENT_LOW_RISE'
    | 'APARTMENT_HIGH_RISE'
    | 'MOBILE'
    | 'TRI_PLEX_DETACHED'
    | 'TRI_PLEX_SEMI_DETACHED'
    | 'STACKED'
    | 'MODULAR_HOME_DETACHED'
    | 'MODULAR_HOME_SEMI_DETACHED'
    | 'FOUR_PLEX_DETACHED'
    | 'FOUR_PLEX_SEMI_DETACHED';

export type MLSProperty = {
    address: Address;
    estimatedValue: number;
    yearBuilt: number;
    constructionType: ConstructionType;
    propertyType: PropertyType;
    buildingType: string;
    floors: number;
};

export type QueriedAccount = {
    created: string;
    email: string;
    firstName: string;
    firstNameSpecified: boolean;
    id: number;
    lastName: string;
    lastNameSpecified: boolean;
    phone: string;
    phoneSpecified: boolean;
    preferredLanguage: string;
    preferredLanguageSpecified: boolean;
    region: string;
    regionSpecified: boolean;
    rid: string;
    role: string;
    updated: string;
};
export type Applicant = {
    applicantId: number;
    addresses: RegisteredAddress[];
    allAssets: Asset[];
    assets: {
        giftFromImmediateRelative: {
            familyRelationship: string;
            amount: number;
            willUseForDownPayment: boolean;
            amountUsedForDownPayment: number;
        };
        houseHoldGoods: {
            description: string;
            value: number;
        };
        otherAssets: any[];
        otherInvestments: {
            amount: number;
            willUseForDownPayment: boolean;
            amountUsedForDownPayment: number;
        };
        properties: any[];
        registeredRetirementSavingsPlan: {
            amount: number;
            willUseForDownPayment: boolean;
            amountUsedForDownPayment: number;
        };
        savingsAccount: {
            amount: number;
            willUseForDownPayment: boolean;
            amountUsedForDownPayment: number;
        };
        taxFreeSavingsAccount: {
            amount: number;
            willUseForDownPayment: boolean;
            amountUsedForDownPayment: number;
        };
        vehicles: any[];
    };
    dateOfBirth: string;
    email: string;
    firstName: string;
    firstTimeHomeBuyer: boolean;
    income: any;
    lastName: string;
    liabilities: any[];
    maritalStatus: string;
    phone: string;
    properties: OtherProperty[];
    relationToMainApplicant: string;
    salutation: string;
    socialInsuranceNumber: string;
    otherIncomesSpecified: boolean;
    propertiesSpecified: boolean;
    primaryBankingInstitution: string;
    primaryBankingInstitutionOther?: string;
    creditScoreQuality: string;
    /** deprecated */
    covid19Impacted: boolean;
    /** deprecated */
    covid19ImpactDescription: string;
    hasConsumerProposalOrBankruptcyLast5yrs: 'YES' | 'NO' | '';
};

export type ApplicationMortgage = {
    firstPaymentDate: string;
    maturityDate: string;
    payment: RecurringPayment;
    monthlyPayment: RecurringPayment;
    product: Product;
    amortizationYears: number;
    amortizationMonths: number;
};

// TODO defined string possibilities from BE
export type ApplicationEvent = string;

export enum ApplicationState {
    // created states
    Created = 'CREATED',
    Submitted = 'SUBMITTED',
    // submitted state
    UnderRevision = 'UNDER_REVISION',
    Reviewed = 'REVIEWED',
    NotesSubmitted = 'NOTES_SUBMITTED',
    LenderSubmitted = 'LENDER_SUBMITTED',
    LenderApproved = 'LENDER_APPROVED',
    PendingCommitmentSignature = 'PENDING_COMMITMENT_SIGNATURE',
    PendingConditions = 'PENDING_CONDITIONS',
    Complete = 'COMPLETE',
    NotaryAlerted = 'NOTARY_ALERTED',
    // funded state
    Funded = 'FUNDED',
    Closed = 'CLOSED',
    Expired = 'EXPIRED',
    Locked = 'LOCKED'
}

export type ApplicationType = 'NEW' | 'RENEWAL' | 'REFINANCE';

export enum ApplicationTypeEnum {
    NEW = 'NEW',
    RENEWAL = 'RENEWAL',
    REFINANCE = 'REFINANCE'
}

export enum ApplicationFormName {
    NEW = 'NEW_MORTGAGE',
    RENEWAL = 'RENEWAL_MORTGAGE',
    REFINANCE = 'REFINANCE_MORTGAGE'
}

export type ApplicationChangeStateEvent =
    | 'SUBMIT'
    | 'SUBMIT_TO_LENDERS_DATA_EXCHANGE'
    | 'DELETE';

export type ApplicationsMap = Record<number, Application>;

export type Application = {
    id: number;
    mainApplicantId: number;
    created: string;
    updated: string;
    type: ApplicationType;
    applicationState: ApplicationState;
    validEvents: ApplicationEvent[];
    applicants: Record<number, Applicant>;
    property: TargetProperty;
    locked: boolean;
    salesforceQueueOutboundCallNow?: boolean;
    salesforceQueueComfortableSpeakingFrench?: boolean;
    salesforceApplicationSubmittedByAgent?: boolean;
    firstCallBooked: boolean;
    // Old property value
    /** deprecated */
    propertyToPurchase: TargetProperty;
    preQualificationIdSpecified: boolean;
    preQualificationId: number;
    product: Product | undefined;
    subPartnerID: number;
    mortgage?: ApplicationMortgage;
    sdrQualified?: boolean;
    // This is managed in the FE, the field is populated if there is a servicing asset
    // with the same application ID
    linkedServicingAsset?: ServicingAsset;
};

export type ApplicantInfo = {
    applicantId: number;
    salutation: 'MR' | 'MRS' | 'MS' | 'MISS' | 'DR' | 'REV' | 'JUDGE' | 'PROF';
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    phone: number;
    email: string;
    maritalStatus:
        | 'SINGLE'
        | 'MARRIED'
        | 'DIVORCED'
        | 'COMMON_LAW'
        | 'WIDOWED'
        | 'SEPERATED'
        | 'OTHER';
    socialInsuranceNumber: number;
    relationToMainApplicant:
        | 'SPOUSE'
        | 'COMMON_LAW'
        | 'RELATED_FAMILY_MEMBER'
        | 'PARENT'
        | 'CHILD'
        | 'SIBLING'
        | 'GRAND_PARENT'
        | 'GRAND_CHILD'
        | 'OTHER';
    firstTimeHomeBuyer: true;
    hasConsumerProposalOrBankruptcyLast5yrs: boolean;
};

export type RegisteredAddress = {
    id?: number;
    isCurrentAddress: boolean;
    occupiedYears: number;
    occupiedMonths: number;
    situation: RegisteredAddressType;
    rent?: {
        amount: number;
        frequency: 'WEEKLY' | 'MONTHLY' | 'ANNUALLY';
    };
    address: Address;
};

export type YearsOfResidence = {
    totalMonths: number;
    totalTime: number;
    totalYears: number;
    yearList: string[];
};

export type TargetProperty = {
    id?: number;
    isFound: boolean;
    purpose: string;
    address: Address;
    purchasePrice: number;
    estimatedPropertyValue: number;
    additionalFundAmount: number;
    coOwnershipFees: CoOwnershipFees;
    rentalIncome: RentalIncome;
    mlsListingNumber: number;
    schoolAndMunicipalTaxes: {
        amount: number;
        frequency: string;
        paidOnYourBehalf: boolean;
    };
    constructionType: string;
    propertyType: string;
    tenure: string;
    livingSpace: {
        amount: number;
        unit: string;
    };
    lotSize: {
        amount: number;
        unit: string;
    };
    heatingType: string;
    waterType: string;
    sewageType: string;
    numberOfUnits: number;
    propertyStyle: string;
    yearBuilt: number;
    garage: Garage;
    environmentalHazard: boolean;
    homeImprovement: {
        significantHomeImprovementPlanned: boolean;
        description: string;
        value: number;
    };
    mortgage: TargetPropertyMortgage;
    mortgages: TargetPropertyMortgage[];
    purchaseDate?: string;
};

export type RentalIncome = {
    amount: number;
    frequency: string;
};

export type Address = {
    addressLine?: string;
    city: string;
    country?: string;
    countryCode: string;
    postalCode: string;
    state?: string;
    stateCode: string;
    street: string;
    streetNumber: string;
    unit?: string;
};

export type CoOwnershipFees = {
    amount: number;
    frequency: string;
    heatingIncluded: boolean;
};

export type Garage = {
    present: boolean;
    size: { amount: number; unit: string };
    type: string;
};

export type TargetPropertyMortgage = {
    id?: number;
    payment: { amount: number; frequency: string };
    balance: number;
    maturityDate: string | null;
    lender: string;
    interestRate: number;
    interestRateType: string;
    termType: string;
    mortgageType: string;
    insuranceQuestionOriginallyInsured: 'YES' | 'NO';
    insuranceQuestionRefinanceOrRenewal: 'YES' | 'NO';
    insuranceQuestionAddFundsOrIncreasedMortgageAmount: 'YES' | 'NO';
    insuranceQuestionIncreasedAmortization: 'YES' | 'NO';
    insuranceQuestionChangesBefore: 'YES' | 'NO';
};

export type Lender =
    | 'RBC'
    | 'CIBC'
    | 'TD'
    | 'SCOTIABANK'
    | 'NATIONAL_BANK'
    | 'DESJARDINS'
    | 'BMO'
    | 'B2B_LAURENTIAN'
    | 'MCAP'
    | 'FIRST_NATIONAL'
    | 'EQUITABLE_BANK'
    | 'STREET_CAPITAL'
    | 'LENDWISE'
    | 'MERIX'
    | 'CMLS'
    | 'ALTERNA'
    | 'RADIUS_FINANCIAL'
    | 'DUCA'
    | 'HOME_TRUST'
    | 'ICICI_BANK'
    | 'INDUSTRIAL_ALLIANCE'
    | 'MANULIFE'
    | 'MEREDIAN'
    | 'OPTIMUM_MORTGAGE'
    | 'PC_FINANCIALS'
    | 'PRIVATE'
    | 'OTHER';

export type OtherProperty = {
    readonly id: number;
    hasMortgage: boolean;
    currentPurpose: ProductPurpose;
    afterTransactionPurpose:
        | 'SOLD'
        | 'OWNER_OCCUPIED'
        | 'OWNER_OCCUPIED_AND_RENTAL'
        | 'RENTAL';
    currentSaleStatus:
        | 'NOT_READY_YET'
        | 'LISTED_FOR_SALE'
        | 'CONDITIONALLY_SOLD'
        | 'SOLD_FIRM';
    address: Address;
    type:
        | ''
        | propertyTypes.HOUSE
        | propertyTypes.CONDO
        | propertyTypes.SECONDARY_HOME_OR_COTTAGE
        | propertyTypes.DUPLEX
        | propertyTypes.TRIPLEX
        | propertyTypes.FOURPLEX;
    estimatedPropertyValue: number;
    condoFees: {
        amount: number;
        frequency:
            | 'ANNUALLY'
            | 'SEMIANNUALLY'
            | 'MONTHLY'
            | 'SEMIMONTHLY'
            | 'BIWEEKLY'
            | 'WEEKLY';
        heatingIncluded: boolean;
    };
    taxes: {
        amount: number;
        frequency: string;
    };
    rentalIncome: {
        amount: number;
        frequency: string;
    };
    mortgage: {
        payment: {
            amount: number;
            frequency:
                | 'ANNUALLY'
                | 'SEMIANNUALLY'
                | 'MONTHLY'
                | 'SEMIMONTHLY'
                | 'BIWEEKLY'
                | 'WEEKLY';
        };
        balance: number;
        maturityDate: string;
        lender: Lender;
        interestRate: number;
        interestRateType:
            | 'FIXED'
            | 'VARIABLE'
            | 'ADJUSTABLE'
            | 'CAPPED_VARIABLE';
        termType: 'OPEN' | 'CLOSED' | 'CONVERTABLE';
        mortgageType: 'STANDARD' | 'HELOC';
    };
};

export type Advisor = {
    contactName: string;
    phone: string;
    email: string;
    title: string;
};
