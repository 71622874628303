import { useMemo } from 'react';

export const isUndefined = (value: any) => value === undefined;

export const isNullOrUndefined = (value: any) =>
    value === null || isUndefined(value);

export const get = <T = any>(obj: any, path: string, defaultValue?: T): T => {
    const result = path
        .split(/[,[\].]+?/)
        .filter(Boolean)
        .reduce(
            (result, key) => (isNullOrUndefined(result) ? result : result[key]),
            obj
        );

    return isUndefined(result) || result === obj
        ? obj[path] || defaultValue
        : result;
};

export const isNumber = (value: any) => typeof value === 'number';

export const isString = (value: any) => typeof value === 'string';

export const isArray = (value: any) => Array.isArray(value);

export const isStringOrArray = (value: any) =>
    isString(value) || isArray(value);

export const isDefaultValueAvailable = (
    defaultValue?: string | number | ReadonlyArray<string>
) => {
    if (isNullOrUndefined(defaultValue)) {
        return false;
    }

    if (
        isStringOrArray(defaultValue) &&
        (defaultValue as string | string[]).length > 0
    ) {
        return true;
    }

    if (isNumber(defaultValue)) {
        return true;
    }

    return false;
};

export const isFieldShown = (
    docFieldMap,
    fieldName: string,
    documents: string[]
) => {
    return documents.some(doc => docFieldMap[doc].includes(fieldName));
};

type DefaultFields = string;

export function useShowFields<F extends DefaultFields = any>(
    docFieldMap: Record<string, string[]>,
    fields: string[],
    metadataDocuments?: Record<string, boolean>
) {
    const selectedDocuments = useMemo(() => {
        return Object.entries(metadataDocuments || {})
            .filter(([_key, value]) => value)
            .map(([key]) => key);
    }, [metadataDocuments]);

    return useMemo<Record<F, boolean>>(
        () =>
            // @ts-ignore
            fields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field]: isFieldShown(
                        docFieldMap,
                        field,
                        selectedDocuments || []
                    )
                }),
                {}
            ),
        [docFieldMap, fields, selectedDocuments]
    );
}

export const allFieldsHidden = (fielShown: Record<string, boolean>): boolean =>
    !Object.values(fielShown || {}).includes(true);
