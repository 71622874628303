import { Box, Flex } from 'components/reflexbox';
import styled, { css } from 'styled-components';
import { sectionTitleHeight } from './documents-constants';
import { space } from 'styled-system';

type NavProps = {
    isMobile: boolean;
    applicants: string[];
    isOpen: boolean;
};

type NavBarElementProps = {
    selected: boolean;
    isOpen: boolean;
    isDocuments?: boolean;
};

export const Nav = styled(Box)<NavProps>`
    position: relative;
    display: flex;
    ${({ isMobile }) => isMobile && MobileNav};
`;

export const NavBarElement = styled(Box)<NavBarElementProps>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    font-weight: bold;
    display: ${({ isOpen, selected }) => isOpen && !selected && 'none'};

    border-bottom: ${({ selected, theme, isDocuments }) =>
        selected
            ? `${
                  !isDocuments ? theme.brand.primary : theme.colors.timeBlue
              } solid 2px`
            : 'transparent solid 2px'};

    .userName {
        font-size: 14px;
        color: ${({ selected, theme, isDocuments }) =>
            selected
                ? !isDocuments
                    ? theme.brand.primary
                    : theme.colors.timeBlue
                : theme.colors.nobel};
    }
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        padding: 12px;
        padding-left: 0;
        padding-right: 16px;
        border-bottom: none;
        color: ${({ selected, theme, isDocuments }) =>
            selected
                ? !isDocuments
                    ? theme.brand.primary
                    : theme.colors.timeBlue
                : theme.colors.flavioGrey};
        size: 14px;
        font-weight: 600;
    }
`;

export const MobileNavWrapper = styled(Flex)<{ isOpen: boolean }>`
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 4px;
    .icon {
        position: absolute;
        right: 14px;
        top: 8px;
        transform: ${({ isOpen }) =>
            isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
`;

export const MobileNav = css<{ applicants: string[]; isOpen: boolean }>`
    display: grid;
    width: 100%;
    grid-template-areas:
        'selected'
        ${({ applicants, isOpen }) =>
            isOpen ? applicants && applicants.join(` `) : ''};
`;

export const Pill = styled(Box)`
    background: ${({ theme }) => theme.colors.darkDark};
    border-radius: 13px;
    padding: 4px 8px;
    margin-bottom: 4px;
    word-spacing: 0.6px;
    ${space}
`;

export const FlexContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
`;

export const disabled = css`
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: ${({ theme }) => theme.colors.whiteSmoke};
        width: 100%;
        height: 100%;
        min-height: 160px;
        z-index: 1;
        border-radius: 10px;
    }
`;

export const AdditionalDocs = styled(Box)`
    display: grid;
    gap: 10px;
`;

export const EmptyDiv = styled(Box)`
    width: 100%;
    background: ${({ theme }) => theme.colors.whiteSmoke};
    border-radius: 10px;
    height: 100%;
    min-height: 160px;
`;

export const SidebarContainer = styled.div`
    display: none;
    position: relative;
    ${({ theme }) => theme.mediaQueries.small} {
        display: block;
    }
`;

export const SidebarSticky = styled.div`
    position: sticky;
    top: 24px;
    margin-top: ${sectionTitleHeight};
`;

const MobileAnchor = css`
    .sectionTitle {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.timeBlue};
        line-height: 20px;
        size: 14px;
        font-weight: 700;
    }
`;

export const Anchor = styled.a<{ isMobile?: boolean }>`
    text-decoration: none;
    color: inherit;
    ${({ isMobile }) => isMobile && MobileAnchor}
`;
