import { useEffect, useState, useRef } from 'react';

export function useScrollPosition() {
    const [scrollPos, setScrollPos] = useState({
        x: 0,
        y: 0,
        direction: 'down'
    });
    const prevScrollY = useRef(0);
    const [goingUp, setGoingUp] = useState(false);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        const currentScrollX = window.scrollX;

        if (prevScrollY.current < currentScrollY && goingUp) {
            setGoingUp(false);
        }
        if (prevScrollY.current > currentScrollY && !goingUp) {
            setGoingUp(true);
        }
        prevScrollY.current = currentScrollY;

        setScrollPos({
            x: currentScrollX,
            y: currentScrollY,
            direction: goingUp ? 'up' : 'down'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollPos]); // eslint-disable-line react-hooks/exhaustive-deps

    return scrollPos;
}
