import { useEffect, useContext, useCallback } from 'react';
import {
    CalendlyCalendarConfig,
    CalendlyProvinceConfig,
    ConfigProps,
    CalendlyContext
} from '@nesto/themes';
import { useIntl } from 'react-intl';

import { PartnerContext } from 'app-root/providers/PartnerProvider';
import { useAnalyticEvent, useCss, useScript } from './hooks';
import { AnalyticsTrackEvent } from 'sagas/analytics.sagas';
import { ApplicationType } from 'models/application/Application';

export enum CalendlyEvent {
    DateAndTimeSelected = 'calendly.date_and_time_selected',
    EventScheduled = 'calendly.event_scheduled',
    ProfilePageViewed = 'calendly.profile_page_viewed',
    EventTypeViewed = 'calendly.event_type_viewed'
}

type CalendlyMessageEvent = {
    event: CalendlyEvent;
    payload: any;
    // only calendly.event_scheduled have payload and it is not used atm rest is empty object `{}`
    // event: {uri: "https://api.calendly.com/scheduled_events/DASXMDXS3O5VV34A"}
    // invitee: {uri: "https://api.calendly.com/scheduled_events/DASXMDXS3O5VV34A/invitees/DCXZUKWWKMA35WWM"}
};

const getCalendarFromContext = (
    config: ConfigProps,
    context: CalendlyContext
): CalendlyProvinceConfig => {
    return config.calendly?.[context] || config.calendly.default;
};

const getCalendlyCalendar = ({
    calendars,
    region,
    locale
}: {
    calendars: CalendlyCalendarConfig;
    region: string;
    locale: string;
}) => {
    if (Object.keys(calendars).includes(region)) {
        return calendars[region][locale];
    }

    return calendars.default[locale];
};

export const formatCalendlyApplicantData = mainApplicant => ({
    customAnswers: {
        a1: mainApplicant
            ? +`1${mainApplicant.phone.replace(/\D/g, '')}`
            : undefined
    },
    email: mainApplicant ? mainApplicant.email : undefined,
    name: mainApplicant
        ? `${mainApplicant.firstName} ${mainApplicant.lastName}`
        : undefined
});

export const isCalendlyEvent = event => {
    return event.data.event && event.data.event.indexOf('calendly') === 0;
};

export const getTrackingData = (calendlyEventName: string) => {
    switch (calendlyEventName) {
        case CalendlyEvent.DateAndTimeSelected:
            return 'Select calendar time';
        case CalendlyEvent.EventScheduled:
            return 'Book calendar time';
        case CalendlyEvent.ProfilePageViewed:
            return 'Calendly profile page viewed';
        case CalendlyEvent.EventTypeViewed:
            return 'Calendly event type viewed';
        default:
            return calendlyEventName;
    }
};

type CalendlyEvents = {
    onProfilePageViewed?: () => void;
    onEventTypeViewed?: () => void;
    onDateAndTimeSelected?: () => void;
    onEventScheduled?: () => void;
};
export const useCalendly = (
    context: CalendlyContext = 'default',
    region = 'default',
    calendlyTrackingPayload?: AnalyticsTrackEvent,
    {
        onProfilePageViewed,
        onEventTypeViewed,
        onDateAndTimeSelected,
        onEventScheduled
    }: CalendlyEvents = {}
) => {
    const analyticEvent = useAnalyticEvent();
    const intl = useIntl();
    const { config } = useContext(PartnerContext);
    const [scriptIsLoaded, scriptLoadError] = useScript(
        'https://assets.calendly.com/assets/external/widget.js'
    );
    const [cssIsLoaded, cssLoadError] = useCss(
        'https://assets.calendly.com/assets/external/widget.css'
    );

    const url = getCalendlyCalendar({
        calendars: getCalendarFromContext(config, context),
        region,
        locale: intl.locale
    });

    const calendlyEvent = useCallback(
        (event: MessageEvent<CalendlyMessageEvent>) => {
            if (!isCalendlyEvent(event)) {
                return;
            }

            if (calendlyTrackingPayload) {
                analyticEvent('calendly', {
                    label: getTrackingData(event.data.event),
                    ...calendlyTrackingPayload
                });
            }

            if (event.data.event === CalendlyEvent.ProfilePageViewed) {
                onProfilePageViewed && onProfilePageViewed();
            }
            if (event.data.event === CalendlyEvent.EventTypeViewed) {
                onEventTypeViewed && onEventTypeViewed();
            }
            if (event.data.event === CalendlyEvent.DateAndTimeSelected) {
                onDateAndTimeSelected && onDateAndTimeSelected();
            }
            if (event.data.event === CalendlyEvent.EventScheduled) {
                onEventScheduled && onEventScheduled();
            }
        },
        [
            calendlyTrackingPayload,
            onProfilePageViewed,
            onEventTypeViewed,
            onDateAndTimeSelected,
            onEventScheduled,
            analyticEvent
        ]
    );

    useEffect(() => {
        window.addEventListener('message', calendlyEvent);

        return () => {
            window.removeEventListener('message', calendlyEvent);
        };
    }, [calendlyEvent]);

    return { cssIsLoaded, cssLoadError, scriptIsLoaded, scriptLoadError, url };
};

export const applicationTypeToCalendlyContext = (
    type: ApplicationType = 'NEW'
): CalendlyContext => {
    const contextMap: Record<ApplicationType, CalendlyContext> = {
        NEW: 'confirmation',
        REFINANCE: 'confirmationRenewRefinance',
        RENEWAL: 'confirmationRenewRefinance'
    };

    return contextMap[type] ?? 'confirmation';
};
