import * as R from 'ramda';
import inflection from 'inflection';
import {
    Document,
    DocumentList,
    GroupedDocuments,
    DocumentCategory,
    DocumentCategories,
    DocumentSubCategories,
    DocumentCompositeKey,
    DocumentFile,
    DocState
} from 'models/documents';
import { TypographI18nKeys } from 'components/typograph';

export const byProp = (prop: keyof Document | number | string) =>
    R.groupBy<Document>(document => document[prop]);

export const byCategories = byProp('category');

export const bySubCategories = byProp('subCategory');

export const byEntityId = byProp('entityId');

// @ts-ignore
export const sortDocuments = R.sortWith([
    R.ascend(R.prop('category')),
    R.ascend(R.prop('subCategory')),
    // @ts-ignore
    R.descend(R.prop('documentType')),
    // @ts-ignore
    R.descend(R.prop('year'))
]) as (documents: DocumentList) => DocumentList;

// These categories are not groupped by subcategories in lower level object
const ungroupedSubCategoryForCategory: string[] = [
    DocumentCategory.identification,
    DocumentCategory.financials
];

// Core logic to group document in `GroupedDocuments` from an array of `Document`
export const groupDocumentsList = (R.compose(
    R.mapObjIndexed((item: any, category: any) =>
        R.mapObjIndexed((documents: Document[], subCategory: string) =>
            ungroupedSubCategoryForCategory.includes(category)
                ? { undefined: documents }
                : bySubCategories(documents)
        )(item)
    ),
    R.map(byEntityId),
    // @ts-ignore
    byCategories,
    sortDocuments
) as unknown) as (documents: DocumentList) => GroupedDocuments;

// Remove disabled document when not on behalf
// keep all when logged on behalf
const isDocumentOnBehalf = R.curry(
    (isOnBehalf: boolean, document: Document) =>
        (!isOnBehalf && document.state !== DocState.disabled) || isOnBehalf
);

const sortAndTake = (callback, sortArr, obj) => {
    return R.map(k => callback(k, obj[k]), sortArr);
};

export const takeSortedItems = R.curry(sortAndTake);

export const groupAndFilterOnBehalfDocuments = (
    documents: DocumentList,
    isOnBehalf = false
) =>
    R.pipe<DocumentList, DocumentList, GroupedDocuments>(
        // @ts-ignore
        R.filter(isDocumentOnBehalf(isOnBehalf)), // Remove disabled or keep if logged on behalf
        // @ts-ignore
        groupDocumentsList
    )(documents);

export const getCategories = R.keys as (
    documents: GroupedDocuments
) => DocumentCategories;

export const getEntitySubCategories = (
    documents: GroupedDocuments,
    category: DocumentCategory,
    entityId: string | number
) =>
    R.compose(
        R.keys,
        // @ts-ignore
        R.path([category, entityId])
    )(documents) as DocumentSubCategories;

// @ts-ignore
export const fileListToArray = R.map(file => file as DocumentFile) as (
    files: FileList
) => File[];

export const mapObject = R.curry(
    (callback: (item: any, key: any) => any, obj) =>
        R.pipe(
            R.mapObjIndexed((item, key) => callback(item, key)),
            R.values
        )(obj)
);

export const findDocByCompositeKey = R.curry(
    (
        {
            applicationId,
            applicantId,
            documentType: type,
            year,
            entityId
        }: DocumentCompositeKey,
        documents: Document[]
    ) =>
        R.find(
            R.where({
                applicationId: R.equals(applicationId),
                applicantId: R.equals(applicantId),
                documentType: R.equals(type),
                year: R.equals(year),
                entityId: R.equals(entityId)
            })
        )(documents)
);

export const findDocIndexByCompositeKey = R.curry(
    (
        {
            applicationId,
            applicantId,
            documentType: type,
            year,
            entityId
        }: DocumentCompositeKey,
        documents: Document[]
    ) =>
        R.findIndex(
            R.where({
                applicationId: R.equals(applicationId),
                applicantId: R.equals(applicantId),
                documentType: R.equals(type),
                year: R.equals(year),
                entityId: R.equals(entityId)
            })
        )(documents)
);

export const findFileIndexByUploadKey = (
    uploadKey: string,
    files: DocumentFile[]
) => findFileIndexByProp('uploadKey', uploadKey, files);

export const findFileIndexByProp = (
    prop: keyof DocumentFile,
    value: any,
    files: DocumentFile[]
) => files.findIndex((file: DocumentFile) => file[prop] === value);

export const appendIn = R.curry((path: any[], item: any, items: any) =>
    // @ts-ignore
    R.assocPath(path, R.append(item, R.path(path, items)), items)
);

export const updateIn = R.curry((path: any[], merge: any, items: any) =>
    // @ts-ignore
    R.assocPath(path, R.merge(R.path(path, items), merge), items)
);

export const removeIn = R.curry((path: any[], index: number, items: any) =>
    // @ts-ignore
    R.assocPath(path, R.remove(index, 1, R.path(path, items)), items)
);

export const withoutIn = R.curry((path: any[], without: any[], items: any) =>
    // @ts-ignore
    R.assocPath(path, R.without(without, R.path(path, items)), items)
);

export const hasValueIn = (allowed: any[], valid: any[]) =>
    allowed.some(item => valid.includes(item));

const addUnitToAddress = address =>
    R.assoc('unit', `${address.unit} -`, address);
const hasUnit = R.compose(
    R.not,
    R.isEmpty,
    R.prop<string, { unit: string }>('unit')
);
export const formatAddress = R.pipe(
    R.pick([
        'unit',
        'streetNumber',
        'street',
        'city',
        'state',
        'country',
        'postalCode'
    ]),
    R.ifElse(hasUnit, addUnitToAddress, o => o),
    R.values,
    R.join(' '),
    R.trim
);

export const buildDocumentTypeId = (documentType?: string): TypographI18nKeys =>
    `documents.documentType.${inflection.camelize(
        (documentType || '').toLowerCase(),
        true
    )}`;

export const buildDocumentDescriptionId = (
    documentType?: string
): TypographI18nKeys =>
    `documents.description.${inflection.camelize(
        (documentType || '').toLowerCase(),
        false
    )}`;

export const buildDocumentRetrieveId = (
    documentType?: string
): TypographI18nKeys =>
    `documents.howToRetrieve.${inflection.camelize(
        (documentType || '').toLowerCase(),
        false
    )}`;

export const formatDocumentTypeForI18Keys = (documentType?: string): string => {
    return `${inflection.camelize((documentType || '').toLowerCase(), false)}`;
};
