import * as Normalizers from 'lib/normalizers';
import { isPhoneNumber } from 'lib/validators';

import {
    PhoneField as PhoneFieldInput,
    TextFieldProps
} from './phone-input.component';
import styled, { css, ThemeProps } from 'styled-components/macro';
import { Theme } from '@nesto/themes';

const readOnlyStyle = ({ theme }: any) =>
    css<{ theme: any }>`
        color: ${theme.input.default.color};
        background-color: #f8f8f8;
    `;

const plusOne = ({
    theme,
    readOnly
}: ThemeProps<Theme> & { readOnly: boolean }) => css`
    content: '+1';
    position: absolute;
    left: 0;
    top: 0;
    height: 60px;
    width: 50px;
    padding-right: 10px;
    border-radius: 6px 0 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.fonts.Montserrat500};
    font-size: ${theme.fontSizes[2]};
    color: ${theme.fontColor.default};
    border: 1px solid ${theme.borderColor.default};
    background: ${theme.backgroundColor.default};
    ${readOnly && readOnlyStyle}
`;

const Wrapper = styled.div<any>`
    position: relative;
    padding-left: 40px;
    width: 100%;
    :before {
        ${plusOne}
    }
`;

const PhoneField = ({ readOnly, ...rest }: TextFieldProps) => (
    <Wrapper readOnly={readOnly}>
        <PhoneFieldInput
            type="text"
            normalize={Normalizers.normalizePhone}
            validate={rest.validate || [isPhoneNumber]}
            readOnly={readOnly}
            {...rest}
        />
    </Wrapper>
);

export { PhoneField };
