import { Language } from 'types/language';

const HTML_TAG_REGEX = /<[^<>]+>/g;

const hasHtmlContent = (str: string) => HTML_TAG_REGEX.test(str);
const hasIntlStringTemplate = (str: string) => /\{[^{}]+\}/.test(str);

/**
 * Historically, react-intl had two utils that offered out of the box HTML
 * syntax support for translation strings. However, that was changed in v4
 * https://formatjs.io/docs/react-intl/upgrade-guide-4x/
 * forcing developers to write html tag resolvers for each intl message. That 'new way'
 * of working with translations involves many changes in our codebase, increasing the
 * chances of introducing regressions. So instead, we are escaping the html tags, forcing
 * react-intl to ignore the special syntax and treat it as plain text e.g.
 * "Hello <h1>nesto</h1>" gets converted to "Hello '<h1>'nesto'</h1>'"
 */
export function escapeHtmlMessages(message: string) {
    if (!hasIntlStringTemplate(message) || !hasHtmlContent(message)) {
        return message;
    }

    return message.replace(HTML_TAG_REGEX, (match, offset: number) => {
        const escaped = `'${match.replace(/'/g, `"`)}'`;
        const nextChar = message.charAt(offset + match.length);

        // We need to separate adjacent html tags, otherwise a quote `'` gets rendered
        if (nextChar === '<') {
            return escaped + ' ';
        }

        return escaped;
    });
}

export const intlListFormatter = (list: string[], lang: Language) => {
    const formatter = new Intl.ListFormat(lang, {
        style: 'long',
        type: 'conjunction'
    });
    return formatter.format(list);
};
