import { ReactNode } from 'react';
import { css as StyleCss, keyframes } from 'styled-components';

import { CheckIcon, FlameIcon, InfoIcon, CloseIcon, AlertIcon } from './icons';
import { Flex, Box } from 'reflexbox/styled-components';

import { ToastElement } from './toast-element';
import { ToastEdge } from './toast-edge';

import { Typograph } from '@nesto/design-system';

export const gutter = 8;

export const shrinkKeyframes = keyframes`from { height: 100%; } to { height: 0% }`;

export const appearances = {
    success: {
        icon: CheckIcon,
        fillColor: '#43D011'
    },
    error: {
        icon: FlameIcon,
        fillColor: '#F9423A'
    },
    warning: {
        icon: AlertIcon,
        fillColor: '#F8CD46'
    },
    info: {
        icon: InfoIcon,
        fillColor: '#3D66B0'
    }
};

// TODO move types outta here
export type AppearanceTypes = 'error' | 'info' | 'success' | 'warning';
export type Id = string;
export type Callback = (id: Id) => void;
export type Options = {
    appearance: AppearanceTypes;
    title?: string;
    autoDismiss?: boolean;
    onDismiss?: Callback;
};

export type AddFn = (content: ReactNode, options?: Options) => Id;
export type UpdateFn = (id: Id, options: Options) => void;
export type RemoveFn = (id: Id) => void;

export type HoverFn = () => void;
export type Tag = any /* React Element or html */;
export type NOOP = any;

export type Placement =
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
    | 'top-left'
    | 'top-center'
    | 'top-right';

export type ToastProps = {
    appearance: AppearanceTypes;
    autoDismiss: boolean; // may be inherited from ToastProvider
    autoDismissTimeout: number; // inherited from ToastProvider
    children: ReactNode;
    isRunning: boolean;
    onDismiss: NOOP;
    onMouseEnter: HoverFn;
    onMouseLeave: HoverFn;
    placement: Placement;
    transitionDuration?: number; // inherited from ToastProvider
    transitionState?: TransitionState; // inherited from ToastProvider
    title?: string;
};

const Button = props => (
    <Flex
        role="button"
        className="react-toast-notifications__toast__dismiss-button"
        css={`
            cursor: pointer;
            flex-shrink: 0;
            opacity: 0.5;
            padding: ${gutter}px ${gutter * 1.5}px;
            transition: opacity 150ms;
            align-self: center;

            :hover: {
                opacity: 1px;
            }
        `}
        {...props}
    />
);

const Icon = ({ appearance }: any) => {
    const meta = appearances[appearance];

    const Glyph = meta.icon;

    return (
        <Box
            className="react-toast-notifications__toast__icon-wrapper"
            // eslint-disable-next-line
            // @ts-ignore
            css={`
                color: ${meta.fillColor};
                flex-shrink: 0;
                padding-bottom: ${gutter}px;
                padding-top: ${gutter}px;
                position: relative;
                overflow: hidden;
                text-align: center;
                width: 50px;
                align-items: center;
                display: flex;
                justify-content: center;
            `}
        >
            <Glyph className="react-toast-notifications__toast__icon" />
        </Box>
    );
};

// Transitions
// ------------------------------

function getTranslate(placement) {
    const pos = placement.split('-');
    const relevantPlacement = pos[1] === 'center' ? pos[0] : pos[1];
    const translateMap = {
        right: 'translate3d(120%, 0, 0)',
        left: 'translate3d(-120%, 0, 0)',
        bottom: 'translate3d(0, 120%, 0)',
        top: 'translate3d(0, -120%, 0)'
    };

    return translateMap[relevantPlacement];
}

export type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited';

export const toastStates = (placement: Placement) => ({
    entering: StyleCss`
        transform: ${getTranslate(placement)};
    `,
    entered: StyleCss`
        transform: 'translate3d(0,0,0)';
    `,
    exiting: StyleCss`
        transform: scale(0.66);
        opacity: 0;
    `,
    exited: StyleCss`
        transform: scale(0.66);
        opacity: 0;
    `
});

export const Toast = ({
    title,
    appearance = 'warning',
    autoDismiss = true,
    autoDismissTimeout = 5000,
    children,
    isRunning,
    onDismiss,
    placement = 'top-right',
    // TODO for animation
    transitionDuration = 3000,
    transitionState = 'entered', // default should be entering with animation
    onMouseEnter,
    onMouseLeave,
    ...otherProps
}: ToastProps) => {
    return (
        <ToastElement
            appearance={appearance}
            placement={placement}
            transitionState={transitionState}
            transitionDuration={transitionDuration}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...otherProps}
        >
            <ToastEdge
                appearance={appearance}
                autoDismiss={autoDismiss}
                autoDismissTimeout={autoDismissTimeout}
                isRunning={isRunning}
            />
            <Icon appearance={appearance} />
            <Flex flexDirection="column" justifyContent="center" py="12px">
                {title && (
                    <Typograph
                        as="p"
                        css={{
                            fontSize: '13px',
                            fontWeight: 500,
                            paddingBottom: '3px',
                            color: 'black'
                        }}
                        tx={title}
                    />
                )}
                <Typograph fontSize={0}>{children}</Typograph>
            </Flex>
            {onDismiss ? (
                <Button onClick={onDismiss}>
                    <CloseIcon className="react-toast-notifications__toast__dismiss-icon" />
                    <Typograph visuallyHidden tx="popup.close" />
                </Button>
            ) : null}
        </ToastElement>
    );
};
