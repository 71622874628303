import { ReduxState, Step, StepProductId } from 'product-selection/models';
import { Params as NextParams } from 'product-selection/hooks';
import { AccountTypes } from './account.redux';

export type PayloadProduct = {
    selectedStepProductID: StepProductId;
    nextStep?: string;
};

export type PayloadProductSummary = {
    summaryConfirmation: 'CONFIRMED' | 'NOT_SURE_YET';
    noRedirect?: boolean;
};

export type PayloadQuestion = {
    questions: {
        [key: string]: {
            question: string;
            answer: string;
        };
    };
};

export type Payload = PayloadProduct | PayloadQuestion | PayloadProductSummary;

export const Types = {
    STEP_REQUEST: 'PRODUCT_SELECTION/STEP_REQUEST',
    STEP_SUCCESS: 'PRODUCT_SELECTION/STEP_SUCCESS',
    STEP_ERROR: 'PRODUCT_SELECTION/STEP_ERROR',
    STEP_INFO_REQUEST: 'PRODUCT_SELECTION/STEP_INFO_REQUEST',
    STEP_INFO_SUCCESS: 'PRODUCT_SELECTION/STEP_INFO_SUCCESS',
    STEP_INFO_ERROR: 'PRODUCT_SELECTION/STEP_INFO_ERROR',
    SELECTION_REQUEST: 'PRODUCT_SELECTION/SELECTION_REQUEST',
    SELECTION_SUCCESS: 'PRODUCT_SELECTION/SELECTION_SUCCESS',
    SELECTION_ERROR: 'PRODUCT_SELECTION/SELECTION_ERROR'
};

export const INITIAL_STATE: ReduxState = {
    loaded: false,
    loading: false,
    selection: {},
    summaryConfirmation: undefined,
    steps: {
        LTV_PRODUCTS: {},
        PRODUCT_TYPE_QUESTIONS: {},
        PRODUCT_TYPE_2_QUESTIONS: {},
        PRODUCT_TYPE_PRODUCTS: {},
        PRODUCT_TERM_PRODUCTS: {},
        HELOC_OPTIONS_QUESTIONS: {},
        HELOC_OPTIONS_2_QUESTIONS: {},
        HELOC_OPTIONS_3_QUESTIONS: {},
        HELOC_OPTIONS_PRODUCTS: {},
        PREPAYMENT_OPTIONS_PRODUCTS: {},
        AMORTIZATION_PRODUCTS: {},
        PAYMENT_FREQUENCY_PRODUCTS: {},
        PRODUCT_SUMMARY: {}
    }
};

export const reducer = (state = INITIAL_STATE, action: any): ReduxState => {
    switch (action.type) {
        case Types.STEP_INFO_REQUEST:
        case Types.SELECTION_REQUEST:
            return {
                ...state,
                loading: true,
                // @ts-ignore
                steps: {
                    ...INITIAL_STATE.steps
                }
            };
        case Types.STEP_REQUEST:
            return {
                ...state,
                loading: true,
                // @ts-ignore
                steps: {
                    ...state.steps,
                    [action.section as Step]: {
                        // @ts-ignore
                        ...(state.steps[action.section as Step] || {}),
                        payload: action.payload as Payload
                    }
                }
            };
        case Types.STEP_SUCCESS:
            // Response is the next step
            return {
                ...state,
                loading: false,
                summaryConfirmation: action.data.summary?.confirmation,
                // @ts-ignore
                steps: {
                    ...INITIAL_STATE.steps,
                    // Response next step from BE
                    [action.data.currentStepId as Step]: {
                        // @ts-ignore
                        ...(state.steps[action.data.currentStepId as Step] ||
                            {}),
                        data: action.data
                    }
                }
            };
        case Types.STEP_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                summaryConfirmation: action.data.summary?.confirmation,
                // @ts-ignore
                steps: {
                    ...state.steps,
                    // TODO: to be confirmed not tested yet
                    [action.data.currentStepId as Step]: {
                        // @ts-ignore
                        ...(state.steps[action.data.currentStepId as Step] ||
                            {}),
                        data: action.data
                    }
                }
            };
        case Types.STEP_ERROR:
        case Types.STEP_INFO_ERROR:
        case Types.SELECTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case Types.SELECTION_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
                selection: action.selection,
                // @ts-ignore
                steps: {
                    ...state.steps,
                    // TODO: to be confirmed not tested yet
                    [action.selection.currentStepId as Step]: {
                        // @ts-ignore
                        ...(state.steps[
                            action.selection.currentStepId as Step
                        ] || {}),
                        data: action.selection
                    }
                }
            };
        case AccountTypes.LOGOUT:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Actions
const putStep = (
    section: Step,
    payload: Payload,
    next?: (value?: any, queryParams?: NextParams) => void,
    value?: any,
    queryParams?: NextParams
) => ({
    type: Types.STEP_REQUEST,
    section,
    payload,
    next,
    value,
    queryParams
});

const stepInfoRequest = (section: Step) => ({
    type: Types.STEP_INFO_REQUEST,
    section
});

const productSelection = (params?: { availability: true }) => ({
    type: Types.SELECTION_REQUEST,
    params
});

export const Actions = {
    putStep,
    stepInfoRequest,
    productSelection
};
