import { path } from 'ramda';
import qs from 'qs';
import createCachedSelector from 're-reselect';
import { selectLocaleRegion } from 'reducers/locale.redux';

import {
    Account,
    AccountState,
    BrokerAccount,
    CommunicationPreferences
} from './account.redux';
import { Routes } from 'app-root/routing/routes';
import { AccountDeletionState } from 'types/account/account-deletion';
import { Language } from 'types/language';

export const getAccount = ({ account }: any): Account => account.account;

export const getIsQuebecAccount = ({ account }: any): boolean =>
    account.account?.regionSpecified && account.account?.region === 'QC';

export const getIsContactSignup = state => {
    const search: any = getCurrentSearchParams(state);
    const { contact } = qs.parse(search, {
        ignoreQueryPrefix: true
    });

    return contact === 'true';
};

export const selectUserLanguage = (state: any) =>
    path(['locale', 'lang'], state);

export const selectCurrentRoute = (state: any) =>
    path(['router', 'location', 'pathname'], state);

export const getRefererPath = state => {
    const from =
        state.router.location.state && state.router.location.state.from;
    if (!from || from.pathname === Routes.root) {
        const hasApplications = !!state.application?.applications;
        if (!hasApplications) {
            return Routes.getAQuoteRoot;
        }

        return Routes.root;
    }

    return `${from.pathname}${from.search || ''}`;
};

export const getCurrentSearchParams = state =>
    path(['router', 'location', 'search'], state);

/**
 * @deprecated
 * Use `getAccount` instead
 * */
export const getAccountInfo = (state: any): Account | undefined =>
    path(['account'], state.account);

export const getBrokerAccountInfo = (state: any): BrokerAccount | undefined =>
    path(['broker'], state.account);

export const getApplicantFirstName = (state: any): string | undefined =>
    path(['account', 'firstName'], state.account);

export const getApplicantLastName = (state: any): string | undefined =>
    path(['account', 'lastName'], state.account);

export const getApplicantFullName = (state: any): string | undefined => {
    const first = getApplicantFirstName(state) || '';
    const last = getApplicantLastName(state) || '';
    return `${first} ${last}`;
};

export const getApplicantPhone = (state: any): string | undefined =>
    path(['account', 'phone'], state.account);

export const getApplicantEmail = (state: any): string | undefined =>
    path(['account', 'email'], state.account);

export const getApplicantRegion = (state: any): string | undefined =>
    path(['account', 'region'], state.account);

export const getApplicantPostalCode = (state: any): string | undefined =>
    state?.account?.account?.postalCode;

export const getUserValues = (state: any): any => {
    const initialValues = {
        firstName: getApplicantFirstName(state) || '',
        lastName: getApplicantLastName(state) || '',
        email: getApplicantEmail(state),
        phone: getApplicantPhone(state),
        postalCode: getApplicantPostalCode(state),
        region: getApplicantRegion(state) || selectLocaleRegion(state)
    };

    return initialValues;
};

export const getApplicantPartnerAgreement = (state: any): boolean | undefined =>
    path(['account', 'partnerAgreement'], state.account);

export const getApplicantEmailConsent = (state: any): boolean | undefined =>
    path(['account', 'emailConsent'], state.account);

export const getUserConsents = (state: any): any => {
    const initialValues = {
        partnerAgreement: getApplicantPartnerAgreement(state) || false,
        emailConsent: getApplicantEmailConsent(state) || false
    };

    return initialValues;
};

export const getAccountId = (state: any): string | undefined =>
    path(['account', 'id'], state.account);

export const getApplicantLanguage = (state: any): Language =>
    path(['account', 'preferredLanguage'], state.account) || Language.English;

export const getSettingsLoading = (state: any): boolean =>
    path(['dashboard', 'loading'], state.dashboard) || false;

export const getBrokerLanguage = (state: any): Language =>
    path(['broker', 'preferredLanguage'], state.account) || Language.English;

export const getRole = (state: any): 'borrower' | 'broker' | undefined =>
    path(['broker', 'role'], state.account) ||
    path(['account', 'role'], state.account);

export const isBroker = (state: any): boolean =>
    !!path(['broker', 'id'], state.account);

export const isBehalfAUser = (state: any): boolean =>
    !!(getRole(state) === 'broker' && getAccountId(state));

export const isBrokerLoginPage = state =>
    state.router.location.pathname === Routes.brokersLogin;

export const getUserLanguage = (state: any): Language =>
    getApplicantLanguage(state);

export const getUsersOtherLanguage = (state: any): Language => {
    const lang = getUserLanguage(state);
    return lang === Language.English ? Language.French : Language.English;
};

export const getSubPartnerId = sessionStorage.getItem('subPartnerId');

export const getAccountRidFromParams = (state: any): string | undefined => {
    // @ts-ignore
    const { accountRid }: Record<string, string> = qs.parse(
        state.router.location.search,
        {
            ignoreQueryPrefix: true
        }
    );

    return accountRid;
};

export const getFeatureFlaggerUserData = (locale, email, id) => ({
    ip: locale.ip,
    country: locale.country,
    email: email,
    custom: {
        id: id
    }
});

const getLocale = state => state.locale;

export const memorizedGetFeatureFlaggerUserData = createCachedSelector(
    getLocale,
    getApplicantEmail,
    getAccountId,
    getFeatureFlaggerUserData
)(state => (state.account.loading ? 1 : 0));

export const getImpressionsTrackingId = (state: any) =>
    path<string>(['impressionsTrackingId'], state.account);

export const getAffiliateMarketingId = (state: AccountState) =>
    path<string>(['affiliateMarketingId'], state.account);

export const getAccountImpressionsTrackingId = (state: any) =>
    path<string>(['account', 'impressionsTrackingId'], state.account);

export const getHasImpressionsTrackingId = (state: any): boolean => {
    const impressionsTrackingId = getImpressionsTrackingId(state);
    const accountImpressionsTrackingId = getAccountImpressionsTrackingId(state);

    return !!(impressionsTrackingId || accountImpressionsTrackingId);
};

export const getCommunicationSettings = (state: any) =>
    path<CommunicationPreferences>(['communicationPreferences'], state.account);

export const selectGetAccountDeletionState = (state: AccountState) =>
    path<AccountDeletionState>(['accountDeletionState'], state.account);
