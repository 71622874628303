import { useState, useEffect } from 'react';
import { MapSizesToProps, Size } from './types';

/**
 *
 * @param mapSizesToProps MapSizesToProps
 *
 * Usage:
 * ```
 * const sizes = useSizes({ isMobile, isTablet, isDesktop });
 *
 * {
 *      width: 1200,
 *      height: 800,
 *      isMobile: false
 *      isTablet: false,
 *      isDesktop: true
 * }
 * ```
 */

type SizesType = {
    isMobile?: boolean;
    isTablet?: boolean;
    isDesktop?: boolean;
} & Size;
export const useSizes = (mapSizesToProps: MapSizesToProps): SizesType => {
    const isClient = typeof window === 'object';

    const parseMappedSizesToProps = (
        mapSizesToProps: MapSizesToProps,
        size: Size
    ) =>
        Object.entries(mapSizesToProps).reduce(
            (acc, [prop, fn]) => ({
                ...acc,
                [prop]: fn(size)
            }),
            {}
        );

    function getSize(): Size {
        return {
            width: isClient ? window.innerWidth : 0,
            height: isClient ? window.innerHeight : 0
        };
    }

    const size = getSize();
    const propsToPass: Record<string, boolean> = parseMappedSizesToProps(
        mapSizesToProps,
        size
    );

    const [windowSize, setWindowSize] = useState({
        ...size,
        ...propsToPass
    });

    useEffect(() => {
        if (!isClient) {
            return;
        }

        function handleResize() {
            const size = getSize();
            const propsToPass: Record<
                string,
                boolean
            > = parseMappedSizesToProps(mapSizesToProps, size);
            setWindowSize({ ...size, ...propsToPass });
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, []); // Empty array ensures that effect is only run on mount and unmount --

    return windowSize;
};
