import { getCurrencyByLocale } from 'utils/localization-utils';
import { CurrencyProps, StateProps } from './currency.component';

type InterfaceWithReqs = Omit<CurrencyProps, 'id' | 'sign'> & StateProps;

const rangeText = { en: 'to', fr: 'à' };

const formatCurrency = ({ language, amount, range }: InterfaceWithReqs) => {
    if (range) {
        if (amount <= range) {
            return `< ${getCurrencyByLocale(language, range)}`;
        }
        return `${getCurrencyByLocale(language, amount - range)} ${
            rangeText[language]
        } ${getCurrencyByLocale(language, amount)}`;
    }
    return getCurrencyByLocale(language, amount);
};

export default formatCurrency;
