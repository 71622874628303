import React from 'react';

type Props = {
    fillColor?: string;
    width?: number;
    height?: number;
};
const CheckMark = ({
    fillColor = '#d5d5d5',
    width = 16,
    height = 16
}: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width}
        height={height}
        viewBox="0 0 16 16"
    >
        <defs>
            <path
                id="hyq0a"
                d="M2353.601 639.557l6.834-7.235a1 1 0 0 1 1.47 1.356l-7.54 8a1 1 0 0 1-1.442.03l-3.63-3.63a1 1 0 0 1 1.414-1.415z"
            />
            <path id="hyq0c" d="M2347 645v-16h16v16z" />
            <clipPath id="hyq0b">
                <use xlinkHref="#hyq0a" />
            </clipPath>
        </defs>
        <g>
            <g transform="translate(-2347 -629)">
                <g>
                    <use fill="#979797" xlinkHref="#hyq0a" />
                </g>
                <g clipPath="url(#hyq0b)">
                    <g>
                        <use fill={fillColor} xlinkHref="#hyq0c" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export { CheckMark };
