import styled from 'styled-components';
import { readOnlyInput } from '../shared/styles';

export const MockedInputWrapper = styled.div`
    word-break: normal;
    font-family: 'Montserrat', sans-serif, Arial, Helvetica;
    box-sizing: border-box;

    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    border-radius: 6px;
    font-size: 14px;
    height: 60px;
    width: 100%;
    color: #333333;
    padding: calc(10px + 15px - (15px / 3)) 40px 10px 10px;

    background-color: #f8f8f8;
    border-color: #d5d5d5;
    border-width: 1px;
    border-style: solid;

    ${readOnlyInput({ readOnly: true })}
`;
