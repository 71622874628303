export const APPLICATION_PROGRESS_BAR_INFO = {
    // Creation of co-applicant is same as identification (applicant-info)
    'create-coapplicant': {
        section: 'sidebar.identification'
    },
    'applicant-info': {
        section: 'sidebar.identification'
    },
    'registered-address': {
        section: 'sidebar.registeredAddress'
    },
    'employment-situation': {
        section: 'sidebar.income'
    },
    bankruptcy: {
        section: 'sidebar.income'
    },
    'has-bankruptcy': {
        section: 'sidebar.income'
    },
    'other-income': { section: 'sidebar.otherIncome', time: 1, stepIndex: 3 },
    'number-of-properties': { section: 'sidebar.ownedProperty' },
    'other-property': {
        section: 'sidebar.ownedProperty'
    },
    // This page is shown once only and is a breakdown of downpayment
    // That's  why we kept same section and stepIndex
    'existing-property-downpayment': {
        section: 'sidebar.downPayment'
    },
    'assets-downpayment': {
        section: 'sidebar.downPayment'
    },
    'banking-details': {
        section: 'sidebar.bankingDetails'
    },
    'target-property': {
        section: 'sidebar.targetProperty'
    },
    'renewing-property': {
        section: 'sidebar.renewingProperty'
    },
    submit: {
        section: 'sidebar.sendToReview'
    }
};

export const APPLICATION_PROGRESS_BAR_STEPS = {
    applicantInformation: 2,
    registeredAddress: 3,
    income: 5,
    otherIncome: 1,
    otherProperties: 2,
    downpayment: 5,
    bankingDetails: 1
};

export const APPLICATION_PROGRESS_BAR_TOTAL_STEPS = 6;

export const APPLICATION_PROGRESS_BAR_TARGET_PROPERTY = 5;

export const APPLICATION_PROGRESS_BAR_SELECT_RATE = 2;

export const APPLICATION_PROGRESS_BAR_SUBMIT = 1;

export const APPLICATION_PROGRESS_BAR_EXISTING_PROPERTY_DOWNPAYMENT = 5;

export const APPLICATION_PROGRESS_BAR_CREATE_CO_APPLICANT = 2;

export const DEFAULT_GAQ_VALUES = {
    ownerOccupied: true,
    propertyType: 'HOME_OR_CONDO',
    lender: 'OTHER',
    lenderOther: 'part of AB test'
};
