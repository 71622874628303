import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'reflexbox/styled-components';
import { format } from 'date-fns';
import { Field, WrappedFieldProps } from 'redux-form';
import styled from 'styled-components/macro';
import * as Validators from 'lib/validators';
import { Divider } from 'components/divider';
import { InputError } from 'components/inputs/input-error/input-error.component';
import { TextInput } from 'components/inputs/text-input';
import { SpaceProps } from 'styled-system';
import { Typograph } from 'components/typograph';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { TranslationKeys } from 'messages';
import { readOnlyInput } from 'components/inputs/shared/styles';
import { css } from 'styled-components';
import { Select } from 'components/inputs/select';
import { MONTH_OPTIONS } from 'constants/appConstants';

const readOnlyStyle = ({ readOnly, theme }: any) =>
    readOnly &&
    css`
        padding: 0 0 0 10px;
        color: ${theme.input.default.color};
        background-color: #f8f8f8;
    `;

const StyledTextInput = styled(TextInput)`
    background: ${props => props.theme.colors.white};
    ${props => props.theme.input.border}
    border: none;
    width: 100%;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -moz-appearance: textfield;
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }

    > input {
        padding: 0px 12px;
    }

    ${readOnlyInput}
    ${readOnlyStyle}

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        width: 74px;
        padding-right: 10px;
    }
`;

const VerticalDivider = styled(Divider)`
    margin: 0px 4px;
    background-color: ${props => props.theme.borderColor.default};
    height: 21px;
    width: 1px;
`;

const FieldsContainer = styled(Flex)`
    padding-left: 4px;
    ${props => props.theme.input.border}
    transition: border-color ${props => props.theme.transitionAnimation};
    &:hover {
        border-color: ${({ theme, classStyle }) =>
            classStyle === 'disabled'
                ? theme.input.disabled.borderColor
                : theme.input.hover.borderColor};
    }

    border-color: ${({ classStyle, theme }) =>
        theme.input[classStyle].borderColor};
    ${readOnlyStyle}
`;

const isInValidDate = (year, month, day): boolean => {
    if (!year || +year < 1900 || +year > 2100 || !month || !day) {
        return true;
    }

    const d = new Date(`${year}/${month}/${day}`);

    if (
        d.getFullYear() === +year &&
        d.getMonth() === +month - 1 && // need to reduce month value by 1 to accommodate new Date formats the month
        d.getDate() === +day
    ) {
        return false;
    }

    return true;
};

const getDateFromState = (date: string) => {
    if (date === '' || typeof date === 'object') {
        return {
            year: '',
            month: '',
            day: ''
        };
    }

    const [year, month, dayWithTime] = date.split('-');
    const [day] = dayWithTime ? dayWithTime.split('T') : [dayWithTime];
    return {
        year,
        month,
        day
    };
};

const formatNewValue = (
    value: number | string, // ReduxForm return a string containing a number
    type: 'day' | 'month' | 'year',
    inputState: any
) => {
    const newValue = Math.abs(+value);
    if (value === '' && newValue === 0) {
        return '';
    }

    switch (type) {
        case 'day':
            return value > 31 || value === 0 ? inputState.day : newValue;
        case 'month':
            return newValue > 12 || newValue < 0 ? inputState.month : newValue;
        case 'year':
            return newValue > 9999 ? inputState.year : newValue;
        default:
            return newValue;
    }
};

const Placeholder = styled(Typograph)`
    font-size: ${({ theme }) => theme.fontSizes[2]};
    margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

type DateInputsProps = {
    placeholder: TranslationKeys;
    showMonthYear: boolean;
    /**
     * defined a specific day to the year and month combo
     */
    yearMonthInputDay?: number;
    disabled: boolean;
    onSubmit?: () => void;
    showErrorOnTouched?: boolean;
    readOnly?: boolean;
    showMonthsAsSelect?: boolean;
    showYearsAsSelect?: boolean;
    minYearOptions?: number;
    maxYearOptions?: number;
};

const getClassStyle = (isTouched, meta, disabled) => {
    if (disabled) return 'disabled';

    if (isTouched && meta.valid) return 'valid';

    if (isTouched && meta.invalid) return 'error';

    return 'default';
};

export const DateInputs = injectIntl(
    ({
        placeholder,
        input,
        meta,
        intl,
        disabled,
        showMonthYear,
        yearMonthInputDay = 1,
        onSubmit,
        showErrorOnTouched = false,
        readOnly,
        showMonthsAsSelect = false,
        showYearsAsSelect = false,
        minYearOptions = new Date().getFullYear(),
        maxYearOptions
    }: DateInputsProps & WrappedComponentProps & WrappedFieldProps) => {
        const withDay = !showMonthYear;
        const [initialized, setInitialized] = useState(false);
        const [isTouched, setTouched] = useState(false);

        const [inputState, onInputChange] = useState(
            getDateFromState(input.value)
        );

        // This one is trigger only when a parent change the value of input
        // ex: main applicant identification -> co-applicant identitification
        useEffect(() => {
            const parsedDate = getDateFromState(input.value);

            if (typeof input.value === 'string' && meta.dirty === false) {
                onInputChange(parsedDate);
            }
        }, [input.value]); // eslint-disable-line react-hooks/exhaustive-deps

        useEffect(() => {
            if (meta.submitFailed) {
                setTouched(true);
            }
        }, [meta]); // eslint-disable-line react-hooks/exhaustive-deps

        // When we change inputState format date and bubble into Redux-Form
        useEffect(() => {
            if (
                isInValidDate(
                    inputState.year,
                    inputState.month,
                    showMonthYear
                        ? inputState.day
                            ? inputState.day
                            : yearMonthInputDay
                        : inputState.day
                )
            ) {
                if (initialized) input.onChange({});

                setInitialized(true);
                return;
            }

            setInitialized(true);

            const formattedDate = format(
                new Date(
                    +inputState.year,
                    +inputState.month - 1,
                    +inputState.day ? +inputState.day : yearMonthInputDay,
                    0,
                    0,
                    0,
                    0
                ),
                'yyyy-MM-dd'
            );

            input.onChange(formattedDate);
        }, [inputState]); // eslint-disable-line react-hooks/exhaustive-deps

        const onChangeInput = (type: 'day' | 'month' | 'year') => event => {
            const formattedValue = formatNewValue(
                event.target.value,
                type,
                inputState
            );

            if (!isTouched) {
                if (withDay) {
                    if (inputState.day && inputState.month && inputState.year) {
                        setTouched(true);
                    }
                } else if (inputState.month && inputState.year) {
                    setTouched(true);
                }
            }

            onInputChange({
                ...inputState,
                [type]: formattedValue
            });
        };

        const onChangeSelect = (type: 'day' | 'month' | 'year') => (
            value: number | string
        ) => {
            const formattedValue = formatNewValue(value, type, inputState);

            if (!isTouched) {
                if (withDay) {
                    if (inputState.day && inputState.month && inputState.year) {
                        setTouched(true);
                    }
                } else if (inputState.month && inputState.year) {
                    setTouched(true);
                }
            }

            onInputChange({
                ...inputState,
                [type]: formattedValue
            });
        };

        const onKeyPress = event => {
            if (onSubmit && event.key === 'Enter') {
                onSubmit();
            }
        };

        // build the years select options based on the minYearOptions and maxYearOptions
        // if minYearOptions is not provided, we will use the current year
        // if maxYearOptions is not provided, we will use the current year + 10, [currentYear, currentYear + 1, ...currentYear + 10] -> 11 years
        const yearsOptions = Array.from(
            { length: maxYearOptions ? maxYearOptions - minYearOptions : 11 },
            (_, i) => {
                const year = minYearOptions
                    ? minYearOptions + i
                    : new Date().getFullYear() + i;

                return {
                    value: year.toString(),
                    label: year.toString()
                };
            }
        );

        return (
            <>
                {placeholder && <Placeholder tx={placeholder} />}
                <FieldsContainer
                    readOnly={readOnly}
                    alignItems="center"
                    classStyle={getClassStyle(
                        isTouched || meta.touched,
                        meta,
                        disabled
                    )}
                >
                    {withDay && (
                        <>
                            <StyledTextInput
                                name={`${input.name}-day`}
                                width="auto"
                                type="number"
                                placeholder={intl.formatMessage({ id: 'day' })}
                                hideInputErrors
                                step="1"
                                min="1"
                                max="31"
                                meta={{
                                    ...meta,
                                    touched: isTouched
                                }}
                                // @ts-ignore
                                input={{
                                    name: `${input.name}-day`,
                                    value: inputState.day,
                                    onChange: onChangeInput('day'),
                                    // @ts-ignore
                                    onKeyPress: onKeyPress
                                }}
                                disabled={disabled}
                                readOnly={readOnly}
                            />
                            <VerticalDivider />
                        </>
                    )}
                    {showMonthsAsSelect && (
                        <Select
                            name={`${input.name}-month`}
                            width="auto"
                            placeholder={intl.formatMessage({ id: 'month' })}
                            hideInputErrors
                            meta={{
                                ...meta,
                                touched: isTouched,
                                // let's hide the error message for the month select
                                // it will show on this component but we don't want to show it twice
                                error: undefined
                            }}
                            // @ts-ignore
                            input={{
                                name: `${input.name}-month`,
                                value: inputState.month,
                                onChange: onChangeSelect('month'),
                                // @ts-ignore
                                onKeyPress: onKeyPress
                            }}
                            disabled={disabled}
                            readOnly={readOnly}
                            showScrollBar
                            options={MONTH_OPTIONS}
                            translate
                            isRequired
                            withNoBorder
                        />
                    )}
                    {!showMonthsAsSelect && (
                        <StyledTextInput
                            name={`${input.name}-month`}
                            width="auto"
                            type="number"
                            step="1"
                            min="1"
                            max="12"
                            placeholder={intl.formatMessage({ id: 'month' })}
                            hideInputErrors
                            meta={{
                                ...meta,
                                touched: isTouched
                            }}
                            // @ts-ignore
                            input={{
                                name: `${input.name}-month`,
                                value: inputState.month,
                                onChange: onChangeInput('month'),
                                // @ts-ignore
                                onKeyPress: onKeyPress
                            }}
                            disabled={disabled}
                            readOnly={readOnly}
                        />
                    )}
                    <VerticalDivider />
                    {showYearsAsSelect && (
                        <Select
                            name={`${input.name}-year`}
                            width="auto"
                            placeholder={intl.formatMessage({ id: 'year' })}
                            hideInputErrors
                            meta={{
                                ...meta,
                                touched: isTouched,
                                // let's hide the error message for the year select
                                // it will show on this component but we don't want to show it twice
                                error: undefined
                            }}
                            // @ts-ignore
                            input={{
                                name: `${input.name}-year`,
                                value: inputState.year,
                                onChange: onChangeSelect('year'),
                                // @ts-ignore
                                onKeyPress: onKeyPress
                            }}
                            disabled={disabled}
                            readOnly={readOnly}
                            showScrollBar
                            options={yearsOptions}
                            isRequired
                            withNoBorder
                            stylesOverrides={{
                                menu: base => ({
                                    ...base,
                                    margin: '-2px 0px 0px 0px',
                                    borderTop: '0px',
                                    padding: 0,
                                    zIndex: 500,
                                    overflowY: 'scroll',
                                    minWidth: '90px'
                                })
                            }}
                        />
                    )}
                    {!showYearsAsSelect && (
                        <StyledTextInput
                            name={`${input.name}-year`}
                            width="auto"
                            type="number"
                            placeholder={intl.formatMessage({ id: 'year' })}
                            step="1"
                            min="1900"
                            max="2200"
                            hideInputErrors
                            meta={{
                                ...meta,
                                touched: isTouched
                            }}
                            // @ts-ignore
                            input={{
                                name: `${input.name}-year`,
                                value: inputState.year,
                                onChange: onChangeInput('year'),
                                // @ts-ignore
                                onKeyPress: onKeyPress
                            }}
                            pr={[0, 40]}
                            disabled={disabled}
                            readOnly={readOnly}
                        />
                    )}
                </FieldsContainer>

                <InputError
                    meta={{
                        ...meta,
                        touched: isTouched
                    }}
                    name={input.name}
                    showErrorOnTouched={showErrorOnTouched}
                />
            </>
        );
    }
);

type Props = {
    name: string;
    placeholder?: string;
    showMonthYear?: boolean;
    /**
     * defined a specific day to the year and month combo
     */
    yearMonthInputDay?: number;
    isRequired?: boolean;
    validate?: any[];
    id?: string;
    disabled?: boolean;
    readOnly?: boolean;
    onSubmit?: () => void;
    onChange?: (value: any) => void;
    showErrorOnTouched?: boolean;
    showMonthsAsSelect?: boolean;
    showYearsAsSelect?: boolean;
    minYearOptions?: number;
    maxYearOptions?: number;
};

const noOP = () => undefined;

const getValidators = (isRequired: boolean, values?: any[]): any[] => {
    const validators: any[] = [isRequired ? Validators.isValidDate : noOP];
    if (values) {
        validators.push(...values);
    }

    return validators;
};

export const DateField = ({
    name,
    placeholder,
    isRequired = false,
    showMonthYear = false,
    yearMonthInputDay,
    validate,
    disabled = false,
    onSubmit,
    id = '',
    showErrorOnTouched = false,
    readOnly,
    onChange,
    showMonthsAsSelect = false,
    showYearsAsSelect = false,
    minYearOptions,
    maxYearOptions,
    ...rest
}: Props & SpaceProps) => (
    <Box mt={3} width="100%" {...rest}>
        <Field
            component={DateInputs}
            disabled={disabled}
            data-test-id={id ? id : name}
            placeholder={placeholder}
            readOnly={readOnly}
            showMonthYear={showMonthYear}
            yearMonthInputDay={yearMonthInputDay}
            name={name}
            validate={getValidators(isRequired, validate)}
            onSubmit={onSubmit}
            onChange={onChange}
            showMonthsAsSelect={showMonthsAsSelect}
            showYearsAsSelect={showYearsAsSelect}
            minYearOptions={minYearOptions}
            maxYearOptions={maxYearOptions}
        />
    </Box>
);
