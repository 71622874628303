import { Options } from 'types/fields';

export const TERMS: Options = [
    {
        label: 'rates.termText.six_months',
        value: '6_MONTHS'
    },
    {
        label: 'rates.termText.one_year',
        value: '1_YEAR'
    },
    {
        label: 'rates.termText.three_years',
        value: '3_YEAR'
    },
    {
        label: 'rates.termText.five_years',
        value: '5_YEAR'
    }
];

export const AMORTIZATION: Options = [
    {
        label: 'rates.termText.five_years',
        value: 5
    },
    {
        label: 'rates.termText.ten_years',
        value: 10
    },
    {
        label: 'rates.termText.fifteen_years',
        value: 15
    },
    {
        label: 'rates.termText.twenty_years',
        value: 20
    },
    {
        label: 'rates.termText.twenty_five_years',
        value: 25
    },
    {
        label: 'rates.termText.thirty_years',
        value: 30
    }
];

export const TERM_TYPE: Options = [
    {
        label: 'rates.rateType.variable',
        value: 'VARIABLE'
    },
    {
        label: 'rates.rateType.fixed',
        value: 'FIXED'
    }
];

export const TERM_TYPE_OTHER_PROPERTY: Options = [
    {
        value: 'ADJUSTABLE',
        label: 'application.otherProperty.adjustable'
    },
    {
        value: 'CAPPED_VARIABLE',
        label: 'application.otherProperty.cappedVariable'
    }
];

export const TERM_TYPE_RENEWING_PROPERTY: Options = [
    {
        value: 'ADJUSTABLE',
        label: 'application.renewingProperty.adjustable'
    },
    {
        value: 'CAPPED_VARIABLE',
        label: 'application.renewingProperty.cappedVariable'
    }
];

export const PROPERTY_TYPE: Options = [
    {
        value: 'HOME_OR_CONDO',
        label: 'propertyType.houseOrCondo'
    },
    {
        value: 'SECOND_HOME_OR_COTTAGE',
        label: 'propertyType.secondHomeOrCottage'
    },
    {
        value: 'DUPLEX',
        label: 'propertyType.duplex'
    },
    {
        value: 'TRIPLEX',
        label: 'propertyType.triplex'
    },
    {
        value: 'FOURPLEX',
        label: 'propertyType.fourplex'
    }
];

export const PROPERTY_TYPE_WITH_COMMERCIAL: Options = [
    ...PROPERTY_TYPE,
    {
        value: 'COMMERCIAL',
        label: 'propertyType.commercial'
    }
];

export enum downPaymentRatios {
    TWENTY_MORE = 'TWENTY_MORE',
    TWENTY_LESS = 'TWENTY_LESS',
    UNKNOWN = 'UNKNOWN'
}

export const DOWN_PAYMENT_RATIO: Options = [
    {
        label: 'yes',
        value: downPaymentRatios.TWENTY_LESS
    },
    {
        label: 'no',
        value: downPaymentRatios.TWENTY_MORE
    },
    {
        label: 'getAQuote.downpaymentremember',
        value: downPaymentRatios.UNKNOWN
    }
];
