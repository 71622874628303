import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Box } from 'reflexbox/styled-components';

type AccordianKey = number | string | null;

type Props = {
    children: (
        isOpenAt: AccordianKey,
        toggleAt: (i: AccordianKey) => void,
        close: () => void
    ) => any;
    initialState?: AccordianKey;
    [rest: string]: any;
};

const Row = styled(Box)`
    height: auto;
`;

export const Accordion = ({
    children,
    initialState = null,
    ...rest
}: Props) => {
    const [isOpenAt, toggleAt] = useState<AccordianKey>(initialState);
    const close = () => toggleAt(null);

    useEffect(() => {
        toggleAt(initialState);
    }, [initialState]);

    return <Row {...rest}>{children(isOpenAt, toggleAt, close)}</Row>;
};
