import * as React from 'react';
import { Box } from 'reflexbox/styled-components';

const BoxContained = ({ children, ...props }: any) => (
    <Box width="100%" maxWidth="1024px" {...props}>
        {children}
    </Box>
);

export { BoxContained };
