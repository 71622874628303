import React from 'react';

import { Layout } from 'layouts/content-with-header';
import { BaseRoute, RouteProps } from './BaseRoute';

type Props = {
    hideLogin?: boolean;
    hideLogout?: boolean;
};

export const PublicRoute = ({
    hideLogin = false,
    hideLogout = false,
    ...props
}: Props & RouteProps) => (
    <Layout hideLogin={hideLogin} hideLogout={hideLogout}>
        <BaseRoute {...props} />
    </Layout>
);
