import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled, { withTheme, ThemeProps } from 'styled-components/macro';
import { space, SpaceProps, MaxWidthProps } from 'styled-system';
import { Flex } from 'reflexbox/styled-components';
import { Theme } from '@nesto/themes';

import { getCurrentPath } from 'reducers/startup.redux';
import { getApplicantLanguage } from 'reducers/account.selectors';
import { Language } from 'types/language';

const ImageWrapper = styled.div`
    height: 60px;
`;

const StyledImage = styled.img<SpaceProps>`
    height: 100%;
    object-fit: contain;
    ${space}
`;

const Wrapper = styled(Flex)`
    height: 100%;
    align-items: center;
`;

type StateProps = {
    language: Language;
    currentPath: string;
};

type Props = MaxWidthProps & SpaceProps;

const LogoView = ({
    theme,
    language,
    currentPath,
    ...rest
}: StateProps & Props & ThemeProps<Theme>) => {
    const logoMetadata = theme.logo;
    const nestoLandingUrl =
        language === 'en' ? 'https://nesto.ca/' : 'https://nesto.ca/fr';
    return (
        <Wrapper>
            <ImageWrapper>
                {currentPath === '/getaquote' ? (
                    <a href={nestoLandingUrl}>
                        <StyledImage
                            src={logoMetadata[language]}
                            width={logoMetadata.width}
                            height={logoMetadata.height}
                            alt="Nesto"
                            {...rest}
                        />
                    </a>
                ) : (
                    <StyledImage
                        src={logoMetadata[language]}
                        width={logoMetadata.width}
                        height={logoMetadata.height}
                        alt="Nesto"
                        {...rest}
                    />
                )}
            </ImageWrapper>
            <img
                id="appBlankSpacer"
                src="/images/spacer.png"
                width="5"
                height="5"
                alt="small blank png used as a hook for ab testing"
            />
        </Wrapper>
    );
};

export const Logo = compose(
    withTheme,
    connect<StateProps>(state => ({
        language: getApplicantLanguage(state) || 'en',
        currentPath: getCurrentPath(state)
    }))
)(LogoView) as React.FC<Props>;
