import { TypographI18nKeys } from 'components/typograph';

export type AppTrackerStatusProps = 'DEFAULT' | 'COMPLETED' | 'ACTIVE';

export enum AppTrackerStatus {
    Active = 'ACTIVE',
    Complete = 'COMPLETE',
    Default = 'DEFAULT'
}

export type AppTrackerMessagePositionProps = 'RIGHT' | 'LEFT';

export type DefaultAppTrackerProps = {
    order: number;
    status: AppTrackerStatusProps;
    messagePosition: AppTrackerMessagePositionProps;
    time?: string;
    title: TypographI18nKeys;
    messageTitle?: TypographI18nKeys;
    text: TypographI18nKeys;
    documents?: number;
    totalDocs?: number;
    link?: string;
    linkText?: string;
    scheduleCall?: boolean;
};

export const APP_TRACKER_STATE_VALUES = {
    CREATED: 'inProgress',
    SUBMITTED: 'submitted',
    UNDER_REVISION: 'underReview',
    REVIEWED: 'underReview',
    NOTES_SUBMITTED: 'underReview',
    LENDER_SUBMITTED: 'submittedLender',
    LENDER_APPROVED: 'submittedLender',
    PENDING_COMMITMENT_SIGNATURE: 'approval',
    PENDING_CONDITIONS: 'documents',
    COMPLETE: 'complete',
    NOTARY_ALERTED: 'complete',
    FUNDED: 'complete',
    CLOSED: 'complete',
    EXPIRED: 'complete'
};

type UnderReviewMessages = {
    [key: string]: TypographI18nKeys;
};

export const UNDER_REVIEW_MESSAGES_NO_DOCS = {
    UNDER_REVISION: 'applicationUnderReview.messageNoDocs',
    REVIEWED: 'applicationUnderReview.reviewedMessageNoDocs',
    NOTES_SUBMITTED: 'applicationUnderReview.notesMessageNoDocs'
} as UnderReviewMessages;

export const UNDER_REVIEW_MESSAGES = {
    UNDER_REVISION: 'applicationUnderReview.message',
    REVIEWED: 'applicationUnderReview.reviewedMessage',
    NOTES_SUBMITTED: 'applicationUnderReview.notesMessage'
} as UnderReviewMessages;

export const APP_TRACKER_DEFAULT_VALUES = {
    inProgress: {
        order: 1,
        status: 'DEFAULT',
        messagePosition: 'RIGHT',
        title: 'applicationInProgress',
        messageTitle: 'applicationTitle',
        text: 'applicationInProgress.message',
        link: '/application/applicant-info',
        linkText: 'continueApplication'
    },
    submitted: {
        order: 2,
        status: 'DEFAULT',
        messagePosition: 'RIGHT',
        title: 'applicationSubmitted',
        messageTitle: 'applicationTitle',
        text: 'applicationSubmitted.messageNoDocs',
        link: '/application/documents?step=1&sectionName=application.documents',
        linkText: 'applicationUnderReview.link'
    },
    underReview: {
        order: 3,
        status: 'DEFAULT',
        messagePosition: 'RIGHT',
        title: 'applicationUnderReview',
        messageTitle: 'applicationTitle',
        link: '/application/documents?step=1&sectionName=application.documents',
        linkText: 'applicationUnderReview.link',
        text: 'applicationUnderReview.messageNoDocs'
    },
    submittedLender: {
        order: 4,
        status: 'DEFAULT',
        messagePosition: 'LEFT',
        title: 'submittedToLender',
        messageTitle: 'applicationTitle',
        link: '/application/documents?step=1&sectionName=application.documents',
        linkText: 'applicationUnderReview.link',
        text: 'submittedToLender.messageNoDocs'
    },
    approval: {
        order: 5,
        status: 'DEFAULT',
        messagePosition: 'LEFT',
        time: '4-72hrs',
        messageTitle: 'applicationTitle',
        title: 'signingApprovalLetter',
        text: 'signingApprovalLetter.message',
        link: '/application/documents?step=1&sectionName=application.documents',
        linkText: 'applicationUnderReview.link'
    },
    documents: {
        order: 6,
        status: 'DEFAULT',
        messagePosition: 'LEFT',
        title: 'remainingDocument',
        messageTitle: 'applicationTitle',
        link: '/application/documents?step=1&sectionName=application.documents',
        linkText: 'applicationUnderReview.link',
        text: 'remainingDocument.messageNoDocs'
    },
    complete: {
        order: 7,
        status: 'DEFAULT',
        messagePosition: 'LEFT',
        title: 'applicationComplete',
        messageTitle: 'applicationTitle',
        time: '4-48hrs',
        text: 'applicationComplete.message'
    }
} as { [key: string]: DefaultAppTrackerProps };
