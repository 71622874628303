import { ApiResponse } from 'apisauce';

type Log = {
    error: Error | string;
    tag?: string;
    data?: object;
};

const blackListedErrorCodes = [400, 401, 403, 404, 409, 422, 429];
const blackListedReasons = [
    'invalid password',
    'entity not found',
    'password not set',
    'invalid credentials'
];

export const log = ({ error, tag = 'error', data }: Log): void => {
    const isBlacklistedReason = blackListedReasons.includes(error as string);

    if (window.config.env === 'test' || isBlacklistedReason) return;
    !!window.DD_RUM && window.config.env === 'production'
        ? window.DD_RUM.addError(error, { ...data })
        : // tslint:disable-next-line:no-console
          console.log({ error, data });
};

export const logMonitor = (res: ApiResponse<any>) => {
    const { ok, status, duration, problem, config, headers } = res;

    const isBlacklistedStatusCode =
        status && blackListedErrorCodes.includes(status);
    const isBlacklistedReason =
        res.data &&
        res.data.reason &&
        blackListedReasons.includes(res.data.reason);

    if (!ok && !isBlacklistedStatusCode && !isBlacklistedReason) {
        log({
            error: `Http Error (${status || problem}): ${problem}`,
            tag: 'http-error',
            data: {
                status,
                problem,
                ok,
                duration,
                url: config && config.url,
                method: config && config.method,
                requestId: headers && headers[`x-request-id`],
                authenticated: !!(config && config.headers?.[`Authorization`])
            }
        });
    }
};
