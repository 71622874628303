export function parseCurrencyValue(
    value: number | undefined,
    placeholder: number
) {
    if (value === undefined || (typeof value === 'string' && value === '')) {
        return placeholder;
    }

    return value;
}

export function showRange(value, range) {
    if (!range) return false;
    if (value >= range) {
        return range;
    }
}
