import React, { useState, useEffect, useCallback, useRef } from 'react';

import { BaseLoader } from 'components/loader/base-loader.component';

type Props = {
    size?: number;
    startMs?: number;
    color?: string;
};

// 'til we get concurrent mode in React
// we can fake a delay before showing the loader
// to avoid showing an undesirable loading state
// From Dan Abramov's suggestion here:
// https://github.com/facebook/react/issues/14326#issuecomment-441680293
const useSleepAndWake = (timeoutMs: number) => {
    let intervalId: any;
    const didCancel = useRef(false);

    const [isSleeping, setIsSleeping] = useState(true);

    const cancel = useCallback(() => {
        didCancel.current = true;
        clearTimeout(intervalId);
    }, [intervalId]);

    const promise: Promise<void> = new Promise(resolve => {
        intervalId = setTimeout(resolve, timeoutMs);
    }).then(() => {
        if (!didCancel.current) {
            setIsSleeping(false);
        }

        cancel();
    });

    return {
        isSleeping,
        isAwake: !isSleeping,
        promise,
        cancel,
        intervalId
    };
};

export const Loader = ({ size, startMs = 1000, color }: Props) => {
    const { isAwake, cancel } = useSleepAndWake(startMs);

    // Cancel timeout promise on component unmount
    useEffect(
        () => cancel,
        [cancel] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return isAwake ? <BaseLoader size={size} color={color} /> : null;
};
