import { Box, Flex } from 'components/reflexbox';
import { Typograph } from '@nesto/design-system';
import { Icon } from '@nesto/design-system/v2';

import { useDocuments } from 'components/documents-new/documents-hooks';
import { countDocumentsBySection } from './documents.utils';
import { MobileNavWrapper, Nav, NavBarElement, Pill } from './documents.styles';
import { useIsMobile, useTheme } from 'utils/hooks';
import { useFeatureFlag } from 'components/feature-flagger/hooks';

type Props = { isOpen: boolean; setIsOpen: any };

const CompletedDocs = () => {
    const { colors } = useTheme();
    return (
        <Flex
            data-test-id="status_red"
            justifyContent={'center'}
            alignItems={'center'}
            mr={2}
            mb={'4px'}
            css={{
                borderRadius: '50%',
                width: '16px',
                height: '16px',
                backgroundColor: colors.screaminGreen
            }}
        >
            <Icon name="check" color="white" />
        </Flex>
    );
};

const Applicants = ({ isOpen, setIsOpen }: Props) => {
    const {
        groupedDocuments,
        selectedDocumentsGrouped,
        setActiveApplicant,
        applicants,
        docsHasLoaded
    } = useDocuments();
    const isMobile = useIsMobile();

    const onClickHandler = (applicantId: number) => {
        if (isMobile) {
            if (isOpen) {
                setActiveApplicant(applicantId);
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
        } else {
            setActiveApplicant(applicantId);
        }
    };

    return (
        <Nav
            p={0}
            isMobile={isMobile}
            isOpen={isOpen}
            css={{ gap: '1rem' }}
            applicants={applicants.map(
                applicant => `'${applicant.applicantId.toString()}a'`
            )}
        >
            {groupedDocuments &&
                applicants &&
                groupedDocuments.map(({ applicantId, allDocs }) => {
                    const { uploadedDocs, totalDocs } = countDocumentsBySection(
                        allDocs
                    );

                    const [selectedApplicant] = applicants.filter(
                        applicant => applicant.applicantId === applicantId
                    );

                    const { firstName, lastName } = selectedApplicant;

                    if (!docsHasLoaded) return null;

                    const isSelected =
                        applicantId === selectedDocumentsGrouped?.applicantId;

                    return (
                        <NavBarElement
                            key={applicantId}
                            selected={isSelected}
                            onClick={() =>
                                applicants.length > 1 &&
                                onClickHandler(applicantId)
                            }
                            css={{
                                gridArea: isSelected
                                    ? 'selected'
                                    : applicantId.toString() + 'a'
                            }}
                            isOpen={isMobile && !isOpen}
                            pb={isMobile ? 0 : 1}
                            isDocuments
                        >
                            <span className="userName">{`${firstName} ${lastName}`}</span>
                            {uploadedDocs === totalDocs ? (
                                <CompletedDocs />
                            ) : (
                                <Pill
                                    mb={0}
                                >{`${uploadedDocs} / ${totalDocs}`}</Pill>
                            )}
                        </NavBarElement>
                    );
                })}
        </Nav>
    );
};

export const MobileNavBar = ({
    isOpen,
    setIsOpen,
    isDocuments
}: Props & { isDocuments: boolean }) => {
    const showDocumentsNewDesign = useFeatureFlag<boolean>(
        'show-documents-new-design'
    );
    const theme = useTheme();
    const isMobile = useIsMobile();

    const { applicants } = useDocuments();

    return isMobile && isDocuments && showDocumentsNewDesign ? (
        <MobileNavWrapper
            isOpen={isOpen}
            style={{
                borderTop: '1px solid',
                borderColor: theme.colors.mercury,
                paddingLeft: theme.space[3],
                paddingRight: theme.space[3]
            }}
        >
            {applicants.length > 1 && (
                <Box className="icon">
                    <Icon name={'arrow-down'} size={'32px'} />
                </Box>
            )}
            <Applicants {...{ isOpen, setIsOpen }} />
        </MobileNavWrapper>
    ) : null;
};

export const DocumentsNavBar = () => {
    const theme = useTheme();
    const isMobile = useIsMobile();

    return (
        <Flex
            css={{
                width: '100%',
                backgroundColor: 'white',
                padding: !isMobile
                    ? `0px ${theme.space[4]}px ${theme.space[3]}px ${theme.space[4]}px`
                    : undefined,
                justifyContent: 'center'
            }}
        >
            <Flex flexDirection="column" width={[875, 1075]}>
                <Box width={'100%'} justifyContent={'space-between'}>
                    {!isMobile && (
                        <Typograph
                            fontSize={5}
                            as="h1"
                            my={3}
                            tx="sidebar.documents"
                        />
                    )}

                    <Flex p={0} css={{ gap: '1rem' }}>
                        {!isMobile && (
                            <Applicants
                                {...{ isOpen: false, setIsOpen: () => null }}
                            />
                        )}
                    </Flex>
                </Box>
            </Flex>
        </Flex>
    );
};
