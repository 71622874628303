import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { Flex } from 'reflexbox/styled-components';
import { Link } from 'react-router-dom';

import { Typograph } from 'components/typograph';
import { Button } from 'product-selection/components/buttons/button.component';
import { Actions as ApplicationActions } from 'reducers/application.redux';
import { getSubmitFilogixState } from 'reducers/application.selectors';
import {
    getApplicantFullName,
    isBehalfAUser,
    isBroker
} from 'reducers/account.selectors';

type StateProps = {
    fullName: string;
    isSubmitted?: boolean;
    isRepresentingUser: boolean;
    showBrokerBadge: boolean;
};

type DispatchProps = {
    submitFilogix: () => void;
};

const FixedBadge = styled(Flex)`
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    min-height: 80px;
    background-color: white;
    z-index: 1;
`;

const BrokerBannerView = ({
    fullName,
    isSubmitted,
    submitFilogix,
    isRepresentingUser,
    showBrokerBadge
}: StateProps & DispatchProps) =>
    showBrokerBadge ? (
        <FixedBadge
            alignItems="center"
            justifyContent="center"
            flexWrap={['initial', 'wrap']}
            py={[2]}
            px={[2, 0]}
            flexDirection={['column', 'row']}
        >
            <Typograph
                my={[2]}
                px={[3]}
                fontSize={[3]}
                fontWeight="bold"
                data-test-id="broker-logged-in"
                color="sunsetOrange"
            >
                Broker
            </Typograph>
            {isRepresentingUser && (
                <Typograph
                    my={[2]}
                    px={[3]}
                    tx="badge.loggedOnBehalf"
                    values={{ fullName }}
                    fontSize={[2]}
                    data-test-id="broker-representing-user"
                    fontWeight="bold"
                    color="sunsetOrange"
                />
            )}

            {isSubmitted && (
                <Link to="/application/documents/closing">
                    <Button
                        size="small"
                        fontSize={[0]}
                        my={[1, 0]}
                        mr={[2]}
                        label="application.closingDocumentsButton"
                    />
                </Link>
            )}
            {isSubmitted && (
                <Button
                    onClick={submitFilogix}
                    size="small"
                    fontSize={[0]}
                    my={[1, 0]}
                    mr={[2]}
                    label="application.submitFilogix"
                />
            )}
        </FixedBadge>
    ) : null;

export const BrokerBanner = connect<StateProps, DispatchProps>(
    (state: any) => ({
        fullName: getApplicantFullName(state) || 'Name not specified',
        isRepresentingUser: isBehalfAUser(state),
        showBrokerBadge: isBroker(state),
        isSubmitted: getSubmitFilogixState(state)
    }),
    (dispatch: any) => ({
        submitFilogix: () =>
            dispatch(ApplicationActions.submitApplicationFilogix())
    })
)(BrokerBannerView);
