import { AccountTypes } from './account.redux';
import { createReducer, createActions } from 'reduxsauce';
import { TypographI18nKeys } from 'components/typograph';
import { AppearanceTypes } from 'components/toast/toast';

/* ------------- NOTIFICATION CONSTANTS ------------- */
export const accountErrorsNotification = {
    coapplicantAlreadyExists: {
        text: 'toasts.duplicateCoapplicant.message',
        action: false
    },
    'duplicate entity': {
        title: 'toasts.duplicateAccount.title',
        text: 'toasts.duplicateAccount.message',
        action: true
    },
    'entity not found': {
        title: 'toasts.invalidPassword.title',
        text: 'toasts.invalidPassword.message',
        action: true
    },
    'password not set': {
        title: 'toasts.passwordNotSet.title',
        text: 'toasts.passwordNotSet.message',
        action: true
    },
    'invalid password': {
        title: 'toasts.invalidPassword.title',
        text: 'toasts.invalidPassword.message',
        action: true
    },
    'invalid credentials': {
        title: 'toasts.invalidPassword.title',
        text: 'toasts.invalidPassword.message',
        action: true
    }
};
/* ------------- NOTIFICATION CONSTANTS  ------------- */

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    notifications: ['notifications'],
    notificationsRemove: ['id']
});

export { Creators };

export const successNotification = (notification: NotificationAction) => ({
    type: Types.NOTIFICATIONS,
    notification: {
        type: 'success',
        delay: 6000,
        ...notification
    }
});

export const errorNotification = (notification: NotificationAction) => ({
    type: Types.NOTIFICATIONS,
    notification: {
        type: 'error',
        delay: 10000,
        ...notification
    }
});

export const warningNotification = (notification: NotificationAction) => ({
    type: Types.NOTIFICATIONS,
    notification: {
        type: 'warning',
        delay: 10000,
        ...notification
    }
});

export const infoNotification = (notification: NotificationAction) => ({
    type: Types.NOTIFICATIONS,
    notification: {
        type: 'info',
        delay: 6000,
        ...notification
    }
});

export const NotificationsTypes = Types;

export const INITIAL_STATE = {
    notifications: []
};

const DEFAULT_NOTIFICATION = {
    delay: 6000,
    type: 'default'
};

export type NotificationProps = {
    notification: Notification;
};

export type Notification = {
    id: string;
    type: AppearanceTypes;
    title: TypographI18nKeys;
    text: TypographI18nKeys;
    delay: number;
};

export type NotificationAction = {
    title?: string;
    text: string;
    delay?: number;
};

const updateNotification = (
    state: any,
    { notification }: NotificationProps
) => ({
    ...state,
    notifications: [
        ...state.notifications,
        {
            ...DEFAULT_NOTIFICATION,
            ...notification,
            id: `${notification.type}_${Math.random()}`
        }
    ]
});

const removeNotification = (state: any, { id }: { id: string }) => ({
    ...state,
    notifications: state.notifications.filter(val => val.id !== id)
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.NOTIFICATIONS]: updateNotification,
    [Types.NOTIFICATIONS_REMOVE]: removeNotification,
    [AccountTypes.LOGOUT]: () => INITIAL_STATE
});
