import { getApplicationId } from './../reducers/application.selectors';
import { call, select, put } from 'redux-saga/effects';

import { apiClient } from 'services/api';
import {
    Payload,
    PayloadProductSummary,
    Types
} from 'reducers/product-selection.redux';
import { Params as NextParams } from 'product-selection/hooks';
import { errorNotification } from 'reducers/notifications.redux';
import { log } from 'utils/logging';
import { push } from 'connected-react-router';
import { Step } from 'product-selection/models';
import { Actions } from 'reducers/product-selection.redux';

type ProductSelectionAction = ReturnType<typeof Actions['productSelection']>;

export function* productSelection({ params }: ProductSelectionAction) {
    try {
        const applicationId: number = yield select(getApplicationId);

        const { ok, data } = yield call(
            apiClient.productSelection,
            applicationId,
            params
        );

        if (!ok) {
            throw new Error(data.error);
        }
        yield put({
            type: Types.SELECTION_SUCCESS,
            selection: data
        });
    } catch (error) {
        yield put({
            type: Types.SELECTION_ERROR,
            error
        });
    }
}

export function* stepInfoRequest({ section }: { type: string; section: Step }) {
    try {
        const applicationId: number = yield select(getApplicationId);
        const { ok, data } = yield call(
            apiClient.productSelectionStepInfo,
            applicationId,
            section
        );
        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: Types.STEP_INFO_SUCCESS,
            section,
            data
        });
    } catch (error) {
        yield put({
            type: Types.STEP_INFO_ERROR,
            error
        });
    }
}

export function* step({
    section,
    payload,
    next,
    value,
    queryParams
}: {
    type: string;
    section: Step;
    payload: Payload;
    next: (value?: any, queryParams?: NextParams) => void;
    value?: any;
    queryParams?: NextParams;
}) {
    try {
        const applicationId: number = yield select(getApplicationId);
        const { ok, data } = yield call(
            apiClient.productSelectionStep,
            applicationId,
            section,
            payload
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: Types.STEP_SUCCESS,
            section,
            data
        });

        if (
            section === 'PRODUCT_SUMMARY' &&
            (payload as PayloadProductSummary).summaryConfirmation ===
                'CONFIRMED'
        ) {
            if (!(payload as PayloadProductSummary).noRedirect) {
                yield put(
                    push(
                        '/application/documents?step=1&sectionName=application.documents',
                        {
                            showSubmitted: true
                        }
                    )
                );
            }
        }

        if (next) {
            yield call(next, value, queryParams);
        }
    } catch (error) {
        yield put({
            type: Types.STEP_ERROR,
            error
        });
    }
}

export function* productSelectionError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'productSelection.error'
            })
        );
    } catch (err) {
        log(error);
    }
}
