import jwtDecode from 'jwt-decode';
import { path, pathOr } from 'ramda';
import { Token } from 'services/api/ApiClient';

export const isLoggedIn = (state: any): boolean => {
    const accessToken = path(['auth', 'token', 'accessToken'], state);

    return typeof accessToken === 'string';
};

export const getRefreshToken = (state: any): string | undefined =>
    path(['auth', 'token', 'refreshToken'], state);

export const getAuthToken = (state: any): Token | undefined => {
    return path(['auth', 'token'], state);
};

export const isBrokerBehalfToken = state => {
    const token = getAuthToken(state);

    const decoded = jwtDecode(token?.accessToken);

    return (
        pathOr<'borrower' | 'broker'>('borrower', ['rep', 'rol'], decoded) ===
        'broker'
    );
};
