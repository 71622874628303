import { ADDRESS_MAPPED_FIELDS } from 'constants/appConstants';
import { Address } from 'models/application/Application';

// Helpers to normalize for api from redux stare

export const getParamId = (param: string | undefined): string | undefined =>
    typeof param === 'string' ? param.split('-')[0] : param;

// Helpers for normalizing into redux form redux state

export const idPostfix = '-id';

export const getAddressRealFields = address => {
    if (address && address.noAutoFill) {
        if (address.section) {
            return {
                streetNumber: address[`${address.section}_sN`],
                street: address[`${address.section}_s`],
                unit: address[`${address.section}_u`],
                city: address[`${address.section}_c`],
                stateCode: ['CA', 'US'].includes(
                    address.countryCode || address[`${address.section}_cC`]
                )
                    ? address[`${address.section}_sC`]
                    : '', // WE send "" for international addresses used in registered address section
                postalCode: address[`${address.section}_pC`],
                countryCode:
                    address.countryCode || address[`${address.section}_cC`]
            };
        }
        return {
            streetNumber: address.sN,
            street: address.s,
            unit: address.u,
            city: address.c,
            stateCode: ['CA', 'US'].includes(address.countryCode || address.cC)
                ? address.sC
                : '', // WE send "" for international addresses used in registered address section
            postalCode: address.pC,
            countryCode: address.countryCode || address.cC
        };
    }

    return address;
};

export const getAddressNoAutoFillFields = (
    address?: Partial<Address>,
    section = ''
) => {
    const keyName = section ? `${section}_` : '';

    // just leave it on 1 line to be easier to read
    // prettier-ignore
    return {
        [`${keyName}${ADDRESS_MAPPED_FIELDS.streetNumber}`]: address?.streetNumber || '',
        [`${keyName}${ADDRESS_MAPPED_FIELDS.street}`]: address?.street || '',
        [`${keyName}${ADDRESS_MAPPED_FIELDS.unit}`]: address?.unit || '',
        [`${keyName}${ADDRESS_MAPPED_FIELDS.city}`]: address?.city || '',
        [`${keyName}${ADDRESS_MAPPED_FIELDS.stateCode}`]: address?.stateCode || '',
        [`${keyName}${ADDRESS_MAPPED_FIELDS.postalCode}`]: address?.postalCode || '',
        [`${keyName}${ADDRESS_MAPPED_FIELDS.countryCode}`]: address?.countryCode || ''
    };
};
