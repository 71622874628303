import { getFormValues } from 'redux-form';

import { PRODUCT_SELECTION_FORMS } from 'constants/appConstants';
import { Step } from 'product-selection/models';
import { getApplicationType } from 'reducers/application.selectors';
import { applicationTypeToCalendlyContext } from 'utils/calendly';

// TODO Change for import { RootState } from 'reducers/index'; after PR GH #2199 merge feat(NP-2156)
type ReduxState = any;

export const getApplicationDetailsFormData = (state: any): any => {
    const formValues: any = getFormValues(
        PRODUCT_SELECTION_FORMS.APPLICATION_DETAILS
    )(state);

    return formValues;
};

export const getProductSelection = (state: ReduxState) => {
    const { productSelection } = state;

    return productSelection;
};
export const getSummaryConfirmationStatus = (state: ReduxState) =>
    state.productSelection.summaryConfirmation;

export const getSelection = (state: ReduxState) =>
    state.productSelection.selection;

export const hasStepData = (step: Step) => (state: ReduxState) =>
    !!state.productSelection.steps[step]?.data;

export const getStepData = (step: Step) => (state: ReduxState) =>
    state.productSelection.steps[step]?.data;

export const getProductSelectionCalendlyContext = (state: ReduxState) =>
    applicationTypeToCalendlyContext(getApplicationType(state));
