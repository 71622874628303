export enum SalutationsEn {
    MR = 'MR',
    MS = 'MS',
    MISS = 'MISS',
    MRS = 'MRS',
    DR = 'DR',
    REV = 'REV',
    JUDGE = 'JUDGE'
}

export enum SalutationsFr {
    MR = 'MR',
    MS = 'MS',
    MRS = 'MRS',
    DR = 'DR',
    REV = 'REV',
    JUDGE = 'JUDGE'
}
