import { css } from 'styled-components';

export const readOnlyInput = ({ readOnly }: { readOnly?: boolean }) =>
    readOnly &&
    css`
        :focus-visible {
            border-color: transparent !important;
            outline: grey solid 1px !important;
        }
    `;
