import { select, call, put } from 'redux-saga/effects';
import { evolve, map, isEmpty, isNil } from 'ramda';
import FileSaver from 'file-saver';

import { getFormValues } from 'reducers/hook-form.selector';
import {
    ClosingDocuments,
    ClosingDocumentBorrower
} from 'models/documents/closing-documents';
import { apiClient } from 'services/api';
import { getActiveApplicationId } from 'reducers/application.selectors';
import { Actions } from 'reducers/closing-documents.redux';
import { log } from 'utils/logging';
import {
    errorNotification,
    successNotification
} from 'reducers/notifications.redux';

const isNilOrEmpty = (data: any) => isNil(data) || isEmpty(data);

const toDateNoTime = (date: string) => {
    if (isNilOrEmpty(date)) {
        return undefined;
    }

    const yyyyMmDd = date.split('T')[0];

    return `${yyyyMmDd}T00:00:00Z`;
};

const dateWithCurrentTime = (date: string) => {
    if (isNilOrEmpty(date)) {
        return undefined;
    }

    const now = new Date();
    const yyyyMmDd = date.split('T')[0];

    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    const time = `${hours
        .toString()
        .padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return `${yyyyMmDd}T${time}Z`;
};

const removeBorrowerId = {
    id: () => undefined,
    fiAccountTransit: String,
    fiAccountCode: String,
    fiAccountNumber: String
};

const transformBorrower = evolve(removeBorrowerId);

const transformBorrowers = map<
    ClosingDocumentBorrower,
    ClosingDocumentBorrower
>(transformBorrower);

const transformInfos = {
    closingDate: toDateNoTime,
    creditConsentDate: toDateNoTime,
    borrower2CreditConsentDate: toDateNoTime,
    currentDate: dateWithCurrentTime,
    currentMortgageMaturityDate: toDateNoTime,
    interestAdjustmentDate: toDateNoTime,
    firstRegularPaymentDate: toDateNoTime,
    loanTermMonths: Number,
    amortizationYears: Number,
    amortizationMonths: Number,
    borrowers: transformBorrowers
};

const normalizeInfos = evolve(transformInfos);

export function* saveClosingDocuments({ resolve, reject }: any) {
    try {
        const activeApplicationId = yield select(getActiveApplicationId);
        const formValues = yield select(
            getFormValues<Partial<ClosingDocuments>>('CLOSING_DOCUMENTS')
        );

        const normalizedData = normalizeInfos<ClosingDocuments>(formValues);

        const { ok } = yield call(
            apiClient.saveClosingDocumentsInfos,
            activeApplicationId,
            normalizedData
        );

        if (!ok) {
            return;
        }

        if (resolve) {
            resolve();
        }
    } catch (error) {
        log({ error: `saveClosingDocuments error: ${error}` });
        if (reject) {
            reject();
        }
    }
}

export function* getClosingDocuments() {
    try {
        const activeApplicationId = yield select(getActiveApplicationId);

        const { ok, data } = yield call(
            apiClient.getClosingDocumentsInfos,
            activeApplicationId
        );

        if (!ok) {
            return;
        }

        yield put(Actions.getInfosSuccess(data));
    } catch (error) {
        log({ error: `getClosingDocuments error: ${error}` });
    }
}

export function* getMultiple() {
    try {
        const activeApplicationId = yield select(getActiveApplicationId);
        const formValues = yield select(
            getFormValues<Partial<ClosingDocuments>>('CLOSING_DOCUMENTS')
        );
        const language = formValues?.metadata?.language || 'en';

        const searchIds = `ids[]=${Object.entries(
            formValues?.metadata?.documents || {}
        )
            .filter(([_key, value]) => value)
            .map(([key]) => key)
            .join('&ids[]=')}`;

        const foo = yield call(
            apiClient.getClosingDocumentsMultiple,
            activeApplicationId,
            `?lang=${language.toLowerCase()}&${searchIds}`
        );

        const { ok, data } = foo;

        if (!ok) {
            yield put(Actions.getMultipleFailed(data));

            return;
        }

        const filename = `${activeApplicationId}-closing-${language.toLowerCase()}.zip`;

        const zipFile = new File([data], filename, {
            type: 'application/zip;charset=utf-8',
            lastModified: Date.now()
        });
        FileSaver.saveAs(zipFile);

        yield put(Actions.getMultipleSuccess({ file: zipFile }));
    } catch (error) {
        log({ error: `getMultipleDocZip error: ${error}` });
        yield put(Actions.getMultipleFailed(error));
    }
}

export function* getMultipleSuccess() {
    yield put(
        successNotification({
            text: 'application.dataUpdatedSuccess'
        })
    );
}

export function* getMultipleFailed() {
    yield put(
        errorNotification({
            text: 'toasts.genericError'
        })
    );
}
