import { ifElse, evolve } from 'ramda';

import { AccountTypes } from './account.redux';
import { ClosingDocuments } from 'models/documents/closing-documents';

export type ClosingDocumentsState = {
    error?: any;
    success?: any;
    loading: boolean;
    infos: ClosingDocuments | undefined;
};

export const Types = {
    CLOSING_DOCUMENTS_GET: 'CLOSING_DOCUMENTS/GET',
    CLOSING_DOCUMENTS_GET_SUCCESS: 'CLOSING_DOCUMENTS/GET_SUCCESS',
    CLOSING_DOCUMENTS_GET_FAILED: 'CLOSING_DOCUMENTS/GET_FAILED',
    CLOSING_DOCUMENTS_MULTIPLE: 'CLOSING_DOCUMENTS/MULTIPLE',
    CLOSING_DOCUMENTS_MULTIPLE_SUCCESS: 'CLOSING_DOCUMENTS/MULTIPLE_SUCCESS',
    CLOSING_DOCUMENTS_MULTIPLE_FAILED: 'CLOSING_DOCUMENTS/MULTIPLE_FAILED',
    CLOSING_DOCUMENTS_INFOS: 'CLOSING_DOCUMENTS/INFOS',
    CLOSING_DOCUMENTS_INFOS_SUCCESS: 'CLOSING_DOCUMENTS/INFOS_SUCCESS',
    CLOSING_DOCUMENTS_INFOS_FAILED: 'CLOSING_DOCUMENTS/INFOS_FAILED',
    CLOSING_DOCUMENTS_SAVE_INFOS: 'CLOSING_DOCUMENTS/SAVE_INFOS',
    CLOSING_DOCUMENTS_SAVE_INFOS_SUCCESS:
        'CLOSING_DOCUMENTS/SAVE_INFOS_SUCCESS',
    CLOSING_DOCUMENTS_SAVE_INFOS_FAILED: 'CLOSING_DOCUMENTS/SAVE_INFOS_FAILED'
};

const INITIAL_STATE: ClosingDocumentsState = {
    error: undefined,
    success: undefined,
    loading: false,
    infos: undefined
};

const tDate = ifElse(
    val => val === '0001-01-01T00:00:00Z',
    () => undefined,
    val => val
);

const transformInfos = {
    closingDate: tDate,
    creditConsentDate: tDate,
    borrower2CreditConsentDate: tDate,
    currentDate: tDate,
    currentMortgageMaturityDate: tDate,
    interestAdjustmentDate: tDate,
    firstRegularPaymentDate: tDate
};

const normalizeInfos = evolve(transformInfos);

export const reducer = (
    state = INITIAL_STATE,
    action: any
): ClosingDocumentsState => {
    switch (action.type) {
        case Types.CLOSING_DOCUMENTS_INFOS_SUCCESS:
            return {
                ...state,
                error: undefined,
                success: undefined,
                infos: normalizeInfos(action.infos) as ClosingDocuments
            };
        case Types.CLOSING_DOCUMENTS_MULTIPLE:
            return {
                ...state,
                error: undefined,
                success: undefined,
                loading: true
            };
        case Types.CLOSING_DOCUMENTS_MULTIPLE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                success: action.success
            };
        case Types.CLOSING_DOCUMENTS_MULTIPLE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                success: undefined
            };
        case AccountTypes.LOGOUT:
        case AccountTypes.BEHALF_LOGIN_SUCCESS:
        case AccountTypes.LOGIN_SUCCESS:
        case AccountTypes.BROKER_LOGIN_SUCCESS:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const Actions = {
    getById: (closingDocumentId: any) => ({
        type: Types.CLOSING_DOCUMENTS_GET,
        closingDocumentId
    }),
    getMultiple: () => ({
        type: Types.CLOSING_DOCUMENTS_MULTIPLE
    }),
    getMultipleSuccess: (success: any) => ({
        type: Types.CLOSING_DOCUMENTS_MULTIPLE_SUCCESS,
        success
    }),
    getMultipleFailed: (error: any) => ({
        type: Types.CLOSING_DOCUMENTS_MULTIPLE_FAILED,
        error
    }),
    getInfos: () => ({
        type: Types.CLOSING_DOCUMENTS_INFOS
    }),
    getInfosSuccess: (infos: ClosingDocuments) => ({
        type: Types.CLOSING_DOCUMENTS_INFOS_SUCCESS,
        infos
    }),
    saveInfos: (documentInfos: Partial<ClosingDocuments>, resolve, reject) => ({
        type: Types.CLOSING_DOCUMENTS_SAVE_INFOS,
        documentInfos,
        resolve,
        reject
    })
};
