export type ParsedUtmz = {
    source: string;
    medium: string;
    campaign: string;
    content?: string;
    term?: string;
    gclid?: string;
    dclid?: string;
    channel: string;
    wbraid?: string;
    msclkid?: string;
};

/**
 * Get cookie value
 * Logic coming from Greg's utm cookie replicator
 * @ref https://www.bounteous.com/insights/2017/12/18/utmz-cookie-replicator-gtm/
 *
 * @param name Name of the cookie to get
 * @returns String value if cookie is found else undefined
 */
export const getCookie = (name: string) => {
    const cookies = `; ${document.cookie}`;
    const cookieValues = cookies.split('; ' + name + '=');

    if (cookieValues.length > 1) {
        // I know TS it could be undefined but we already check the lenght of cvals
        // @ts-ignore
        return cookieValues.pop().split(';')[0];
    }
};

/**
 * Parse utmz cookie value to object
 * From: `utmcsr=(direct)|utmcmd=(none)|utmccn=(not set)|...`
 * To: `{utmcsr: "local", utmcmd: "(none)", utmccn: "(not set)", ...}`
 * @param storedVals
 * @returns Record<string, string | number>
 */
export const parseUtmzCookie = (storedVals?: string): ParsedUtmz => {
    // These are the default values from google cookies
    const values: ParsedUtmz = {
        source: '(direct)',
        medium: '(not set)',
        campaign: '(not set)',
        channel: 'Direct'
    };

    const keyMap = {
        utmcsr: 'source',
        utmcmd: 'medium',
        utmccn: 'campaign',
        utmcct: 'content',
        utmctr: 'term',
        utmgclid: 'gclid',
        utmdclid: 'dclid',
        utmwbraid: 'wbraid',
        msclkid: 'msclkid',
        channel: 'channel'
    };

    // Logic coming from Greg's utm cookie replicator
    // @ref https://www.bounteous.com/insights/2017/12/18/utmz-cookie-replicator-gtm/
    (storedVals || '').split('|').forEach(storedValue => {
        const [key, val] = storedValue.split('=');
        const _key = key.split('.').pop() as keyof typeof keyMap;
        values[keyMap[_key] || _key] = decodeURIComponent(val || '');
    });

    return values;
};
