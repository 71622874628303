import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Flex } from 'reflexbox/styled-components';
import { Form, reduxForm, InjectedFormProps } from 'redux-form';
import { Link } from 'react-router-dom';

import { LoginForm } from 'components/form-login';
import { AccountContainer } from 'containers/account';
import { PageContainer } from 'components/page-container';
import { CardWizard } from 'components/card/card-wizard.component';
import { Typograph } from 'components/typograph';
import {
    InjectedAccountProps,
    LoginPayload
} from 'containers/account/account.container.hoc';
import { SubmitButton } from 'components/buttons/button';
import { getReferalUrl, getReferalSearch } from 'reducers/startup.redux';
import NestoSecureLogoMobileEN from 'assets/media/icons/nesto_secure_logo_en_mobile.png';
import NestoSecureLogoMobileFR from 'assets/media/icons/nesto_secure_logo_fr_mobile.png';
import { Image } from 'components/image';
import { Routes } from 'app-root/routing/routes';
import { clearServicingPortalStorage } from 'utils/auth';

type StateProps = {
    referalUrl: string | undefined;
    referalSearch: string | undefined;
};

const TypedLoginForm: any = LoginForm;

const Logos = {
    en: NestoSecureLogoMobileEN,
    fr: NestoSecureLogoMobileFR
};

const LoginView = ({
    login,
    handleSubmit,
    submitting,
    referalUrl,
    referalSearch,
    ...rest
}: StateProps & InjectedAccountProps & InjectedFormProps<LoginPayload>) => {
    clearServicingPortalStorage();

    return (
        <PageContainer flexDirection="column" alignItems="center">
            <Flex
                alignItems={['center']}
                justifyContent={['center']}
                width="100%"
            >
                <Form onSubmit={handleSubmit(login)}>
                    <CardWizard>
                        <Flex justifyContent="space-between" alignItems="start">
                            <Typograph
                                fontSize={20}
                                fontWeight={700}
                                tx="form.login.title"
                            />

                            <Image
                                data-test-id="nestoSecured"
                                css={{
                                    width: '30%'
                                }}
                                src={
                                    Logos[rest.currentLang]
                                        ? Logos[rest.currentLang]
                                        : Logos.en
                                }
                                alt="Nesto Secure Encryption"
                            />
                        </Flex>

                        <TypedLoginForm {...rest} />
                        <Flex mt={3} flexDirection="column">
                            <SubmitButton
                                data-test-id="login"
                                label="form.signup.login"
                                submitting={submitting}
                                width="100%"
                                maxWidth="100%"
                            />
                            <Link
                                to={
                                    referalUrl
                                        ? {
                                              pathname: Routes.signUp,
                                              state: {
                                                  from: {
                                                      pathname: referalUrl,
                                                      search: referalSearch
                                                  }
                                              }
                                          }
                                        : Routes.signUp
                                }
                                data-test-id="signUp"
                            >
                                <Typograph
                                    setInnerHtml
                                    mt={3}
                                    fontSize={2}
                                    color="textBlack"
                                    textAlign="center"
                                    tx="loginPage.signUp"
                                    fontWeight={500}
                                />
                            </Link>
                        </Flex>
                    </CardWizard>
                </Form>
            </Flex>
        </PageContainer>
    );
};

export const Login = compose(
    AccountContainer,
    connect<StateProps>(state => ({
        referalUrl: getReferalUrl(state),
        referalSearch: getReferalSearch(state)
    })),
    reduxForm({
        form: 'LOGIN'
    })
)(LoginView);
