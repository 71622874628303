import { TypographI18nKeys } from 'components/typograph';
import { useMessages } from './useMessages';
/**
 * Used to generate labels like 'Unit Number (optional)'
 * @param labelText The actual, already translated text. Include this or the labelkey
 * @param labelKey The messages key for the label or placeholder of an form field. Include this or the label Text
 * @param isRequired Whether the form field is required
 * @returns a form field label string
 */

type Props = {
    labelText?: string;
    labelKey?: TypographI18nKeys;
    isOptional: boolean;
    frOptionalLabelGender?: 'masculine' | 'feminine';
};

export const useOptionalLabelGenerator = ({
    labelText,
    labelKey,
    isOptional,
    frOptionalLabelGender = 'feminine'
}: Props): string => {
    const translate = useMessages();

    const label = labelText ? labelText : labelKey ? translate(labelKey) : '';

    if (!isOptional) return label;

    return `${label} ${
        frOptionalLabelGender === 'feminine'
            ? translate('field.optional')
            : translate('field.optionalMasculine')
    }`;
};
