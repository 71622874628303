import { WrappedFieldProps } from 'redux-form';
import styled, { css } from 'styled-components/macro';
import { Theme } from '@nesto/themes';
import MaskedInput from 'react-input-mask';

import { CheckMark as CheckMarkSvg } from 'assets/media/icons/Checkmark';
import { normalizeInputNameForE2E } from 'utils/e2e-utilities';
import { InputError } from 'components/inputs/input-error/input-error.component';
import { readOnlyInput } from '../shared/styles';

const MAX_PHONE_NUMBER_LENGTH = 10;

type Props = {
    name?: string;
    type: string;
    preset?: string;
    flip?: boolean;
    placeholder?: string;
    disabled?: boolean;
    iconAction?: () => void;
    hideInputErrors?: boolean;
    icon?: any;
    useClassStyle?: boolean;
    readOnly?: boolean;
    dataTestId?: string;
    showErrorOnTouched?: boolean;
};

type MapStylesProps = {
    preset?: string;
    flip?: boolean;
    updated?: boolean;
    readOnly?: boolean;
    classStyle?: 'disabled' | 'error' | 'valid' | 'default';
    theme: Theme;
};

interface Selection {
    start: number;
    end: number;
}

interface InputState {
    value: string;
    selection: Selection | null;
}

const mapStylesToPresetsInput = ({
    preset,
    flip,
    theme,
    classStyle = 'default'
}: MapStylesProps) => css`
    width: 100%;
    border-color: ${theme.input[classStyle].borderColor};
    color: ${theme.input[classStyle].color};
    background-color: ${theme.input[classStyle].background};
    padding: ${flip ? '10px 10px 10px 37px' : '10px 40px 10px 10px'};
    font-size: ${theme.fontSizes[preset === 'SMALL' ? 1 : 2]};
    height: ${preset === 'SMALL' ? 48 : 60}px;
`;

const mapStylesToPresetsLabel = ({
    preset,
    flip,
    updated,
    readOnly,
    theme
}: MapStylesProps) => {
    const transitionLeft = readOnly ? '0px' : flip ? '38px' : '11px';
    switch (preset) {
        case 'SMALL':
            return css`
                color: ${updated ? theme.colors.boulder : theme.colors.tundora};
                font-size: ${theme.fontSizes[1]};
                transform: ${updated
                    ? `translate(${transitionLeft}, 4px) scale(0.75)`
                    : `translate(${transitionLeft}, 14px) scale(1)`};
            `;
        default:
            return css`
                color: ${updated ? theme.colors.boulder : theme.colors.tundora};
                font-size: ${theme.fontSizes[2]};
                transform: ${updated
                    ? `translate(${transitionLeft}, 10px) scale(0.75)`
                    : `translate(${transitionLeft}, 24px) scale(1)`};
            `;
    }
};

const InputWrapper = styled.div<any>`
    width: 100%;
    display: inline-flex;
    padding: ${props => (props.readOnly ? '0 0 0 10px' : 0)};
    flex-direction: column;
    vertical-align: top;
    position: relative;
    input:-webkit-autofill + div {
        transform: translate(11px, 10px) scale(0.75);
    }
`;

const readOnlyStyle = ({ readOnly, theme }: any) =>
    readOnly &&
    css`
        padding: 0 0 0 10px;
        color: ${theme.input.default.color};
        border-left: transparent;
        background-color: #f8f8f8;
        border-radius: 0 6px 6px 0;
    `;

const Input = styled.input<MapStylesProps>`
    word-break: normal;
    font-family: ${props => props.theme.fontFamily};
    box-sizing: border-box;
    outline: none;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

    ${props => props.theme.input.border};
    color: ${props => props.theme.input.default.color};
    border-color: ${props => props.theme.input.default.borderColor};
    background-color: ${props => props.theme.input.default.background};
    ${({ theme, readOnly }) => css`
        :hover {
            border-color: ${readOnly
                ? undefined
                : theme.input.hover.borderColor};
        }
        :focus,
        :active {
            color: ${readOnly ? undefined : theme.input.active.color};
            border-color: ${readOnly
                ? undefined
                : theme.input.active.borderColor};
            background-color: ${readOnly
                ? undefined
                : theme.input.active.background};
        }
    `};
    ${mapStylesToPresetsInput};
    ${readOnlyStyle};
    ${readOnlyInput}
`;

const Placeholder = styled.div<MapStylesProps>`
    top: 0;
    padding: ${props => (props.readOnly ? '10px' : 0)};
    position: absolute;
    pointer-events: none;
    padding: 0;
    font-family: ${props => props.theme.fontFamily};
    transform-origin: top left;
    color: ${({ theme }) => theme.input.disabled.color};
    ${mapStylesToPresetsLabel};
    ${readOnlyStyle};
    background-color: ${props => props.readOnly && 'transparent'};
`;

const CheckMarkContainer = styled.div<MapStylesProps>`
    top: ${({ preset }) => (preset === 'SMALL' ? '13px' : '23px')};
    right: 15px;
    position: absolute;
`;

const IconIconContainer = styled.div<MapStylesProps>`
    top: ${({ preset }) => (preset === 'SMALL' ? '12px' : '22px')};
    ${({ flip }) => (flip ? 'left: 17px;' : 'right: 17px;')};
    position: absolute;
    &:hover {
        cursor: pointer;
    }
`;

export const returnClass = (
    meta,
    icon,
    useClassStyle = true,
    disabled,
    readOnly
) => {
    if (!useClassStyle || readOnly) {
        return 'default';
    }

    if (icon) {
        return 'default'; // If it has an icon we return default style class
    }

    if (disabled) {
        return 'disabled';
    }

    if (meta && meta.touched && meta.error) {
        return 'error';
    }

    if (meta && meta.touched && meta.valid) {
        return 'valid';
    }

    return 'default';
};

export const PhoneInput = ({
    meta,
    input,
    hideInputErrors = false,
    placeholder,
    type,
    preset,
    flip,
    icon,
    disabled = false,
    useClassStyle = true,
    iconAction,
    readOnly = false,
    dataTestId,
    showErrorOnTouched = false,
    ...rest
}: Props & WrappedFieldProps) => {
    const classStyle = returnClass(
        meta,
        icon,
        useClassStyle,
        disabled,
        readOnly
    );

    const beforeMaskedValueChange = (
        newState: InputState,
        _oldState: InputState,
        userInput: string
    ): InputState => {
        const { value } = newState;
        const selection = newState.selection;

        if (userInput?.length > MAX_PHONE_NUMBER_LENGTH) {
            const difference = userInput?.length - MAX_PHONE_NUMBER_LENGTH;
            const updatedValue = userInput?.substring(difference);

            return {
                value: updatedValue,
                selection
            };
        }

        return {
            value,
            selection
        };
    };

    return (
        <>
            <InputWrapper readOnly={readOnly} {...rest}>
                <MaskedInput
                    mask={'999-999-9999'}
                    disabled={disabled}
                    readOnly={readOnly}
                    beforeMaskedValueChange={beforeMaskedValueChange}
                    {...input}
                >
                    {inputProps => (
                        <Input
                            id={normalizeInputNameForE2E(
                                input.name || 'text-field'
                            )}
                            data-test-id={
                                dataTestId
                                    ? dataTestId
                                    : normalizeInputNameForE2E(
                                          (input && input.name) || 'text-field'
                                      )
                            }
                            type={type}
                            classStyle={classStyle}
                            preset={preset}
                            flip={flip}
                            {...rest}
                            disabled={disabled}
                            readOnly={readOnly}
                            {...inputProps}
                        />
                    )}
                </MaskedInput>
                {placeholder && (
                    <Placeholder
                        updated={input.value !== '' || meta?.active}
                        preset={preset}
                        flip={flip}
                        readOnly={readOnly}
                        data-test-id={`input_label-${normalizeInputNameForE2E(
                            input.name || 'text-field'
                        )}`}
                    >
                        {placeholder}
                    </Placeholder>
                )}
                {classStyle === 'valid' && !icon && !readOnly && (
                    <CheckMarkContainer
                        preset={preset}
                        data-test-id={`input_checkmark-${normalizeInputNameForE2E(
                            input.name || 'text-field'
                        )}`}
                    >
                        <CheckMarkSvg />
                    </CheckMarkContainer>
                )}
                {icon && !readOnly && (
                    <IconIconContainer
                        data-test-id={`input_icon-${normalizeInputNameForE2E(
                            input.name || 'text-field'
                        )}`}
                        onClick={iconAction}
                        flip={flip}
                        preset={preset}
                    >
                        {icon}
                    </IconIconContainer>
                )}
            </InputWrapper>
            {!hideInputErrors && (
                <InputError
                    meta={meta}
                    name={input.name}
                    showErrorOnTouched={showErrorOnTouched}
                />
            )}
        </>
    );
};
