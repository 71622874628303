import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { useIntl } from 'react-intl';

export const useMessages = () => {
    const intl = useIntl();
    return (
        message: string,
        values?: Record<
            string | number,
            PrimitiveType | FormatXMLElementFn<string, string>
        >
    ) => intl.formatMessage({ id: message }, values);
};
