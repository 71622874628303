/**
 * Safely invoke the function with the given arguments, if it is indeed a
 * function, and return its value. Otherwise, return undefined.
 */
import { ApplicationType } from 'models/application/Application';
export function safeInvoke(func: any | void, ...args: any[]) {
    if (typeof func === 'function') {
        return func(...args);
    }
    return undefined;
}
const getTitleType = {
    NEW: 'application.browserNewMortgageApplicationsTitle',
    RENEWAL: 'application.browserRenewalApplicationsTitle',
    REFINANCE: 'application.browserRefinanceApplicationsTitle'
};
export const getTitleOfApplicationType = (
    applicationType: ApplicationType | undefined
) => (applicationType ? getTitleType[applicationType] : '');
