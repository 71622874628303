import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { Typograph } from '@nesto/design-system';

import { Actions as SidebarActions } from 'reducers/ui.redux';
import { ApplicationNavigation } from 'components/navigation/application-navigation.component';
import { DocumentNavigation } from 'components/navigation/document-navigation.components';
import { ProductSelectionNavigation } from 'components/navigation/product-selection-navigation.component';
import {
    getDashboardStatus,
    getDashboardLoaded
} from 'reducers/dashboard.selectors';
import {
    ActiveStates,
    computeSidebarStatus,
    SidebarStatus
} from 'reducers/sidebar.selectors';
import {
    getIsLeadReferralNeeded,
    getIsConsentNeeded
} from 'reducers/ui.selectors';
import { NavigationIcons } from 'components/sidebar/components/navigation-icons.component';
import { Navigations } from 'components/sidebar/components/navigations.component';
import { WithLink } from 'components/with-link';
import { DashboardStatuses } from 'reducers/dashboard.redux';
import { SwitchApplicationButton } from './components/switch-application-button.component';
import { useAnalyticEvent, useTheme } from 'utils/hooks';
import {
    getActiveApplicationId,
    getIsLockedState
} from 'reducers/application.selectors';
import { Routes } from 'app-root/routing/routes';

export enum navigationState {
    UNREACHED_STATE = 'UNREACHED',
    COMPLETED_STATE = 'COMPLETED',
    ACTIVE_STATE = 'ACTIVE'
}

export type navigationStates = ActiveStates;

const Container = styled.div`
    width: 100%;
    z-index: ${props => props.theme.zIndex.heaven + 2};
    position: relative;

    ${({ theme }) => theme.mediaQueries.large} {
        width: ${props => props.theme.sidebar.width};
        top: 0;
        position: unset;
    }
`;

export const NavigationTitle = ({ label, state = '', ...rest }) => {
    const theme = useTheme();

    return (
        <Typograph
            color={
                state === 'COMPLETED'
                    ? theme.fontColor.disabled
                    : theme.fontColor.default
            }
            fontWeight={state === 'ACTIVE' ? 'bold' : 'normal'}
            fontSize={state === 'ACTIVE' ? '15px' : 2}
            tx={label}
            {...rest}
        />
    );
};

export const SidebarNavigationIcon = styled(NavigationIcons)`
    position: absolute;
    right: -11px;
    top: calc(50% - 12px);
    z-index: ${props => props.theme.zIndex.heaven};
`;

type StateProps = {
    activeStates: SidebarStatus;
    dashboardStatus: DashboardStatuses;
    dashboardLoaded: boolean;
    disableNavigation: boolean;
    isLocked?: boolean | undefined;
};

type DispatchProps = {
    toggleSidebar: (showSidebar?: boolean) => void;
};

export const SideBarView = ({
    activeStates,
    toggleSidebar,
    dashboardStatus,
    dashboardLoaded,
    disableNavigation,
    isLocked
}: StateProps & DispatchProps) => {
    const analyticEvent = useAnalyticEvent();

    const showApplicationNavs = dashboardStatus === 'APPLICATION';
    const showGAQNav = !showApplicationNavs;

    return (
        <Container>
            {showApplicationNavs && (
                <Navigations maxHeight="90px" px={3} py={3} withHover={false}>
                    <SwitchApplicationButton
                        onClick={() => {
                            analyticEvent('application_tracker', {
                                main_section_name: 'application tracker',
                                event_location_category: 'navigation',
                                info: 'application tracker'
                            });
                            toggleSidebar();
                        }}
                    />
                </Navigations>
            )}
            {showGAQNav && (
                <WithLink
                    onClick={() => {
                        analyticEvent('change_section', {
                            main_section_name: 'get a quote',
                            event_location_category: 'navigation'
                        });
                    }}
                    to={
                        activeStates.getAQuote === 'ACTIVE'
                            ? Routes.getAQuoteRoot
                            : ''
                    }
                >
                    <Navigations
                        active={activeStates.getAQuote}
                        onClick={() => toggleSidebar()}
                    >
                        <NavigationTitle
                            state={activeStates.getAQuote}
                            label="sidebar.getAQuote"
                        />
                        <SidebarNavigationIcon
                            activeState={activeStates.getAQuote}
                        >
                            <Typograph fontSize="15px" fontWeight="bold">
                                1
                            </Typograph>
                        </SidebarNavigationIcon>
                    </Navigations>
                </WithLink>
            )}
            {dashboardLoaded && !disableNavigation && (
                <ApplicationNavigation
                    applicationState={
                        disableNavigation ? 'UNREACHED' : activeStates.apply
                    }
                    nextApplicationState={activeStates.rateSelection}
                    toggleSidebar={() => toggleSidebar()}
                />
            )}
            {!disableNavigation && !isLocked && (
                <ProductSelectionNavigation
                    applicationState={activeStates.rateSelection}
                    nextApplicationState={activeStates.documents}
                />
            )}
            {/* Documents */}
            {showApplicationNavs && !disableNavigation && (
                <DocumentNavigation
                    applicationState={activeStates.documents}
                    toggleSidebar={() => toggleSidebar()}
                />
            )}
        </Container>
    );
};

export const SideBar = connect<StateProps, DispatchProps>(
    (state: any) => {
        const applicationId = getActiveApplicationId(state);
        const hasTooManyMortgagedProperties =
            window.localStorage.getItem(
                `hasTooManyProperties:${applicationId}`
            ) === true.toString();
        const disableNavigation =
            hasTooManyMortgagedProperties ||
            getIsConsentNeeded(state) ||
            getIsLeadReferralNeeded(state);

        return {
            activeStates: computeSidebarStatus(state),
            dashboardStatus: getDashboardStatus(state),
            dashboardLoaded: getDashboardLoaded(state),
            isLocked: getIsLockedState(state),
            disableNavigation
        };
    },
    {
        toggleSidebar: SidebarActions.updateSidebar
    }
)(SideBarView as any) as React.FC<{}>;
