import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Flex } from 'reflexbox/styled-components';

import { Typograph } from '@nesto/design-system';
import { Icon } from '@nesto/design-system/v2';

import { getActiveApplication } from 'reducers/application.selectors';
import { Button } from 'product-selection/components/buttons/button.component';
import { Application } from 'models/application/Application';
import { Divider } from 'components/divider';
import { ApplicationIdentifier } from './application-identifier.component';
import { ApplicationTypes } from 'constants/appConstants';
import { Routes } from 'app-root/routing/routes';

type Props = {
    onClick: () => void;
};

type StateProps = {
    application: Application | undefined;
};

const SwitchApplicationButtonView = ({
    onClick,
    application
}: Props & StateProps) => {
    if (!application) return null;

    return (
        <Link to={Routes.applicationSelection} style={{ width: '100%' }}>
            <Button
                id="btn-applicationStatus"
                data-test-id="btn-applicationStatus"
                variant="outline"
                size="small"
                flexGrow={1}
                mt={0}
                pr={3}
                width="100%"
                hideArrow
                onClick={onClick}
            >
                <Flex flexDirection="column">
                    <Flex>
                        <Box flexShrink={0}>
                            <Typograph
                                tx={ApplicationTypes[application.type]}
                                fontSize="7px"
                                fontWeight="bold"
                            />
                        </Box>
                        <Box
                            as="span"
                            fontSize="7px"
                            fontWeight="light"
                            css={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            &nbsp;-{' '}
                            <ApplicationIdentifier application={application} />
                        </Box>
                    </Flex>
                    <Typograph tx="applicationStatus" />
                </Flex>
                <Flex alignSelf="stretch" flexShrink={0}>
                    <Divider mx={2} vertical />
                </Flex>
                <Icon name="open-link" size={16} />
            </Button>
        </Link>
    );
};

export const SwitchApplicationButton = connect<StateProps>(state => ({
    application: getActiveApplication(state)
}))(SwitchApplicationButtonView);
