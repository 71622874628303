// @ts-nocheck
import React, { ChangeEvent } from 'react';
import { Box } from 'reflexbox/styled-components';
import { Field, Validator, Normalizer } from 'redux-form';
import { PhoneInput } from 'components/inputs/phone-input';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import * as Validators from 'lib/validators';
import { WidthProps, SpaceProps, FontSizeProps } from 'styled-system';

export type Props = {
    name: string;
    inputMode?: string;
    normalize?: Normalizer;
    format?: Normalizer;
    type?: string;
    placeholder?: string;
    width?: string;
    isRequired?: boolean;
    disabled?: boolean;
    validate?: Validator[];
    useClassStyle?: boolean;
    preset?: string;
    onChange?: (
        event?: ChangeEvent<any>,
        newValue?: any,
        previousValue?: any
    ) => void;
    onKeyPress?: (e: any) => void;
    onBlur?: (e: any) => void;
    onFocus?: (e: any) => void;
    autoComplete?: string;
    readOnly?: boolean;
    dataTestId?: string;
    iconAction?: () => void;
    icon?: any;
};

type StyledProps = WidthProps & SpaceProps & FontSizeProps;

export type TextFieldProps = StyledProps & Props;

const noOp = () => undefined;

const PhoneFieldView = ({
    type = 'text',
    placeholder,
    name,
    validate = [noOp],
    normalize,
    intl,
    format,
    preset,
    onChange,
    onKeyPress,
    inputMode,
    onBlur,
    onFocus,
    isRequired = false,
    disabled = false,
    useClassStyle = true,
    autoComplete,
    readOnly,
    dataTestId,
    iconAction,
    icon,
    ...rest
}: TextFieldProps & WrappedComponentProps) => (
    <Box mt={[3]} width="100%" {...rest}>
        <Field
            placeholder={
                placeholder
                    ? intl.formatMessage({
                          id: placeholder
                      })
                    : ''
            }
            name={name}
            component={PhoneInput}
            validate={[isRequired ? Validators.isRequired : noOp, ...validate]}
            normalize={normalize}
            format={format}
            preset={preset}
            inputMode={inputMode}
            type={type}
            disabled={disabled}
            useClassStyle={useClassStyle}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            onFocus={onFocus}
            autoComplete={autoComplete}
            readOnly={readOnly}
            dataTestId={dataTestId}
            iconAction={iconAction}
            icon={icon}
        />
    </Box>
);

export const PhoneField = injectIntl(PhoneFieldView);
