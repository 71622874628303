import React from 'react';
import { Box } from 'reflexbox/styled-components';
import { Link } from 'react-router-dom';
import { EmailField, PasswordField } from 'components/fields';
import { Typograph } from 'components/typograph';

export const LoginForm = () => (
    <Box width={['100%']}>
        <EmailField placeholder="email" name="email" />

        <PasswordField
            mt={2}
            name="password"
            placeholder="form.signup.password"
        />

        <Box mt={[3]} width="100%">
            <Link to="/forgot" data-test-id="forgot">
                <Typograph
                    fontSize={[1]}
                    color="timeBlue"
                    textAlign="right"
                    tx="form.signup.forgotPassword"
                    fontWeight={500}
                    style={{
                        textDecoration: 'underline'
                    }}
                />
            </Link>
        </Box>
    </Box>
);
