import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';
import { maxWidth, space, width } from 'styled-system';

const PageContainerWrapper = styled(Flex)`
    max-width: 1024px;
    width: 100%;
    margin: auto;

    ${width}
    ${maxWidth}
    ${space}
`;

const GreyBackgroundWrapper = styled(Flex)`
    align-items: center;
`;

export const PageContainer = ({
    children,
    padding = {},
    mobilePadding,
    pt = [2, 2, 5, 6],
    ...props
}: any) => (
    <GreyBackgroundWrapper
        pt={pt}
        pb={[7, 7, 5, 3]}
        px={mobilePadding ? 0 : [2, 3, 4, 2]}
        width="100%"
        {...padding}
    >
        <PageContainerWrapper {...props}>{children}</PageContainerWrapper>
    </GreyBackgroundWrapper>
);
