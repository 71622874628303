import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import storageSession from 'redux-persist/lib/storage/session';
import { combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer, createTransform } from 'redux-persist';
import { reducer as rates } from './rates.redux';
import { reducer as account } from './account.redux';
import { reducer as auth } from './auth.redux';
import { localeReducer as locale } from './locale.redux';
import { reducer as dashboard } from './dashboard.redux';
import { reducer as application } from './application.redux';
import { reducer as notifications } from './notifications.redux';
import { reducer as documents } from './documents.redux';
import { reducer as ui } from './ui.redux';
import { reducer as mortgageWizards } from './mortgage-wizards.redux';
import { reducer as productSelection } from './product-selection.redux';
import { reducer as applicationsState } from './applications-state.redux';
import { reducer as hookForm } from './hook-form.redux';
import { reducer as closingDocuments } from './closing-documents.redux';
import { reducer as localStorageStore } from './local-storage.redux';
import { reducer as session } from './session.redux';
import GetAQuoteFlowValues from 'redux-store/mortgage-wizard/get-a-quote-flow.reducer';

const FORM_PERSIST_CONFIG = {
    key: 'form',
    storage: storageSession,
    whitelist: ['NEW_MORTGAGE', 'RENEWAL_MORTGAGE', 'REFINANCE_MORTGAGE']
};

const HOOK_FORM_PERSIST_CONFIG = {
    key: 'hookForm',
    storage: storageSession
};

const AUTH_PERSIST_CONFIG = {
    key: 'auth',
    storage
};

const GAQ_PERSIST_CONFIG = {
    key: 'GetAQuoteFlowValues',
    storage: storage
};

const QUOTE_RATE_PERSIST_CONFIG = {
    key: 'rates',
    storage: storage,
    whitelist: ['matrix']
};

const APP_STATE_PERSIST_CONFIG = {
    key: 'applicationsState',
    storage,
    // Transform dates back into JS Dates on rehydrate
    // (see: https://github.com/rt2zz/redux-persist/issues/82)
    transforms: [
        createTransform(JSON.stringify, toRehydrate =>
            JSON.parse(toRehydrate, (key, value) =>
                typeof value === 'string' &&
                value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
                    ? new Date(value)
                    : value
            )
        )
    ]
};

const ACCOUNT_PERSIST_CONFIG = {
    key: 'account',
    storage: storageSession,
    blacklist: ['passwordResetSent']
};

const LOCAL_STORAGE_PERSIST_CONFIG = {
    key: 'appMortgage',
    storage
};

const SESSION_PERSIST_CONFIG = {
    key: 'session',
    storage: storageSession
};

const form = formReducer.plugin({});

export const reducers = history =>
    combineReducers({
        auth: persistReducer(AUTH_PERSIST_CONFIG, auth),
        router: connectRouter(history),
        form: persistReducer(FORM_PERSIST_CONFIG, form),
        hookForm: persistReducer(HOOK_FORM_PERSIST_CONFIG, hookForm),
        rates: persistReducer(QUOTE_RATE_PERSIST_CONFIG, rates),
        locale,
        account: persistReducer(ACCOUNT_PERSIST_CONFIG, account),
        dashboard,
        application,
        applicationsState: persistReducer(
            // @ts-ignore
            APP_STATE_PERSIST_CONFIG,
            applicationsState
        ),
        notifications,
        documents,
        ui,
        mortgageWizards,
        productSelection,
        closingDocuments,
        localeStorage: persistReducer(
            LOCAL_STORAGE_PERSIST_CONFIG,
            localStorageStore
        ),
        GetAQuoteFlowValues: persistReducer(
            GAQ_PERSIST_CONFIG,
            GetAQuoteFlowValues
        ),
        session: persistReducer(SESSION_PERSIST_CONFIG, session)
    });

export type RootStateWithHistory = ReturnType<typeof reducers>;
export type RootState = ReturnType<RootStateWithHistory>;
