import * as React from 'react';
import styled from 'styled-components/macro';
import { Flex } from 'reflexbox/styled-components';
import { IconComponent as Icon } from 'components/icon';
import { Typograph, TypographI18nKeys } from 'components/typograph';

type Props = {
    text?: {
        defaultTx: TypographI18nKeys;
        tx: TypographI18nKeys;
    };
    link?: string;
    children?: any;
    clickAction?: () => void;
    iconRight?: string;
    index?: number;
    newPage?: boolean;
};

const MenuDivider = styled.div`
    background-color: ${props => props.theme.colors.alto};
    height: 1px;
`;

const MenuItemContainer = styled.div`
    cursor: pointer;
    max-width: 500px;
    width: 100%;
    background-color: white;
`;

export const MenuItem = ({
    index,
    clickAction,
    link,
    text,
    iconRight,
    children,
    newPage
}: Props) => (
    <MenuItemContainer>
        <Flex
            justifyContent="space-between"
            flexDirection="column"
            onClick={clickAction}
            pl="4px"
            pr="4px"
        >
            {index === 0 ? <MenuDivider /> : null}
            <a
                href={link}
                target={newPage ? '_blank' : undefined}
                rel="noreferrer"
                data-test-id={text?.defaultTx}
            >
                <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    p="16px 24px"
                >
                    {text && (
                        <Typograph
                            fontSize={[1, 2]}
                            tx={text.tx}
                            defaultTx={text.defaultTx}
                            color="textBlack"
                        />
                    )}
                    {children}
                    {iconRight ? (
                        <Icon maxWidth="28px" src={iconRight} />
                    ) : null}
                </Flex>
            </a>
            <MenuDivider />
        </Flex>
    </MenuItemContainer>
);
