import { call, put } from 'redux-saga/effects';

import { apiClient } from 'services/api';
import { log } from 'utils/logging';
import { DashboardTypes, DashboardState } from 'reducers/dashboard.redux';

type Response = {
    ok: boolean;
    data: DashboardState;
};

export function* onFetchDashboardRequest() {
    try {
        const { data, ok }: Response = yield call(
            apiClient.fetchDashboardState
        );

        if (!ok) {
            throw new Error(
                'something went wrong with apiClient.fetchDashboardState'
            );
        }

        yield put({
            type: DashboardTypes.FETCH_DASHBOARD_SUCCESS,
            dashboardState: data
        });
    } catch (error) {
        log({ error: `Dashboard get failure: ${error}` });
        yield put({
            type: DashboardTypes.FETCH_DASHBOARD_FAILURE,
            error
        });
    }
}
