import { RegionMapTimeZones } from './../constants/appConstants';
import { defineMessages } from 'react-intl';
import qs from 'qs';

import { messages } from 'messages';
import NestoSecureLogoEN from 'assets/media/icons/nesto_secure_logo_en.png';
import NestoSecureLogoMobileEN from 'assets/media/icons/nesto_secure_logo_en_mobile.png';
import NestoSecureLogoFR from 'assets/media/icons/nesto_secure_logo_fr.png';
import NestoSecureLogoMobileFR from 'assets/media/icons/nesto_secure_logo_fr_mobile.png';
import { Region } from 'types/application/application';
import { Language } from 'types/language';

export const getLanguageFromUrl = ({
    pathname,
    search
}: {
    pathname: string;
    search: string;
}): Language | undefined => {
    const acceptedLanguage = ['ca-fr', 'en'];
    const splitPath = pathname ? pathname.split('/') : [];
    const { lang } = qs.parse(search && search.replace(/\?/, ''));

    if (
        splitPath.includes(acceptedLanguage[0]) ||
        lang === acceptedLanguage[0]
    ) {
        return Language.French;
    }

    if (
        splitPath.includes(acceptedLanguage[1]) ||
        lang === acceptedLanguage[1]
    ) {
        return Language.English;
    }

    return undefined;
};

export const getCurrencyByLocale = (
    language: Language | string,
    number: number,
    options?: Intl.NumberFormatOptions
) =>
    new Intl.NumberFormat(`${language}-CA`, {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        ...options
    })
        .format(number)
        .replace(' CA', '');

export const formatPercentageLocalized = (
    language: Language | string,
    number: number,
    options?: Intl.NumberFormatOptions
) =>
    new Intl.NumberFormat(`${language}-CA`, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options
    }).format(number);

export function identifyBrowserLanguage(): Language {
    /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    try {
        // Check if browser language is in French or else defaults to English
        const sessionAccount =
            sessionStorage.getItem('persist:account') &&
            JSON.parse(sessionStorage.getItem('persist:account') || '');

        if (sessionAccount.account) {
            const { preferredLanguage } = JSON.parse(sessionAccount.account);

            if (preferredLanguage) {
                return preferredLanguage;
            }
        }
    } catch (err) {}

    const staticSiteLanguage = getLanguageFromUrl(window.location);
    if (staticSiteLanguage) {
        return staticSiteLanguage;
    }

    const multiLanguage = navigator.languages ? navigator.languages[0] : null;

    const navigatorLanguage = navigator.language || multiLanguage || 'en';
    if (navigatorLanguage.toLowerCase().indexOf(Language.French) > -1) {
        return Language.French;
    }
    return Language.English;
}

export const userLocale = identifyBrowserLanguage();

export function changeLocale(language: Language) {
    const newMessages = messages[language];
    defineMessages(newMessages as any);

    return newMessages;
}

export const getNestoSecuredLogoSrc = (isMobile, currentLang) => {
    const Logos = {
        mobile: {
            en: NestoSecureLogoMobileEN,
            fr: NestoSecureLogoMobileFR
        },
        desktop: {
            en: NestoSecureLogoEN,
            fr: NestoSecureLogoFR
        }
    };

    if (isMobile) {
        return Logos.mobile[currentLang]
            ? Logos.mobile[currentLang]
            : Logos.mobile.en;
    }

    return Logos.desktop[currentLang]
        ? Logos.desktop[currentLang]
        : Logos.desktop.en;
};

/**
 * continue to add time zone names to this union as needed.
 * a full list can be found here: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
export const convertTimeZone = (date: Date, timeZoneString: Region): Date => {
    // converted to utc string for time zone conversion
    return new Date(
        date.toLocaleString('en-US', {
            timeZone: RegionMapTimeZones[timeZoneString]
        })
    );
};

export const isMatchingIpApiPostalCode = (
    userPostalCode: string,
    IpApiPostalCode: string
): boolean => {
    const shortPostalCode = userPostalCode && userPostalCode.slice(0, 3);

    return shortPostalCode === IpApiPostalCode;
};
