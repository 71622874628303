import React from 'react';
import { createGlobalStyle } from 'styled-components';

// Below is a hack to hide the error overlay from CRA
const GlobalStyle = createGlobalStyle`
  .calendly-overlay iframe {
    display: inline !important;
    background: none;
  }
`;

export const GlobalStyles = ({ children }) => (
    <>
        {children}
        <GlobalStyle />
    </>
);
