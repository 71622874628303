import styled, { css } from 'styled-components/macro';
import { transparentize } from 'polished';

export const PageOverlay = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: ${props => transparentize(0.1, props.theme.colors.white)};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${props => props.theme.zIndex.sky};
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease 0s;
    ${({ isOpen }: { isOpen: boolean }) =>
        isOpen &&
        css`
            opacity: 1;
            visibility: visible;
        `};

    ${({ theme }) => theme.mediaQueries.large} {
        display: none;
    }
`;
