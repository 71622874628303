import React, { ClipboardEvent } from 'react';
import * as Validators from 'lib/validators';

import { TextField, TextFieldProps } from './text-input.component';

type Props = {
    preventCopy?: boolean;
    preventPaste?: boolean;
};

const onPreventCopy = (event: ClipboardEvent<HTMLInputElement>) =>
    event.preventDefault();

const onPreventPaste = (event: ClipboardEvent<HTMLInputElement>) =>
    event.preventDefault();

const EmailField = ({
    validate = [],
    isRequired = true,
    preventCopy,
    preventPaste,
    ...rest
}: TextFieldProps & Props) => (
    <TextField
        type="email"
        isRequired={isRequired}
        onCopy={preventCopy ? onPreventCopy : undefined}
        onPaste={preventPaste ? onPreventPaste : undefined}
        validate={[Validators.isEmail, Validators.maxEmailLength, ...validate]}
        {...rest}
    />
);

export { EmailField };
