import { select, call } from 'redux-saga/effects';
import { track } from 'utils/analytics';

import { getAccountId, selectUserLanguage } from 'reducers/account.selectors';
import { getCurrentPath } from 'reducers/startup.redux';
import { getPartnerName } from 'reducers/ui.selectors';

type AnalyticEventBasePayload = {
    tenant?: string; // current tenant default to 'nesto'
    event_location_category: string; // 'get a quote' | 'application' | 'account' | 'co_branded_platform
    // page pathname
    location?: string;
    info?: string; // 'email' | 'facebook' | 'google' | <variant name>
    type?: 'new mortgage' | 'renewal' | 'refinance' | 'signup' | string; // 'new mortgage' | 'renewal' | 'refinance' | 'signup'
    language_site?: string; // 'en' | 'fr' default to current customer language
};

type AnalyticEventPayload<
    T = Record<string, string>
> = AnalyticEventBasePayload & T;

type AnalyticEventOptions = Record<string, boolean>;

export type AnalyticEvent<T = Record<string, string>> =
    | {
          name: 'co_branded_platform';
          payload: AnalyticEventPayload<AnalyticEventPayloadCoBrandPartner>;
      }
    | {
          name: 'create_account';
          payload: AnalyticEventPayload<AnalyticEventPayloadCreateAccount>;
      }
    | {
          name: 'log_in';
          payload: AnalyticEventPayload<AnalyticEventPayloadLogin>;
      }
    | {
          name: 'start_application';
          payload: AnalyticEventPayload<AnalyticEventPayloadStartApplication>;
      }
    | {
          name: 'submit';
          payload: AnalyticEventPayload<AnalyticEventPayloadSubmit>;
      }
    | {
          name: 'gds';
          payload: AnalyticEventPayload<AnalyticEventPayloadGDS>;
      }
    | {
          name: 'tds';
          payload: AnalyticEventPayload<AnalyticEventPayloadTDS>;
      }
    | {
          name: 'launch_darkly_test';
          payload: AnalyticEventPayload<AnalyticEventPayloadLD>;
      }
    | {
          name: 'start_product_onboarding';
          payload: AnalyticEventPayload<AnalyticEventPayloadProductSelection>;
      }
    | {
          name: 'start_product_selection';
          payload: AnalyticEventPayload<AnalyticEventPayloadProductSelection>;
      }
    | {
          name: 'skip_onboarding';
          payload: AnalyticEventPayload<AnalyticEventPayloadProductSelection>;
      }
    | {
          name: 'information';
          payload: AnalyticEventPayload<AnalyticEventPayloadProductSelection>;
      }
    | {
          name: 'confirm_selection';
          payload: AnalyticEventPayload<AnalyticEventPayloadProductSelection>;
      }
    | {
          name: 'confirm_product';
          payload: AnalyticEventPayload<AnalyticEventPayloadProductSelection>;
      }
    | {
          name: 'book_a_call';
          payload: AnalyticEventPayload<AnalyticEventPayloadProductSelection>;
      }
    | {
          name: 'change_section';
          payload: AnalyticEventPayload<AnalyticEventPayloadChangeSection>;
      }
    | {
          name: 'application_tracker';
          payload: AnalyticEventPayload<AnalyticEventPayloadChangeSection>;
      }
    | {
          name: 'viewed_question';
          payload: AnalyticEventPayload<AnalyticEventPayloadQuestionViewed>;
      }
    | {
          name: 'updated_answer';
          payload: AnalyticEventPayload<AnalyticEventPayloadQuestionUpdated>;
      }
    | {
          name: 'offer_shown';
          payload: AnalyticEventPayload<
              AnalyticEventPayloadPartnerReferralViewed
          >;
      }
    | {
          name: 'offer_shown';
          payload: AnalyticEventPayload<
              AnalyticEventPayloadPartnerReferralClicked
          >;
      }
    | {
          name: 'log_out';
          payload: AnalyticEventPayload<AnalyticEventPayloadLogout>;
      }
    | {
          name: 'bankruptcy_answers';
          payload: AnalyticEventPayload<AnalyticEventPayloadBankruptcy>;
      }
    | {
          name: 'compare_rates';
          payload: AnalyticEventPayload<AnalyticEventCompareRates>;
      }
    | {
          name: 'filter_change';
          payload: AnalyticEventPayload<AnalyticEventRatesFilterChange>;
      }
    | {
          name: 'rate_type';
          payload: AnalyticEventPayload<AnalyticEventRatesType>;
      }
    | {
          name: 'save';
          payload: AnalyticEventPayload<AnalyticEventSaveApplication>;
      }
    | {
          name: 'calendar_view_availabilities';
          payload: AnalyticEventPayload<AnalyticEventCalendarViews>;
      }
    | {
          name: string;
          payload: AnalyticEventPayload<T>;
      };

export type AnalyticEventPayloadCoBrandPartner = {
    event_location_category: 'co-branded platforms';
    co_branded_partner: string;
};

export type AnalyticEventPayloadCreateAccount = {
    event_location_category: 'get a quote';
    info: 'email' | 'facebook' | 'google';
    province: string; // same value as cfProvince in identify call
};

export type AnalyticEventPayloadLogin = {
    event_location_category: 'account';
    info: 'email' | 'facebook' | 'google';
};

export type AnalyticEventPayloadStartApplication = {
    event_location_category: 'application';
    info: string;
};

export type AnalyticEventPayloadSubmit = {
    event_location_category: 'application';
    info: string;
};

export type AnalyticEventPayloadGDS = {
    event_location_category: 'application';
    info: 'high' | 'excellent' | 'good' | 'fair' | 'poor';
    value: string | number; // user's GDS ratio
    GDS: string | number; // user's GDS ratio
    maxGDS: string | number; // user's max GDS ratio
};

export type AnalyticEventPayloadTDS = {
    event_location_category: 'application';
    info: 'high' | 'excellent' | 'good' | 'fair' | 'poor';
    value: string | number; // user's TDS ratio
    TDS: string | number; // user's TDS ratio
    maxTDS: string | number; // user's max TDS ratio
};

export type AnalyticEventPayloadLD = {
    event_location_category: 'launchdarkly tests';
    flag_name: string;
    info: string; // <variant name> | value
    feature_flag_tests: string; // <flag name 1> : <variation name 1> | <flag name 2> : <variation name 2> | ...
    value: string | number; // user's TDS ratio
    TDS: string | number; // user's TDS ratio
    maxTDS: string | number; // user's max TDS ratio
};
export type AnalyticEventPayloadProductSelection = {
    event_location_category: 'product selection';
    info: string; //<button text> | slide <onboarding slide #>
    value?: string; // <rate*100 without percent sign> we send value for confirm_product
    type: 'new mortgage' | 'renewal' | 'refinance' | 'unknown'; //[new mortgage|renewal|refinance|unknown]
};
export type AnalyticEventPayloadChangeSection = {
    main_section_name: string; //<main section name>
    event_location_category: 'navigation';
    info?: string; //<sub-section name>
};

export type AnalyticEventPayloadQuestionViewed = {
    event_location_category: 'get a quote';
    info: string; // <question #>|<question name>
    type: string; // [new mortgage|renewal|refinance|renewal/refinance|choice page]
    question_id: string | number; // <question #>
    question_name: string | number; // <question_name>
    step: string | number; // <step #>
};

export type AnalyticEventPayloadQuestionUpdated = {
    event_location_category: 'get a quote';
    info: string; // <question #>|<question name>
    type: string; // [new mortgage|renewal|refinance|renewal/refinance|choice page]
    question_id: string | number; // <question #>
    question_name: string | number; // <question_name>
    answer: { [s: string]: string } | string; // <answer>]
    step: string | number; // <step #>
};

export type AnalyticEventPayloadPartnerReferralViewed = {
    event_location_category: 'offer_shown';
    info: string; //<declined reason>
    type: 'new mortgage' | 'renewal' | 'refinance' | 'unknown'; //[new mortgage|renewal|refinance|unknown]
};
export type AnalyticEventPayloadPartnerReferralClicked = {
    event_location_category: 'offer_clicked';
    info: string; //<declined reason>
    type: 'new mortgage' | 'renewal' | 'refinance' | 'unknown'; //[new mortgage|renewal|refinance|unknown]
};
export type AnalyticEventPayloadLogout = {
    event_location_category: 'log_out';
    info: string; // <page path>
    type: string; // <page path>
};

export type AnalyticEventPayloadBankruptcy = {
    event_location_category: 'bankruptcy';
    answer: 'yes' | 'no'; //[yes|no]
    info: 'main applicant' | 'co-applicant'; // [main applicant|co-applicant]
    type: 'new mortgage' | 'renewal' | 'refinance' | 'unknown'; // [new mortgage|renewal|refinance|unknown]
};
export type AnalyticEventCompareRates = {
    event_location_category: 'get a quote';
    location: 'header';
    info: string; // <button text>
};
export type AnalyticEventRatesFilterChange = {
    filter_element: string; // [term|amortization|amount|down-payment|property-type|owner-occupied]
    info: string; // <new answer>
    type: 'new mortgage' | 'renewal' | 'refinance'; // [new mortgage|renewal|refinance]
    event_location_category: 'rates';
};
export type AnalyticEventRatesType = {
    info: string; // <variable|fixed>
    type: 'new mortgage' | 'renewal' | 'refinance'; // [new mortgage|renewal|refinance]
    event_location_category: 'rates';
};
export type AnalyticEventSaveApplication = {
    event_location_category: 'application';
    info: string; // <section name>
};
export type AnalyticEventCalendarViews = {
    event_location_category: 'application';
    current_applicant_id: number; //<current applicant nesto ID>
};

///
// ----

export const AnalyticEventTypes = {
    ANALYTIC_EVENT: 'ANALYTICS_EVENT'
};

export const analyticEvent = (
    name: AnalyticEvent['name'],
    payload: AnalyticEvent['payload'],
    options?: AnalyticEventOptions
) => ({
    type: AnalyticEventTypes.ANALYTIC_EVENT,
    name,
    payload,
    options
});

export const fromAngryCase = (string: string): string =>
    string &&
    string
        .split('_')
        .map(s => s.toLowerCase())
        .join(' ');
const types = {
    NEW: 'new mortgage',
    RENEWAL: 'renewal',
    RENEWAL_MORTGAGE: 'renewal'
};
export const getType = (
    type: 'NEW' | 'RENEWAL' | 'RENEWAL_MORTGAGE' | string
) => types[type] || type;

// handlers
export function* onAnalyticEvent(
    evt: AnalyticEvent & { options?: AnalyticEventOptions }
) {
    const languageSite = yield select(selectUserLanguage);
    const location = yield select(getCurrentPath);
    const coBrandedPartner = yield select(getPartnerName);
    const accountId = yield select(getAccountId);
    const isProduction = window.config.env === 'production';
    const payload = evt.payload as AnalyticEvent<
        Record<string, string>
    >['payload'];

    yield call(track, evt.name, {
        ...payload,
        session_id:
            isProduction &&
            window?.google_tag_manager?.['GTM-KKVK4SF']?.dataLayer.get(
                'gtagApiResult.session_id'
            ),
        session_number:
            isProduction &&
            window?.google_tag_manager?.['GTM-KKVK4SF']?.dataLayer.get(
                'gtagApiResult.session_number'
            ),
        gaid: isProduction && window?.gaGlobal?.vid,
        co_branded_partner: coBrandedPartner,
        // @ts-ignore
        location: payload.location || location,
        language_site: languageSite,
        tenant: payload?.tenant || 'nesto',
        type: payload?.type && fromAngryCase(getType(payload?.type)),
        userId: accountId
    });
}
