import { DidomiSDK } from '@didomi/react';
import { useSelector } from 'react-redux';

import { getAccount } from 'reducers/account.selectors';

export const DidomiProvider = () => {
    const account = useSelector(getAccount);

    // if user account preferredLanguage is 'en' or 'fr' then set it as default language
    // otherwise set 'en' as default language
    // URLs starting with /ca-fr/ will have 'fr' as default language `preferredLanguage`
    //
    // ex: `preferredLanguage` is `fr` then
    // {
    //     languages: {
    //         enabled: ['fr'],
    //         default: 'fr'
    //     }
    // }
    // ref: https://support.didomi.io/how-to-force-the-language-of-my-banner-with-the-language-of-my-website
    const didomiConfig = {
        languages: {
            enabled: ['en', 'fr'].includes(account?.preferredLanguage)
                ? [account?.preferredLanguage]
                : ['en', 'fr'],
            default: ['en', 'fr'].includes(account?.preferredLanguage)
                ? account?.preferredLanguage
                : 'en'
        }
    };

    return (
        <DidomiSDK
            apiKey="b1c3471f-8907-46ed-b5a6-0c2b04626f5b"
            noticeId="qMQdrWme"
            gdprAppliesGlobally={false}
            sdkPath="https://sdk.privacy-center.org/"
            config={didomiConfig}
        />
    );
};
