import { put, call, select } from 'redux-saga/effects';
import { isNil, omit } from 'ramda';
import qs from 'qs';

import { log } from 'utils/logging';
import { push, replace } from 'connected-react-router';
import { apiClient } from 'services/api';
import {
    getApplicantLanguage,
    selectCurrentRoute,
    getIsContactSignup,
    getRefererPath,
    getCurrentSearchParams,
    getImpressionsTrackingId,
    isBehalfAUser,
    getAffiliateMarketingId
} from 'reducers/account.selectors';
import { getQuoteTypeFromRouterState } from 'reducers/router.selector';

import {
    Actions,
    AccountTrackingPayload,
    CommunicationPreferences,
    CallPreferencesPayload
} from 'reducers/account.redux';
import { Actions as UIActions } from 'reducers/ui.redux';
import {
    errorNotification,
    accountErrorsNotification,
    successNotification
} from 'reducers/notifications.redux';
import { Actions as ApplicationActions } from 'reducers/application.redux';
import {
    analyticsTrackCreateAccountSuccess,
    analyticsTrackLoginSuccess,
    getGAClientId,
    analyticsTrackValidPhoneNumber,
    getTypeFromFormName
} from 'sagas/analytics.sagas';
import { isLoggedIn } from 'reducers/auth.selectors';
import { getAccount } from 'reducers/account.selectors';
import { getRefreshToken } from 'reducers/auth.selectors';
import { getUserKey } from 'components/feature-flagger/getUserKey';
import { getFeatureFlagState } from 'components/feature-flagger/hooks';
import { onFetchApplicationsRequest } from 'sagas/application.sagas';
import { getPartner } from 'reducers/ui.selectors';
import { LeadReferralVariant, PARTNER_NAMES } from 'constants/appConstants';
import { getActiveApplicationId } from 'reducers/application.selectors';
import { Actions as RatesActions } from 'reducers/rates.redux';
import { getCookie, parseUtmzCookie } from 'utils/cookie';
import { isFunction, processError } from 'utils/helpers';
import { getSubPartnerIdFallback } from 'utils/partner-util';
import { Routes } from 'app-root/routing/routes';
import { getIsPhoneNumberReal } from 'utils/mortgage-utils';
import { RequestStatus } from 'services/api/ApiClient';
import { isMatchingIpApiPostalCode } from 'utils/localization-utils';
import { getLocalePostalCode } from 'reducers/locale.selector';
import { analyticEvent } from './analytics-events.sagas';

// let's make sure to omit the user's password from being leaked to our analytics.
export const omitUserProps = omit(['password', 'passwordConfirm', 'token']);
export const omitAccountProps = omit(['token']);
const SHORT_POSTAL_CODE_LENGTH = 3;

export function* onCreateAccount({
    userInfo,
    redirect,
    noRedirect
}: {
    userInfo: any;
    redirect?: boolean;
    noRedirect?: boolean;
}) {
    try {
        const currentRoute = yield select(selectCurrentRoute);
        const currentLanguage = yield select(getApplicantLanguage);
        const gaClientId = yield call(getGAClientId);
        const isContact = yield select(getIsContactSignup); // if param contact = true is passed act like get a quotee
        const impressionsTrackingId = yield select(getImpressionsTrackingId);
        const affiliateMarketingId = yield select(getAffiliateMarketingId);
        const gaqQuoteType = yield select(getQuoteTypeFromRouterState);
        const partner = yield select(getPartner);

        const isShortPostalCode =
            userInfo?.postalCode?.length === SHORT_POSTAL_CODE_LENGTH;

        const { ipApi, ipInfo } = yield select(getLocalePostalCode);
        const isPostalCodeMatchingIpApi = isMatchingIpApiPostalCode(
            userInfo?.postalCode,
            ipApi
        );
        const isPostalCodeMatchingIpInfo = isMatchingIpApiPostalCode(
            userInfo?.postalCode,
            ipInfo
        );

        const sessionSubPartnerId =
            sessionStorage.getItem('subPartnerId') ||
            getSubPartnerIdFallback(partner);

        // UTMz cookie values and Marketing channel
        const utmCookie = getCookie('__utmz') || getCookie('__utmzz') || '';
        const utm = parseUtmzCookie(utmCookie);
        const marketingChannel = utm.channel;
        const gclid = utm.gclid;
        const wbraid = utm.wbraid;
        const msclkid = utm.msclkid;

        const fbp = getCookie('_fbp');
        const fbc = getCookie('_fbc');

        const filterRealPhoneNumberFlag = yield getFeatureFlagState(
            'filter-real-phone-number'
        );

        let validPhoneNumber = true;

        if (filterRealPhoneNumberFlag && userInfo?.phone) {
            const formName = userInfo?.formName ? userInfo?.formName : 'SIGNUP';
            const formattedPhoneNumber = `1${userInfo.phone}`;
            validPhoneNumber = yield call(
                getIsPhoneNumberReal,
                formattedPhoneNumber,
                userInfo.phone
            );

            yield put(
                analyticsTrackValidPhoneNumber({
                    userInfo: { is_valid: validPhoneNumber, formType: formName }
                })
            );
        }
        const isDirect = marketingChannel === 'Direct';
        const isSocial = marketingChannel === 'Social';

        const { ok, data } = yield call(apiClient.createAccount, {
            ...userInfo,
            leadDistributeConsentAgreement:
                userInfo.leadDistributeConsentAgreement ?? false,
            partnerAgreement: userInfo.partnerAgreement ?? false,
            emailConsent:
                partner === 'wealthSimple'
                    ? true
                    : (userInfo.emailConsent || // WealthSimple is the only partner that doesn't require email consent, the consent is implied by the user signing up
                          userInfo.leadDistributeConsentAgreement) ??
                      false,
            region: userInfo.province,
            language: currentLanguage,
            postalCode: !isShortPostalCode ? userInfo?.postalCode : '',
            partialPostalCode: isShortPostalCode ? userInfo?.postalCode : '',
            passwordSpecified: !isNil(userInfo.password),
            createdAt:
                currentRoute === Routes.signUp && !isContact
                    ? 'LOGIN'
                    : 'GET_A_QUOTE',
            gaClientId,
            anonymousAccountId: getUserKey(),
            marketingChannel,
            utmSource: utm.source,
            utmMedium: utm.medium,
            utmCampaign: utm.campaign,
            utmTerm: utm.term,
            utmContent: utm.content,
            impressionsTrackingId,
            affiliateMarketingId,
            impressionsTrackingIdSpecified: !!impressionsTrackingId,
            partner,
            subPartnerId: +sessionSubPartnerId,
            fbp,
            fbc,
            gclid,
            wbraid,
            msclkid,
            phone: validPhoneNumber ? userInfo?.phone : undefined,
            direct: isDirect,
            social: isSocial ? utm.source : false
        });

        if (!ok) {
            log({ error: `createAccount error: ${data.error}` });
            return yield put(
                errorNotification({
                    title:
                        accountErrorsNotification?.[data.error]?.title ||
                        undefined,
                    text:
                        accountErrorsNotification?.[data.error]?.text ||
                        'toasts.genericError'
                })
            );
        }

        const { account, token } = data;

        yield call(apiClient.setAuthToken, token);

        const leadReferralVariant: LeadReferralVariant = yield getFeatureFlagState<
            LeadReferralVariant
        >('lead-referral-variant');
        const isSplitContactPage =
            leadReferralVariant === LeadReferralVariant.LEAD_REFERRAL_SPLIT;
        const isLeadReferral = [
            LeadReferralVariant.LEAD_REFERRAL_SINGLE,
            LeadReferralVariant.LEAD_REFERRAL_SPLIT,
            LeadReferralVariant.LEAD_REFERRAL_SHORT_CONSENT_V2,
            LeadReferralVariant.LEAD_REFERRAL_SHORT_CONSENT_FONT_SIZE,
            LeadReferralVariant.LEAD_REFERRAL_SHORT_CONSENT,
            LeadReferralVariant.LEAD_REFERRAL_SHORT_CONSENT_FONT_SIZE_V2
        ].includes(leadReferralVariant);
        const consentType = isLeadReferral
            ? 'partner consent'
            : 'email consent';
        const consentValue = isLeadReferral
            ? userInfo.leadDistributeConsentAgreement
            : userInfo.emailConsent;
        const labelConsent = `${
            isSplitContactPage ? 'split' : 'single'
        } - ${consentType} - ${String(consentValue ?? false)}`;

        yield put(
            analyticEvent('create_consent', {
                event_location_category: 'account',
                create_consent: labelConsent,
                type: getTypeFromFormName(userInfo.formName)
            })
        );

        yield put(Actions.createAccountSuccess(account, token));
        const isAnonymousRates = yield getFeatureFlagState(
            'anonymous-rates-page'
        );

        // GA-4
        yield put(
            analyticEvent('test_details', {
                event_location_category: 'test',
                test_event: 'postal_code_match_ipapi',
                info: isPostalCodeMatchingIpApi ? 'true' : 'false'
            })
        );

        // GA-4
        yield put(
            analyticEvent('test_details', {
                event_location_category: 'test',
                test_event: 'postal_code_match_ipinfo',
                info: isPostalCodeMatchingIpInfo ? 'true' : 'false'
            })
        );

        yield put(
            analyticsTrackCreateAccountSuccess({
                userInfo: {
                    ...omitUserProps(userInfo),
                    anonymousId: getUserKey(),
                    isAnonymousRates: isAnonymousRates ? true : undefined,
                    unsubscribed_from_emails: !userInfo.emailConsent
                }
            })
        );

        if (gaqQuoteType) {
            yield put(
                ApplicationActions.createApplicationsRequest(gaqQuoteType)
            );
        }

        if (redirect && !noRedirect) {
            const referalPath = yield select(getRefererPath);
            yield put(push(referalPath));
        }
    } catch (error) {
        log({ error: `createAccount error: ${error}` });
    }
}

export function* onLoginRequest({ loginFormValues }: any) {
    try {
        const currentPartner = yield select(getPartner);
        const { ok, data } = yield call(apiClient.loginAccount, {
            params: {
                ...loginFormValues
            }
        });

        if (!ok) {
            let errorMessage = data && data.error;
            if (data.reason === 'password not set') {
                errorMessage = data.reason;
                yield put(push('/forgot'));
            } else if (data.error === 'too many requests') {
                errorMessage = data.error;
                yield put(push('/forgot'));
            }

            log({ error: errorMessage });
            yield put(
                errorNotification({
                    title:
                        accountErrorsNotification?.[errorMessage]?.title ||
                        undefined,
                    text:
                        accountErrorsNotification?.[errorMessage]?.text ||
                        'toasts.genericError'
                })
            );

            return yield put(Actions.loginFailure(errorMessage));
        }

        const { account, token } = data;

        yield call(apiClient.setAuthToken, token);

        yield put(Actions.loginSuccess(account, token));
        yield call(onFetchApplicationsRequest);

        if (account && account.partner && currentPartner !== account.partner) {
            const utmSource =
                // possibility of `undefined` from `PARTNER_NAMES[account.partner]`
                // so we use `|| account.partner || "nesto"` to prevent `undefined` from being used
                // NO you wont crash here anymore ¯\_(ツ)_/¯
                PARTNER_NAMES[account.partner || 'nesto']?.utm_source ||
                account.partner ||
                'nesto';

            // GA-4
            yield put(
                analyticEvent('co_branded_platform', {
                    event_location_category: 'co-branded platforms',
                    co_branded_partner: utmSource
                })
            );
        }

        // Load communication settings
        yield put(Actions.getCommunicationPreferences());

        // Load account deletion status
        yield put(Actions.getAccountDeletionState());

        const referalPath = yield select(getRefererPath);
        yield put(push(referalPath));
        yield put(analyticsTrackLoginSuccess());
    } catch (error) {
        log({ error: `onLoginRequest type error: ${error}` });
        yield put(Actions.loginFailure({ error }));
        yield put(errorNotification({ text: 'toasts.genericError' }));
    }
}

export function* onPasswordResetRequest({ resetPasswordFormValues }: any) {
    const { ok, data } = yield call(apiClient.resetPassword, {
        params: {
            ...resetPasswordFormValues
        }
    });
    if (!ok) {
        yield put(Actions.passwordResetFailure(data));
        log(data);
        return yield put(errorNotification({ text: 'toasts.genericError' }));
    }

    yield put(Actions.passwordResetSuccess());
}

export function* onPasswordChangeRequest({ changePasswordFormValues }: any) {
    try {
        const { id, password, ...formValues } = changePasswordFormValues;
        const { ok, data } = yield call(
            apiClient.changePassword,
            {
                params: {
                    ...formValues,
                    newPassword: password
                }
            },
            id
        );

        if (!ok) {
            yield put(Actions.passwordChangeFailure(data));
            log({ error: `passwordChangeFailure failure: ${data.error}` });
            return yield put(
                errorNotification({
                    text:
                        data.error === 'invalid token'
                            ? 'toasts.resetPasswordUnauthorizedToken'
                            : 'toasts.genericError'
                })
            );
        }

        yield put(Actions.passwordChangeSuccess());

        const { account, token } = data;
        if (account) {
            yield call(apiClient.setAuthToken, token);

            yield put(Actions.loginSuccess(account, token));

            yield put(push('/'));

            yield put(analyticsTrackLoginSuccess());

            yield put(
                successNotification({
                    text: 'toasts.account.passwordChanged'
                })
            );
        } else {
            yield put(push('/login'));
        }
    } catch (error) {
        log({ error: `passwordChangeFailure type error: ${error}` });
    }
}

export function* onActivateAccountRequest({
    activationToken,
    accountId,
    redirect = false
}: {
    activationToken: string;
    accountId: string;
    redirect: boolean;
}) {
    try {
        const { ok, data } = yield call(
            apiClient.activateAccount,
            {
                params: {
                    activationToken
                }
            },
            accountId
        );

        if (!ok) {
            yield put(Actions.activateAccountFailure(data));
            log({ error: `onActivateAccountRequest failure: ${data.error}` });

            return yield put(
                errorNotification({
                    text:
                        data.error === 'invalid token'
                            ? 'toasts.activationUnauthorizedToken'
                            : 'toasts.genericError'
                })
            );
        }

        yield put(Actions.activateAccountSuccess());

        if (redirect) {
            const { account, token } = data;

            yield call(apiClient.setAuthToken, token);

            yield put(Actions.loginSuccess(account, token));

            yield put(
                successNotification({
                    text: 'toasts.account.activated'
                })
            );

            yield put(push('/'));
        }
    } catch (error) {
        log({ error: `activateAccount type error: ${error}` });
        yield put(Actions.activateAccountFailure());
    }
}

export function* onGetAccount() {
    try {
        if (!(yield select(isLoggedIn))) {
            yield put(Actions.getAccountExit('User is not logged in'));
            return;
        }

        const { data, ok, status } = yield call(apiClient.getAccount);

        if (!ok) {
            if (status === RequestStatus.UnauthorizedToken) {
                yield put(Actions.logout());
            }

            const error = `Failed to get Account on Startup: ${data}`;
            yield put(Actions.getAccountFailure(error));
            return log({ error });
        }

        if (data.role === 'broker') {
            yield put(Actions.getAccountExit('User is Broker'));
            return;
        }

        yield put(Actions.getAccountSuccess(data));
    } catch (error) {
        yield put(Actions.getAccountFailure(error));
        return log({ error: `Failure in getAccount Function` });
    }
}

export function* getBrokerAccountRequest() {
    try {
        const { ok, data } = yield call(apiClient.getBrokerAccount);

        if (!ok) {
            // error API cannot get broker account with current token
            log({ error: data.error, tag: 'broker-account' });
            yield put(Actions.getBrokerAccountFailure(data.error));
            return yield put(
                errorNotification({
                    title:
                        accountErrorsNotification?.[data.error]?.title ||
                        undefined,
                    text:
                        accountErrorsNotification?.[data.error]?.text ||
                        'toasts.genericError'
                })
            );
        }

        yield put(Actions.getBrokerAccountSuccess(data));
    } catch (error) {
        const err = processError(error, 'unable to get broker account');

        log({ error: err, tag: 'broker-account' });
        yield put(Actions.getBrokerAccountFailure(error));
    }
}

export function* onBrokerLoginRequest({ loginFormValues }: any) {
    const search = yield select(getCurrentSearchParams);
    const { accountRid } = qs.parse(search, {
        ignoreQueryPrefix: true
    });

    try {
        // see comment below
        // find `localStorage.setItem('broker_on_behalf_of', account.rid);`
        const currentRid = localStorage.getItem('broker_on_behalf_of');

        // Make sure currentRid isn't 'null'
        if (currentRid && currentRid !== accountRid) {
            yield call(onBehalfLogoutRequest);
        }
    } catch (error) {
        log({
            error: `onBrokerLoginRequest logout old user type error: ${error}`
        });
        yield put(Actions.brokerLoginFailure({ data: error }));
    }

    // Old Client Logout
    //
    // Having a separate try...catch like this
    // results in better logging of errors in Redux
    // Dev tools. We know for sure the error was
    // on LOGOUT and not NEW CLIENT LOGIN.
    //
    // eslint-disable-next-line no-useless-catch
    try {
        yield put(Actions.brokerLoginStart());
        const { ok, data } = yield call(apiClient.brokerLoginAccount, {
            params: {
                ...loginFormValues
            }
        });

        if (!ok) {
            if (data.reason === 'password not set') {
                data.error = data.reason;
                yield put(push('/forgot'));
            }

            yield put(Actions.brokerLoginFailure(data.error));
            return yield put(
                errorNotification({
                    title:
                        accountErrorsNotification?.[data.error]?.title ||
                        undefined,
                    text:
                        accountErrorsNotification?.[data.error]?.text ||
                        'toasts.genericError'
                })
            );
        }
        const { broker, token } = data;

        yield call(apiClient.setAuthToken, token);
        yield put(Actions.brokerLoginSuccess(broker, token));
        yield call(onBehalfLoginRequest, { accountRid });

        yield put(Actions.resetAnalyticsForBroker());
    } catch (error) {
        log({ error: `onBrokerLoginRequest type error: ${error}` });
        yield put(Actions.brokerLoginFailure({ data: error }));
    }
}

export function* onBehalfLoginRequest({ accountRid }) {
    // eslint-disable-next-line no-useless-catch
    try {
        // see comment below
        // find `localStorage.setItem('broker_on_behalf_of', account.rid);`
        const currentRid = localStorage.getItem('broker_on_behalf_of');

        // Make sure currentRid isn't 'null'
        if (currentRid && currentRid !== accountRid) {
            yield call(onBehalfLogoutRequest);
        }
    } catch (logoutError) {
        throw logoutError;
    }

    // New Client Login
    try {
        yield put(Actions.behalfLoginStart());
        const refreshToken = yield select(getRefreshToken);

        const { ok, data } = yield call(apiClient.behalfLoginAccount, {
            params: {
                accountRid,
                refreshToken
            }
        });

        if (!ok) {
            let errorMessage;
            if (data) {
                errorMessage = data.error;

                if (data.reason === 'password not set') {
                    errorMessage = data.reason;
                    yield put(push('/forgot'));
                }
            }

            if (errorMessage) {
                yield put(
                    errorNotification({
                        title:
                            accountErrorsNotification?.[errorMessage]?.title ||
                            undefined,
                        text:
                            accountErrorsNotification?.[errorMessage]?.text ||
                            'toasts.genericError'
                    })
                );
            }

            return yield put(Actions.behalfLoginFailure(errorMessage));
        }

        const { account, token } = data;

        yield call(apiClient.setAuthToken, token);
        yield put(Actions.behalfLoginSuccess(account, token));

        // From startup.sagas.ts
        // const currentRid = localStorage.getItem('broker_on_behalf_of');
        // startup logic fro on behalf user will grab the value of this
        // instead of getting the account from the BE
        // because on get account success will defined the session partner value
        // the old behavior was leading to corrupted data on client 2
        localStorage.setItem('broker_on_behalf_of', account.rid);

        yield call(onFetchApplicationsRequest);
        yield put(push('/'));
    } catch (error) {
        const err = processError(error, 'unable to login on behalf');

        log({ error: err });
        yield put(Actions.behalfLoginFailure({ error }));
    }
}

export function* onBehalfLogoutRequest() {
    try {
        yield put(Actions.behalfLogoutStart());
        const refreshToken = yield select(getRefreshToken);
        const { ok, data } = yield call(apiClient.behalfLogoutAccount, {
            params: {
                refreshToken
            }
        });

        if (!ok) {
            log(data.error);
            yield put(Actions.behalfLogoutFailure(data.error));

            return yield put(
                errorNotification({
                    title:
                        accountErrorsNotification?.[data.error]?.title ||
                        undefined,
                    text:
                        accountErrorsNotification?.[data.error]?.text ||
                        'toasts.genericError'
                })
            );
        }

        yield call(apiClient.setAuthToken, data);
        // token is not nested into data in this scenario (i.e. data.token === undefined)
        yield put(Actions.behalfLogoutSuccess(data));
    } catch (error) {
        const err = processError(error, 'unable to logout on behalf');

        log({ error: err });
        yield put(Actions.behalfLogoutFailure({ error }));
    }
}

export function* updateUserInformation({ formValues, resolve, reject }) {
    try {
        const account = yield select(getAccount);

        const { ok, data } = yield call(apiClient.updateAccount, {
            ...account,
            ...formValues
        });

        if (!ok) {
            log({ tag: 'UpdateUserError: ', error: data.error });
            yield put(Actions.behalfLogoutFailure({ error: data.error }));
            return yield put(
                errorNotification({
                    title:
                        accountErrorsNotification?.[data.error]?.title ||
                        undefined,
                    text:
                        accountErrorsNotification?.[data.error]?.text ||
                        'toasts.genericError'
                })
            );
        }

        yield put(Actions.updateUserSuccess(data));

        if (formValues) {
            yield put(
                successNotification({
                    text: 'application.dataUpdatedSuccess'
                })
            );
        }

        if (isFunction(resolve)) {
            resolve();
        }
    } catch (error) {
        const err = processError(error, 'unable to update user');

        log({
            tag: 'UpdateUserError type error: ',
            error: err
        });
        yield put(Actions.behalfLogoutFailure({ error }));
        if (isFunction(resolve)) {
            reject();
        }
    }
}

export function* updateUserPostalCode({ formValues }) {
    try {
        const search = yield select(getCurrentSearchParams);
        const searchParams = qs.parse(search, {
            ignoreQueryPrefix: true
        });
        yield put(Actions.updateUserInformation(formValues));

        yield put(
            replace({
                pathname: '/rates',
                search: qs.stringify({
                    ...searchParams,
                    postalCode: formValues?.postalCode
                })
            })
        );

        yield put(
            RatesActions.fetchQuotesMatrixRequest({
                ...searchParams,
                postalCode: formValues?.postalCode
            })
        );
    } catch (error) {
        const err = processError(error, 'unable to update postal code');

        log({
            tag: 'UpdateUserError type error: ',
            error: err
        });
        yield put(Actions.behalfLogoutFailure({ error }));
    }
}

export function* resetAnalyticsForBroker() {
    try {
        if (yield call(getFeatureFlagState, 'broker-analytics')) {
            // if the user is broker reload page to reload analytics with
            // speacial flags
            yield window.localStorage.setItem('isUserBroker', 'true');
            yield window.location.reload();
        }
    } catch (error) {
        const err = processError(error, 'unable to reset analytics for broker');

        log({
            tag: 'resetAnalyticsForBroker: ',
            error: err
        });
    }
}

export function* bootstrapImpressionAnalytics() {
    try {
        const search = yield select(getCurrentSearchParams);

        const { ext_pid: impressionsTrackingId } = qs.parse(search, {
            ignoreQueryPrefix: true
        });

        if (impressionsTrackingId) {
            yield put(
                Actions.updateImpressionsTrackingId(impressionsTrackingId)
            );
        }
    } catch (error) {
        const err = processError(error, 'unable to bootstrap impression');

        log({
            tag: 'boostrap Impression Tracking: ',
            error: err
        });
    }
}

export function* onCreateOrGetAccountSuccess({ account }) {
    yield call(onUpdatePartner);

    if (account && account.id) {
        window.DD_RUM?.setUser({
            id: account.id,
            name: `${account.firstName} ${account.lastName}`,
            email: account.email
        });
    }

    // Get Impression Tracking Id from state.account
    const impressionsTrackingId = yield select(getImpressionsTrackingId);

    if (
        impressionsTrackingId &&
        account?.impressionsTrackingId !== impressionsTrackingId
    ) {
        const { ok, data } = yield call(apiClient.updateAccount, {
            ...account,
            impressionsTrackingId,
            impressionsTrackingIdSpecified: true
        });

        if (!ok) {
            log({ tag: 'Update Account Error: ', error: data.error });
        }

        // Update user redux state: DO NOT add saga listener
        // AccountTypes.UPDATE_USER_SUCCESS, -> AccountSagas.onCreateOrGetAccountSuccess
        yield put(Actions.updateUserSuccess(data));
    }
}

export function* onUpdatePartner() {
    const account = yield select(getAccount);
    const sessionPartner = sessionStorage.getItem('partner');
    const sessionSubPartnerId = sessionStorage.getItem('subPartnerId') || 0;
    const isOnBehalfOf = yield select(isBehalfAUser);
    const shouldUpdatePartner =
        !isOnBehalfOf && // <- on behalf logged in should never update client account automaticaly
        sessionPartner &&
        sessionPartner !== 'nesto' &&
        account.partner !== sessionPartner;
    const shouldUpdateSubPartnerId =
        !isOnBehalfOf && // <- on behalf logged in should never update client account automaticaly
        +sessionSubPartnerId > 0 &&
        account.subPartnerId !== +sessionSubPartnerId;
    const shouldUpdateAccount =
        !isOnBehalfOf && (shouldUpdatePartner || shouldUpdateSubPartnerId);

    // SET theme UI
    // Since `shouldUpdatePartner` and `shouldUpdateSubPartnerId` have
    // `!isOnBehalfOf && ....` in the condition it will always be false
    // on representation account (Logged on behalf of a client)
    // so the UI will always be set as the client account partner and subPartnerId
    yield put(
        UIActions.updatePartner(
            shouldUpdatePartner ? sessionPartner : account.partner
        )
    );

    yield put(
        UIActions.setSubPartnerId(
            shouldUpdateSubPartnerId
                ? +sessionSubPartnerId
                : account.subPartnerId
        )
    );

    if (shouldUpdateAccount) {
        const payload = {
            ...account,
            partner: shouldUpdatePartner ? sessionPartner : account.partner,
            partnerSpecified: shouldUpdatePartner,

            subPartnerId: shouldUpdateSubPartnerId
                ? +sessionSubPartnerId
                : account.subPartnerId,
            subPartnerIdSpecified: shouldUpdateSubPartnerId
        };

        const response = yield call(apiClient.updateAccount, payload);

        yield put(Actions.updateUserSuccess(response.data));
    }
}

export function* onGetAccountTracking({ key }: { key: string }) {
    try {
        const loggedIn = yield select(isLoggedIn);

        if (loggedIn) {
            return yield call(apiClient.getAccountTracking, key);
        }

        return;
    } catch (error) {
        const err = processError(error, 'unable to GET account tracking');

        log({
            tag: 'GET Account Tracking Error: ',
            error: err
        });
    }
}

export function* onAccountTracking({
    payload
}: {
    payload: AccountTrackingPayload;
}) {
    try {
        const loggedIn = yield select(isLoggedIn);

        if (loggedIn) {
            yield call(apiClient.addAccountTracking, payload);
        }
    } catch (error) {
        const err = processError(error, 'unable to set account tracking');

        log({
            tag: 'ADD Account Tracking Error: ',
            error: err
        });
    }
}

export function* onAddCallPreferences({
    payload
}: {
    payload: CallPreferencesPayload;
}) {
    try {
        yield put(UIActions.setCallPreferenceRequestState('loading'));
        const { ok } = yield call(apiClient.addCallPreferences, payload);

        if (ok) {
            yield put(UIActions.setCallPreferenceRequestState('success'));
        }
        if (!ok) {
            yield put(UIActions.setCallPreferenceRequestState('error'));
        }
    } catch (error) {
        yield put(UIActions.setCallPreferenceRequestState('error'));
        const err = processError(error, 'unable to set call preferences');

        log({
            tag: 'Set call preferences Error: ',
            error: err
        });
    }
}

export function* onGetCommunicationPreferences() {
    try {
        const { ok, data } = yield call(apiClient.getCommunicationPreferences);

        if (!ok) {
            log(data.error);
            yield put(Actions.getCommunicationPreferencesFailure(data.error));
        }

        yield put(Actions.getCommunicationPreferencesSuccess(data));
    } catch (error) {
        const err = processError(
            error,
            'unable to get communication preferences'
        );

        log({ error: err });
        yield put(Actions.getCommunicationPreferencesFailure({ error }));
    }
}

export function* onUpdateCommunicationPreferences({
    payload
}: {
    payload: Partial<CommunicationPreferences>;
}) {
    try {
        const { ok, data } = yield call(
            apiClient.updateCommunicationPreferences,
            payload
        );

        if (!ok) {
            log(data.error);
            yield put(
                Actions.updateCommunicationPreferencesFailure(data.error)
            );
        }

        // BE is returning an empty object as `data`
        // so we need to call GET communication preferences to get newly updated result
        yield put(Actions.getCommunicationPreferences());
    } catch (error) {
        const err = processError(error, 'unable to update communication');

        log({ error: err });
        yield put(Actions.updateCommunicationPreferencesFailure({ error }));
    }
}

export function* onRouteChange({
    payload
}: {
    type: string;
    payload: { location: any; action: string; isFirstRendering: boolean };
    meta: any;
}) {
    const activeApplicationId = yield select(getActiveApplicationId);
    // We don't want to register broker only routes
    const isRepresentingUser = yield select(isBehalfAUser);

    if (
        activeApplicationId &&
        !payload.isFirstRendering &&
        payload.action === 'PUSH' &&
        !isRepresentingUser
    ) {
        const { pathname, search, hash } = payload.location;

        if (
            pathname === Routes.login ||
            pathname === Routes.applicationSelection
        ) {
            return;
        }

        yield put(
            Actions.accountTracking({
                key: `website.last-visited-page-${activeApplicationId}`,
                value: JSON.stringify({ pathname, search, hash })
            })
        );
    }

    return;
}

export function* onGetAccountDeletionState() {
    try {
        const { ok, data } = yield call(apiClient.getAccountDeletionState);

        if (!ok) {
            throw data.error;
        }

        yield put(Actions.getAccountDeletionStateSuccess(data));
    } catch (error) {
        const err = processError(error, 'unable to get account deletion state');

        log({
            error: err,
            tag: 'get account deletion state request'
        });
        yield put(Actions.getAccountDeletionStateFailure({ error }));
    }
}
