import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import ParcelComponent from 'single-spa-react/lib/esm/parcel';
import { mountRootParcel, start } from 'single-spa';

import { history } from './history';
import { App } from 'app-root/app';

start();

export const TopRouting = () => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const remoteImport = async url => await SystemJS.import(url);

    return (
        <Router history={history}>
            <Switch>
                <Route
                    path="/servicing"
                    render={() => (
                        <ParcelComponent
                            config={remoteImport(window.config.servicingRoot)}
                            mountParcel={mountRootParcel}
                            wrapWith="section"
                            className="app-container"
                        />
                    )}
                />

                <Route component={App} />
            </Switch>
        </Router>
    );
};
