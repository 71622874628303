import React from 'react';
import { withTheme } from 'styled-components/macro';

const OpenView = ({ theme, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="20"
        height="14"
        viewBox="0 0 20 14"
        {...props}
    >
        <defs>
            <path
                data-test-id="hamburger_btn"
                id="ok67a"
                d="M35.415 1780h-18.75c-.345 0-.625.373-.625.833 0 .46.28.833.625.833h18.75c.345 0 .625-.373.625-.833 0-.46-.28-.833-.625-.833"
            />
            <path
                id="ok67b"
                d="M16.665 1775.666h18.75c.345 0 .625-.373.625-.833 0-.46-.28-.833-.625-.833h-18.75c-.345 0-.625.373-.625.833 0 .46.28.833.625.833"
            />
            <path
                id="ok67c"
                d="M35.415 1787.666h-18.75c-.345 0-.625-.373-.625-.833 0-.46.28-.833.625-.833h18.75c.345 0 .625.373.625.833 0 .46-.28.833-.625.833"
            />
        </defs>
        <g>
            <g transform="translate(-16 -1774)">
                <g>
                    <use fill="current-color" xlinkHref="#ok67a" />
                </g>
                <g>
                    <use fill="current-color" xlinkHref="#ok67b" />
                </g>
                <g>
                    <use fill="current-color" xlinkHref="#ok67c" />
                </g>
            </g>
        </g>
    </svg>
);

export const Open = withTheme(OpenView);
