// @ts-nocheck
import { TextInput } from 'components/inputs/text-input';
import * as Validators from 'lib/validators';
import { ChangeEvent, ClipboardEvent, ComponentProps } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Field, Normalizer, Validator } from 'redux-form';
import { Box } from 'reflexbox/styled-components';
import { FontSizeProps, SpaceProps, WidthProps } from 'styled-system';

type InputProps = ComponentProps<'input'>;

export type Props = {
    name: string;
    // Living Standard
    /**
     * Hints at the type of data that might be entered by the user while editing the element or its contents
     * @see https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
     */
    inputMode?:
        | 'none'
        | 'text'
        | 'tel'
        | 'url'
        | 'email'
        | 'numeric'
        | 'decimal'
        | 'search';
    normalize?: Normalizer;
    format?: Normalizer;
    parse?: Normalizer;
    type?: string;
    placeholder?: string;
    width?: string;
    isRequired?: boolean;
    isOptional?: boolean;
    disabled?: boolean;
    validate?: Validator[];
    useClassStyle?: boolean;
    preset?: string;
    onChange?: (
        event?: ChangeEvent<any>,
        newValue?: any,
        previousValue?: any
    ) => void;
    onKeyPress?: (e: any) => void;
    onBlur?: (e: any) => void;
    onCopy?: (e: ClipboardEvent<any>) => void;
    onFocus?: (e: any) => void;
    onPaste?: (e: ClipboardEvent<any>) => void;
    autoComplete?: string;
    readOnly?: boolean;
    dataTestId?: string;
    iconAction?: () => void;
    icon?: any;
    errorValues?: any;
    showErrorOnTouched?: boolean;
    autoFocus?: boolean;
    frOptionalLabelGender?: 'masculine' | 'feminine';
    style?: InputProps['style'];
};

type StyledProps = WidthProps & SpaceProps & FontSizeProps;

export type TextFieldProps = StyledProps & Props;

const noOp = () => undefined;

const TextFieldView = ({
    type = 'text',
    placeholder,
    name,
    validate = [noOp],
    normalize,
    parse,
    intl,
    format,
    preset,
    onChange,
    onKeyPress,
    inputMode,
    onBlur,
    onCopy,
    onFocus,
    onPaste,
    isRequired = false,
    isOptional = true,
    disabled = false,
    useClassStyle = true,
    autoComplete,
    readOnly,
    dataTestId,
    iconAction,
    icon,
    errorValues,
    showErrorOnTouched = false,
    autoFocus,
    frOptionalLabelGender = 'feminine',
    ...rest
}: TextFieldProps & WrappedComponentProps) => (
    <Box mt={[3]} width="100%" {...rest}>
        <Field
            placeholder={
                placeholder
                    ? intl.formatMessage({
                          id: placeholder
                      })
                    : ''
            }
            isRequired={isRequired}
            isOptional={!isRequired && isOptional}
            name={name}
            component={TextInput}
            errorValues={errorValues}
            validate={[isRequired ? Validators.isRequired : noOp, ...validate]}
            normalize={normalize}
            format={format}
            parse={parse}
            preset={preset}
            inputMode={inputMode}
            type={type}
            disabled={disabled}
            useClassStyle={useClassStyle}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onCopy={onCopy}
            onBlur={onBlur}
            onFocus={onFocus}
            onPaste={onPaste}
            autoComplete={autoComplete}
            readOnly={readOnly}
            dataTestId={dataTestId}
            iconAction={iconAction}
            icon={icon}
            showErrorOnTouched={showErrorOnTouched}
            autoFocus={autoFocus}
            frOptionalLabelGender={frOptionalLabelGender}
        />
    </Box>
);

export const TextField = injectIntl(TextFieldView);
