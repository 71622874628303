import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
    space,
    width,
    fontSize,
    fontWeight,
    lineHeight,
    SpaceProps,
    FontSizeProps,
    WidthProps,
    LineHeightProps
} from 'styled-system';
import { Flex } from 'reflexbox/styled-components';
import { Typograph, TypographI18nKeys } from 'components/typograph';

type ItemProps = {
    value: string;
    label: TypographI18nKeys;
    onClick: (value: string) => void;
    icon?: React.ReactElement<any>;
    selected?: boolean;
    preset?: 'DEFAULT' | 'SECONDARY';
};

type StyledRadioSwitchItemProps = SpaceProps &
    FontSizeProps &
    WidthProps &
    LineHeightProps;

// eslint-disable-next-line no-unexpected-multiline
const StyledRadioSwitchOption = styled.div<
    ItemProps & StyledRadioSwitchItemProps
>`
    transition: ${props => props.theme.transition.all};
    ${space} 
    ${width}
    ${fontSize}
    ${fontWeight}
    ${lineHeight}
    flex-basis: 100%;
    height: 53px;
    align-self:center;
    display:flex;
    text-align: left;
    border-radius: ${props => props.theme.borderRadius};
    padding-left: 18px;

    cursor: pointer;
    color: ${({ theme, preset }) =>
        preset === 'SECONDARY' ? theme.brand.tertiary : theme.colors.coldGray};

    &:first-child {
        border-left: none;
    }

    &:last-child {
        border-right: none;
    }

    &:hover {
        background-color: ${({ theme, preset }) =>
            preset === 'SECONDARY'
                ? theme.brand.tertiary
                : theme.borderColor.default};
        color: ${({ theme, preset }) =>
            preset === 'SECONDARY'
                ? theme.brand.secondary
                : theme.brand.tertiary};
        & svg {
            fill: ${({ theme, preset }) =>
                preset === 'SECONDARY'
                    ? theme.brand.secondary
                    : theme.brand.tertiary};
        }
    }

    & svg {
        transition: ${props => props.theme.transition.all};
        fill: ${({ theme }) => theme.brand.tertiary};
    }

    & svg,
    & img {
        max-height: 17px;
        margin-right: ${props => props.theme.space[2]}px;
        vertical-align: text-top;
        & + div {
            display: inline;
        }
    }

    ${({ preset, theme }) =>
        preset === 'SECONDARY' &&
        css`
            background-color: ${theme.brand.secondary};
            font-weight: 700;
        `}


    ${({ selected, preset, theme }) =>
        selected &&
        css`
            background-color: ${preset === 'SECONDARY'
                ? theme.brand.tertiary
                : theme.borderColor.default};

            color: ${preset === 'SECONDARY'
                ? theme.brand.secondary
                : theme.brand.tertiary};
            font-weight: 700;

            &:hover {
                background-color: ${preset === 'SECONDARY'
                    ? theme.brand.tertiary
                    : theme.borderColor.default};
            }

            & svg {
                fill: ${preset === 'SECONDARY'
                    ? theme.brand.secondary
                    : theme.brand.tertiary};
            }
        `}

`;

export const RadioSwitchOption = (
    props: ItemProps & StyledRadioSwitchItemProps
) => (
    <StyledRadioSwitchOption
        {...props}
        p={props.p}
        lineHeight={props.lineHeight}
        value={props.value}
        label={props.label}
        selected={props.selected}
        onClick={() => props.onClick(props.value)}
        preset={props.preset}
    >
        <Flex flexDirection="row" alignSelf="center">
            {props.icon && (
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    {props.icon}
                </Flex>
            )}
            <Typograph
                fontSize={[0, 1, 2]}
                fontWeight="bold"
                tx={props.label}
            />
        </Flex>
    </StyledRadioSwitchOption>
);

RadioSwitchOption.defaultProps = {
    selected: false,
    p: 2,
    lineHeight: 0
};
