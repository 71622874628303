import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { Box } from 'reflexbox/styled-components';
import { Typograph } from '@nesto/design-system';
import {
    Button as ButtonBase,
    ButtonProps as ButtonPropsBase
} from '@nesto/design-system/v2';

import { Icon } from '@nesto/design-system/v2';
import { normalizeInputNameForE2E } from 'utils/e2e-utilities';

export type ButtonProps = {
    label?: string;
    values?: {};
    hideArrow?: boolean;
} & ButtonPropsBase;

export const StyledArrow = styled.div`
    transition: all 0.355s cubic-bezier(0.02, 0.01, 0.47, 1);
    opacity: 0;
    width: 0;
    display: flex;
    transform: translateX(-10px);
`;

const arrowStyle = css`
    > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
            position: absolute;
            bottom: 0;
            right: -1em;

            width: 1em;
            height: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    :focus,
    :active,
    :hover {
        > div > div {
            opacity: 1;
            transform: translateX(0px);
        }
    }
    :disabled {
        > div > div {
            display: none;
        }
    }
`;

// @ts-ignore
export const StyledButton = styled<any>(ButtonBase)`
    position: relative;
    > div span {
        ::after {
            ${({ theme }) => theme.fonts.Montserrat700};
            content: attr(title);
            display: block;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }

    /*  Arrow */
    ${({ hideArrow }: any) => !hideArrow && arrowStyle}
`;

const ChildWrapper = styled(Box)`
    display: contents;
`;

const Label = styled(Typograph)`
    flex-grow: 1;
`;

export const Button = ({
    children,
    label,
    values = {},
    isLoading,
    disabled,
    hideArrow = false,
    css,
    rightIconProps,
    ...rest
}: ButtonProps) => {
    const intl = useIntl();
    const id = normalizeInputNameForE2E(
        rest.id || label || 'no-translation-id'
    );

    return (
        <StyledButton
            id={id}
            data-test-id={id}
            isLoading={isLoading}
            disabled={disabled}
            hideArrow={hideArrow}
            css={css}
            rightIconProps={rightIconProps}
            {...rest}
        >
            {!isLoading && label && (
                <Label>
                    <span title={intl.formatMessage({ id: label }, values)}>
                        {intl.formatMessage({ id: label }, values)}
                    </span>
                    {!rest.rightIcon && !disabled && !hideArrow && (
                        <StyledArrow>
                            <Icon
                                name="arrow-button-right"
                                {...rightIconProps}
                            />
                        </StyledArrow>
                    )}
                </Label>
            )}
            {!label && children && (
                <ChildWrapper>
                    {children}
                    {!rest.rightIcon && !disabled && !hideArrow && (
                        <StyledArrow>
                            <Icon name="arrow-button-right" />
                        </StyledArrow>
                    )}
                </ChildWrapper>
            )}
        </StyledButton>
    );
};
