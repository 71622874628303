import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import { Flex } from 'reflexbox/styled-components';
import { Icon } from '@nesto/design-system/v2';

import Modal from 'components/modal';
import { ModalManager } from 'components/modal-manager';
import { useTheme } from 'utils/hooks';
import { Mobile } from 'hocs/mobile';

export type ModalProps = {
    visible?: boolean;
    children?: any;
    onCloseComplete?: () => void;
    //
    onOpenComplete?: () => void;
    topLevel?: boolean;
    level?: string;
    closeOnEsc?: boolean;
    showClose?: boolean;
};

const BottomWrapper = styled(Flex)`
    background: ${({ theme }) => theme.brand.secondary};
    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
    height: 75px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
`;

export const ModalBase = ({
    visible = false,
    showClose = true,
    onCloseComplete,
    children,
    ...rest
}: ModalProps) => {
    const theme = useTheme();
    const [isVisible, setIsVisible] = useState(visible);

    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    return (
        <ModalManager
            isShown={isVisible}
            bg={transparentize(0.2, theme.colors.grey25)}
            onCloseComplete={onCloseComplete}
            {...rest}
        >
            {({ close }: { close: () => void }) => (
                <Modal>
                    {typeof children === 'function'
                        ? children({ close })
                        : children}
                    {showClose && (
                        <Mobile>
                            <BottomWrapper>
                                <Icon
                                    name="close"
                                    size="24px"
                                    onClick={() => {
                                        close();
                                    }}
                                />
                            </BottomWrapper>
                        </Mobile>
                    )}
                </Modal>
            )}
        </ModalManager>
    );
};
