export enum DocumentSubCategory {
    // INCOMES
    incomes = 'INCOMES',
    employments = 'INCOMES_EMPLOYMENTS',
    otherIncomes = 'INCOMES_OTHER_INCOMES',
    pensions = 'INCOMES_PENSIONS',
    selfEmployed = 'INCOMES_SELF_EMPLOYED',
    // IDENTIFICATION
    noPic = 'IDENTIFICATION_NO_PIC',
    withPic = 'IDENTIFICATION_WITH_PIC',
    // FINANCIALS
    assets = 'FINANCIALS_ASSETS',
    liabilities = 'FINANCIALS_LIABILITIES',
    otherFinancials = 'FINANCIALS_OTHER_FINANCIALS',
    // PROPERTIES
    properties = 'PROPERTIES',
    otherProperty = 'PROPERTIES_OTHER_PROPERTY',
    otherProperties = 'PROPERTIES_OTHER_PROPERTIES',
    subjectProperty = 'PROPERTIES_SUBJECT_PROPERTY',
    // FINAL
    finalNestoRequired = 'FINAL_NESTO_REQUIRED',
    finalLenderRequired = 'FINAL_LENDER_REQUIRED',
    finaleOther = 'FINAL_OTHER',
    // Others
    others = 'OTHERS'
}
