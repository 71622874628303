import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Box } from 'reflexbox/styled-components';

import { ActiveStates } from 'reducers/sidebar.selectors';
import { navigationState } from 'components/sidebar/sidebar.component';

export const SubNavigationContainer = styled(Box)`
    border-right: 2px solid ${props => props.theme.colors.mercury};
    border-right-color: ${({ isComplete, theme }) =>
        isComplete ? theme.sidebar.borderColor : null};
    padding: ${props => props.theme.space[2]}px;
    padding-left: ${props => props.theme.space[4]}px;
    padding-right: 0;

    background: linear-gradient(
        90deg,
        #f4f4f4,
        ${({ theme }) => theme.colors.grey00}
    );
`;

const DividerLine = styled.div`
    width: calc(100% - 2px);
    height: 2px;
    background: linear-gradient(to right, #f7f7f7, white);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: ${props => props.theme.zIndex.floor};
`;

const TopDividerLine = styled<any>(DividerLine)`
    top: 0;
`;

const defaultState = (): any => css`
    background: linear-gradient(
        to top,
        ${props => props.theme.colors.mercury} 0%,
        ${props => props.theme.colors.mercury} 50%,
        ${props => props.theme.colors.mercury} 50%,
        ${props => props.theme.colors.mercury} 100%
    );
`;

const mapToActiveStates = (active: ActiveStates): any => {
    switch (active) {
        case navigationState.ACTIVE_STATE:
            return css`
                background: linear-gradient(
                    to top,
                    ${props => props.theme.colors.mercury} 0%,
                    ${props => props.theme.colors.mercury} 50%,
                    ${props => props.theme.brand.accent} 50%,
                    ${props => props.theme.brand.accent} 100%
                );
            `;
        case navigationState.COMPLETED_STATE:
            return css`
                background: linear-gradient(
                    to top,
                    ${props => props.theme.brand.accent} 0%,
                    ${props => props.theme.brand.accent} 50%,
                    ${props => props.theme.brand.accent} 50%,
                    ${props => props.theme.brand.accent} 100%
                );
            `;
        case navigationState.UNREACHED_STATE:
            return defaultState();
        default:
            return undefined;
    }
};

const NavigationLine = styled(({ active, withNavLine, ...rest }) => (
    <div {...rest} active={active} />
))`
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
    z-index: ${props => props.theme.zIndex.floor};
    height: 103%;

    ${defaultState()};
    ${({ active, withNavLine }) => withNavLine && mapToActiveStates(active)}}
`;

type NavBoxProps = {
    withHover: boolean;
    withCursor: boolean;
};
const StyledNavBox = styled(Box)<NavBoxProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: ${({ active, withCursor }) =>
        active === 'ACTIVE' || withCursor ? 'pointer' : 'default'};
    ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}` : '')};

    &:hover {
        background: ${({ theme, withHover }) =>
            withHover ? theme.colors.grey25 : 'inherit'};
    }
`;

export const Navigations = ({
    children,
    active = 'COMPLETED',
    topLine = false,
    withNavLine = true,
    withCursor = false,
    withHover = true,
    ...rest
}) => (
    <StyledNavBox
        py={3}
        pl={4}
        pr={3}
        withHover={withHover}
        withCursor={withCursor}
        {...rest}
    >
        {topLine && <TopDividerLine />}
        {children}
        <NavigationLine active={active} withNavLine={withNavLine} />
        <DividerLine />
    </StyledNavBox>
);
