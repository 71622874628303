import * as React from 'react';
import styled, { keyframes, css, withTheme } from 'styled-components';
import { transparentize } from 'polished';
import Transition from 'react-transition-group/Transition';

import { Portal } from 'components/portal';
import { ESCAPE } from 'utils/keys';

type Props = {
    children: any;
    isShown: boolean;
    onExited: (node: any) => void;
    onExiting: (node: any) => void;
    onEntering: (node: any) => void;
    onEntered: (node: any) => void;
    onEnter: (node: any) => void;
    onExit: () => void;
    topLevel: boolean;
    level?: string;
    theme: any;
    closeOnEsc?: boolean;
};

type State = {
    exiting: boolean;
    exited: boolean;
};

const ANIMATION_DURATION = 240;

const animationEasing = {
    deceleration: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
    acceleration: `cubic-bezier(0.4, 0.0, 1, 1)`
};

const slideOutAnimation = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
`;

const slideInAnimation = keyframes`
  from {
    visibility: visible;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const animationStyles = css`
    transform: translate3d(0, -100%, 0);
    &[data-state='entering'],
    &[data-state='entered'] {
        animation: ${slideInAnimation} ${ANIMATION_DURATION}ms
            ${animationEasing.acceleration} both;
    }
    &[data-state='exiting'] {
        animation: ${slideOutAnimation} ${ANIMATION_DURATION}ms
            ${animationEasing.acceleration} both;
    }
`;

const OverlaySheet = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: ${props => transparentize(0.1, props.theme.colors.white)};
    ${animationStyles};
    z-index: ${props => props.theme.zIndex.heaven + 2};
`;

class ModalOverlayView extends React.PureComponent<Props, State> {
    static defaultProps = {
        onHide: () => {},
        onExit: () => {},
        onExiting: () => {},
        onExited: () => {},
        onEnter: () => {},
        onEntering: () => {},
        onEntered: () => {},
        closeOnEsc: true
    };

    static getDerivedStateFromProps(props: Props) {
        if (!props.isShown) {
            return {
                exited: false
            };
        }
        return null;
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            exiting: false,
            exited: !props.isShown
        };
    }

    componentWillUnmount() {
        const { closeOnEsc } = this.props;

        if (closeOnEsc) {
            document.body.removeEventListener('keydown', this.onEsc, false);
        }
    }

    handleExited = (node: any) => {
        this.setState({ exiting: false, exited: true });
        this.props.onExited(node);
    };

    onEsc = (e: any) => {
        // Esc key
        if (e.keyCode === ESCAPE) {
            this.close();
        }
    };

    handleExiting = (node: any) => {
        const { closeOnEsc } = this.props;

        if (closeOnEsc) {
            document.body.removeEventListener('keydown', this.onEsc, false);
        }

        this.props.onExiting(node);
    };

    handleEntering = (node: any) => {
        const { closeOnEsc } = this.props;

        if (closeOnEsc) {
            document.body.addEventListener('keydown', this.onEsc, false);
        }

        this.props.onEntering(node);
    };

    handleEntered = (node: any) => this.props.onEntered(node);

    close = () => this.setState({ exiting: true });

    render() {
        const {
            isShown,
            children,
            onEnter,
            onExit,
            topLevel,
            level,
            theme
        } = this.props;
        const { exiting, exited } = this.state;

        if (exited) return null;

        return (
            <Portal>
                <Transition
                    appear
                    unmountOnExit
                    timeout={ANIMATION_DURATION}
                    in={isShown && !exiting}
                    onExiting={this.handleExiting}
                    onExited={this.handleExited}
                    onEnter={onEnter}
                    onExit={onExit}
                    onEntering={this.handleEntering}
                    onEntered={this.handleEntered}
                >
                    {state => (
                        <OverlaySheet
                            data-state={state}
                            style={
                                {
                                    zIndex: topLevel
                                        ? theme.zIndex.heaven
                                        : level
                                } as any
                            }
                        >
                            {typeof children === 'function'
                                ? children({
                                      state,
                                      close: this.close
                                  })
                                : children}
                        </OverlaySheet>
                    )}
                </Transition>
            </Portal>
        );
    }
}

export const ModalOverlay = withTheme(ModalOverlayView as any);
