/**
 * Get sub-partner ID (nesto ID) from partner name
 *
 * Each environment has it's own ID since it's coming from the DB
 * ID values are set into configs file for each env
 * @param {string} partner Partner key string value
 * Partner configs
 *
 * Proprio Direct:
 * STAGE: 12156, QA: 33577, PRODUCTION: 223761
 *
 * @returns {number} nesto ID of partner
 */
export const getSubPartnerIdFallback = (partner?: string) =>
    window.config.subPartnerIdFallback?.[partner || '*'] ||
    window.config.subPartnerIdFallback['*'];
