import { Size } from './types';
import { useSizes } from './useSizes';
import { useTheme } from './useTheme';

export const useIsMobile = () => {
    const theme = useTheme();
    const isMobile = ({ width }: Size) =>
        width < Number(theme.breakpoints[0].replace('px', ''));

    const sizes = useSizes({ isMobile });

    return sizes.isMobile || false;
};
