import React from 'react';
import { theme } from 'theme';
import { Colors } from '@nesto/themes';

type Props = {
    color?: Colors;
    size: number;
    dataTestId?: string;
};

const CircleAdd = ({
    color = 'textBlack',
    size = 24,
    dataTestId = 'add-icon'
}: Props) => (
    <svg
        data-test-id={dataTestId}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        viewBox="0 0 24 24"
    >
        <defs>
            <path
                id="cirecle-add-a"
                d="M13 7c0-.552-.444-1-1-1-.552 0-1 .444-1 1v4H7c-.552 0-1 .444-1 1 0 .552.444 1 1 1h4v4c0 .552.444 1 1 1 .552 0 1-.444 1-1v-4h4c.552 0 1-.444 1-1 0-.552-.444-1-1-1h-4V7z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use fill={theme.colors[color]} xlinkHref="#cirecle-add-a" />
            <circle
                cx="12"
                cy="12"
                r="10"
                stroke={theme.colors[color]}
                strokeWidth="2"
            />
        </g>
    </svg>
);

export { CircleAdd };
