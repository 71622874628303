import { select, put } from 'redux-saga/effects';

import { EVENTS } from 'utils/analytics';
import { log } from 'utils/logging';
import { selectUserLanguage, getAccount } from 'reducers/account.selectors';
import { WIZARD_FORM_TYPES, SUMMARY_FORM } from 'constants/appConstants';
import { getCurrentPath, getAllParamsFromRouter } from 'reducers/startup.redux';
import {
    fromAngryCase,
    formatValue,
    getQuestionName,
    mappedFormNameToPrexfix
} from 'utils/analytics/wizard-tracking-helpers';
import { getPartnerName } from 'reducers/ui.selectors';
import { Account } from 'reducers/account.redux';
import { PhoneNumberValidity } from 'utils/analytics/analytics';
import { Action } from 'redux';
import { ApplicationFormName } from 'models/application/Application';
import { analyticEvent } from './analytics-events.sagas';

/**
 * Saga that listens to [MORTGAGE_WIZARDS] ANALYTICS_QUESTIONS_VIEWED action triggered from the wizards
 * onComponentDidMount and onComponentDidUpdate
 */
type WizardRegisteredForm = { FORM_NAME: string; index: number; step: string };
export function* onWizardFormViewed({
    FORM_NAME,
    index,
    step
}: WizardRegisteredForm) {
    try {
        const language = yield select(selectUserLanguage);
        const questionName = getQuestionName(FORM_NAME, step);
        const formType = mappedFormNameToPrexfix(FORM_NAME);

        const type = fromAngryCase(getType(FORM_NAME));

        if (step === 'start' && formType === WIZARD_FORM_TYPES.GETAQUOTE) {
            // GA-4
            yield put(
                analyticEvent('compare_rates', {
                    event_location_category: 'get a quote',
                    info: 'compare rates',
                    location: 'header',
                    language
                })
            );
        }

        // GA-4
        yield put(
            analyticEvent('viewed_question', {
                event_location_category: 'get a quote',
                info: `${index} | ${questionName}`,
                type,
                question_id: step,
                question_name: questionName,
                step: index
            })
        );
    } catch (error) {
        log({ error: `Error in question viewed analytics event: ${error}` });
    }
}

const exceptions = [
    'PREQUAL_SECURITY_QUESTION',
    'PREQUAL_SECURITY_QUESTION2',
    'PREQUAL_SECURITY_QUESTION3',
    'SECURITY_QUESTION',
    'SECURITY_QUESTION2',
    'SECURITY_QUESTION3'
];

/**
 * Saga that listens to [MORTGAGE_WIZARDS] QUESTION_ANSWERED action triggered from the form(s)
 * changePage method and tracks every time a user 'answers' a question.
 * This event is called 'answered question' in segment.
 */
type WizardAnsweredAction = { value: any } & WizardRegisteredForm;
export function* onWizardFormAnswered({
    FORM_NAME,
    step,
    index,
    value
}: WizardAnsweredAction) {
    try {
        const questionName = getQuestionName(FORM_NAME, step);
        const type = fromAngryCase(getType(FORM_NAME));

        // GA-4
        yield put(
            analyticEvent('updated_question', {
                event_location_category: 'get a quote',
                info: `${index} | ${questionName}`,
                type,
                question_id: step,
                question_name: questionName,
                answer: exceptions.includes(questionName)
                    ? 'NONE'
                    : formatValue(value),
                step: index
            })
        );
    } catch (error) {
        log({ error: `Error in question answered analytics event: ${error}` });
    }
}

export const AnalyticTypes = {
    /* @deprecated */
    TRACK_EVENT: 'ANALYTICS_TRACK_EVENT',
    TRACK_PAGE: 'ANALYTICS_TRACK_PAGE',
    START_FLOW: 'ANALYTICS_START_FLOW',
    SAVE_APPLICATIONS_SUCCESS: 'SAVE_APPLICATIONS_SUCCESS',
    TRACK_CREATE_ACCOUNT_SUCCESS: 'TRACK_CREATE_ACCOUNT_SUCCESS',
    TRACK_LOGIN_SUCCESS: 'TRACK_LOGIN_SUCCESS',
    TRACK_VALID_PHONE_NUMBER: 'TRACK_VALID_PHONE_NUMBER'
};

export const saveApplicationSuccess = () => ({
    type: AnalyticTypes.SAVE_APPLICATIONS_SUCCESS
});

export type AnalyticsTrackEvent = {
    event_location_category: string;
    label?: string;
    location?: string;
    type?: string;
    language?: string;
    [key: string]: string | number | boolean | undefined;
};

export const analyticsTrackStartFlowEvent = (payload: AnalyticsTrackEvent) => ({
    type: AnalyticTypes.START_FLOW,
    payload
});

export const analyticsTrackEvent = (
    eventType: string,
    { category, label, location, type, language, ...rest }: AnalyticsTrackEvent
) => ({
    type: AnalyticTypes.TRACK_EVENT,
    eventType,
    payload: {
        category,
        label,
        location,
        type,
        language,
        ...rest
    }
});

const types = {
    NEW: 'new mortgage',
    RENEWAL: 'renewal',
    RENEWAL_MORTGAGE: 'renewal'
};

const getTypeFromCurrentPath = (currentPath: string) => {
    if (currentPath.includes('newMortgage')) {
        return 'new mortgage';
    }
    if (currentPath.includes('renewal')) {
        return 'renewal';
    }
    if (currentPath.includes('refinance')) {
        return 'refinance';
    }
    if (currentPath.includes('signup')) {
        return 'unknown'; // Greg requested this value
    }

    return currentPath;
};

export const getTypeFromFormName = (formName: ApplicationFormName) =>
    ({
        [ApplicationFormName.NEW]: 'new mortgage',
        [ApplicationFormName.RENEWAL]: 'renewal',
        [ApplicationFormName.REFINANCE]: 'refinance'
    }[formName]);

export const getType = (
    type: 'NEW' | 'RENEWAL' | 'RENEWAL_MORTGAGE' | string
) => types[type] || type;

const getFormType = (formType: string) =>
    ({
        [WIZARD_FORM_TYPES.GETAQUOTE]: 'get a quote',
        [SUMMARY_FORM]: 'application'
    }[formType] || formType);

type TrackEventType = {
    type: string;
    eventType: string;
    payload: AnalyticsTrackEvent;
};

export function* trackStartFlowEvent(event: TrackEventType) {
    const { label, type = '' } = event.payload;
    const language = yield select(selectUserLanguage);
    const event_location_category = getFormType(
        event.payload.event_location_category
            ? `${event.payload.event_location_category}`
            : ''
    );
    const coBrandedPartner = yield select(getPartnerName);

    yield put(
        analyticEvent(EVENTS.BEGIN_PROCESS, {
            ...event.payload,
            event_location_category,
            coBrandedPartner,
            type: fromAngryCase(getType(type)),
            label: label ? fromAngryCase(label) : '',
            language
        })
    );
}

// TODO this seem to never be triggered
export function* onSaveApplicationSuccess() {
    try {
        const { sectionName } = yield select(getAllParamsFromRouter);

        // GA-4
        yield put(
            analyticEvent('save', {
                event_location_category: 'application',
                info: sectionName
            })
        );
    } catch (error) {
        log({
            error: `failed to track onSaveApplicationSuccess success: ${error}`
        });
    }
}

type AccountIdentifyType = { userInfo: any };
export const analyticsTrackCreateAccountSuccess = (
    userInfo: AccountIdentifyType
) => ({
    type: AnalyticTypes.TRACK_CREATE_ACCOUNT_SUCCESS,
    ...userInfo
});

export function* trackCreateAccountSuccess({
    userInfo
}: AccountIdentifyType & Action) {
    const currentPath = yield select(getCurrentPath);
    const account: Account = yield select(getAccount);

    try {
        // GA-4
        yield put(
            analyticEvent('create_account', {
                event_location_category: 'get a quote',
                province: account.region as string,
                type: getTypeFromCurrentPath(currentPath),
                location: currentPath,
                info: userInfo.provider || 'email'
            })
        );
    } catch (error) {
        log({
            error: `failed to track trackCreateAccountSuccess success: ${error}`
        });
    }
}

interface ValidPhoneNumberType {
    userInfo: { is_valid: boolean; formType: string };
}
export const analyticsTrackValidPhoneNumber = (
    userInfo: ValidPhoneNumberType
) => ({
    type: AnalyticTypes.TRACK_VALID_PHONE_NUMBER,
    ...userInfo
});

export function* trackValidPhoneNumber({
    userInfo
}: ValidPhoneNumberType & Action) {
    try {
        const label = userInfo.is_valid
            ? PhoneNumberValidity.Valid
            : PhoneNumberValidity.Invalid;

        // GA-4
        yield put(
            analyticEvent('phone_validation', {
                event_location_category: 'account',
                info: label,
                type: getType(userInfo.formType) || 'unknown'
            })
        );
    } catch (error) {
        log({
            error: `failed to track trackValidPhoneNumber: ${error}`
        });
    }
}

export const analyticsTrackLoginSuccess = () => ({
    type: AnalyticTypes.TRACK_LOGIN_SUCCESS
});

export function* trackLoginSuccess() {
    try {
        // GA-4
        yield put(
            analyticEvent('log_in', {
                event_location_category: 'account'
            })
        );
    } catch (error) {
        log({ error: `failed to track trackLoginSuccess success: ${error}` });
    }
}

export const getGAClientId = () => {
    try {
        const gaClient = window?.google_tag_manager;

        if (!gaClient) {
            return '';
        }

        return (
            window?.google_tag_manager?.['GTM-KKVK4SF']?.dataLayer.get(
                'gtagApiResult.client_id'
            ) || ''
        );
    } catch (error) {
        log({ error: `getGAClientId error: ${error}` });
        return '';
    }
};
