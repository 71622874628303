import { DocState, DocumentCategory, DocumentList } from 'models/documents';
import { Colors } from '@nesto/themes';

type ColorStateProp = { textColor: Colors; iconColor: Colors };

type GroupedCategories = {
    [K in Lowercase<DocumentCategory>]: DocumentList;
};

const brokerColorMap: {
    [key: string]: ColorStateProp;
} = {
    [DocState.empty]: {
        iconColor: 'silverChalice',
        textColor: 'silverChalice'
    },
    [DocState.received]: {
        iconColor: 'textBlack',
        textColor: 'textBlack'
    },
    [DocState.brokerApproved]: {
        iconColor: 'gold',
        textColor: 'gold'
    },
    [DocState.brokerUnapproved]: {
        iconColor: 'sunsetOrange',
        textColor: 'sunsetOrange'
    },
    [DocState.lenderAccepted]: {
        iconColor: 'salem',
        textColor: 'salem'
    },
    [DocState.disabled]: {
        iconColor: 'mercury',
        textColor: 'mercury'
    },
    default: {
        iconColor: 'textBlack',
        textColor: 'textBlack'
    }
};

const clientColorMap: {
    [key: string]: ColorStateProp;
} = {
    [DocState.empty]: {
        iconColor: 'textBlack',
        textColor: 'textBlack'
    },
    [DocState.received]: {
        iconColor: 'textBlack',
        textColor: 'silverChalice'
    },
    [DocState.brokerApproved]: {
        iconColor: 'salem',
        textColor: 'silverChalice'
    },
    [DocState.brokerUnapproved]: {
        iconColor: 'sunsetOrange',
        textColor: 'sunsetOrange'
    },
    [DocState.lenderAccepted]: {
        iconColor: 'salem',
        textColor: 'silverChalice'
    },
    [DocState.disabled]: {
        iconColor: 'mercury',
        textColor: 'mercury'
    },
    default: {
        iconColor: 'textBlack',
        textColor: 'textBlack'
    }
};

export function groupDocumentsByCategory(
    categories: DocumentCategory[],
    documents: DocumentList
) {
    let res = {} as GroupedCategories;
    categories.forEach(category => {
        res[category.toLowerCase()] = filterDocumentsByCategory(
            documents,
            category
        );
    });
    return res;
}

export const countDocumentsBySection = (documents: DocumentList) => {
    let approvedDocs = 0;
    let totalDocs = 0;
    let uploadedDocs = 0;
    let rejectedDocs = 0;

    approvedDocs += documents.filter(document =>
        document.state
            ? [DocState.lenderAccepted, DocState.brokerApproved].includes(
                  document.state
              )
            : false
    ).length;

    uploadedDocs += documents.filter(document =>
        document.state
            ? [
                  DocState.lenderAccepted,
                  DocState.brokerApproved,
                  DocState.received
              ].includes(document.state)
            : false
    ).length;

    rejectedDocs += documents.filter(document =>
        document.state
            ? [DocState.brokerUnapproved].includes(document.state)
            : false
    ).length;

    totalDocs += documents.filter(document =>
        document.state ? ![DocState.disabled].includes(document.state) : false
    ).length;

    return {
        approvedDocs,
        uploadedDocs,
        rejectedDocs,
        totalDocs
    };
};

export function filterDocumentsByCategory(
    documents: DocumentList,
    category: DocumentCategory
) {
    return documents.filter(document => document?.category === category);
}

export function mapEntries<T, U>(
    obj: { [key: string]: T },
    fn: (key: string, value: T) => U
) {
    return Object.entries(obj).map(([key, value]) => fn(key, value));
}

export const getStateColor = (
    state: DocState | undefined,
    isBroker = false
): ColorStateProp => {
    if (isBroker) {
        return brokerColorMap[state || 'default'];
    }

    return clientColorMap[state || 'default'];
};
