import { langType } from 'utils/hooks';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reducers';
import { LocaleState } from './locale.redux';

const getLocale = (state: RootState) => state?.locale;

export const getLat = createSelector(
    getLocale,
    (locale: LocaleState) => locale?.lat
);

export const getLongitude = createSelector(
    getLocale,
    (locale: LocaleState) => locale?.lng
);

export const getPosition = createSelector(getLocale, (locale: LocaleState) => ({
    lat: locale?.lat,
    lng: locale?.lng
}));

export const getLang = createSelector(
    getLocale,
    (locale: LocaleState) => locale?.lang as langType
);

export const getRegion = createSelector(
    getLocale,
    (locale: LocaleState) => locale?.region
);

export const getLocalePostalCode = createSelector(
    getLocale,
    (locale: LocaleState) => locale?.postal
);
