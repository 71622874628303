import { useAppDispatch, useAppSelector } from 'redux-store/hooks';
import {
    getCurrentApplicantInfo,
    getCurrentApplicationApplicantId,
    isApplicationSubmitted as getIsApplicationSubmitted
} from 'reducers/application.selectors';
import {
    getDocumentPreviewUrl,
    getDocuments,
    getRouteDocumentStep,
    getDocumentTypes,
    hasLoaded,
    getActiveApplicantId,
    getIsModalShown,
    getSelectedDocumentIndex
} from 'reducers/documents.selectors';
import { getActiveApplicationId } from 'reducers/session.selectors';
import { getHasClosingDocumentsInfos } from 'reducers/closing-documents.selector';
import {
    getPropertyIsFound,
    getHasOneApplicantBankrupt,
    getHasOneApplicantWithLowCreditScore,
    memorizedGetAllApplicationApplicants
} from 'reducers/sidebar.selectors';

import { Actions } from 'reducers/documents.redux';
import { Actions as ApplicationStateActions } from 'reducers/applications-state.redux';
import {
    AccountTrackingPayload,
    Actions as AccountActions
} from 'reducers/account.redux';
import { DocumentStep } from 'models/documents';
import { useCallback } from 'react';
import { useMemo } from 'react';
import {
    countDocumentsBySection,
    groupDocumentsByCategory
} from './documents.utils';
import { DOC_CATEGORIES } from './documents-constants';
import { findDocIndexByCompositeKey } from 'utils/documents-helpers';
import { Document as DocumentType } from 'models/documents';

export const useDocuments = () => {
    const dispatch = useAppDispatch();

    const docsHasLoaded = useAppSelector((state: any) => hasLoaded(state));

    const currentApplicationApplicantId = useAppSelector(state =>
        getCurrentApplicationApplicantId(state)
    );
    const activeApplicationId = useAppSelector(state =>
        getActiveApplicationId(state)
    );

    const hasClosingDocuments = useAppSelector(state =>
        getHasClosingDocumentsInfos(state)
    );

    const documentPreviewUrl = useAppSelector<string | undefined>(
        (state: any) => getDocumentPreviewUrl(state)
    );

    const documentStep = useAppSelector((state: any) =>
        getRouteDocumentStep(state)
    );
    const documents = useAppSelector((state: any) => getDocuments(state));

    const isApplicationSubmitted = useAppSelector(state =>
        getIsApplicationSubmitted(state)
    );
    const applicantId = useAppSelector(state =>
        getCurrentApplicationApplicantId(state)
    );
    const applicationId = useAppSelector(state =>
        getActiveApplicationId(state)
    );
    const applicantInfo = useAppSelector(state =>
        getCurrentApplicantInfo(state)
    );

    const activeApplicant = useAppSelector((state: any) =>
        getActiveApplicantId(state)
    );

    const isPropertyFound = useAppSelector(state => getPropertyIsFound(state));
    const hasOneBankruptApplicant = useAppSelector(state =>
        getHasOneApplicantBankrupt(state)
    );
    const hasOneLowCreditScoreApplicant = useAppSelector(state =>
        getHasOneApplicantWithLowCreditScore(state)
    );

    const documentTypes = useAppSelector((state: any) =>
        getDocumentTypes(state)
    );

    const applicants = useAppSelector((state: any) =>
        memorizedGetAllApplicationApplicants(state)
    );

    const isModalShown = useAppSelector((state: any) => getIsModalShown(state));

    const selectedDocumentIndex = useAppSelector((state: any) =>
        getSelectedDocumentIndex(state)
    );

    const groupedDocuments = useMemo(() => {
        if (applicants) {
            return applicants.map(applicant => {
                const { applicantId } = applicant;

                const allDocs = documents.filter(
                    docs => docs.applicantId === applicantId
                );
                const criticalDocuments = allDocs.filter(
                    docs => docs.step === DocumentStep.Critical
                );
                const additionalDocuments = allDocs.filter(
                    docs => docs.step === DocumentStep.Additional
                );

                const groupedCriticalDocuments = groupDocumentsByCategory(
                    [...Object.values(DOC_CATEGORIES).map(value => value.type)],
                    criticalDocuments
                );
                const groupedAdditionalDocuments = groupDocumentsByCategory(
                    [...Object.values(DOC_CATEGORIES).map(value => value.type)],
                    additionalDocuments
                );

                return {
                    allDocs,
                    criticalDocuments: {
                        all: criticalDocuments,
                        ...groupedCriticalDocuments
                    },
                    additionalDocuments: {
                        all: additionalDocuments,
                        ...groupedAdditionalDocuments
                    },
                    applicantId
                };
            });
        }
    }, [applicants, documents]);

    const filteredDocuments = useMemo(() => {
        const [defaultApplicant] = applicants.map(
            applicant => applicant.applicantId
        );

        if (groupedDocuments) {
            return groupedDocuments?.filter(
                docs =>
                    docs.applicantId === (activeApplicant || defaultApplicant)
            )[0];
        }
    }, [activeApplicant, applicants, groupedDocuments]);

    const hasCompletedCriticalDocs = useMemo(() => {
        if (filteredDocuments) {
            const {
                uploadedDocs,
                totalDocs,
                rejectedDocs
            } = countDocumentsBySection(
                filteredDocuments.criticalDocuments.all
            );
            return (
                uploadedDocs === totalDocs ||
                rejectedDocs + uploadedDocs === totalDocs
            );
        }
    }, [filteredDocuments]);

    const selectedDocumentsGrouped = useMemo(() => {
        if (groupedDocuments) {
            return (
                groupedDocuments.find(
                    group => group.applicantId === activeApplicant
                ) || groupedDocuments[0]
            );
        }
    }, [groupedDocuments, activeApplicant]);

    // Actions
    const loadDocuments = useCallback(
        (step: DocumentStep) => dispatch(Actions.documentsRequest(step)),
        [dispatch]
    );
    const loadAllDocuments = useCallback(() => {
        const applicantIds = applicants.map(applicant => applicant.applicantId);
        if (!!applicantIds.length) {
            dispatch(Actions.allDocumentsRequest(applicantIds));
        }
    }, [applicants, dispatch]);

    const loadDocumentTypes = useCallback(
        () => dispatch(Actions.getDocumentTypesRequest()),
        [dispatch]
    );

    const accountTracking = useCallback(
        ({ key, value }: AccountTrackingPayload) =>
            dispatch(AccountActions.accountTracking({ key, value })),
        [dispatch]
    );

    const setAdvisorBooked = useCallback(
        applicationId =>
            dispatch(ApplicationStateActions.setAdvisorBooked(applicationId)),
        [dispatch]
    );

    const updateDocumentState = useCallback(
        (documentCompositeKey: any, updatedDocument: any) =>
            dispatch(
                Actions.updateDocumentSuccess(
                    documentCompositeKey,
                    updatedDocument
                )
            ),
        [dispatch]
    );

    const setActiveApplicant = useCallback(
        (applicationId: number) =>
            dispatch(Actions.setActiveApplicant(applicationId)),
        [dispatch]
    );

    const showModal = useCallback(
        (isModalShown: boolean) => dispatch(Actions.showModal(isModalShown)),
        [dispatch]
    );

    const setSelectedDocumentIndex = useCallback(
        (documentIndex: number) =>
            dispatch(Actions.setSelectedDocumentIndex(documentIndex)),
        [dispatch]
    );

    // Handlers
    const handleSelectDocument = useCallback(
        (document: DocumentType) => {
            // Open modal with document
            const selectedIndex = findDocIndexByCompositeKey(
                document,
                documents
            );

            setSelectedDocumentIndex(selectedIndex);
            showModal(true);
        },
        [documents, setSelectedDocumentIndex, showModal]
    );

    return {
        applicants,
        docsHasLoaded,
        currentApplicationApplicantId,
        activeApplicationId,
        hasClosingDocuments,
        documents,
        isApplicationSubmitted,
        applicantId,
        documentPreviewUrl,
        applicationId,
        applicantInfo,
        documentStep,
        isPropertyFound,
        hasOneBankruptApplicant,
        hasOneLowCreditScoreApplicant,
        documentTypes,
        activeApplicant,
        groupedDocuments,
        filteredDocuments,
        selectedDocumentsGrouped,
        isModalShown,
        selectedDocumentIndex,
        hasCompletedCriticalDocs,

        // Handlers
        handleSelectDocument,

        // Actions
        setSelectedDocumentIndex,
        showModal,
        loadDocuments,
        loadDocumentTypes,
        accountTracking,
        setAdvisorBooked,
        updateDocumentState,
        loadAllDocuments,
        setActiveApplicant
    };
};
