import { Currency } from 'product-selection/components/card/currency.component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Field, WrappedFieldProps } from 'redux-form';
import { Box } from 'reflexbox/styled-components';
import { FontSizeProps, SpaceProps, WidthProps } from 'styled-system';
import { parseCurrencyValue, showRange } from '../utils';

type RangeProps = {
    placeholder: number;
    disabled?: boolean;
    range?: number;
};

const CurrencyRangeProps = ({
    input,
    placeholder,
    disabled = false,
    range
}: RangeProps & WrappedFieldProps) => {
    const parsedValue = parseCurrencyValue(input.value, placeholder);
    const displayRange = showRange(parsedValue, range);
    return (
        <Currency
            color={disabled ? '#f9423a' : '#3D66B0'}
            fontSize="16px"
            amount={parsedValue}
            range={displayRange}
        />
    );
};

export type Props = {
    name: string;
    placeholder: number;
    disabled?: boolean;
    dataTestId?: string;
    range?: number;
};

type StyledProps = WidthProps & SpaceProps & FontSizeProps;

type CurrencyFieldProps = StyledProps & Props;

const TextFieldView = ({
    placeholder,
    name,
    intl,
    disabled = false,
    dataTestId,
    range,
    ...rest
}: CurrencyFieldProps & WrappedComponentProps) => (
    <Box mt={3} width="100%" {...rest}>
        <Field
            name={name}
            component={CurrencyRangeProps}
            props={{ range, placeholder }}
            disabled={disabled}
            dataTestId={dataTestId}
        />
    </Box>
);

export const CurrencyFieldRange = injectIntl(TextFieldView);
