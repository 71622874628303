import { getUserKey } from 'components/feature-flagger/getUserKey';

export const ldKey = getUserKey();

export const convertFeatureFlagProgressToObject = (input: string) =>
    input
        .trim()
        .split('|')
        .map(value => {
            const tuple = value.split(':');
            return { [tuple[0].trim()]: tuple[1].trim() };
        })
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const convertFeatureFlagProgressToString = (input: {
    [flag: string]: string;
}) =>
    Object.keys(input)
        .map(flag => `${flag} : ${input[flag]}`)
        .join(' | ');
