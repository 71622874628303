import { propertyTypes } from 'types/properties';

export enum Timings {
    SIGNED_OFFER = 'SIGNED_OFFER',
    FOUND_PROPERTY = 'FOUND_PROPERTY',
    ACTIVELY_LOOKING = 'ACTIVELY_LOOKING',
    NOT_LOOKING = 'NOT_LOOKING',
    // NEW Feature flagged values
    MADE_OFFER = 'MADE_OFFER',
    MAKING_OFFER_SOON = 'MAKING_OFFER_SOON',
    GET_PREAPPROVED = 'GET_PREAPPROVED',
    JUST_CURIOUS = 'JUST_CURIOUS'
}

export type Frequency =
    | 'ANNUALLY'
    | 'SEMIANNUALLY'
    | 'MONTHLY'
    | 'SEMIMONTHLY'
    | 'BIWEEKLY'
    | 'BIWEEKLYACCELERATED'
    | 'WEEKLY'
    | 'WEEKLYACCELERATED';

export type PropertyPurpose =
    | 'OWNER_OCCUPIED'
    | 'OWNER_OCCUPIED_AND_RENTAL'
    | 'CONVERT_DUPLEX_TO_HOME'
    | 'RENTAL'
    | 'SELLING';

export type RecurringPayment = {
    amount: number;
    frequency: Frequency;
};

export type Address = {
    unit: string;
    streetNumber: string;
    street: string;
    city: string;
    state: string;
    stateCode: string;
    country: string;
    countryCode: string;
    postalCode: string;
};

export type Property = {
    id: string;
    address?: Address;
    propertyValue?: number;
    type?:
        | propertyTypes.HOUSE
        | propertyTypes.CONDO
        | propertyTypes.SECONDARY_HOME_OR_COTTAGE
        | propertyTypes.DUPLEX
        | propertyTypes.TRIPLEX
        | propertyTypes.FOURPLEX;
    condoFees?: RecurringPayment;
    heatingIncluded?: boolean;
    rentalIncome?: RecurringPayment;
    mortgagePaymentAmount?: RecurringPayment;
    hasMortgage: boolean;
    purpose?: PropertyPurpose;
};

export enum Region {
    ON = 'ON',
    QC = 'QC',
    AB = 'AB',
    BC = 'BC',
    MB = 'MB',
    NB = 'NB',
    NS = 'NS',
    NL = 'NL',
    PE = 'PE',
    SK = 'SK'
}

export enum MortgageTypes {
    NEW = 'newMortgage',
    RENEWAL = 'renewal',
    REFINANCE = 'refinance'
}
