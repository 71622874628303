import { AccountTypes, Actions as AccountActions } from './account.redux';

type Token = {
    accessToken: string;
    tokenType: string;
    expires: string;
    refreshToken: string;
};

type AuthState = {
    error: any;
    token: Token | null;
    requestingToken: boolean;
};

const INITIAL_STATE: AuthState = {
    token: null,
    error: null,
    requestingToken: false
};

const tokenSuccess = (
    state: AuthState,
    { token }: { token: Token }
): AuthState => {
    return {
        ...state,
        token,
        error: null,
        requestingToken: false
    };
};

// Refresh token
const refreshTokenRequest = (state: AuthState) => ({
    ...state,
    requestingToken: true
});

const refreshTokenError = (state: AuthState, { error }: { error: any }) => ({
    ...state,
    error
});

const logout = () => ({
    ...INITIAL_STATE
});

const auth = (
    state = INITIAL_STATE,
    action: typeof AccountActions
): AuthState => {
    switch (action.type) {
        // XXX_SUCCESS
        case AccountTypes.LOGIN_SUCCESS:
        case AccountTypes.CREATE_ACCOUNT_SUCCESS:
        case AccountTypes.BROKER_LOGIN_SUCCESS:
        case AccountTypes.BEHALF_LOGIN_SUCCESS:
        case AccountTypes.BEHALF_LOGOUT_SUCCESS:
            return tokenSuccess(state, action);
        // Refresh Token
        case AccountTypes.REFRESH_TOKEN_REQUEST:
            return refreshTokenRequest(state);
        case AccountTypes.REFRESH_TOKEN_SUCCESS:
            return tokenSuccess(state, action);
        case AccountTypes.REFRESH_TOKEN_ERROR:
            return refreshTokenError(state, action);
        // XXX_LOGOUT
        case AccountTypes.LOGOUT:
            return logout();
        default:
            return state;
    }
};

export const reducer = auth;
