import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import { IconComponent as Icon } from 'components/icon';
import { MenuItem } from 'components/menu-item';
import { ModalManager } from 'components/modal-manager';
import { WEBSITE_LINKS } from 'constants/appConstants';
import HamburgerPng from 'assets/media/icons/hamburger_closed@2x.png';
import ClosePng from 'assets/media/icons/close_001@2x.png';
import RoundArrowRight from 'assets/media/icons/bouton_rond_droit@2x.png';
import { useTheme } from 'utils/hooks';
import { Routes } from 'app-root/routing/routes';
import { LanguageSwitcher } from 'components/navigation/language-switcher.component';

type Props = {
    onOpenCloseMenu: () => void;
    isOpen: boolean;
    hideLogin?: boolean;
};

const HamburgerButton = styled.button`
    cursor: pointer;
    border: none;
    display: block;
    padding: 0 !important;
    background-color: transparent;
`;

const MenuBackground = styled.div`
    display: block;
    position: fixed;
    width: 100vw;
    padding-left: 5%;
    padding-right: 5%;
    height: 100vh;
    left: 0;
    padding-top: 80px;
    background-color: ${props => props.theme.colors.white};
    z-index: 99;
    @media (min-width: 576px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const HamburgerMenu = ({
    onOpenCloseMenu,
    isOpen,
    hideLogin = false
}: Props) => {
    const intl = useIntl();
    const theme = useTheme();
    const history = useHistory();
    const { pathname } = useLocation();

    const currentPathLogin = pathname.indexOf('login') > -1;
    const allMenuItems: any = [
        ...(!hideLogin
            ? [
                  {
                      text: {
                          tx: 'nav.login'
                      },
                      clickAction: () => {
                          if (currentPathLogin) {
                              onOpenCloseMenu();
                          } else {
                              history.push(Routes.login);
                          }
                      },
                      link: undefined,
                      key: 'connect',
                      newPage: false,
                      iconRight: RoundArrowRight
                  }
              ]
            : []),
        {
            text: {
                tx: 'userMenu.howWorks',
                defaultTx: 'how it works'
            },
            link: WEBSITE_LINKS[intl.locale].howItWorks,
            key: 'works',
            newPage: false
        },
        {
            text: {
                tx: 'userMenu.career',
                defaultTx: 'career'
            },
            link: WEBSITE_LINKS[intl.locale].career,
            key: 'career',
            newPage: true
        },
        {
            text: {
                tx: 'getAQuote.termsOfService',
                defaultTx: 'terms of service'
            },
            link: WEBSITE_LINKS[intl.locale].termsOfService,
            key: 'terms',
            newPage: false
        },
        {
            text: {
                tx: 'userMenu.privacy',
                defaultTx: 'privacy policy'
            },
            link: WEBSITE_LINKS[intl.locale].privacyPolicy,
            key: 'privacy',
            newPage: false
        }
    ];

    return (
        <>
            <HamburgerButton onClick={onOpenCloseMenu}>
                {isOpen ? (
                    <Icon
                        data-test-id="hamburger_btn"
                        maxWidth="30px"
                        src={ClosePng}
                    />
                ) : (
                    <Icon
                        data-test-id="close_btn"
                        maxWidth="25px"
                        src={HamburgerPng}
                    />
                )}
            </HamburgerButton>

            <ModalManager isShown={isOpen} level={`${theme.zIndex.house}`}>
                <MenuBackground>
                    {allMenuItems.map((each, i) => (
                        <MenuItem
                            text={each.text}
                            link={each.link}
                            clickAction={each.clickAction}
                            index={i}
                            key={each.key}
                            newPage={each.newPage}
                            iconRight={each.iconRight}
                        />
                    ))}
                    <MenuItem>
                        <LanguageSwitcher fontSize={[1, 2]} />
                    </MenuItem>
                </MenuBackground>
            </ModalManager>
        </>
    );
};
