import React, { useState, useEffect } from 'react';
import { Flex } from 'reflexbox/styled-components';
import { Emoji } from '@nesto/design-system/v2';

import { ModalCard } from 'components/modal-app';
import { Typograph } from 'components/typograph';

export const ConnectivityListener = () => {
    const [isOnline, setIsOnline] = useState(
        window ? window.navigator.onLine : false
    );

    const handleConnectionChange = () => {
        setIsOnline(window ? window.navigator.onLine : false);
    };

    useEffect(() => {
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);

        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        };
    }, []);

    return (
        <ModalCard visible={!isOnline} closeOnEsc={false} showClose={false}>
            <Flex p={3} alignItems="center" flexDirection="column">
                <Emoji
                    label="warning"
                    symbol="🚨"
                    fontSize={6}
                    fontWeight="bold"
                    mb={3}
                />
                <Typograph
                    textAlign="center"
                    fontSize={[3, 4]}
                    tx="connectivity.offline"
                    setInnerHtml
                />
            </Flex>
        </ModalCard>
    );
};
