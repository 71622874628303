import { useContext } from 'react';

import { ToastContext } from './toast-provider';

export const useToasts = () => {
    const ctx = useContext(ToastContext);

    if (!ctx) {
        throw Error(
            'The `useToasts` hook must be called from a descendent of the `ToastProvider`.'
        );
    }
    // use addToast to create a new toast message
    return {
        addToast: ctx.add,
        removeToast: ctx.remove,
        removeAllToasts: ctx.removeAll,
        updateToast: ctx.update,
        toastStack: ctx.toasts
    };
};
