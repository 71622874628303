import React from 'react';

const getHeight = (size: number): number => {
    const originalWidth = 14;
    const originalHeight = 10;

    return (originalHeight * size) / originalWidth;
};

export const TickLight = ({
    size = 14,
    fillColor = 'currentColor',
    dataTestId = 'tick'
}) => (
    <svg
        data-test-id={dataTestId}
        width={size}
        height={getHeight(size)}
        viewBox="0 0 14 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M6.60096117,10.5570494 L13.4351612,3.32160744 C13.8098271,2.91584404 14.44249,2.89063451 14.8482534,3.26530038 C15.2540168,3.63996625 15.2792263,4.27262917 14.9045605,4.67839256 L7.36439779,12.6783926 C6.97913693,13.0956304 6.32415914,13.1086745 5.9225914,12.7071068 L2.29289322,9.0774086 C1.90236893,8.68688431 1.90236893,8.05371933 2.29289322,7.66319504 C2.68341751,7.27267075 3.31658249,7.27267075 3.70710678,7.66319504 L6.60096117,10.5570494 Z"
                id="path-1"
            ></path>
        </defs>
        <g
            id="--Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Icon/16x16/Check"
                transform="translate(-2.000000, -3.000000)"
            >
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use
                    id="check"
                    fill={fillColor}
                    fillRule="nonzero"
                    xlinkHref="#path-1"
                ></use>
            </g>
        </g>
    </svg>
);
