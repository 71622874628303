import { GaqApplicationType } from './../components/get-a-quote/pretto/pretto.constants';
import qs from 'qs';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { Partner } from '@nesto/themes';
import {
    getCurrentApplicantInfo,
    getApplicationType,
    isApplicationSubmitted as getIsApplicationSubmitted,
    getTargetProperty
} from 'reducers/application.selectors';
import { getAccount, getApplicantPartnerAgreement } from './account.selectors';

import {
    APPLICATION_PROGRESS_BAR_INFO,
    APPLICATION_PROGRESS_BAR_STEPS,
    APPLICATION_PROGRESS_BAR_TARGET_PROPERTY,
    APPLICATION_PROGRESS_BAR_SELECT_RATE,
    APPLICATION_PROGRESS_BAR_SUBMIT
} from 'constants/uiConstants';
import {
    ApplicationProgressBarProps,
    CallPreferenceRequestState,
    CTAModalType,
    DrawerType,
    LeadReferralTypes
} from 'reducers/ui.redux';
import { memorizedGetApplicationNavigationStates } from 'reducers/sidebar.selectors';
import { omit } from 'ramda';
import { PARTNER_NAMES } from 'constants/appConstants';
import { getCookie } from 'utils/cookie';
import { TargetProperty } from 'models/application/Application';
import { Region } from 'types/application/application';
import { getLang } from './locale.selector';
import { langType } from 'utils/hooks';
import { Language } from 'types/language';
import {
    PrettoFormKey,
    PrettoMicroCopy,
    PrettoFormValues
} from 'components/get-a-quote/pretto/pretto.types';

type ReduxState = any;

export const getUIState = (state: ReduxState) => state.ui;

export const getSidebarState = (state: ReduxState): boolean => {
    const { ui } = state;
    return ui && ui.showSidebar;
};

export const getFiltersState = (state: ReduxState): boolean => {
    const { ui } = state;
    return ui && ui.showFilters;
};

export const getDrawerState = (state: ReduxState): boolean => {
    const { ui } = state;
    return ui && ui.showDrawer;
};

export const getBookACAllMessageState = (state: ReduxState): boolean => {
    const { ui } = state;
    return ui && ui.showBookACallMessage;
};

export const getDrawerType = (state: ReduxState): DrawerType => {
    const { ui } = state;

    return ui && ui.drawerType;
};

export const getUniPostalCodeState = (state: ReduxState): boolean => {
    const { ui } = state;
    return ui && ui.showUniPostalCode;
};

export const hasPostalCode = (state: ReduxState): boolean => {
    const account = getAccount(state);

    return !!account?.postalCode;
};

const checkUniLocationValidity = (state: ReduxState): boolean => {
    const locale = state?.locale;

    // if it is from ON we always show the popup
    return locale?.region === 'ON' || false;
};

export const showUniPostalCode = (state: ReduxState): boolean => {
    const showUniPostalCode = sessionStorage.getItem('showUniPostalCode');

    const validUniLocation = checkUniLocationValidity(state);
    const hasPostalCodeValid = hasPostalCode(state);

    if (
        validUniLocation &&
        !hasPostalCodeValid &&
        showUniPostalCode !== 'hide'
    ) {
        return true;
    }

    return false;
};

export const getPartnerFromQuery = (state: ReduxState): any => {
    const { search } = state.router.location;
    const { partner } = qs.parse(state.router.location.search, {
        ignoreQueryPrefix: true
    });

    return search && partner ? partner : null;
};

export const getPartnerFromCookie = (): string | null => {
    const value = getCookie('nesto_partner');

    return value || null;
};

export const getPartnerFromQsOrCookie = (state: ReduxState): string | null => {
    return getPartnerFromQuery(state) || getPartnerFromCookie();
};

export const getPartner = (state: ReduxState): Partner => {
    const { ui } = state;
    const partner = ui && ui.partner;

    const partnersAvailable = Object.values(PARTNER_NAMES).map(
        partner_values => partner_values.partner
    );

    return partnersAvailable.includes(partner) ? partner : 'nesto';
};

export const getPartnerName = (state: ReduxState): string => {
    const partner = getPartner(state);

    return PARTNER_NAMES[partner].partner || 'nesto';
};

export const getSubPartnerIdFromQuery = (state: ReduxState): any => {
    const { search } = state.router.location;
    const { subPartnerId } = qs.parse(state.router.location.search, {
        ignoreQueryPrefix: true
    });

    return search && subPartnerId ? subPartnerId : null;
};

export const getSubPartnerId = (state: ReduxState): number => {
    const { ui } = state;
    const subPartnerId = ui && ui.subPartnerId;

    return subPartnerId || 0;
};

export const getLeadReferral = (state: ReduxState): LeadReferralTypes => {
    const { ui } = state;

    return ui.leadReferral;
};

export const isLeadReferralNeeded = (state: ReduxState): boolean => {
    const { ui } = state;

    return ui.leadReferral.eligibility || false;
};

export const getIsLeadReferralNeeded = createCachedSelector(
    isLeadReferralNeeded,
    isReferralNeeded => isReferralNeeded
)(state => `getIsLeadReferralNeeded${state?.account?.account?.id}`);

const isConsentNeededForPartner = (
    state: ReduxState,
    partner: string
): boolean => {
    const currentPartner = getPartner(state);
    const partnerAgreement = getApplicantPartnerAgreement(state);

    return currentPartner === partner && !partnerAgreement;
};

const getIsConsentProprioDirectNeeded = createCachedSelector(
    (state: ReduxState) =>
        isConsentNeededForPartner(state, PARTNER_NAMES.proprioDirect.partner),
    isConsentNeeded => isConsentNeeded
)(state => `getIsConsentProprioDirectNeeded${state?.account?.account?.id}`);

const getIsConsentFeriqueNeeded = createCachedSelector(
    (state: ReduxState) =>
        isConsentNeededForPartner(state, PARTNER_NAMES.ferique.partner),
    isConsentNeeded => isConsentNeeded
)(state => `getIsConsentFeriqueNeeded${state?.account?.account?.id}`);

const getIsConsentFHGNeeded = createCachedSelector(
    (state: ReduxState) =>
        isConsentNeededForPartner(state, PARTNER_NAMES.fhg.partner),
    isConsentNeeded => isConsentNeeded
)(state => `getIsConsentFHGNeeded${state?.account?.account?.id}`);

const getIsConsentRealtorNeeded = createCachedSelector(
    (state: ReduxState) =>
        isConsentNeededForPartner(state, PARTNER_NAMES.realtor.partner),
    isConsentNeeded => isConsentNeeded
)(state => `getIsConsentRealtorNeeded${state?.account?.account?.id}`);

export const getIsConsentNeeded = (state: ReduxState) =>
    getIsConsentProprioDirectNeeded(state) ||
    getIsConsentFeriqueNeeded(state) ||
    getIsConsentFHGNeeded(state) ||
    getIsConsentRealtorNeeded(state);

export const getApplicationProgressInfo = (
    state: ReduxState
): ApplicationProgressBarProps | undefined => {
    const applicantInfo = getCurrentApplicantInfo(state);
    const { pathname } = state.router.location;
    const [section, step] = pathname.substring(1).split('/');
    const navigationStepStates = memorizedGetApplicationNavigationStates(state);
    const isRefinance = getApplicationType(state) === 'REFINANCE';
    const isApplicationSubmitted = getIsApplicationSubmitted(state);

    let totalTime =
        APPLICATION_PROGRESS_BAR_TARGET_PROPERTY +
        APPLICATION_PROGRESS_BAR_SUBMIT +
        APPLICATION_PROGRESS_BAR_SELECT_RATE;
    let completedTime = 0;

    if (navigationStepStates) {
        (Object.keys(navigationStepStates) || []).forEach(key => {
            const omitSteps = isRefinance ? ['id', 'downpayment'] : ['id'];
            const navigationSteps = omit(omitSteps, navigationStepStates[key]);

            Object.keys(navigationSteps).forEach(step => {
                if (APPLICATION_PROGRESS_BAR_STEPS[step]) {
                    totalTime += APPLICATION_PROGRESS_BAR_STEPS[step];
                    if (navigationSteps[step]) {
                        completedTime += APPLICATION_PROGRESS_BAR_STEPS[step];
                    }
                }
            });
        });
    }

    if (navigationStepStates.subjectProperty) {
        completedTime += APPLICATION_PROGRESS_BAR_TARGET_PROPERTY;
    }

    if (step === 'submit' && !isApplicationSubmitted) {
        completedTime += APPLICATION_PROGRESS_BAR_SELECT_RATE;
    }

    if (isApplicationSubmitted) {
        completedTime = totalTime;
    }

    if (section === 'application' && applicantInfo) {
        if (APPLICATION_PROGRESS_BAR_INFO[step]) {
            return {
                numOfSteps: totalTime,
                currentStepIndex: completedTime,
                section:
                    step === 'renewing-property' && isRefinance
                        ? 'sidebar.refinanceProperty'
                        : APPLICATION_PROGRESS_BAR_INFO[step].section,
                userName: `${applicantInfo?.firstName} ${applicantInfo?.lastName}`
            };
        }
    }

    return undefined;
};

export const selectIsCTAModalShown = createSelector(
    getUIState,
    (UIState: any) => UIState.showCTAModal
);

export const getCTAModalType = createSelector(getUIState, (UIState: any) => {
    return UIState.CTAModalType as CTAModalType;
});

export const getIsNoBookingFlow = createSelector(
    getTargetProperty,
    getLang,
    (targetProperty: TargetProperty | undefined, lang: langType) => {
        const region = targetProperty?.address.stateCode as Region;
        const QC_EN = lang === Language.English && region === Region.QC;

        return QC_EN || region === Region.AB || region === Region.BC;
    }
);

export const getIsHighVolumeRegion = createSelector(
    getTargetProperty,
    getLang,
    (targetProperty: TargetProperty | undefined, lang: langType) => {
        const region = targetProperty?.address.stateCode as Region;
        const isQcFr = lang === Language.French && region === Region.QC;
        const isOnEn = lang === Language.English && region === Region.ON;

        return isQcFr || isOnEn;
    }
);

export const getIsNavbarVisible = (state: ReduxState): boolean =>
    state.ui.isNavbarVisible;

export const getIsQcEn = createSelector(
    getTargetProperty,
    getLang,
    (targetProperty: TargetProperty | undefined, lang: Language) => {
        const region = targetProperty?.address.stateCode as Region;
        return lang === Language.English && region === Region.QC;
    }
);

export const selectPrettoStep = createSelector(
    (state: ReduxState) => state.ui.prettoSteps,
    (prettoSteps: PrettoFormKey[]) => prettoSteps
);

export const selectPrettoMicroCopy = (state: ReduxState): PrettoMicroCopy =>
    state.ui.prettoMicroCopy;

export const selectApplicationType = createSelector(
    (state: ReduxState) => state.ui.prettoApplicationType,
    (prettoApplicationType: GaqApplicationType) => prettoApplicationType
);
export const selectCanSubmit = (state: ReduxState) => state.ui.canSubmit;

export const getGaqInitialValues = (state: ReduxState) =>
    state?.ui?.gaqInitialValues;

export const selectPrettoForm = (
    state: ReduxState
): PrettoFormValues | undefined => {
    if (state?.ui?.form && state?.ui?.prettoApplicationType) {
        const { form, prettoApplicationType } = state.ui;
        return form[prettoApplicationType]?.values ?? undefined;
    }
};
export const getCallPreferenceRequestState = (
    state: ReduxState
): CallPreferenceRequestState => {
    const { ui } = state;

    return ui.callPreferenceRequestState;
};
