import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Box } from 'reflexbox/styled-components';
import { SpaceProps, FlexboxProps } from 'styled-system';

import {
    SubmitButton,
    Typograph,
    Props as TypographProps
} from '@nesto/design-system';

import { WEBSITE_LINKS } from 'constants/appConstants';
import { Routes } from 'app-root/routing/routes';
import { LanguageSwitcher } from 'components/navigation/language-switcher.component';
import { useAnalyticEvent } from 'utils/hooks';
import { AgentContact } from 'components/agent-contact';

type SidebarFooterProps = {
    logout: () => void;
    toggleSidebar: (showSidebar?: boolean) => void;
};

export const SidebarFooter = ({
    logout,
    toggleSidebar,
    ...rest
}: SidebarFooterProps & SpaceProps & FlexboxProps) => {
    const analyticEvent = useAnalyticEvent();

    const intl = useIntl();
    const { pathname } = useLocation();

    const trackLink = (type: string) => {
        analyticEvent('change_section', {
            main_section_name: type,
            event_location_category: 'navigation'
        });
        toggleSidebar();
    };

    const LinkText = (props: TypographProps) => (
        <Typograph
            color="textBlack"
            fontSize={1}
            fontWeight="normal"
            {...props}
        />
    );

    return (
        <Box {...rest}>
            <AgentContact />

            <Link
                to={Routes.applicationSelection}
                onClick={() => trackLink('my applications')}
            >
                <LinkText
                    tx="sidebar.myApplications"
                    fontWeight={
                        pathname === Routes.applicationSelection
                            ? 'bold'
                            : 'normal'
                    }
                    mt={2}
                />
            </Link>

            <Link to={Routes.settings} onClick={() => trackLink('settings')}>
                <LinkText
                    tx="sidebar.settings"
                    fontWeight={
                        pathname === Routes.settings ? 'bold' : 'normal'
                    }
                    mt={2}
                />
            </Link>

            <LanguageSwitcher mt={2} fontSize={1} />

            <SubmitButton
                data-test-id="logout"
                mt={4}
                px={4}
                label="nav.logout"
                fontSize={1}
                preset="TINY"
                minWidth="auto"
                width="auto"
                onClick={logout}
            />

            <a
                href={WEBSITE_LINKS[intl.locale].privacyPolicy}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackLink('privacy')}
            >
                <LinkText tx="userMenu.privacy" mt={4} />
            </a>

            <a
                href={WEBSITE_LINKS[intl.locale].contact}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackLink('contact')}
            >
                <LinkText tx="userMenu.contact" mt={2} />
            </a>
        </Box>
    );
};
