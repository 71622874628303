import { createReducer, createActions } from 'reduxsauce';
import { RateCardProps } from 'components/rate-box/props';
import { AccountTypes } from 'reducers/account.redux';
import { ApplicationType } from 'models/application/Application';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    // Fetch Matrix
    fetchQuotesMatrixRequest: ['filters'],
    fetchQuotesMatrixSuccess: ['matrix'],
    fetchQuotesMatrixFailure: ['error']
});

export { Creators as Actions };

export const RatesTypes = Types;

export const INITIAL_STATE = {
    loading: true,
    error: null,
    matrix: null,
    moreProducts: [],
    // to be deprecated be kept because of feature flag
    products: null,
    featured: null
};

export type QuoteMatrix = {
    transactionType: ApplicationType;
    firstSelection: RateCardProps;
    secondSelection: RateCardProps;
    unqualifiedSelection: RateCardProps;
};

const fetchQuotesMatrixRequest = (state: any) => ({ ...state, loading: true });

const fetchQuotesMatrixSuccess = (
    state: any,
    { matrix }: { matrix: QuoteMatrix }
) => ({
    ...state,
    error: null,
    loading: false,
    matrix,
    // Keep only firstSelection.product without head.
    // secondSelection and unqualifiedSelection are not product user is allowed to select
    moreProducts:
        matrix.firstSelection && matrix.firstSelection.products
            ? matrix.firstSelection.products.slice(1)
            : []
});

const fetchQuotesMatrixFailure = (state: any, { error }: { error: any }) => ({
    ...state,
    loading: false,
    error,
    matrix: {},
    moreProducts: [],
    products: null,
    featured: null
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.FETCH_QUOTES_MATRIX_REQUEST]: fetchQuotesMatrixRequest,
    [Types.FETCH_QUOTES_MATRIX_SUCCESS]: fetchQuotesMatrixSuccess,
    [Types.FETCH_QUOTES_MATRIX_FAILURE]: fetchQuotesMatrixFailure,
    [AccountTypes.LOGOUT]: () => INITIAL_STATE
});
