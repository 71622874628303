import { getIsDocumentNavOpen } from 'reducers/sidebar.selectors';
import {
    navigationState,
    navigationStates,
    NavigationTitle,
    SidebarNavigationIcon
} from 'components/sidebar/sidebar.component';
import {
    Navigations,
    SubNavigationContainer
} from 'components/sidebar/components/navigations.component';

import { WithLink } from 'components/with-link';
import { DocumentsNavigationRoutes } from './documents-navigation-routes.component';

import { DocumentSvg } from 'assets/media/icons/DocumentSvg';
import { useAppSelector } from 'redux-store/hooks';
import { isBehalfAUser } from 'reducers/account.selectors';
import { useFeatureFlag } from 'components/feature-flagger/hooks';
import { useAnalyticEvent } from 'utils/hooks';

type Props = {
    applicationState: navigationStates;
    toggleSidebar?: () => void;
};

export const DocumentNavigation = ({
    applicationState,
    toggleSidebar = () => {}
}: Props) => {
    const isNavOpen = useAppSelector(getIsDocumentNavOpen) ?? false;
    const isLoggedInOnBehalf = useAppSelector(isBehalfAUser);
    const showDocumentsNewDesign = useFeatureFlag<boolean>(
        'show-documents-new-design'
    );
    const analyticEvent = useAnalyticEvent();

    return (
        <>
            <WithLink
                onClick={() => {
                    analyticEvent('change_section', {
                        main_section_name: 'documents',
                        event_location_category: 'navigation',
                        info: 'documents'
                    });
                    toggleSidebar();
                }}
                to={
                    applicationState === navigationState.ACTIVE_STATE
                        ? `/application/documents?step=1&sectionName=application.documents`
                        : ''
                }
            >
                <Navigations
                    topLine
                    mt={4}
                    withNavLine={false}
                    data-test-id="documents-sidebar-section"
                    active={'ACTIVE'}
                >
                    <NavigationTitle
                        state={isNavOpen ? 'ACTIVE' : ''}
                        label="sidebar.documents"
                    />
                    <SidebarNavigationIcon activeState={applicationState}>
                        <DocumentSvg size={12} />
                    </SidebarNavigationIcon>
                </Navigations>
            </WithLink>
            {isNavOpen && (isLoggedInOnBehalf || !showDocumentsNewDesign) && (
                <SubNavigationContainer
                    data-test-id="documents-navigation-container"
                    isComplete={
                        applicationState === navigationState.COMPLETED_STATE
                    }
                >
                    <DocumentsNavigationRoutes toggleSidebar={toggleSidebar} />
                </SubNavigationContainer>
            )}
        </>
    );
};
