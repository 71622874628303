import { createReducer, createActions } from 'reduxsauce';
import { LOCALE_SRC } from 'constants/appConstants';
import { Language } from 'types/language';

export type LocaleState = {
    lang: Language;
    messages: Record<string, string>;
    localeSource: string;
    region: string | null;
    messagesLoaded: boolean;
    ip?: string;
    city?: string;
    country?: string;
    postal?: { ipApi: string; ipInfo: string };
    lat?: number;
    lng?: number;
};

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    changeLanguage: null,
    updateLocaleAndMessages: ['language', 'messages', 'localeSource'],
    updateRegion: ['data'],
    fetchRegion: null
});

export { Creators as Actions };

export const LocaleTypes = Types;

export const INITIAL_STATE: LocaleState = {
    lang: Language.English,
    messages: {},
    localeSource: LOCALE_SRC.BROWSER,
    region: null,
    postal: { ipApi: '', ipInfo: '' },
    messagesLoaded: false
};

export type UpdateLocaleType = {
    language: Language;
    messages: any;
    localeSource: any;
};

const updateLocale = (
    state: any,
    { language, messages, localeSource }: UpdateLocaleType
) => ({
    ...state,
    lang: language,
    messages,
    localeSource,
    messagesLoaded: true
});

const updateRegion = (state: any, { data }) => ({
    ...state,
    ...data
});

export const localeReducer: (
    state: LocaleState | undefined,
    action: any
) => LocaleState = createReducer(INITIAL_STATE, {
    [Types.UPDATE_LOCALE_AND_MESSAGES]: updateLocale,
    [Types.UPDATE_REGION]: updateRegion
});

export const selectLocaleRegion = (state): string =>
    state.locale.region || 'ON';

export const selectOtherLocale = (state): Language =>
    state.locale.lang === Language.English ? Language.French : Language.English;
