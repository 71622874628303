import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

type Props = {
    size?: number;
    color?: string;
};

const ballScaleMultiple = keyframes`
    0% {
        transform: scale(0.0);
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
`;

const BallScaleMultiple = styled.div<Props>`
    position: relative;
    transform: ${({ size }) => `translateY(-${size ? size / 2 : 30}px)`};

    > div:nth-child(2) {
        animation-delay: -0.4s;
    }

    > div:nth-child(3) {
        animation-delay: -0.2s;
    }

    > div {
        background-color: ${({ theme, color }) => color || theme.brand.primary};
        border-radius: 100%;
        margin: 2px;
        animation-fill-mode: both;
        position: absolute;
        left: ${({ size }) => `-${size ? size / 2 : 30}`}px;
        top: 0px;
        opacity: 0;
        margin: 0;
        width: ${({ size }) => size}px;
        height: ${({ size }) => size}px;
        animation: ${ballScaleMultiple} 1s 0s linear infinite;
    }
`;

export const BaseLoader = ({ size = 100, color }: Props) => (
    <BallScaleMultiple size={size} color={color}>
        <div />
        <div />
        <div />
    </BallScaleMultiple>
);
