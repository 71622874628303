import { Theme } from '@nesto/themes';
import { connect } from 'react-redux';
import { Flex } from 'components/reflexbox';
import styled, { ThemeProps, withTheme } from 'styled-components';
import { FontSizeProps, SpaceProps, TextColorProps } from 'styled-system';
import { Language } from 'types/language';
import formatCurrency from './utils';
import { BoxProps } from 'custom-reflexbox';

export type StateProps = {
    language: Language;
};

const CurrencyWrapper = styled(Flex)<any>`
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 700)};
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : 'center'};
    align-items: center;
    transition: color 0.3s ease-in-out;
    color: ${({ theme, color }) => (color ? color : theme.brand.tertiary)};
`;

export type CurrencyProps = {
    id?: string;
    amount: number;
    sign?: string;
    range?: number;
};

type StyledProps = SpaceProps &
    TextColorProps &
    FontSizeProps &
    Partial<BoxProps>;
type Props = StyledProps & CurrencyProps;
const CurrencyView = ({
    id = 'CurrencyField',
    amount,
    sign,
    language = Language.English,
    range,
    ...props
}: Props & StateProps) => (
    // @ts-ignore
    <CurrencyWrapper data-test-id={id} {...props}>
        {sign}
        {formatCurrency({ language, amount, range })}
    </CurrencyWrapper>
);

export const Currency = connect<StateProps>((state: any) => ({
    language: state.locale.lang
}))(CurrencyView);

export const SignCurrency = withTheme(
    ({ amount, theme, ...rest }: Props & ThemeProps<Theme>) => (
        <Currency
            amount={amount}
            sign={amount > 0 ? '+' : ''}
            color={amount > 0 ? theme.colors.sunsetOrange : theme.colors.salem}
            fontSize={2}
            {...rest}
        />
    )
);
