export const Types = {
    SET: 'LOCAL_STORAGE/SET'
};

export type LocalStorageState = Record<string, any>;

export type SetAction = {
    type: typeof Types.SET;
    key: string;
    value: any;
};

export type LocalStorageActions = SetAction;

const INITIAL_STATE: LocalStorageState = {};

const setValue = (state: LocalStorageState, { key, value }: SetAction) => ({
    ...state,
    [key]: value
});

export const reducer = (
    state: LocalStorageState = INITIAL_STATE,
    action: LocalStorageActions
) => {
    switch (action.type) {
        case Types.SET:
            return setValue(state, action as SetAction);
        default:
            return state;
    }
};

export const Actions = {
    /**
     * Set a value to local storage
     * @param {String} key - Local Storage key
     * @param value - Value to cache in local storage
     */
    set: (key: string, value: any): SetAction => ({
        type: Types.SET,
        key,
        value
    }),
    /**
     * Set the application "has saw onboarding" cached in locale storage
     * @param {Number} applicationId - Application id
     * @param {?boolean} value - Defaulted to true if ignored
     */
    setHasSeenOnboarding: (applicationId: number, value = true) => ({
        type: Types.SET,
        key: `hasSeenOnboarding:${applicationId}`,
        value
    }),
    /**
     *
     * Non-live deals (defined as having selected 'just curious' or 'pre-approval' in GAQ)
     * have one opportunity to book a call in Calendly, right after they've uploaded
     * critical documents
     */
    setHasSeenCalendlyNonLiveDeal: (value = true) => ({
        type: Types.SET,
        key: 'hasSeenCalendlyNonLiveDeal',
        value
    })
};
