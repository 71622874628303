import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { Routes, RoutesType } from './routes';

import { isLoggedIn } from 'reducers/auth.selectors';
import { ApplicationRoute } from './ApplicationRoute';

type StateProps = {
    loggedIn?: boolean;
};

type Props = {
    match?: string | string[];
    path: RoutesType | RoutesType[];
    location?: {
        pathname: string;
        search: string;
        state: any;
        hash: any;
    };
    exact?: boolean;
    component: any;
};

const PrivateRouteV2View = ({
    component: Component,
    loggedIn,
    match,
    ...rest
}: Props & StateProps) => {
    const { search } = useLocation();

    return (
        <ApplicationRoute
            {...rest}
            render={() =>
                loggedIn ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.login,
                            search,
                            state: { from: rest.location }
                        }}
                    />
                )
            }
        />
    );
};

export const PrivateRouteV2 = connect<StateProps>(state => ({
    loggedIn: isLoggedIn(state)
}))(PrivateRouteV2View);
