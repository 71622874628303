import { WEBSITE_LINKS } from 'constants/appConstants';

export const formatUrlsWithQueries = (url: string, queries: any): string =>
    `${url}?${Object.entries(queries)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')}`;

export function promesifySaga<T = any>(fn: any, ...args): Promise<T> {
    return new Promise((resolve, reject) => fn(...args, resolve, reject));
}

/**
 * Determine whether the given `candidate` is a function
 *
 * @param {*} candidate
 */
export const isFunction = (candidate: any) => typeof candidate === 'function';

export const redirectToNestoWebsite = (locale: string) =>
    window.location.replace(WEBSITE_LINKS[locale].website);

/**
 * Transforms a caught unknown error into an Error instance
 * If it is not of type Error, it will return a new Error with the contextMessage
 * @param err - The error caught (type unknown).
 * @param contextMessage - Context to return about the error if it is not a Error instance.
 * @returns An Error object with the contextMessage.
 */
export const processError = (err: unknown, contextMessage: string): Error => {
    if (err instanceof Error) {
        return err;
    } else {
        return new Error(contextMessage);
    }
};
