import { path, pathOr } from 'ramda';
import createCachedSelector from 're-reselect';
import * as yup from 'yup';
import * as R from 'ramda';
import qs from 'qs';

import {
    getCurrentSearchParams,
    getIsQuebecAccount
} from 'reducers/account.selectors';
import { getDashboardStatus } from 'reducers/dashboard.selectors';
import {
    getHasMinPercentDownpayment,
    getApplicationType,
    getDownPaymentAmount,
    getApplicants,
    getMainApplicant
} from './application.selectors';
import { navigationState } from 'components/sidebar/sidebar.component';
import { DocumentStep } from 'models/documents/documents-step.enum';
import { ApplicantDocumentsCounts } from 'models/documents';
import { Applicant } from 'models/application/Application';
import {
    getCurrentApplicationApplicantId,
    getMainApplicantId,
    getTargetProperty,
    getCurrentApplicationType,
    getIsMainApplicant,
    getRegisteredAddressHasEnoughHistory,
    memorizedGetApplicants,
    applicationStateKey,
    getApplicant,
    isApplicationSubmitted as getIsApplicationSubmitted,
    hasBankingDetails,
    getCurrentApplicantInfo
} from 'reducers/application.selectors';
import { getCurrentPath } from 'reducers/startup.redux';
import { Routes } from 'app-root/routing/routes';
import { creditScoreQuality } from 'types/credit-score';
import { getActiveApplicationId } from 'reducers/session.selectors';
import { getFeatureFlag } from 'components/feature-flagger/hooks';

export type ApplicantNavigationStates = {
    applicantInformation: boolean;
    registeredAddress: boolean;
    income: boolean;
    otherIncome: boolean;
    otherProperties: boolean;
    bankingDetails: boolean;
};

export type ApplicantId = string | number;
export type ApplicantStates = {
    [id: string]: ApplicantNavigationStates;
};

export type SubjectPropertyStates = {
    subjectProperty: boolean;
};

export type DownpaymentStates = {
    downpayment: boolean;
};

export type NavigationStates = SubjectPropertyStates &
    DownpaymentStates &
    ApplicantStates;

export type ApplicationNavigationStates =
    | (SubjectPropertyStates & DownpaymentStates)
    | NavigationStates;

export const getCurrentApplicationApplicant = (state): any => {
    const currentApplicantId = getCurrentApplicationApplicantId(state);

    return getApplicant(state, currentApplicantId);
};

export const getIsApplicationRoute = (state: any): boolean => {
    const pathname = getCurrentPath(state);

    const isAllowedRoute =
        // excluded routes from nested application but not in same sub nav anymore
        [
            Routes.productSelectionSelection,
            Routes.applicationDocuments,
            Routes.applicationSubmit
        ].some(route => pathname.startsWith(route)) === false;

    return pathname.startsWith('/application') && isAllowedRoute;
};

export const getIsDocumentsRoute = (state: any): boolean => {
    const pathname = getCurrentPath(state);

    return pathname.startsWith(Routes.applicationDocuments);
};

export const getIsRateSelectionRoute = (state: any): boolean => {
    const pathname = getCurrentPath(state);
    const allowedRoutes = [
        Routes.productSelectionSelection,
        Routes.applicationSubmit
    ];

    return allowedRoutes.some(route => pathname.startsWith(route));
};

const getAllApplicationApplicants = (
    applicants?: { [id: string]: Applicant },
    mainApplicantId?: string | number
) => {
    if (!applicants || !mainApplicantId) {
        return [];
    }

    return Object.values({
        ...applicants,
        [mainApplicantId]: {
            isMainApplicant: true,
            ...applicants[mainApplicantId]
        }
    });
};

export const memorizedGetAllApplicationApplicants = createCachedSelector(
    memorizedGetApplicants,
    getMainApplicantId,
    getAllApplicationApplicants
)(state => `allApplicationApplicants${applicationStateKey(state)}`);

export const getIsApplicantComplete = (
    state: any,
    applicantId: ApplicantId
): ApplicantNavigationStates & { downpayment: boolean } => {
    const applicant = getApplicant(state, applicantId);
    const applicantInformation = getCurrentApplicantInfo(state);
    const isMainApplicant = getIsMainApplicant(state);
    const isPoorCreditScore =
        applicantInformation?.creditScoreQuality === 'POOR';
    const isQuebecAccount = getIsQuebecAccount(state);
    const hasBankruptcyOrNotAnswered = ['YES', ''].includes(
        (applicant && applicant?.hasConsumerProposalOrBankruptcyLast5yrs) || ''
    );
    const isBankruptcyEnable = getFeatureFlag('bankruptcy');

    // All province except QC
    // We **block** poor credit for the main applicant
    // We **DO NOT** block poor credit on 2nd applicant
    //
    // QC only
    // We **DO NOT** block poor credit for the main applicant
    // We **DO NOT** block poor credit on 2nd applicant
    if (
        isBankruptcyEnable &&
        !isQuebecAccount &&
        isMainApplicant &&
        isPoorCreditScore
    ) {
        return {
            applicantInformation: false,
            registeredAddress: false,
            income: false,
            otherIncome: false,
            otherProperties: false,
            downpayment: false,
            bankingDetails: false
        };
    }

    // Rest of Canada aren't allowed to have bankruptcy
    if (isBankruptcyEnable && !isQuebecAccount && hasBankruptcyOrNotAnswered) {
        return {
            applicantInformation: getIsApplicantInformationCompleted(
                state,
                applicantId
            ),
            registeredAddress: getRegisteredAddressHasEnoughHistory(
                state,
                applicantId
            ),
            income: getIsApplicantIncomeComplete(state, applicantId),
            otherIncome: false,
            otherProperties: false,
            downpayment: false,
            bankingDetails: false
        };
    }

    return {
        applicantInformation: getIsApplicantInformationCompleted(
            state,
            applicantId
        ),
        registeredAddress: getRegisteredAddressHasEnoughHistory(
            state,
            applicantId
        ),
        income: getIsApplicantIncomeComplete(state, applicantId),
        otherIncome: getIsApplicantOtherIncomeComplete(state, applicantId),
        otherProperties: getIsApplicantOtherPropertiesComplete(
            state,
            applicantId
        ),
        downpayment: getHasDownpayment(state, applicantId),
        bankingDetails: hasBankingDetails(state, applicantId)
    };
};

export const getIsApplicantCompleteDPInApp = (
    state: any,
    applicantId: ApplicantId
): ApplicantNavigationStates => {
    const applicantInformation = getCurrentApplicantInfo(state);

    if (applicantInformation?.creditScoreQuality === 'POOR') {
        return {
            applicantInformation: false,
            registeredAddress: false,
            income: false,
            otherIncome: false,
            otherProperties: false,
            bankingDetails: false
        };
    }

    return {
        applicantInformation: getIsApplicantInformationCompleted(
            state,
            applicantId
        ),
        registeredAddress: getRegisteredAddressHasEnoughHistory(
            state,
            applicantId
        ),
        income: getIsApplicantIncomeComplete(state, applicantId),
        otherIncome: getIsApplicantOtherIncomeComplete(state, applicantId),
        otherProperties: getIsApplicantOtherPropertiesComplete(
            state,
            applicantId
        ),
        bankingDetails: hasBankingDetails(state, applicantId)
    };
};

export const getIsTargetPropertyComplete = (state: any): boolean => {
    const targetProperty = getTargetProperty(state);
    const type = getCurrentApplicationType(state);

    if (!targetProperty) {
        return false;
    }

    try {
        targetPropertySchema.validateSync(targetProperty, {
            context: {
                type
            }
        });

        return true;
    } catch (error) {
        return false;
    }
};

export const combineTargetProps = (targetProperty, type) => {
    if (!targetProperty) {
        return false;
    }

    const schema = targetProperty.isFound
        ? targetPropertySchema
        : targetPropertyNotFoundSchema;

    try {
        schema.validateSync(targetProperty, {
            context: {
                type
            }
        });

        return true;
    } catch (error) {
        return false;
    }
};

export const memorizedGetIsTargetPropertyComplete = createCachedSelector(
    getTargetProperty,
    getCurrentApplicationType,
    combineTargetProps
)(state => `isTargetPropertyComplete${applicationStateKey(state)}`);

export const getHasOneApplicantBankrupt = createCachedSelector(
    getApplicants,
    applicants =>
        R.pipe<Applicant[], string[], boolean>(
            R.pluck('hasConsumerProposalOrBankruptcyLast5yrs'),
            R.includes('YES')
        )(Object.values(applicants || {}))
)(state => `getHasOneApplicantBankrupt${applicationStateKey(state)}`);

export const getHasOneApplicantWithLowCreditScore = createCachedSelector(
    getApplicants,
    applicants =>
        R.pipe<Applicant[], string[], boolean>(
            R.pluck('creditScoreQuality'),
            R.anyPass([
                R.includes(creditScoreQuality.POOR),
                R.includes(creditScoreQuality.FAIR)
            ])
        )(Object.values(applicants || {}))
)(state => `getHasOneApplicantWithLowCreditScore${applicationStateKey(state)}`);

export const getIsMainApplicantBankrupt = createCachedSelector(
    getMainApplicant,
    mainApplicant =>
        mainApplicant?.hasConsumerProposalOrBankruptcyLast5yrs === 'YES'
)(state => `getIsMainApplicantBankrupt${applicationStateKey(state)}`);

export const getApplicationNavigationStates = (
    state: any,
    applicants?: { [id: string]: Applicant },
    isTargetPropertyComplete?: boolean
): ApplicationNavigationStates => {
    if (applicants) {
        const applicantValues = Object.values(applicants)
            .map(applicant => ({
                id: applicant.applicantId,
                ...getIsApplicantComplete(state, applicant.applicantId)
            }))
            .reduce(
                (acc, currentValue) => ({
                    ...acc,
                    [currentValue.id]: currentValue
                }),
                {}
            );

        const isBlockByBankruptcy = getIsBlockByBankruptcy(state);

        const states = {
            subjectProperty: isBlockByBankruptcy
                ? false
                : isTargetPropertyComplete || false,
            downpayment: isBlockByBankruptcy
                ? false
                : getHasMinPercentDownpayment(state),
            ...applicantValues
        } as ApplicationNavigationStates;

        return states;
    }

    return {} as ApplicationNavigationStates;
};

export const getApplicationNavigationStatesDPInApp = (
    state: any,
    applicants?: { [id: string]: Applicant },
    isTargetPropertyComplete?: boolean
): ApplicationNavigationStates => {
    if (applicants) {
        const applicantValues = Object.values(applicants)
            .map(applicant => ({
                id: applicant.applicantId,
                ...getIsApplicantCompleteDPInApp(state, applicant.applicantId)
            }))
            .reduce(
                (acc, currentValue) => ({
                    ...acc,
                    [currentValue.id]: currentValue
                }),
                {}
            );

        const isBlockByBankruptcy = getIsBlockByBankruptcy(state);

        const states = {
            subjectProperty: isBlockByBankruptcy
                ? false
                : isTargetPropertyComplete || false,
            downpayment: isBlockByBankruptcy
                ? false
                : getHasMinPercentDownpayment(state),
            ...applicantValues
        } as ApplicationNavigationStates;

        return states;
    }

    return {} as ApplicationNavigationStates;
};

/**
 * Allow Quebec resident to go through even with bankruptcy
 *
 * Rest of Canada application is **block** if main applicant had bankruptcy
 *
 * @param state
 */
export const getIsBlockByBankruptcy = createCachedSelector(
    getIsQuebecAccount,
    getIsMainApplicantBankrupt,
    (isQuebecAccount, isMainApplicantBankrupt): boolean => {
        // Can not use async/await into that selector so we grab the value right from the
        // Launch Darkly sdk.
        // const bankruptcyEnable = await getFeatureFlagState('bankruptcy')
        const isBankruptcyEnable = getFeatureFlag('bankruptcy');

        if (isBankruptcyEnable === false) return false;

        return !isQuebecAccount && isMainApplicantBankrupt;
    }
)(state => `getIsBlockByBankruptcy${applicationStateKey(state)}`);

export const memorizedGetApplicationNavigationStates = createCachedSelector(
    state => state,
    memorizedGetApplicants,
    memorizedGetIsTargetPropertyComplete,
    getApplicationNavigationStates
)(state => `applicationNavigationStates${applicationStateKey(state)}`);

export const memorizedGetApplicationNavigationStatesDPInApp = createCachedSelector(
    state => state,
    memorizedGetApplicants,
    memorizedGetIsTargetPropertyComplete,
    getApplicationNavigationStatesDPInApp
)(state => `applicationNavigationStatesDPInApp${applicationStateKey(state)}`);

export const getIsApplicantInformationCompleted = (
    state,
    applicantId
): boolean => {
    const currentApplicant = getApplicant(state, applicantId);

    if (!applicantId || !currentApplicant) {
        return false;
    }

    const isMainApplicant: boolean = !applicantId
        ? false
        : getIsMainApplicant(state, applicantId.toString());

    if (!currentApplicant) {
        return false;
    }

    try {
        applicantInformationSchema.validateSync(currentApplicant, {
            context: { isMainApplicant }
        });

        return true;
    } catch (error) {
        return false;
    }
};

export const getIsApplicantIncomeComplete = (
    state: any,
    applicantId: ApplicantId
): boolean => {
    const applicant = getApplicant(state, applicantId);
    if (!applicant) {
        return false;
    }

    const employments = applicant.income.employments;

    try {
        incomeArraySchema.validateSync(employments);

        return true;
    } catch (error) {
        return false;
    }
};

export const getIsApplicantOtherIncomeComplete = (
    state: any,
    applicantId: ApplicantId
): boolean => {
    const applicant = getApplicant(state, applicantId);

    if (!applicant) {
        return false;
    }

    return applicant.otherIncomesSpecified;
};

export const getIsApplicantOtherPropertiesComplete = (
    state: any,
    applicantId: ApplicantId
): boolean => {
    const applicant = getApplicant(state, applicantId);
    if (!applicant) {
        return false;
    }

    return applicant.propertiesSpecified;
};

export const getHasDownpayment = (state: any, applicantId: ApplicantId) => {
    const applicant = getApplicant(state, applicantId);
    if (!applicant) {
        return false;
    }

    const assets = applicant.allAssets;

    const sumAmountUsedForDownPayment = (assets || []).reduce(
        (accumulator, asset) => +accumulator + +asset.amountUsedForDownPayment,
        0
    );

    return sumAmountUsedForDownPayment >= 1000;
};

//
// Yup Validation
//

export const applicantInformationSchema = yup.object().shape({
    salutation: yup
        .string()
        .min(2)
        .required(),
    firstName: yup
        .string()
        .min(1)
        .required(),
    lastName: yup
        .string()
        .min(1)
        .required(),
    dateOfBirth: yup
        .string()
        .min(2)
        .required(),
    phone: yup
        .string()
        .min(10)
        .required(),
    maritalStatus: yup
        .string()
        .min(2)
        .required(),
    relationToMainApplicant: yup.string().when('$isMainApplicant', {
        is: false,
        then: yup
            .string()
            .min(0)
            .nullable(),
        otherwise: yup
            .string()
            .min(0)
            .nullable()
    })
});

// Validate if we have base amount and frequency only when *NOT* of type *NONE*
const incomeSchema = yup.object().shape({
    salary: yup.object().when('incomeType', (incomeType, schema) =>
        incomeType === 'NONE'
            ? schema
            : yup.object().shape({
                  base: yup.object().shape({
                      amount: yup.number().required(),
                      frequency: yup
                          .string()
                          .min(1)
                          .required()
                  })
              })
    )
});

export const incomeArraySchema = yup
    .array()
    .of(incomeSchema)
    .min(1);

export const targetPropertyNotFoundSchema = yup.object().shape({
    purpose: yup
        .string()
        .min(1)
        .required(),
    propertyType: yup
        .string()
        .min(1)
        .required(),
    purchasePrice: yup
        .number()
        .min(0)
        .required()
});

export const targetPropertySchema = yup.object().shape({
    purpose: yup
        .string()
        .min(1)
        .required(),
    constructionType: yup
        .string()
        .min(1)
        .required(),
    propertyType: yup
        .string()
        .min(1)
        .required(),
    tenure: yup
        .string()
        .min(1)
        .required(),
    waterType: yup
        .string()
        .min(1)
        .required(),
    propertyStyle: yup
        .string()
        .min(1)
        .required(),
    yearBuilt: yup
        .number()
        .min(0)
        .required(),
    estimatedPropertyValue: yup
        .number()
        .min(0)
        .required()
});

export const getIsNavOpen = state => {
    const isApplicationRoute = getIsApplicationRoute(state);
    const currentPath = getCurrentPath(state);
    const activeStates = computeSidebarStatus(state);

    return (
        isApplicationRoute ||
        (activeStates.apply === navigationState.ACTIVE_STATE &&
            currentPath === '/')
    );
};

export const getIsDocumentNavOpen = state => getIsDocumentsRoute(state);

export const getIsRateSelectionNavOpen = state =>
    getIsRateSelectionRoute(state);

export const getDocumentsStep = state => {
    const search: any = getCurrentSearchParams(state);
    const { step } = qs.parse(search, {
        ignoreQueryPrefix: true
    });

    return (step && +step) || 1;
};

export const getDocumentsEmptyCount = (
    state,
    step: DocumentStep = DocumentStep.All
): number => path(['documents', 'counts', step, 'emptyCount'], state) || 0;

export const getDocumentsBrokerUnapprovedCount = (
    state,
    step: DocumentStep = DocumentStep.All
): number =>
    path(['documents', 'counts', step, 'brokerUnapprovedCount'], state) || 0;

export const getDocumentsStepCountsBadge = (
    state,
    step: DocumentStep = DocumentStep.All
): number =>
    getDocumentsEmptyCount(state, step) +
    getDocumentsBrokerUnapprovedCount(state, step);

export const memorizedGetDocumentsStepCountsBadge: (
    state: any,
    step: DocumentStep
) => number = createCachedSelector(
    getDocumentsStepCountsBadge,
    stepCounts => stepCounts
)(state => `documentStepCountsBadge${applicationStateKey(state)}`);

export const getApplicantStepCounts = (
    state,
    applicantId: number,
    step: DocumentStep = DocumentStep.All
) =>
    pathOr(
        {},
        ['documents', 'counts', step, 'countsStateByApplicantId', applicantId],
        state
    );
export const getAllApplicantsStepCounts = (
    state,
    step: DocumentStep = DocumentStep.All
): {
    [applicantId: string]: ApplicantDocumentsCounts;
} => {
    const applicants = memorizedGetAllApplicationApplicants(state);

    return applicants.reduce(
        (prev, applicant) => ({
            ...prev,
            [applicant.applicantId]: getApplicantStepCounts(
                state,
                applicant.applicantId,
                step
            )
        }),
        {}
    );
};

export const memorizedGetAllApplicantsStepCounts: (
    state: any,
    step: DocumentStep
) => Record<string, ApplicantDocumentsCounts> = createCachedSelector(
    getAllApplicantsStepCounts,
    allApplicantsStepCounts => allApplicantsStepCounts
)(state => `allApplicantsStepCounts${applicationStateKey(state)}`);

export const getIsAdditionalDocEnabled = createCachedSelector(
    (state: any) => state,
    (state: any) => getDocumentsStepCountsBadge(state, DocumentStep.Critical),
    getIsApplicationSubmitted,
    (_, criticalStepCounts, isApplicationSubmitted) =>
        isApplicationSubmitted ? true : criticalStepCounts <= 0 ? true : false
)(state => `isAdditionalDocEnabled${applicationStateKey(state)}`);

export const getPropertyIsFound = (state): boolean => {
    const property = getTargetProperty(state);

    return !!(property && property.isFound);
};

export type ActiveStates = 'ACTIVE' | 'COMPLETED' | 'UNREACHED';
export type SidebarStatus = {
    getAQuote: ActiveStates;
    apply: ActiveStates;
    rateSelection: ActiveStates;
    documents: ActiveStates;
    finalSignature: ActiveStates;
};

// The new sidebar has a dependency of application state to know if
// rate slection, documents, and final signature state are reached, completed or unreached

export const computeSidebarStatus = createCachedSelector(
    getDashboardStatus,
    getIsApplicationSubmitted,
    memorizedGetIsTargetPropertyComplete,
    (
        applicationState,
        isApplicationSubmitted,
        isTargetPropertyComplete
    ): SidebarStatus => {
        if (!applicationState) {
            return {
                getAQuote: 'ACTIVE',
                apply: 'UNREACHED',
                rateSelection: 'UNREACHED',
                documents: 'UNREACHED',
                finalSignature: 'UNREACHED'
            };
        }

        if (applicationState === 'APPLICATION') {
            return {
                getAQuote: 'COMPLETED',
                apply: isApplicationSubmitted ? 'COMPLETED' : 'ACTIVE',
                rateSelection: isApplicationSubmitted
                    ? 'COMPLETED'
                    : isTargetPropertyComplete
                    ? 'ACTIVE'
                    : 'UNREACHED',
                documents: 'ACTIVE',
                finalSignature: 'UNREACHED'
            };
        }

        return {
            getAQuote: 'ACTIVE',
            apply: 'UNREACHED',
            rateSelection: 'UNREACHED',
            documents: 'UNREACHED',
            finalSignature: 'UNREACHED'
        };
    }
)(
    (state: any) =>
        `computeSidebarStatus${getActiveApplicationId(state)}.${
            state.dashboard.dashboardState.state
        }`
);

export const getIsMainApplicantIncomeComplete = (state: any) => {
    const mainApplicantId = getMainApplicantId(state);

    return getIsApplicantIncomeComplete(state, mainApplicantId);
};

export const getIsMainApplicantBankingDetails = (state: any) => {
    const mainApplicantId = getMainApplicantId(state);

    return hasBankingDetails(state, mainApplicantId);
};

export const getIsTargetPropertyActive = createCachedSelector(
    getApplicationType,
    getDownPaymentAmount,
    getIsMainApplicantIncomeComplete,
    getIsMainApplicantBankingDetails,
    (
        applicationType,
        downPaymentAmount,
        isMainApplicantIncomeComplete,
        hasBankingDetails
    ) => {
        // NEW
        // Income is completed and down payment >= 1000$
        if (applicationType === 'NEW') {
            return (
                isMainApplicantIncomeComplete &&
                downPaymentAmount >= 1000 &&
                hasBankingDetails
            );
        }

        // RENEWAL or REFINANCE
        // Main Applicant Income is completed
        return isMainApplicantIncomeComplete && hasBankingDetails;
    }
    // @ts-ignore
)(state => `isTargetPropertyActive${applicationStateKey(state)}`);

export const getIsTargetPropertyActiveDpInApp = createCachedSelector(
    getIsMainApplicantIncomeComplete,
    getIsMainApplicantBankingDetails,
    (isMainApplicantIncomeComplete, hasBankingDetails) =>
        isMainApplicantIncomeComplete && hasBankingDetails
    // @ts-ignore
)(state => `dpInAppIsTargetPropertyActive${applicationStateKey(state)}`);
