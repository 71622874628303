import React from 'react';
import { connect } from 'react-redux';
import { theme } from 'theme';
import {
    getCurrentApplicationApplicantId,
    getIsLockedState,
    getMainApplicantId
} from 'reducers/application.selectors';
import {
    memorizedGetApplicationNavigationStates,
    ApplicationNavigationStates,
    getIsNavOpen
} from 'reducers/sidebar.selectors';
import {
    navigationState,
    navigationStates,
    NavigationTitle,
    SidebarNavigationIcon
} from 'components/sidebar/sidebar.component';
import {
    Navigations,
    SubNavigationContainer
} from 'components/sidebar/components/navigations.component';
import { ApplicationNavigationRoutes } from './application-navigation-routes.component';

import { WithLink } from 'components/with-link';
import { Typograph } from 'components/typograph';
import { Tooltip } from 'components/tooltip';
import { useAnalyticEvent } from 'utils/hooks';

type StateProps = {
    activeApplicantId?: string | number;
    isNavOpen?: boolean;
    navigationStepStates: ApplicationNavigationStates;
    mainApplicantId: string | number;
    isLocked?: boolean;
};

type Props = {
    applicationState: navigationStates;
    nextApplicationState: navigationStates;
    toggleSidebar?: () => void;
};

export const ApplicationNavigationView = ({
    applicationState,
    nextApplicationState,
    isNavOpen = false,
    toggleSidebar = () => {},
    mainApplicantId,
    isLocked
}: Props & StateProps) => {
    const analyticEvent = useAnalyticEvent();

    return (
        <>
            <WithLink
                onClick={() => {
                    analyticEvent('change_section', {
                        main_section_name: 'Applicants & property',
                        event_location_category: 'navigation'
                    });
                }}
                to={
                    applicationState === navigationState.UNREACHED_STATE
                        ? ''
                        : `/application/applicant-info?applicant=${mainApplicantId}&sectionName=application.applicantInformation`
                }
            >
                <Navigations
                    data-test-id="application-sidebar-section"
                    active={
                        nextApplicationState === navigationState.ACTIVE_STATE
                            ? navigationState.COMPLETED_STATE
                            : applicationState
                    }
                    withCursor={
                        applicationState !== navigationState.UNREACHED_STATE
                    }
                >
                    <NavigationTitle
                        state={
                            applicationState === navigationState.UNREACHED_STATE
                                ? 'COMPLETED'
                                : isNavOpen
                                ? 'ACTIVE'
                                : ''
                        }
                        label="sidebar.application"
                    />
                    {isLocked && (
                        <Tooltip
                            triggerIcon="lock-icon"
                            content="sidebar.lockTooltip"
                            iconSize="20"
                            iconColor={theme.colors.portGore}
                        />
                    )}
                    <SidebarNavigationIcon activeState={applicationState}>
                        <Typograph fontSize="15px" fontWeight="bold">
                            {applicationState ===
                            navigationState.UNREACHED_STATE
                                ? 3
                                : 1}
                        </Typograph>
                    </SidebarNavigationIcon>
                </Navigations>
            </WithLink>
            {isNavOpen && (
                <SubNavigationContainer
                    data-test-id="application-navigation-container"
                    isComplete={
                        applicationState === navigationState.COMPLETED_STATE ||
                        // next sidebar link is active
                        nextApplicationState === navigationState.ACTIVE_STATE
                    }
                >
                    <ApplicationNavigationRoutes
                        toggleSidebar={toggleSidebar}
                    />
                </SubNavigationContainer>
            )}
        </>
    );
};

export const ApplicationNavigation = connect<StateProps>((state: any) => ({
    isNavOpen: getIsNavOpen(state),
    activeApplicantId: getCurrentApplicationApplicantId(state),
    navigationStepStates: memorizedGetApplicationNavigationStates(state),
    mainApplicantId: getMainApplicantId(state),
    isLocked: getIsLockedState(state)
}))(ApplicationNavigationView) as React.ComponentType<Props>;
