export enum rateTypes {
    VARIABLE = 'VARIABLE',
    FIXED = 'FIXED'
}

export enum terms {
    SIXMONTHS = '6_MONTHS',
    ONEYEAR = '1_YEAR',
    TWOYEAR = '2_YEAR',
    THREEYEAR = '3_YEAR',
    FOURYEAR = '4_YEAR',
    FIVEYEAR = '5_YEAR',
    SIXYEAR = '6_YEAR',
    SEVENYEAR = '7_YEAR',
    TENYEAR = '10_YEAR'
}

export type QualifiedRate = {
    id: string;
    providerName: string;
    providerLogo: string;
    providerDescrition: string;
    rate: string;
    monthly: number;
    rateType: rateTypes;
    term: terms;
};
