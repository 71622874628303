import React from 'react';
import { useIntl } from 'react-intl';

import { Application } from 'models/application/Application';
import { getApplicationIdentifier } from 'utils/application';

export const ApplicationIdentifier = ({
    application
}: {
    application: Application;
}) => {
    const intl = useIntl();
    const identifier = getApplicationIdentifier(application);

    return (
        <>
            {isNaN(identifier as number)
                ? identifier
                : intl.formatMessage(
                      {
                          id: 'transactionId'
                      },
                      { id: identifier }
                  )}
        </>
    );
};
