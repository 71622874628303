import { Box, Flex } from 'reflexbox/styled-components';

import { Close as CloseIcon } from '@nesto/design-system/v2';
import Modal from 'components/modal';
import { CloseButtonProps } from 'types/components';
import { UnstyledButton } from '@nesto/design-system';

type Props = {
    children: React.ReactNode;
    overrideCloseComponent?: (props: CloseButtonProps) => JSX.Element;
};

const DefaultComponent = ({ close }: CloseButtonProps) => (
    <UnstyledButton onClick={close} accessibleLabel="popup.close">
        <CloseIcon
            width="36px"
            height="36px"
            style={{
                pointerEvents: 'none'
            }}
        />
    </UnstyledButton>
);

export const ModalContent = ({
    children,
    close,
    overrideCloseComponent
}: Props & CloseButtonProps) => {
    const CloseComponent = overrideCloseComponent
        ? overrideCloseComponent
        : DefaultComponent;
    return (
        <Modal>
            <Flex
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                pb={[4]}
                style={{ height: '100%' }}
                onClick={(event: MouseEvent) => {
                    if (event.target === event.currentTarget) {
                        close();
                    }
                }}
            >
                <div style={{ position: 'relative' }}>
                    {children}
                    <Box
                        justifyContent="center"
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10
                        }}
                    >
                        <CloseComponent close={close} />
                    </Box>
                </div>
            </Flex>
        </Modal>
    );
};
