import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { Actions as DocumentsActions } from 'reducers/documents.redux';
import { Actions as DashboardActions } from 'reducers/dashboard.redux';
import { onFetchApplicationRedirect } from 'sagas/application.sagas';
import { SetActiveApplicationIdAction } from 'reducers/session.redux';
import { getActiveApplication } from 'reducers/application.selectors';
import { isMortgageFundedByNesto } from 'utils/application';

export function* onSetActiveApplicationId({
    activeApplicationId,
    redirectTo
}: SetActiveApplicationIdAction) {
    const activeApplication = yield select(getActiveApplication);

    if (!activeApplication) {
        return;
    }
    if (
        activeApplication.sdrQualified &&
        !isMortgageFundedByNesto(activeApplication)
    ) {
        return yield put(push('/application/documents'));
    }

    if (isMortgageFundedByNesto(activeApplication)) {
        return yield put(push('/servicing'));
    }

    const location = yield call(onFetchApplicationRedirect, {
        applicationId: activeApplicationId,
        redirectTo
    });

    if (redirectTo && activeApplicationId) {
        yield put(push(location));
    }

    if (activeApplicationId) {
        yield put(DocumentsActions.documentsCountsRequest());
        yield put(DashboardActions.fetchDashboardRequest());
    }
}
