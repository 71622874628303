import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions(
    {
        submitRenewal: ['formName', 'seeCrunchingAnimation'],
        submitRenewalSuccess: null,
        submitRenewalFailure: null,
        submitRefinance: ['formName', 'seeCrunchingAnimation'],
        submitRefinanceSuccess: null,
        submitRefinanceFailure: null,
        crunchingCompleted: ['search'],
        submitNew: ['formName', 'seeCrunchingAnimation'],
        submitNewSuccess: null,
        submitNewFailure: null,
        analyticsQuestionAnswered: ['FORM_NAME', 'step', 'index', 'value'],
        analyticsQuestionViewed: ['FORM_NAME', 'step', 'index'],
        submitNewAnonymous: null,
        submitRenewalAnonymous: null,
        submitRefinanceAnonymous: null,
        updateInitialValues: ['initialValues']
    },
    {
        prefix: '[MORTGAGE_WIZARDS]'
    }
);

export const WizardTypes = Types;
export { Creators as Actions };

export const INITIAL_STATE = {
    loading: false,
    initialValues: {}
};

export const submitRenewal = (state: any) => ({ ...state, loading: true });
export const submitRenewalSuccess = (state: any) => ({
    ...state,
    loading: false
});
export const submitRenewalFailure = (state: any) => ({
    ...state,
    loading: false
});

export const submitRefinance = (state: any) => ({ ...state, loading: true });
export const submitRefinanceSuccess = (state: any) => ({
    ...state,
    loading: false
});
export const submitRefinanceFailure = (state: any) => ({
    ...state,
    loading: false
});
export const updateInitialValues = (state: any, { initialValues }) => ({
    ...state,
    initialValues
});

export const submitNew = (state: any) => ({ ...state, loading: true });
export const submitNewSuccess = (state: any) => ({ ...state, loading: false });
export const submitNewFailure = (state: any) => ({ ...state, loading: false });

export const questionAnswered = (state: any) => state;

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_INITIAL_VALUES]: updateInitialValues,
    [Types.SUBMIT_RENEWAL]: submitRenewal,
    [Types.SUBMIT_RENEWAL_SUCCESS]: submitRenewalSuccess,
    [Types.SUBMIT_RENEWAL_FAILURE]: submitRenewalFailure,
    [Types.SUBMIT_REFINANCE]: submitRefinance,
    [Types.SUBMIT_REFINANCE_SUCCESS]: submitRefinanceSuccess,
    [Types.SUBMIT_REFINANCE_FAILURE]: submitRefinanceFailure,
    [Types.SUBMIT_NEW]: submitNew,
    [Types.SUBMIT_NEW_SUCCESS]: submitNewSuccess,
    [Types.SUBMIT_NEW_FAILURE]: submitNewFailure,
    [Types.ANALYTICS_QUESTION_ANSWERED]: questionAnswered,
    [Types.ANALYTICS_QUESTION_VIEWED]: questionAnswered
});
