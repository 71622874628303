import { useEffect } from 'react';

import { Creators } from 'reducers/notifications.redux';
import { Typograph } from '@nesto/design-system';
import { isMobile as isMobileSizes, useSizes } from 'utils/hooks';
import { useToasts } from 'components/toast';
import { useAppDispatch, useAppSelector } from 'redux-store/hooks';
import { getNotifications } from 'reducers/notifications.selectors';

export const Notifications = ({ children }) => {
    const { isMobile } = useSizes({ isMobileSizes });
    const totalNotifications = isMobile ? 2 : 3; // show only 2 notifications for mobile
    const notifications = useAppSelector(getNotifications);
    const dispatch = useAppDispatch();
    const removeNotification = id => dispatch(Creators.notificationsRemove(id));

    const { addToast } = useToasts();
    useEffect(() => {
        const notificationsValues = notifications.slice(0, totalNotifications);
        notificationsValues.forEach(notification => {
            addToast(
                <Typograph
                    fontSize={[1]}
                    fontWeight={500}
                    tx={notification.text}
                    data-test-id={`notification_text_${notification.id}`}
                    setInnerHtml
                />,
                {
                    appearance: notification.type,
                    title: notification.title,
                    delay: notification.delay
                },
                () => removeNotification(notification.id)
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications, totalNotifications, removeNotification]);
    return <>{children}</>;
};
