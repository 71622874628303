import { Box, Flex } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';
import { height } from 'styled-system';

export const BackgroundWrapper = styled(Flex)`
    flex-direction: column;
    min-height: 100vh;
    background-color: ${props => props.theme.colors.alabaster};
`;

export const ContentWrapper = styled(Flex)`
    flex-direction: column;
    width: 100%;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
`;

export const LeftCol = styled(Flex)`
    width: 0;
    top: 0;

    ${({ theme }) => theme.mediaQueries.large} {
        flex: 0 0 ${props => props.theme.sidebar.width};
    }
`;

export const RightCol = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
`;

export const LogoContainer = styled(Box)<{ useNavLine }>`
    padding: ${props => props.theme.space[4]}px;
    padding-top: ${props => props.theme.space[3]}px;
    padding-bottom: ${props => props.theme.space[3]}px;
    box-shadow: inset -2px 0 ${({ theme, useNavLine }) => (useNavLine ? theme.brand.accent : theme.colors.mercury)};

    ${({ theme }) => theme.mediaQueries.large} {
        padding-top: ${props => props.theme.space[2]}px;
        padding-bottom: ${props => props.theme.space[2]}px;
    }
`;

export const Sidebar = styled(Flex)`
    z-index: ${props => props.theme.zIndex.heaven};
    min-width: ${props => props.theme.sidebar.width};
    box-shadow: inset -2px 0 ${props => props.theme.colors.mercury};
    background-color: ${props => props.theme.colors.white};
    position: relative;
    width: ${props => props.theme.sidebar.width};
    min-width: auto;
    left: 0;
    top: 0;
    height: 100%;
    min-height: calc(100vh - 60px);
    min-width: auto;
    position: -webkit-sticky;
    position: sticky;
    padding-bottom: ${props => props.theme.space[4]}px;
    flex-direction: column;

    ${height}
`;
