const getNestedProperty = (
    properties: string,
    allValues?: { [s: string]: any }
): { [s: string]: any } | undefined =>
    allValues &&
    properties &&
    properties
        .replace('[', '.')
        .replace(']', '')
        .split('.')
        .reduce(
            (acc: any, property: string) => (acc ? acc[property] : undefined),
            allValues
        );

export { getNestedProperty };
