import React, { useEffect } from 'react';
import { Provider, connect } from 'react-redux';
import { Providers } from 'app-root/providers/providers';
import { StartupActions } from 'reducers/startup.redux';
import { Routing } from './routing/routing';
import { store } from 'app-root/store';

type DispatchProps = {
    startup: () => void;
};

const AppView = ({ startup }: DispatchProps) => {
    useEffect(() => {
        startup();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Providers>
            <Routing />
        </Providers>
    );
};

const ConnectedApp = connect(null, {
    startup: StartupActions.startup
})(AppView);

export const App = () => (
    <Provider store={store}>
        <ConnectedApp />
    </Provider>
);
