import React from 'react';
import { Button as ButtonComponent, ButtonProps } from '@nesto/design-system';
import { ColorProps, TypographyProps } from 'styled-system';
import { withRouter } from 'react-router';

type Props = {
    to?: any;
    history?: any;
};

export const ButtonView = ({
    onClick,
    to,
    history,
    ...props
}: Props & ColorProps & TypographyProps & ButtonProps) => (
    <ButtonComponent
        onClick={event => {
            onClick && onClick(event);
            if (to) history.push(to);
        }}
        {...props}
    />
);

export const Button: React.FC<Props &
    ColorProps &
    TypographyProps &
    ButtonProps> = withRouter(ButtonView as any) as any;
