import React, { forwardRef } from 'react';
import { Box } from 'reflexbox/styled-components';
import styled, { css } from 'styled-components';
import { BorderProps } from 'styled-system';
import { Assign, ForwardRef } from 'types/components';
import { BoxProps } from './box-props';

import { shrinkKeyframes, AppearanceTypes } from './toast';

// NOTE: invoke animation when NOT `autoDismiss` with opacity of 0 to avoid a
// paint bug in FireFox.
// https://bugzilla.mozilla.org/show_bug.cgi?id=625289

const animation = props =>
    css`
        ${shrinkKeyframes} ${props.autoDismissTimeout}ms linear
    `;

export const Countdown = ({
    autoDismissTimeout,
    opacity,
    isRunning,
    shrinkKeyframes,
    ...rest
}: any) => (
    <Box
        className="react-toast-notifications__toast__countdown"
        css={`
            animation: ${animation};
            animation-play-state: ${isRunning ? 'running' : 'paused'};
            background-color: rgba(0, 0, 0, 0.1);
            bottom: 0;
            height: 0;
            left: 0;
            opacity: ${opacity};
            position: absolute;
            width: 100%;
        `}
        {...rest}
    />
);

interface ToastEdgeOwnProps extends BoxProps, BorderProps {
    autoDismiss: any;
    autoDismissTimeout: any;
    isRunning: boolean;
    appearance?: Appearance;
}

export type ToastEdgeProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    ToastEdgeOwnProps
>;

type Appearance = AppearanceTypes;

const edgeColors = {
    info: {
        backgroundColor: '#3D66B0',
        color: '#3D66B0'
    },
    success: {
        backgroundColor: '#43D011',
        color: '#43D011'
    },
    warning: {
        backgroundColor: '#F8CD46',
        color: '#F8CD46'
    },
    error: {
        backgroundColor: '#F9423A',
        color: '#F9423A'
    }
};

export const ToastEdgeStyled = styled<any>(Box)`
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #43d011;
    flex-shrink: 0;
    padding-bottom: 8px;
    padding-top: 8px;
    position: relative;
    overflow: hidden;
    text-align: center;
    width: 4px;
    background-color: ${props => edgeColors[props.appearance].backgroundColor};
    color: ${props => edgeColors[props.appearance].color};
`;

export const ToastEdge: ForwardRef<HTMLDivElement, ToastEdgeProps> = forwardRef(
    (
        {
            appearance,
            autoDismiss,
            autoDismissTimeout,
            isRunning
        }: ToastEdgeProps,
        ref
    ) => {
        return (
            <ToastEdgeStyled ref={ref} appearance={appearance}>
                <Countdown
                    shrinkKeyframes={shrinkKeyframes}
                    opacity={autoDismiss ? 1 : 0}
                    autoDismissTimeout={autoDismissTimeout}
                    isRunning={isRunning}
                />
            </ToastEdgeStyled>
        );
    }
);
