import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { getApplicationType } from 'reducers/application.selectors';
import { ApplicationType } from 'models/application/Application';
import { getTitleOfApplicationType } from './fp-utils';

type StateProps = {
    applicationType: ApplicationType | undefined;
};

type Props = {
    title?: string;
    subsection?: string;
    children: any;
};

export const BrowserTitleView = ({
    applicationType,
    title,
    subsection = '',
    children
}: Props & StateProps) => {
    const intl = useIntl();
    const prefix = title || getTitleOfApplicationType(applicationType);
    const fullTitle = `nesto${
        prefix?.length ? ` | ${intl.formatMessage({ id: prefix })}` : ''
    }${subsection.length ? ` | ${intl.formatMessage({ id: subsection })}` : ''}
`;
    return (
        <>
            <Helmet>
                <title>{fullTitle}</title>
            </Helmet>
            {children}
        </>
    );
};

export const BrowserTitle = connect<StateProps>(state => ({
    applicationType: getApplicationType(state)
}))(BrowserTitleView);
