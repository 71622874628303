import { put, select, call } from 'redux-saga/effects';
import { apiClient } from 'services/api';

import { log } from 'utils/logging';
import {
    getPartnerFromQsOrCookie,
    getSubPartnerIdFromQuery
} from 'reducers/ui.selectors';
import { analyticsTrackEvent } from './analytics.sagas';
import { Actions } from 'reducers/ui.redux';
import { isFunction } from 'utils/helpers';
import { isLoggedIn } from 'reducers/auth.selectors';
import { getActiveApplicationId } from 'reducers/application.selectors';
import { Actions as ApplicationActions } from 'reducers/application.redux';
import { isBehalfAUser } from 'reducers/account.selectors';
import { PARTNER_NAMES } from 'constants/appConstants';
import { analyticEvent } from './analytics-events.sagas';

const PARTNERS = Object.values(PARTNER_NAMES).map(partner => partner.partner);

// Set "partner" from `partner` query string and or from session
export function* bootstrapPartner() {
    const isOnBehalfOf = yield select(isBehalfAUser);

    // On behalf of we can skip this and wait for the client account to be loaded
    // and set from account.sagas.ts `onUpdatePartner`
    if (isOnBehalfOf) {
        return;
    }

    try {
        const partner = yield select(getPartnerFromQsOrCookie);

        if (partner && PARTNERS.includes(partner)) {
            sessionStorage.setItem('partner', partner);
        } else {
            sessionStorage.setItem('partner', 'nesto');
        }

        let partnerSession = sessionStorage.getItem('partner');

        // Support old client "default" or "" from session storage to nesto or from database
        if (
            partnerSession === 'default' ||
            partnerSession === '' ||
            !partnerSession
        ) {
            partnerSession = 'nesto';
        }

        if (partnerSession && PARTNERS.includes(partnerSession)) {
            yield put(Actions.updatePartner(partnerSession));

            const utmSource =
                PARTNER_NAMES[partnerSession]?.utm_source || 'nesto';

            // GA-4
            yield put(
                analyticEvent('co_branded_platform', {
                    event_location_category: 'co-branded platforms',
                    co_branded_partner: utmSource
                })
            );
        }
    } catch (error) {
        log({ tag: 'setting partner: ', error: error as any });
    }

    try {
        const oldSubPartnerId = sessionStorage.getItem('subPartnerId') || 0;
        const subPartnerId = yield select(getSubPartnerIdFromQuery);
        if (subPartnerId) {
            sessionStorage.setItem('subPartnerId', subPartnerId);
        }

        const subPartnerIdSession = sessionStorage.getItem('subPartnerId');

        // if only store to session and not in URL we need to still setup the ID in redux
        if (subPartnerIdSession) {
            yield put(
                analyticsTrackEvent(subPartnerIdSession, {
                    event_location_category: 'sub partner platform',
                    label: '',
                    nonInteraction: 1
                })
            );
        }

        // Looking if the user/client refresh the page with a new (different) subPartnerId in URL
        // if it is different from session and we have an active application selected
        // if shouldUpdateApplicationSubPartnerId then update application subPartnerId
        // oldSubPartnerIdNumber -> subPartnerIdSessionNumber
        const loggedIn = yield select(isLoggedIn);
        const activeApplicationId = yield select(getActiveApplicationId);
        const subPartnerIdSessionNumber = subPartnerIdSession
            ? +subPartnerIdSession
            : 0;
        const oldSubPartnerIdNumber = +oldSubPartnerId;

        const shouldUpdateApplicationSubPartnerId =
            loggedIn &&
            activeApplicationId > 0 &&
            oldSubPartnerIdNumber !== subPartnerIdSessionNumber;

        if (shouldUpdateApplicationSubPartnerId) {
            yield put(
                ApplicationActions.updateSubpartnerId(
                    activeApplicationId,
                    subPartnerIdSessionNumber
                )
            );
        }
    } catch (error) {
        log({ tag: 'setting subPartnerId: ', error });
    }
}

export function updatePartner({ partner }: { partner: string }) {
    if (PARTNERS.includes(partner)) {
        sessionStorage.setItem('partner', partner);
    }
}

export function* updateLeadReferral({ leadReferralState, resolve, reject }) {
    try {
        const { data, ok } = yield call(
            apiClient.updateLeadReferral,
            leadReferralState
        );

        if (!ok) throw new Error(data.error);

        yield put(Actions.showLeadReferral(false, undefined));

        if (isFunction(resolve)) {
            resolve();
        }
    } catch (error) {
        log({ tag: 'update lead referral: ', error });
        if (isFunction(resolve)) {
            reject(error);
        }
    }
}

export function* fetchLeadReferral() {
    try {
        const { data, ok } = yield call(apiClient.getLeadReferral);
        if (!ok) throw new Error(data.error);

        if (data.elligible) {
            yield put(
                Actions.showLeadReferral(
                    true,
                    data?.quoteRequest?.interestLevel
                )
            );
        }
    } catch (error) {
        log({ tag: 'loading lead referral: ', error });
    }
}

export function updateUniPostalCodeFlow({
    uniPostalCodeState
}: {
    uniPostalCodeState: boolean;
}) {
    if (uniPostalCodeState) {
        sessionStorage.setItem('showUniPostalCode', 'show');
    }

    sessionStorage.setItem('showUniPostalCode', 'hide');
}
