import React from 'react';
import { connect } from 'react-redux';

import { isLoggedIn } from 'reducers/auth.selectors';
import { useTheme } from 'utils/hooks';
import { HeaderLoggedOut } from 'components/navigation/header-logged-out.component';
import { HeaderLoggedIn } from 'components/navigation/header-logged-in.component';
import { Wrapper } from './content-with-header.styles';

type Props = {
    children: React.ReactChild;
    hideLogout?: boolean;
    hideLogin?: boolean;
};

type StateProps = {
    isUserLoggedIn: boolean;
};

const LayoutView = ({
    children,
    isUserLoggedIn = false,
    hideLogout = false,
    hideLogin = false
}: Props & StateProps) => {
    const theme = useTheme();

    return (
        <Wrapper backgroundColor={theme.colors.alabaster}>
            {isUserLoggedIn ? (
                <HeaderLoggedIn hideLogout={hideLogout} />
            ) : (
                <HeaderLoggedOut hideLogin={hideLogin} />
            )}
            {children}
        </Wrapper>
    );
};

export const Layout = connect<StateProps>((state: any) => ({
    isUserLoggedIn: isLoggedIn(state)
}))(LayoutView) as React.FC<Props>;
