/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Toast, ToastProps } from './toast';
import { NOOP } from './utils';

type Props = {
    // TODO when we want to have custom Toast component
    // component: any;
};

type TimerType = {
    clear: typeof NOOP;
    pause: typeof NOOP;
    resume: typeof NOOP;
};

function Timer(callback: () => void, delay: number): TimerType {
    let timerId: NodeJS.Timeout;
    let start = delay;
    let remaining = delay;

    const clear = function() {
        clearTimeout(timerId);
    };

    const pause = function() {
        clearTimeout(timerId);
        remaining -= Date.now() - start;
    };

    const resume = function() {
        start = Date.now();
        clearTimeout(timerId);
        timerId = setTimeout(callback, remaining);
    };

    resume();

    return {
        clear,
        pause,
        resume
    };
}

export const ToastController = ({
    // component: Toast,
    autoDismiss = false,
    autoDismissTimeout,
    onDismiss,
    ...rest
}: Omit<ToastProps, 'onMouseEnter' | 'onMouseLeave' | 'isRunning'> & Props) => {
    const [isRunning, setIsrunning] = useState(false);

    let timeout: TimerType | undefined = undefined;

    const startTimer = () => {
        if (!autoDismiss) return;

        setIsrunning(true);

        timeout = Timer(onDismiss, autoDismissTimeout);
    };

    const clearTimer = () => {
        if (timeout) {
            timeout.clear();
        }
    };

    const onMouseEnter = autoDismiss
        ? () => {
              setIsrunning(false);
              if (timeout) {
                  timeout.pause();
              }
          }
        : NOOP;

    const onMouseLeave = autoDismiss
        ? () => {
              setIsrunning(true);
              if (timeout) {
                  timeout.resume();
              }
          }
        : NOOP;

    useEffect(() => {
        startTimer();

        return () => {
            clearTimer();
        };
    }, []);

    return (
        <Toast
            autoDismiss={autoDismiss}
            autoDismissTimeout={autoDismissTimeout}
            isRunning={isRunning}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onDismiss={onDismiss}
            {...rest}
        />
    );
};
