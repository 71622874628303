// Core and raf packages are necessary for older versions of IE
import 'core-js/features/map';
import 'core-js/features/set';
import 'core-js/features/weak-set';
import 'core-js/features/array';
import 'core-js/features/object';
import 'raf/polyfill';
import 'whatwg-fetch';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { TopRouting } from 'app-root/routing/top-routing';
import intl from 'intl';
import areIntlLocalesSupported from 'intl-locales-supported';

import { ldKey as key } from 'components/feature-flagger/helpers';

/* CSS RESET */
import 'sanitize.css/sanitize.css';

// Shared CSS
require('style/shared.css');

const supportedLocales = ['en', 'fr'];

// pretty sure the intl polyfills can all be removed
// Load intl pollyfill only if older browser (mostly Safari) does not support the EMCAScript Internationalization API
if (global.Intl) {
    // If intl exists but doesn't have the locales that we need
    if (!areIntlLocalesSupported(supportedLocales)) {
        const IntlPolyfill = intl;
        Intl.NumberFormat = IntlPolyfill.NumberFormat;
        Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
    }
} else {
    global.Intl = intl;
}

// Ensures app does not flicker due to flag changes at startup time.
(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: window.config.launchDarklyKey,
        // identify user later in Providers
        // frontend/src/components/feature-flagger/feature-flag-identify.component.tsx
        // kept the same key for anonymous and the user to have the same flags
        user: { key },
        options: {
            bootstrap: 'localStorage'
        },
        reactOptions: {
            // New version will camel case all the flags
            // keep old kebab case for backwards compatibility
            useCamelCaseFlagKeys: false
        }
    });

    ReactDOM.render(
        <LDProvider>
            <TopRouting />
        </LDProvider>,
        document.getElementById('app')
    );
})();
