import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducers as createRootReducer } from 'reducers';
import { history } from './routing/history';
import { sagas } from 'sagas';
import { configureStore } from '@reduxjs/toolkit';

const rootPersistConfig = {
    key: 'root',
    storage,
    whitelist: [] // persist no fields by in root level default
};

export const storeCreator = () => {
    const allowDevTools = process.env.NODE_ENV === 'development';
    const sagaMiddleware = createSagaMiddleware();

    const middleware = [sagaMiddleware, routerMiddleware(history)];

    const rootReducer = createRootReducer(history);
    const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

    const store = configureStore({
        reducer: persistedReducer,
        middleware: middleware,
        preloadedState: {},
        devTools: allowDevTools
    });

    const persistor = persistStore(store);

    sagaMiddleware.run(sagas);

    return { store, persistor };
};

export type AppDispatch = typeof store.dispatch;

export const { store, persistor } = storeCreator();
