import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { connect } from 'react-redux';
import { themes, configs, v2, getTheme } from '@nesto/themes';

import { persistor } from 'app-root/store';
import { DidomiProvider } from './DidomiProvider';
import { GlobalStyles } from './GlobalStyles';
import { PartnerProvider } from './PartnerProvider';
import { MobileHeightHack } from 'hocs/mobileHeightHack';
import { AppLoading } from 'components/app-loading';
import { ErrorHandler as AppErrorBoundary } from 'components/app-error-boundary';
import { FeatureFlagIdentify } from 'components/feature-flagger/feature-flag-identify.component';
import { getPartner, getSubPartnerId } from 'reducers/ui.selectors';
import { ConnectedIntlProvider } from 'app-root/providers/ConnectedIntlProvider';
import { userLocale } from 'utils/localization-utils';
import { Notifications } from 'components/notifications';
import { ConnectivityListener } from 'components/connectivity-listener';
import { BrowserTitle } from 'utils/browser-title';
import { ToastProvider } from 'components/toast';

const ProvidersView = ({ children, partner }) => (
    <ConnectedIntlProvider initialUserLocale={userLocale}>
        <PartnerProvider
            value={{
                partner,
                config: configs[partner] || configs.nesto
            }}
        >
            <ThemeProvider
                key="rootThemeProvider"
                theme={getTheme(
                    themes[partner] || themes.nesto,
                    v2.themes?.[partner]
                )}
            >
                <GlobalStyles>
                    <FeatureFlagIdentify />
                    <AppErrorBoundary>
                        <MobileHeightHack>
                            <PersistGate
                                loading={<AppLoading />}
                                persistor={persistor}
                            >
                                <ConnectivityListener />
                                <ToastProvider
                                    autoDismiss
                                    autoDismissTimeout={10000}
                                >
                                    <Notifications>
                                        <BrowserTitle>{children}</BrowserTitle>
                                        <DidomiProvider />
                                    </Notifications>
                                </ToastProvider>
                            </PersistGate>
                        </MobileHeightHack>
                    </AppErrorBoundary>
                </GlobalStyles>
            </ThemeProvider>
        </PartnerProvider>
    </ConnectedIntlProvider>
);

export const Providers = connect((state: any) => ({
    partner: getPartner(state),
    subPartnerId: getSubPartnerId(state)
}))(ProvidersView);
