import { withRouter } from 'react-router';
import { useScrollReset } from 'utils/hooks';

const RouterHooks = ({ location, children }) => {
    useScrollReset(location.key);

    return children;
};

export const ScrollReset = withRouter(RouterHooks as any);
