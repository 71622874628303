import React from 'react';
import * as Normalizers from 'lib/normalizers';

import { TextField, TextFieldProps } from './text-input.component';

type Props = {
    maxValue?: number;
};
const CurrencyField = ({ maxValue, ...rest }: TextFieldProps & Props) => (
    <TextField
        normalize={Normalizers.currencyField(maxValue || 9999999)}
        inputMode="numeric"
        format={Normalizers.chain([
            Normalizers.numberFieldNoDecimals,
            Normalizers.normalizeWithMax(maxValue || 9999999),
            Normalizers.normalizeMoney
        ])}
        {...rest}
    />
);

export { CurrencyField };
