import { AccountTypes } from './account.redux';
import { createReducer, createActions } from 'reduxsauce';
import { Property } from 'models/application/Application';

type ReduxState = any;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    createApplicationsFromPrequal: null,
    createApplicationsRequest: ['applicationType'],
    createApplicationsSuccess: ['applications'],
    createApplicationsFailure: ['error'],
    fetchApplicationsRequest: null,
    fetchApplicationsStart: null,
    fetchApplicationsSuccess: ['applications', 'servicingAssets'],
    fetchApplicationsFailure: ['error'],
    fetchApplicationSummary: null,
    fetchApplicationSummarySuccess: ['summary'],
    fetchApplicationSummaryError: ['error'],
    submitApplicationsRequest: null,
    submitApplicationsSuccess: null,
    submitApplicationsFailure: ['error'],
    setApplicantOtherIncomesSpecified: ['specified', 'resolve', 'reject'],
    setApplicantOtherPropertiesSpecified: ['specified', 'resolve', 'reject'],
    submitApplicantIncomes: ['applicationId', 'applicantId'],
    submitApplicantOtherIncome: ['resolve', 'reject'],
    deleteApplicantExtraIncome: ['extraIncomeId'],
    deleteApplicantExtraIncomeOld: ['extraIncomeId'],
    submitApplicantInformation: ['resolve', 'reject'],
    submitApplicantInformationSuccess: null,
    submitApplicantInformationFailure: ['error'],
    removeAddress: null,
    saveRegisteredAddress: ['resolve', 'reject'],
    saveRegisteredAddressStart: null,
    saveRegisteredAddressSuccess: null,
    saveRegisteredAddressFail: null,
    deleteRegisteredAddress: ['id'],
    registeredAddressSuccess: null,
    registeredAddressFailure: ['error'],
    updateTargetProperty: ['resolve', 'reject'],
    updateTargetPropertySuccess: null,
    updateTargetPropertyFailure: ['error'],
    updateRenewingProperty: ['resolve', 'reject'],
    updateRenewingPropertySuccess: null,
    updateRenewingPropertyFailure: ['error'],
    deleteOtherProperty: ['propertyId'],
    updateOtherProperties: ['resolve', 'reject'],
    createOtherProperty: ['address'],
    updateOtherProperty: ['address'],
    deleteEmployment: ['employmentId'],
    updateApplicationEmployments: ['selectedSituations'],
    updatePropertyAssets: null,
    updateAsset: ['resolve', 'reject'],
    deleteAsset: ['assetId'],
    saveBankingDetails: ['resolve', 'reject'],
    saveBankingDetailsStart: null,
    saveBankingDetailsSuccess: null,
    saveBankingDetailsFailure: null,
    submitApplicationFilogix: ['applicationId'],
    submitApplicationFilogixSuccess: null,
    submitApplicationFilogixFailure: ['error'],
    saveApplicationSuccess: null,
    setCurrentIncome: ['resolve', 'reject'],
    saveIncome: ['resolve', 'reject'],
    saveIncomeStart: null,
    saveIncomeSuccess: null,
    saveIncomeFail: null,
    saveEmployment: null,
    saveEmploymentSuccess: null,
    saveEmploymentFailure: ['error'],
    transitionToNextPage: ['url', 'sectionName', 'applicantId'],
    otherPropertiesTransitionToNextPage: ['isDownpaymentOutOfApplicant'],
    createCoapplicant: ['resolve', 'reject'],
    createCoapplicantSuccess: null,
    createCoapplicantFailure: ['error'],
    deleteCoapplicant: ['applicantId'],
    deleteCoapplicantSuccess: null,
    deleteCoapplicantFailure: ['error'],
    updateSubpartnerId: ['applicationId', 'subPartnerId'],
    // MLS upload
    uploadMlsSuccess: null,
    uploadMlsFail: ['error'],
    uploadMlsTargetInfo: ['formData', 'resolve', 'reject'],
    // Update Partner Manually
    searchRealtorAccounts: ['query'],
    searchRealtorAccountsFailure: ['error'],
    searchRealtorAccountsSuccess: null,
    manualAccountPartnerUpdate: ['formValues'],
    manualAccountPartnerUpdateSuccess: null,
    manualAccountPartnerUpdateFailure: null,
    deleteApplication: ['applicationId'],
    deleteApplicationSuccess: null,
    deleteApplicationFailure: ['error'],
    fetchApplicationAdvisor: null,
    setApplicationAdvisor: ['advisor'],
    updateSalesforceQueueComfortableSpeakingFrench: [
        'applicationId',
        'salesforceQueueComfortableSpeakingFrench'
    ],
    updateSalesforceApplicationSubmittedByAgent: [
        'applicationId',
        'salesforceApplicationSubmittedByAgent'
    ]
});

export { Creators as Actions };

export const ApplicationsTypes = Types;

export const INITIAL_STATE = {
    loading: false,
    applications: null,
    error: null,
    formData: null,
    moreProducts: [],
    data: {},
    properties: [],
    advisor: {}
};

// Transform array to object Indexed map by id
const transformApplicationStore = applications =>
    (applications || []).reduce(
        (acc, application) => ({
            ...acc,
            [application.id]: application
        }),
        {}
    );

const addApplicationState = (state: ReduxState, { applications }: any) => ({
    ...state,
    error: null,
    loading: false,
    applications: {
        ...state.applications,
        ...transformApplicationStore(applications)
    }
});

const setApplicationsState = (
    state: ReduxState,
    { applications, servicingAssets }: any
) => ({
    ...state,
    error: null,
    loading: false,
    servicingAssets: servicingAssets ?? state.servicingAssets,
    applications: {
        ...transformApplicationStore(applications)
    }
});

const setSuccessMLSTargetProperty = (
    state: ReduxState,
    { properties }: { properties: Property[] }
) => ({
    ...state,
    loading: false,
    properties,
    error: null
});

const setMLSTargetProperty = (state: ReduxState) => ({
    ...state,
    loading: true
});

const setErrorMLSTargetProperty = (state: ReduxState) => ({
    ...state,
    error: true,
    loading: false
});

const setApplicationsByRealtorRole = (
    state: ReduxState,
    { accountsByRealtorRole }: any
) => ({
    ...state,
    error: null,
    loading: false,
    accountsByRealtorRole
});

const setErrorState = (state: ReduxState, { error }: any) => ({
    ...state,
    loading: false,
    error
});

const setLoadedState = (state: ReduxState) => ({
    ...state,
    loading: false,
    error: null
});

const setLoadingState = (state: ReduxState) => ({
    ...state,
    loading: true,
    error: null
});

const fetchApplicationSummary = (state: ReduxState, { summary }: any) => ({
    ...state,
    error: null,
    loading: false,
    summary
});

const updateSalesforceApplicationSubmittedByAgent = (
    state: ReduxState,
    {
        applicationId,
        salesforceApplicationSubmittedByAgent
    }: { applicationId: number; salesforceApplicationSubmittedByAgent: boolean }
) => {
    return {
        ...state,
        applications: {
            ...state.applications,
            [applicationId]: {
                ...state.applications[applicationId],
                salesforceApplicationSubmittedByAgent
            }
        }
    };
};

const setApplicantComfortableSpeakingFrench = (
    state: ReduxState,
    {
        applicationId,
        salesforceQueueComfortableSpeakingFrench
    }: {
        applicationId: number;
        salesforceQueueComfortableSpeakingFrench: boolean;
    }
) => {
    return {
        ...state,
        applications: {
            ...state.applications,
            [applicationId]: {
                ...state.applications[applicationId],
                salesforceQueueComfortableSpeakingFrench
            }
        }
    };
};

const setApplicationAdvisor = (state: ReduxState, data: any) => ({
    ...state,
    advisor: data.advisor
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.CREATE_APPLICATIONS_REQUEST]: setLoadingState,
    [Types.CREATE_APPLICATIONS_SUCCESS]: addApplicationState,
    [Types.CREATE_APPLICATIONS_FAILURE]: setErrorState,
    [Types.FETCH_APPLICATIONS_REQUEST]: setLoadingState,
    [Types.FETCH_APPLICATIONS_START]: setLoadingState,
    [Types.FETCH_APPLICATIONS_SUCCESS]: setApplicationsState,
    [Types.FETCH_APPLICATIONS_FAILURE]: setErrorState,
    [Types.FETCH_APPLICATION_SUMMARY]: setLoadedState,
    [Types.FETCH_APPLICATION_SUMMARY_SUCCESS]: fetchApplicationSummary,
    [Types.FETCH_APPLICATION_SUMMARY_ERROR]: setErrorState,
    [Types.SUBMIT_APPLICATIONS_SUCCESS]: setLoadedState,
    [Types.SUBMIT_APPLICATIONS_FAILURE]: setErrorState,
    [Types.SUBMIT_APPLICANT_INCOMES]: setLoadingState,
    [Types.SUBMIT_APPLICANT_OTHER_INCOME]: setLoadingState,
    [Types.SUBMIT_APPLICANT_INFORMATION]: setLoadingState,
    [Types.SUBMIT_APPLICANT_INFORMATION_SUCCESS]: setLoadedState,
    [Types.SUBMIT_APPLICANT_INFORMATION_FAILURE]: setErrorState,
    [Types.SAVE_REGISTERED_ADDRESS]: setLoadingState,
    [Types.DELETE_REGISTERED_ADDRESS]: setLoadingState,
    [Types.REGISTERED_ADDRESS_SUCCESS]: setLoadedState,
    [Types.REGISTERED_ADDRESS_FAILURE]: setErrorState,
    [Types.UPDATE_TARGET_PROPERTY]: setErrorState,
    [Types.UPDATE_TARGET_PROPERTY_SUCCESS]: setLoadedState,
    [Types.UPDATE_TARGET_PROPERTY_FAILURE]: setErrorState,
    [Types.UPDATE_OTHER_PROPERTIES]: setLoadingState,
    [Types.UPDATE_OTHER_PROPERTY]: setLoadingState,
    [Types.CREATE_OTHER_PROPERTY]: setLoadingState,
    [Types.SAVE_BANKING_DETAILS]: setLoadingState,
    [Types.SAVE_BANKING_DETAILS_SUCCESS]: setLoadedState,
    [Types.SAVE_BANKING_DETAILS_FAILURE]: setErrorState,
    [Types.SUBMIT_APPLICATIONS_REQUEST]: setLoadingState,
    [Types.SUBMIT_APPLICATIONS_FAILURE]: setErrorState,
    [Types.SUBMIT_APPLICATION_FILOGIX]: setLoadingState,
    [Types.SUBMIT_APPLICATION_FILOGIX_SUCCESS]: setLoadedState,
    [Types.SUBMIT_APPLICATION_FILOGIX_FAILURE]: setErrorState,
    [Types.SAVE_EMPLOYMENT]: setLoadingState,
    [Types.SAVE_EMPLOYMENT_SUCCESS]: setLoadedState,
    [Types.SAVE_EMPLOYMENT_FAILURE]: setErrorState,
    [Types.SAVE_INCOME]: setLoadingState,
    [Types.SAVE_INCOME_SUCCESS]: setLoadedState,
    [Types.SAVE_INCOME_FAIL]: setErrorState,
    [Types.CREATE_COAPPLICANT]: setLoadingState,
    [Types.CREATE_COAPPLICANT_SUCCESS]: setLoadedState,
    [Types.CREATE_COAPPLICANT_FAILURE]: setErrorState,
    [Types.DELETE_COAPPLICANT]: setLoadingState,
    [Types.DELETE_COAPPLICANT_SUCCESS]: setLoadedState,
    [Types.DELETE_COAPPLICANT_FAILURE]: setErrorState,
    [Types.UPLOAD_MLS_TARGET_INFO]: setMLSTargetProperty,
    [Types.UPLOAD_MLS_SUCCESS]: setSuccessMLSTargetProperty,
    [Types.UPLOAD_MLS_FAIL]: setErrorMLSTargetProperty,
    [Types.SEARCH_REALTOR_ACCOUNTS]: setLoadingState,
    [Types.SEARCH_REALTOR_ACCOUNTS_FAILURE]: setErrorState,
    [Types.SEARCH_REALTOR_ACCOUNTS_SUCCESS]: setApplicationsByRealtorRole,
    [Types.MANUAL_ACCOUNT_PARTNER_UPDATE_SUCCESS]: setLoadedState,
    [Types.MANUAL_ACCOUNT_PARTNER_UPDATE_FAILURE]: setErrorState,
    [Types.MANUAL_ACCOUNT_PARTNER_UPDATE]: setLoadingState,
    [Types.DELETE_APPLICATION]: setLoadingState,
    [Types.DELETE_APPLICATION_SUCCESS]: setLoadedState,
    [Types.DELETE_APPLICATION_FAILURE]: setErrorState,
    [Types.UPDATE_SALESFORCE_APPLICATION_SUBMITTED_BY_AGENT]: updateSalesforceApplicationSubmittedByAgent,
    [Types.UPDATE_SALESFORCE_QUEUE_COMFORTABLE_SPEAKING_FRENCH]: setApplicantComfortableSpeakingFrench,
    [Types.SET_APPLICATION_ADVISOR]: setApplicationAdvisor,

    [AccountTypes.LOGOUT]: () => INITIAL_STATE
});
