import React from 'react';
import { connect } from 'react-redux';
import { Flex } from 'reflexbox/styled-components';

import {
    Actions as SidebarActions,
    ApplicationProgressBarProps
} from 'reducers/ui.redux';
import { Actions as AccountActions } from 'reducers/account.redux';
import { getApplicationProgressInfo } from 'reducers/ui.selectors';
import { SideBar } from 'components/sidebar/sidebar.component';
import { BrokerBanner } from 'components/broker-banner/broker-banner.component';
import { Header } from 'components/layout/mobile-header.component';
import { Layout as ContentWithSidebar } from 'layouts/content-with-sidebar';
import { SidebarFooter } from 'components/sidebar/components/sidebar-footer.component';
import { isBroker } from 'reducers/account.selectors';
import { StatusProgressBarMobile } from 'components/status-progress-bar/status-progress-bar-mobile.component';

type Props = {
    children: React.ReactChild;
};

type StateProps = {
    applicationProgressInfo: ApplicationProgressBarProps | undefined;
    isBroker: boolean;
};

type DispatchProps = {
    logout: () => void;
    toggleSidebar: () => void;
};

const LayoutView = ({
    children,
    toggleSidebar,
    applicationProgressInfo,
    isBroker,
    logout
}: Props & StateProps & DispatchProps) => (
    <ContentWithSidebar
        useNavLine
        sidebar={
            <Flex flexDirection="column" flex={1}>
                <SideBar />
                <Flex flex={1} flexDirection="column" mx={4} mt={'32px'}>
                    <SidebarFooter
                        logout={logout}
                        toggleSidebar={toggleSidebar}
                    />
                </Flex>
            </Flex>
        }
        header={
            <Header>
                {!!applicationProgressInfo && (
                    <StatusProgressBarMobile
                        numOfSteps={applicationProgressInfo.numOfSteps}
                        currentStepIndex={
                            applicationProgressInfo.currentStepIndex
                        }
                        section={applicationProgressInfo.section}
                        userName={applicationProgressInfo.userName}
                    />
                )}
            </Header>
        }
        content={
            <>
                {isBroker && <BrokerBanner />}
                {children}
            </>
        }
    />
);

export const Layout = connect<StateProps, DispatchProps>(
    (state: any) => ({
        applicationProgressInfo: getApplicationProgressInfo(state),
        isBroker: isBroker(state)
    }),
    {
        logout: AccountActions.logout,
        toggleSidebar: () => SidebarActions.updateSidebar()
    }
)(LayoutView) as React.FC<Props>;
