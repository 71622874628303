import React from 'react';
import { withSizesHoc, WithSizesProps } from 'hocs/with-sizes.hoc';

type RenderCallback = (args: WithSizesProps) => any;

type Props = {
    children: RenderCallback | React.ReactNode;
};

const MobileView = ({ children, isMobile, ...rest }: Props & WithSizesProps) =>
    typeof children === 'function'
        ? children({ isMobile, ...rest })
        : isMobile
        ? children
        : null;

export const Mobile = withSizesHoc(MobileView) as React.FC;
