import React, { ChangeEvent } from 'react';
import { Box } from 'reflexbox/styled-components';
import { Field, Validator, BaseFieldProps } from 'redux-form';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { WidthProps, SpaceProps } from 'styled-system';
import styled from 'styled-components/macro';

import { Select } from 'components/inputs/select';
import * as Validators from 'lib/validators';

type Props = {
    placeholder?: string;
    id?: string;
    dataTestId?: string;
    isRequired?: boolean;
    disabled?: boolean;
    validate?: Validator[];
    options: object[];
    showScrollBar?: boolean;
    sort?: boolean;
    translate?: boolean;
    preset?: string;
    stylesOverrides?: any;
    useClassStyle?: boolean;
    menuPlacement?: 'top' | 'auto';
    icon?: {
        src: string;
        width: number;
        height: number;
    };
    onChange?: (
        event?: ChangeEvent<any>,
        newValue?: any,
        previousValue?: any
    ) => void;
    onSubmit?: (value: any) => void;
    setPlaceholderInnerHtml?: true;
    showErrorOnTouched?: boolean;
    readOnly?: boolean;
    frOptionalLabelGender?: 'masculine' | 'feminine';
};

type StyledProps = WidthProps & SpaceProps;

const noOp = () => undefined;

const Wrapper = styled(Box)`
    width: 100%;
`;

const SelectFieldView = ({
    placeholder,
    name,
    dataTestId,
    validate = [noOp],
    intl,
    id = '',
    isRequired = false,
    showScrollBar = false,
    options,
    sort,
    translate = true,
    menuPlacement = 'auto',
    stylesOverrides,
    useClassStyle,
    icon,
    preset,
    onChange,
    setPlaceholderInnerHtml,
    disabled = false,
    onSubmit,
    showErrorOnTouched = false,
    readOnly,
    frOptionalLabelGender = 'feminine',
    ...rest
}: Props & WrappedComponentProps & BaseFieldProps & StyledProps) => (
    <Wrapper mt={3} {...rest}>
        <Field
            component={Select as any}
            name={name}
            dataTestId={dataTestId}
            data-test-id={id ? id : name}
            placeholder={
                placeholder
                    ? setPlaceholderInnerHtml
                        ? intl.formatMessage({
                              id: placeholder
                          })
                        : intl.formatMessage({
                              id: placeholder
                          })
                    : ''
            }
            options={options}
            showScrollBar
            isRequired={isRequired}
            validate={[isRequired ? Validators.isRequired : noOp, ...validate]}
            locale={intl.locale}
            sort={sort}
            translate={translate}
            menuPlacement={menuPlacement}
            stylesOverrides={stylesOverrides}
            useClassStyle={useClassStyle}
            icon={icon}
            preset={preset}
            onChange={onChange}
            setPlaceholderInnerHtml={setPlaceholderInnerHtml}
            disabled={disabled}
            onSubmit={onSubmit}
            showErrorOnTouched={showErrorOnTouched}
            readOnly={readOnly}
            frOptionalLabelGender={frOptionalLabelGender}
        />
    </Wrapper>
);

export const SelectField = injectIntl(SelectFieldView);
