import { isViableDownPayment as getIsViableDownpayment } from 'utils/down-payment-helpers';
import { SubmissionError } from 'redux-form';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { call, put, select, all } from 'redux-saga/effects';
import { LocationDescriptorObject } from 'history';

import { Asset, AssetProperty } from 'models/application/application-assets';
import { getParamId, getAddressRealFields } from 'utils/address-helpers';
import { log } from 'utils/logging';
import { ApplicationsTypes, Actions } from 'reducers/application.redux';
import { Account, Actions as AccountActions } from 'reducers/account.redux';
import {
    errorNotification,
    successNotification
} from 'reducers/notifications.redux';
import {
    getExistingPropertyAssets,
    getAllOtherPropertyAssets,
    getApplicantInfoFormData,
    getApplicantOtherIncomeFormData,
    getApplicationId,
    getRegisteredAddressFormData,
    getTargetPropertyFormData,
    getCurrentApplicationApplicantId,
    getIsMainApplicant,
    getAssetsDownpaymentsFormData,
    getOtherPropertiesFormData,
    getRenewingPropertyFormData,
    getEmploymentsFormData,
    getMainApplicantId,
    getCoapplicantDetails,
    getCurrentApplicationType,
    isApplicationSubmitted,
    getTargetProperty,
    getDownPaymentAmount,
    getCurrentApplicantInfo,
    getBankingDetailsFormData,
    getOtherPropertyTransitionNextPageParams,
    getActiveApplication,
    getActiveApplicationId,
    getEmployments,
    getAssetById,
    isApplicationCreated,
    getApplicationSubPartnerId,
    getRegisteredAddresses,
    getFirstCallBooked
} from 'reducers/application.selectors';
import { apiClient } from 'services/api';
import {
    RegisteredAddress,
    OtherProperty
} from 'models/application/Application';
import { Actions as DashboardActions } from 'reducers/dashboard.redux';
import { Actions as DocumentsActions } from 'reducers/documents.redux';
import { SessionActions } from 'reducers/session.redux';
import { Actions as UIActions } from 'reducers/ui.redux';
import { Actions as ApplicationsStateActions } from 'reducers/applications-state.redux';
import { getIsApplicantIncomeComplete } from 'reducers/sidebar.selectors';
import { getFeatureFlagState } from 'components/feature-flagger/hooks';
import { getAsNumber } from 'lib/normalizers';
import { isFunction } from 'utils/helpers';
import {
    getIsConsentNeeded,
    getIsLeadReferralNeeded
} from 'reducers/ui.selectors';
import { fetchLeadReferral } from 'sagas/ui.sagas';
import { getAccount, isBehalfAUser } from 'reducers/account.selectors';
import { Routes } from 'app-root/routing/routes';
import { getSubPartnerIdFallback } from 'utils/partner-util';
import { getRedirectUrl } from 'utils/application';
import { analyticEvent } from './analytics-events.sagas';

type PromisifyAction = {
    resolve: (value?: any | PromiseLike<any>) => void;
    reject: (reason?: any) => void;
};

export function* createApplication({ applicationType }) {
    try {
        // Always assign partner id from what we have in session
        // We dont want to pay lead if the client come back N month later
        // direct to nesto.
        // only app created from incoming partner invite and partner link utm_*
        // will be linked to realtor
        const account = yield select(getAccount);
        const sessionSubPartnerId =
            sessionStorage.getItem('subPartnerId') ||
            getSubPartnerIdFallback(account.partner);

        const { data, ok } = yield call(
            apiClient.createApplication,
            applicationType,
            +sessionSubPartnerId
        );

        if (!ok) {
            throw new Error(data.error);
        }

        // GA-4
        yield put(
            analyticEvent('start_application', {
                info: 'continue my application',
                type: applicationType,
                event_location_category: 'application'
            })
        );

        yield put({
            type: ApplicationsTypes.CREATE_APPLICATIONS_SUCCESS,
            applications: [data]
        });

        const isProprioDirectReferral = yield getFeatureFlagState(
            'proprioDirect-lead-referral'
        );

        if (isProprioDirectReferral) {
            yield call(fetchLeadReferral);
        }

        const isLeadReferralNeeded = yield select(getIsLeadReferralNeeded);

        // if data referral is eligible
        // we redirect to lead-referral page
        if (isLeadReferralNeeded) {
            yield put(push(Routes.applicationLeadReferral));
        }

        // select newly created App
        // Call after app success because we want to have the applications int the state
        // when setting the active app, because `onSetActiveApplicationId` need the selected app data
        if (data?.id) {
            yield put(SessionActions.setActiveApplicationId(data.id));
        }

        // If we have an realtorId save for this account we need to update the app
        // and clear that account/tracking/subpartnerid

        const mainApplicantId = yield select(getMainApplicantId);

        const isConsentNeeded = yield select(getIsConsentNeeded);
        if (isConsentNeeded) {
            // redirect to Commision Consent if partner === Proprio Direct
            // and no SubPartnerID
            yield put(push(Routes.applicationCommissionConsent));
        } else {
            // redirect to identification of main applicant
            yield put(
                push(
                    `/application/applicant-info?applicant=${mainApplicantId}&sectionName=application.applicantInformation`
                )
            );
        }
    } catch (error) {
        yield put({
            type: ApplicationsTypes.CREATE_APPLICATIONS_FAILURE,
            error
        });
        yield put(
            errorNotification({
                text: 'application.dataUpdatedFailure'
            })
        );
    }
}

export function* onFetchApplicationsRequest() {
    try {
        yield put(Actions.fetchApplicationsStart());

        const { data, ok } = yield call(apiClient.getApplications);
        if (!ok) throw new Error(data.error);

        let { data: servicingAssets, ok: servicingAssetsOk } = yield call(
            apiClient.getServicingAssets
        );
        if (!servicingAssetsOk) {
            // We are failing silently here on purpose. Servicing asset fetch will fail
            // when logged in on behalf because represented requests are blocked on both servicing gateways
            // When logged in on behalf of a client that has a mortgage serviced by nesto, clicking
            // any of the associated applications from the selector will result in undefined behavior.
            servicingAssets = null;
        }

        yield put(
            Actions.fetchApplicationsSuccess(
                data.length ? data : null,
                servicingAssets?.length ? servicingAssets : null
            )
        );
    } catch (error) {
        yield put(Actions.fetchApplicationsFailure(error));
    }
}

export function* onFetchApplicationSummary() {
    try {
        const applicationId = yield select(getApplicationId);

        const { data, ok } = yield call(
            apiClient.getApplicationSummary,
            applicationId
        );

        if (!ok) throw new Error(data.error);

        yield put(Actions.fetchApplicationSummarySuccess(data));
    } catch (error) {
        yield put(Actions.fetchApplicationsFailure(error));
    }
}

export function* onFetchApplicationAdvisor() {
    try {
        const applicationId = yield select(getActiveApplicationId);

        // we don't want to call
        // `/api/applications/undefined/contact-information`
        if (!applicationId) return;

        const { data, ok } = yield call(
            apiClient.getApplicationAdvisor,
            applicationId
        );

        if (!ok) throw new Error(data.error);
        yield put(Actions.setApplicationAdvisor(data));
    } catch (error) {
        console.log('error', error);
        // yield put(Actions.fetchApplicationsFailure(error));
    }
}

export function* onFetchApplicationsSuccess({ applications }) {
    const activeApplicationId = yield select(getActiveApplicationId);

    if (applications && activeApplicationId) {
        yield put(DocumentsActions.documentsCountsRequest());

        const advisorBooked = yield select(getFirstCallBooked);

        if (advisorBooked === undefined) {
            yield put(
                ApplicationsStateActions.setAdvisorBooked(activeApplicationId)
            );
        }
    }
    yield put(DashboardActions.fetchDashboardRequest());
}

function* applicationSubmitSuccess(redirect = false) {
    try {
        if (redirect) {
            yield put(
                push(
                    '/application/documents?step=1&sectionName=application.documents',
                    {
                        showSubmitted: true
                    }
                )
            );
        }

        yield put(Actions.submitApplicationsSuccess());
        yield call(onFetchApplicationsRequest);
        yield put(
            successNotification({
                text: 'application.dataUpdatedSuccess'
            })
        );
    } catch (error) {
        throw Error(error);
    }
}

function* transitionToApplicationPage(url: any, sectionName: string) {
    try {
        if (url) {
            const applicantId = yield select(getCurrentApplicationApplicantId);

            yield put(
                push({
                    pathname: `/application/${url}`,
                    search: `?applicant=${applicantId || ''}${
                        sectionName ? `&sectionName=${sectionName}` : ''
                    }`
                })
            );
        }
    } catch (error) {
        throw Error(error);
    }
}

export function* getAccountsByRealtorRole({ query }) {
    try {
        const { data, ok } = yield call(
            apiClient.getAccountsByRealtorRole,
            query as string
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: ApplicationsTypes.SEARCH_REALTOR_ACCOUNTS_SUCCESS,
            accountsByRealtorRole: Object.values(data.results)
        });
    } catch (error) {
        yield put({
            type: ApplicationsTypes.SEARCH_REALTOR_ACCOUNTS_FAILURE,
            error
        });
    }
}
export function* updateAccountWithPartnerAndSubPartner({ formValues }) {
    const applicationId = yield select(getActiveApplicationId);
    const account = yield select(getAccount);

    const subPartnerId = formValues.selectedRealtorAccount;
    const partner = formValues.selectedPartner;

    try {
        const accountPayload = {
            ...account,
            partner,
            subPartnerId,
            partnerSpecified: true,
            subPartnerIdSpecified: true
        };

        const { updateApplication, updateAccount } = yield all({
            updateAccount: call(apiClient.updateAccount, accountPayload),
            updateApplication: call(
                apiClient.updateApplicationSubPartner,
                applicationId,
                subPartnerId
            )
        });

        if (updateAccount.ok && updateApplication.ok) {
            yield call(onFetchApplicationsRequest);
            yield put({
                type: ApplicationsTypes.MANUAL_ACCOUNT_PARTNER_UPDATE_SUCCESS
            });
            yield put(
                successNotification({
                    text: 'application.manualPartnerUpdateSuccess'
                })
            );
        }
        if (!updateApplication.ok || !updateAccount.ok) {
            yield put(
                errorNotification({
                    text: 'application.manualPartnerUpdateError'
                })
            );
        }
    } catch (error) {
        yield put({
            type: ApplicationsTypes.MANUAL_ACCOUNT_PARTNER_UPDATE_FAILURE,
            error
        });
    }
}

function* applicationSubmitFailure(error: any) {
    try {
        log({ error });
        yield put({
            type: ApplicationsTypes.SUBMIT_APPLICATIONS_FAILURE
        });
        yield put(
            errorNotification({
                text: 'application.dataUpdatedFailure'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* submitApplicationFilogix() {
    try {
        const applicationId = yield select(getActiveApplicationId);

        const { data, ok } = yield call(
            apiClient.updateApplicationState,
            applicationId,
            'SUBMIT_TO_LENDERS_DATA_EXCHANGE'
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield call(applicationSubmitSuccess, true);
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

export function* submitApplicantInformation({
    resolve,
    reject,
    primaryBankingInstitution,
    primaryBankingInstitutionOther
}: {
    primaryBankingInstitution?: string;
    primaryBankingInstitutionOther?: string;
} & PromisifyAction) {
    try {
        const currentApplicantInfo = yield select(getCurrentApplicantInfo);
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);
        const applicantInfoFormData = yield select(getApplicantInfoFormData);
        const accountId = yield select(getCurrentApplicationApplicantId);
        const isUserMainApplicant = yield select(getIsMainApplicant, accountId);

        let applicantInfoData = {
            ...currentApplicantInfo,
            ...applicantInfoFormData
        };

        if (primaryBankingInstitution) {
            applicantInfoData = {
                ...applicantInfoData,
                primaryBankingInstitution,
                primaryBankingInstitutionOther: undefined // Reset the other institution
            };
        }

        if (primaryBankingInstitutionOther) {
            applicantInfoData = {
                ...applicantInfoData,
                primaryBankingInstitutionOther
            };
        }

        const params = {
            ...applicantInfoData,
            email: applicantInfoData.email.trim(),
            firstName: applicantInfoData.firstName.trim(),
            lastName: applicantInfoData.lastName.trim(),
            phone: applicantInfoData.phone.trim(),
            permissions: isUserMainApplicant
                ? 'MAIN_APPLICANT'
                : 'CO_APPLICANT_DEFAULT',
            applicantId,
            relationToMainApplicant:
                applicantInfoData.relationToMainApplicant || '',
            firstTimeHomeBuyer: applicantInfoData.firstTimeHomeBuyer === true
        };

        const { data, ok } = yield call(
            apiClient.updateApplicantInfo,
            applicationId,
            accountId,
            params
        );

        if (!ok) throw new Error(data.error);

        yield call(applicationSubmitSuccess);

        resolve();
    } catch (error) {
        yield call(applicationSubmitFailure, error);

        reject(
            new SubmissionError({
                _error: 'Save Applicant Information Failed!'
            })
        );
    }
    return;
}

type ExtraIncome = {
    id?: string;
    description: string;
    type: [
        'INVESTMENT_INCOME' | 'CHILD_SUPPORT' | 'ALIMONY' | 'PENSION' | 'OTHER'
    ];
    income: {
        amount: number;
        frequency: string;
    };
};

export function* createOtherIncomes(extraIncome: ExtraIncome) {
    const applicationId = yield select(getApplicationId);
    const accountId = yield select(getCurrentApplicationApplicantId);

    return yield call(
        apiClient.createApplicantExtraIncome,
        applicationId,
        accountId,
        extraIncome
    );
}

export function* updateOtherIncomes(extraIncome: ExtraIncome) {
    const applicationId = yield select(getApplicationId);
    const accountId = yield select(getCurrentApplicationApplicantId);

    return yield call(
        apiClient.updateApplicantExtraIncome,
        applicationId,
        accountId,
        extraIncome.id as string,
        extraIncome
    );
}

export function* removeAddress(action: RegisteredAddress) {
    try {
        const { id } = action;
        const applicationId = yield select(getApplicationId);
        const accountId = yield select(getCurrentApplicationApplicantId);

        const { data, ok } = yield call(
            apiClient.removeRegisteredAddress,
            applicationId,
            accountId,
            id as number
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: ApplicationsTypes.REGISTERED_ADDRESS_SUCCESS,
            data
        });
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

export function* deleteApplicantOtherIncomes({
    extraIncomeId
}: {
    extraIncomeId: string;
}) {
    try {
        const applicationId = yield select(getApplicationId);
        const accountId = yield select(getCurrentApplicationApplicantId);
        const { data, ok } = yield call(
            apiClient.deleteApplicantExtraIncome,
            applicationId,
            accountId,
            extraIncomeId
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: ApplicationsTypes.SAVE_APPLICATION_SUCCESS,
            data
        });

        yield call(onFetchApplicationsRequest);
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

export function* updateApplicantOtherIncomes({
    resolve,
    reject
}: PromisifyAction) {
    try {
        const formData = yield select(getApplicantOtherIncomeFormData);

        if (formData.id) {
            yield call(updateOtherIncomes, formData);
        } else {
            yield call(createOtherIncomes, formData);
        }

        yield call(applicationSubmitSuccess);

        resolve();
    } catch (error) {
        yield call(applicationSubmitFailure, error);
        reject(
            new SubmissionError({
                _error: 'Save Applicant Other Income Failed!'
            })
        );
    }
}

export function* deleteRegisteredAddress({ id }) {
    try {
        const applicationId = yield select(getActiveApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);

        let nextCurrentAddress: RegisteredAddress | undefined = undefined;
        // If deleting the first of the list, set the next one as current address
        const addresses = yield select(getRegisteredAddresses, applicantId);
        if (addresses.length >= 2 && addresses[0].id === id) {
            nextCurrentAddress = addresses[1];
        }

        yield call(
            apiClient.removeRegisteredAddress,
            applicationId,
            applicantId,
            id
        );

        if (nextCurrentAddress) {
            // Set next address as current and save
            nextCurrentAddress.isCurrentAddress = true;
            yield call(
                updateRegisteredAddress,
                nextCurrentAddress,
                applicationId,
                applicantId
            );
        }

        yield call(onFetchApplicationsRequest);
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

function* createRegisteredAddress(
    registeredAddress: RegisteredAddress,
    applicationId: number,
    applicantId: number
) {
    const {
        situation,
        occupiedYears,
        occupiedMonths,
        address,
        rent
    } = registeredAddress;

    const params = {
        situation,
        occupiedYears: occupiedYears ? parseInt(`${occupiedYears}`, 10) : 0,
        occupiedMonths: occupiedMonths ? parseInt(`${occupiedMonths}`, 10) : 0,
        isCurrentAddress: registeredAddress.isCurrentAddress,
        address: {
            ...address,
            addressLine: `${address.streetNumber} ${address.street}`
        },
        rent
    };

    const { data, ok } = yield call(
        apiClient.createRegisteredAddress,
        applicationId,
        applicantId,
        params as any
    );

    if (!ok) {
        throw new Error(data.error);
    }

    yield put({
        type: ApplicationsTypes.REGISTERED_ADDRESS_SUCCESS,
        data
    });
}

function* updateRegisteredAddress(
    registeredAddress: RegisteredAddress,
    applicationId: number,
    applicantId: number
) {
    const {
        id,
        situation,
        occupiedYears,
        occupiedMonths,
        address,
        rent
    } = registeredAddress;

    const params = {
        id,
        situation,
        occupiedYears: occupiedYears ? +occupiedYears : 0,
        occupiedMonths: occupiedMonths ? +occupiedMonths : 0,
        isCurrentAddress: registeredAddress.isCurrentAddress,
        address: {
            ...address,
            addressLine: `${address.streetNumber} ${address.street}`
        },
        rent: situation === 'RENTING' ? rent : undefined
    };

    const { data, ok } = yield call(
        apiClient.updateRegisteredAddress,
        applicationId,
        applicantId,
        id as number,
        params as any
    );

    if (!ok) {
        // TODO: This is an AWFUL hack. We need to implement an proper Error throwing system because
        // throw new Error(obj) doesn't work with parameters other than 'name' or 'message' in the object.
        throw data;
    }

    yield put({
        type: ApplicationsTypes.REGISTERED_ADDRESS_SUCCESS,
        data
    });
}

export function* saveRegisteredAddress({ resolve, reject }) {
    try {
        yield put(Actions.saveRegisteredAddressStart());

        const applicationId = yield select(getActiveApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);
        let address = yield select(getRegisteredAddressFormData);
        address = {
            ...address,
            address: getAddressRealFields(address.address)
        };

        // If editing the first of the list, set it as current address
        // If no existing address and creating the first one, set it as current address
        const addresses = yield select(getRegisteredAddresses, applicantId);
        if (addresses.length) {
            if (addresses[0].id === address.id) {
                address.isCurrentAddress = true;
            }
        } else {
            address.isCurrentAddress = true;
        }

        if (address.id) {
            yield call(
                updateRegisteredAddress,
                address,
                applicationId,
                applicantId
            );
        } else {
            yield call(
                createRegisteredAddress,
                address,
                applicationId,
                applicantId
            );
        }

        yield call(onFetchApplicationsRequest); // TODO: move this outta here
        yield put(Actions.saveRegisteredAddressSuccess());

        resolve();
    } catch ({ error, parameters }) {
        yield call(applicationSubmitFailure, error); // TODO: move this outta here
        yield put(Actions.saveRegisteredAddressFail());
        const formError = parameters.reduce(
            (prev, curr) => ({ ...prev, [curr]: `api.errors.${curr}` }),
            {}
        );
        reject(new SubmissionError(formError));
    }
}

const getNotFoundPropertyData = ({
    isFound,
    purchasePrice,
    acceptanceDate,
    propertyType,
    purpose,
    rentalIncome,
    address
}: any) => ({
    isFound,
    purchasePrice,
    acceptanceDate: `${acceptanceDate.slice(0, -2)}01`, // force first of the month when property not found
    propertyType,
    purpose,
    rentalIncome: purpose === 'OWNER_OCCUPIED' ? undefined : rentalIncome,
    address: {
        stateCode: address.noAutoFill
            ? address.section
                ? address[`${address.section}_sC`]
                : address.sC
            : address.stateCode,
        countryCode: address.countryCode
    }
});

const getPropertyData = ({
    garage,
    mlsListingNumber,
    livingSpace,
    lotSize,
    ...formData
}: any) => ({
    ...formData,
    garage: garage.present ? garage : { present: garage.present },
    mlsListingNumber: +mlsListingNumber,
    // Need to cast decimal value to number
    // It may finish with `.` like `111.` -> `111`
    livingSpace: {
        ...livingSpace,
        amount: getAsNumber(livingSpace?.amount)
    },
    lotSize: {
        ...lotSize,
        amount: getAsNumber(lotSize?.amount)
    },
    // add amount and frequency since backend require full object
    coOwnershipFees: {
        ...formData.coOwnershipFees,
        amount: formData.coOwnershipFees.amount ?? null,
        frequency: formData.coOwnershipFees.frequency ?? ''
    },
    // required when coownership fees are empty and heating included in coownership fees is true
    heatingCost: {
        amount: formData.heatingCost?.amount ?? null,
        frequency: formData.heatingCost?.frequency ?? ''
    }
});

// update target property on type === NEW
export function* updateTargetProperty({ resolve, reject }: PromisifyAction) {
    try {
        const applicationId = yield select(getApplicationId);
        const formData = yield select(getTargetPropertyFormData);

        const payload = formData.isFound
            ? getPropertyData(formData)
            : getNotFoundPropertyData(formData);

        const { data, ok } = yield call(
            apiClient.updateTargetProperty,
            applicationId,
            { ...payload, address: getAddressRealFields(payload.address) }
        );

        if (!ok) {
            throw new Error(data.error);
        }

        let pageUrl = 'assets-downpayment';

        let pageParam = 'application.assetsDownPayment';

        const mainApplicantID = yield select(getMainApplicantId);
        const isApplicantIncomeComplete = yield select(
            getIsApplicantIncomeComplete,
            mainApplicantID
        );

        // README code below could technically be remove
        // but if you remove that you'll go to a circular dependencies nightmare
        // and in hell at the same time.
        const propertyValue = payload.purchasePrice;
        const downPaymentAmount = yield select(getDownPaymentAmount);
        const isRental = payload.purpose === 'RENTAL';

        // eslint-disable-next-line
        const isViableDownpayment = getIsViableDownpayment(
            propertyValue,
            downPaymentAmount,
            isRental
        );
        // END of hell 👿

        if (!isApplicantIncomeComplete) {
            pageUrl = 'employment-situation';
            pageParam = 'application.employmentSituation&showRateModal=1';
        }

        yield call(applicationSubmitSuccess);

        yield call(transitionToApplicationPage, pageUrl, pageParam);
        resolve();
    } catch (error) {
        yield call(applicationSubmitFailure, error);
        reject(error);
    }
}

export function* createOtherProperty(address: OtherProperty) {
    const applicationId = yield select(getApplicationId);
    const applicantId = yield select(getCurrentApplicationApplicantId);

    return yield apiClient.createOtherProperty(
        applicationId,
        address as any,
        applicantId
    );
}

export function* uploadMLSTargetProperty({ formData, resolve, reject }) {
    try {
        const { data, ok } = yield apiClient.uploadMLSTargetProperty(formData);
        if (!ok) {
            throw new Error(data.error);
        }
        if (data.status !== 'OK') {
            if (isFunction(reject)) {
                reject(data.status);
            }
        }
        yield put({
            type: ApplicationsTypes.UPLOAD_MLS_SUCCESS,
            properties: data.properties
        });

        if (isFunction(resolve)) {
            resolve(data.properties);
        }
    } catch (error) {
        yield put({
            type: ApplicationsTypes.UPLOAD_MLS_FAIL
        });

        yield put(
            errorNotification({
                title: 'toasts.failMLSUpload',
                text: 'toasts.unknownPDFFormat'
            })
        );
    }
}

export function* deleteOtherProperty({ propertyId }: { propertyId: string }) {
    try {
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);

        const { data, ok } = yield call(
            apiClient.deleteOtherProperty,
            applicationId,
            propertyId,
            applicantId
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: ApplicationsTypes.SAVE_APPLICATION_SUCCESS,
            data
        });
        yield call(onFetchApplicationsRequest);
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

// this function is to omit properties based on conditional fields
// Stacker doesn't remove hidden in the form data object
// we need to manually remove them
function cleanOwnedPropertyData(formData) {
    const isSold = R.propEq('afterTransactionPurpose', 'SOLD');
    const sold = R.omit([
        'remove',
        'rentalIncome',
        'propertyType',
        'heatingIncluded',
        'condoFees',
        'estimatedValue',
        'annualTaxes',
        'annualTaxesYear'
    ]);

    const isRental = R.propSatisfies(
        afterTransactionPurpose =>
            afterTransactionPurpose === 'OWNER_OCCUPIED_AND_RENTAL' ||
            afterTransactionPurpose === 'RENTAL',
        'afterTransactionPurpose'
    );

    const isCondo = R.propSatisfies(type => type === 'CONDO', 'type');

    const notSold = R.pipe(
        // If not sold alway remove this three property
        R.omit(['currentSaleStatus', 'purchasePrice', 'sellingDate']),
        // if NOT rental remove rentalIncome from payload
        R.ifElse(isRental, data => data, R.omit(['rentalIncome'])),
        // if NOT condo remove heatingIncluded from payload
        R.ifElse(isCondo, data => data, R.omit(['heatingIncluded']))
    );

    return R.ifElse(isSold, sold, notSold)(formData);
}

export function* updateOtherProperties({ resolve, reject }) {
    try {
        const {
            hasOtherProperty,
            hasMortgage,
            ...otherProperty
        } = yield select(getOtherPropertiesFormData);
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);
        const formatedOtherProperty = cleanOwnedPropertyData({
            ...otherProperty,
            hasMortgage,
            mortgage:
                hasMortgage && otherProperty.mortgage
                    ? {
                          ...otherProperty.mortgage,
                          interestRate: parseFloat(
                              otherProperty.mortgage.interestRate
                          )
                      }
                    : null,
            address: getAddressRealFields(otherProperty.address)
        });

        const { data, ok } = otherProperty.id
            ? yield call(
                  apiClient.updateOtherProperties,
                  applicationId,
                  otherProperty.id,
                  applicantId,
                  formatedOtherProperty
              )
            : yield call(createOtherProperty, formatedOtherProperty);

        if (!ok) {
            throw new Error(data.error);
        }

        yield call(applicationSubmitSuccess);
        resolve();
    } catch (error) {
        yield call(applicationSubmitFailure, error);
        reject(error);
    }
}

export function* onSubmitApplicationRequest() {
    try {
        const applicationId = yield select(getActiveApplicationId);
        const type = yield select(getCurrentApplicationType);
        const account: Account = yield select(getAccount);

        const { data, ok } = yield call(
            apiClient.updateApplicationState,
            applicationId,
            'SUBMIT'
        );

        if (!ok) {
            throw new Error(data.error);
        }

        // GA-4
        yield put(
            analyticEvent('submit', {
                info: 'Submit application',
                type,
                event_location_category: 'application',
                province: account.region
            })
        );

        yield put({
            type: ApplicationsTypes.FETCH_APPLICATIONS_SUCCESS,
            applications: [data]
        });

        yield put(push(Routes.applicationSubmitted, { showSubmitted: true }));

        yield put(
            successNotification({
                text: 'application.dataUpdatedSuccess'
            })
        );
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

// FROM HERE
export function* onUpdateAsset({ resolve, reject }: PromisifyAction) {
    try {
        let formData = yield select(getAssetsDownpaymentsFormData);

        //we have to set the mainApplicantID if it is just one applicant
        const mainApplicantID = yield select(getMainApplicantId);

        // in the new flow we don't ask for the value
        // we will set the same value as amountUsedForDownPayment
        // we are not using the value field anymore, it is used just for Vehicle
        formData = {
            ...formData,
            value:
                formData.type !== 'VEHICLE'
                    ? formData.amountUsedForDownPayment
                    : formData.value,
            applicant: {
                applicantId: formData?.applicant?.applicantId || mainApplicantID
            }
        };

        if (formData.id) {
            const oldAsset: Asset | undefined = yield select(
                getAssetById(formData.id)
            );

            // If we change applicant of same asset id we need to remove it then create to the new applicant
            if (
                Number(formData?.applicant?.applicantId) !==
                Number(oldAsset?.applicant?.applicantId)
            ) {
                // delete
                yield call(deleteAsset, { assetId: oldAsset?.id });
                // create for new user
                const recreated = yield call(createAsset, {
                    ...formData,
                    willUseForDownPayment:
                        formData.amountUsedForDownPayment > 0 ? true : false
                });

                yield call(applicationSubmitSuccess);

                if (isFunction(resolve)) {
                    resolve(recreated);
                }

                return recreated;
            }

            // compare applicant ID

            const updated = yield call(updateAsset, formData.id, {
                ...formData,
                willUseForDownPayment:
                    formData.amountUsedForDownPayment > 0 ? true : false
            });

            yield call(applicationSubmitSuccess);

            if (isFunction(resolve)) {
                resolve(updated);
            }

            return updated;
        }

        const created = yield call(createAsset, {
            ...formData,
            willUseForDownPayment:
                formData.amountUsedForDownPayment > 0 ? true : false
        });

        yield call(applicationSubmitSuccess);

        if (isFunction(resolve)) {
            resolve(created);
        }
    } catch (error) {
        yield call(applicationSubmitFailure, error);
        if (isFunction(reject)) {
            reject(error);
        }
    }
}

export function* createAsset(asset) {
    const applicationId = yield select(getApplicationId);

    const applicantId = asset.applicant.applicantId as string;

    return yield call(
        apiClient.createAsset,
        applicationId as string,
        applicantId as string,
        R.omit(['applicantId', 'applicant'], asset) as OtherProperty
    );
}

export function* updateAsset(assetId, asset) {
    const applicationId = yield select(getApplicationId);

    const applicantId = asset.applicant.applicantId as string;

    return yield call(
        apiClient.updateAsset,
        applicationId as string,
        applicantId as string,
        assetId,
        R.omit(['applicantId', 'applicant'], asset) as OtherProperty
    );
}

export function* deleteAsset({ assetId }) {
    const applicationId = yield select(getApplicationId);
    const asset = yield select(getAssetById(assetId));

    return yield call(
        apiClient.deleteAsset,
        applicationId as string,
        asset.applicant.applicantId,
        asset.id
    );
}

export function* onDeleteAsset({ assetId }) {
    try {
        // delete
        yield call(deleteAsset, { assetId });

        yield call(applicationSubmitSuccess);
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

type PropertyAssetFormState = { [s in string]: AssetProperty };

export function* updatePropertyAssets() {
    try {
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);
        const formData: PropertyAssetFormState = yield select(
            getExistingPropertyAssets
        );
        const assets = yield select(getAllOtherPropertyAssets);

        const propertyAssets = Object.entries(formData).map(
            ([unformatedId, fields]) => ({
                ...assets.find(
                    ({ existingPropertyId }) =>
                        `${existingPropertyId}` === getParamId(unformatedId)
                ),
                ...fields
            })
        );

        const propertyAssetUpdates = propertyAssets.map(asset =>
            call(
                apiClient.updateAsset,
                applicationId as string,
                applicantId,
                asset.id,
                asset
            )
        );

        yield all(propertyAssetUpdates);
        yield call(onFetchApplicationsRequest);
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

export function* saveBankingDetails({ resolve, reject }) {
    yield put(Actions.saveBankingDetailsStart());

    const { institution, institutionOther } = yield select(
        getBankingDetailsFormData
    );

    // Success and Error toast message will be trigger by `submitApplicantInformation`
    // on `resolve` redux-form will call the onSubmitSucess
    // With no promisification of this the UI doesn't wait and go to next
    yield call(submitApplicantInformation, {
        resolve,
        reject,
        primaryBankingInstitution: institution,
        primaryBankingInstitutionOther:
            institution === 'OTHER' ? institutionOther : null
    });
}

export function* deleteEmployment({ employmentId }) {
    try {
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);

        if (employmentId) {
            const { data, ok } = yield call(
                apiClient.deleteEmployment,
                applicationId as string,
                applicantId,
                employmentId
            );

            yield put(Actions.submitApplicationsSuccess());

            if (!ok) {
                throw new Error(data.error);
            }
        }

        yield call(onFetchApplicationsRequest);
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

export function* createEmployment(applicationId, applicantId, formData) {
    return yield apiClient.createEmployment(
        applicationId as string,
        applicantId,
        formData
    );
}

export function* updateEmployment(
    applicationId,
    applicantId,
    employmentId,
    formData
) {
    // TODO: remove when backend stop sending those back (for backward compatibility)
    formData.reportingTo = null;
    formData.position = null;
    formData.hourlyIncome = null;

    return yield call(
        apiClient.updateEmployment,
        applicationId,
        applicantId,
        employmentId,
        formData
    );
}

export function* saveIncome({ resolve, reject }) {
    try {
        yield put(Actions.saveIncomeStart());
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);
        let formData = yield select(getEmploymentsFormData);

        if (formData.employer && formData.employer.address) {
            formData = {
                ...formData,
                employer: {
                    ...formData.employer,
                    address: getAddressRealFields(formData.employer.address)
                }
            };
        }

        if (formData.id) {
            yield call(
                updateEmployment,
                applicationId,
                applicantId,
                formData.id,
                formData
            );
        } else {
            yield call(createEmployment, applicationId, applicantId, formData);
        }

        yield call(onFetchApplicationsRequest); // TODO: move this outta here
        yield put(Actions.saveIncomeSuccess());
        resolve();
    } catch ({ error, parameters }) {
        yield call(applicationSubmitFailure, error); // TODO: move this outta here
        yield put(Actions.saveIncomeFail());
        reject(
            new SubmissionError({
                _error: 'Save Applicant Income Failed!'
            })
        );
    }
}

export function* setCurrentIncome({ resolve, reject }) {
    try {
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);
        const allEmployments = yield select(getEmployments);
        const { incomeId } = yield select(getEmploymentsFormData);

        let selectedIncome = allEmployments.find(
            employment => employment.id === incomeId
        );

        if (selectedIncome.employer && selectedIncome.employer.address) {
            selectedIncome = {
                ...selectedIncome,
                employer: {
                    ...selectedIncome.employer,
                    address: getAddressRealFields({
                        ...selectedIncome.employer.address,
                        noAutoFill: true
                    })
                }
            };
        }

        yield call(updateEmployment, applicationId, applicantId, incomeId, {
            ...selectedIncome,
            isCurrent: true
        });

        yield call(onFetchApplicationsRequest);

        resolve();
    } catch ({ error, parameters }) {
        reject(new Error('formError'));
    }
}

export function* saveEmployment() {
    try {
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);
        let formData = yield select(getEmploymentsFormData);
        formData = {
            ...formData,
            employer: {
                ...formData.employer,
                address: getAddressRealFields(formData.employer.address)
            }
        };

        if (formData.id) {
            yield call(
                updateEmployment,
                applicationId,
                applicantId,
                formData.id,
                formData
            );
        } else {
            yield call(createEmployment, applicationId, applicantId, formData);
        }

        yield put(Actions.submitApplicationsSuccess());
        yield call(onFetchApplicationsRequest);
        yield put(
            successNotification({
                text: 'application.dataUpdatedSuccess'
            })
        );
    } catch (error) {
        yield call(applicationSubmitFailure, error);
    }
}

// update target property on type === RENEWAL || REFINCANCE
export function* updateRenewingProperty({ resolve, reject }: PromisifyAction) {
    const applicationId = yield select(getApplicationId);
    const oldPropertyData = yield select(getTargetProperty);

    let formData = yield select(getRenewingPropertyFormData);

    const hideTargetPropertyFields = yield getFeatureFlagState(
        'target-property-fields'
    );

    try {
        if (formData.garage && !formData.garage.present) {
            const omitGarageProps = R.omit(['size', 'type']);

            formData = {
                ...formData,
                garage: omitGarageProps(formData.garage)
            };
        }

        if (formData.purpose === 'OWNER_OCCUPIED') {
            const omitRentalIncome = R.omit(['rentalIncome']);

            formData = omitRentalIncome(formData);
        }

        // Mortgages
        // Parse interest rate as float. Redux-form return it as string because of `.`
        // Keep termType only on mortgageType === 'STANDARD'
        formData.mortgages = (formData.mortgages || []).map(mortgage => ({
            ...mortgage,
            insuranceQuestionAddFundsOrIncreasedMortgageAmount:
                mortgage.insuranceQuestionRefinanceOrRenewal === 'YES'
                    ? mortgage.insuranceQuestionAddFundsOrIncreasedMortgageAmount
                    : null,
            insuranceQuestionIncreasedAmortization:
                mortgage.insuranceQuestionRefinanceOrRenewal === 'YES'
                    ? mortgage.insuranceQuestionIncreasedAmortization
                    : null,
            interestRate: parseFloat(mortgage.interestRate),
            termType:
                mortgage.mortgageType === 'STANDARD'
                    ? mortgage.termType
                    : undefined,
            payment: {
                ...mortgage.payment,
                amount: getAsNumber(mortgage.payment.amount)
            }
        }));

        if (!hideTargetPropertyFields) {
            formData = {
                ...formData,
                livingSpace: {
                    ...formData.livingSpace,
                    amount: getAsNumber(formData?.livingSpace?.amount || 0),
                    unit: formData?.livingSpace?.unit || ''
                },
                lotSize: {
                    ...formData.lotSize,
                    amount: getAsNumber(formData?.lotSize?.amount || 0),
                    unit: formData?.lotSize?.unit || ''
                },
                // add amount and frequency since backend require full object
                coOwnershipFees: {
                    ...formData.coOwnershipFees,
                    amount: formData.coOwnershipFees.amount ?? null,
                    frequency: formData.coOwnershipFees.frequency ?? ''
                },
                // required when coownership fees are empty and heating included in coownership fees is true
                heatingCost: {
                    amount: formData.heatingCost?.amount ?? null,
                    frequency: formData.heatingCost?.frequency ?? ''
                }
            };
        }

        const { ok, data } = yield call(
            apiClient.updateTargetProperty,
            applicationId,
            {
                ...oldPropertyData,
                ...formData,
                address: getAddressRealFields(formData.address)
            }
        );

        if (!ok) {
            throw new Error(data.error);
        }

        let pageUrl = 'submit';
        let pageParam = 'application.submit';

        const isAppSubmitted = yield select(isApplicationSubmitted);

        yield call(applicationSubmitSuccess);

        if (isAppSubmitted) {
            pageUrl = 'submitted';
            pageParam = 'application.submitted';
        }

        yield call(transitionToApplicationPage, pageUrl, pageParam);
        resolve();
    } catch (error) {
        yield call(applicationSubmitFailure, error);
        reject(error);
    }
}

export function* transitionToNextPage({
    url,
    sectionName = 'default',
    applicantId
}: {
    url: string;
    sectionName: string;
    applicantId: string | number;
}) {
    const id: string | number = yield applicantId ||
        select(getCurrentApplicationApplicantId);

    yield put(
        push({
            pathname: url,
            search: `?applicant=${id}&sectionName=${sectionName}`
        })
    );
}

export function* otherPropertiesTransitionToNextPage() {
    const { url, sectionName, applicantId } = yield select(
        getOtherPropertyTransitionNextPageParams
    );

    yield put(Actions.transitionToNextPage(url, sectionName, applicantId));
}

export function* createCoapplicant({ resolve, reject }: PromisifyAction) {
    try {
        const applicationId = yield select(getApplicationId);
        const coapplicant = yield select(getCoapplicantDetails);

        const { data, ok } = yield call(
            apiClient.createCoapplicant,
            applicationId,
            { permissions: 'CO_APPLICANT_DEFAULT', ...coapplicant }
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield call(onFetchApplicationsRequest);
        yield put(
            successNotification({
                text: 'application.dataUpdatedSuccess'
            })
        );

        // All province except QC
        // We **DO NOT** block poor credit on 2nd applicant
        // QC only
        // We **DO NOT** block poor credit on 2nd applicant
        const isPoorCreditScore = coapplicant?.creditScoreQuality === 'POOR';
        const isFairCreditScore = coapplicant?.creditScoreQuality === 'FAIR';
        const isPoorOrFairCreditScore = isPoorCreditScore || isFairCreditScore;

        let url = `/application/registered-address?applicant=${data.applicantId}&sectionName=application.registeredAddress`;

        if (isPoorOrFairCreditScore) {
            url = `/application/credit-score-warning?applicant=${data.applicantId}&sectionName=application.creditScoreWarning`;
        }

        yield put(push(url));
        resolve();
    } catch (error) {
        yield call(createApplicantFailure, error);
        reject(error);
    }
}

function* createApplicantFailure(error: any) {
    try {
        log({ error });
        yield put({
            type: ApplicationsTypes.CREATE_COAPPLICANT_FAILURE
        });
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* deleteCoapplicant({ applicantId }) {
    try {
        const applicationId = yield select(getApplicationId);
        const mainApplicantId = yield select(getMainApplicantId);
        const currentApplicantId = yield select(
            getCurrentApplicationApplicantId
        );

        const isUserMainApplicant = yield select(
            getIsMainApplicant,
            applicantId
        );

        // Can not delete main applicant
        if (isUserMainApplicant) {
            throw new Error('Could not remove main applicant');
        }

        const { data, ok } = yield call(
            apiClient.deleteCoapplicant,
            applicationId,
            applicantId
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield call(onFetchApplicationsRequest);
        yield put(
            successNotification({
                text: 'application.dataUpdatedSuccess'
            })
        );

        // if the removed applicant is current applicant
        // redirect to target-property
        if (currentApplicantId === applicantId) {
            yield put(
                push(
                    `/application/applicant-info?applicant=${mainApplicantId}&sectionName=application.applicantInformation`
                )
            );
        }
    } catch (error) {
        yield call(deleteApplicantFailure, error);
    }
}

function* deleteApplicantFailure(error: any) {
    try {
        log({ error });
        yield put({
            type: ApplicationsTypes.DELETE_COAPPLICANT_FAILURE
        });
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* setApplicantOtherPropertiesSpecified({
    specified,
    resolve,
    reject
}: {
    specified: boolean;
} & PromisifyAction) {
    try {
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);

        const { data, ok } = yield call(
            apiClient.setPropertiesSpecified,
            applicationId,
            applicantId,
            { specified }
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield call(onFetchApplicationsRequest);
        resolve();
    } catch (error) {
        yield call(setApplicantSpecifiedFailure, error);
        reject(error);
    }
}

export function* setApplicantOtherIncomesSpecified({
    specified,
    resolve,
    reject
}: {
    specified: boolean;
} & PromisifyAction) {
    try {
        const applicationId = yield select(getApplicationId);
        const applicantId = yield select(getCurrentApplicationApplicantId);

        const { data, ok } = yield call(
            apiClient.setOtherIncomesSpecified,
            applicationId,
            applicantId,
            { specified }
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield call(onFetchApplicationsRequest);
        resolve();
    } catch (error) {
        yield call(setApplicantSpecifiedFailure, error);
        reject(error);
    }
}

export function* setApplicantSpecifiedFailure(error: any) {
    try {
        log({ error });

        yield put({
            type: ApplicationsTypes.UPDATE_APPLICANT_FAILURE
        });

        yield put(
            errorNotification({
                text: 'application.dataUpdatedFailure'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* onSetActiveApplicationId() {
    const activeApplication = yield select(getActiveApplication);
    // applicationStatus === CREATED is a in progress app others status are after app submission
    const isInProgress = yield select(isApplicationCreated);
    const applicationId = yield select(getActiveApplicationId);
    const type = yield select(getCurrentApplicationType);
    const subPartnerId = yield select(getApplicationSubPartnerId);
    const account = yield select(getAccount);
    // Here we really want to take what we have in session and not store in our DB
    // If we have something different we want to overwrite it.
    const partner = sessionStorage.getItem('partner') || account.partner;
    const sessionSubPartnerId = sessionStorage.getItem('subPartnerId') || 0;
    // logical const
    const hasSessionSubPartnerId = +sessionSubPartnerId > 0;
    const isNewMortgage = type === 'NEW';
    const isDifferentSubPartnerId = +sessionSubPartnerId !== subPartnerId;

    const isOnBehalfOf = yield select(isBehalfAUser);

    // On logged on behalf we should never update the account and application partner
    // when select an application from the list
    const shouldUpdate =
        !isOnBehalfOf &&
        hasSessionSubPartnerId &&
        isNewMortgage &&
        isInProgress &&
        isDifferentSubPartnerId;

    // Always use `account.partner` when logged in on behalf of
    yield put(
        UIActions.updatePartner(isOnBehalfOf ? account.partner : partner)
    );

    if (shouldUpdate) {
        const accountPayload = {
            ...account,
            partner,
            partnerSpecified: true
        };
        const { updateApplication, updateAccount } = yield all({
            updateApplication: call(
                apiClient.updateApplicationSubPartner,
                applicationId,
                +sessionSubPartnerId
            ),
            updateAccount: call(apiClient.updateAccount, accountPayload)
        });

        if (updateApplication.ok) {
            yield put(
                Actions.fetchApplicationsSuccess([
                    { ...activeApplication, subPartnerID: +sessionSubPartnerId }
                ])
            );
        }

        if (updateAccount.ok) {
            yield put(AccountActions.updateUserSuccess(accountPayload));
        }

        if (!updateApplication.ok) {
            log({
                error: '[APPLICATION] Could not update sub partner id',
                tag: 'subPartner',
                data: {
                    accountId: account.id,
                    sessionSubPartnerId,
                    applicationId
                }
            });
        }

        if (!updateAccount.ok) {
            log({
                error: '[ACCOUNT] Could not update partner',
                tag: 'subPartner',
                data: {
                    accountId: account.id,
                    sessionSubPartnerId,
                    applicationId
                }
            });
        }
    }
}

export function* onSetAdvisorBooked({
    applicationId
}: {
    applicationId: number;
    type: string;
}) {
    try {
        const result = yield call(apiClient.setFirstCallBooked, applicationId);

        if (result.ok) {
            yield call(onFetchApplicationsRequest);
        }
    } catch (error) {
        log(error);
    }
}

export function* onSetApplicantInSalesForceQueue({
    addToQueue
}: {
    addToQueue: boolean;
    type: string;
}) {
    const applicationId = yield select(getActiveApplicationId);

    try {
        const { ok, data } = yield call(
            apiClient.addApplicantToSalesForceQueue,
            applicationId,
            addToQueue
        );

        if (ok) {
            yield put(
                ApplicationsStateActions.setApplicantSalesForceQueue(
                    applicationId,
                    data.salesforceQueueOutboundCallNow
                )
            );
        }
    } catch (error) {
        yield put(
            ApplicationsStateActions.setApplicantSalesForceQueue(
                applicationId,
                false
            )
        );
        log({
            tag: 'FAILED unable to add applicant to Salesforce Queue',
            error
        });
    }
}
export function* onSetApplicantComfortableSpeakingFrench({
    salesforceQueueComfortableSpeakingFrench
}: {
    salesforceQueueComfortableSpeakingFrench: boolean;
    type: string;
}) {
    const applicationId = yield select(getActiveApplicationId);

    try {
        const { ok, data } = yield call(
            apiClient.addApplicantToFrenchQueue,
            applicationId,
            salesforceQueueComfortableSpeakingFrench
        );

        if (ok) {
            yield put(
                Actions.updateSalesforceQueueComfortableSpeakingFrench(
                    applicationId,
                    data.salesforceQueueComfortableSpeakingFrench
                )
            );
        }
    } catch (error) {
        yield put(
            Actions.updateSalesforceQueueComfortableSpeakingFrench(
                applicationId,
                false
            )
        );
        log({
            tag: 'FAILED unable to add applicant to Salesforce French Queue',
            error
        });
    }
}

export function* onSetApplicationSubmittedByAgent({
    salesforceApplicationSubmittedByAgent
}: {
    salesforceApplicationSubmittedByAgent: boolean;
    type: string;
}) {
    const applicationId = yield select(getActiveApplicationId);

    try {
        const { ok, data } = yield call(
            apiClient.applicationSubmittedByAgent,
            applicationId,
            salesforceApplicationSubmittedByAgent
        );
        if (ok) {
            yield put(
                Actions.updateSalesforceApplicationSubmittedByAgent(
                    applicationId,
                    data.salesforceApplicationSubmittedByAgent
                )
            );
        }
    } catch (error) {
        yield put(
            Actions.updateSalesforceApplicationSubmittedByAgent(
                applicationId,
                false
            )
        );
        log({
            tag: 'FAILED unable to add applicant to Salesforce Queue',
            error
        });
    }
}

/**
 * onUpdateSubpartnerId
 * This function will be call from a logged in user with active application
 * that change his subPartnerId in the URL
 * see `ApplicationActions.updateSubpartnerId` in ui.sagas.ts
 */
export function* onUpdateSubpartnerId({
    applicationId,
    subPartnerId
}: {
    type: string;
    applicationId: number;
    subPartnerId: number;
}) {
    try {
        const { ok } = yield call(
            apiClient.updateApplicationSubPartner,
            applicationId as any,
            +subPartnerId
        );

        if (ok) {
            // Redo the application request since the update subpartner doesnt return the updated application
            // will get 2 application when refreshen with new subPartnerId in the URL
            // on a new refresh of the page but we dont have other option because `apiClient.updateApplicationSubPartner` return an empty object
            yield call(onFetchApplicationsRequest);
        }
    } catch (error) {
        log({ tag: 'FAILED to set application subPartnerId: ', error });
    }
}

export function* onDeleteApplication({
    applicationId
}: {
    applicationId: number;
}) {
    try {
        const { data, ok } = yield call(
            apiClient.updateApplicationState,
            applicationId,
            'DELETE'
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put(Actions.deleteApplicationSuccess());
        yield put(SessionActions.resetActiveApplication());
        yield call(onFetchApplicationsRequest);
        yield put(successNotification({ text: 'toasts.applicationDeleted' }));
    } catch (error) {
        yield put(errorNotification({ text: 'toasts.genericError' }));
        yield put(Actions.deleteApplicationFailure(error));
        log(error);
    }
}

export function* onFetchApplicationRedirect({ applicationId, redirectTo }) {
    let location: LocationDescriptorObject = {
        pathname: redirectTo || Routes.root
    };

    if (applicationId && redirectTo === 'lastVisited') {
        const { data, ok } = yield call(
            apiClient.getAccountTracking,
            `website.last-visited-page-${applicationId}`
        );

        if (ok) {
            location = getRedirectUrl(data.value);
        }
    }

    return location;
}
