import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { path } from 'ramda';
import qs from 'qs';
import createCachedSelector from 're-reselect';

import { Employment } from 'models/application/application-employment';
import { Asset, AssetTypes } from 'models/application/application-assets';
import {
    ADDRESS_SECTIONS,
    APPLICATION_FORMS,
    IncomeTypes,
    PARTNERS_SETTINGS_FORM
} from 'constants/appConstants';
import { DocumentStep } from 'models/documents/documents-step.enum';

import {
    Advisor,
    Applicant,
    ApplicantInfo,
    Application,
    ApplicationsMap,
    ApplicationState,
    ApplicationType,
    OtherProperty,
    RegisteredAddress,
    TargetProperty
} from 'models/application/Application';
import { ApplicantId } from 'reducers/sidebar.selectors';
import { getAddressNoAutoFillFields } from 'utils/address-helpers';
import { TranslationKeys } from 'messages';
import {
    getDownPaymentInformation,
    getDownPaymentPercentage as getDownPaymentPercentageByValue,
    getDownPaymentValue,
    isViableDownPayment
} from 'utils/down-payment-helpers';
import { Routes } from 'app-root/routing/routes';
import { isMortgage, isMortgageFundedByNesto } from 'utils/application';
import { getActiveApplicationId } from 'reducers/session.selectors';
import { mortgageBuilderQueueOpen } from 'components/speak-to-agent/business-hours';
import { Region } from 'types/application/application';
import { ServicingAsset } from 'models/servicing-asset';

export { getActiveApplicationId } from 'reducers/session.selectors';

type ReduxState = any;

export const getHasTransactions = (state: any): boolean =>
    getTransactions(state).length > 0;

export const applicationStateKey = (state: any) => {
    const activeApplicationId = getActiveApplicationId(state);

    return activeApplicationId &&
        state.application.applications?.[activeApplicationId]?.updated
        ? `${activeApplicationId}.${state.application.applications?.[activeApplicationId]?.updated}`
        : 'loading';
};

export const getApplicantInfoFormData = (state: ReduxState): ApplicantInfo => {
    const formValues = getFormValues(APPLICATION_FORMS.APPLICANT_INFO)(
        state
    ) as any;
    return formValues && formValues.applicantInfo;
};

export const getApplicantOtherIncomeFormData = (state: ReduxState) => {
    return getFormValues(APPLICATION_FORMS.OTHER_INCOME)(state) as any;
};
export const getManualPartnerInfoFormData = (state: ReduxState) => {
    return getFormValues(PARTNERS_SETTINGS_FORM)(state) as any;
};

export const getApplicantOtherIncomeFormType = (state: ReduxState) => {
    const formData = getApplicantOtherIncomeFormData(state);
    return formData && formData.type;
};

export const getAssetsDownpaymentsFormData = (state: ReduxState) =>
    getFormValues(APPLICATION_FORMS.ASSETS_DOWN_PAYMENT)(state) as any;

export const getAssetsType = (state: ReduxState): AssetTypes | undefined => {
    const formData = getAssetsDownpaymentsFormData(state);
    return formData && formData.type;
};

export const getBankingDetailsFormData = getFormValues(
    APPLICATION_FORMS.BANKING_DETAILS
) as (state: ReduxState) => { institution: string; institutionOther?: string };

export const getBankruptcyFormData = getFormValues(
    APPLICATION_FORMS.APPLICANT_INFO
) as (
    state: ReduxState
) => {
    applicantInfo: {
        hasConsumerProposalOrBankruptcyLast5yrs: 'YES' | 'NO' | '';
    };
};

export const getIsInstitutionOther = (state: ReduxState): boolean => {
    const formData = getAssetsDownpaymentsFormData(state);
    return !!(formData && formData.institution === 'OTHER');
};

export const getRegisteredAddressFormData = (state: any): RegisteredAddress =>
    getFormValues(APPLICATION_FORMS.REGISTERED_ADDRESS)(state) as any;

export const getApplicationsIsLoading = (state: ReduxState): boolean =>
    !!R.path(['application', 'loading'], state);

export const getOtherPropertiesFormData = (state: ReduxState): OtherProperty =>
    getFormValues(APPLICATION_FORMS.OTHER_PROPERTY)(state) as OtherProperty;

// Gets servicing assets indexed by applicationId
const getServicingAssets = (state: ReduxState) =>
    R.pipe(
        R.pathOr<ServicingAsset[]>([], ['application', 'servicingAssets']),
        R.indexBy(asset => asset.loans?.[0]?.applicationId.toString() ?? '*')
    )(state);

const getAllTransactions = (state: ReduxState) =>
    R.pipe(
        R.pathOr<ApplicationsMap>({}, ['application', 'applications']),
        R.values
    )(state);

export const getTransactions = (state: ReduxState): Application[] => {
    const all = getAllTransactions(state);
    const assets = getServicingAssets(state);

    return all.map(application => {
        const linkedAsset = assets[application.id];
        if (linkedAsset) {
            application.linkedServicingAsset = linkedAsset;
            application.applicationState = ApplicationState.Funded;
        }

        return application;
    });
};

const getFilteredTransactionList = (
    predicate: (application: Application) => boolean
) => (state: ReduxState) => getTransactions(state).filter(predicate);

export const getMortgagesFundedByNesto = getFilteredTransactionList(
    isMortgageFundedByNesto
);

export const getMortgages = getFilteredTransactionList(isMortgage);

export const getApplications = getFilteredTransactionList(
    R.complement(isMortgage)
);

export const getSortedMortgages = (state: ReduxState) => {
    const mortgages = getMortgages(state);
    return mortgages.sort((a, b) => (a.updated > b.updated ? -1 : 1));
};

export const getOpenApplicationsByType = (state: ReduxState) =>
    getApplications(state)
        .sort((a, b) => (a.updated > b.updated ? -1 : 1))
        .reduce((r, a) => {
            r[a.type] = [...(r[a.type] || []), a];
            return r;
        }, {});

export const getActiveApplication = createCachedSelector(
    state => state,
    getActiveApplicationId,
    (state, activeApplicationId): Application | undefined =>
        activeApplicationId
            ? R.path<Application>([
                  'application',
                  'applications',
                  activeApplicationId
              ])(state)
            : undefined
)(applicationStateKey);

export const getIsLockedState = createCachedSelector(
    state => state,
    getActiveApplication,
    (
        state,
        activeApplication: Application | undefined
    ): boolean | undefined => {
        return activeApplication?.locked && !state.account.broker;
    }
)(applicationStateKey);

export const getIsSdrQualified = createCachedSelector(
    getActiveApplication,
    (activeApplication: Application | undefined): boolean => {
        return !!activeApplication?.sdrQualified;
    }
)(applicationStateKey);

export const getHasProductSelected = createCachedSelector(
    state => state,
    getActiveApplication,
    (
        state,
        activeApplication: Application | undefined
    ): boolean | undefined => {
        return activeApplication?.product !== null;
    }
)(applicationStateKey);

export const getHasActiveApplication = createCachedSelector(
    getActiveApplication,
    activeApplication => !!activeApplication
)(applicationStateKey);

export const getApplicants = createCachedSelector(
    getActiveApplication,
    application => application?.applicants
)(applicationStateKey);

export const memorizedGetApplicants = getApplicants;

export const getApplicantsList = createCachedSelector(
    getApplicants,
    applicants => Object.values(applicants || {})
)(applicationStateKey);

export const getApplicationSubPartnerId = createCachedSelector(
    getActiveApplication,
    application => (application ? application?.subPartnerID : 0)
)(applicationStateKey);

/**
 * @deprecated
 * Use `getActiveApplicationId`
 */
export const getApplicationId = getActiveApplicationId;

export const getMainApplicantId = (state: ReduxState): string | number => {
    const application = getActiveApplication(state);

    return application?.mainApplicantId || '';
};

export const getMainApplicant = (state: ReduxState): Applicant | undefined => {
    const application = getActiveApplication(state);
    const mainApplicantId = getMainApplicantId(state);

    return application?.applicants?.[mainApplicantId];
};

export const getApplicant = (
    state: any,
    applicantId: ApplicantId
): Applicant | undefined => {
    const application = getActiveApplication(state);

    return R.path(['applicants', applicantId], application);
};

export const getCurrentApplicationApplicantId = (state: ReduxState): number => {
    const { search } = state.router.location;
    const { applicant } = search && qs.parse(search.replace(/\?/, ''));
    const applicants = getApplicants(state);
    const applicantIds = R.pipe<
        {
            [s: number]: Applicant;
        },
        Applicant[],
        number[]
    >(
        R.values,
        R.pluck('applicantId')
    )(applicants || {});

    const mainApplicantId = getMainApplicantId(state);
    const currentApplicant = applicant || mainApplicantId;

    return applicantIds.includes(+currentApplicant)
        ? +currentApplicant
        : +mainApplicantId;
};

export const getAccountsBasedOnRealtorRole = (state: any): ReduxState =>
    state.application.accountsByRealtorRole;

export const getSelectedSubPartnerId = (state: any): ReduxState =>
    state.application.manualSubPartnerID;

export const getApplicantsIncome = (state: ReduxState) => {
    const application = getActiveApplication(state);
    const currentApplicantId = getCurrentApplicationApplicantId(state);

    return application?.applicants[currentApplicantId]?.income;
};

export const memorizedGetApplicantIncome = createCachedSelector(
    getActiveApplication,
    getCurrentApplicationApplicantId,
    (application, currentApplicantId: number) =>
        application?.applicants?.[currentApplicantId]?.income
)(applicationStateKey);

export const getApplicantsOthersIncome = (state: ReduxState) => {
    const applicantIncome = getApplicantsIncome(state);
    return applicantIncome && applicantIncome.others;
};

export const getCurrentApplicant = (state: ReduxState) => {
    const application = getActiveApplication(state);
    const currentApplicantId = getCurrentApplicationApplicantId(state);

    return application?.applicants?.[currentApplicantId];
};

export const getFirstCallBooked = (state: ReduxState) => {
    const application = getActiveApplication(state);

    return application?.firstCallBooked;
};

// TODO: Do we really need this function separate from getCurrentApplicant?
export const getCurrentApplicantInfo = (state: any) => {
    const applicant = getCurrentApplicant(state);

    return (
        applicant && {
            applicantId: applicant.applicantId,
            salutation: applicant.salutation,
            firstName: applicant.firstName,
            lastName: applicant.lastName,
            email: applicant.email,
            dateOfBirth: applicant.dateOfBirth,
            phone: applicant.phone,
            maritalStatus: applicant.maritalStatus,
            relationToMainApplicant: applicant.relationToMainApplicant,
            firstTimeHomeBuyer: applicant.firstTimeHomeBuyer,
            primaryBankingInstitution: applicant.primaryBankingInstitution,
            primaryBankingInstitutionOther:
                applicant.primaryBankingInstitutionOther,
            creditScoreQuality: applicant.creditScoreQuality,
            propertiesSpecified: applicant.propertiesSpecified,
            otherIncomesSpecified: applicant.otherIncomesSpecified,
            /* deprecated */
            // covid19Impacted: applicant.covid19Impacted,
            // covid19ImpactDescription: applicant.covid19ImpactDescription,
            hasConsumerProposalOrBankruptcyLast5yrs:
                applicant.hasConsumerProposalOrBankruptcyLast5yrs
        }
    );
};

export const getCurrentApplicantBankruptcy = createCachedSelector(
    getCurrentApplicant,
    applicant => applicantBankruptcy(applicant)
)(applicationStateKey);

const applicantBankruptcy = (applicant): 'YES' | 'NO' | '' =>
    applicant?.hasConsumerProposalOrBankruptcyLast5yrs || '';

export const getIsBankruptcyAnswered = createCachedSelector(
    getCurrentApplicantBankruptcy,
    hasConsumerProposalOrBankruptcyLast5yrs =>
        hasConsumerProposalOrBankruptcyLast5yrs !== ''
)(applicationStateKey);

export const getHasBankruptcy = createCachedSelector(
    getCurrentApplicantBankruptcy,
    hasConsumerProposalOrBankruptcyLast5yrs =>
        hasConsumerProposalOrBankruptcyLast5yrs === 'YES'
)(applicationStateKey);

export const getHasNotBankruptcy = createCachedSelector(
    getCurrentApplicantBankruptcy,
    hasConsumerProposalOrBankruptcyLast5yrs =>
        hasConsumerProposalOrBankruptcyLast5yrs === 'NO'
)(applicationStateKey);

export const getShowBankruptcy = createCachedSelector(
    getCurrentApplicantBankruptcy,
    hasConsumerProposalOrBankruptcyLast5yrs =>
        hasConsumerProposalOrBankruptcyLast5yrs !== 'NO'
)(applicationStateKey);

export const memorizedGetOtherAllAssets = createCachedSelector(
    getCurrentApplicationApplicantId,
    getCurrentApplicant,
    (_, applicant) => getApplicantAssets(applicant)
)(applicationStateKey);

export const getApplicantAssets = (applicant): any[] => {
    if (!applicant) return [];

    const {
        allAssets,
        properties,
        applicantId,
        firstName,
        lastName
    } = applicant;

    return allAssets.map(asset => {
        if (asset.type !== 'PROPERTY')
            return {
                ...asset,
                // applicantId,
                applicant: {
                    applicantId,
                    firstName,
                    lastName
                }
            };

        const property: any = asset.existingPropertyId
            ? properties
                  .map(({ type, ...item }) => ({
                      ...item,
                      propertyType: type
                  }))
                  .find(({ id }) => id === asset.existingPropertyId)
            : {};

        return {
            ...property,
            ...asset,
            value:
                asset.value ||
                property.purchasePrice ||
                property.estimatedPropertyValue,
            // applicantId,
            applicant: {
                applicantId,
                firstName,
                lastName
            }
        };
    });
};

export const getApplicantAssetsDownPayment = (applicant): any[] => {
    if (!applicant) return [];

    const {
        allAssets,
        properties,
        applicantId,
        firstName,
        lastName
    } = applicant;

    const downPaymentAssets: any[] = [];

    allAssets.forEach(asset => {
        if (asset.type !== 'PROPERTY')
            downPaymentAssets.push({
                ...asset,
                // applicantId,
                applicant: {
                    applicantId,
                    firstName,
                    lastName
                }
            });

        if (asset.type === 'PROPERTY') {
            const property: any = asset.existingPropertyId
                ? properties
                      .map(({ type, ...item }) => ({
                          ...item,
                          propertyType: type
                      }))
                      .find(({ id }) => id === asset.existingPropertyId)
                : {};

            if (property.afterTransactionPurpose === 'SOLD') {
                downPaymentAssets.push({
                    ...property,
                    ...asset,
                    value:
                        asset.value ||
                        property.purchasePrice ||
                        property.estimatedPropertyValue,
                    // applicantId,
                    applicant: {
                        applicantId,
                        firstName,
                        lastName
                    }
                });
            }
        }
    });

    return downPaymentAssets;
};

export const memoGetAllAssets = createCachedSelector(
    memorizedGetApplicants,
    applicants => {
        const assets: Asset[] = [];

        if (!applicants) return assets;

        for (const applicant of Object.values(applicants)) {
            assets.push(...getApplicantAssets(applicant));
        }

        return assets;
    }
)(applicationStateKey);

export const memoGetAllAssetsDownPayment = createCachedSelector(
    memorizedGetApplicants,
    applicants => {
        const assets: Asset[] = [];

        if (!applicants) return assets;

        for (const applicant of Object.values(applicants)) {
            assets.push(...getApplicantAssetsDownPayment(applicant));
        }

        return assets;
    }
)(applicationStateKey);

export const getAssetById = (assetId: number) =>
    createCachedSelector(memoGetAllAssets, assets =>
        assets.find(asset => asset.id === assetId)
    )(applicationStateKey);

export const getAllOtherPropertyAssets = (state: ReduxState) => {
    const applicant = getCurrentApplicant(state);

    return (
        applicant &&
        applicant.allAssets.filter(({ type }) => type === 'PROPERTY')
    );
};

export const getAllPropertyAssets = (state: ReduxState) =>
    memorizedGetOtherAllAssets(state).filter(({ type }) => type === 'PROPERTY');

export const getInitialPropsPropertyAssets = (state: ReduxState) => {
    const properties = getAllPropertyAssets(state);
    return properties.reduce(
        (acc, property) => ({
            ...acc,
            [`${property.id}-id`]: {
                ...property,
                value: property.purchasePrice || property.estimatedPropertyValue
            }
        }),
        {}
    );
};

export const getExistingPropertyAssets = (state: ReduxState) =>
    getFormValues(APPLICATION_FORMS.PROPERTY_DOWN_PAYMENT)(state);

export const getMlsProperties = (state: any): ReduxState => {
    return state.application.properties;
};

export const getError = (state: any) => state.application.error;
export const getLoadingState = (state: any) => state.application.loading;

export const getTotalApplicantDownpayment = (state: ReduxState): number => {
    const allAssets = memorizedGetOtherAllAssets(state);
    return allAssets
        ? allAssets.reduce((acc, asset) => {
              return acc + asset.amountUsedForDownPayment;
          }, 0)
        : 0;
};

/**
 * Get current applicant assets down payment percentage
 * @param {ReduxState} state Redux State
 * @returns {number | undefined} Current applicant percentage or undefined
 */
export const getAssetsDownpaymentPercentage = (state: ReduxState) => {
    const downPayment = getTotalApplicantDownpayment(state);
    const targetProperty = getTargetProperty(state);

    return targetProperty &&
        targetProperty.purchasePrice !== 0 &&
        downPayment !== 0
        ? (downPayment / targetProperty.purchasePrice) * 100
        : undefined;
};

/**
 * Get down payment amount of all applicants
 * @param {ReduxState} state Redux State
 * @returns {number} Value in $ of all amount used for down payment from all applicants
 */
export const getDownPaymentAmount = (state: ReduxState): number => {
    const applicants = memorizedGetApplicants(state);

    if (!applicants) return 0;

    return Object.values(applicants).reduce((downpayment, applicant) => {
        return (
            downpayment +
            (applicant.allAssets
                ? applicant.allAssets.reduce((applicantDownpayment, asset) => {
                      return (
                          applicantDownpayment + asset.amountUsedForDownPayment
                      );
                  }, 0)
                : 0)
        );
    }, 0);
};

/**
 * Get down payment amount of all applicants with live form `ASSETS_DOWN_PAYMENT`
 * @param {ReduxState} state Redux State
 * @returns {number} Value in $ of all amount used for down payment from all applicants
 */
export function getDownPaymentAmountLiveForm(state: ReduxState) {
    const applicants = memorizedGetApplicants(state);

    if (!applicants) return 0;

    const formValues = getFormValues(APPLICATION_FORMS.ASSETS_DOWN_PAYMENT)(
        state
    ) as Asset;

    const assets = memoGetAllAssets(state);

    const filteredAssets =
        assets && formValues?.id
            ? assets.filter(({ id }) => id !== formValues.id).concat(formValues)
            : assets;

    return filteredAssets.reduce(
        (downpayment, asset) => downpayment + asset.amountUsedForDownPayment,
        0
    );
}

/**
 * Get down payment percentage, all applicants included
 * @param {ReduxState} state Redux State
 * @returns {number} return percentage number
 */
export const getDownpaymentPercentage = (state: ReduxState): number => {
    const downPayment = getDownPaymentAmount(state);
    const targetProperty = getTargetProperty(state);

    return getDownPaymentPercentageByValue(
        targetProperty?.purchasePrice,
        downPayment
    );
};

/**
 * Get assets worth, all applicants included
 * @param {ReduxState} state Redux State
 * @returns {number} return total assets worth number
 */
export const getTotalAssetWorth = (state: ReduxState) => {
    const allAssets = memoGetAllAssets(state);
    return allAssets
        ? allAssets.reduce((acc, asset) => {
              return acc + asset.value;
          }, 0)
        : 0;
};

export const getTotalApplicantAssetWorth = (state: ReduxState) => {
    const allAssets = memorizedGetOtherAllAssets(state);
    return allAssets
        ? allAssets.reduce((acc, asset) => {
              return acc + asset.value;
          }, 0)
        : 0;
};

export const getApplicantFirstName = (state: ReduxState): string => {
    const application = getActiveApplication(state);
    const applicantId = getCurrentApplicationApplicantId(state);
    const firstName = R.path(
        ['applicants', applicantId, 'firstName'],
        application
    ) as string;

    return firstName;
};

export const getIsMainApplicant = (
    state: ReduxState,
    applicantId?: string | number | undefined
): boolean => {
    const mainApplicantId = getMainApplicantId(state);

    const currentApplicantId =
        applicantId || getCurrentApplicationApplicantId(state);

    return mainApplicantId === currentApplicantId;
};

/**
 * Look if all applicants downpayment combined is bigger than 5%
 * @param {ReduxState} state Redux State
 * @returns {boolean} Return `true` if downpayment is bigger than 5% otherwise `false`
 */
export const getHasMinPercentDownpayment = (state: ReduxState): boolean => {
    const applicationType = getCurrentApplicationType(state);
    const downPayment = getDownPaymentAmount(state);
    const targetProperty = getTargetProperty(state);

    const isRental = targetProperty?.purpose === 'RENTAL';
    const propertyValue = targetProperty
        ? applicationType === 'NEW'
            ? targetProperty.purchasePrice
            : targetProperty.estimatedPropertyValue
        : 0;
    const isViable = isViableDownPayment(propertyValue, downPayment, isRental);

    return isViable;
};

/**
 * Look if all applicants downpayment combined is bigger than 5% with live form `ASSETS_DOWN_PAYMENT`
 * @param {ReduxState} state Redux State
 * @returns {boolean} Return `true` if downpayment is bigger than 5% otherwise `false`
 */
export const getHasMinPercentDownpaymentLiveForm = (
    state: ReduxState
): boolean => {
    const applicationType = getCurrentApplicationType(state);
    const downPayment = getDownPaymentAmountLiveForm(state);
    const targetProperty = getTargetProperty(state);

    const isRental = targetProperty?.purpose === 'RENTAL';
    const propertyValue = targetProperty
        ? applicationType === 'NEW'
            ? targetProperty.purchasePrice
            : targetProperty.estimatedPropertyValue
        : 0;

    const isViable = isViableDownPayment(propertyValue, downPayment, isRental);

    return isViable;
};

export const getApplicationState = (state: ReduxState) =>
    R.path<ApplicationState>(['applicationState'], getActiveApplication(state));

export const isApplicationSubmitted = (state: ReduxState) =>
    [
        'SUBMITTED',
        'UNDER_REVISION',
        'REVIEWED',
        'NOTES_SUBMITTED',
        'LENDER_SUBMITTED',
        'LENDER_APPROVED',
        'PENDING_COMMITMENT_SIGNATURE',
        'PENDING_CONDITIONS',
        'COMPLETE',
        'NOTARY_ALERTED',
        'FUNDED',
        'CLOSED',
        'EXPIRED'
    ].includes(getApplicationState(state) || '');

export const isApplicationCreated = (state: ReduxState): boolean =>
    getApplicationState(state) === 'CREATED';

export const getSubmitFilogixState = (state: ReduxState): boolean =>
    R.pipe<ReduxState, string[], boolean>(
        R.pathOr([], ['validEvents']),
        R.includes('SUBMIT_TO_LENDERS_DATA_EXCHANGE')
    )(getActiveApplication(state));

export const getCurrentApplicationType = (
    state: ReduxState
): ApplicationType | undefined => R.path(['type'], getActiveApplication(state));

export const getAllRegisteredAddresses = (state: any): RegisteredAddress[] =>
    R.pipe<
        ReduxState,
        Applicant[],
        Record<number, RegisteredAddress[]>,
        RegisteredAddress[][],
        RegisteredAddress[]
    >(
        R.pathOr([], ['applicants']),
        R.pluck('addresses'),
        R.values,
        R.flatten
    )(getActiveApplication(state));

export const getRegisteredAddresses = (
    state: ReduxState,
    applicantId?: ApplicantId
): RegisteredAddress[] => {
    const application = getActiveApplication(state);
    const currentApplicantId =
        applicantId || getCurrentApplicationApplicantId(state);
    return R.pathOr(
        [],
        ['applicants', currentApplicantId, 'addresses'],
        application
    );
};

export const getAllOwnedRegisteredAddresses = (
    state: ReduxState
): RegisteredAddress[] => {
    return getRegisteredAddresses(state).filter(
        address => address.situation === 'OWNER'
    );
};

export const getFormattedRegisteredAddresses = (
    RegisteredAddresses: RegisteredAddress[]
) =>
    RegisteredAddresses.map(
        address =>
            ({
                ...address,
                address: getAddressNoAutoFillFields(
                    address.address,
                    `${ADDRESS_SECTIONS.registeredAddress}_${address.id}`
                )
            } as RegisteredAddress)
    );

export const getRegisteredAddressInfo = (
    state: ReduxState,
    applicantId?: ApplicantId
): RegisteredAddress[] => {
    const RegisteredAddresses = getRegisteredAddresses(state, applicantId);
    return getFormattedRegisteredAddresses(RegisteredAddresses);
};

// Use for sidebar completed status
export const getRegisteredAddressTime = (
    state: ReduxState,
    applicantId?: ApplicantId
): { years: number; months: number } => {
    const formData = getRegisteredAddressFormData(state);
    const addresses = getRegisteredAddressInfo(state, applicantId);

    // if form data and the same object exists we need to replace it
    const filteredAddresses = (addresses || []).map(address => {
        return address.id === formData?.id ? formData : address;
    });

    return getSumYearsMonths(filteredAddresses);
};

// Use inside registered address to validate if the first card in the stacker
// is address or continue to next section
export const getRegisteredAddressTimeLive = (
    state: ReduxState,
    applicantId?: ApplicantId
): { years: number; months: number } => {
    const addresses = getRegisteredAddressInfo(state, applicantId);

    return getSumYearsMonths(addresses);
};

// Use inside registered address form
export const getRegisteredAddressTimeLiveForm = (
    state: ReduxState,
    applicantId?: ApplicantId
): { years: number; months: number } => {
    const formData = getRegisteredAddressFormData(state);
    const addresses = getRegisteredAddressInfo(state, applicantId);

    // We always need the current form to be into `addresses` array
    const filteredAddresses =
        addresses && formData
            ? addresses.filter(({ id }) => id !== formData.id).concat(formData)
            : addresses;

    return getSumYearsMonths(filteredAddresses);
};

export const getSumYearsMonths = (addresses: RegisteredAddress[]) => {
    const years = addresses
        ? addresses.reduce(
              (acc, { occupiedYears = 0 }) => acc + occupiedYears,
              0
          )
        : 0;

    const months = addresses
        ? addresses.reduce(
              (acc, { occupiedMonths = 0 }) => acc + occupiedMonths,
              0
          )
        : 0;

    return formatYearsMonths(years, months);
};

export const formatYearsMonths = (
    enteredYears: number,
    enteredMonths: number
): { years: number; months: number } => {
    const accMonths = enteredYears * 12 + enteredMonths;
    return {
        years: parseInt((accMonths / 12).toString()),
        months: accMonths % 12
    };
};

export const getRegisteredAddressHasEnoughHistory = (
    state: ReduxState,
    applicantId?: ApplicantId
): boolean => {
    const { years, months } = getRegisteredAddressTime(state, applicantId);

    // 3 years min of previous addresses
    return getIsEnoughHistory(years, months);
};

export const getRegisteredAddressHasEnoughHistoryLiveForm = (
    state: ReduxState,
    applicantId?: ApplicantId
): boolean => {
    const { years, months } = getRegisteredAddressTimeLiveForm(
        state,
        applicantId
    );

    // 3 years min of previous addresses
    return getIsEnoughHistory(years, months);
};

export const getRegisteredAddressHasEnoughHistoryLive = (
    state: ReduxState,
    applicantId?: ApplicantId
): boolean => {
    const { years, months } = getRegisteredAddressTimeLive(state, applicantId);

    // 3 years min of previous addresses
    return getIsEnoughHistory(years, months);
};

export const getRegisteredAddressType = (state: ReduxState) => {
    const formData = getRegisteredAddressFormData(state);

    return formData && formData.situation;
};

export const getTargetPropertyFormData = (
    state: ReduxState
): TargetProperty => {
    const formValues = getFormValues(APPLICATION_FORMS.TARGET_PROPERTY)(
        state
    ) as any;
    return formValues;
};

//
// Employments
//

export const getEmploymentsFormData = (state: ReduxState): Employment =>
    getFormValues(APPLICATION_FORMS.EMPLOYMENT_SITUATION)(state) as any;

export const getEmploymentsIncomeType = (
    state: ReduxState
): IncomeTypes | undefined => {
    const formValues = getEmploymentsFormData(state);
    return formValues ? formValues.incomeType : undefined;
};

export const getEmploymentsPensionType = (
    state: ReduxState
): IncomeTypes | undefined => {
    const formValues: any = getFormValues(
        APPLICATION_FORMS.EMPLOYMENT_SITUATION
    )(state);
    return formValues ? formValues.pensionType : undefined;
};

export const getEmployments = (state: ReduxState): Employment[] => {
    let applicantIncome = memorizedGetApplicantIncome(state);

    if (applicantIncome && applicantIncome.employments) {
        const employments = (applicantIncome.employments || []).map(
            (employment, id) => {
                return {
                    ...employment,
                    employer: {
                        ...employment.employer,
                        address:
                            employment.employer &&
                            employment.employer.address &&
                            getAddressNoAutoFillFields(
                                employment.employer.address
                            )
                    }
                };
            }
        );

        applicantIncome = { ...applicantIncome, employments: [...employments] };
    }

    return applicantIncome?.employments || [];
};

export const hasCurrentEmployment = (state: ReduxState): boolean => {
    const employments = getEmployments(state);

    return (
        employments.filter(employment => employment.isCurrent === true)
            .length >= 1
    );
};

export const getHasAtLeastOneCurrentEmployment = (
    state: ReduxState
): boolean => {
    const formData = getEmploymentsFormData(state);
    const employments = getEmployments(state);

    const filteredEmployments = employments.map(employment =>
        employment.id === formData?.id
            ? {
                  ...employment,
                  ...formData
              }
            : employment
    );

    if (filteredEmployments.length === 0 && formData) {
        // first income to be saved (not existing yet in redux storage),
        // add it to the array.
        filteredEmployments.push(formData);
    }

    const withCurrent: IncomeTypes[] = [
        'HOURLY',
        'SALARIED',
        'COMMISSIONS',
        'HOURLY_COMMISSIONS',
        'SELF_EMPLOYED'
    ];

    const isAllFalsy = filteredEmployments.every(
        employment =>
            withCurrent.includes(employment.incomeType)
                ? !employment.isCurrent
                : false // PENSION and NONE are like isCurrent = true
    );
    const hasOneCurrent = !isAllFalsy;

    return hasOneCurrent;
};

export const getIsTotalEmploymentHistoryEnough = (
    state: ReduxState
): boolean => {
    const formData = getEmploymentsFormData(state);
    const employments = getEmployments(state);

    const filteredEmployments =
        employments && formData
            ? employments.filter(({ id }) => id !== formData.id)
            : employments;

    const years = getTotalEmployedYears(filteredEmployments, formData);
    const months = getTotalEmployedMonths(filteredEmployments, formData);

    // need at least three years of employment history
    return getIsEnoughHistory(years, months);
};

export const getIsTotalEmploymentHistoryEnoughNoForm = (
    state: ReduxState
): boolean => {
    const employments = getEmployments(state);

    const filteredEmployments = employments;

    const years = getEmployedYears(filteredEmployments);
    const months = getEmployedMonths(filteredEmployments);

    // need at least three years of employment history
    return getIsEnoughHistory(years, months);
};

export const getTotalEmployedYears = (
    employments: Employment[],
    formData: Employment
): number =>
    getEmployedYears(employments) +
    (formData && formData.employedYears ? formData.employedYears : 0);

export const getTotalEmployedMonths = (
    employments: Employment[],
    formData: Employment
): number =>
    getEmployedMonths(employments) +
    (formData && formData.employedMonths ? formData.employedMonths : 0);

const getEmployedYears = (employments: Employment[]): number =>
    getTotal(employments, ({ employedYears }) => employedYears || 0);

const getEmployedMonths = (employments: Employment[]): number =>
    getTotal(employments, ({ employedMonths }) => employedMonths || 0);

const getTotal = (
    employments: Employment[],
    getter: (e: Employment) => number
): number =>
    (employments &&
        employments.length &&
        employments.reduce((acc, item) => {
            if (!item) return acc;
            return acc + getter(item);
        }, 0)) ||
    0;

export const getEmployedTime = (
    employments: Employment[]
): { years: number; months: number } => {
    const years = getEmployedYears(employments);
    const months = getEmployedMonths(employments);

    return formatYearsMonths(years, months);
};

export const getIsEnoughHistory = (years: number, months: number): boolean =>
    years * 12 + months >= 36;

export const getTargetProperty = (
    state: ReduxState
): TargetProperty | undefined => {
    const application = getActiveApplication(state);

    return application?.property || application?.propertyToPurchase;
};

export const getIsFoundTargetProperty = (
    state: ReduxState
): boolean | undefined => {
    const targetProperty = getTargetProperty(state);

    return targetProperty?.isFound;
};

export const getRenewingPropertyFormData = (
    state: ReduxState
): TargetProperty | undefined => {
    const formValues = getFormValues(APPLICATION_FORMS.RENEWING_PROPERTY)(
        state
    ) as any;
    return formValues;
};

export const getOtherPropertyAfterTransactionPurpose = (state: ReduxState) => {
    const formValues: any = getOtherPropertiesFormData(state);
    return formValues && formValues.afterTransactionPurpose;
};

export const getIsOtherPropertyRental = (state: ReduxState) => {
    const formValues: any = getOtherPropertiesFormData(state);

    return (
        formValues &&
        (formValues.afterTransactionPurpose === 'OWNER_OCCUPIED_AND_RENTAL' ||
            formValues.afterTransactionPurpose === 'RENTAL')
    );
};

export const getIsOtherPropertyCondo = (state: ReduxState) => {
    const formValues: any = getOtherPropertiesFormData(state);
    return formValues && formValues.type === 'CONDO';
};

export const getAllOtherProperties = (state: ReduxState): OtherProperty[] => {
    const application = getActiveApplication(state);
    const currentApplicantId = getCurrentApplicationApplicantId(state);

    let properties: any =
        application?.applicants[currentApplicantId].properties;

    properties = (properties || []).map((address, id) => {
        return {
            ...address,
            address: getAddressNoAutoFillFields(
                address.address,
                `${ADDRESS_SECTIONS.otherProperties}_${address.id}`
            )
        };
    });

    return properties;
};

export const getSoldOtherProperties = (state: ReduxState) => {
    const application = getActiveApplication(state);
    const currentApplicantId = getCurrentApplicationApplicantId(state);
    const allAssets = getAllOtherPropertyAssets(state) || [];

    const properties: any =
        application?.applicants[currentApplicantId].properties;
    const soldProperties: any = [];

    (properties || []).forEach((property, id) => {
        if (property.afterTransactionPurpose === 'SOLD') {
            soldProperties.push({
                ...allAssets.find(
                    ({ existingPropertyId }: any) =>
                        existingPropertyId === property.id
                ),
                ...property,
                address: getAddressNoAutoFillFields(
                    property.address,
                    `${ADDRESS_SECTIONS.otherProperties}_${property.id}`
                ),
                id:
                    allAssets.find(
                        ({ existingPropertyId }: any) =>
                            existingPropertyId === property.id
                    )?.id || property.id
            });
        }
    });

    return soldProperties;
};

export const getApplicationOtherPropertyInitializedFields = (
    state: ReduxState
) => {
    const formValues: any = getOtherPropertiesFormData(state);

    return formValues
        ? {
              address: formValues.address,
              currentPurpose: formValues.currentPurpose,
              afterTransactionPurpose: formValues.afterTransactionPurpose
          }
        : undefined;
};

export const getOtherPropertyTransitionNextPageParams = (
    state: ReduxState
): {
    url: string;
    sectionName: string;
    applicantId?: any;
} => {
    if (getApplicationType(state) !== 'NEW') {
        return {
            url: `/application/banking-details`,
            sectionName: 'application.bankingDetails'
        };
    }

    const soldOtherProperties = getSoldOtherProperties(state);

    const propertyPurposeType = !!getOtherPropertyAfterTransactionPurpose(
        state
    );

    if (soldOtherProperties.length > 0 || propertyPurposeType) {
        return {
            url: `/application/existing-property-downpayment`,
            sectionName: 'application.existingPropertyDownpayment'
        };
    }

    return {
        url: `/application/banking-details`,
        sectionName: 'application.bankingDetails'
    };
};

export const getBankingDetailsTransitionNextPageParams = (
    state: ReduxState
): { url: string; sectionName: string; applicantId?: any } => {
    const currentApplicantId = getCurrentApplicationApplicantId(state);

    return {
        url: `/application/co-applicant`,
        applicantId: currentApplicantId,
        sectionName: 'application.coApplicant'
    };
};

export const getCoApplicantLink = (state: ReduxState): string => {
    const isNew = getApplicationType(state) === 'NEW';
    const currentApplicantId = getCurrentApplicationApplicantId(state);

    if (isNew) {
        return `${Routes.applicationTargetProperty}?applicant=${currentApplicantId}&sectionName=application.targetProperty`;
    }

    return `${Routes.applicationRenewingProperty}?applicant=${currentApplicantId}&sectionName=application.renewingProperty`;
};

export const getCoapplicantDetails = (state: ReduxState): any => {
    const formValues = getFormValues(APPLICATION_FORMS.APPLICATION_COAPPLICANT)(
        state
    ) as any;

    return formValues && formValues.applicantInfo;
};

export const getApplicationType = (
    state: ReduxState
): ApplicationType | undefined => {
    const application = getActiveApplication(state);

    return application?.type;
};

export const getDocumentsEmptyCount = (
    state,
    step: DocumentStep = DocumentStep.All
): number => path(['documents', 'counts', step, 'emptyCount'], state) || 0;

export const getDocumentsBrokerUnapprovedCount = (
    state,
    step: DocumentStep = DocumentStep.All
): number =>
    path(['documents', 'counts', step, 'brokerUnapprovedCount'], state) || 0;

export const getDocumentsStepCountsBadge = (
    state,
    step: DocumentStep = DocumentStep.All
): number =>
    getDocumentsEmptyCount(state, step) +
    getDocumentsBrokerUnapprovedCount(state, step);

export const showTracker = (state: ReduxState): boolean => {
    if (!getApplicationState(state)) return false;

    return Object.prototype.hasOwnProperty.call(
        state.documents.counts,
        DocumentStep.All
    );
};

export const hasApplicationId = (state: ReduxState): boolean => {
    if (!getApplicationState(state)) return false;

    const applicationId = getApplicationId(state);

    return !!applicationId;
};

export const hasBankingDetails = (state: any, applicantId: ApplicantId) => {
    const applicant = getApplicant(state, applicantId);
    if (!applicant) return false;

    const institution =
        applicant.primaryBankingInstitution === 'OTHER'
            ? applicant.primaryBankingInstitutionOther
            : applicant.primaryBankingInstitution;
    return !!institution;
};

const getApplicationOfType = (
    state: any,
    quoteType: ApplicationType,
    applicationState: ApplicationState
): Application | undefined => {
    const applications = getApplications(state);

    return applications.find(
        (application: Application) =>
            application.applicationState === applicationState &&
            application.type === quoteType
    );
};

const getHasApplicationOfType = (
    state: any,
    quoteType: ApplicationType,
    applicationState: ApplicationState
): boolean => Boolean(getApplicationOfType(state, quoteType, applicationState));

// Look for application created (not yet submitted) of same type
export const getshowAppBeforeCreate = (state: any) => {
    const { search } = state.router.location;

    const queries =
        search &&
        qs.parse(search, {
            ignoreQueryPrefix: true
        });

    const quoteType = queries.quoteType;

    const hasApplicationOfType = getHasApplicationOfType(
        state,
        quoteType as ApplicationType,
        ApplicationState.Created
    );

    return hasApplicationOfType;
};

export const getIsValidDownPaymentAmount = (state: any): boolean => {
    const property = getTargetProperty(state);
    const downPaymentAmount = getDownPaymentAmount(state);

    if (property?.purchasePrice && downPaymentAmount) {
        return isViableDownPayment(
            property?.purchasePrice,
            downPaymentAmount,
            property?.purpose !== 'OWNER_OCCUPIED'
        );
    }
    return false;
};

export const getDownPaymentMessage = (
    state: any
): TranslationKeys | undefined => {
    const property = getTargetProperty(state);
    const downPaymentAmount = getDownPaymentAmount(state);

    return getDownPaymentInformation(
        property?.purchasePrice,
        downPaymentAmount,
        property?.purpose !== 'OWNER_OCCUPIED'
    );
};

export const getMinimumDownPayment = (
    state: any
): { percentage: number; value: number } => {
    const downPayment = getDownPaymentAmount(state);
    const downPaymentPercentage = getDownpaymentPercentage(state);
    const targetProperty = getTargetProperty(state);
    const propertyValue = targetProperty?.purchasePrice || 5000000; // if there is no property price we set default value to 500k
    const isRentalProperty = targetProperty?.purpose !== 'OWNER_OCCUPIED';

    const minDownPayment = 5;
    let minDownPaymentPercentage = minDownPayment;

    if (!propertyValue) {
        minDownPaymentPercentage = minDownPayment;
        return {
            percentage: minDownPaymentPercentage,
            value: getDownPaymentValue(propertyValue, minDownPaymentPercentage)
        };
    }

    const downPaymentRemainder = (propertyValue - 500000) * 0.1;

    // if rental property and down payment is less than 20% then we don't need to check the rest of the cases
    // if we don't do this then we will get the minimum down payment for rental property as 5% which is incorrect
    if (isRentalProperty && downPaymentPercentage < 20) {
        minDownPaymentPercentage = 20;

        return {
            percentage: minDownPaymentPercentage,
            value: getDownPaymentValue(minDownPaymentPercentage, propertyValue)
        };
    }

    // 1 Million plus
    if (propertyValue >= 1000000 && downPaymentPercentage <= 20) {
        minDownPaymentPercentage = 20;
    }

    // more than 500k
    if (propertyValue > 500000 && propertyValue < 1000000) {
        if (25000 + downPaymentRemainder > downPayment) {
            minDownPaymentPercentage = getDownPaymentPercentageByValue(
                propertyValue,
                25000 + downPaymentRemainder,
                false
            );
        }
    }

    // less than 500k
    if (propertyValue <= 500000 && downPaymentPercentage <= 5)
        minDownPaymentPercentage = minDownPayment;

    return {
        percentage: Math.round(minDownPaymentPercentage * 100) / 100,
        value: getDownPaymentValue(minDownPaymentPercentage, propertyValue)
    };
};

export const getHasApplicationsInProgress = (state: any) => {
    const INPROGRESS_APPLICATION_STATES: ApplicationState[] = [
        ApplicationState.Created,
        ApplicationState.Submitted,
        ApplicationState.UnderRevision,
        ApplicationState.NotesSubmitted,
        ApplicationState.LenderSubmitted,
        ApplicationState.LenderApproved,
        ApplicationState.PendingCommitmentSignature,
        ApplicationState.PendingConditions
    ];

    return getApplications(state).some((application: Application) =>
        INPROGRESS_APPLICATION_STATES.includes(application.applicationState)
    );
};

export const selectMortgageBuilderQueueOpen = createSelector(
    getTargetProperty,
    (targetProperty: TargetProperty | undefined): boolean => {
        const region = targetProperty?.address.stateCode as Region;

        const isQueueOpen = mortgageBuilderQueueOpen(region);

        return isQueueOpen;
    }
);

export const selectTargetPropertyRegion = createSelector(
    getTargetProperty,
    (targetProperty: TargetProperty | undefined) =>
        targetProperty?.address.stateCode as Region
);

export const getApplication = (state: any): ReduxState => state.application;

export const getApplicationAdvisor = (state: any): Advisor =>
    state.application.advisor;
