/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as H from 'history';

type Props = {
    href?: string;
    to?: H.LocationDescriptor;
    openInNewTab?: boolean;
    onClick?: (v: any) => void;
    children?: any;
};

const WithLink = ({ href, to, children, openInNewTab, onClick }: Props) => {
    if (href) {
        return (
            <a
                onClick={onClick}
                href={href}
                target={openInNewTab ? '_blank' : ''}
                rel={openInNewTab ? 'noopener' : ''}
            >
                {children}
            </a>
        );
    }

    if (to) {
        return (
            <Link onClick={onClick} to={to}>
                {children}
            </Link>
        );
    }

    return children;
};

export { WithLink };
