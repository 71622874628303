import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { usePopperTooltip } from 'react-popper-tooltip';
import { compose } from 'redux';
import { withSizesHoc as WithSizes, WithSizesProps } from 'hocs/with-sizes.hoc';
import { Icon, IconNames } from '@nesto/design-system/v2';
import { useIntl } from 'react-intl';

import { Typograph } from 'components/typograph';

import {
    WidthProps,
    MaxWidthProps,
    SpaceProps,
    FontSizeProps,
    FontWeightProps,
    LineHeightProps,
    TextAlignProps,
    FontStyleProps,
    BorderProps
} from 'styled-system';

import 'react-popper-tooltip/dist/styles.css';

const TooltipWrapper = styled.div`
    &.tooltip-container {
        --tooltipBackground: #3b3b3b;
        border: none;
        border-radius: 4px;
        padding: 13px 5px 13px 16px;
        color: white;
        z-index: ${props => props.theme.zIndex.heaven + 1};
        max-width: 80%;

        .tooltip-arrow::before {
            border: none !important; // feels bad but need to overpower
        }
    }
`;

const UnstyledButton = styled.button`
    background: none;
    padding-right: 0;
    border: none;
    cursor: pointer;
    display: flex;
`;

type Props = {
    triggerIcon: IconNames; // new icons can be continued to be added into design-system/v2
    content: string;
    iconSize?: string | number;
    iconColor?: string;
    label?: string;
    contentProps?: { setInnerHtml?: true | undefined } & WidthProps &
        MaxWidthProps &
        SpaceProps &
        FontSizeProps &
        FontWeightProps &
        LineHeightProps &
        TextAlignProps &
        FontStyleProps &
        BorderProps;
};

const TooltipView = ({
    triggerIcon,
    iconSize = 13,
    iconColor = 'currentColor',
    content,
    label = 'tooltip.triggerMessage',
    contentProps
}: Props & WithSizesProps) => {
    const [controlledVisible, setControlledVisible] = useState(false);

    const intl = useIntl();

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible
    } = usePopperTooltip({
        trigger: ['click', 'focus', 'hover'],
        placement: 'top-start', // on mobile, top-start makes the tooltip touch the edge of the screen
        visible: controlledVisible,
        onVisibleChange: setControlledVisible
    });

    const handleKeyDown = ({ key }) => {
        // close on escape :)
        if (key === 'Escape') {
            setControlledVisible(false);
        }
    };

    useEffect(() => {
        if (visible) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [visible]);

    return (
        <>
            <UnstyledButton
                ref={setTriggerRef}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setControlledVisible(!controlledVisible);
                }}
                aria-label={intl.formatMessage({
                    id: label
                })}
            >
                <Icon name={triggerIcon} size={iconSize} color={iconColor} />
            </UnstyledButton>
            {visible && (
                <TooltipWrapper
                    ref={setTooltipRef}
                    {...getTooltipProps({ className: 'tooltip-container' })}
                >
                    <Typograph
                        fontSize="12px"
                        fontWeight="500"
                        tx={content}
                        {...contentProps}
                    />
                    <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                </TooltipWrapper>
            )}
        </>
    );
};

export const Tooltip = compose<React.FC<Props>>(WithSizes)(TooltipView);
