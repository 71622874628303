import {
    createSlice,
    PayloadAction,
    Reducer,
    createSelector
} from '@reduxjs/toolkit';
import { downPaymentRatios } from 'constants/ratesConstants';
import {
    ApplicationType,
    Lender,
    TargetProperty,
    TargetPropertyMortgage
} from 'models/application/Application';
import { RootState } from 'reducers';
import {
    getApplicationType,
    getTargetProperty
} from 'reducers/application.selectors';

interface GetAQuoteFlowFields {
    downPaymentRatio: downPaymentRatios;
    lender: Lender;
    mortgageAmount: number;
    ownerOccupied: boolean;
    propertyValue: number;
    renewalSchedule: string;
    formName: string;
    additionalFundAmount: number;
}

enum GaqApplicationType {
    NEW_MORTGAGE = 'NEW',
    RENEWAL_MORTGAGE = 'RENEWAL',
    REFINANCE_MORTGAGE = 'REFINANCE'
}

enum PartnerMatched {
    MATCHED = 'MATCHED',
    UNMATCHED = 'UNMATCHED'
}
type YesNo = 'YES' | 'NO';

interface MergedTargetProperty extends TargetProperty {
    additionalFundAmount: number;
    lender: string;
}

export interface GetAQuoteFlowValues {
    formName: string;
    partnerMatched: boolean;
    mortgage: Partial<TargetPropertyMortgage>;
    property: Partial<TargetProperty>;
}

const initialState: GetAQuoteFlowValues = {
    formName: '',
    partnerMatched: false,
    mortgage: {},
    property: {}
};

export const GetAQuoteFlowReducer = createSlice({
    name: 'GetAQuoteFlowValues',
    initialState,
    reducers: {
        updateGAQFlow(
            state: GetAQuoteFlowValues,
            action: PayloadAction<GetAQuoteFlowFields>
        ): GetAQuoteFlowValues {
            const isMortgageInsured = !!action.payload.lender ? 'YES' : 'NO';
            const isMortgageOwnerOccupied = action.payload.ownerOccupied
                ? 'OWNER_OCCUPIED'
                : '';

            return {
                ...state,
                formName: GaqApplicationType[action.payload.formName],
                mortgage: {
                    insuranceQuestionOriginallyInsured: isMortgageInsured,
                    balance: action.payload.mortgageAmount,
                    lender: action.payload.lender
                },
                property: {
                    purpose: isMortgageOwnerOccupied,
                    estimatedPropertyValue: action.payload.propertyValue,
                    additionalFundAmount: action.payload.additionalFundAmount
                }
            };
        },
        updateFormName(
            state: GetAQuoteFlowValues,
            action: PayloadAction<string>
        ): GetAQuoteFlowValues {
            return {
                ...state,
                formName: action.payload
            };
        },
        updatePartnerMatched(
            state: GetAQuoteFlowValues,
            action: PayloadAction<string>
        ): GetAQuoteFlowValues {
            const isMatched = action.payload === PartnerMatched.MATCHED;

            return {
                ...state,
                partnerMatched: isMatched
            };
        }
    }
});

export const {
    updateGAQFlow,
    updateFormName,
    updatePartnerMatched
} = GetAQuoteFlowReducer.actions;

export const getGAQFlowValues = (state: RootState) =>
    state.GetAQuoteFlowValues as GetAQuoteFlowValues;

export const getGAQApplicationType = createSelector(
    getGAQFlowValues,
    (state: GetAQuoteFlowValues) => state.formName as ApplicationType
);

export const getTransformedTargetPropertyValues = createSelector(
    getGAQFlowValues,
    getTargetProperty,
    getApplicationType,
    (
        getAQuoteValues: GetAQuoteFlowValues,
        targetProperty: TargetProperty | undefined,
        applicationType: ApplicationType | undefined
    ) => {
        const applicationMortgages = targetProperty?.mortgages;
        const updatedMortgages = applicationMortgages ?? [
            {} as TargetPropertyMortgage
        ];
        const applicationInsuredQuestion =
            updatedMortgages[0]?.insuranceQuestionOriginallyInsured;
        const gaqInsuredQuestion = getAQuoteValues.mortgage
            .insuranceQuestionOriginallyInsured as YesNo;

        updatedMortgages[0] = {
            ...updatedMortgages[0],
            ...getAQuoteValues?.mortgage,
            insuranceQuestionOriginallyInsured: !!applicationInsuredQuestion?.length
                ? applicationInsuredQuestion
                : gaqInsuredQuestion
        };

        const applicationData = {
            ...targetProperty,
            ...getAQuoteValues.property,
            mortgages: [updatedMortgages[0]]
        };

        const mergedFormValues =
            applicationType === getAQuoteValues.formName
                ? applicationData
                : {
                      ...targetProperty,
                      mortgages: [updatedMortgages[0]]
                  };

        return mergedFormValues as MergedTargetProperty | undefined;
    }
);

export const selectPartnerMatched = createSelector(
    getGAQFlowValues,
    (state: GetAQuoteFlowValues) => state?.partnerMatched
);

export default GetAQuoteFlowReducer.reducer as Reducer<typeof initialState>;
