import { add as dateAdd, formatISO, sub as dateSub } from 'date-fns';

import { TypographI18nKeys } from 'components/typograph';
import { SalutationsEn, SalutationsFr } from 'types/salutations';
import { maritalStatuses } from 'types/marital-status/marital-statuses';
import { creditScoreQuality } from 'types/credit-score/credit-score-quality';
import { Region, Timings } from 'types/application/application';
import { ApplicationType } from 'models/application/Application';
import { TranslationKeys } from 'messages';
import { rateTypes } from 'models/QualifiedRate';
import { AccountDeletionReason } from 'types/account';
import { Language } from 'types/language';

type ConstantType<T> = Array<{ label: TypographI18nKeys; value: T }>;

export enum WIZARDS {
    NEW_MORTGAGE_QUOTE = 'NEW_MORTGAGE',
    RENEWAL_MORTGAGE_QUOTE = 'RENEWAL_MORTGAGE',
    REFINANCE_MORTGAGE_QUOTE = 'REFINANCE_MORTGAGE'
}

export const SUMMARY_FORM = 'PREQUAL_SUMMARY_FORM';

export const PARTNERS_SETTINGS_FORM = 'PARTNERS_SETTINGS_FORM';

export const PARTNER_PICKLIST: ConstantType<string> = [
    { value: 'proprioDirect', label: 'partnerName.proprioDirect' }
];

export const ACCOUNT_DELETION_FEEDBACK_FORM = 'ACCOUNT_DELETION_FEEDBACK_FORM';

export const ACCOUNT_INFORMATION_FORM = 'ACCOUNT_INFORMATION_FORM';

export const APPLICATION_FORMS = {
    EMPLOYMENT_SITUATION: 'EMPLOYMENT_SITUATION',
    REGISTERED_ADDRESS: 'REGISTERED_ADDRESS',
    APPLICANT_INFO: 'APPLICANT_INFO',
    OTHER_PROPERTY: 'OTHER_PROPERTY',
    OTHER_INCOME: 'OTHER_INCOME',
    TARGET_PROPERTY: 'TARGET_PROPERTY',
    RENEWING_PROPERTY: 'RENEWING_PROPERTY',
    BANKING_DETAILS: 'BANKING_DETAILS',
    ASSETS_DOWN_PAYMENT: 'ASSETS_DOWN_PAYMENT',
    PROPERTY_DOWN_PAYMENT: 'PROPERTY_DOWN_PAYMENT',
    ADD_MANUAL_ADDRESS: 'ADD_MANUAL_ADDRESS',
    RATES_FILTER: 'RATES_FILTER',
    APPLICATION_COAPPLICANT: 'APPLICATION_COAPPLICANT'
};

export const PRODUCT_SELECTION_FORMS = {
    APPLICATION_DETAILS: 'APPLICATION_DETAILS'
};

export const WIZARD_FORM_TYPES = {
    GETAQUOTE: 'GETAQUOTE',
    PREQUAL: 'PREQUAL'
};

export const LOCALE_SRC = {
    BROWSER: 'BROWSER',
    USER_SELECTION: 'USER_SELECTION',
    LANDING_URL: 'LANDING_URL'
};

export const SCHEDULE_CALL_LINK = {
    en: 'https://calendly.com/nesto-en/nesto-intro-call',
    fr: 'https://calendly.com/nesto-fr/nesto-appel-d-introduction'
};

export const PHONE_NUMBERS = {
    en: '1-833-452-3541',
    fr: '1-833-452-3267'
};

export const WEBSITE_LINKS = {
    en: {
        website: 'https://www.nesto.ca',
        termsOfService: 'https://www.nesto.ca/terms-of-services/',
        privacyPolicy: 'https://www.nesto.ca/privacy-policy/',
        consentLink: 'https://www.nesto.ca/credit-consent-terms/',
        howItWorks: 'https://www.nesto.ca#how',
        contact: 'https://www.nesto.ca/contact/',
        career: 'https://www.nesto.ca/careers/'
    },
    fr: {
        website: 'https://www.nesto.ca/fr/',
        termsOfService: 'https://www.nesto.ca/fr/conditions-d-utilisation/',
        privacyPolicy: 'https://www.nesto.ca/fr/politique-de-confidentialite/',
        consentLink: 'https://www.nesto.ca/fr/credit-consent-form/',
        howItWorks: 'https://www.nesto.ca/fr#how',
        contact: 'https://www.nesto.ca/fr/contact/',
        career: 'https://www.nesto.ca/fr/carrieres/'
    }
};

export const BLOG_LINK = {
    en: 'https://www.nesto.ca/blog/',
    fr: 'https://www.nesto.ca/fr/blog/'
};

export const ABOUT_US_LINK = {
    en: 'https://www.nesto.ca/about-us',
    fr: 'https://www.nesto.ca/fr/about-us/'
};

export const COUNTRIES = [
    {
        value: 'CA',
        label: 'country.CA',
        default: 'Canada'
    },
    {
        value: 'US',
        label: 'country.US',
        default: 'United States'
    }
];

export const PROVINCES = [
    {
        value: Region.ON,
        label: 'provinces.ON',
        default: 'Ontario'
    },
    {
        value: Region.QC,
        label: 'provinces.QC',
        default: 'Quebec'
    },
    {
        value: Region.AB,
        label: 'provinces.AB',
        default: 'Alberta'
    },
    {
        value: Region.BC,
        label: 'provinces.BC',
        default: 'British Columbia'
    },
    {
        value: Region.MB,
        label: 'provinces.MB',
        default: 'Manitoba'
    },
    {
        value: Region.NB,
        label: 'provinces.NB',
        default: 'New Brunswick'
    },
    {
        value: Region.NS,
        label: 'provinces.NS',
        default: 'Nova Scotia'
    },
    {
        value: Region.NL,
        label: 'provinces.NL',
        default: 'New Foundland'
    },
    {
        value: Region.PE,
        label: 'provinces.PE',
        default: 'Prince Edward Island'
    },
    {
        value: Region.SK,
        label: 'provinces.SK',
        default: 'Saskatchewan'
    }
];

export const PROVINCES_WITH_DISABLED = [
    {
        value: Region.ON,
        label: 'provinces.ON',
        default: 'Ontario'
    },
    {
        value: Region.QC,
        label: 'provinces.QC',
        default: 'Quebec'
    },
    {
        value: Region.AB,
        label: 'provinces.AB',
        default: 'Alberta'
    },
    {
        value: Region.BC,
        label: 'provinces.BC',
        default: 'British Columbia'
    },
    {
        value: Region.MB,
        label: 'provinces.MB_NA',
        default: 'Manitoba',
        isDisabled: true
    },
    {
        value: Region.NB,
        label: 'provinces.NB',
        default: 'New Brunswick'
    },
    {
        value: Region.NS,
        label: 'provinces.NS',
        default: 'Nova Scotia'
    },
    {
        value: Region.NL,
        label: 'provinces.NL',
        default: 'New Foundland'
    },
    {
        value: Region.PE,
        label: 'provinces.PE',
        default: 'Prince Edward Island'
    },
    {
        value: Region.SK,
        label: 'provinces.SK',
        default: 'Saskatchewan'
    }
];

export const PROVINCES_BLOCK = [''];

// EN: https://www23.statcan.gc.ca/imdb/p3VD.pl?Function=getVD&TVD=53971
// FR: https://www23.statcan.gc.ca/imdb/p3VD_f.pl?Function=getVD&TVD=53971
export const US_STATES = [
    { value: 'AL', label: 'states.AL', default: 'Alabama' },
    { value: 'AK', label: 'states.AK', default: 'Alaska' },
    { value: 'AZ', label: 'states.AZ', default: 'Arizona' },
    { value: 'AR', label: 'states.AR', default: 'Arkansas' },
    { value: 'CA', label: 'states.CA', default: 'California' },
    { value: 'CO', label: 'states.CO', default: 'Colorado' },
    { value: 'CT', label: 'states.CT', default: 'Connecticut' },
    { value: 'DE', label: 'states.DE', default: 'Delaware' },
    { value: 'DC', label: 'states.DC', default: 'District of Columbia' },
    { value: 'FL', label: 'states.FL', default: 'Florida' },
    { value: 'GA', label: 'states.GA', default: 'Georgia' },
    { value: 'HI', label: 'states.HI', default: 'Hawaii' },
    { value: 'ID', label: 'states.ID', default: 'Idaho' },
    { value: 'IL', label: 'states.IL', default: 'Illinois' },
    { value: 'IN', label: 'states.IN', default: 'Indiana' },
    { value: 'IA', label: 'states.IA', default: 'Iowa' },
    { value: 'KS', label: 'states.KS', default: 'Kansas' },
    { value: 'KY', label: 'states.KY', default: 'Kentucky' },
    { value: 'LA', label: 'states.LA', default: 'Louisiana' },
    { value: 'ME', label: 'states.ME', default: 'Maine' },
    { value: 'MD', label: 'states.MD', default: 'Maryland' },
    { value: 'MA', label: 'states.MA', default: 'Massachusetts' },
    { value: 'MI', label: 'states.MI', default: 'Michigan' },
    { value: 'MN', label: 'states.MN', default: 'Minnesota' },
    { value: 'MS', label: 'states.MS', default: 'Mississippi' },
    { value: 'MO', label: 'states.MO', default: 'Missouri' },
    { value: 'MT', label: 'states.MT', default: 'Montana' },
    { value: 'NE', label: 'states.NE', default: 'Nebraska' },
    { value: 'NV', label: 'states.NV', default: 'Nevada' },
    { value: 'NH', label: 'states.NH', default: 'New Hampshire' },
    { value: 'NJ', label: 'states.NJ', default: 'New Jersey' },
    { value: 'NM', label: 'states.NM', default: 'New Mexico' },
    { value: 'NY', label: 'states.NY', default: 'New York' },
    { value: 'NC', label: 'states.NC', default: 'North Carolina' },
    { value: 'ND', label: 'states.ND', default: 'North Dakota' },
    { value: 'OH', label: 'states.OH', default: 'Ohio' },
    { value: 'OK', label: 'states.OK', default: 'Oklahoma' },
    { value: 'OR', label: 'states.OR', default: 'Oregon' },
    { value: 'PA', label: 'states.PA', default: 'Pennsylvania' },
    { value: 'RI', label: 'states.RI', default: 'Rhode Island' },
    { value: 'SC', label: 'states.SC', default: 'South Carolina' },
    { value: 'SD', label: 'states.SD', default: 'South Dakota' },
    { value: 'TN', label: 'states.TN', default: 'Tennessee' },
    { value: 'TX', label: 'states.TX', default: 'Texas' },
    { value: 'UT', label: 'states.UT', default: 'Utah' },
    { value: 'VT', label: 'states.VT', default: 'Vermont' },
    { value: 'VA', label: 'states.VA', default: 'Virginia' },
    { value: 'WA', label: 'states.WA', default: 'Washington' },
    { value: 'WV', label: 'states.WV', default: 'West Virginia' },
    { value: 'WI', label: 'states.WI', default: 'Wisconsin' },
    { value: 'WY', label: 'states.WY', default: 'Wyoming' }
];

export const CONDO_FEES_FREQUENCY = [
    {
        value: 'MONTHLY',
        label: 'frequency.monthly',
        namePath: 'frequency.monthly'
    },
    {
        value: 'ANNUALLY',
        label: 'incomeFrequency.annually',
        namePath: 'incomeFrequency.annually'
    }
];

export const APPLICATION_MORTGAGE_FREQUENCY = [
    {
        value: 'MONTHLY',
        namePath: 'frequency.monthly',
        default: 'Monthly',
        label: 'frequency.monthly' as TypographI18nKeys
    },
    {
        value: 'SEMIMONTHLY',
        namePath: 'frequency.semi-monthly',
        default: 'Semi-monthly',
        label: 'frequency.semi-monthly' as TypographI18nKeys
    },
    {
        value: 'BIWEEKLY',
        namePath: 'frequency.bi-weekly',
        default: 'Bi-weekly',
        label: 'frequency.bi-weekly' as TypographI18nKeys
    },
    {
        value: 'WEEKLY',
        namePath: 'frequency.weekly',
        default: 'Weekly',
        label: 'frequency.weekly' as TypographI18nKeys
    }
];

export const APPLICATION_INCOME_FREQUENCY = [
    {
        value: 'ANNUALLY',
        namePath: 'incomeFrequency.annually',
        default: 'Annually',
        label: 'incomeFrequency.annually' as TypographI18nKeys
    },
    {
        value: 'SEMIANNUALLY',
        namePath: 'incomeFrequency.semiAnnually',
        default: 'Semi-annually',
        label: 'incomeFrequency.semiAnnually' as TypographI18nKeys
    },
    ...APPLICATION_MORTGAGE_FREQUENCY
];

export const APPLICATION_INCOME_FREQUENCY_WITH_ACCELERATED = [
    ...APPLICATION_MORTGAGE_FREQUENCY,
    {
        value: 'ACCELERATED_BIWEEKLY',
        label: 'frequency.bi-weekly-accelerated',
        namePath: 'frequency.bi-weekly-accelerated'
    },
    {
        value: 'ACCELERATED_WEEKLY',
        label: 'frequency.weekly-accelerated',
        namePath: 'frequency.weekly-accelerated'
    }
];

enum IndustriesEnum {
    BANK_FINANCE = 'BANK_FINANCE',
    MANUFACTURING = 'MANUFACTURING',
    GOVERNMENT = 'GOVERNMENT',
    HEALTH = 'HEALTH',
    RETAIL = 'RETAIL',
    HIGH_TECH = 'HIGH_TECH',
    EDUCATION = 'EDUCATION',
    LEISURE_ENTERTAINMENT = 'LEISURE_ENTERTAINMENT',
    SERVICES = 'SERVICES',
    TRANSPORTATION = 'TRANSPORTATION',
    NATURAL_RESOURCES = 'NATURAL_RESOURCES',
    CONSTRUCTION = 'CONSTRUCTION',
    FARMING = 'FARMING'
}

export type IndustryType = keyof typeof IndustriesEnum;

export const INDUSTRY: ConstantType<IndustryType> = [
    {
        value: IndustriesEnum.BANK_FINANCE,
        label: 'industry.bank'
    },
    {
        value: IndustriesEnum.MANUFACTURING,
        label: 'industry.manufacturing'
    },
    {
        value: IndustriesEnum.GOVERNMENT,
        label: 'industry.government'
    },
    {
        value: IndustriesEnum.HEALTH,
        label: 'industry.health'
    },
    {
        value: IndustriesEnum.RETAIL,
        label: 'industry.retail'
    },
    {
        value: IndustriesEnum.HIGH_TECH,
        label: 'industry.highTech'
    },
    {
        value: IndustriesEnum.EDUCATION,
        label: 'industry.education'
    },
    {
        value: IndustriesEnum.LEISURE_ENTERTAINMENT,
        label: 'industry.leisure'
    },
    {
        value: IndustriesEnum.SERVICES,
        label: 'industry.services'
    },
    {
        value: IndustriesEnum.TRANSPORTATION,
        label: 'industry.transportation'
    },
    {
        value: IndustriesEnum.NATURAL_RESOURCES,
        label: 'industry.naturalResources'
    },
    {
        value: IndustriesEnum.CONSTRUCTION,
        label: 'industry.construction'
    },
    {
        value: IndustriesEnum.FARMING,
        label: 'industry.farming'
    }
];

export const PROPERTY_TYPE_APPLICATION = [
    {
        value: 'DETACHED',
        label: 'applicationProperty.detached',
        defaultValue: 'detached'
    },
    {
        value: 'SEMI_DETACHED',
        label: 'applicationProperty.semidetached',
        defaultValue: 'semi detached'
    },
    {
        value: 'DUPLEX_DETACHED',
        label: 'applicationProperty.duplex_detached',
        defaultValue: 'duplex setached'
    },
    {
        value: 'DUPLEX_SEMI_DETACHED',
        label: 'applicationProperty.duplex_semi_detached',
        defaultValue: 'duplex semi-detached'
    },
    {
        value: 'ROW_HOUSING',
        label: 'applicationProperty.row_housing',
        defaultValue: 'row housing'
    },
    {
        value: 'APARTMENT_LOW_RISE',
        label: 'applicationProperty.apartment_low_rise',
        defaultValue: 'apartment low-rise'
    },
    {
        value: 'APARTMENT_HIGH_RISE',
        label: 'applicationProperty.apartment_hi_rise',
        defaultValue: 'apartment hi-rise'
    },
    {
        value: 'MOBILE',
        label: 'applicationProperty.mobile',
        defaultValue: 'mobile'
    },
    {
        value: 'TRI_PLEX_DETACHED',
        label: 'applicationProperty.tri_plex_detached',
        defaultValue: 'tri-plex detached'
    },
    {
        value: 'TRI_PLEX_SEMI_DETACHED',
        label: 'applicationProperty.tri_plex_semi_detached',
        defaultValue: 'tri-plex semi-detached'
    },
    {
        value: 'STACKED',
        label: 'applicationProperty.stacked',
        defaultValue: 'stacked'
    },
    {
        value: 'MODULAR_HOME_DETACHED',
        label: 'applicationProperty.modular_home_detached',
        defaultValue: 'modular mode detached'
    },
    {
        value: 'MODULAR_HOME_SEMI_DETACHED',
        label: 'applicationProperty.modular_home_semi_detached',
        defaultValue: 'modular home semi-detached'
    },
    {
        value: 'FOUR_PLEX_DETACHED',
        label: 'applicationProperty.four_plex_detached',
        defaultValue: 'four-plex detached'
    },
    {
        value: 'FOUR_PLEX_SEMI_DETACHED',
        label: 'applicationProperty.four_plex_semi_detached',
        defaultValue: 'four-plex semi-detached'
    }
];

export const OTHER_PROPERTY_TERM_TYPE = [
    {
        value: 'OPEN',
        label: 'application.otherProperty.open'
    },
    {
        value: 'CLOSED',
        label: 'application.otherProperty.closed'
    }
];

export const OTHER_PROPERTY_PURPOSE = [
    {
        value: 'OWNER_OCCUPIED',
        label: 'purpose.owner'
    },
    {
        value: 'OWNER_OCCUPIED_AND_RENTAL',
        label: 'purpose.ownerRental'
    },
    {
        value: 'RENTAL',
        label: 'purpose.rental'
    }
];

export const OTHER_PROPERTY_STATUS = [
    {
        value: 'NOT_READY_YET',
        label: 'application.otherProperty.notReady'
    },
    {
        value: 'LISTED_FOR_SALE',
        label: 'application.otherProperty.listed'
    },
    {
        value: 'CONDITIONALLY_SOLD',
        label: 'application.otherProperty.soldConditionally'
    },
    {
        value: 'SOLD_FIRM',
        label: 'application.otherProperty.soldFirm'
    }
];

export const OTHER_PROPERTY_MORTGAGE_TYPE = [
    {
        value: 'STANDARD',
        label: 'application.otherProperty.standard'
    },
    {
        value: 'HELOC',
        label: 'application.otherProperty.heloc'
    }
];

export const PROPERTY_PURPOSE = [
    {
        value: 'SELLING',
        label: 'sellIt',
        defaultValue: 'I will sell it'
    },
    {
        value: 'OWNER_OCCUPIED',
        label: 'property.live',
        defaultValue: 'I will keep living in it'
    },
    {
        value: 'RENTAL',
        label: 'property.rent',
        defaultValue: 'I will start renting it'
    }
];

export const APPLICATION_SURFACE = [
    {
        value: 'SQ_FEET',
        label: 'space.squarefeet',
        namePath: 'space.squarefeet'
    },
    {
        value: 'SQ_METERS',
        label: 'space.squaremeters',
        namePath: 'space.squaremeters'
    }
];

export const APPLICATION_GARAGE_SIZETYPE: ConstantType<string> = [
    { value: 'SINGLE', label: 'garagespace.single' },
    { value: 'DOUBLE', label: 'garagespace.double' },
    { value: 'TRIPLE', label: 'garagespace.triple' }
];

export const APPLICATION_GARAGE_TYPE: ConstantType<string> = [
    { value: 'DETACHED', label: 'garageType.detached' },
    { value: 'ATTACHED', label: 'garageType.attached' }
];

export const APPLICATION_PROPERTY_STYLE: ConstantType<string> = [
    { value: 'ONE_STOREY', label: 'style.bungalow' },
    { value: 'BI_LEVEL', label: 'style.biLevel' },
    { value: 'TWO_STORY', label: 'style.twoStory' },
    { value: 'SPLIT_LEVEL', label: 'style.split' },
    { value: 'STOREY_AND_A_HALF', label: 'style.storeyHalf' },
    { value: 'THREE_STOREY', label: 'style.threeStorey' },
    { value: 'OTHER', label: 'other' }
];

export const APPLICATION_PROPERTY_SEWAGE_TYPE: ConstantType<string> = [
    { value: 'MUNICIPAL', label: 'municipal' },
    { value: 'SEPTIC', label: 'sewage.septic' },
    { value: 'HOLDING_TANK', label: 'sewage.holdingTank' }
];

export const APPLICATION_PROPERTY_WATER_TYPE: ConstantType<string> = [
    { value: 'MUNICIPAL', label: 'municipal' },
    { value: 'WELL', label: 'water.well' }
];

export const RENEWAL_SCHEDULE: ConstantType<string> = [
    {
        label: 'getAQuote.dueTwo',
        value: 'TWO_MONTHS'
    },
    {
        label: 'getAQuote.dueFour',
        value: 'FOUR_MONTHS_LESS'
    },
    {
        label: 'getAQuote.dueMoreFour',
        value: 'FOUR_MONTHS_PLUS'
    }
];

export const RENEWAL_SCHEDULE_OPTIONS = {
    TWO_MONTHS: 'TWO_MONTHS',
    FOUR_MONTHS_LESS: 'FOUR_MONTHS_LESS',
    FOUR_MONTHS_PLUS: 'FOUR_MONTHS_PLUS'
};

const SALUTATIONS_EN: ConstantType<keyof typeof SalutationsEn> = [
    {
        value: SalutationsEn.MR,
        label: 'application.salutations.mr'
    },
    {
        value: SalutationsEn.MRS,
        label: 'application.salutations.mrs'
    },
    {
        value: SalutationsEn.MS,
        label: 'application.salutations.ms'
    },
    {
        value: SalutationsEn.MISS,
        label: 'application.salutations.miss'
    },
    {
        value: SalutationsEn.DR,
        label: 'application.salutations.dr'
    },
    {
        value: SalutationsEn.REV,
        label: 'application.salutations.rev'
    },
    {
        value: SalutationsEn.JUDGE,
        label: 'application.salutations.judge'
    }
];

const SALUTATIONS_FR: ConstantType<keyof typeof SalutationsFr> = [
    {
        value: SalutationsFr.MR,
        label: 'application.salutations.mr'
    },
    {
        value: SalutationsFr.MRS,
        label: 'application.salutations.mrs'
    },
    {
        value: SalutationsFr.MS,
        label: 'application.salutations.ms'
    },
    {
        value: SalutationsFr.DR,
        label: 'application.salutations.dr'
    },
    {
        value: SalutationsFr.REV,
        label: 'application.salutations.rev'
    },
    {
        value: SalutationsFr.JUDGE,
        label: 'application.salutations.judge'
    }
];

/**
 * Business logic:
 * Need to offer 'miss' as an option in English, but both 'miss' and 'ms' translate
 * to the 'mme' in French. Seperate lists to avoid duplicates of 'mme' in French.
 * When we move away from Filogix, we can improve this implementation.
 */
export const SALUTATIONS: Record<
    Language,
    | ConstantType<keyof typeof SalutationsEn>
    | ConstantType<keyof typeof SalutationsFr>
> = {
    en: SALUTATIONS_EN,
    fr: SALUTATIONS_FR
};

export const CREDITSCORE_QUALITY: ConstantType<keyof typeof creditScoreQuality> = [
    {
        value: creditScoreQuality.UNKNOWN,
        label: 'application.creditScoreQuality.unknown'
    },
    {
        value: creditScoreQuality.EXCELLENT,
        label: 'application.creditScoreQuality.excellent'
    },
    {
        value: creditScoreQuality.GOOD,
        label: 'application.creditScoreQuality.good'
    },
    {
        value: creditScoreQuality.FAIR,
        label: 'application.creditScoreQuality.fair'
    },
    {
        value: creditScoreQuality.POOR,
        label: 'application.creditScoreQuality.poor'
    }
];

export const MARITAL_STATUSES: ConstantType<keyof typeof maritalStatuses> = [
    {
        value: maritalStatuses.SINGLE,
        label: 'application.maritalStatus.single'
    },
    {
        value: maritalStatuses.MARRIED,
        label: 'application.maritalStatus.married'
    },
    {
        value: maritalStatuses.DIVORCED,
        label: 'application.maritalStatus.divorced'
    },
    {
        value: maritalStatuses.COMMON_LAW,
        label: 'application.maritalStatus.commonLaw'
    },
    {
        value: maritalStatuses.WIDOWED,
        label: 'application.maritalStatus.widowed'
    },
    {
        value: maritalStatuses.SEPERATED,
        label: 'application.maritalStatus.seperated'
    },
    {
        value: maritalStatuses.OTHER,
        label: 'other'
    }
];

export const RELATION_TO_MAIN_APPLICANT = [
    {
        value: 'SPOUSE',
        label: 'application.relationToOtherApplicant.spouse'
    },
    {
        value: 'COMMON_LAW',
        label: 'application.maritalStatus.commonLaw'
    },
    {
        value: 'RELATED_FAMILY_MEMBER',
        label: 'application.relationToOtherApplicant.family_member'
    },
    {
        value: 'PARENT',
        label: 'application.relationToOtherApplicant.parent'
    },
    {
        value: 'CHILD',
        label: 'familyRelationship.child'
    },
    {
        value: 'SIBLING',
        label: 'application.relationToOtherApplicant.sibling'
    },
    {
        value: 'GRAND_PARENT',
        label: 'application.relationToOtherApplicant.grandparent'
    },
    {
        value: 'GRAND_CHILD',
        label: 'application.relationToOtherApplicant.grandchild'
    },
    {
        value: 'OTHER',
        label: 'other'
    }
];

enum EmploymentEnum {
    FULL_TIME = 'FULL_TIME',
    PART_TIME = 'PART_TIME',
    SEASONAL = 'SEASONAL'
}

export type EmploymentType = keyof typeof EmploymentEnum;

export const EMPLOYMENT_TYPE: ConstantType<EmploymentType> = [
    {
        label: 'application.income.fulltime',
        value: EmploymentEnum.FULL_TIME
    },
    {
        label: 'application.income.parttime',
        value: EmploymentEnum.PART_TIME
    },
    {
        label: 'application.income.seasonal',
        value: EmploymentEnum.SEASONAL
    }
];

enum TenureEnum {
    PERMANENT = 'PERMANENT',
    ON_PROBATION = 'ON_PROBATION',
    ON_CONTRACT = 'ON_CONTRACT'
}

export type TenureType = keyof typeof TenureEnum;

export const TENURE: ConstantType<TenureType> = [
    {
        label: 'application.income.tenure.permanent',
        value: TenureEnum.PERMANENT
    },
    {
        label: 'application.income.tenure.probation',
        value: TenureEnum.ON_PROBATION
    },
    {
        label: 'application.income.tenure.contract',
        value: TenureEnum.ON_CONTRACT
    }
];

export const PROPERTY_TENURE: ConstantType<string> = [
    { value: 'FREE_HOLD', label: 'tenure.freeHold' },
    { value: 'CONDO', label: 'tenure.condo' }
];

export enum PensionEnum {
    OLD_AGE_SECURITY = 'OLD_AGE_SECURITY',
    CANADIAN_PENSION_PLAN = 'CANADIAN_PENSION_PLAN',
    EMPLOYER = 'EMPLOYER',
    OTHER = 'OTHER'
}
export type PensionType = keyof typeof PensionEnum;

export const PENSION_TYPES: ConstantType<keyof typeof PensionEnum> = [
    {
        label: 'application.income.pension.security' as TypographI18nKeys,
        value: PensionEnum.OLD_AGE_SECURITY
    },
    {
        label: 'application.income.pension.canadaPension' as TypographI18nKeys,
        value: PensionEnum.CANADIAN_PENSION_PLAN
    },
    {
        label: 'application.income.pension.employerPension' as TypographI18nKeys,
        value: PensionEnum.EMPLOYER
    },
    {
        label: 'application.income.pension.otherPension' as TypographI18nKeys,
        value: PensionEnum.OTHER
    }
];

enum OperatingAsEnum {
    CORPORATION = 'CORPORATION',
    PARTNERSHIP = 'PARTNERSHIP',
    SOLE_PROPRIETOR = 'SOLE_PROPRIETOR'
}

export type OperatingAsType = keyof typeof OperatingAsEnum;

export const OPERATING_AS: ConstantType<OperatingAsType> = [
    {
        label: 'application.income.selfEmployed.corporation',
        value: OperatingAsEnum.CORPORATION
    },
    {
        label: 'application.income.selfEmployed.partnership',
        value: OperatingAsEnum.PARTNERSHIP
    },
    {
        label: 'application.income.selfEmployed.soleProprietor',
        value: OperatingAsEnum.SOLE_PROPRIETOR
    }
];

export type IncomeTypes =
    | 'SELF_EMPLOYED'
    | 'SALARIED'
    | 'HOURLY'
    | 'HOURLY_COMMISSIONS'
    | 'COMMISSIONS'
    | 'PENSION'
    | 'NONE';

export const INCOME_TYPES: ConstantType<IncomeTypes> = [
    {
        label: 'salaried',
        value: 'SALARIED'
    },
    {
        label: 'application.income.hourly.title',
        value: 'HOURLY'
    },
    {
        label: 'application.income.hourlyCommissions.title',
        value: 'HOURLY_COMMISSIONS'
    },
    {
        label: 'application.income.commission',
        value: 'COMMISSIONS'
    },
    {
        label: 'selfEmployed',
        value: 'SELF_EMPLOYED'
    },
    {
        label: 'pension',
        value: 'PENSION'
    },
    {
        label: 'application.income.noIncome.title',
        value: 'NONE'
    }
];

export enum OtherIncomeEnum {
    INVESTMENT_INCOME = 'INVESTMENT_INCOME',
    INTEREST_INCOME = 'INTEREST_INCOME',
    ALIMONY = 'ALIMONY',
    CHILD_SUPPORT = 'CHILD_SUPPORT',
    EMPLOYMENT_EXPENSE = 'EMPLOYMENT_EXPENSE',
    OTHER = 'OTHER'
}

type OtherIncomeConstantTypes = keyof typeof OtherIncomeEnum;

export const OTHER_INCOME_TYPES: ConstantType<OtherIncomeConstantTypes> = [
    {
        value: OtherIncomeEnum.INVESTMENT_INCOME,
        label: 'incomeType.investment'
    },
    {
        value: OtherIncomeEnum.INTEREST_INCOME,
        label: 'incomeType.interest'
    },
    {
        value: OtherIncomeEnum.CHILD_SUPPORT,
        label: 'incomeType.childSupport'
    },
    {
        value: OtherIncomeEnum.ALIMONY,
        label: 'incomeType.alimony'
    },
    {
        value: OtherIncomeEnum.EMPLOYMENT_EXPENSE,
        label: 'incomeType.employment'
    },
    {
        value: OtherIncomeEnum.OTHER,
        label: 'other'
    }
];

export const HEATING_TYPE: ConstantType<string> = [
    {
        value: 'ELECTRIC',
        label: 'heating.electric'
    },
    {
        value: 'FORCED_AIR_OIL_GAS_ELECTRIC',
        label: 'heating.oil'
    },
    {
        value: 'HOT_WATER',
        label: 'heating.hotWater'
    },
    {
        value: 'OTHER',
        label: 'other'
    }
];

type TimingConstantTypes = keyof typeof Timings;

export const TimingAcceptanceDates = {
    [Timings.MADE_OFFER]: formatISO(dateAdd(new Date(), { days: 0 }), {
        representation: 'date'
    }),
    [Timings.MAKING_OFFER_SOON]: formatISO(dateAdd(new Date(), { months: 3 }), {
        representation: 'date'
    }),
    [Timings.GET_PREAPPROVED]: formatISO(dateAdd(new Date(), { months: 6 }), {
        representation: 'date'
    }),
    [Timings.JUST_CURIOUS]: formatISO(dateAdd(new Date(), { months: 12 }), {
        representation: 'date'
    })
};

export const APPLICATION_TIMING: ConstantType<TimingConstantTypes> = [
    {
        label: 'getAQuote.madeOffer',
        value: Timings.MADE_OFFER
    },
    {
        label: 'getAQuote.makingOfferSoon',
        value: Timings.MAKING_OFFER_SOON
    },
    {
        label: 'getAQuote.getPreapproved',
        value: Timings.GET_PREAPPROVED
    },
    {
        label: 'getAQuote.justCurious',
        value: Timings.JUST_CURIOUS
    }
];

export const APPLICATION_ACCEPTANCE_DATE_RANGE: ConstantType<string> = [
    {
        label: 'acceptanceDate.0-3Months',
        value: formatISO(dateAdd(new Date(), { months: 1, days: 15 }), {
            representation: 'date'
        })
    },
    {
        label: 'acceptanceDate.3-6Months',
        value: formatISO(dateAdd(new Date(), { months: 4, days: 15 }), {
            representation: 'date'
        })
    },
    {
        label: 'acceptanceDate.6MonthsPlus',
        value: formatISO(dateAdd(new Date(), { months: 9 }), {
            representation: 'date'
        })
    },
    {
        label: 'notSureYet',
        value: formatISO(dateAdd(new Date(), { years: 1 }), {
            representation: 'date'
        })
    }
];

export const APPLICATION_ACCEPTANCE_DATE_RECENT: ConstantType<string> = [
    {
        label: 'acceptanceDate.today',
        value: formatISO(dateAdd(new Date(), {}), {
            representation: 'date'
        })
    },
    {
        label: 'acceptanceDate.thisWeek',
        value: formatISO(dateSub(new Date(), { weeks: 1 }), {
            representation: 'date'
        })
    },
    {
        label: 'acceptanceDate.thisMonth',
        value: formatISO(dateSub(new Date(), { months: 1 }), {
            representation: 'date'
        })
    }
];

export const BOOLEAN_YES_NO_OPTIONS: ConstantType<boolean> = [
    {
        label: 'yes',
        value: true
    },
    {
        label: 'no',
        value: false
    }
];

export const STRING_YES_NO_OPTIONS: ConstantType<string> = [
    {
        label: 'yes',
        value: 'YES'
    },
    {
        label: 'no',
        value: 'NO'
    }
];

enum RegisteredAddressEnum {
    RENTING = 'RENTING',
    OWNER = 'OWNER',
    LIVING_WITH_PARENTS = 'LIVING_WITH_PARENTS'
}

export type RegisteredAddressType = keyof typeof RegisteredAddressEnum;

export const REGISTERED_ADDRESS_TYPE: ConstantType<RegisteredAddressType> = [
    {
        value: 'RENTING',
        label: 'application.registeredAddress.rent'
    },
    {
        value: 'OWNER',
        label: 'application.registeredAddress.own'
    },
    {
        value: 'LIVING_WITH_PARENTS',
        label: 'application.registeredAddress.parents'
    }
];

export const DOCUMENT_CREATE_FORM = 'DOCUMENT_CREATE_FORM';

// what is we have some missing partners?
// this can be possibility of `undefined` from `PARTNER_NAMES[account.partner]`
// so we need to check it at run time
// see `../sagas/account.sagas.ts` function `onLoginRequest` for more details
export const PARTNER_NAMES = {
    canadalife: {
        utm_source: 'canada life',
        partner: 'canadalife'
    },
    clicassure: {
        utm_source: 'clicassure',
        partner: 'clicassure'
    },
    creditKarma: {
        utm_source: 'credit karma',
        partner: 'creditKarma'
    },
    dwelly: {
        utm_source: 'dwelly',
        partner: 'dwelly'
    },
    ia: {
        utm_source: 'ia',
        partner: 'ia'
    },
    eqb: {
        utm_source: 'eqb',
        partner: 'eqb'
    },
    ferique: {
        utm_source: 'ferique',
        partner: 'ferique'
    },
    helenelauzier: {
        utm_source: 'helenelauzier',
        partner: 'helenelauzier'
    },
    guideHabitation: {
        utm_source: 'guidehabitation',
        partner: 'guideHabitation'
    },
    greatWestLife: {
        utm_source: 'greatwestlife',
        partner: 'greatWestLife'
    },
    myChoice: {
        utm_source: 'mychoice',
        partner: 'myChoice'
    },
    nesto: {
        utm_source: 'nesto',
        partner: 'nesto'
    },
    proprioDirect: {
        utm_source: 'proprio direct',
        partner: 'proprioDirect'
    },
    suttonSummit: {
        utm_source: 'suttonsummit',
        partner: 'suttonSummit'
    },
    laurentien: {
        utm_source: 'laurentien',
        partner: 'laurentien'
    },
    IG_WM: {
        utm_source: 'IG_WM',
        partner: 'IG_WM'
    },
    royalLepage: {
        utm_source: 'royalLepage',
        partner: 'royalLepage'
    },
    Tangerine: {
        utm_source: 'tangerine',
        partner: 'Tangerine'
    },
    TD: {
        utm_source: 'TD',
        partner: 'TD'
    },
    uni: {
        utm_source: 'uni',
        partner: 'uni'
    },
    viaCapitale: {
        utm_source: 'viacapitale',
        partner: 'viaCapitale'
    },
    wealthSimple: {
        utm_source: 'wealthsimple',
        partner: 'wealthSimple'
    },
    worldsource: {
        utm_source: 'worldsource',
        partner: 'worldsource'
    },
    zolo: {
        utm_source: 'zolo',
        partner: 'zolo'
    },
    mtlguidecondo: {
        utm_source: 'mtlguidecondo',
        partner: 'mtlguidecondo'
    },
    vistoo: {
        utm_source: 'vistoo',
        partner: 'vistoo'
    },
    properly: {
        utm_source: 'properly',
        partner: 'properly'
    },
    fhg: {
        utm_source: 'fhg',
        partner: 'fhg'
    },
    borrowell: {
        utm_source: 'borrowell',
        partner: 'borrowell'
    },
    brookfield: {
        utm_source: 'brookfield',
        partner: 'brookfield'
    },
    mylo: {
        utm_source: 'mylo',
        partner: 'mylo'
    },
    rew: {
        utm_source: 'rew',
        partner: 'rew'
    },
    planhub: {
        utm_source: 'planhub',
        partner: 'planhub'
    },
    movingwaldo: {
        utm_source: 'movingwaldo',
        partner: 'movingwaldo'
    },
    communities: {
        utm_source: 'communities',
        partner: 'communities'
    },
    cbns: {
        utm_source: 'cbns',
        partner: 'cbns'
    },
    hardbacon: {
        utm_source: 'hardbacon',
        partner: 'hardbacon'
    },
    squareone: {
        utm_source: 'squareone',
        partner: 'squareone'
    },
    optimize: {
        utm_source: 'optimize',
        partner: 'optimize'
    },
    justo: {
        utm_source: 'justo',
        partner: 'justo'
    },
    billdr: {
        utm_source: 'billdr',
        partner: 'billdr'
    },
    home: {
        utm_source: 'home',
        partner: 'home'
    },
    hellosafe: {
        utm_source: 'hellosafe',
        partner: 'hellosafe'
    },
    clearscore: {
        utm_source: 'clearscore',
        partner: 'clearscore'
    },
    fido: {
        utm_source: 'fido',
        partner: 'fido'
    },
    assetdirect: {
        utm_source: 'assetdirect',
        partner: 'assetdirect'
    },
    wealthrocket: {
        utm_source: 'wealthrocket',
        partner: 'wealthrocket'
    },
    realtor: {
        utm_source: 'realtor',
        partner: 'realtor'
    },
    nestoreferral: {
        utm_source: 'nestoreferral',
        partner: 'nestoreferral'
    },
    door2door: {
        utm_source: 'door2door',
        partner: 'door2door'
    },
    redflagdeals: {
        utm_source: 'redflagdeals',
        partner: 'redflagdeals'
    },
    chrisallard: {
        utm_source: 'chrisallard',
        partner: 'chrisallard'
    },
    unreserved: {
        utm_source: 'unreserved',
        partner: 'unreserved'
    },
    moneywise: {
        utm_source: 'moneywise',
        partner: 'moneywise'
    },
    nerdwallet: {
        utm_source: 'nerdwallet',
        partner: 'nerdwallet'
    },
    santasellshouses: {
        utm_source: 'santasellshouses',
        partner: 'santasellshouses'
    },
    tlg: {
        utm_source: 'tlg',
        partner: 'tlg'
    },
    loanscanada: {
        utm_source: 'loanscanada',
        partner: 'loanscanada'
    },
    pretsquebec: {
        utm_source: 'pretsquebec',
        partner: 'pretsquebec'
    },
    lenderforall: {
        utm_source: 'lenderforall',
        partner: 'lenderforall'
    },
    educfinance: {
        utm_source: 'educfinance',
        partner: 'educfinance'
    },
    milesopedia: {
        utm_source: 'milesopedia',
        partner: 'milesopedia'
    },
    forbesadvisor: {
        utm_source: 'forbesadvisor',
        partner: 'forbesadvisor'
    },
    sonnet: {
        utm_source: 'sonnet',
        partner: 'sonnet'
    },
    point2homes: {
        utm_source: 'point2homes',
        partner: 'point2homes'
    },
    movemeto: {
        utm_source: 'movemeto',
        partner: 'movemeto'
    },
    comparemortgages: {
        utm_source: 'comparemortgages',
        partner: 'comparemortgages'
    },
    quadrus: {
        utm_source: 'quadrus',
        partner: 'quadrus'
    },
    manulife: {
        utm_source: 'manulife',
        partner: 'manulife'
    },
    rentals: {
        utm_source: 'rentals',
        partner: 'rentals'
    },
    rentfund: {
        utm_source: 'rentfund',
        partner: 'rentfund'
    },
    myabode: {
        utm_source: 'myabode',
        partner: 'myabode'
    },
    smarterloans: {
        utm_source: 'smarterloans',
        partner: 'smarterloans'
    },
    moneygenius: {
        utm_source: 'moneygenius',
        partner: 'moneygenius'
    },
    rogersbank: {
        utm_source: 'rogersbank',
        partner: 'rogersbank'
    },
    lpfinancial: {
        utm_source: 'lpfinancial',
        partner: 'lpfinancial'
    }
};

export const ADDRESS_MAPPED_FIELDS = {
    streetNumber: 'sN',
    street: 's',
    unit: 'u',
    city: 'c',
    stateCode: 'sC',
    postalCode: 'pC',
    countryCode: 'cC'
};

export const ADDRESS_SECTIONS = {
    targetProperty: 'targetProperty',
    refinanceProperty: 'refinanceProperty',
    renewingProperty: 'renewingProperty',
    registeredAddress: 'registeredAddress',
    otherProperties: 'otherProperties'
};

export const MINIMUM_NEW_FINANCING_REQUIRED = 100_000;
export const MINIMUM_REFI_FINANCING_REQUIRED = 125_000;

export const UNI_POSTAL_CODES = [
    'L1Z',
    'L1T',
    'L1S',
    'P0R',
    'L9K',
    'L9G',
    'L4M',
    'L4N',
    'K8R',
    'K8N',
    'K8P',
    'L1B',
    'L1C',
    'L6V',
    'L6T',
    'L6P',
    'L6S',
    'L6R',
    'L6Y',
    'L6W',
    'L6X',
    'L6Z',
    'N3R',
    'N3P',
    'N3V',
    'N3S',
    'N3T',
    'L7N',
    'L7M',
    'L7L',
    'L7S',
    'L7R',
    'L7T',
    'L7P',
    'N1R',
    'N1T',
    'N3C',
    'N3E',
    'N1P',
    'N1S',
    'N3H',
    'N7L',
    'N7M',
    'K6H',
    'K6K',
    'K6J',
    'M3B',
    'M3C',
    'M3M',
    'M3L',
    'L0N',
    'M4G',
    'M4B',
    'M4H',
    'M4C',
    'M8W',
    'M9C',
    'M9A',
    'M9R',
    'M8Z',
    'M8Y',
    'M9V',
    'M8X',
    'M9B',
    'M9P',
    'M9W',
    'K0H',
    'K1J',
    'K1B',
    'K1T',
    'K1W',
    'K1C',
    'K1X',
    'P3B',
    'P3L',
    'P3C',
    'P3P',
    'P3Y',
    'P3G',
    'P3A',
    'P3E',
    'P3N',
    'N1G',
    'N1L',
    'N1E',
    'N1H',
    'N1C',
    'N1K',
    'L0P',
    'L9E',
    'L9B',
    'L8R',
    'L8E',
    'L9A',
    'L8P',
    'L8J',
    'L8K',
    'L8G',
    'L8V',
    'L8T',
    'L9C',
    'L8N',
    'L8W',
    'L8M',
    'L8L',
    'L8H',
    'L8S',
    'K2K',
    'K2M',
    'K2L',
    'K2T',
    'K2W',
    'K2V',
    'K7L',
    'K7M',
    'K7K',
    'K7P',
    'N2G',
    'N2A',
    'N2K',
    'N2H',
    'N2B',
    'N2M',
    'N2R',
    'N2C',
    'N2P',
    'N2E',
    'N2N',
    'N9H',
    'N9J',
    'L0K',
    'L0E',
    'L0L',
    'P0S',
    'P0T',
    'N6L',
    'N5X',
    'N5Z',
    'N6M',
    'N6K',
    'N6N',
    'N6E',
    'N6J',
    'N6G',
    'N6P',
    'N5Y',
    'N5V',
    'N6B',
    'N5W',
    'N6A',
    'N6C',
    'N6H',
    'L3P',
    'L6B',
    'L6G',
    'L6E',
    'L6C',
    'L3R',
    'L3S',
    'L5S',
    'L5E',
    'L5M',
    'L5J',
    'L5T',
    'L4X',
    'L5V',
    'L5L',
    'L5N',
    'L4T',
    'L4W',
    'L5W',
    'L5A',
    'L5G',
    'L4Y',
    'L5B',
    'L5C',
    'L5R',
    'L5H',
    'L4Z',
    'L5K',
    'L4V',
    'L5P',
    'K2J',
    'K2H',
    'K2G',
    'K2R',
    'K2E',
    'L3Y',
    'L3X',
    'L2E',
    'L2J',
    'L2G',
    'L2H',
    'P0A',
    'P0H',
    'P0B',
    'P1B',
    'P1C',
    'P1A',
    'M3H',
    'M2K',
    'M2J',
    'M6B',
    'M2H',
    'M9L',
    'M9M',
    'M3N',
    'M6A',
    'M6L',
    'M3J',
    'M3A',
    'M2L',
    'M2P',
    'L6K',
    'L6H',
    'L6J',
    'L6L',
    'L6M',
    'L9V',
    'L9W',
    'L1G',
    'L1K',
    'L1L',
    'L1H',
    'L1J',
    'K1A',
    'K1H',
    'K2B',
    'K2P',
    'K2A',
    'K1N',
    'K1K',
    'K1P',
    'K2C',
    'K1V',
    'K1G',
    'K1M',
    'K1S',
    'K1L',
    'K1R',
    'K1Z',
    'K1Y',
    'P0C',
    'P0G',
    'P0E',
    'K8B',
    'K8A',
    'K9K',
    'K9L',
    'K9H',
    'K9J',
    'K0L',
    'L1X',
    'L1Y',
    'L1W',
    'L1V',
    'K0J',
    'L4S',
    'L4E',
    'L4B',
    'L4C',
    'N7S',
    'N7X',
    'N7V',
    'N7W',
    'N7T',
    'P6B',
    'P6A',
    'P6C',
    'M1S',
    'M1N',
    'M1H',
    'M1T',
    'M1M',
    'M1P',
    'M1J',
    'M1E',
    'M1K',
    'M1B',
    'M1V',
    'M1C',
    'M1W',
    'M1L',
    'M1X',
    'M1R',
    'M1G',
    'L2R',
    'L2P',
    'L2M',
    'L2N',
    'L2T',
    'L2V',
    'L2S',
    'L2W',
    'N5P',
    'N5R',
    'N8V',
    'N8N',
    'P7C',
    'P7G',
    'P7B',
    'P7A',
    'P7J',
    'P7E',
    'P7K',
    'P0K',
    'P0J',
    'P4P',
    'P4N',
    'P4R',
    'M4P',
    'M4S',
    'M5P',
    'M4N',
    'M4T',
    'M4R',
    'M5N',
    'M4V',
    'M5R',
    'M3K',
    'M5E',
    'M5G',
    'M6G',
    'M4Y',
    'M5V',
    'M5L',
    'M5J',
    'M5T',
    'M5A',
    'M5H',
    'M4W',
    'M5B',
    'M5C',
    'M4X',
    'M5K',
    'M5X',
    'M5S',
    'M5W',
    'M4L',
    'M4M',
    'M4E',
    'M4J',
    'M4K',
    'M7Y',
    'M8V',
    'M6S',
    'M6K',
    'M6H',
    'M6P',
    'M6R',
    'M6J',
    'N2V',
    'N2L',
    'N2J',
    'N2T',
    'L3B',
    'L3C',
    'L1R',
    'L1M',
    'L1N',
    'L1P',
    'M2M',
    'M2N',
    'M2R',
    'N9A',
    'N8R',
    'N8P',
    'N8S',
    'N9G',
    'N9C',
    'N8W',
    'N9B',
    'N8T',
    'N8Y',
    'N9E',
    'N8X',
    'L4H',
    'L4L',
    'N4S',
    'N4T',
    'N4V',
    'M6C',
    'M6M',
    'M6E',
    'M6N',
    'L7J',
    'P0M',
    'L9R',
    'N9V',
    'K7N',
    'K7S',
    'L4G',
    'N5H',
    'N3A',
    'L9J',
    'L9X',
    'L7E',
    'P1L',
    'L3Z',
    'L7A',
    'N0E',
    'K6V',
    'N0H',
    'L7C',
    'L7K',
    'N3W',
    'K7C',
    'K9A',
    'P0L',
    'L9Y',
    'L4K',
    'L1E',
    'K4C',
    'K4B',
    'N4B',
    'P8N',
    'L9H',
    'N1A',
    'L0B',
    'L0R',
    'N0L',
    'K6T',
    'P5A',
    'N3B',
    'P5E',
    'N8M',
    'N0R',
    'N1M',
    'L2A',
    'P9A',
    'K7G',
    'L7G',
    'L0M',
    'N0C',
    'N7A',
    'P1P',
    'K4P',
    'L3M',
    'N4N',
    'K6A',
    'L9N',
    'P1H',
    'N0G',
    'N5C',
    'L9S',
    'P5N',
    'K0M',
    'P9N',
    'P0X',
    'N0P',
    'L4P',
    'N2Z',
    'L7B',
    'N9Y',
    'P2N',
    'P0Y',
    'N0N',
    'N8H',
    'K9V',
    'N4W',
    'P0P',
    'K4M',
    'L6A',
    'N4L',
    'N0M',
    'L9X',
    'L4R',
    'L9T',
    'L9X',
    'K7R',
    'K0A',
    'P7L',
    'L0S',
    'L0J',
    'M5M',
    'M4A',
    'P0V',
    'L0G',
    'L3V',
    'K4A',
    'K1E',
    'N4K',
    'N0J',
    'N3L',
    'P2A',
    'L9M',
    'K7H',
    'N0K',
    'K8H',
    'L3K',
    'L1A',
    'L9L',
    'N5L',
    'K0B',
    'M7A',
    'K0K',
    'P0W',
    'K7V',
    'K0G',
    'K4K',
    'K4R',
    'N3Y',
    'P8T',
    'K7A',
    'K0E',
    'L9X',
    'N4X',
    'K2S',
    'K0C',
    'L4A',
    'N5A',
    'N4Z',
    'N7G',
    'P2B',
    'N9K',
    'L3T',
    'L4J',
    'N4G',
    'P0N',
    'K8V',
    'L9P',
    'N8A',
    'L9Z',
    'L8B',
    'N0B',
    'L0C',
    'N0A',
    'L0A',
    'L0V',
    'M9N',
    'L0H',
    'K2X',
    'K2Y',
    'K2Z',
    'K4E',
    'K4G',
    'K4J',
    'K4L',
    'K4S',
    'K4V',
    'K4X',
    'K4Y',
    'K4Z'
];

export const ApplicationTypes: Record<ApplicationType, TranslationKeys> = {
    NEW: 'newMortgage',
    RENEWAL: 'renewal',
    REFINANCE: 'refinance'
};

export const RateTypes: Record<rateTypes, TranslationKeys> = {
    VARIABLE: 'variable',
    FIXED: 'fixed'
};

export const AutoDownPaymentPercents = {
    BASE_DOWN_PAYMENT: 0.1,
    NON_OWNER_OCCUPIED_DOWN_PAYMENT: 0.2
};

export type ValidPhoneNumberPayload = (
    payload: any
) => {
    userInfo: {
        is_valid: boolean;
        formType: string;
    };
    type: string;
};

export enum CallCenterHours {
    WeekendStart = 10,
    WeekendEnd = 18,
    WeekdayStart = 9,
    WeekdayEnd = 20
}

export enum AdvisorOperatingHours {
    GeneralMeeting = 11,
    FridayOpen = 9,
    FridayClose = 16,
    WeekdayOpen = 9,
    WeekdayClose = 19
}

export const RegionMapTimeZones = {
    [Region.AB]: 'America/Edmonton', // GMT-6
    [Region.BC]: 'America/Vancouver', // GMT-7
    [Region.MB]: 'America/Winnipeg', // GMT-6
    [Region.SK]: 'America/Regina', // GMT-6
    [Region.QC]: 'America/Montreal', // GMT-4
    [Region.ON]: 'America/Toronto', // GMT-5
    [Region.NS]: 'America/St_Johns', // GMT-3:30
    [Region.NB]: 'America/St_Johns', // GMT-3:30
    [Region.NL]: 'America/St_Johns' // GMT-3:30
};

export type NestoDate = {
    day: number;
    month: number;
};

export const FEDERAL_HOLIDAYS: NestoDate[] = [
    { day: 24, month: 12 },
    { day: 25, month: 12 },
    { day: 26, month: 12 },
    { day: 27, month: 12 },
    { day: 28, month: 12 },
    { day: 29, month: 12 },
    { day: 30, month: 12 },
    { day: 31, month: 12 },
    { day: 1, month: 1 },
    { day: 2, month: 1 },
    { day: 3, month: 1 },
    { day: 15, month: 4 },
    { day: 23, month: 5 },
    { day: 1, month: 7 },
    { day: 5, month: 9 },
    { day: 10, month: 10 }
];

export const HOLIDAYS_OPEN_DATES: NestoDate[] = [
    { day: 28, month: 12 },
    { day: 29, month: 12 },
    { day: 30, month: 12 }
];

export const HOLIDAYS_CLOSED_DATES: NestoDate[] = [
    { day: 25, month: 12 },
    { day: 26, month: 12 },
    { day: 27, month: 12 },
    { day: 31, month: 12 },
    { day: 1, month: 1 },
    { day: 2, month: 1 }
];

export const QC_HOLIDAYS: NestoDate[] = [{ day: 24, month: 6 }];

export const ON_HOLIDAYS: NestoDate[] = [{ day: 21, month: 2 }];

export const AB_HOLIDAYS: NestoDate[] = [
    { day: 21, month: 2 },
    { day: 11, month: 11 }
];
export const BC_HOLIDAYS: NestoDate[] = [
    { day: 21, month: 2 },
    { day: 11, month: 11 },
    { day: 1, month: 8 }
];

export const PROVINCIAL_HOLIDAYS = {
    [Region.QC]: QC_HOLIDAYS,
    [Region.ON]: ON_HOLIDAYS,
    [Region.AB]: AB_HOLIDAYS,
    [Region.BC]: BC_HOLIDAYS
};

export const FUND_CHOICES = [
    {
        label: 'fundPurpose.homeImprovement',
        value: 'homeImprovement'
    },
    {
        label: 'fundPurpose.consolidateDebts',
        value: 'consolidateDebts'
    },
    {
        label: 'fundPurpose.homeEquityInvestment',
        value: 'homeEquity'
    },
    {
        label: 'fundPurpose.giftToRelative',
        value: 'giftToRelative'
    }
];

export const ACCOUNT_DELETION_REASONS: ConstantType<string> = [
    {
        value: AccountDeletionReason.FundedOtherInstitution,
        label: 'accountDeletion.feedback.fundedOtherInstitution'
    },
    {
        value: AccountDeletionReason.NestoDifficultToUse,
        label: 'accountDeletion.feedback.nestoDifficultToUse'
    },
    {
        value: AccountDeletionReason.NotEffective,
        label: 'accountDeletion.feedback.notEffective'
    },
    {
        value: AccountDeletionReason.WindowShopping,
        label: 'accountDeletion.feedback.windowShopping'
    },
    {
        value: AccountDeletionReason.Other,
        label: 'accountDeletion.feedback.other'
    },
    {
        value: AccountDeletionReason.NoReason,
        label: 'accountDeletion.feedback.noReason'
    }
];

export enum LeadReferralVariant {
    LEAD_REFERRAL_SINGLE = 'LEAD_REFERRAL_SINGLE', //0%
    LEAD_REFERRAL_SHORT_CONSENT = 'LEAD_REFERRAL_SHORT_CONSENT', //0%
    LEAD_REFERRAL_SPLIT = 'LEAD_REFERRAL_SPLIT', //0%
    NO_LEAD_REFERRAL = 'NO_LEAD_REFERRAL', //34%,
    LEAD_REFERRAL_SHORT_CONSENT_V2 = 'LEAD_REFERRAL_SHORT_CONSENT_V2', //33%
    LEAD_REFERRAL_SHORT_CONSENT_FONT_SIZE = 'LEAD_REFERRAL_SHORT_CONSENT_FONT_SIZE', //0%
    LEAD_REFERRAL_SHORT_CONSENT_FONT_SIZE_V2 = 'LEAD_REFERRAL_SHORT_CONSENT_FONT_SIZE_V2' //33%
}

export enum EmploymentPartialSavingVariant {
    PARTIAL_SAVING_HIDE = 'PARTIAL_SAVING_HIDE',
    PARTIAL_SAVING_OPTIONAL = 'PARTIAL_SAVING_OPTIONAL',
    FLAG_OFF = 'FLAG_OFF'
}

export enum GaqPhoneAtFirstStep {
    PHONE_MANDATORY = 'PHONE_MANDATORY', //0%
    PHONE_OPTIONAL = 'PHONE_OPTIONAL', //0%
    FLAG_OFF = 'FLAG_OFF' //0%
}

export enum GaqMinimumRequiredSteps {
    DEFAULT = 'DEFAULT',
    WITH_OWNER_OCCUPIED = 'WITH_OWNER_OCCUPIED',
    FLAG_OFF = 'FLAG_OFF'
}

export enum MpiProductCardStateVariant {
    MPI_HIDDEN = 'MPI_HIDDEN',
    PREMIUM_SEPARATE = 'PREMIUM_SEPARATE',
    PREMIUM_INCLUDED = 'PREMIUM_INCLUDED'
}

export const CREDITSCORE_QUALITY_OPTIONS: ConstantType<string> = [
    {
        value: 'EXCELLENT',
        label: 'application.creditScoreQuality.excellent'
    },
    {
        value: 'GOOD',
        label: 'application.creditScoreQuality.good'
    },
    {
        value: 'FAIR',
        label: 'application.creditScoreQuality.fair'
    },
    {
        value: 'POOR',
        label: 'application.creditScoreQuality.poor'
    },
    {
        value: 'UNKNOWN',
        label: 'application.creditScoreQuality.unknown'
    }
];

export enum Frequency {
    ANNUALLY = 'ANNUALLY',
    SEMIANNUALLY = 'SEMIANNUALLY',
    MONTHLY = 'MONTHLY',
    SEMIMONTHLY = 'SEMIMONTHLY',
    BIWEEKLY = 'BIWEEKLY',
    BIWEEKLYACCELERATED = 'BIWEEKLYACCELERATED',
    WEEKLY = 'WEEKLY',
    WEEKLYACCELERATED = 'WEEKLYACCELERATED'
}

export const ALL_COUNTRIES = [
    {
        value: 'CA',
        label: 'country.CA',
        default: 'Canada'
    },
    {
        value: 'US',
        label: 'country.US',
        default: 'United States'
    },
    {
        value: 'AF',
        label: 'country.AF',
        default: 'Afghanistan'
    },
    {
        value: 'AX',
        label: 'country.AX',
        default: '\u00c5land Islands'
    },
    {
        value: 'AL',
        label: 'country.AL',
        default: 'Albania'
    },
    {
        value: 'DZ',
        label: 'country.DZ',
        default: 'Algeria'
    },
    {
        value: 'AS',
        label: 'country.AS',
        default: 'American Samoa'
    },
    {
        value: 'AD',
        label: 'country.AD',
        default: 'Andorra'
    },

    {
        value: 'AO',
        label: 'country.AO',
        default: 'Angola'
    },
    {
        value: 'AI',
        label: 'country.AI',
        default: 'Anguilla'
    },
    {
        value: 'AQ',
        label: 'country.AQ',
        default: 'Antarctica'
    },
    {
        value: 'AG',
        label: 'country.AG',
        default: 'Antigua & Barbuda'
    },
    {
        value: 'AR',
        label: 'country.AR',
        default: 'Argentina'
    },
    {
        value: 'AM',
        label: 'country.AM',
        default: 'Armenia'
    },
    {
        value: 'AW',
        label: 'country.AW',
        default: 'Aruba'
    },
    {
        value: 'AU',
        label: 'country.AU',
        default: 'Australia'
    },
    {
        value: 'AT',
        label: 'country.AT',
        default: 'Austria'
    },
    {
        value: 'AZ',
        label: 'country.AZ',
        default: 'Azerbaijan'
    },
    {
        value: 'BS',
        label: 'country.BS',
        default: 'Bahamas'
    },
    {
        value: 'BH',
        label: 'country.BH',
        default: 'Bahrain'
    },
    {
        value: 'BD',
        label: 'country.BD',
        default: 'Bangladesh'
    },
    {
        value: 'BB',
        label: 'country.BB',
        default: 'Barbados'
    },
    {
        value: 'BY',
        label: 'country.BY',
        default: 'Belarus'
    },
    {
        value: 'BE',
        label: 'country.BE',
        default: 'Belgium'
    },
    {
        value: 'BZ',
        label: 'country.BZ',
        default: 'Belize'
    },
    {
        value: 'BJ',
        label: 'country.BJ',
        default: 'Benin'
    },
    {
        value: 'BM',
        label: 'country.BM',
        default: 'Bermuda'
    },
    {
        value: 'BT',
        label: 'country.BT',
        default: 'Bhutan'
    },

    {
        value: 'BO',
        label: 'country.BO',
        default: 'Bolivia'
    },
    {
        value: 'BA',
        label: 'country.BA',
        default: 'Bosnia & Herzegovina'
    },
    {
        value: 'BW',
        label: 'country.BW',
        default: 'Botswana'
    },
    {
        value: 'BV',
        label: 'country.BV',
        default: 'Bouvet Island'
    },
    {
        value: 'BR',
        label: 'country.BR',
        default: 'Brazil'
    },
    {
        value: 'IO',
        label: 'country.IO',
        default: 'British Indian Ocean Territory'
    },
    {
        value: 'VG',
        label: 'country.VG',
        default: 'British Virgin Islands'
    },
    {
        value: 'BN',
        label: 'country.BN',
        default: 'Brunei'
    },
    {
        value: 'BG',
        label: 'country.BG',
        default: 'Bulgaria'
    },
    {
        value: 'BF',
        label: 'country.BF',
        default: 'Burkina Faso'
    },
    {
        value: 'BI',
        label: 'country.BI',
        default: 'Burundi'
    },
    {
        value: 'KH',
        label: 'country.KH',
        default: 'Cambodia'
    },
    {
        value: 'CM',
        label: 'country.CM',
        default: 'Cameroon'
    },
    {
        value: 'CV',
        label: 'country.CV',
        default: 'Cape Verde'
    },
    {
        value: 'BQ',
        label: 'country.BQ',
        default: 'Caribbean Netherlands'
    },
    {
        value: 'KY',
        label: 'country.KY',
        default: 'Cayman Islands'
    },
    {
        value: 'CF',
        label: 'country.CF',
        default: 'Central African Republic'
    },
    {
        value: 'TD',
        label: 'country.TD',
        default: 'Chad'
    },
    {
        value: 'CL',
        label: 'country.CL',
        default: 'Chile'
    },
    {
        value: 'CN',
        label: 'country.CN',
        default: 'China'
    },
    {
        value: 'CX',
        label: 'country.CX',
        default: 'Christmas Island'
    },
    {
        value: 'CC',
        label: 'country.CC',
        default: 'Cocos (Keeling) Islands'
    },
    {
        value: 'CO',
        label: 'country.CO',
        default: 'Colombia'
    },
    {
        value: 'KM',
        label: 'country.KM',
        default: 'Comoros'
    },
    {
        value: 'CG',
        label: 'country.CG',
        default: 'Congo - Brazzaville'
    },
    {
        value: 'CD',
        label: 'country.CD',
        default: 'Congo - Kinshasa'
    },
    {
        value: 'CK',
        label: 'country.CK',
        default: 'Cook Islands'
    },
    {
        value: 'CK',
        label: 'country.CK',
        default: 'Cook Islands'
    },

    {
        value: 'CR',
        label: 'country.CR',
        default: 'Costa Rica'
    },
    {
        value: 'CI',
        label: 'country.CI',
        default: 'C\u00f4te d\u2019Ivoire'
    },
    {
        value: 'HR',
        label: 'country.HR',
        default: 'Croatia'
    },
    {
        value: 'CU',
        label: 'country.CU',
        default: 'Cuba'
    },
    {
        value: 'CW',
        label: 'country.CW',
        default: 'Cura\u00e7ao'
    },
    {
        value: 'CY',
        label: 'country.CY',
        default: 'Cyprus'
    },
    {
        value: 'CZ',
        label: 'country.CZ',
        default: 'Czechia'
    },
    {
        value: 'DK',
        label: 'country.DK',
        default: 'Denmark'
    },
    {
        value: 'DJ',
        label: 'country.DJ',
        default: 'Djibouti'
    },
    {
        value: 'DM',
        label: 'country.DM',
        default: 'Dominica'
    },
    {
        value: 'DO',
        label: 'country.DO',
        default: 'Dominican Republic'
    },
    {
        value: 'EC',
        label: 'country.EC',
        default: 'Ecuador'
    },
    {
        value: 'EG',
        label: 'country.EG',
        default: 'Egypt'
    },
    {
        value: 'SV',
        label: 'country.SV',
        default: 'El Salvador'
    },
    {
        value: 'GQ',
        label: 'country.GQ',
        default: 'Equatorial Guinea'
    },
    {
        value: 'ER',
        label: 'country.ER',
        default: 'Eritrea'
    },
    {
        value: 'EE',
        label: 'country.EE',
        default: 'Estonia'
    },
    {
        value: 'SZ',
        label: 'country.SZ',
        default: 'Eswatini'
    },
    {
        value: 'ET',
        label: 'country.ET',
        default: 'Ethiopia'
    },
    {
        value: 'FK',
        label: 'country.FK',
        default: 'Falkland Islands'
    },
    {
        value: 'FO',
        label: 'country.FO',
        default: 'Faroe Islands'
    },
    {
        value: 'FJ',
        label: 'country.FJ',
        default: 'Fiji'
    },
    {
        value: 'FI',
        label: 'country.FI',
        default: 'Finland'
    },
    {
        value: 'FR',
        label: 'country.FR',
        default: 'France'
    },
    {
        value: 'GF',
        label: 'country.GF',
        default: 'French Guiana'
    },
    {
        value: 'PF',
        label: 'country.PF',
        default: 'French Polynesia'
    },
    {
        value: 'TF',
        label: 'country.TF',
        default: 'French Southern Territories'
    },
    {
        value: 'GA',
        label: 'country.GA',
        default: 'Gabon'
    },
    {
        value: 'GM',
        label: 'country.GM',
        default: 'Gambia'
    },
    {
        value: 'GE',
        label: 'country.GE',
        default: 'Georgia'
    },
    {
        value: 'DE',
        label: 'country.DE',
        default: 'Germany'
    },
    {
        value: 'GH',
        label: 'country.GH',
        default: 'Ghana'
    },
    {
        value: 'GI',
        label: 'country.GI',
        default: 'Gibraltar'
    },
    {
        value: 'GR',
        label: 'country.GR',
        default: 'Greece'
    },
    {
        value: 'GL',
        label: 'country.GL',
        default: 'Greenland'
    },
    {
        value: 'GD',
        label: 'country.GD',
        default: 'Grenada'
    },
    {
        value: 'GP',
        label: 'country.GP',
        default: 'Guadeloupe'
    },
    {
        value: 'GU',
        label: 'country.GU',
        default: 'Guam'
    },
    {
        value: 'GT',
        label: 'country.GT',
        default: 'Guatemala'
    },
    {
        value: 'GG',
        label: 'country.GG',
        default: 'Guernsey'
    },
    {
        value: 'GN',
        label: 'country.GN',
        default: 'Guinea'
    },
    {
        value: 'GW',
        label: 'country.GW',
        default: 'Guinea-Bissau'
    },
    {
        value: 'GY',
        label: 'country.GY',
        default: 'Guyana'
    },
    {
        value: 'HT',
        label: 'country.HT',
        default: 'Haiti'
    },
    {
        value: 'HM',
        label: 'country.HM',
        default: 'Heard & McDonald Islands'
    },
    {
        value: 'HN',
        label: 'country.HN',
        default: 'Honduras'
    },
    {
        value: 'HK',
        label: 'country.HK',
        default: 'Hong Kong SAR China'
    },
    {
        value: 'HU',
        label: 'country.HU',
        default: 'Hungary'
    },
    {
        value: 'IS',
        label: 'country.IS',
        default: 'Iceland'
    },
    {
        value: 'IN',
        label: 'country.IN',
        default: 'India'
    },
    {
        value: 'ID',
        label: 'country.ID',
        default: 'Indonesia'
    },
    {
        value: 'IR',
        label: 'country.IR',
        default: 'Iran'
    },
    {
        value: 'IQ',
        label: 'country.IQ',
        default: 'Iraq'
    },
    {
        value: 'IE',
        label: 'country.IE',
        default: 'Ireland'
    },
    {
        value: 'IM',
        label: 'country.IM',
        default: 'Isle of Man'
    },
    {
        value: 'IL',
        label: 'country.IL',
        default: 'Israel'
    },
    {
        value: 'IT',
        label: 'country.IT',
        default: 'Italy'
    },
    {
        value: 'JM',
        label: 'country.JM',
        default: 'Jamaica'
    },
    {
        value: 'JP',
        label: 'country.JP',
        default: 'Japan'
    },
    {
        value: 'JE',
        label: 'country.JE',
        default: 'Jersey'
    },
    {
        value: 'JO',
        label: 'country.JO',
        default: 'Jordan'
    },
    {
        value: 'KZ',
        label: 'country.KZ',
        default: 'Kazakhstan'
    },
    {
        value: 'KE',
        label: 'country.KE',
        default: 'Kenya'
    },
    {
        value: 'KI',
        label: 'country.KI',
        default: 'Kiribati'
    },
    {
        value: 'KW',
        label: 'country.KW',
        default: 'Kuwait'
    },
    {
        value: 'KG',
        label: 'country.KG',
        default: 'Kyrgyzstan'
    },
    {
        value: 'LA',
        label: 'country.LA',
        default: 'Laos'
    },
    {
        value: 'LV',
        label: 'country.LV',
        default: 'Latvia'
    },
    {
        value: 'LB',
        label: 'country.LB',
        default: 'Lebanon'
    },
    {
        value: 'LS',
        label: 'country.LS',
        default: 'Lesotho'
    },
    {
        value: 'LR',
        label: 'country.LR',
        default: 'Liberia'
    },
    {
        value: 'LY',
        label: 'country.LY',
        default: 'Libya'
    },
    {
        value: 'LI',
        label: 'country.LI',
        default: 'Liechtenstein'
    },
    {
        value: 'LT',
        label: 'country.LT',
        default: 'Lithuania'
    },
    {
        value: 'LU',
        label: 'country.LU',
        default: 'Luxembourg'
    },
    {
        value: 'MO',
        label: 'country.MO',
        default: 'Macao SAR China'
    },
    {
        value: 'MG',
        label: 'country.MG',
        default: 'Madagascar'
    },
    {
        value: 'MW',
        label: 'country.MW',
        default: 'Malawi'
    },
    {
        value: 'MY',
        label: 'country.MY',
        default: 'Malaysia'
    },
    {
        value: 'MV',
        label: 'country.MV',
        default: 'Maldives'
    },
    {
        value: 'ML',
        label: 'country.ML',
        default: 'Mali'
    },
    {
        value: 'MT',
        label: 'country.MT',
        default: 'Malta'
    },
    {
        value: 'MH',
        label: 'country.MH',
        default: 'Marshall Islands'
    },
    {
        value: 'MQ',
        label: 'country.MQ',
        default: 'Martinique'
    },
    {
        value: 'MR',
        label: 'country.MR',
        default: 'Mauritania'
    },
    {
        value: 'MU',
        label: 'country.MU',
        default: 'Mauritius'
    },
    {
        value: 'YT',
        label: 'country.YT',
        default: 'Mayotte'
    },
    {
        value: 'MX',
        label: 'country.MX',
        default: 'Mexico'
    },
    {
        value: 'FM',
        label: 'country.FM',
        default: 'Micronesia'
    },
    {
        value: 'MD',
        label: 'country.MD',
        default: 'Moldova'
    },
    {
        value: 'MC',
        label: 'country.MC',
        default: 'Monaco'
    },
    {
        value: 'MN',
        label: 'country.MN',
        default: 'Mongolia'
    },
    {
        value: 'ME',
        label: 'country.ME',
        default: 'Montenegro'
    },
    {
        value: 'MS',
        label: 'country.MS',
        default: 'Montserrat'
    },
    {
        value: 'MA',
        label: 'country.MA',
        default: 'Morocco'
    },
    {
        value: 'MZ',
        label: 'country.MZ',
        default: 'Mozambique'
    },
    {
        value: 'MM',
        label: 'country.MM',
        default: 'Myanmar (Burma)'
    },
    {
        value: 'NA',
        label: 'country.NA',
        default: 'Namibia'
    },
    {
        value: 'NR',
        label: 'country.NR',
        default: 'Nauru'
    },
    {
        value: 'NP',
        label: 'country.NP',
        default: 'Nepal'
    },
    {
        value: 'NL',
        label: 'country.NL',
        default: 'Netherlands'
    },
    {
        value: 'NC',
        label: 'country.NC',
        default: 'New Caledonia'
    },
    {
        value: 'NZ',
        label: 'country.NZ',
        default: 'New Zealand'
    },
    {
        value: 'NI',
        label: 'country.NI',
        default: 'Nicaragua'
    },
    {
        value: 'NE',
        label: 'country.NE',
        default: 'Niger'
    },
    {
        value: 'NG',
        label: 'country.NG',
        default: 'Nigeria'
    },
    {
        value: 'NU',
        label: 'country.NU',
        default: 'Niue'
    },
    {
        value: 'NF',
        label: 'country.NF',
        default: 'Norfolk Island'
    },
    {
        value: 'KP',
        label: 'country.KP',
        default: 'North Korea'
    },
    {
        value: 'MK',
        label: 'country.MK',
        default: 'North Macedonia'
    },
    {
        value: 'MP',
        label: 'country.MP',
        default: 'Northern Mariana Islands'
    },
    {
        value: 'NO',
        label: 'country.NO',
        default: 'Norway'
    },
    {
        value: 'OM',
        label: 'country.OM',
        default: 'Oman'
    },
    {
        value: 'PK',
        label: 'country.PK',
        default: 'Pakistan'
    },
    {
        value: 'PW',
        label: 'country.PW',
        default: 'Palau'
    },
    {
        value: 'PS',
        label: 'country.PS',
        default: 'Palestinian Territories'
    },
    {
        value: 'PA',
        label: 'country.PA',
        default: 'Panama'
    },
    {
        value: 'PG',
        label: 'country.PG',
        default: 'Papua New Guinea'
    },
    {
        value: 'PY',
        label: 'country.PY',
        default: 'Paraguay'
    },
    {
        value: 'PE',
        label: 'country.PE',
        default: 'Peru'
    },
    {
        value: 'PH',
        label: 'country.PH',
        default: 'Philippines'
    },
    {
        value: 'PN',
        label: 'country.PN',
        default: 'Pitcairn Islands'
    },
    {
        value: 'PL',
        label: 'country.PL',
        default: 'Poland'
    },
    {
        value: 'PT',
        label: 'country.PT',
        default: 'Portugal'
    },
    {
        value: 'PR',
        label: 'country.PR',
        default: 'Puerto Rico'
    },
    {
        value: 'QA',
        label: 'country.QA',
        default: 'Qatar'
    },
    {
        value: 'RE',
        label: 'country.RE',
        default: 'R\u00e9union'
    },
    {
        value: 'RO',
        label: 'country.RO',
        default: 'Romania'
    },
    {
        value: 'RU',
        label: 'country.RU',
        default: 'Russia'
    },
    {
        value: 'RW',
        label: 'country.RW',
        default: 'Rwanda'
    },
    {
        value: 'WS',
        label: 'country.WS',
        default: 'Samoa'
    },
    {
        value: 'SM',
        label: 'country.SM',
        default: 'San Marino'
    },
    {
        value: 'ST',
        label: 'country.ST',
        default: 'S\u00e3o Tom\u00e9 & Pr\u00edncipe'
    },
    {
        value: 'SA',
        label: 'country.SA',
        default: 'Saudi Arabia'
    },
    {
        value: 'SN',
        label: 'country.SN',
        default: 'Senegal'
    },
    {
        value: 'RS',
        label: 'country.RS',
        default: 'Sebia'
    },
    {
        value: 'SC',
        label: 'country.SC',
        default: 'Seychelles'
    },
    {
        value: 'SL',
        label: 'country.SL',
        default: 'Sierra Leone'
    },
    {
        value: 'SG',
        label: 'country.SG',
        default: 'Singapore'
    },
    {
        value: 'SX',
        label: 'country.SX',
        default: 'Sint Maarten'
    },
    {
        value: 'SK',
        label: 'country.SK',
        default: 'Slovakia'
    },
    {
        value: 'SI',
        label: 'country.SI',
        default: 'Slovenia'
    },
    {
        value: 'SB',
        label: 'country.SB',
        default: 'Solomon Islands'
    },
    {
        value: 'SO',
        label: 'country.SO',
        default: 'Somalia'
    },
    {
        value: 'ZA',
        label: 'country.ZA',
        default: 'South Africa'
    },
    {
        value: 'GS',
        label: 'country.GS',
        default: 'South Georgia & South Sandwich Islands'
    },
    {
        value: 'KR',
        label: 'country.KR',
        default: 'South Korea'
    },
    {
        value: 'SS',
        label: 'country.SS',
        default: 'South Sudan'
    },
    {
        value: 'ES',
        label: 'country.ES',
        default: 'Spain'
    },
    {
        value: 'LK',
        label: 'country.LK',
        default: 'Sri Lanka'
    },
    {
        value: 'BL',
        label: 'country.BL',
        default: 'St. Barth\u00e9lemy'
    },
    {
        value: 'SH',
        label: 'country.SH',
        default: 'St. Helena'
    },
    {
        value: 'KN',
        label: 'country.KN',
        default: 'St. Kitts & Nevis'
    },
    {
        value: 'LC',
        label: 'country.LC',
        default: 'St. Lucia'
    },
    {
        value: 'MF',
        label: 'country.MF',
        default: 'St. Martin'
    },
    {
        value: 'PM',
        label: 'country.PM',
        default: 'St. Pierre & Miquelon'
    },
    {
        value: 'VC',
        label: 'country.VC',
        default: 'St. Vincent & Grenadines'
    },
    {
        value: 'SD',
        label: 'country.SD',
        default: 'Sudan'
    },
    {
        value: 'SR',
        label: 'country.SR',
        default: 'Suriname'
    },
    {
        value: 'SJ',
        label: 'country.SJ',
        default: 'Svalbard & Jan Mayen'
    },
    {
        value: 'SE',
        label: 'country.SE',
        default: 'Sweden'
    },
    {
        value: 'CH',
        label: 'country.CH',
        default: 'Switzerland'
    },
    {
        value: 'SY',
        label: 'country.SY',
        default: 'Syria'
    },
    {
        value: 'TW',
        label: 'country.TW',
        default: 'Taiwan'
    },
    {
        value: 'TJ',
        label: 'country.TJ',
        default: 'Tajikistan'
    },
    {
        value: 'TZ',
        label: 'country.TZ',
        default: 'Tanzania'
    },
    {
        value: 'TH',
        label: 'country.TH',
        default: 'Thailand'
    },
    {
        value: 'TL',
        label: 'country.TL',
        default: 'Timor-Leste'
    },
    {
        value: 'TG',
        label: 'country.TG',
        default: 'Togo'
    },
    {
        value: 'TK',
        label: 'country.TK',
        default: 'Tokelau'
    },
    {
        value: 'TO',
        label: 'country.TO',
        default: 'Tonga'
    },
    {
        value: 'TT',
        label: 'country.TT',
        default: 'Trinidad & Tobago'
    },
    {
        value: 'TN',
        label: 'country.TN',
        default: 'Tunisia'
    },
    {
        value: 'TR',
        label: 'country.TR',
        default: 'Turkey'
    },
    {
        value: 'TM',
        label: 'country.TM',
        default: 'Turkmenistan'
    },
    {
        value: 'TC',
        label: 'country.TC',
        default: 'Turks & Caicos Islands'
    },
    {
        value: 'TV',
        label: 'country.TV',
        default: 'Tuvalu'
    },
    {
        value: 'UM',
        label: 'country.UM',
        default: 'U.S. Outlying Islands'
    },
    {
        value: 'VI',
        label: 'country.VI',
        default: 'U.S. Virgin Islands'
    },
    {
        value: 'UG',
        label: 'country.UG',
        default: 'Uganda'
    },
    {
        value: 'UA',
        label: 'country.UA',
        default: 'Ukraine'
    },
    {
        value: 'AE',
        label: 'country.AE',
        default: 'United Arab Emirates'
    },
    {
        value: 'GB',
        label: 'country.GB',
        default: 'United Kingdom'
    },
    {
        value: 'UY',
        label: 'country.UY',
        default: 'Uruguay'
    },
    {
        value: 'UZ',
        label: 'country.UZ',
        default: 'Uzbekistan'
    },
    {
        value: 'VU',
        label: 'country.VU',
        default: 'Vanuatu'
    },
    {
        value: 'VA',
        label: 'country.VA',
        default: 'Vatican City'
    },
    {
        value: 'VE',
        label: 'country.VE',
        default: 'Venezuela'
    },
    {
        value: 'VN',
        label: 'country.VN',
        default: 'Vietnam'
    },
    {
        value: 'WF',
        label: 'country.WF',
        default: 'Wallis & Futuna'
    },
    {
        value: 'EH',
        label: 'country.EH',
        default: 'Western Sahara'
    },
    {
        value: 'YE',
        label: 'country.YE',
        default: 'Yemen'
    },
    {
        value: 'ZM',
        label: 'country.ZM',
        default: 'Zambia'
    },
    {
        value: 'ZW',
        label: 'country.ZW',
        default: 'Zimbabwe'
    }
];

export const SOCIALS = [
    'facebook',
    'instagram',
    'twitter',
    'linkedin',
    'pinterest',
    'reddit',
    'yelp',
    'livejournal',
    'tiktok'
];

export const WealthSimplePhone = `1-800-799-6188`;

export const MONTH_OPTIONS = [
    {
        value: '01',
        label: 'month.january'
    },
    {
        value: '02',
        label: 'month.february'
    },
    {
        value: '03',
        label: 'month.march'
    },
    {
        value: '04',
        label: 'month.april'
    },
    {
        value: '05',
        label: 'month.may'
    },
    {
        value: '06',
        label: 'month.june'
    },
    {
        value: '07',
        label: 'month.july'
    },
    {
        value: '08',
        label: 'month.august'
    },
    {
        value: '09',
        label: 'month.september'
    },
    {
        value: '10',
        label: 'month.october'
    },
    {
        value: '11',
        label: 'month.november'
    },
    {
        value: '12',
        label: 'month.december'
    }
];
