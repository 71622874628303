import { Typograph } from '@nesto/design-system';
import { Theme } from '@nesto/themes';
import React from 'react';
import { ApplicationProgressBarProps } from 'reducers/ui.redux';
import { Flex } from 'reflexbox/styled-components';
import styled, { ThemeProps, withTheme } from 'styled-components';
import { ProgressBar } from './progress-bar.component';
import { useScrollPosition } from './scroll-hooks';

const SectionWrapper = styled(Typograph)`
    font-size: ${({ theme }) => theme.fontSizes[0]};
    color: ${({ theme }) => theme.fontColor.disabled};
    padding-left: ${({ theme }) => theme.space[1]}px;
    font-weight: bold;
`;

const UserNameWrapper = styled(Flex)`
    font-size: ${({ theme }) => theme.fontSizes[0]};
    color: ${({ theme }) => theme.fontColor.disabled};
    padding-right: ${({ theme }) => theme.space[1]}px;
    border-right: 1px solid ${({ theme }) => theme.colors.grey25};
`;

const UserDetailsWrapper = styled(Flex)`
    border-top: 1px solid ${({ theme }) => theme.colors.grey25};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey25};
    padding: ${({ theme }) => theme.space[1]}px 0;
    width: 100%;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

const StatusBarWrapper = styled(Flex)`
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: all 0.4s ease-in;
`;

const StatusProgressBarMobileView = ({
    numOfSteps,
    currentStepIndex,
    section,
    userName,
    theme
}: ApplicationProgressBarProps & ThemeProps<Theme>) => {
    const position = useScrollPosition();

    return (
        <StatusBarWrapper
            isFixed={position.y}
            scrollDirection={position.direction}
        >
            <Flex
                data-test-id="progress-bar-header"
                width={1}
                flexDirection="column"
                alignItems="center"
                maxWidth={theme.contentWidths[3]}
            >
                <UserDetailsWrapper flex={1} flexDirection="row">
                    <UserNameWrapper data-test-id="mobile-headers-user-name">
                        {userName}
                    </UserNameWrapper>
                    <Flex>
                        <SectionWrapper
                            data-test-id="mobile-headers-section-name"
                            tx={section}
                        />
                    </Flex>
                </UserDetailsWrapper>

                <Flex width="100%">
                    <ProgressBar
                        numOfSteps={numOfSteps}
                        currentStepIndex={currentStepIndex}
                    />
                </Flex>
            </Flex>
        </StatusBarWrapper>
    );
};

export const StatusProgressBarMobile = withTheme(StatusProgressBarMobileView);
