import { RootState } from 'reducers';
import { createSelector } from '@reduxjs/toolkit';
import { SessionState } from './session.redux';

const selectSessionState = (state: RootState) => state.session;

export const selectDismissBannerCount = createSelector(
    selectSessionState,
    (state: SessionState) => state.bannerDismissCount
);

export const getActiveApplicationId = (state: any) =>
    (state as RootState).session?.activeApplicationId ?? undefined;

export const getLastLtvProductSelected = (state: any) =>
    (state as RootState).session?.lastLtvProductSelected;
