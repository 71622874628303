import { useState } from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import { connect } from 'react-redux';

import {
    getCurrentApplicationApplicantId,
    isApplicationCreated,
    getApplicationType,
    getActiveApplicationId,
    getIsLockedState,
    getAllOtherProperties,
    getApplicantsOthersIncome
} from 'reducers/application.selectors';
import {
    memorizedGetApplicationNavigationStates,
    ApplicantId,
    ApplicationNavigationStates,
    memorizedGetAllApplicationApplicants,
    getIsTargetPropertyActive,
    getIsTargetPropertyActiveDpInApp,
    memorizedGetApplicationNavigationStatesDPInApp
} from 'reducers/sidebar.selectors';
import {
    getCurrentStepFromRouter,
    getCurrentPath
} from 'reducers/startup.redux';
import { Actions } from 'reducers/application.redux';
import { Typograph } from 'components/typograph';
import { Accordion, AccordionContent } from 'components/accordion';
import { TabTitle } from 'components/tab-title/tab-title.component';
import { Applicant as ApplicantBase } from 'models/application/Application';
import { CircleAdd } from 'assets/media/icons/CircleAdd';
import { CircleRemove } from 'assets/media/icons/CircleRemove';
import { Confirm } from 'components/confirm';
import HouseIcon from 'assets/media/icons/house.png';
import { Routes } from 'app-root/routing/routes';
import { Tooltip } from 'components/tooltip';
import { useAppSelector } from 'redux-store/hooks';
import { getIsNavbarVisible } from 'reducers/ui.selectors';

type Props = {
    toggleSidebar: () => void;
};

type Applicant = ApplicantBase & { isMainApplicant?: boolean };

type StateProps = {
    activeApplicantId?: string | number;
    navigationStepStates: ApplicationNavigationStates;
    navigationStepStatesDPInApp: ApplicationNavigationStates;
    isApplicationSubmittable: boolean;
    applicants: Applicant[];
    currentStepFromRouter: string;
    currentPath: string;
    isApplicationTypeRenew: boolean;
    isApplicationTypeRefinance: boolean;
    isNewMortgage: boolean;
    isTargetPropertyActive: boolean;
    isTargetPropertyActiveDpInApp: boolean;
    applicationId?: number;
    isLocked?: boolean;
    hasOtherProperties?: boolean;
    hasOtherIncome?: boolean;
};

type DispatchProps = {
    deleteCoapplicant: (applicantId: number) => void;
};

// compute nav item states
const computeAllStates = (states): number =>
    states
        ? Object.values({ ...states, otherProperties: true }).filter(
              state => !state
          ).length
        : 0;

const computeAllStatesAreActive = states => computeAllStates(states) > 0;

const computeAllStatesAreComplete = states => computeAllStates(states) === 0;

const formatTrackingLabel = (isMainApplicant: boolean, label: string) =>
    `${isMainApplicant ? 'main-applicant' : 'co-applicant'} ${label}`;

const formatUrlsWithApplicantIds = (
    url: string,
    applicantId: string | number,
    name: string
): string => `${url}?applicant=${applicantId}&sectionName=${name}`;

type NavigationRoutesTitleTabProps = {
    to: string;
    name: string;
    label: string;
    isActiveType?: string;
    type: string;
    applicant: any;
    navigationStepStates: any;
    isCurrent?: boolean;
    active?: boolean;
    onClick: () => void;
    disabled?: boolean;
};

const NavigationRoutesTitleTab = ({
    to,
    name,
    label,
    isActiveType = '',
    type,
    applicant,
    navigationStepStates,
    isCurrent = false,
    active = undefined,
    onClick = () => {},
    disabled = false
}: NavigationRoutesTitleTabProps) => (
    <TabTitle
        trackingEventType="application"
        trackingLabel={formatTrackingLabel(applicant.isMainApplicant, type)}
        ml={4}
        pr={3}
        to={formatUrlsWithApplicantIds(to, applicant.applicantId, name)}
        fontSize={1}
        label={label}
        active={
            active !== undefined
                ? active
                : isActiveType &&
                  navigationStepStates[applicant.applicantId] &&
                  navigationStepStates[applicant.applicantId][isActiveType]
        }
        isComplete={
            type &&
            navigationStepStates[applicant.applicantId] &&
            navigationStepStates[applicant.applicantId][type]
        }
        isCurrent={isCurrent}
        onClick={onClick}
        disabled={disabled}
    />
);

export const getAccordianKey = (
    section: string,
    applicantId: ApplicantId | undefined
): string => {
    switch (section) {
        case 'create-coapplicant':
            return `create-coapplicant-${applicantId}`;
        case 'documents':
            return `documents-${applicantId}`;
        case 'target-property':
            return `target-property-${applicantId}`;
        case 'renewing-property':
            return `renewing-property-${applicantId}`;
        case 'submit':
            return `submit-${applicantId}`;
        default:
            return `application-${applicantId}`;
    }
};

const appApplicantClosedRoutes = [
    'documents',
    'submit',
    'submitted',
    'target-property',
    'create-coapplicant',
    'renewing-property'
];

const TargetPropertyLink = ({ tx }) => (
    <Flex width="100%" alignItems="center">
        <Flex width={20} height={20} mr={2}>
            <img src={HouseIcon} alt="house Icon" width={20} height={20} />
        </Flex>
        <Typograph tx={tx} />
    </Flex>
);

export const ApplicationNavigationRoutesView = ({
    activeApplicantId,
    applicants,
    currentPath,
    currentStepFromRouter,
    navigationStepStates,
    navigationStepStatesDPInApp,
    toggleSidebar,
    deleteCoapplicant,
    isApplicationTypeRenew,
    isApplicationTypeRefinance,
    isNewMortgage,
    isTargetPropertyActive,
    isTargetPropertyActiveDpInApp,
    isLocked,
    hasOtherProperties,
    hasOtherIncome
}: Props & StateProps & DispatchProps) => {
    const [removeApplicantId, setApplicantId] = useState<number | undefined>();
    const [isConfirmOpen, toggleConfirm] = useState(false);
    const isNavbarVisible = useAppSelector(state => getIsNavbarVisible(state));

    return (
        <>
            <Accordion
                width="270"
                initialState={getAccordianKey(
                    currentStepFromRouter,
                    activeApplicantId
                )}
            >
                {(isOpenAt, toggleAt) => (
                    <>
                        {applicants &&
                            applicants.map(applicant => {
                                return (
                                    <Box
                                        key={`accordion-get-qualified.${applicant.applicantId}`}
                                        data-test-id={`application-sidebar-${applicant.applicantId}`}
                                    >
                                        <TabTitle
                                            trackingEventType="application"
                                            trackingLabel={
                                                applicant.isMainApplicant
                                                    ? 'main-applicant'
                                                    : 'co-applicant'
                                            }
                                            dataTestId="applicant"
                                            to={formatUrlsWithApplicantIds(
                                                '/application/applicant-info',
                                                applicant.applicantId,
                                                'application.applicantInformation'
                                            )}
                                            onClick={() =>
                                                toggleAt(
                                                    isOpenAt !==
                                                        getAccordianKey(
                                                            'applicant-info',
                                                            applicant.applicantId
                                                        )
                                                        ? getAccordianKey(
                                                              'applicant-info',
                                                              applicant.applicantId
                                                          )
                                                        : null
                                                )
                                            }
                                            active={computeAllStatesAreActive(
                                                navigationStepStates[
                                                    applicant.applicantId
                                                ]
                                            )}
                                            isComplete={computeAllStatesAreComplete(
                                                navigationStepStatesDPInApp[
                                                    applicant.applicantId
                                                ]
                                            )}
                                            isCurrent={
                                                currentPath.startsWith(
                                                    '/application'
                                                ) &&
                                                !appApplicantClosedRoutes.includes(
                                                    currentStepFromRouter
                                                ) &&
                                                activeApplicantId ===
                                                    applicant.applicantId
                                            }
                                        >
                                            <Flex
                                                width="100%"
                                                justifyContent="space-between"
                                            >
                                                <Typograph>
                                                    {applicant.firstName}{' '}
                                                    {applicant.lastName}
                                                </Typograph>
                                                {!applicant.isMainApplicant &&
                                                    !isLocked && (
                                                        <Box
                                                            onClick={event => {
                                                                // avoid getting this click event
                                                                // to parent nav item
                                                                event.stopPropagation();
                                                                event.preventDefault();

                                                                setApplicantId(
                                                                    applicant.applicantId
                                                                );
                                                                toggleConfirm(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <CircleRemove
                                                                dataTestId="sidebar-removeCoApplicants-icon"
                                                                size={15}
                                                                color="boulder"
                                                            />
                                                        </Box>
                                                    )}
                                            </Flex>
                                        </TabTitle>
                                        <AccordionContent
                                            data-test-id={`application-sidebar-${
                                                applicant.applicantId
                                            }-${
                                                isOpenAt ===
                                                getAccordianKey(
                                                    'applicant-info',
                                                    applicant.applicantId
                                                )
                                                    ? 'open'
                                                    : 'closed'
                                            }`}
                                            isOpen={
                                                isOpenAt ===
                                                    getAccordianKey(
                                                        'applicant-info',
                                                        applicant.applicantId
                                                    ) &&
                                                !appApplicantClosedRoutes.includes(
                                                    currentStepFromRouter
                                                )
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <Flex
                                                flexDirection={'column'}
                                                my={1}
                                                data-test-id={`application-links-${applicant.applicantId}`}
                                            >
                                                <NavigationRoutesTitleTab
                                                    active
                                                    to="/application/applicant-info"
                                                    name="application.applicantInformation"
                                                    type="applicantInformation"
                                                    label="sidebar.identification"
                                                    navigationStepStates={
                                                        navigationStepStates
                                                    }
                                                    applicant={applicant}
                                                    onClick={toggleSidebar}
                                                    isCurrent={
                                                        currentPath ===
                                                            '/application/applicant-info' &&
                                                        activeApplicantId ===
                                                            applicant.applicantId
                                                    }
                                                />
                                                <NavigationRoutesTitleTab
                                                    to="/application/registered-address"
                                                    name="application.registeredAddress"
                                                    isActiveType="applicantInformation"
                                                    type="registeredAddress"
                                                    label="sidebar.registeredAddress"
                                                    navigationStepStates={
                                                        navigationStepStates
                                                    }
                                                    applicant={applicant}
                                                    onClick={toggleSidebar}
                                                    isCurrent={
                                                        currentPath ===
                                                            '/application/registered-address' &&
                                                        activeApplicantId ===
                                                            applicant.applicantId
                                                    }
                                                />

                                                <NavigationRoutesTitleTab
                                                    to={
                                                        '/application/employment-situation'
                                                    }
                                                    name={
                                                        'application.employmentSituation'
                                                    }
                                                    isActiveType="registeredAddress"
                                                    type="income"
                                                    label="sidebar.income"
                                                    navigationStepStates={
                                                        navigationStepStates
                                                    }
                                                    applicant={applicant}
                                                    onClick={toggleSidebar}
                                                    isCurrent={
                                                        [
                                                            '/application/employment-situation',
                                                            '/application/bankruptcy'
                                                        ].includes(
                                                            currentPath
                                                        ) &&
                                                        activeApplicantId ===
                                                            applicant.applicantId
                                                    }
                                                />

                                                <NavigationRoutesTitleTab
                                                    to="/application/other-income"
                                                    name="application.otherIncome"
                                                    isActiveType="applicantInformation"
                                                    type="otherIncome"
                                                    label="sidebar.otherIncome"
                                                    navigationStepStates={
                                                        navigationStepStates
                                                    }
                                                    applicant={applicant}
                                                    onClick={toggleSidebar}
                                                    isCurrent={
                                                        currentPath ===
                                                            '/application/other-income' &&
                                                        activeApplicantId ===
                                                            applicant.applicantId
                                                    }
                                                    disabled={
                                                        !hasOtherIncome &&
                                                        isLocked
                                                    }
                                                />
                                                <NavigationRoutesTitleTab
                                                    to={
                                                        Routes.applicationOtherProperty
                                                    }
                                                    name={'ownedProperty'}
                                                    isActiveType="applicantInformation"
                                                    type="otherProperties"
                                                    label="sidebar.ownedProperty"
                                                    navigationStepStates={
                                                        navigationStepStates
                                                    }
                                                    applicant={applicant}
                                                    onClick={toggleSidebar}
                                                    isCurrent={
                                                        [
                                                            Routes.applicationOtherProperty,
                                                            Routes.applicationHasTooManyProperties
                                                        ].includes(
                                                            currentPath
                                                        ) &&
                                                        activeApplicantId ===
                                                            applicant.applicantId
                                                    }
                                                    disabled={
                                                        !hasOtherProperties &&
                                                        isLocked
                                                    }
                                                />

                                                <NavigationRoutesTitleTab
                                                    to="/application/banking-details"
                                                    name="application.bankingDetails"
                                                    isActiveType="income"
                                                    type="bankingDetails"
                                                    label="sidebar.bankingDetails"
                                                    navigationStepStates={
                                                        navigationStepStates
                                                    }
                                                    applicant={applicant}
                                                    onClick={toggleSidebar}
                                                    isCurrent={
                                                        currentPath ===
                                                            '/application/banking-details' &&
                                                        activeApplicantId ===
                                                            applicant.applicantId
                                                    }
                                                />
                                            </Flex>
                                        </AccordionContent>
                                    </Box>
                                );
                            })}
                        {/* END of applicants sub nav loop */}
                        {!isNavbarVisible && (
                            <TabTitle
                                trackingEventType="application"
                                trackingLabel="add coapplicant"
                                to="/application/create-coapplicant"
                                onClick={toggleSidebar}
                                active
                                isCurrent={
                                    currentPath ===
                                    '/application/create-coapplicant'
                                }
                                disabled={isLocked}
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Flex alignItems="center">
                                        <Typograph tx="sidebar.addCoApplicants" />
                                        <Tooltip
                                            content="sidebar.coApplicantTooltip"
                                            triggerIcon="question-mark"
                                        />
                                    </Flex>
                                    {!isLocked && (
                                        <CircleAdd
                                            dataTestId="sidebar-addCoApplicants-icon"
                                            size={15}
                                            color="boulder"
                                        />
                                    )}
                                </Flex>
                            </TabTitle>
                        )}
                        {isApplicationTypeRenew ||
                        isApplicationTypeRefinance ? (
                            <TabTitle
                                trackingEventType="application"
                                trackingLabel="target property"
                                to={`/application/renewing-property?applicant=${activeApplicantId}&sectionName=application.renewingProperty`}
                                onClick={toggleSidebar}
                                label={
                                    isApplicationTypeRefinance
                                        ? 'sidebar.refinanceProperty'
                                        : 'sidebar.renewingProperty'
                                }
                                active={isTargetPropertyActive}
                                isComplete={
                                    navigationStepStates.subjectProperty
                                }
                                isCurrent={
                                    currentPath ===
                                    '/application/renewing-property'
                                }
                            >
                                <TargetPropertyLink
                                    tx={
                                        isApplicationTypeRefinance
                                            ? 'sidebar.refinanceProperty'
                                            : 'sidebar.renewingProperty'
                                    }
                                />
                            </TabTitle>
                        ) : (
                            <TabTitle
                                trackingEventType="application"
                                trackingLabel="target property"
                                to={`/application/target-property?applicant=${activeApplicantId}&sectionName=application.targetProperty`}
                                onClick={toggleSidebar}
                                label="sidebar.targetProperty"
                                active={isTargetPropertyActiveDpInApp}
                                isComplete={
                                    navigationStepStates.subjectProperty
                                }
                                isCurrent={
                                    currentPath ===
                                    '/application/target-property'
                                }
                            >
                                <TargetPropertyLink tx="sidebar.targetProperty" />
                            </TabTitle>
                        )}
                        {isNewMortgage && (
                            <TabTitle
                                trackingEventType="application"
                                trackingLabel="add coapplicant"
                                to="/application/assets-downpayment?sectionName=application.assetsDownPayment"
                                onClick={toggleSidebar}
                                active={navigationStepStates.subjectProperty}
                                isComplete={navigationStepStates.downpayment}
                                isCurrent={
                                    currentPath ===
                                    '/application/assets-downpayment'
                                }
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                >
                                    <Typograph tx="sidebar.downPayment" />
                                </Flex>
                            </TabTitle>
                        )}
                    </>
                )}
            </Accordion>
            <Confirm
                open={isConfirmOpen}
                onCloseComplete={() => toggleConfirm(false)}
                onConfirm={() => {
                    if (removeApplicantId) {
                        deleteCoapplicant(removeApplicantId);
                    }
                }}
                confirmLabel="confirm"
                cancelLabel="cancel"
                tx="application.confirm.removeApplicant"
            />
        </>
    );
};

export const ApplicationNavigationRoutes = connect<StateProps, DispatchProps>(
    (state: any) => ({
        activeApplicantId: getCurrentApplicationApplicantId(state),
        applicants: memorizedGetAllApplicationApplicants(state),
        currentPath: getCurrentPath(state),
        currentStepFromRouter: getCurrentStepFromRouter(state),
        navigationStepStates: memorizedGetApplicationNavigationStates(state),
        navigationStepStatesDPInApp: memorizedGetApplicationNavigationStatesDPInApp(
            state
        ),
        isApplicationSubmittable: isApplicationCreated(state),
        isApplicationTypeRenew: getApplicationType(state) === 'RENEWAL',
        isApplicationTypeRefinance: getApplicationType(state) === 'REFINANCE',
        isNewMortgage: getApplicationType(state) === 'NEW',
        isTargetPropertyActive: getIsTargetPropertyActive(state),
        isTargetPropertyActiveDpInApp: getIsTargetPropertyActiveDpInApp(state),
        applicationId: getActiveApplicationId(state),
        isLocked: getIsLockedState(state),
        hasOtherProperties: !!getAllOtherProperties(state).length,
        hasOtherIncome: !!getApplicantsOthersIncome(state).length
    }),
    dispatch => ({
        deleteCoapplicant: (applicantId: number) =>
            dispatch(Actions.deleteCoapplicant(applicantId))
    })
)(ApplicationNavigationRoutesView);
