import { createActions, createReducer } from 'reduxsauce';
import { omit } from 'ramda';
import {
    Document,
    DocumentCompositeKey,
    DocumentFile,
    DocumentList,
    DocumentTypes,
    DocumentsCounts,
    DocumentStep,
    BoostEmailInfo
} from 'models/documents';
import {
    appendIn,
    findDocIndexByCompositeKey,
    findFileIndexByProp,
    findFileIndexByUploadKey,
    removeIn,
    updateIn
} from 'utils/documents-helpers';
import { AccountTypes } from './account.redux';

export type DocumentState = {
    counts: DocumentsCounts;
    documents: DocumentList;
    lastBoostSentInfo: BoostEmailInfo;
    error: any;
    loading: boolean;
    loaded: boolean;
    types: DocumentTypes;
    filePreview: Partial<DocumentFile>;
    documentPreview: any;
    loadingPreview: boolean;
    activeApplicant: number;
    hasFileTooBigError: boolean;
    isModalShown: boolean;
    documentIndex: number | null;
};

export type ReduxState = {
    documents: DocumentState;
};

export type ReduxAction<Params = {}> = {
    type: string;
} & Params;

export type DocumentActions = {
    fileUploadRequest: (
        files: any,
        document: any,
        isNewDocument: any
    ) => ReduxAction;
    fileUploadSuccess: (data: any) => ReduxAction;
    fileUploadError: (error: any) => ReduxAction;

    updateDocumentRequest: (
        documentCompositeKey: any,
        payload: any,
        loading: any
    ) => ReduxAction;
    updateDocumentSuccess: (
        documentCompositeKey: any,
        updatedDocument: any
    ) => ReduxAction;
    updateDocumentError: (error: any) => ReduxAction;

    setActiveApplicant: (activeApplicant: number) => ReduxAction;

    showModal: (payload: boolean) => ReduxAction;
    setSelectedDocumentIndex: (documentIndex: number) => ReduxAction;

    updateDocumentStateRequest: (
        documentCompositeKey: any,
        event: any,
        loading: any
    ) => ReduxAction;
    updateDocumentStateSuccess: (
        documentCompositeKey: any,
        updatedDocument: any
    ) => ReduxAction;
    updateDocumentStateError: (error: any) => ReduxAction;
    updateHasFileTooBigError: (hasFileTooBigError: boolean) => ReduxAction;

    documentsRequest: (step: DocumentStep) => ReduxAction;
    allDocumentsRequest: (applicantIds: number[]) => ReduxAction;
    documentsBoostSend: (applicationId: any, applicantId: any) => ReduxAction;
    documentsBoostSuccess: (date: any) => ReduxAction;
    documentsBoostInfoRequest: (
        applicationId: any,
        applicantId: any
    ) => ReduxAction;
    documentsSuccess: (documents: any) => ReduxAction;
    documentsError: (error: any) => ReduxAction;

    documentsCountsRequest: () => ReduxAction;
    documentsCountsSuccess: (counts: DocumentsCounts) => ReduxAction;
    documentsCountsError: (error: any) => ReduxAction;

    createDocumentRequest: () => ReduxAction;
    createDocumentSuccess: (document: any) => ReduxAction;

    uploadFileRequest: (file: any, document: any) => ReduxAction;
    uploadFileSuccess: (
        file: any,
        updatedDocument: any,
        document: any
    ) => ReduxAction;
    uploadFileError: (uploadKey: any, document: any, error: any) => ReduxAction;

    fileDeleteRequest: (fileId: any, document: any) => ReduxAction;
    fileDeleteSuccess: (fileId: any, document: any) => ReduxAction;
    fileDeleteError: (fileId: any, document, error: any) => ReduxAction;

    getDocumentTypesRequest: () => ReduxAction;
    getDocumentTypesSuccess: (types: any) => ReduxAction;
    getDocumentTypesError: () => ReduxAction;

    fileDownloadRequest: (file: any, document: any) => ReduxAction;
    fileDownloadError: (error: any) => ReduxAction;

    fileDownloadPDFRequest: (file: any, document: any) => ReduxAction;
    fileDownloadPDFError: (error: any) => ReduxAction;

    filePreviewRequest: (file: any, document: any) => ReduxAction;
    filePreviewSuccess: (filePreview: DocumentFile) => ReduxAction;
    filePreviewError: (error: any) => ReduxAction;
    filePreviewClosed: () => ReduxAction;

    documentDownloadPDFRequest: (document: any) => ReduxAction;
    documentDownloadPDFError: (error: any) => ReduxAction;

    documentPreviewRequest: (document: any) => ReduxAction;
    documentPreviewSuccess: (documentPreview: any) => ReduxAction;
    documentPreviewError: (error: any) => ReduxAction;
    documentPreviewClosed: () => ReduxAction;

    createMessageRequest: (
        document: any,
        text: string,
        resolve: (value?: any | PromiseLike<any>) => void,
        reject: (reason?: any) => void
    ) => Promise<ReduxAction>;
    createMessageSuccess: () => ReduxAction;
    createMessageError: (error: any) => ReduxAction;

    deleteMessageRequest: (
        document: any,
        messageId: number
    ) => Promise<ReduxAction>;
    deleteMessageSuccess: () => ReduxAction;
    deleteMessageError: (error: any) => ReduxAction;

    editMessageRequest: (
        document: any,
        messageId: number,
        text: string,
        resolve: (value?: any | PromiseLike<any>) => void,
        reject: (reason?: any) => void
    ) => Promise<ReduxAction>;
    editMessageSuccess: () => ReduxAction;
    editMessageError: (error: any) => ReduxAction;
};

const { Types, Creators } = createActions({
    fileUploadRequest: ['files', 'document', 'isNewDocument'],
    fileUploadSuccess: ['data'],
    fileUploadError: ['error'],

    updateDocumentRequest: ['documentCompositeKey', 'payload', 'loading'],
    updateDocumentSuccess: ['documentCompositeKey', 'updatedDocument'],
    updateDocumentError: ['error'],

    updateDocumentStateRequest: ['documentCompositeKey', 'event', 'loading'],
    updateDocumentStateSuccess: ['documentCompositeKey', 'updatedDocument'],
    updateDocumentStateError: ['error'],
    updateHasFileTooBigError: ['hasFileTooBigError'],

    documentsRequest: ['step'],
    allDocumentsRequest: ['applicantIds'],
    setActiveApplicant: ['activeApplicant'],
    documentsBoostSend: [],
    documentsBoostSuccess: ['lastBoostSentInfo', 'loading'],
    documentsBoostError: ['lastBoostSentInfo', 'loading'],
    documentsBoostInfoRequest: ['lastBoostSentInfo', 'loading'],
    documentsSuccess: ['documents'],
    documentsError: ['error'],

    documentsCountsRequest: [],
    documentsCountsSuccess: ['counts'],
    documentsCountsError: ['error'],

    createDocumentRequest: [],
    createDocumentSuccess: ['document'],

    uploadFileRequest: ['file', 'document'],
    uploadFileSuccess: ['file', 'updatedDocument', 'document'],
    uploadFileError: ['uploadKey', 'document', 'error'],

    fileDeleteRequest: ['file', 'document'],
    fileDeleteSuccess: ['file', 'document', 'updatedDocument'],
    fileDeleteError: ['file', 'document', 'error'],

    getDocumentTypesRequest: [],
    getDocumentTypesSuccess: ['types'],
    getDocumentTypesError: [],

    fileDownloadRequest: ['file', 'document'],
    fileDownloadError: ['error'],

    filePreviewRequest: ['file', 'document'],
    filePreviewSuccess: ['filePreview'],
    filePreviewError: ['error'],

    filePreviewClosed: [],

    fileDownloadPDFRequest: ['file', 'document'],
    fileDownloadPDFError: ['error'],

    documentPreviewRequest: ['document'],
    documentPreviewSuccess: ['documentPreview'],
    documentPreviewError: ['error'],

    documentPreviewClosed: [],

    documentDownloadPDFRequest: ['document'],
    documentDownloadPDFError: ['error'],

    createMessageRequest: ['document', 'text', 'resolve', 'reject'],
    createMessageSuccess: [],
    createMessageError: ['error'],

    deleteMessageRequest: ['document', 'messageId'],
    deleteMessageSuccess: [],
    deleteMessageError: ['error'],

    editMessageRequest: ['document', 'messageId', 'text', 'resolve', 'reject'],
    editMessageSuccess: [],
    editMessageError: ['error'],
    showModal: ['isModalShown'],
    setSelectedDocumentIndex: ['documentIndex']
});

export const Actions = Creators as DocumentActions;

export const DocumentsTypes = Types;

export const INITIAL_STATE = {
    counts: {},
    documents: [],
    lastBoostSentInfo: null,
    error: null,
    loading: false,
    loaded: false,
    types: {},
    filePreview: {},
    documentPreview: {},
    loadingPreview: false,
    activeApplicant: null,
    isModalShown: false
};

const documentsRequest = (state: DocumentState) => ({
    ...state,
    loading: true
});
const documentsBoostSend = (state: DocumentState) => ({
    ...state,
    loading: true
});
const documentsBoostInfoRequest = (state: DocumentState) => ({
    ...state,
    loading: true
});
const documentsBoostSuccess = (
    state: DocumentState,
    { lastBoostSentInfo }: Partial<DocumentState>
) => ({
    ...state,
    loading: false,
    lastBoostSentInfo
});
const documentsBoostError = (
    state: DocumentState,
    { error }: Partial<DocumentState>
) => ({
    ...state,
    loading: false,
    lastBoostSentInfo: null,
    error
});
const documentsSuccess = (
    state: DocumentState,
    { documents }: Partial<DocumentState>
) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    documents
});
const documentsError = (
    state: DocumentState,
    { error }: Partial<DocumentState>
) => ({
    ...state,
    loading: false,
    error
});

const documentsCountsRequest = (
    state: DocumentState,
    { counts }: Partial<DocumentState>
) => ({
    ...state,
    error: null,
    loading: true
});

const documentsCountsSuccess = (
    state: DocumentState,
    { counts }: Partial<DocumentState>
) => ({
    ...state,
    loading: false,
    error: null,
    counts
});

const documentsCountsError = (
    state: DocumentState,
    { error }: Partial<DocumentState>
) => ({
    ...state,
    loading: false,
    error
});

const createDocumentRequest = (state: DocumentState) => ({ ...state });
const createDocumentSuccess = (
    state: DocumentState,
    { document }: { document: Document }
) => ({ ...state, documents: appendIn([], document, state.documents) });

const fileUploadRequest = (state: DocumentState) => ({
    ...state,
    error: null
});

const updateHasFileTooBigError = (
    state: DocumentState,
    hasFileTooBigError: boolean
) => ({
    ...state,
    hasFileTooBigError
});

const fileUploadSuccess = (state: DocumentState) => ({
    ...state,
    error: null
});

const setActiveApplicantFn = (
    state: DocumentState,
    { activeApplicant }: { activeApplicant: number }
) => ({
    ...state,
    activeApplicant
});

const fileUploadError = (
    state: DocumentState,
    { error }: Partial<DocumentState>
) => ({
    ...state,
    error
});

const uploadFileRequest = (
    state: DocumentState,
    { file, document }: { file: DocumentFile; document: Document }
) => {
    const docIndex = findDocIndexByCompositeKey(document, state.documents);

    return {
        ...state,
        documents: appendIn([docIndex, 'files'], file, state.documents)
    };
};

const uploadFileSuccess = (
    state: DocumentState,
    {
        documentCompositeKey,
        updatedDocument,
        file
    }: {
        documentCompositeKey: DocumentCompositeKey;
        updatedDocument: Document;
        file: DocumentFile;
    }
) => {
    const docIndex = findDocIndexByCompositeKey(
        documentCompositeKey,
        state.documents
    );
    const previousStateDocument = state.documents[docIndex];

    const fileIndex = findFileIndexByUploadKey(
        file.uploadKey as string,
        previousStateDocument.files
    );

    // Update file in files -> change loading status
    const files = updateIn(
        [fileIndex],
        {
            ...file,
            uploading: false,
            uploaded: true,
            error: null
        },
        previousStateDocument.files
    );

    return {
        ...state,
        error: null,
        documents: updateIn(
            [docIndex],
            { ...previousStateDocument, ...updatedDocument, files },
            state.documents
        )
    };
};

const uploadFileError = (
    state: DocumentState,
    {
        document,
        uploadKey,
        error
    }: { document: Document; error: any } & Pick<DocumentFile, 'uploadKey'>
) => {
    const docIndex = findDocIndexByCompositeKey(document, state.documents);
    const fileIndex = findFileIndexByUploadKey(
        uploadKey as string,
        state.documents[docIndex].files
    );

    return {
        ...state,
        documents: updateIn(
            [docIndex, 'files', fileIndex],
            {
                uploading: false,
                uploaded: false,
                error
            },
            state.documents
        )
    };
};

const fileDeleteRequest = (state: DocumentState) => ({
    ...state
});
const fileDeleteSuccess = (
    state: DocumentState,
    {
        document,
        file,
        updatedDocument
    }: {
        file: DocumentFile;
        document: Document;
        updatedDocument: Document;
        error: any;
    }
) => {
    const docIndex = findDocIndexByCompositeKey(document, state.documents);
    let previousStateDocument: Document = state.documents[docIndex];
    const fileIndex = findFileIndexByProp(
        file.fileId ? 'fileId' : 'uploadKey',
        (file.fileId || file.uploadKey) as string,
        state.documents[docIndex].files
    );

    previousStateDocument = (removeIn(
        ['files'],
        fileIndex,
        previousStateDocument
    ) as unknown) as Document;

    return {
        ...state,
        documents: updateIn(
            [docIndex],
            { ...previousStateDocument, ...omit(['files'], updatedDocument) },
            state.documents
        )
    };
};
const fileDeleteError = (
    state: DocumentState,
    {
        document,
        file,
        error
    }: { file: DocumentFile; document: Document; error: any }
) => {
    const docIndex = findDocIndexByCompositeKey(document, state.documents);
    const fileIndex = findFileIndexByProp(
        file.fileId ? 'fileId' : 'uploadKey',
        (file.fileId || file.uploadKey) as string,
        state.documents[docIndex].files
    );

    return {
        ...state,
        documents: updateIn(
            [docIndex, 'files', fileIndex],
            {
                error
            },
            state.documents
        )
    };
};

const getDocumentTypesRequest = (state: DocumentState) => ({
    ...state
});
const getDocumentTypesSuccess = (state: DocumentState, { types }: any) => ({
    ...state,
    types
});
const getDocumentTypesError = (
    state: DocumentState,
    { error }: Partial<DocumentState>
) => ({
    ...state,
    error
});

const updateDocumentRequest = (
    state: DocumentState,
    data: { loading: boolean }
) => {
    const { loading = true } = data;
    return {
        ...state,
        loading
    };
};

const updateDocumentSuccess = (
    state: DocumentState,
    {
        documentCompositeKey,
        updatedDocument
    }: { documentCompositeKey: DocumentCompositeKey; updatedDocument: Document }
) => {
    const docIndex = findDocIndexByCompositeKey(
        documentCompositeKey,
        state.documents
    );

    return {
        ...state,
        loading: false,
        error: null,
        documents: updateIn([docIndex], updatedDocument, state.documents)
    };
};

const updateDocumentError = (
    state: DocumentState,
    { error }: Partial<DocumentState>
) => ({ ...state, loading: false, error });

const filePreviewRequest = (state: DocumentState) => {
    return {
        ...state,
        loadingPreview: true
    };
};

const filePreviewSuccess = (
    state: DocumentState,
    { filePreview }: { filePreview: Partial<DocumentFile> }
) => ({
    ...state,
    loading: false,
    loadingPreview: false,
    error: null,
    filePreview
});

const filePreviewClosed = (state: DocumentState) => ({
    ...state,
    loading: false,
    loadingPreview: false,
    error: null,
    filePreview: {}
});

const filePreviewError = (
    state: DocumentState,
    { error }: Partial<DocumentState>
) => ({ ...state, loading: false, loadingPreview: false, error });

const documentPreviewRequest = (state: DocumentState) => {
    return {
        ...state,
        loadingPreview: true
    };
};

const documentPreviewSuccess = (
    state: DocumentState,
    { documentPreview }: { documentPreview: any }
) => ({
    ...state,
    loading: false,
    loadingPreview: false,
    error: null,
    documentPreview
});

const documentPreviewClosed = (state: DocumentState) => ({
    ...state,
    loading: false,
    loadingPreview: false,
    error: null,
    documentPreview: {}
});

const documentPreviewError = (
    state: DocumentState,
    { error }: Partial<DocumentState>
) => ({ ...state, loading: false, loadingPreview: {}, error });

const showModal = (
    state: DocumentState,
    { isModalShown }: Partial<DocumentState>
) => ({ ...state, isModalShown });

const setSelectedDocumentIndex = (
    state: DocumentState,
    { documentIndex }: Partial<DocumentState>
) => ({ ...state, documentIndex });

export const reducer = createReducer(INITIAL_STATE, {
    [Types.FILE_UPLOAD_REQUEST]: fileUploadRequest,
    [Types.FILE_UPLOAD_SUCCESS]: fileUploadSuccess,
    [Types.FILE_UPLOAD_ERROR]: fileUploadError,

    [Types.DOCUMENTS_REQUEST]: documentsRequest,
    [Types.ALL_DOCUMENTS_REQUEST]: documentsRequest,
    [Types.SET_ACTIVE_APPLICANT]: setActiveApplicantFn,
    [Types.DOCUMENTS_BOOST_SEND]: documentsBoostSend,
    [Types.DOCUMENTS_BOOST_INFO_REQUEST]: documentsBoostInfoRequest,
    [Types.DOCUMENTS_BOOST_SUCCESS]: documentsBoostSuccess,
    [Types.DOCUMENTS_BOOST_ERROR]: documentsBoostError,
    [Types.DOCUMENTS_SUCCESS]: documentsSuccess,
    [Types.DOCUMENTS_ERROR]: documentsError,

    [Types.DOCUMENTS_COUNTS_REQUEST]: documentsCountsRequest,
    [Types.DOCUMENTS_COUNTS_SUCCESS]: documentsCountsSuccess,
    [Types.DOCUMENTS_COUNTS_ERROR]: documentsCountsError,

    [Types.CREATE_DOCUMENT_REQUEST]: createDocumentRequest,
    [Types.CREATE_DOCUMENT_SUCCESS]: createDocumentSuccess,

    [Types.UPDATE_DOCUMENT_REQUEST]: updateDocumentRequest,
    [Types.UPDATE_DOCUMENT_SUCCESS]: updateDocumentSuccess,
    [Types.UPDATE_DOCUMENT_ERROR]: updateDocumentError,
    [Types.UPDATE_HAS_FILE_TOO_BIG_ERROR]: updateHasFileTooBigError,

    [Types.UPDATE_DOCUMENT_STATE_REQUEST]: updateDocumentRequest,
    [Types.UPDATE_DOCUMENT_STATE_SUCCESS]: updateDocumentSuccess,
    [Types.UPDATE_DOCUMENT_STATE_ERROR]: updateDocumentError,

    [Types.UPLOAD_FILE_REQUEST]: uploadFileRequest,
    [Types.UPLOAD_FILE_SUCCESS]: uploadFileSuccess,
    [Types.UPLOAD_FILE_ERROR]: uploadFileError,

    [Types.FILE_DELETE_REQUEST]: fileDeleteRequest,
    [Types.FILE_DELETE_SUCCESS]: fileDeleteSuccess,
    [Types.FILE_DELETE_ERROR]: fileDeleteError,

    [Types.GET_DOCUMENT_TYPES_REQUEST]: getDocumentTypesRequest,
    [Types.GET_DOCUMENT_TYPES_SUCCESS]: getDocumentTypesSuccess,
    [Types.GET_DOCUMENT_TYPES_ERROR]: getDocumentTypesError,

    [Types.FILE_PREVIEW_REQUEST]: filePreviewRequest,
    [Types.FILE_PREVIEW_SUCCESS]: filePreviewSuccess,
    [Types.FILE_PREVIEW_ERROR]: filePreviewError,
    [Types.FILE_PREVIEW_CLOSED]: filePreviewClosed,

    [Types.DOCUMENT_PREVIEW_REQUEST]: documentPreviewRequest,
    [Types.DOCUMENT_PREVIEW_SUCCESS]: documentPreviewSuccess,
    [Types.DOCUMENT_PREVIEW_ERROR]: documentPreviewError,
    [Types.DOCUMENT_PREVIEW_CLOSED]: documentPreviewClosed,
    [Types.SHOW_MODAL]: showModal,
    [Types.SET_SELECTED_DOCUMENT_INDEX]: setSelectedDocumentIndex,

    [AccountTypes.LOGOUT]: () => INITIAL_STATE
});
