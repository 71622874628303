import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox/styled-components';
import { Typograph } from '@nesto/design-system';

type Props = {
    numOfSteps: number;
    currentStepIndex: number;
    showApplicationSubmittedText?: boolean;
};

type PercentDoneProps = {
    percentDone: number;
};

const StepWrapper = styled(Flex)`
    position: relative;
    padding: 0px;
    cursor: default;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.floor};
`;

const AccomplishedSteps = styled.div<PercentDoneProps>`
    width: ${({ percentDone }) => `${percentDone}%`};
    height: 2px;
    background-color: ${({ theme }) => theme.brand.accent};
    transition: width 1s;
`;

const StillToDoSteps = styled.div<PercentDoneProps>`
    width: ${({ percentDone }) => `${100 - percentDone}%`};
    height: 1px;
    margin-top: 1px;
    background-color: ${({ theme }) => theme.borderColor.disabled};
    transition: width 1s;
`;

type StepCircleProps = {
    sectionPosition: string;
};

const StepCircle = styled.div<StepCircleProps & PercentDoneProps>`
    width: 11px;
    height: 11px;
    border: 2px solid ${({ theme }) => theme.brand.accent};
    border-radius: 50%;
    position: absolute;
    background-color: white;
    left: calc(${({ percentDone }) => `${percentDone}%`} - 2px);
    top: -4px;
    margin: auto;
    transition: left 1s;
    ${({ sectionPosition, percentDone }) =>
        sectionPosition === SectionPosition.right &&
        css`
            left: calc(${percentDone}% - 10px);
        `}
`;

const SectionPosition = {
    left: 'LEFT',
    center: 'CENTER',
    right: 'RIGHT'
};

const SectionWrapper = styled(Flex)`
    width: 100%;
    padding-top: ${props => props.theme.space[1]}px;
    padding-bottom: ${props => props.theme.space[1]}px;
    font-size: ${({ theme }) => theme.fontSizes[1]};
    color: ${({ theme }) => theme.brand.accent};
    margin-top: ${props => props.theme.space[1]}px;
    margin-bottom: ${props => props.theme.space[2]}px;
    justify-content: center;
    ${({ sectionPosition }) =>
        sectionPosition === SectionPosition.right &&
        css`
            justify-content: flex-end;
        `}

    ${({ sectionPosition }) =>
        sectionPosition === SectionPosition.left &&
        css`
            justify-content: flex-start;
        `}
`;

const getSectionPosition = (currentStepIndex, numOfSteps) => {
    if (currentStepIndex > numOfSteps - 6) return SectionPosition.right;

    if (currentStepIndex < 10) return SectionPosition.left;

    return SectionPosition.center;
};

export const ProgressBar = ({
    numOfSteps,
    currentStepIndex,
    showApplicationSubmittedText = true
}: Props) => {
    const percentage = Math.round((currentStepIndex / numOfSteps) * 100);
    const percentDone = percentage < 100 ? percentage : 100; // in GAQ we might get a percentage>100%
    const sectionPosition = getSectionPosition(currentStepIndex, numOfSteps);

    return (
        <Flex
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={numOfSteps}
            aria-valuenow={currentStepIndex}
            flex="1"
            width="100%"
            flexDirection="column"
            mt={[0, 0, 0, 4]}
        >
            <StepWrapper flexDirection="row">
                <AccomplishedSteps percentDone={percentDone} />
                <StillToDoSteps percentDone={percentDone} />
                <StepCircle
                    percentDone={percentDone}
                    sectionPosition={sectionPosition}
                />
            </StepWrapper>

            {numOfSteps === currentStepIndex && showApplicationSubmittedText && (
                <SectionWrapper
                    data-test-id="status-text"
                    sectionPosition={sectionPosition}
                >
                    <Typograph setInnerHtml tx="applicationSubmitted" />
                </SectionWrapper>
            )}
        </Flex>
    );
};
