import { getCurrencyByLocale } from './localization-utils';
import { apiClient } from 'services/api';
import { MAX_LENDING_AMOUNT_RATIO } from 'constants/lenderConstants';

export const getAvailableWithdrawalAmount = ({
    propertyValue,
    mortgageAmount,
    language = 'en'
}: {
    propertyValue: number;
    mortgageAmount: number;
    language?: string;
}) => {
    const percentage = MAX_LENDING_AMOUNT_RATIO;

    const availableAmount = propertyValue * percentage - mortgageAmount;

    const total = availableAmount > 0 ? availableAmount : 0;
    const formattedTotal = getCurrencyByLocale(language, total, {
        maximumFractionDigits: 0
    });

    return formattedTotal;
};

export const getIsPhoneNumberReal = async (
    phone: string,
    isPhoneNumber: boolean
) => {
    const { data, ok } = await apiClient.validatePhoneNumber(phone);

    if (ok) {
        const { valid } = data as { valid: boolean };
        const isPhoneNumberReal = valid && isPhoneNumber;
        return isPhoneNumberReal;
    } else {
        return false;
    }
};
