export enum DocumentCategory {
    incomes = 'INCOMES',
    identification = 'IDENTIFICATION',
    finalDocuments = 'FINAL_DOCUMENTS',
    financials = 'FINANCIALS',
    properties = 'PROPERTIES',
    lowRateGuarantee = 'LOW_RATE_GUARANTEE',
    // Others
    other = 'OTHER'
}
