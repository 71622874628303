import * as React from 'react';
import styled from 'styled-components/macro';
import {
    maxHeight,
    MaxHeightProps,
    maxWidth,
    MaxWidthProps,
    space,
    SpaceProps,
    OverflowProps,
    overflow,
    color,
    ColorProps,
    BackgroundProps
} from 'styled-system';

import { ModalOverlay as Overlay } from 'components/modal-overlay';

type Props = {
    children: any;
    isShown: boolean;
    onCloseComplete?: () => void;
    onOpenComplete?: () => void;
    topLevel?: boolean;
    level?: string;
    closeOnEsc?: boolean;
};

const ModalWrapper = styled.div<any>`
    width: 100%;
    height: 100%;
    ${overflow}
    ${color}
    ${maxHeight}
    ${maxWidth}
    ${space}
`;

const ModalManager = ({
    isShown,
    onCloseComplete,
    onOpenComplete,
    children,
    topLevel = false,
    level,
    closeOnEsc = true,
    bg,
    ...props
}: Props &
    SpaceProps &
    OverflowProps &
    MaxWidthProps &
    MaxHeightProps &
    BackgroundProps &
    ColorProps) => (
    <Overlay
        onExited={onCloseComplete}
        onEntered={onOpenComplete}
        isShown={isShown}
        topLevel={topLevel}
        level={level}
        closeOnEsc={closeOnEsc}
        bg={bg}
    >
        {({ state, close }: { state: boolean; close: () => void }) => (
            <ModalWrapper data-state={state} {...props}>
                {typeof children === 'function'
                    ? children({ close })
                    : children}
            </ModalWrapper>
        )}
    </Overlay>
);

export { ModalManager };
