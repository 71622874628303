import { put, call, select, all } from 'redux-saga/effects';
import { destroy } from 'redux-form';
import { push } from 'connected-react-router';
import { Routes } from 'app-root/routing/routes';
import { log } from 'utils/logging';
import { apiClient } from 'services/api';
import { Token } from 'services/api/ApiClient';
import { Actions } from 'reducers/account.redux';
import { analyticEvent } from './analytics-events.sagas';
import { clearServicingPortalStorage } from 'utils/auth';

type SetAuthTokenType = {
    type?: string;
    token: Token;
};

export function* setAuthToken({ token }: SetAuthTokenType) {
    try {
        yield call(apiClient.setAuthToken, token);
    } catch (error) {
        log({ tag: 'setting auth token: ', error });
        yield put(Actions.behalfLogoutFailure({ error }));
    }
}

export function* onLogout() {
    const location = window.location.pathname;
    const isGAQCurrentRoute = [
        Routes.getAQuoteRoot,
        Routes.frenchCanadaGetAQuoteRoot,
        Routes.frenchCanadaGetAQuoteNewMortgageUnifiedSf,
        Routes.frenchCanadaGetAQuoteRefinanceUnifiedSf,
        Routes.frenchCanadaGetAQuoteRenewalUnifiedSf,
        Routes.getAQuoteNewMortgageUnifiedSf,
        Routes.getAQuoteRefinanceUnifiedSF,
        Routes.getAQuoteRenewalUnifiedSf
    ].includes(location);

    try {
        // GA-4
        yield put(
            analyticEvent('log_out', {
                event_location_category: 'account',
                info: location,
                location: location
            })
        );

        // manually destroy all the currently active forms so they do not
        // persist after the user logs out.
        const allActiveForms: { [s in string]: any } = yield select(
            (state: any) => state.form
        );
        const forms = Object.keys(allActiveForms)
            .filter(formname => formname !== '_persist')
            .map(formname => put(destroy(formname)));
        yield all(forms);

        yield call(apiClient.clearAuthToken);

        // always set to false
        window.localStorage.setItem('isUserBroker', 'false');
        // Remove localStorage value on logout
        window.localStorage.removeItem('broker_on_behalf_of');

        window.localStorage.removeItem('persist:auth');

        clearServicingPortalStorage();

        // if user is at the start of the GAQ flow, do not redirect on logout
        // since this route is both public and private

        if (!isGAQCurrentRoute) {
            yield put(push('/login'));
        }
    } catch (error) {
        log({ error: `Failed to logout: ${error}` });
    }
}
