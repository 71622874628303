import { LocaleTypes, UpdateLocaleType } from './locale.redux';
import { createReducer, createActions } from 'reduxsauce';
import { AccountDeletionState } from 'types/account/account-deletion';
import { Language } from 'types/language';

export type Account = {
    id: number;
    role: 'borrower';
    email: string;
    created: string;
    updated: string;
    firstName: string;
    firstNameSpecified: boolean;
    lastName: string;
    lastNameSpecified: boolean;
    phone: string;
    postalCode?: string;
    phoneSpecified: boolean;
    preferredLanguage: Language;
    preferredLanguageSpecified: boolean;
    region: 'QC' | 'ON';
    regionSpecified: boolean;
    active: boolean;
    activated: string;
    /** @deprecated use communicationPreferences instead */
    emailConsent: boolean;
    partnerAgreement?: boolean;
    partnerAgreementSpecified?: boolean;
    partner?: string;
    impressionsTrackingIdSpecified?: boolean;
    impressionsTrackingId?: string;
    affiliateMarketingId?: number;
    leadDistributeConsentAgreement?: boolean;
};

export type BrokerAccount = {
    id: number;
    role: string;
    email: string;
    created: string;
    updated: string;
    firstName: string;
    firstNameSpecified: boolean;
    lastName: string;
    lastNameSpecified: boolean;
    phone: string;
    phoneSpecified: boolean;
    preferredLanguage: Language;
    preferredLanguageSpecified: boolean;
    region: 'QC' | 'ON';
    regionSpecified: boolean;
    filogixAgentId: string;
};

export type AccountTrackingPayload = {
    key: string;
    value: string;
};

export type CallPreferencesPayload = {
    callPreference: string | null;
};

export type CommunicationPreferencesType =
    | 'newsletters'
    | 'documentCenter'
    | 'rateDrop'
    | 'advisorsCommunication'
    | 'reminders';
export type CommunicationPreferences = Record<
    CommunicationPreferencesType,
    { email: boolean; sms: boolean }
>;

export type AccountState = {
    loading: boolean;
    savedUserInformation: boolean;
    requestingAccount: boolean;
    requestingToken: boolean;
    accountCreated: boolean;
    passwordResetSent: boolean;
    failedLoginAttempts: number;
    accountActivated: boolean;
    impressionsTrackingId: string | null;
    error: string | null;
    account: Account | null;
    broker: BrokerAccount | null;
    communicationPreferences: CommunicationPreferences | null;
    accountDeletionState: AccountDeletionState | null;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    resetAnalyticsForBroker: null,

    updateUserInformation: ['formValues', 'resolve', 'reject'],
    updateUserPostalCode: ['formValues'],
    updateUserSuccess: ['account'],
    updateUserError: ['error'],

    getAccountRequest: null,
    getAccountFailure: ['error'],
    getAccountExit: ['message'],
    getAccountSuccess: ['account'],

    createAccountRequest: ['userInfo', 'redirect', 'noRedirect'],
    createAccountSuccess: ['account', 'token'],
    createAccountError: ['error'],

    loginRequest: ['loginFormValues'],
    loginSuccess: ['account', 'token'],
    loginFailure: ['error'],

    brokerLoginRequest: ['loginFormValues'],
    brokerLoginStart: null,
    brokerLoginSuccess: ['broker', 'token'],
    brokerLoginFailure: ['error'],

    behalfLoginRequest: ['accountRid'],
    behalfLoginStart: null,
    behalfLoginSuccess: ['account', 'token'],
    behalfLoginFailure: ['error'],

    behalfLogoutRequest: [],
    behalfLogoutStart: null,
    behalfLogoutSuccess: ['token'],
    behalfLogoutFailure: ['error'],

    passwordResetRequest: ['resetPasswordFormValues'],
    passwordResetSuccess: null,
    passwordResetFailure: ['error'],

    passwordChangeRequest: ['changePasswordFormValues'],
    passwordChangeSuccess: null,
    passwordChangeFailure: ['error'],

    activateAccountRequest: ['activationToken', 'accountId', 'redirect'],
    activateAccountSuccess: null,
    activateAccountFailure: ['error'],

    refreshTokenRequest: ['refreshToken'],
    refreshTokenSuccess: ['account', 'token'],
    refreshTokenError: ['error'],

    getBrokerAccountRequest: null,
    getBrokerAccountFailure: ['error'],
    getBrokerAccountSuccess: ['broker'],

    getCommunicationPreferences: null,
    getCommunicationPreferencesFailure: ['error'],
    getCommunicationPreferencesSuccess: ['communicationPreferences'],

    getAccountDeletionState: null,
    getAccountDeletionStateFailure: ['error'],
    getAccountDeletionStateSuccess: ['accountDeletionState'],

    updateCommunicationPreferences: ['payload'],
    updateCommunicationPreferencesFailure: ['error'],
    updateCommunicationPreferencesSuccess: ['communicationPreferences'],

    updateImpressionsTrackingId: ['impressionsTrackingId'],
    updateAffiliateMarketingId: ['affiliateMarketingId'],
    accountTracking: ['payload'],
    getAccountTracking: ['key'],

    addCallPreferences: ['payload'],

    logout: null
});

export { Creators as Actions };

export const AccountTypes = Types;

export const INITIAL_STATE: AccountState = {
    loading: false,
    savedUserInformation: false,
    requestingAccount: false,
    requestingToken: false,
    accountCreated: false,
    passwordResetSent: false,
    failedLoginAttempts: 0,
    accountActivated: false,
    impressionsTrackingId: null,
    error: null,
    account: null,
    broker: null,
    communicationPreferences: null,
    accountDeletionState: null
};

const getAccountSuccess = (
    state: AccountState,
    { account }: { account: Account }
) => ({
    ...state,
    loading: false,
    error: null,
    account
});

const getAccountFailure = (state: AccountState, { error }: { error: any }) => ({
    ...state,
    loading: false,
    error
});

const createAccountRequest = (state: AccountState) => ({
    ...state,
    requestingAccount: true,
    error: null
});

const createAccountSuccess = (
    state: AccountState,
    { account }: { account: Account }
) => ({
    ...state,
    error: null,
    requestingAccount: false,
    accountCreated: true,
    account
});
const createAccountError = (
    state: AccountState,
    { error }: { error: any }
) => ({
    ...state,
    error,
    requestingAccount: false
});

const loginRequest = (state: AccountState) => ({ ...state, loading: true });
const loginSuccess = (
    state: AccountState,
    { account }: { account: Account }
) => ({
    ...state,
    loading: false,
    account,
    error: null
});
const loginFailure = (state: AccountState, { error }: { error: any }) => ({
    ...state,
    loading: false,
    error,
    failedLoginAttempts: state.failedLoginAttempts + 1
});

const brokerLoginRequest = (state: AccountState) => ({
    ...state,
    loading: true
});

const brokerLoginSuccess = (
    state: AccountState,
    { broker }: { broker: BrokerAccount }
) => ({
    ...state,
    loading: false,
    broker,
    error: null
});

const brokerLoginFailure = (
    state: AccountState,
    { error }: { error: any }
) => ({
    ...state,
    loading: false,
    error,
    failedLoginAttempts: state.failedLoginAttempts + 1
});

const getBrokerAccountRequest = state => ({
    ...state,
    loading: true
});
const getBrokerAccountFailure = (state, { error }: { error: any }) => ({
    ...state,
    loading: false,
    error
});
const getBrokerAccountSuccess = (
    state,
    { broker }: { broker: BrokerAccount }
) => ({
    ...state,
    loading: false,
    broker,
    error: null
});

const behalfLoginRequest = (state: AccountState) => ({
    ...state,
    loading: true
});

const behalfLoginSuccess = (
    state: AccountState,
    { account }: { account: Account }
) => ({
    ...state,
    loading: false,
    account,
    error: null
});

const behalfLoginFailure = (
    state: AccountState,
    { error }: { error: any }
) => ({
    ...state,
    loading: false,
    error,
    failedLoginAttempts: state.failedLoginAttempts + 1
});

const behalfLogoutRequest = (state: AccountState) => ({
    ...state,
    loading: true
});

const behalfLogoutSuccess = (state: AccountState) => ({
    ...INITIAL_STATE,
    account: null,
    broker: state.broker
});

const behalfLogoutFailure = (
    state: AccountState,
    { error }: { error: any }
) => ({
    ...state,
    loading: false,
    error
});

const passwordResetRequest = (state: AccountState) => ({
    ...state,
    loading: true
});
const passwordResetSuccess = (state: AccountState) => ({
    ...state,
    loading: false,
    passwordResetSent: true,
    error: null
});
const passwordResetFailure = (
    state: AccountState,
    { error }: { error: any }
) => ({
    ...state,
    loading: false,
    error
});

const passwordChangeRequest = (state: AccountState) => ({
    ...state,
    loading: true
});
const passwordChangeSuccess = (state: AccountState) => ({
    ...state,
    loading: false,
    error: null
});
const passwordChangeFailure = (
    state: AccountState,
    { error }: { error: any }
) => ({
    ...state,
    loading: false,
    error
});

const activateAccountRequest = (state: AccountState) => ({
    ...state,
    loading: true
});
const activateAccountSuccess = (state: AccountState) => ({
    ...state,
    accountActivated: true,
    loading: false,
    error: null
});

const updateLanguage = (
    state: AccountState,
    { language }: UpdateLocaleType
) => ({
    ...state,
    account: {
        ...state.account,
        preferredLanguage: language
    }
});

const activateAccountFailure = (
    state: AccountState,
    { error }: { error: any }
) => ({
    ...state,
    accountActivated: false,
    loading: false,
    error
});

const logout = (state: AccountState) => ({
    ...INITIAL_STATE,
    account: {
        preferredLanguage: state.broker
            ? state.broker.preferredLanguage
            : state.account?.preferredLanguage
    }
});

const updateAccount = (state: AccountState, payload: Account) => ({
    ...state,
    loading: true,
    account: {
        ...state.account,
        ...payload
    }
});

// Sanitize CK Tracking ID to fit our BE requirement and CK requirement
const sanitizeImpressionsTrackingId = (impressionsTrackingId: string) =>
    (impressionsTrackingId || '').substring(0, 32);

const updateImpressionsTrackingId = (
    state: AccountState,
    { impressionsTrackingId }: { impressionsTrackingId: string }
) => ({
    ...state,
    impressionsTrackingId: sanitizeImpressionsTrackingId(impressionsTrackingId)
});

const communicationPreferences = state => ({
    ...state,
    loading: true
});

const communicationPreferencesFailure = (state, { error }: { error: any }) => ({
    ...state,
    loading: false,
    error
});

const communicationPreferencesSuccess = (
    state,
    {
        communicationPreferences
    }: { communicationPreferences: CommunicationPreferences }
) => ({
    ...state,
    loading: false,
    communicationPreferences,
    error: null
});

const getAccountDeletionState = (state: AccountState) => ({
    ...state,
    loading: true
});

const getAccountDeletionStateFailure = (
    state: AccountState,
    { error }: { error: any }
) => ({
    ...state,
    loading: false,
    error
});

const getAccountDeletionStateSuccess = (
    state: AccountState,
    { accountDeletionState }: { accountDeletionState: AccountDeletionState }
) => ({
    ...state,
    loading: false,
    accountDeletionState,
    error: null
});

const updateAffiliateMarketingId = (
    state: AccountState,
    { affiliateMarketingId }: { affiliateMarketingId: string }
) => ({ ...state, affiliateMarketingId });

export const reducer = createReducer(INITIAL_STATE, {
    [LocaleTypes.UPDATE_LOCALE_AND_MESSAGES]: updateLanguage,
    [Types.GET_ACCOUNT_SUCCESS]: getAccountSuccess,
    [Types.GET_ACCOUNT_FAILURE]: getAccountFailure,
    [Types.UPDATE_USER_INFORMATION]: updateAccount,
    [Types.UPDATE_USER_POSTAL_CODE]: updateAccount,

    [Types.UPDATE_USER_SUCCESS]: getAccountSuccess,
    [Types.UPDATE_USER_ERROR]: createAccountError,

    [Types.CREATE_ACCOUNT_REQUEST]: createAccountRequest,
    [Types.CREATE_ACCOUNT_SUCCESS]: createAccountSuccess,
    [Types.CREATE_ACCOUNT_ERROR]: createAccountError,

    [Types.LOGIN_REQUEST]: loginRequest,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILURE]: loginFailure,

    [Types.BROKER_LOGIN_REQUEST]: brokerLoginRequest,
    [Types.BROKER_LOGIN_SUCCESS]: brokerLoginSuccess,
    [Types.BROKER_LOGIN_FAILURE]: brokerLoginFailure,

    [Types.BEHALF_LOGIN_REQUEST]: behalfLoginRequest,
    [Types.BEHALF_LOGIN_SUCCESS]: behalfLoginSuccess,
    [Types.BEHALF_LOGIN_FAILURE]: behalfLoginFailure,

    [Types.BEHALF_LOGOUT_REQUEST]: behalfLogoutRequest,
    [Types.BEHALF_LOGOUT_SUCCESS]: behalfLogoutSuccess,
    [Types.BEHALF_LOGOUT_FAILURE]: behalfLogoutFailure,

    [Types.PASSWORD_RESET_REQUEST]: passwordResetRequest,
    [Types.PASSWORD_RESET_SUCCESS]: passwordResetSuccess,
    [Types.PASSWORD_RESET_FAILURE]: passwordResetFailure,

    [Types.PASSWORD_CHANGE_REQUEST]: passwordChangeRequest,
    [Types.PASSWORD_CHANGE_SUCCESS]: passwordChangeSuccess,
    [Types.PASSWORD_CHANGE_FAILURE]: passwordChangeFailure,

    [Types.ACTIVATE_ACCOUNT_REQUEST]: activateAccountRequest,
    [Types.ACTIVATE_ACCOUNT_SUCCESS]: activateAccountSuccess,
    [Types.ACTIVATE_ACCOUNT_FAILURE]: activateAccountFailure,

    [Types.GET_BROKER_ACCOUNT_REQUEST]: getBrokerAccountRequest,
    [Types.GET_BROKER_ACCOUNT_SUCCESS]: getBrokerAccountSuccess,
    [Types.GET_BROKER_ACCOUNT_FAILURE]: getBrokerAccountFailure,

    [Types.GET_COMMUNICATION_PREFERENCES]: communicationPreferences,
    [Types.GET_COMMUNICATION_PREFERENCES_FAILURE]: communicationPreferencesFailure,
    [Types.GET_COMMUNICATION_PREFERENCES_SUCCESS]: communicationPreferencesSuccess,

    [Types.UPDATE_COMMUNICATION_PREFERENCES]: communicationPreferences,
    [Types.UPDATE_COMMUNICATION_PREFERENCES_FAILURE]: communicationPreferencesFailure,
    [Types.UPDATE_COMMUNICATION_PREFERENCES_SUCCESS]: communicationPreferencesSuccess,

    [Types.GET_ACCOUNT_DELETION_STATE]: getAccountDeletionState,
    [Types.GET_ACCOUNT_DELETION_STATE_FAILURE]: getAccountDeletionStateFailure,
    [Types.GET_ACCOUNT_DELETION_STATE_SUCCESS]: getAccountDeletionStateSuccess,

    [Types.UPDATE_IMPRESSIONS_TRACKING_ID]: updateImpressionsTrackingId,
    [Types.UPDATE_AFFILIATE_MARKETING_ID]: updateAffiliateMarketingId,

    [Types.LOGOUT]: logout
});
