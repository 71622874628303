// This file is intendented to store react-hook-form into redux when submitting
// useful for wizard form / funnel

import { AccountTypes } from './account.redux';

export type ReduxState = Record<string, any>;

export const Types = {
    UPDATE: 'HOOK_FORM/UPDATE'
};

const INITIAL_STATE: ReduxState = {};

export const reducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case Types.UPDATE:
            return {
                ...state,
                [action.form]: {
                    ...state?.[action.form],
                    ...action.payload
                }
            };
        case AccountTypes.LOGOUT:
        case AccountTypes.BEHALF_LOGIN_SUCCESS:
        case AccountTypes.LOGIN_SUCCESS:
        case AccountTypes.BROKER_LOGIN_SUCCESS:
            return INITIAL_STATE;
        default:
            return state;
    }
};

const updateForm = (form: string, payload: any) => ({
    type: Types.UPDATE,
    form,
    payload
});

export const Actions = {
    updateForm
};
