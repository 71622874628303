import { DocumentCategory } from 'models/documents';

export const DOC_CATEGORIES = {
    identification: {
        type: DocumentCategory.identification,
        label: 'documents.category.identification'
    },
    incomes: {
        type: DocumentCategory.incomes,
        label: 'documents.category.incomes'
    },
    properties: {
        type: DocumentCategory.properties,
        label: 'documents.category.properties'
    },
    financials: {
        type: DocumentCategory.financials,
        label: 'documents.category.financials'
    },
    finalDocuments: {
        type: DocumentCategory.finalDocuments,
        label: 'documents.category.finalDocuments'
    },
    other: {
        type: DocumentCategory.other,
        label: 'documents.category.other'
    }
};

export const sectionTitleHeight = '52px';
