/* eslint-disable @typescript-eslint/no-redeclare */

import { AccountTypes } from 'reducers/account.redux';

export type SessionState = {
    activeApplicationId: number | null;
    bannerDismissCount: number;
    lastLtvProductSelected: string;
};

export enum SessionTypes {
    SET_ACTIVE_APPLICATION_ID = 'SET_ACTIVE_APPLICATION_ID',
    RESET_ACTIVE_APPLICATION = 'RESET_ACTIVE_APPLICATION',
    UPDATE_DISMISS_BANNER = 'UPDATE_DISMISS_BANNER',
    SET_LAST_SELECTED_LTV_PRODUCT = 'SET_LAST_SELECTED_LTV_PRODUCT'
}

const INITIAL_STATE: SessionState = {
    activeApplicationId: null,
    bannerDismissCount: 0,
    lastLtvProductSelected: ''
};

export const reducer = (
    state = INITIAL_STATE,
    action: SessionActions
): SessionState => {
    switch (action.type) {
        case SessionTypes.SET_ACTIVE_APPLICATION_ID:
            return {
                ...state,
                activeApplicationId: action.activeApplicationId
            };
        case SessionTypes.RESET_ACTIVE_APPLICATION:
            return {
                ...state,
                activeApplicationId: null
            };
        case AccountTypes.LOGOUT:
            return INITIAL_STATE;
        case SessionTypes.UPDATE_DISMISS_BANNER:
            return { ...state, bannerDismissCount: action.bannerDismissCount };
        case SessionTypes.SET_LAST_SELECTED_LTV_PRODUCT:
            return {
                ...state,
                lastLtvProductSelected: action.lastLtvProductSelected
            };
        default:
            return state;
    }
};

export const SessionActions = {
    setActiveApplicationId: (
        activeApplicationId: number | null,
        redirectTo?: string
    ) => ({
        type: SessionTypes.SET_ACTIVE_APPLICATION_ID,
        activeApplicationId,
        redirectTo
    }),
    resetActiveApplication: () => ({
        type: SessionTypes.RESET_ACTIVE_APPLICATION
    }),
    updateBannerDismissCount: (bannerDismissCount: number) => ({
        type: SessionTypes.UPDATE_DISMISS_BANNER,
        bannerDismissCount
    }),
    setLastLtvProductSelected: (productId: string) => {
        return {
            type: SessionTypes.SET_LAST_SELECTED_LTV_PRODUCT,
            lastLtvProductSelected: productId
        };
    }
};

export type SetActiveApplicationIdAction = ReturnType<
    typeof SessionActions['setActiveApplicationId']
>;

export type UpdateBannerDismissCountAction = ReturnType<
    typeof SessionActions['updateBannerDismissCount']
>;

export type SetLastLtvProductSelected = ReturnType<
    typeof SessionActions['setLastLtvProductSelected']
>;

export type SessionActions = SetActiveApplicationIdAction &
    UpdateBannerDismissCountAction &
    SetLastLtvProductSelected;
