/**
 * Some helpers used to track the WIZARD flows.
 */

import { WIZARDS, WIZARD_FORM_TYPES } from 'constants/appConstants';

// tslint:disable-next-line variable-name
export const fromAngryCase = (string: string): string =>
    string &&
    string
        .split('_')
        .map(s => s.toLowerCase())
        .join(' ');

// assumes strings are using dot notation
// tslint:disable-next-line variable-name
export const toAngryCase = (string: string): string =>
    string &&
    string
        .split('.')
        .map(s => s.split(/(?=[A-Z])/))
        .reduce((acc, item) => acc.concat(item), [])
        .map(s => s.toUpperCase())
        .join('_');

export const formatAnswerForLabel = (
    answer: { [s: string]: any } | string
): string =>
    typeof answer === 'object' || answer === undefined ? 'NONE' : answer;

export const formatValue = (value: { [s: string]: string } | string) =>
    // https://eslint.org/docs/rules/no-prototype-builtins
    value && Object.prototype.hasOwnProperty.call(value, 'nativeEvent')
        ? 'NONE'
        : value;

export const mappedFormNameToPrexfix = (formName: string): string =>
    ({
        [WIZARDS.NEW_MORTGAGE_QUOTE]: WIZARD_FORM_TYPES.GETAQUOTE,
        [WIZARDS.RENEWAL_MORTGAGE_QUOTE]: WIZARD_FORM_TYPES.GETAQUOTE
    }[formName] || formName);

export const getQuestionName = (formName: string, step: string): string =>
    `${mappedFormNameToPrexfix(formName)}_${toAngryCase(step)}`;
