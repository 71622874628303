import React, { Suspense } from 'react';
import { Route, RouteProps as RoutePropsDOM } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Loader } from 'components/loader/loader.component';
import { RoutesType } from './routes';

const LoaderContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    background: ${props => props.theme.colors.alabaster};
    align-items: center;
    justify-content: center;
`;

export const RouteLoader = () => (
    <LoaderContainer>
        <Loader />
    </LoaderContainer>
);

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface RouteProps extends RoutePropsDOM {
    route?: RoutesType | RoutesType[];
    component?: any;
    render?: any;
}

export const BaseRoute = (props: RouteProps) => (
    <Suspense fallback={<RouteLoader />}>
        <Route {...props} />
    </Suspense>
);
