import React, { forwardRef } from 'react';
import { WrappedFieldProps } from 'redux-form';
import styled, { css } from 'styled-components/macro';
import { Theme } from '@nesto/themes';

import { CheckMark as CheckMarkSvg } from 'assets/media/icons/Checkmark';
import { normalizeInputNameForE2E } from 'utils/e2e-utilities';
import { InputError } from 'components/inputs/input-error/input-error.component';
import { readOnlyInput } from '../shared/styles';
import { useOptionalLabelGenerator } from 'utils/hooks/useOptionalLabelGenerator';

type Props = {
    name?: string;
    type: string;
    value?: string | number;
    preset?: string;
    flip?: boolean;
    placeholder?: string;
    defaultValue?: any;
    disabled?: boolean;
    iconAction?: () => void;
    hideInputErrors?: boolean;
    icon?: any;
    useClassStyle?: boolean;
    readOnly?: boolean;
    dataTestId?: string;
    errorValues?: any;
    showErrorOnTouched?: boolean;
    autoFocus?: boolean;
    isOptional?: boolean;
    frOptionalLabelGender?: 'masculine' | 'feminine';
};

type MapStylesProps = {
    preset?: string;
    flip?: boolean;
    updated?: boolean;
    readOnly?: boolean;
    classStyle?: 'disabled' | 'error' | 'valid' | 'default';
    theme: Theme;
};

const mapStylesToPresetsInput = ({
    preset,
    flip,
    theme,
    classStyle = 'default'
}: MapStylesProps) => {
    const shared = css`
        width: 100%;
        border-color: ${theme.input[classStyle].borderColor};
        color: ${theme.input[classStyle].color};
        background-color: ${theme.input[classStyle].background};
        padding: ${flip ? '10px 10px 10px 37px' : '10px 40px 10px 10px'};
    `;

    switch (preset) {
        case 'SMALL':
            return css`
                font-size: ${theme.fontSizes[1]};
                height: 48px;
                ${shared}
                padding: ${flip ? '2px 10px 2px 37px' : '2px 40px 2px 10px'};
            `;
        default:
            return css`
                font-size: ${theme.fontSizes[2]};
                height: 60px;
                ${shared}
            `;
    }
};

const mapStylesToPresetsLabel = ({
    preset,
    flip,
    updated,
    readOnly,
    theme
}: MapStylesProps) => {
    const transitionLeft = readOnly ? '0px' : flip ? '38px' : '11px';
    switch (preset) {
        case 'SMALL':
            return css`
                color: ${updated ? theme.colors.boulder : theme.colors.tundora};
                font-size: ${theme.fontSizes[1]};
                line-height: ${theme.fontSizes[1]};
                transform: ${updated
                    ? `translate(${transitionLeft}, 4px) scale(0.75)`
                    : `translate(${transitionLeft}, 14px) scale(1)`};
            `;
        default:
            return css`
                color: ${updated ? theme.colors.boulder : theme.colors.tundora};
                font-size: ${theme.fontSizes[2]};
                line-height: ${theme.fontSizes[2]};
                transform: ${updated
                    ? `translate(${transitionLeft}, 10px) scale(0.75)`
                    : `translate(${transitionLeft}, 24px) scale(1)`};
            `;
    }
};

const InputWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    padding: 0px;
    flex-direction: column;
    vertical-align: top;
    position: relative;
    input:-webkit-autofill + div {
        transform: translate(11px, 10px) scale(0.75);
    }
`;

const readOnlyStyle = ({ readOnly, theme }: any) =>
    readOnly &&
    css`
        padding: 0 0 0 10px;
        color: ${theme.input.default.color};
        background-color: #f8f8f8;
    `;

const Input = styled.input<MapStylesProps>`
    word-break: normal;
    font-family: ${props => props.theme.fontFamily};
    box-sizing: border-box;
    outline: none;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

    ${props => props.theme.input.border};
    color: ${props => props.theme.input.default.color};
    border-color: ${props => props.theme.input.default.borderColor};
    background-color: ${props => props.theme.input.default.background};
    ${({ theme, readOnly, classStyle }) => css`
        :hover {
            border-color: ${readOnly || classStyle === 'disabled'
                ? theme.input.disabled.borderColor
                : theme.input.hover.borderColor};
        }
        :focus,
        :active {
            color: ${readOnly ? undefined : theme.input.active.color};
            border-color: ${readOnly
                ? undefined
                : theme.input.active.borderColor};
            background-color: ${readOnly
                ? undefined
                : theme.input.active.background};
        }
    `};
    ${mapStylesToPresetsInput};
    ${readOnlyStyle};
    ${readOnlyInput};
`;

const Placeholder = styled.div<MapStylesProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    top: ${({ preset }) => (preset === 'SMALL' ? '2px' : '0')};
    left: 0;
    position: absolute;
    pointer-events: none;
    padding: 0;
    font-family: ${props => props.theme.fontFamily};
    transform-origin: top left;
    color: ${({ theme }) => theme.input.disabled.color};

    ${mapStylesToPresetsLabel};
    ${readOnlyStyle};
    background-color: ${props => props.readOnly && 'transparent'};

    ${({ theme, classStyle }) =>
        classStyle === 'disabled' &&
        css`
            color: ${theme.input.disabled.color};
        `}
`;

const CheckMarkContainer = styled.div<MapStylesProps>`
    top: ${({ preset }) => (preset === 'SMALL' ? '21px' : '23px')};
    right: 15px;
    position: absolute;
`;

const IconIconContainer = styled.div<MapStylesProps>`
    top: ${({ preset }) => (preset === 'SMALL' ? '20px' : '22px')};
    ${({ flip }) => (flip ? 'left: 17px;' : 'right: 17px;')};
    position: absolute;
    &:hover {
        cursor: pointer;
    }
`;

export const returnClass = (
    meta,
    icon,
    useClassStyle = true,
    disabled,
    readOnly
) => {
    if (!useClassStyle || readOnly) {
        return 'default';
    }

    if (icon) {
        return 'default'; // If it has an icon we return default style class
    }

    if (disabled) {
        return 'disabled';
    }

    if (meta && meta.touched && meta.error) {
        return 'error';
    }

    if (meta && meta.touched && meta.valid) {
        return 'valid';
    }

    return 'default';
};

export const TextInput = forwardRef(
    (
        {
            meta,
            input,
            hideInputErrors = false,
            placeholder,
            type,
            preset,
            flip,
            icon,
            disabled = false,
            useClassStyle = true,
            iconAction,
            readOnly = false,
            dataTestId,
            value,
            errorValues,
            showErrorOnTouched = false,
            autoFocus,
            isOptional = false,
            frOptionalLabelGender = 'feminine',
            ...rest
        }: Props & WrappedFieldProps,
        ref: any
    ) => {
        const classStyle = returnClass(
            meta,
            icon,
            useClassStyle,
            disabled,
            readOnly
        );
        const placeholderValue = useOptionalLabelGenerator({
            labelText: placeholder,
            isOptional,
            frOptionalLabelGender
        });
        return (
            <>
                <InputWrapper>
                    <Input
                        ref={ref}
                        id={normalizeInputNameForE2E(
                            input.name || 'text-field'
                        )}
                        autoFocus={autoFocus}
                        data-test-id={
                            dataTestId
                                ? dataTestId
                                : normalizeInputNameForE2E(
                                      (input && input.name) || 'text-field'
                                  )
                        }
                        type={type}
                        classStyle={classStyle}
                        preset={preset}
                        flip={flip}
                        defaultValue={value}
                        {...rest}
                        {...input}
                        disabled={disabled}
                        readOnly={readOnly}
                    />
                    {placeholderValue && (
                        <Placeholder
                            updated={
                                // JS cast 0 as false but we want that to be a valid input
                                // otherwise the placeholder is over the value in the field
                                value === 0 ||
                                (value && value !== '') ||
                                input.value === 0 ||
                                (input.value && input.value !== '')
                            }
                            preset={preset}
                            flip={flip}
                            readOnly={readOnly}
                            data-test-id={`input_label-${normalizeInputNameForE2E(
                                input.name || 'text-field'
                            )}`}
                            classStyle={classStyle}
                        >
                            {placeholderValue}
                        </Placeholder>
                    )}
                    {classStyle === 'valid' && !icon && !readOnly && (
                        <CheckMarkContainer
                            preset={preset}
                            data-test-id={`input_checkmark-${normalizeInputNameForE2E(
                                input.name || 'text-field'
                            )}`}
                        >
                            <CheckMarkSvg />
                        </CheckMarkContainer>
                    )}
                    {icon && !readOnly && (
                        <IconIconContainer
                            data-test-id={`input_icon-${normalizeInputNameForE2E(
                                input.name || 'text-field'
                            )}`}
                            onClick={iconAction}
                            flip={flip}
                            preset={preset}
                        >
                            {icon}
                        </IconIconContainer>
                    )}
                </InputWrapper>
                {!hideInputErrors && (
                    <InputError
                        meta={meta}
                        errorValues={errorValues}
                        name={input.name}
                        showErrorOnTouched={showErrorOnTouched}
                    />
                )}
            </>
        );
    }
);
