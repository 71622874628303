import { useLayoutEffect } from 'react';

// Hook to lock body scroll
function useLockBodyScroll() {
    useLayoutEffect(() => {
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []); // Empty array ensures effect is only run on mount and unmount
}

export const Modal = ({ children }) => {
    // Call hook to lock body scroll
    useLockBodyScroll();

    return children;
};
