import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { IconComponent as Icon } from 'components/icon';
import ClosePng from 'assets/media/icons/close_001@2x.png';
import HamburgerPng from 'assets/media/icons/hamburger_closed@2x.png';
import { ModalManager } from 'components/modal-manager';
import { useTheme } from 'utils/hooks';
import Modal from 'components/modal';
import { Flex } from 'components/reflexbox';
import { SubmitButton } from 'components/buttons/button';
import { Typograph } from '@nesto/design-system';
import { LanguageSwitcher } from './language-switcher.component';

const MenuBackground = styled(Flex)`
    position: fixed;
    padding-top: 80px;
    background-color: ${props => props.theme.colors.white};
    z-index: 99;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const HamburgerButton = styled.button`
    cursor: pointer;
    border: none;
    display: block;
    padding: 0 !important;
    background-color: transparent;
`;

const MenuDivider = styled.div`
    background-color: ${props => props.theme.colors.alto};
    height: 1px;
`;

export const HeaderLoggedInMobile = ({
    hideLogout,
    logout,
    showAccountSettings
}) => {
    const [showMenu, setShowMenu] = useState(false);
    const theme = useTheme();
    return (
        <>
            <HamburgerButton onClick={() => setShowMenu(!showMenu)}>
                {showMenu ? (
                    <Icon
                        data-test-id="close_btn"
                        maxWidth="30px"
                        src={ClosePng}
                    />
                ) : (
                    <Icon
                        data-test-id="hamburger_btn"
                        maxWidth="25px"
                        src={HamburgerPng}
                    />
                )}
            </HamburgerButton>
            <ModalManager isShown={showMenu} level={`${theme.zIndex.house}`}>
                <Modal>
                    <MenuBackground width={theme.contentWidths} px={3}>
                        <Flex width="100%" flexDirection="column">
                            {showAccountSettings && (
                                <Link to="/settings">
                                    <Typograph
                                        tx="sidebar.settings"
                                        color="textBlack"
                                        fontSize={1}
                                        fontWeight="normal"
                                        p="16px 24px"
                                    />
                                </Link>
                            )}

                            <MenuDivider />

                            <Flex
                                flexDirection="row"
                                alignItems="center"
                                p="16px 24px"
                            >
                                <LanguageSwitcher fontSize={1} />
                            </Flex>

                            <MenuDivider />

                            {!hideLogout && (
                                <Flex p="0px 24px">
                                    <SubmitButton
                                        data-test-id="logout"
                                        width="auto"
                                        minWidth="auto"
                                        preset="TINY"
                                        dataTestId="logout"
                                        label="nav.logout"
                                        onClick={logout}
                                    />
                                </Flex>
                            )}
                        </Flex>
                    </MenuBackground>
                </Modal>
            </ModalManager>
        </>
    );
};
