enum propertyTypes {
    HOUSE = 'HOUSE',
    HOME_OR_CONDO = 'HOME_OR_CONDO',
    CONDO = 'CONDO',
    SECOND_HOME = 'SECOND_HOME',
    SECOND_HOME_OR_COTTAGE = 'SECOND_HOME_OR_COTTAGE',
    SECONDARY_HOME_OR_COTTAGE = 'SECONDARY_HOME_OR_COTTAGE',
    // This duplicate is intentional, and exists because in the back-end
    // 'SECOND_HOME_OR_COTTAGE' is different than 'SECONDARY_HOME_OR_COTTAGE'
    // in some parts of the application
    DUPLEX = 'DUPLEX',
    TRIPLEX = 'TRIPLEX',
    FOURPLEX = 'FOURPLEX',
    RENTAL = 'RENTAL',
    FIVE_UNITS_PLUS = 'FIVE_UNITS_PLUS',
    OTHER = 'OTHER',
    COTTAGE = 'COTTAGE'
}

export { propertyTypes };
