import { GaqApplicationType } from './../components/get-a-quote/pretto/pretto.constants';
import { createReducer, createActions } from 'reduxsauce';
import { AccountTypes } from 'reducers/account.redux';
import { TypographI18nKeys } from 'components/typograph';
import { isUndefined } from 'components/form/utils';
import {
    PrettoFormKey,
    PrettoMicroCopy
} from 'components/get-a-quote/pretto/pretto.types';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    updateSidebar: ['showSidebar'],
    toggleFilters: ['showFilters'],
    showLeadReferral: ['eligibility', 'interestLevel'],
    updateLeadReferral: ['leadReferralState', 'resolve', 'reject'],
    updateDrawer: ['drawerState', 'drawerType'],
    updateBookACall: ['bookACallState', 'drawerType'],
    updateUniPostalCode: ['uniPostalCodeState'],
    updatePartner: ['partner'],
    updateInitialValues: ['initialValues'],
    setSubPartnerId: ['subPartnerId'],
    updateShowCTAModal: ['isShown'],
    updatePrettoSteps: ['prettoSteps'],
    updateGaqInitialValues: ['gaqInitialValues'],
    updateCanSubmit: ['canSubmit'],
    updateApplicationType: ['prettoApplicationType'],
    updateApplicationValues: ['values'],
    updatePrettoMicroCopy: ['tx'],
    updatePrettoMicroCopyValue: ['values'],
    updateNavbarVisibility: ['isShown'],

    updateCTAModalType: ['modalType'],
    setCallPreferenceRequestState: ['requestState']
});

export { Creators as Actions };

export const UITypes = Types;

export const INITIAL_STATE = {
    showFilters: false,
    showSidebar: false,
    leadReferral: { eligibility: false, interestLevel: undefined },
    showDrawer: false,
    showBookACallMessage: false,
    showUniPostalCode: true,
    partner: 'nesto',
    subPartnerId: 0,
    drawerType: 'DEFAULT',
    initialValues: {},
    leadReferralState: undefined,
    showCTAModal: false,
    CTAModalType: 'default' as CTAModalType,
    prettoSteps: ['applicationType'] as PrettoFormKey[],
    prettoMicroCopy: { tx: '', values: {} },
    prettoApplicationType: undefined as GaqApplicationType | undefined,
    canSubmit: false,
    callPreferenceRequestState: null,
    isNavbarVisible: false
};

export type ApplicationProgressBarProps = {
    numOfSteps: number;
    currentStepIndex: number;
    section: TypographI18nKeys;
    userName?: string;
};

const updateSidebar = (state: any, { showSidebar }) => ({
    ...state,
    showSidebar:
        typeof showSidebar === 'boolean' && !isUndefined(showSidebar)
            ? showSidebar
            : !state.showSidebar
});

const updatePrettoSteps = (
    state: any,
    { prettoSteps }: { prettoSteps: string[] }
) => ({
    ...state,
    prettoSteps
});

const updateGaqInitialValues = (
    state: any,
    { gaqInitialValues }: { gaqInitialValues: {} }
) => ({
    ...state,
    gaqInitialValues: { ...state.gaqInitialValues, ...gaqInitialValues }
});

const updateApplicationType = (
    state: any,
    { prettoApplicationType }: { prettoApplicationType: GaqApplicationType }
) => ({
    ...state,
    prettoApplicationType
});

const updatePrettoMicroCopy = (state: any, tx: PrettoMicroCopy) => ({
    ...state,
    prettoMicroCopy: { ...state.prettoMicroCopy, ...tx }
});

const updatePrettoMicroCopyValue = (state: any, { values }: any) => ({
    ...state,
    prettoMicroCopy: { ...state.prettoMicroCopy, values }
});

const updateApplicationValues = (state: any, { values }: { values: any }) => ({
    ...state,
    form: {
        [state.prettoApplicationType]: {
            values: {
                ...values
            }
        }
    }
});

const updateCanSubmit = (
    state: any,
    { canSubmit }: { canSubmit: boolean }
) => ({
    ...state,
    canSubmit
});

const toggleFilters = (state: any, { showFilters }) => ({
    ...state,
    showFilters: !isUndefined(showFilters) ? !!showFilters : !state.showFilters
});

export type DrawerType = 'DEFAULT' | 'SECONDARY';

const updateDrawer = (
    state: any,
    {
        drawerState,
        drawerType
    }: { drawerState: boolean; drawerType?: DrawerType }
) => ({
    ...state,
    showDrawer: drawerState,
    drawerType: drawerType
});

const updateBookACall = (
    state: any,
    {
        bookACallState,
        drawerType
    }: { bookACallState: boolean; drawerType?: DrawerType }
) => ({
    ...state,
    showBookACallMessage: bookACallState,
    drawerType: drawerType
});

const updateUniPostalCode = (state: any, { uniPostalCodeState }) => ({
    ...state,
    showUniPostalCode: uniPostalCodeState
});

export type LeadReferralTypes = {
    eligibility: boolean;
    interestLevel:
        | 'MADE_OFFER'
        | 'MAKING_OFFER_SOON'
        | 'GET_PREAPPROVED'
        | 'JUST_CURIOUS';
};

const showLeadReferral = (
    state: any,
    { eligibility, interestLevel }: LeadReferralTypes
) => ({
    ...state,
    leadReferral: { eligibility, interestLevel }
});

const updatePartner = (state: any, { partner }) => ({
    ...state,
    partner
});

const updateNavbar = (state: any, { isShown }: { isShown: boolean }) => ({
    ...state,
    isNavbarVisible: isShown
});

const updateLeadReferral = (state: any, payload) => ({
    ...state,
    ...payload
});

const setSubPartnerId = (
    state: any,
    { subPartnerId }: { subPartnerId: number | undefined }
) => ({
    ...state,
    subPartnerId
});

const onLogout = (state: any) => ({
    ...INITIAL_STATE,
    partner: state.partner
});

export const updateInitialValues = (state: any, { initialValues }) => ({
    ...state,
    initialValues
});

const updateShowCTAModal = (state: any, { isShown }: { isShown: boolean }) => ({
    ...state,
    showCTAModal: isShown
});

export enum CTAModalType {
    CONFIRM = 'confirm',
    SPEAK_TO_AGENT = 'speakToAgent',
    QC_EN = 'qcEn',
    DEFAULT = 'default'
}

const updateCTAModalType = (
    state: any,
    { modalType }: { modalType: CTAModalType }
) => ({
    ...state,
    CTAModalType: modalType
});

export type CallPreferenceRequestState = 'loading' | 'error' | 'success';

const setCallPreferenceRequestState = (
    state: any,
    { requestState }: { requestState: CallPreferenceRequestState }
) => ({
    ...state,
    callPreferenceRequestState: requestState
});

export const reducer = createReducer(INITIAL_STATE, {
    [Types.UPDATE_INITIAL_VALUES]: updateInitialValues,
    [Types.UPDATE_SIDEBAR]: updateSidebar,
    [Types.TOGGLE_FILTERS]: toggleFilters,
    [Types.SHOW_LEAD_REFERRAL]: showLeadReferral,
    [Types.UPDATE_LEAD_REFERRAL]: updateLeadReferral,
    [Types.UPDATE_DRAWER]: updateDrawer,
    [Types.UPDATE_BOOK_A_CALL]: updateBookACall,
    [Types.UPDATE_UNI_POSTAL_CODE]: updateUniPostalCode,
    [Types.UPDATE_PARTNER]: updatePartner,
    [Types.UPDATE_NAVBAR_VISIBILITY]: updateNavbar,
    [Types.SET_SUB_PARTNER_ID]: setSubPartnerId,
    [Types.UPDATE_SHOW_CTA_MODAL]: updateShowCTAModal,
    [Types.UPDATE_CTA_MODAL_TYPE]: updateCTAModalType,
    [Types.UPDATE_PRETTO_STEPS]: updatePrettoSteps,
    [Types.UPDATE_GAQ_INITIAL_VALUES]: updateGaqInitialValues,
    [Types.UPDATE_CAN_SUBMIT]: updateCanSubmit,
    [Types.UPDATE_APPLICATION_TYPE]: updateApplicationType,
    [Types.UPDATE_APPLICATION_VALUES]: updateApplicationValues,
    [Types.UPDATE_PRETTO_MICRO_COPY]: updatePrettoMicroCopy,
    [Types.UPDATE_PRETTO_MICRO_COPY_VALUE]: updatePrettoMicroCopyValue,

    [Types.SET_CALL_PREFERENCE_REQUEST_STATE]: setCallPreferenceRequestState,
    [AccountTypes.LOGOUT]: onLogout
});
