import React from 'react';
import styled from 'styled-components/macro';
import {
    FontSizeProps,
    maxHeight,
    MaxHeightProps,
    maxWidth,
    MaxWidthProps,
    space,
    SpaceProps,
    WidthProps
} from 'styled-system';

import { Flex } from 'reflexbox/styled-components';

import Modal from 'components/modal';
import { ModalManager } from 'components/modal-manager';
import { Card } from 'components/card';
import { Typograph, TypographI18nKeys } from 'components/typograph';
import { CardProps } from 'components/card/card.component';
import { GroupButton } from 'components/buttons/button';

type Props = {
    open: boolean;
    children?: React.ReactNode;
    onCloseComplete?: () => void;
    onCancel?: () => void;
    onConfirm?: () => void;
    cancelLabel?: TypographI18nKeys;
    confirmLabel?: TypographI18nKeys;
    tx?: TypographI18nKeys;
    txValues?: any;
};

const CardContainer = styled(Card)`
    box-shadow: 4px 8px 15px rgba(100, 100, 100, 0.5);
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    ${space}
    ${maxWidth}
    ${maxHeight}
` as (
    props: CardProps &
        MaxHeightProps &
        MaxWidthProps &
        WidthProps &
        SpaceProps &
        FontSizeProps
) => JSX.Element;

export const Confirm = ({
    open,
    tx = 'confirm.defaultContent',
    txValues,
    children,
    cancelLabel,
    confirmLabel,
    onCancel = () => {},
    onConfirm = () => {},
    onCloseComplete = () => {}
}: Props) => {
    return (
        <ModalManager onCloseComplete={() => onCloseComplete()} isShown={open}>
            {({ close }: { close: () => void }) => (
                <Modal>
                    <Flex
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                        pb={[4]}
                        style={{ height: '100%' }}
                    >
                        <CardContainer
                            id="confirmContainer"
                            width={['90%', '620px']}
                            p={['30px']}
                            pb={['60px']}
                        >
                            {children || (
                                <Typograph
                                    fontSize={[3]}
                                    fontWeight="bold"
                                    color="textBlack"
                                    tx={tx}
                                    values={txValues}
                                    textAlign="center"
                                    my={2}
                                />
                            )}
                            <GroupButton
                                id="confirm-confirm-button"
                                type="button"
                                label={confirmLabel || 'confirm'}
                                mr="auto"
                                ml="auto"
                                onClick={() => {
                                    onConfirm();
                                    close();
                                }}
                            />
                            <GroupButton
                                id="confirm-cancel-button"
                                type="button"
                                label={cancelLabel || 'cancel'}
                                mr="auto"
                                ml="auto"
                                onClick={() => {
                                    onCancel();
                                    close();
                                }}
                            />
                        </CardContainer>
                    </Flex>
                </Modal>
            )}
        </ModalManager>
    );
};
