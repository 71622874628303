import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Box, Flex } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';

import { log } from 'utils/logging';
import { PageContainer } from 'components/page-container';
import { Typograph, TypographI18nKeys } from 'components/typograph';
import { withSizesHoc as WithSizes } from 'hocs/with-sizes.hoc';
import { BoxContained } from 'components/box-contained';
import { Logo } from 'components/sidebar/components/logo.component';

type Links = Array<{
    url: string;
    newPage: boolean;
    name: TypographI18nKeys;
}>;

const LINKS: Links = [
    {
        name: 'getaQuote',
        url: '/getaquote',
        newPage: false
    },
    {
        name: 'application.mortgageApplication',
        url: '/',
        newPage: false
    }
];

const Wrapper = styled(BoxContained)`
    height: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 ${props => props.theme.space[3]}px;
    background-color: ${props => props.theme.colors.white};
    z-index: 99;
`;

const HeaderWraper = styled.div`
    background-color: ${props => props.theme.colors.white};
    width: 100%;
`;

const Row = styled(Flex)`
    border-bottom: 1px solid ${props => props.theme.colors.mercury};
`;

class ErrorHandlerView extends Component {
    state = { error: null };

    componentDidCatch(error: Error) {
        if (error.toString().indexOf('ChunkLoadError') > -1) {
            log({
                error: '[ChunkLoadError] Reloading due to error',
                tag: 'componentDidCatch'
            });
            window.location.reload(true);
        } else {
            log({ error: error.toString(), tag: 'componentDidCatch' });
            this.setState({ error });
        }
    }

    render() {
        if (this.state.error) {
            // Render fallback UI
            return (
                <HeaderWraper>
                    <Wrapper>
                        <Logo />
                    </Wrapper>
                    <PageContainer flexDirection="column" alignItems="center">
                        <Flex
                            flexWrap="wrap"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            mt={[5]}
                        >
                            <Box width={[1, 2 / 3]}>
                                <Typograph
                                    fontSize={[6]}
                                    fontWeight="bold"
                                    mb={[4]}
                                    tx="pagenotfound.oops"
                                />
                                <Typograph
                                    fontSize={[5]}
                                    tx="pagenotfound.text"
                                />
                            </Box>

                            <Flex flexDirection="column" width={[1, 1 / 3]}>
                                {LINKS.map(link => (
                                    <a
                                        href={link.url}
                                        target={
                                            link.newPage ? '_blank' : undefined
                                        }
                                        rel="noreferrer"
                                        data-test-id={link.name}
                                        key={link.name}
                                    >
                                        <Row
                                            justifyContent="space-between"
                                            flexDirection="row"
                                            alignItems="left"
                                            pt={10}
                                            pb={10}
                                        >
                                            <Flex
                                                flexDirection="column"
                                                alignItems="right"
                                                pl={[2, 0]}
                                            >
                                                <Flex flexDirection="row">
                                                    <Typograph
                                                        fontSize={[3]}
                                                        color="textBlack"
                                                        tx={link.name}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </Row>
                                    </a>
                                ))}
                            </Flex>
                        </Flex>
                    </PageContainer>
                </HeaderWraper>
            );
        }
        // When there's not an error, render the children untouched
        return this.props.children;
    }
}

export const ErrorHandler = compose(
    connect((state: any) => ({
        lang: state.locale.lang,
        path: state.router.location.pathname
    })),
    WithSizes
)(ErrorHandlerView) as React.FC;
