import React from 'react';
import styled from 'styled-components/macro';
import {
    space,
    width,
    fontSize,
    fontWeight,
    SpaceProps,
    FontSizeProps,
    WidthProps
} from 'styled-system';
import { Flex } from 'reflexbox/styled-components';
import { WrappedFieldProps } from 'redux-form';
import { RadioSwitchOption } from './radio-switch-option.component';
import { normalizeInputNameForE2E } from 'utils/e2e-utilities';
import { TypographI18nKeys } from 'components/typograph';

type State = {
    selectedValue: string;
};
type GroupProps = {
    defaultValue?: string;
    selectedValue?: string;
    options: Array<{
        label: TypographI18nKeys;
        value: string;
        icon?: React.ReactElement<any>;
    }>;
    preset?: 'DEFAULT' | 'SECONDARY';
};

type StyledProps = { preset?: 'DEFAULT' | 'SECONDARY' };
type StyledRadioSwitchGroupProps = SpaceProps & FontSizeProps & WidthProps;
const StyledRadioSwitchGroup = styled(Flex)<
    StyledProps & StyledRadioSwitchGroupProps
>`
    transition: ${({ theme }) => theme.transition.all};
    ${space}
    ${width}
    ${fontSize}
    ${fontWeight}
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: ${({ preset, theme }) =>
        preset === 'SECONDARY'
            ? `0px 2px 12px ${theme.colors.mercury}`
            : `inset 0px 0px 0px 1px ${theme.colors.mercury}`};
    color: ${({ theme }) => theme.colors.textBlack};
    background-color: ${({ theme }) => theme.colors.white};
`;

class RadioSwitchGroup extends React.Component<
    GroupProps & StyledRadioSwitchGroupProps & WrappedFieldProps,
    State
> {
    static defaultProps = {
        fontSize: 2,
        options: [],
        width: '100%',
        input: {
            onChange: (value: any) => {},
            value: null
        },
        meta: {}
    };

    state = {
        selectedValue: this.props.defaultValue || this.props.input.value
    };

    handleOnChange = (value: any) => {
        this.setState({ selectedValue: value });
        this.props.input.onChange && this.props.input.onChange(value);
    };

    render() {
        return (
            <>
                <StyledRadioSwitchGroup
                    data-test-id={normalizeInputNameForE2E(
                        this.props.input.name || ''
                    )}
                    {...this.props}
                    preset={this.props?.preset}
                >
                    {(this.props.options || []).map((option, index) => {
                        const isDefault =
                            !this.state.selectedValue &&
                            this.props.defaultValue &&
                            option.value === this.props.defaultValue;
                        const isSelected =
                            isDefault ||
                            option.value === this.state.selectedValue;
                        return (
                            <RadioSwitchOption
                                data-test-id={normalizeInputNameForE2E(
                                    `${this.props.input.name}_${option.value}${
                                        isSelected ? '_isSelected' : ''
                                    }`
                                )}
                                key={index}
                                value={option.value}
                                label={option.label}
                                selected={isSelected}
                                icon={option.icon}
                                onClick={this.handleOnChange}
                                preset={this.props?.preset}
                            />
                        );
                    })}
                </StyledRadioSwitchGroup>
                {this.props.meta &&
                    this.props.meta.touched &&
                    this.props.meta.error && (
                        <span>{this.props.meta.error}</span>
                    )}
            </>
        );
    }
}

const RadioSwitch = RadioSwitchGroup;
export { RadioSwitch };
