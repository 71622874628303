import { useLayoutEffect, useRef, useState, useMemo, useEffect } from 'react';
import Headroom from 'react-headroom';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Flex } from 'reflexbox/styled-components';
import { withTheme, ThemeProps } from 'styled-components';
import { transparentize } from 'polished';

import { Theme } from '@nesto/themes';
import { Logo } from 'components/sidebar/components/logo.component';
import { Loader } from 'components/loader';
import { Actions as SidebarActions } from 'reducers/ui.redux';
import { getSidebarState } from 'reducers/ui.selectors';
import { withSizesHoc, WithSizesProps } from 'hocs/with-sizes.hoc';
import { MobileNavBar as DocumentsNavBar } from 'components/documents-new/documents-navbar.component';
import { useIsMobile } from 'utils/hooks';

import {
    BackgroundWrapper,
    ContentWrapper,
    LeftCol,
    RightCol,
    Sidebar,
    LogoContainer
} from './content-with-sidebar.styles';
import { NavigationContainer } from './navigation-container.component';
import { PageOverlay } from './page-overlay.component';
import { useFeatureFlag } from 'components/feature-flagger/hooks';

type Props = {
    sidebarHeight?: any;
    useNavLine?: boolean;
    sidebar: React.ReactNode;
    header: React.ReactNode;
    content: React.ReactNode;
    isLoading?: boolean;
};

type StateProps = {
    isOpen: boolean;
};

type DispatchProps = {
    toggleSidebar: () => void;
};

const LayoutView = ({
    sidebarHeight,
    useNavLine = false,
    sidebar,
    header,
    content,
    isLoading,
    toggleSidebar,
    isOpen,
    isDesktopAndGreater,
    theme
}: Props & StateProps & DispatchProps & WithSizesProps & ThemeProps<Theme>) => {
    const showDocumentsNewDesign = useFeatureFlag<boolean>(
        'show-documents-new-design'
    );
    const targetRef = useRef<any>();
    const isMobile = useIsMobile();
    const history = useHistory();
    const isDocuments = history.location.pathname.includes('documents');
    const [headerHeight, setHeaderHeight] = useState(0);
    const [isMobileOpen, setIsOpen] = useState<boolean>(false);

    const heightWithOffset = useMemo(() => {
        const mobileOffset =
            isMobile && showDocumentsNewDesign && isMobileOpen
                ? theme.space[3]
                : 0;
        return headerHeight + mobileOffset;
    }, [
        headerHeight,
        isMobile,
        showDocumentsNewDesign,
        theme.space,
        isMobileOpen
    ]);

    useLayoutEffect(() => {
        if (targetRef.current) {
            setHeaderHeight(targetRef.current.clientHeight);
        }
    }, [header]);

    useEffect(() => {
        if (!isMobile && isMobileOpen) {
            setIsOpen(false);
        }
    }, [isMobile, isMobileOpen]);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;
        if (isMobile && isDocuments) {
            interval = setInterval(() => {
                if (targetRef.current) {
                    setHeaderHeight(targetRef.current.clientHeight);
                }
            }, 100);
        }

        return () => clearInterval(interval);
    }, [isDocuments, isMobile]);

    return (
        <BackgroundWrapper>
            {!isDesktopAndGreater && (
                <Headroom
                    wrapperStyle={{
                        zIndex: theme.zIndex.sky,
                        display: 'flex'
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',

                        backgroundColor: transparentize(
                            0.05,
                            theme.colors.white
                        ),
                        width: '100%',
                        height: heightWithOffset
                    }}
                >
                    <Box ref={targetRef} width="inherit">
                        <Box
                            style={{
                                paddingLeft: theme.space[3],
                                paddingRight: theme.space[3]
                            }}
                        >
                            {header}
                        </Box>
                        <DocumentsNavBar
                            isOpen={isMobileOpen}
                            setIsOpen={setIsOpen}
                            isDocuments={isDocuments}
                        />
                    </Box>
                </Headroom>
            )}
            <Flex flex={1}>
                <LeftCol
                    id="layout-left-column"
                    data-test-id="layout-left-column"
                    as="nav"
                >
                    <NavigationContainer isOpen={isOpen}>
                        <Box
                            style={{
                                position: 'relative',
                                top: `${heightWithOffset * -1}px`,
                                height: `calc(100% + ${heightWithOffset}px)`
                            }}
                        >
                            <Sidebar height={sidebarHeight}>
                                <LogoContainer useNavLine={useNavLine}>
                                    <Logo />
                                </LogoContainer>
                                {sidebar}
                            </Sidebar>
                        </Box>
                    </NavigationContainer>
                </LeftCol>
                <RightCol
                    id="layout-right-column"
                    data-test-id="layout-right-column"
                    as="main"
                >
                    <ContentWrapper>
                        {isLoading ? (
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                height={100}
                            >
                                <Loader />
                            </Flex>
                        ) : (
                            <Flex
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                mb={[4, 4, 4, 6]}
                            >
                                {content}
                            </Flex>
                        )}
                    </ContentWrapper>
                </RightCol>
            </Flex>
            {!isDesktopAndGreater && (
                <PageOverlay
                    isOpen={isOpen}
                    onClick={() => isOpen && toggleSidebar()}
                    data-test-id="navigation-page-overlay-container"
                />
            )}
        </BackgroundWrapper>
    );
};

export const Layout = compose(
    connect<StateProps, DispatchProps>(
        (state: any) => ({
            isOpen: getSidebarState(state)
        }),
        {
            toggleSidebar: () => SidebarActions.updateSidebar()
        }
    ),
    withTheme,
    withSizesHoc
)(LayoutView) as React.FC<Props>;
