import { connect } from 'react-redux';
import React from 'react';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { useLDIdentify } from './hooks';
import { memorizedGetFeatureFlaggerUserData } from 'reducers/account.selectors';

type StateProps = {
    userState: LDUser;
};

type IdentifyProps = {
    children?: any;
};

const Identify = ({ userState, children }: IdentifyProps & StateProps) => {
    useLDIdentify(userState);
    return <>{children}</>;
};

export const FeatureFlagIdentify = connect<StateProps>((state: any) => ({
    userState: memorizedGetFeatureFlaggerUserData(state)
}))(Identify);
