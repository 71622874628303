import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Transition, {
    TransitionStatus
} from 'react-transition-group/Transition';

import { Typograph, TypographI18nKeys } from 'components/typograph';

type ToolTipPosition = 'top' | 'bottom';

type Props = {
    tx: TypographI18nKeys;
    position?: ToolTipPosition;
    animationDuration?: number;
    toolTipTimeout?: number;
    componentRef: any;
};

const tooltipBottomPosition = css`
    top: 120%;

    &:after {
        bottom: 100%;
        border-color: transparent transparent #e3e3e3 transparent;
    }
`;

const tooltipTopPosition = css`
    bottom: 120%;

    &:after {
        top: 100%;
        border-color: #e3e3e3 transparent transparent transparent;
    }
`;

const ANIMATION_DURATION = 200;

const getToolTipPosition = (position: ToolTipPosition) => {
    switch (position) {
        case 'bottom':
            return tooltipBottomPosition;

        default:
            return tooltipTopPosition;
    }
};

const StyledTooltip = styled.div<{
    position: ToolTipPosition;
    animationDuration: number;
}>`
    ${({ position }) => getToolTipPosition(position)};
    background-color: #e3e3e3;
    color: #212243;
    width: 270px;
    text-align: center;
    border-radius: 10px;
    padding: 10px 15px;
    position: absolute;
    left: 50%;
    margin-left: -135px;
    transition: opacity ${({ animationDuration }) => animationDuration}ms ease
        0.1s;
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        transition: opacity ${({ animationDuration }) => animationDuration}ms
            ease 0.1s;
    }
`;

const transitionStyles: { [state: string]: React.CSSProperties } = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
};

const TOOLTIP_TIMEOUT = 4000;

export const CustomTooltip = ({
    tx,
    componentRef,
    position = 'top',
    toolTipTimeout = TOOLTIP_TIMEOUT,
    animationDuration = ANIMATION_DURATION
}: Props) => {
    const [showTooltip, updateShowTooltip] = useState(false);

    useEffect(() => {
        const currentRef = componentRef?.current;
        const showTooltipCallback = (show: boolean) => () =>
            updateShowTooltip(show);

        currentRef?.addEventListener('click', showTooltipCallback(true));

        return () =>
            currentRef?.removeEventListener(
                'click',
                showTooltipCallback(false)
            );
    }, [componentRef]);

    useEffect(() => {
        if (!showTooltip) return;

        const timeout = setTimeout(
            () => updateShowTooltip(false),
            toolTipTimeout
        );

        return () => clearTimeout(timeout);
    }, [showTooltip, toolTipTimeout]);

    return (
        <Transition in={showTooltip} timeout={animationDuration}>
            {(state: TransitionStatus) => (
                <StyledTooltip
                    style={{ ...transitionStyles[state] }}
                    position={position}
                    animationDuration={animationDuration}
                >
                    <Typograph setInnerHtml tx={tx}></Typograph>
                </StyledTooltip>
            )}
        </Transition>
    );
};
