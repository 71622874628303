import { call, select, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { Token } from 'services/api/ApiClient';
import {
    isBroker,
    isBrokerLoginPage,
    getAccountRidFromParams
} from 'reducers/account.selectors';
import { getAuthToken } from 'reducers/auth.selectors';
import { onFetchRegionRequest } from 'sagas/locale.sagas';
import {
    onGetAccount,
    getBrokerAccountRequest,
    onBehalfLoginRequest
} from 'sagas/account.sagas';
import { setAuthToken } from 'sagas/auth.sagas';
import { bootstrapPartner } from 'sagas/ui.sagas';
import { bootstrapImpressionAnalytics } from 'sagas/account.sagas';
import { isLoggedIn, isBrokerBehalfToken } from 'reducers/auth.selectors';
import { onFetchApplicationsRequest } from 'sagas/application.sagas';
import { StartupActions } from 'reducers/startup.redux';
import { Actions as AccountActions } from 'reducers/account.redux';
import { SessionActions } from 'reducers/session.redux';
import { getActiveApplicationId } from 'reducers/application.selectors';
import { fetchLeadReferral } from 'sagas/ui.sagas';
import { getFeatureFlagState } from 'components/feature-flagger/hooks';
import { Routes } from 'app-root/routing/routes';

export function* startup() {
    const token: Token = yield select(getAuthToken);

    if (token) {
        yield call(setAuthToken, { token });

        const isBrokerToken = yield select(isBrokerBehalfToken);

        if (isBrokerToken) yield call(getBrokerAccountRequest);
    }

    // Set theme from session or querystring before creating latency from backend
    // we need the theme setting to be defined after the token
    // may be an update to parter and subPartnerId can change here
    yield call(bootstrapPartner);
    yield call(bootstrapImpressionAnalytics);

    yield call(onFetchRegionRequest);

    const loggedIn = yield select(isLoggedIn);
    const brokerPage = yield select(isBrokerLoginPage);

    const isProprioDirectReferral = yield getFeatureFlagState(
        'proprioDirect-lead-referral'
    );
    if (loggedIn && isProprioDirectReferral) {
        yield call(fetchLeadReferral);
    }

    if (loggedIn && brokerPage) {
        // To see if Broker (SaleForce) open a new tab with a new client account RID
        // we habe to compare it from the previously (current at this point) logged client account RID
        // `onGetAccount` was call before to get the previously (current) client to look if the account.rid was different
        // from the accountRID into the URL.
        // We cannot `onGetAccount` because on success of that saga another lister watch for the session parter to
        // check if partner should be update.
        //
        // Doing the request on the previous user will set partner in session
        // then swap to new client but the parter session will be from the client 1
        // this will make the client 2 account to be updated with the client 1 partner
        //
        // That's the reason to store only the account RID into the localStorage `broker_on_behalf_of` to avoid doing
        // the BE request only to grab the account RID of the previous client only to do the check condition below
        //
        //
        // BEFORE (with `yield call(onGetAccount)`)
        // Get Account Client 1 from BE
        //   trigger get account success
        //   Sagas listener watching get account success to set partner in session
        // Log out client 1
        // ->
        // Log in client 2
        //   trigger get account success
        //   Sagas listener watching get account success to set partner in session
        //      Checkout if client 2 partner is same or different of session partner
        //      if is different update account client 2 with partner value of client 1
        // ** = CORRUPTED DATA **
        //
        //
        // AFTER (NO `yield call(onGetAccount)`)
        // Get Client 1 RID from localStorage (because of new tab we cannot user session)
        // Log out client 1
        // NO partner session has been set since we are not doing the BE request
        // Log in client 2
        //   trigger get account success
        //   Sagas listener watching get account success to set partner in session
        //      No partner session exists since it is a new tab open by SF
        //      Set partner session of client 2
        //
        //
        // OLD Behaviour that create corruputed account data
        // Because account is store in session we need to get it back from BE
        // otherwise currentRid will be undefined and the broker won't change client
        // SF open each client in new tabs.
        // yield call(onGetAccount); // <- DO NOT CALL THIS HERE
        // const { rid: currentRid } = yield select(getAccount);
        // Use localStorage value instead

        const accountRid = yield select(getAccountRidFromParams);
        const currentRid = localStorage.getItem('broker_on_behalf_of');

        const isNewAccountRid =
            accountRid && currentRid && accountRid !== currentRid;

        if (isNewAccountRid) {
            yield put(SessionActions.setActiveApplicationId(null));
            yield call(onBehalfLoginRequest, { accountRid });
        } else {
            yield call(onGetAccount);

            const activeApplicationId = yield select(getActiveApplicationId);

            if (activeApplicationId) {
                yield put(
                    SessionActions.setActiveApplicationId(
                        activeApplicationId,
                        'lastVisited'
                    )
                );
            } else {
                yield put(push(Routes.applicationSelection));
            }
        }
    } else if (loggedIn) {
        yield call(onGetAccount);
    }

    if (loggedIn && !brokerPage) {
        // On a fresh CLIENT login, we would fetch
        // applications as part of the Login flow.
        //
        // However, if they are already logged in,
        // and then refresh and come back to the site,
        // we need to fetch it on [ STARTUP ]
        // ======================================
        // On fresh BROKER login, we would fetch
        // applications as part of the Login flow.
        //
        // However, if they are already logged in,
        // and then refresh and come back to the site,
        // we need to fetch it on [ STARTUP ]
        //
        // If the BROKER switches clients,
        // they are effectively doing a fresh log-in
        // on-behalf of a new client.
        //
        // So, automatically after the BROKER has been
        // logged out of the previously logged-in on-behalf
        // account, they will do a fresh client login and fetch
        // applications as part of the Login flow.
        // ======================================
        // The only scenario this fetchApplications isn't helpful
        // is when BROKER is switching clients.
        //
        // The fetchApplications will end before the onBehalfLogin
        // finishes for the new client. Thus, it is a wasted fetch.
        //
        // In addition, it causes issues when other API calls
        // are made on onFetchApplicationsSucces with incorrect
        // data. And the application has already moved to a different
        // page for the new client before the data is received from
        // the backend.
        //
        // The current solution is thus to not fetchApplications when
        // the Startup is on '/brokers-login' page.
        //
        // I don't know of a better way to do this ATM.
        yield call(onFetchApplicationsRequest);

        yield put(AccountActions.getCommunicationPreferences());
    }

    window.localStorage.setItem('isUserBroker', yield select(isBroker));

    yield put(StartupActions.end());
}
