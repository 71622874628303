import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { Routes, RoutesType } from './routes';

import { isLoggedIn } from 'reducers/auth.selectors';

import { RouteLoader } from './BaseRoute';
import { PublicRoute } from './PublicRoute';

type StateProps = {
    loggedIn?: boolean;
};

type Props = {
    match?: string | string[];
    path: RoutesType | RoutesType[];
    location?: {
        pathname: string;
        search: string;
        state: any;
        hash: any;
    };
    exact?: boolean;
    component: any;
    condition?: boolean;
};

const ConditionalPrivateRouteView = ({
    component: Component,
    loggedIn,
    condition,
    match,
    ...rest
}: Props & StateProps) => {
    const { search } = useLocation();

    return (
        <PublicRoute
            {...rest}
            render={() => {
                // if feature flag is loading -> loader
                if (condition === undefined || condition === null) {
                    return <RouteLoader />;
                }

                // if feature is enabled -> no redirect
                if (condition === true) {
                    return <Component />;
                }

                // if feature is disabled  -> redirect is non logged in
                if (condition === false) {
                    return loggedIn ? (
                        <Component />
                    ) : (
                        <Redirect
                            to={{
                                pathname: Routes.login,
                                search,
                                state: { from: rest.location, search }
                            }}
                        />
                    );
                }
            }}
        />
    );
};

export const ConditionalPrivateRoute = connect<StateProps>((state: any) => ({
    loggedIn: isLoggedIn(state)
}))(ConditionalPrivateRouteView);
