import styled from 'styled-components/macro';
import { Flex } from 'reflexbox/styled-components';
import Headroom from 'react-headroom';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';

import { isMobile, useSizes, useTheme } from 'utils/hooks';

import { Actions as AccountActions } from 'reducers/account.redux';
import { SubmitButton } from 'components/buttons/button';
import { LanguageSwitcher } from './language-switcher.component';
import { Logo } from 'components/sidebar/components/logo.component';
import { Typograph } from 'components/typograph';
import { HeaderLoggedInMobile } from './header-logged-in-mobile.component';
import { useAppDispatch, useAppSelector } from 'redux-store/hooks';
import { getApplications } from 'reducers/application.selectors';
import { useEffect } from 'react';
import { useState } from 'react';

const HeaderContentWrapper = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    min-width: fit-content;
`;

type Props = {
    hideLogout?: boolean;
};
const windowLocationPathArray = window.location.pathname.split('/');

const isGetAQuote = windowLocationPathArray.includes('getaquote');

const isRatesPage = windowLocationPathArray.includes('rates');

export const HeaderLoggedIn = ({ hideLogout = false }: Props) => {
    const theme = useTheme();
    const [showAccountSettings, setShowAccountSettings] = useState(false);
    const applications = useAppSelector(getApplications);
    const sizes = useSizes({ isMobile });
    const dispatch = useAppDispatch();

    const logout = () => {
        dispatch(AccountActions.logout());
    };

    useEffect(() => {
        if (applications.length || (!isGetAQuote && !isRatesPage)) {
            setShowAccountSettings(true);
        }
    }, [applications]);

    return (
        <Headroom
            wrapperStyle={{
                zIndex: theme.zIndices.heaven + 1
            }}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingLeft: theme.space[3],
                paddingRight: theme.space[3],
                backgroundColor: transparentize(0.05, theme.colors.white)
            }}
        >
            <Flex
                width="100%"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Logo />

                <HeaderContentWrapper>
                    {sizes.isMobile ? (
                        <HeaderLoggedInMobile
                            showAccountSettings={showAccountSettings}
                            hideLogout={hideLogout}
                            logout={logout}
                        />
                    ) : (
                        <>
                            {showAccountSettings && (
                                <Link to="/settings">
                                    <Typograph
                                        tx="sidebar.settings"
                                        color="textBlack"
                                        fontSize={[0]}
                                        fontWeight="normal"
                                    />
                                </Link>
                            )}

                            <LanguageSwitcher px={2} />
                            {!hideLogout && (
                                <SubmitButton
                                    data-test-id="logout"
                                    width="auto"
                                    minWidth="auto"
                                    preset="TINY"
                                    dataTestId="logout"
                                    label="nav.logout"
                                    onClick={logout}
                                    my={0}
                                    ml={1}
                                />
                            )}
                        </>
                    )}
                </HeaderContentWrapper>
            </Flex>
        </Headroom>
    );
};
