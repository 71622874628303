import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { BoxContained } from 'components/box-contained';
import { SubmitButton } from 'components/buttons/button';

import { HamburgerMenu } from 'components/hamburger-menu';
import { Logo } from 'components/sidebar/components/logo.component';
import { withSizesHoc as WithSizes, WithSizesProps } from 'hocs/with-sizes.hoc';
import { LanguageSwitcher } from 'components/navigation/language-switcher.component';
import { useHistory } from 'react-router';
import { Routes } from 'app-root/routing/routes';

type Props = {
    hideLogin?: boolean;
};

const Wrapper = styled(BoxContained)`
    height: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 ${props => props.theme.space[3]}px;
    background-color: ${props => props.theme.colors.white};
    z-index: 99;
`;

const HeaderWraper = styled.div`
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    z-index: ${props => props.theme.zIndex.sky};
`;

const HeaderContentWrapper = styled.div`
    display: flex;
    align-items: center;

    > * {
        padding: 0 20px;
    }
`;

const HeaderLoggedOutView = ({
    isMobile = false,
    hideLogin = false
}: Props & WithSizesProps) => {
    const history = useHistory();
    const [menuState, updateMenuState] = useState(false);

    return (
        <HeaderWraper>
            <Wrapper>
                <Logo />
                <HeaderContentWrapper>
                    {isMobile ? (
                        <HamburgerMenu
                            hideLogin={hideLogin}
                            onOpenCloseMenu={() => updateMenuState(!menuState)}
                            isOpen={menuState}
                        />
                    ) : (
                        <>
                            {!hideLogin && (
                                <SubmitButton
                                    data-test-id="header-login"
                                    width="auto"
                                    minWidth="auto"
                                    preset="TINY"
                                    label="userMenu.login"
                                    onClick={() => history.push(Routes.login)}
                                    my={0}
                                />
                            )}
                            <LanguageSwitcher />
                        </>
                    )}
                </HeaderContentWrapper>
            </Wrapper>
        </HeaderWraper>
    );
};

export const HeaderLoggedOut = WithSizes(HeaderLoggedOutView) as React.FC<
    Props
>;
