import { useFeatureFlag } from './hooks';

type Props = {
    variation: string;
    children: any;
};

// Naming convention is wrong
//
// Flag is what we send to LaunchDarkly
// Variation is the result
//
// TODO: Change it everywhere in another PR
export const FeatureFlagger = ({ variation: flag, children }: Props) => {
    const variation = useFeatureFlag(flag);

    if (variation === undefined || variation === null) {
        return null;
    } else {
        return children(variation);
    }
};
