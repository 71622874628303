import { TypographI18nKeys } from 'components/typograph/index';
import { propertyTypes } from './property-types';

export const getPropertyTypeLabel = (
    propertyType: propertyTypes
): TypographI18nKeys =>
    ({
        [propertyTypes.HOUSE]: 'propertyType.house',
        [propertyTypes.HOME_OR_CONDO]: 'propertyType.houseOrCondo',
        [propertyTypes.CONDO]: 'propertyType.condo',
        [propertyTypes.SECOND_HOME_OR_COTTAGE]:
            'propertyType.secondHomeOrCottage',
        [propertyTypes.SECONDARY_HOME_OR_COTTAGE]:
            'propertyType.secondHomeOrCottage',
        [propertyTypes.SECOND_HOME]: 'propertyType.secondHome',
        [propertyTypes.COTTAGE]: 'propertyType.cottage',
        [propertyTypes.DUPLEX]: 'propertyType.duplex',
        [propertyTypes.TRIPLEX]: 'propertyType.triplex',
        [propertyTypes.FOURPLEX]: 'propertyType.fourplex',
        [propertyTypes.RENTAL]: 'propertyType.rentalFour',
        [propertyTypes.FIVE_UNITS_PLUS]: 'propertyType.rentalFivePlus',
        [propertyTypes.OTHER]: 'other'
    }[propertyType] as TypographI18nKeys);

export const getPropertyTypeOptions = (...types: propertyTypes[]) =>
    types &&
    types.map(option => ({
        value: option,
        label: getPropertyTypeLabel(option)
    }));
