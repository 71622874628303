import React from 'react';
import { Box } from 'reflexbox/styled-components';
import styled, { css } from 'styled-components/macro';
import {
    SpaceProps,
    color,
    ColorProps,
    height,
    HeightProps,
    width,
    WidthProps
} from 'styled-system';

type Props = {
    vertical?: boolean;
};

const horizontalStyle = css`
    height: 1px;
    width: 100%;

    ${width}
`;

const verticalStyle = css`
    height: 100%;
    width: 1px;

    ${height}
`;

// @ts-ignore
export const DividerBox = styled(Box)`
    background-color: ${props => props.theme.colors.mercury};

    ${({ vertical }) => (vertical ? verticalStyle : horizontalStyle)}

    ${color}
`;

const Divider = (
    props: Props & SpaceProps & ColorProps & HeightProps & WidthProps
) => <DividerBox {...props} />;

export { Divider };
