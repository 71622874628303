import { DashboardStatuses, ReduxState } from './dashboard.redux';

export type DashboardStates = {
    shop: 'active' | 'locked' | 'hasQuoteButNoPrequal' | 'completed';
    apply: 'active' | 'locked' | 'completed' | 'hasNoApplication';
    submitsign: 'active' | 'locked' | 'completed';
};

export const getDashboardLoaded = (state: ReduxState): boolean =>
    state.dashboard && state.dashboard.loaded;

export const getDashboardStatus = (state: ReduxState): DashboardStatuses =>
    state.dashboard && state.dashboard.dashboardState.state;

export const getGAQInterestLevel = (state: ReduxState) =>
    state?.dashboard.dashboardState?.quote?.interestLevel;
