import { call, put, select } from 'redux-saga/effects';

import { Actions as AccountActions } from 'reducers/account.redux';
import { Actions, LocaleTypes, selectOtherLocale } from 'reducers/locale.redux';
import { isLoggedIn } from 'reducers/auth.selectors';
import { isBehalfAUser } from 'reducers/account.selectors';
import { log } from 'utils/logging';
import { PROVINCES, LOCALE_SRC } from 'constants/appConstants';
import { apiClient } from 'services/api';
import { changeLocale } from 'utils/localization-utils';
import { Region } from 'types/application/application';

// used to grab object keys from BE route
enum Geolocation {
    IP_API = `ipapi.co`,
    IP_INFO = `ipinfo.io`
}

export type RegionResponse = {
    ip: string;
    city: string;
    region: string;
    region_code: string;
    country: string;
    country_code: string;
    error: false | any;
    latitude: number;
    longitude: number;
    postal: string;

    // I left this one here because I don't have them in the response but maybe it was coming from an older API 🤷‍♂️
    // country_name: string;
    // continent_code: string;
    // in_eu: boolean;
    // timezone: string;
    // utc_offset: string;
    // country_calling_code: string;
    // currency: string;
    // languages: string;
    // asn: string;
    // org: string;
};

export function* onFetchRegionRequest() {
    try {
        const { ok, data, ...rest } = yield call(apiClient.fetchGeolocationAll);
        const { region_code: region, postal: ipApiPostal } = data[
            Geolocation.IP_API
        ];

        if (!ok || !data) {
            return log({
                error: `onFetchRegionRequest http failure: ${data}`,
                tag: 'IPAPI',
                data: {
                    ok,
                    data,
                    ...rest
                }
            });
        }

        const {
            ip,
            city,
            country,
            latitude,
            longitude,
            postal
        }: RegionResponse = data[Geolocation.IP_INFO];

        if (
            PROVINCES.map(province => province.value).includes(region as Region)
        ) {
            yield put({
                type: LocaleTypes.UPDATE_REGION,
                data: {
                    region,
                    ip,
                    city,
                    country,
                    lat: latitude,
                    lng: longitude,
                    postal: {
                        ipApi: ipApiPostal,
                        ipInfo: postal
                    }
                }
            });
        }
    } catch (error) {
        log({
            error: `onFetchRegionRequest type error: ${error}`,
            data: { error }
        });
    }
}

export function* changeLanguage() {
    try {
        const language = yield select(selectOtherLocale);
        const newMessages = changeLocale(language);
        yield put(
            Actions.updateLocaleAndMessages(
                language,
                newMessages,
                LOCALE_SRC.USER_SELECTION
            )
        );

        const isUserLoggedIn = yield select(isLoggedIn);
        const behalfAUser = yield select(isBehalfAUser);
        if (isUserLoggedIn && !behalfAUser) {
            yield put(AccountActions.updateUserInformation());
        }
    } catch (error) {
        log({ error: `changeLanguage: ${error}` });
    }
}
