import * as React from 'react';

const Icon = () => (
    <svg width="5px" height="7px" viewBox="0 0 5 7" version="1.1">
        <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeOpacity="0.98"
        >
            <g
                id="bouton_bigger"
                transform="translate(-195.000000, -24.000000)"
                fillRule="nonzero"
                stroke="#000000"
            >
                <g id="Group">
                    <g
                        id="fleche_001"
                        transform="translate(196.000000, 24.771429)"
                    >
                        <polyline id="Path-2" points="0 0 3 3 0 6" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export { Icon };
