import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { formatUrlsWithQueries } from 'utils/helpers';
import {
    getCurrentApplicationApplicantId,
    isApplicationSubmitted as getIsApplicationSubmitted,
    getIsValidDownPaymentAmount,
    getCurrentApplicationType
} from 'reducers/application.selectors';
import {
    memorizedGetApplicationNavigationStates,
    ApplicationNavigationStates,
    getIsRateSelectionNavOpen,
    getIsBlockByBankruptcy
} from 'reducers/sidebar.selectors';
import {
    navigationState,
    navigationStates,
    NavigationTitle,
    SidebarNavigationIcon
} from 'components/sidebar/sidebar.component';
import { Navigations } from 'components/sidebar/components/navigations.component';
import { WithLink } from 'components/with-link';
import { Typograph } from 'components/typograph';
import {
    getSelection,
    getSummaryConfirmationStatus
} from 'reducers/product-selection.selectors';
import { Actions } from 'reducers/product-selection.redux';
import { hasApplicationId } from 'reducers/application.selectors';
import { useAnalyticEvent } from 'utils/hooks';
import { Actions as SidebarActions } from 'reducers/ui.redux';
import { Routes } from 'app-root/routing/routes';
import { SummaryConfirmation } from 'product-selection/models';

type StateProps = {
    activeApplicantId?: string | number;
    isApplicationRoute?: boolean;
    navigationStepStates: ApplicationNavigationStates;
    isApplicationSubmitted: boolean;
    isBlockByBankruptcy: boolean;
    isValidDownPaymentAmount: boolean;
    isNewMortgage: boolean;
    hasApplicationId: boolean;
    summaryConfirmation: SummaryConfirmation;
};

type DispatchProps = {
    productSelection: typeof Actions['productSelection'];
    toggleSidebar: () => void;
};

type Props = {
    applicationState: navigationStates;
    nextApplicationState: navigationStates;
};

const NavItemTitle = ({ children }: { children: React.ReactNode }) => (
    <Typograph fontSize="15px" fontWeight="bold">
        {children}
    </Typograph>
);
export const ProductSelectionNavigationView = ({
    applicationState,
    isApplicationSubmitted,
    isBlockByBankruptcy,
    isValidDownPaymentAmount,
    isNewMortgage,
    hasApplicationId,
    productSelection,
    toggleSidebar,
    summaryConfirmation
}: Props & StateProps & DispatchProps & RouteComponentProps) => {
    const analyticEvent = useAnalyticEvent();

    useEffect(() => {
        // load product selection only if the app is submitted and we have an appID
        if (hasApplicationId && isApplicationSubmitted) {
            productSelection({ availability: true });
        }
    }, [hasApplicationId, isApplicationSubmitted, productSelection]);

    const getStatusFromConfirmation = (
        summaryConfirmation: SummaryConfirmation
    ): string => {
        const confirmationStatus = {
            CONFIRMED: 'COMPLETED',
            NOT_SURE_YET: 'ACTIVE',
            DEFAULT: 'UNREACHED'
        };
        const stateOfApplication = isApplicationSubmitted
            ? 'ACTIVE'
            : confirmationStatus.DEFAULT;
        return confirmationStatus[summaryConfirmation] || stateOfApplication;
    };
    const productSelectionState = getStatusFromConfirmation(
        summaryConfirmation
    );

    const trackRateSelection = () => {
        analyticEvent('change_section', {
            main_section_name: 'product selection',
            event_location_category: 'navigation',
            info: 'select my rate'
        });
    };

    const validNewMortgageDownPayment =
        !isNewMortgage || isValidDownPaymentAmount;
    const canGoToProductSelection =
        isApplicationSubmitted && validNewMortgageDownPayment;

    const hasCompletedMortgageBuilder =
        applicationState === navigationState.COMPLETED_STATE &&
        productSelectionState === navigationState.COMPLETED_STATE;

    return (
        <>
            <WithLink
                onClick={event => {
                    trackRateSelection();

                    if (!validNewMortgageDownPayment) {
                        event.stopPropagation();
                        event.preventDefault();
                    }
                }}
                to={formatUrlsWithQueries(
                    `/application/${
                        canGoToProductSelection ? 'submitted' : 'submit'
                    }`,
                    {
                        sectionName: `application.${
                            canGoToProductSelection ? 'submitted' : 'submit'
                        }`
                    }
                )}
            >
                <Navigations
                    data-test-id="product-selection-sidebar-section"
                    // Some special rules with new sidebar
                    // we don't want le line to continue after
                    // rate selection because documents is out of the flow
                    active={
                        isBlockByBankruptcy
                            ? navigationState.UNREACHED_STATE
                            : applicationState
                    }
                    withCursor={
                        applicationState !== navigationState.UNREACHED_STATE &&
                        !isBlockByBankruptcy
                    }
                >
                    <NavigationTitle
                        // keep state like this (empty string) === active
                        // completed = light gray disabled color
                        state={
                            isBlockByBankruptcy ||
                            !validNewMortgageDownPayment ||
                            applicationState === navigationState.UNREACHED_STATE
                                ? navigationState.COMPLETED_STATE
                                : ''
                        }
                        label="sidebar.sendToReview"
                    />
                    <SidebarNavigationIcon activeState={applicationState}>
                        <Typograph fontSize="15px" fontWeight="bold">
                            <NavItemTitle>2</NavItemTitle>
                        </Typograph>
                    </SidebarNavigationIcon>
                </Navigations>
            </WithLink>

            <WithLink
                onClick={event => {
                    trackRateSelection();
                    toggleSidebar();

                    if (!canGoToProductSelection) {
                        event.stopPropagation();
                        event.preventDefault();
                    }
                }}
                to={Routes.applicationSubmitted}
            >
                <Navigations
                    data-test-id="mortgage-builder-sidebar-section"
                    // Product Selection is valid if app is submitted
                    active={productSelectionState}
                    withCursor={canGoToProductSelection}
                >
                    <NavigationTitle
                        // keep state like this (empty string) === active
                        // completed = light gray disabled color
                        state={productSelectionState}
                        label="sidebar.selectProduct"
                    />
                    <SidebarNavigationIcon
                        activeState={
                            hasCompletedMortgageBuilder
                                ? navigationState.COMPLETED_STATE
                                : canGoToProductSelection
                                ? navigationState.ACTIVE_STATE
                                : navigationState.UNREACHED_STATE
                        }
                    >
                        <Typograph fontSize="15px" fontWeight="bold">
                            <NavItemTitle>3</NavItemTitle>
                        </Typograph>
                    </SidebarNavigationIcon>
                </Navigations>
            </WithLink>
        </>
    );
};

export const ProductSelectionNavigation = compose<React.FC<Props>>(
    withRouter,
    connect<StateProps, DispatchProps>(
        (state: any) => ({
            isNavOpen: getIsRateSelectionNavOpen(state),
            summaryConfirmation: getSummaryConfirmationStatus(state),
            activeApplicantId: getCurrentApplicationApplicantId(state),
            navigationStepStates: memorizedGetApplicationNavigationStates(
                state
            ),

            isApplicationSubmitted: getIsApplicationSubmitted(state),
            isBlockByBankruptcy: getIsBlockByBankruptcy(state),
            isValidDownPaymentAmount: getIsValidDownPaymentAmount(state),
            isNewMortgage: getCurrentApplicationType(state) === 'NEW',
            selection: getSelection(state),
            hasApplicationId: hasApplicationId(state)
        }),
        {
            productSelection: Actions.productSelection,
            toggleSidebar: () => SidebarActions.updateSidebar()
        }
    )
)(ProductSelectionNavigationView);
