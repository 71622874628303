import qs from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useQueryString = <T = Record<string, string>>(): T => {
    const location = useLocation();

    // @ts-ignore
    return useMemo(
        () =>
            qs.parse(location.search, {
                ignoreQueryPrefix: true
            }),
        [location]
    );
};
