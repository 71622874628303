import { useEffect } from 'react';

export const useKeyDown = (
    targetKey: string,
    action: (event: KeyboardEvent) => any
) => {
    const downHandler = (event: KeyboardEvent) => {
        if (event.key === targetKey) {
            return action(event);
        }
    };

    // Add event listeners
    useEffect(() => {
        window.addEventListener('keydown', downHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
        // eslint-disable-next-line
    }, [action]);
};
