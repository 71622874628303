import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typograph } from '@nesto/design-system';
import { Box, Flex } from 'components/reflexbox';
import { Actions } from 'reducers/application.redux';
import { getApplicationAdvisor } from 'reducers/application.selectors';
import { useAppSelector } from 'redux-store/hooks';
import styled from 'styled-components';
import { useMessages } from 'utils/hooks';
import { Advisor } from 'models/application/Application';
import { useFeatureFlag } from 'components/feature-flagger/hooks';
import { Icon } from '@nesto/design-system/v2';
import { PartnerContext } from 'app-root/providers/PartnerProvider';

const Circle = styled.div`
    background-color: ${({ theme }) => theme.colors.port_gore};
    height: 48px;
    width: 48px;
    border-radius: 100%;
    border: 2px solid white;
    justify-content: center;
    display: flex;
    align-items: center;
`;
const Text = styled(Box)`
    font-size: 12px;
    color: #212243;
    display: block;
    line-height: 16px;

    &:not(:first-child) {
        margin-top: 6px;
        padding-top: 6px;
        border-top: 1px solid white;
    }
`;

export const AgentContact = () => {
    const dispatch = useDispatch();
    const translate = useMessages();
    const { partner } = useContext(PartnerContext);
    const isWealthSimple = partner === 'wealthSimple';
    const isOnDashboard = window.location.pathname
        .split('/')
        .includes('selection');

    const showApplicationAgentContactInfo = useFeatureFlag(
        'show-application-agent-contact-info'
    );
    const defaultContact = {
        contactName: translate('agentContact.CUSTOMER_SUCCESS'),
        phone: translate(
            isWealthSimple
                ? 'getAQuote.footer.wealthSimple.phone'
                : 'agentContact.supportPhone'
        ),
        email: translate('agentContact.supportEmail')
    };

    const [contactToDispath, setContactToDisplay] = useState<Partial<Advisor>>(
        defaultContact
    );

    const advisor = useAppSelector(getApplicationAdvisor) as Advisor;

    useEffect(() => {
        dispatch(Actions.fetchApplicationAdvisor());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnDashboard]);

    useEffect(() => {
        // if advisor exists but there's no contact name
        if (
            !isOnDashboard &&
            showApplicationAgentContactInfo &&
            advisor?.contactName
        ) {
            setContactToDisplay(advisor);
        } else {
            setContactToDisplay(defaultContact);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advisor, isOnDashboard, showApplicationAgentContactInfo]);

    return (
        <Box
            css={{ backgroundColor: '#F0F0F0' }}
            p="7.5px 30px"
            mx="-30px"
            mb="80px"
        >
            <Typograph
                tx="agentContact.title"
                as="h3"
                fontSize={'14px'}
                fontWeight={600}
                pb="10px"
                mb="10px"
                css={{ borderBottom: '1px solid white' }}
            />
            <Box
                display="grid"
                css={{
                    gridTemplateColumns: '48px auto',
                    gridColumnGap: '20px'
                }}
            >
                <Flex alignItems="center">
                    <Circle>
                        <Icon name="headphones" color="white" size={24} />
                    </Circle>
                </Flex>
                <div>
                    {contactToDispath?.contactName && (
                        <Text as="p" css={{ fontWeight: 600 }}>
                            {contactToDispath.contactName}
                        </Text>
                    )}
                    {contactToDispath.title && (
                        <Box as="p">
                            {translate(
                                `agentContact.${contactToDispath.title}`
                            )}
                        </Box>
                    )}

                    {contactToDispath?.phone && (
                        <Text
                            as="a"
                            css={{ fontWeight: 500 }}
                            href={`tel:${contactToDispath.phone}`}
                        >
                            {contactToDispath.phone}
                        </Text>
                    )}
                    {contactToDispath?.email && (
                        <Text
                            as="a"
                            css={{ fontWeight: 500 }}
                            href={`mailto:${contactToDispath.email}`}
                        >
                            {contactToDispath.email}
                        </Text>
                    )}
                </div>
            </Box>
        </Box>
    );
};
