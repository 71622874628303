import * as React from 'react';
import ReactDOM from 'react-dom';

let portalContainer;

class Portal extends React.PureComponent {
    el: any;

    constructor() {
        // @ts-ignore
        super();

        if (!portalContainer) {
            portalContainer = document.createElement('div');
            portalContainer.setAttribute('modal-portal-container', '');
            document.body.appendChild(portalContainer);
        }

        this.el = document.createElement('div');
    }

    componentDidMount() {
        portalContainer.appendChild(this.el);
    }

    componentWillUnmount() {
        portalContainer.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.el);
    }
}

export { Portal };
