enum maritalStatuses {
    SINGLE = 'SINGLE',
    MARRIED = 'MARRIED',
    DIVORCED = 'DIVORCED',
    COMMON_LAW = 'COMMON_LAW',
    WIDOWED = 'WIDOWED',
    SEPERATED = 'SEPERATED',
    OTHER = 'OTHER'
}

export { maritalStatuses };
