import React from 'react';

const getHeight = (size: number): number => {
    const originalWidth = 14;
    const originalHeight = 19;

    return (originalHeight * size) / originalWidth;
};

export const DocumentSvg = ({ size = 14, fillColor = 'currentColor' }) => (
    <svg
        width={size}
        height={getHeight(size)}
        viewBox="0 0 14 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="--Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Icon/26x26/navigation/doc_icon_bold"
                transform="translate(-10.000000, -4.000000)"
                fill={fillColor}
                fillRule="nonzero"
            >
                <path
                    d="M18.3106602,4.75 L23.75,10.1893398 L23.75,19.5 C23.75,21.0187831 22.5187831,22.25 21,22.25 L13,22.25 C11.4812169,22.25 10.25,21.0187831 10.25,19.5 L10.25,7.5 C10.25,5.98121694 11.4812169,4.75 13,4.75 L18.3106602,4.75 Z M16.75,6.25 L13,6.25 C12.3096441,6.25 11.75,6.80964406 11.75,7.5 L11.75,19.5 C11.75,20.1903559 12.3096441,20.75 13,20.75 L21,20.75 C21.6903559,20.75 22.25,20.1903559 22.25,19.5 L22.25,11.25 L19,11.25 C17.809136,11.25 16.8343551,10.3248384 16.7551908,9.15404881 L16.75,9 L16.75,6.25 Z M21.19,9.75 L18.25,6.811 L18.25,9 C18.25,9.37969577 18.5321539,9.69349096 18.8982294,9.74315338 L19,9.75 L21.19,9.75 Z"
                    id="doc_icon_bold"
                ></path>
            </g>
        </g>
    </svg>
);
