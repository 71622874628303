import React from 'react';
import { Box } from 'reflexbox/styled-components';
import { Field, Validator } from 'redux-form';
import { TextArea } from 'components/inputs/text-area';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { WidthProps, SpaceProps, FontSizeProps } from 'styled-system';
import * as Validators from 'lib/validators';

export type Props = {
    name: string;
    placeholder: string;
    width?: string;
    isRequired?: boolean;
    disabled?: boolean;
    validate?: Validator[];
};

type StyledProps = WidthProps & SpaceProps;

const noOp = () => undefined;

const TextAreaFieldView = ({
    placeholder,
    name,
    validate = [noOp],
    intl,
    isRequired = false,
    disabled = false,
    ...rest
}: Props & StyledProps & WrappedComponentProps & Partial<FontSizeProps>) => (
    <Box mt={[3]} width="100%" {...rest}>
        <Field
            placeholder={intl.formatMessage({
                id: placeholder
            })}
            disabled={disabled}
            name={name}
            component={TextArea}
            isRequired={isRequired}
            validate={[isRequired ? Validators.isRequired : noOp, ...validate]}
        />
    </Box>
);

export const TextAreaField = injectIntl(TextAreaFieldView);
