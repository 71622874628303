import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { colors, Colors } from '@nesto/themes';

const lineSpinFadeLoader = keyframes`
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
`;

const animation = (duration: string, delay: string) => props => css`
    animation: ${lineSpinFadeLoader} ${duration} ${delay} infinite ease-in-out;
`;

type Props = {
    variant?: Colors;
    style?: any;
};

type StyledProps = Pick<Props, 'variant'>;

const StyledSpinner = styled.div`
    position: relative;
    top: -10px;
    left: -4px;

    > div {
        background-color: ${({ variant }: StyledProps) =>
            variant ? colors[variant] : colors.textBlack};
        border-radius: 2px;
        margin: 2px;
        animation-fill-mode: both;
        position: absolute;
        width: 5px;
        height: 15px;
    }

    > div:nth-child(1) {
        top: 20px;
        left: 0;
        ${(props: StyledProps) => animation('1.2s', '-0.84s')}
    }

    > div:nth-child(2) {
        top: 13.63636px;
        left: 13.63636px;
        transform: rotate(-45deg);
        ${(props: StyledProps) => animation('1.2s', '-0.72s')}
    }

    > div:nth-child(3) {
        top: 0;
        left: 20px;
        transform: rotate(90deg);
        ${(props: StyledProps) => animation('1.2s', '-0.6s')}
    }

    > div:nth-child(4) {
        top: -13.63636px;
        left: 13.63636px;
        transform: rotate(45deg);
        ${(props: StyledProps) => animation('1.2s', '-0.48s')}
    }

    > div:nth-child(5) {
        top: -20px;
        left: 0;
        ${(props: StyledProps) => animation('1.2s', '-0.36s')}
    }

    > div:nth-child(6) {
        top: -13.63636px;
        left: -13.63636px;
        transform: rotate(-45deg);
        ${(props: StyledProps) => animation('1.2s', '-0.24s')}
    }

    > div:nth-child(7) {
        top: 0;
        left: -20px;
        transform: rotate(90deg);
        ${(props: StyledProps) => animation('1.2s', '-0.12s')}
    }

    > div:nth-child(8) {
        top: 13.63636px;
        left: -13.63636px;
        transform: rotate(45deg);
        ${(props: StyledProps) => animation('1.2s', '1.2s')}
    }
`;

const Spinner = (props: Props) => (
    <StyledSpinner data-test-id={`spinner-${props.variant}`} {...props}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
    </StyledSpinner>
);

Spinner.displayName = 'Spinner';

Spinner.defaultProps = {
    variant: 'textBlack'
};

export { Spinner };
