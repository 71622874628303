import React, { forwardRef } from 'react';
import { Box } from 'reflexbox/styled-components';
import { CSSProp } from 'styled-components';
import { SpaceProps, ColorProps, LayoutProps } from 'styled-system';

type Props = {
    css: CSSProp;
} & SpaceProps &
    ColorProps &
    LayoutProps &
    React.RefAttributes<HTMLImageElement> &
    React.HTMLAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<any>;

export const Image = forwardRef(({ css, ...rest }: Props, ref) => (
    <Box
        ref={ref}
        as="img"
        {...rest}
        css={{
            minWidth: 0,
            maxWidth: '100%',
            // @ts-ignore
            ...css
        }}
    />
));
