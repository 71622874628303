import { ReduxState } from 'reducers/dashboard.redux';
import { getGAQInterestLevel } from 'reducers/dashboard.selectors';
import { getGAQApplicationType } from 'redux-store/mortgage-wizard/get-a-quote-flow.reducer';
import { useAppSelector } from 'redux-store/hooks';
import { Timings } from 'types/application/application';
import { getFormValues } from 'redux-form';
import { WIZARDS } from 'constants/appConstants';
import { ApplicationTypeEnum } from 'models/application/Application';
import { selectPrettoForm } from 'reducers/ui.selectors';

/**
 *
 * @returns false if the user selected just curious or get preapproved in the GAQ flow
 * used to filter out non-serious leads to save advisors time on the phone
 */
export const useIsLiveDeal = (props?: { bypassMortgageBuilder: boolean }) => {
    /**
     * if the active GAQ flow form is new mortgage & there's form data for 'timing' in state,
     * use that
     *
     * otherwise, check if the user is in the application, and use data from BE 'interestLevel'
     * (timing & interestLevel are the same values)
     *
     * otherwise, default to true
     */
    const formValues = useAppSelector(
        state =>
            getFormValues(WIZARDS.NEW_MORTGAGE_QUOTE)(state) as {
                timing?: Timings; // there isn't a type for new mortgage form values, it's not ideal, but it's necessary to avoid ts error
            }
    );
    const activeGAQForm = useAppSelector(state => getGAQApplicationType(state));
    const interestLevel = useAppSelector(state =>
        getGAQInterestLevel(state as ReduxState)
    );

    // This is applies for the pretto-gaq (feature flag)
    const prettoFormValues = useAppSelector(state => selectPrettoForm(state));

    const isUserInApplication = window.location.pathname.includes(
        '/application'
    );

    // for mortgage builder
    const isUserInMortgageBuilder = window.location.pathname.includes(
        '/product-selection'
    );

    if (
        (isUserInApplication || isUserInMortgageBuilder) &&
        props?.bypassMortgageBuilder
    ) {
        return {
            isLiveDeal: true
        };
    }

    if (prettoFormValues && prettoFormValues?.timing) {
        return {
            isLiveDeal:
                prettoFormValues.timing !== Timings.JUST_CURIOUS &&
                prettoFormValues.timing !== Timings.GET_PREAPPROVED
        };
    }

    if (activeGAQForm === ApplicationTypeEnum.NEW && !!formValues) {
        return {
            isLiveDeal:
                formValues?.timing !== Timings.JUST_CURIOUS &&
                formValues?.timing !== Timings.GET_PREAPPROVED
        };
    }

    if (isUserInApplication || isUserInMortgageBuilder) {
        return {
            isLiveDeal:
                interestLevel !== Timings.JUST_CURIOUS &&
                interestLevel !== Timings.GET_PREAPPROVED
        };
    }

    return {
        isLiveDeal: true
    };
};
