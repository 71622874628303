import * as React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import { Actions } from 'reducers/account.redux';
import { isLoggedIn } from 'reducers/auth.selectors';
import { getUserLanguage } from 'reducers/account.selectors';
import { Language } from 'types/language';

export type LoginPayload = {
    email: string;
    password: string;
};

export type PasswordResetPayload = {
    email: string;
};

export type PasswordChangePaylaod = {
    password: string;
    passwordConfirm: string;
    passwordChangeToken: string;
};

export type InjectedAccountProps = {
    signup: (payload: any) => void;
    login: (payload: LoginPayload) => void;
    passwordReset: (payload: PasswordResetPayload) => void;
    passwordResetSent: boolean;
    passwordChange: (payload: PasswordChangePaylaod) => void;
    activateAccount: (
        activationToken: string,
        accountId: string,
        redirect: boolean
    ) => void;
    location: any;
    isLoggedIn: boolean;
    currentLang: Language;
};

/**
 * Higher Order Component that connects to the account state and exposes
 * signup/login methods. Mainly used to wrap any view that needs access to the account
 * state or actions related to it.
 */
export const mapDispatchToProps = (dispatch: any) => ({
    signup: (userInfo: any) =>
        dispatch(Actions.createAccountRequest(userInfo, true)),
    login: (payload: LoginPayload) => dispatch(Actions.loginRequest(payload)),
    passwordReset: (payload: PasswordResetPayload) =>
        dispatch(Actions.passwordResetRequest(payload)),
    passwordChange: (payload: PasswordChangePaylaod) =>
        dispatch(Actions.passwordChangeRequest(payload)),
    activateAccount: (
        activationToken: string,
        accountId: string,
        redirect: boolean
    ) =>
        dispatch(
            Actions.activateAccountRequest(activationToken, accountId, redirect)
        )
});

const mapStateToProps = (state: any, ownProps: InjectedAccountProps) => {
    const { activationToken, id, passwordChangeToken } = qs.parse(
        ownProps.location.search,
        {
            ignoreQueryPrefix: true
        }
    );

    return {
        error: state.account.error,
        passwordResetSent: state.account.passwordResetSent,
        accountActivated: state.account.accountActivated,
        activationToken,
        id,
        passwordChangeToken,
        isLoggedIn: isLoggedIn(state),
        currentLang: getUserLanguage(state)
    };
};

export const AccountContainer = (WrapperComponent: React.ComponentType<any>) =>
    connect(mapStateToProps, mapDispatchToProps)(WrapperComponent);
