import apisauce, { ApiResponse, DEFAULT_HEADERS } from 'apisauce';
import {
    Applicant,
    Application,
    QueriedAccount
} from 'models/application/Application';
import { logMonitor } from 'utils/logging';
import { tokenIsExpired } from 'utils/token-util';
import {
    AccountTypes,
    AccountTrackingPayload,
    CommunicationPreferences,
    CallPreferencesPayload
} from 'reducers/account.redux';
import { store } from 'app-root/store';
import {
    RegisteredAddress,
    TargetProperty,
    OtherProperty,
    ApplicationType,
    ApplicationChangeStateEvent
} from 'models/application/Application';

import {
    Document,
    DocumentCompositeKey,
    DocumentFileCreateData
} from 'models/documents';

import { Payload } from 'reducers/product-selection.redux';
import { ClosingDocuments } from 'models/documents/closing-documents';
import { Step } from 'product-selection/models';
import { RegionResponse } from 'sagas/locale.sagas';
import {
    AccountDeletionState,
    AccountDeletionFormValues
} from 'types/account/account-deletion';
import { ServicingAsset } from 'models/servicing-asset';
import { addHeadersInterceptor } from '@nestoca/utils';
import { InternalAxiosRequestConfig } from 'axios';
import { WalnutLead } from 'components/documents-new/walnut-insurance';

export enum RequestStatus {
    UnauthorizedToken = 401
}

export type Request = {
    path: string;
    params: any;
    headers: any;
};

export type Token = {
    accessToken: string;
    expires: string;
    refreshToken: string;
    tokenType: string;
};

const WALNUT_INSURANCE_API_KEY = window?.config?.walnutInsuranceApiKey;

const create = (baseURL: string) => {
    const api = apisauce.create({
        baseURL,
        headers: {
            ...DEFAULT_HEADERS
        },
        timeout: 25000
    });

    const externalApi = apisauce.create({ baseURL, timeout: 25000 });

    api.axiosInstance.interceptors.request.use(config => {
        return addHeadersInterceptor(config as InternalAxiosRequestConfig);
    });

    let token: Token | undefined;
    let inflightRefresh: any;

    const refreshToken = () => {
        if (inflightRefresh) {
            return inflightRefresh;
        }

        if (token == null) {
            return Promise.reject(Error('Unable to find JWT token'));
        }

        const jwtToken: Token = token || {};

        const path = '/account/token';
        const params = JSON.stringify({
            refreshToken: jwtToken.refreshToken
        });

        const request = {
            path,
            params,
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
                'X-Refreshing-Token': 'true'
            }
        };

        store.dispatch({
            type: AccountTypes.REFRESH_TOKEN_REQUEST
        });

        inflightRefresh = api
            .put(request.path, request.params, { headers: request.headers })
            .then((response: ApiResponse<any>) => {
                if (response.problem) {
                    return Promise.reject(response);
                }
                return response.data;
            })
            .then(refreshed => {
                store.dispatch({
                    type: AccountTypes.REFRESH_TOKEN_SUCCESS,
                    token: refreshed
                });
                return refreshed;
            })
            .catch((error: ApiResponse<any>) => {
                store.dispatch({
                    type: AccountTypes.REFRESH_TOKEN_ERROR,
                    error
                });

                // Force user to login on invalid token
                if (error?.status === RequestStatus.UnauthorizedToken) {
                    store.dispatch({ type: AccountTypes.LOGOUT });
                }
            })
            // @ts-ignore
            .finally(() => {
                inflightRefresh = null;
            });

        return inflightRefresh;
    };

    const refreshTokenIfExpired = (request: any) => {
        // We added the header 'X-Refreshing-Token' to the request responsible
        // for refreshing the token to prevent having a recursion loop. We don't
        // want the refresh request trigger a token refresh and so on...
        if (
            token &&
            tokenIsExpired(token) &&
            request.headers['X-Refreshing-Token'] !== 'true'
        ) {
            return refreshToken()
                .then((authToken: any) => {
                    request.headers.Authorization = `Bearer ${authToken.accessToken}`;
                    return request;
                })
                .catch(() => request);
        }

        // don't actually send this header to the origin, if it exists
        delete request.headers['X-Refreshing-Token'];
        return Promise.resolve();
    };

    api.addAsyncRequestTransform(refreshTokenIfExpired);
    api.addMonitor(logMonitor);

    const setAuthToken = (authToken: Token) => {
        token = authToken;
        api.setHeaders({
            Authorization: `Bearer ${authToken.accessToken}`
        } as any);
    };

    const fetchQuotesMatrix = (params: any) =>
        api.get('/quotes/matrix', params);

    const fetchMatchedPartnerQuote = (quoteId: number) =>
        api.get<any>(`/quotes/${quoteId}/distribute-response`);

    const createAccount = (params: any) => api.post('/accounts', params);

    const getAccount = () => api.get('/account');

    const getAccountDeletionState = () =>
        api.get<AccountDeletionState>('/account/deletable');

    const deleteAccount = (feedback: AccountDeletionFormValues) =>
        api.post<any>('/account/deletion', feedback);

    const getAccountTracking = (key: string) =>
        api.get(`/account/trackings/${key}`);

    const addAccountTracking = (body: AccountTrackingPayload) =>
        api.post('/account/trackings', body);

    const addCallPreferences = (body: CallPreferencesPayload) =>
        api.post('/account/call-preferences', body);

    const loginAccount = (request: any) =>
        api.post('/account/tokens', request.params);

    const changePassword = (request: any, accountId: string) =>
        api.put(`/accounts/${accountId}/password`, request.params);

    const resetPassword = (request: any) =>
        api.post('/account/password/reset', request.params);

    const activateAccount = (request: any, accountId: string) =>
        api.put(`/accounts/${accountId}/activation`, request.params);

    const brokerLoginAccount = (request: any) =>
        api.post('/brokers/tokens', request.params);

    const behalfLoginAccount = (request: any) =>
        api.post('/brokers/tokens/representations/account', request.params);

    const behalfLogoutAccount = (request: any) =>
        api.put('/brokers/tokens/representations/account', request.params);

    const getBrokerAccount = () => api.get('/broker');

    const updateAccount = (request: any) => api.put('/account', request);

    const getCommunicationPreferences = () =>
        api.get('/account/communications/preferences');

    const updateCommunicationPreferences = (
        payload: Partial<CommunicationPreferences>
    ) => api.put('/account/communications/preferences', payload);

    const fetchDashboardState = () => api.get('/dashboard');

    const clearAuthToken = () => {
        token = undefined;
        api.deleteHeader('Authorization');
    };

    const updateApplicantInfo = (
        applicationId: string,
        applicantId: string,
        params: Applicant
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}`,
            params
        );

    const setOtherIncomesSpecified = (
        applicationId: string,
        applicantId: string,
        params: { specified: boolean }
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/other-incomes-specified`,
            params
        );

    const setPropertiesSpecified = (
        applicationId: string,
        applicantId: string,
        params: { specified: boolean }
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/properties-specified`,
            params
        );

    const createApplication = (type: ApplicationType, subPartnerId: number) =>
        api.post('/applications', { type, subPartnerId, enableMulti: true });

    const updateApplicationState = (
        applicationId: number,
        event: ApplicationChangeStateEvent
    ) => api.put(`/applications/${applicationId}/state`, { event });

    const updateApplicationSubPartner = (
        applicationId: string,
        subPartnerId: Application['subPartnerID']
    ) =>
        api.put(`/applications/${applicationId}/subpartner`, {
            subPartnerId
        });

    const createRegisteredAddress = (
        applicationId: number,
        applicantId: number,
        params: RegisteredAddress[]
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/addresses`,
            params
        );

    const removeRegisteredAddress = (
        applicationId: number,
        applicantId: number,
        addressId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/addresses/${addressId}`
        );

    const updateRegisteredAddress = (
        applicationId: number,
        applicantId: number,
        addressId: number,
        params: RegisteredAddress[]
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/addresses/${addressId}`,
            params
        );

    const updateTargetProperty = (
        applicationId: string,
        params: TargetProperty
    ) => api.put(`/applications/${applicationId}/property`, params);

    const getApplications = () => api.get<any[]>('/applications/');

    // maybe in the future we would want a more general query call
    const getAccountsByRealtorRole = (query: string) =>
        api.get<QueriedAccount[]>(`/accounts`, { query, role: 'realtor' });

    const getApplicationById = (id: string) => api.get(`/applications/${id}`);

    const getApplicationSummary = (id: string) =>
        api.get(`/applications/${id}/summary`);

    const getApplicationAdvisor = (id: string) =>
        api.get(`/applications/${id}/contact-information`);

    const updateApplicantOtherIncome = (
        applicationId: string,
        applicantId: string,
        params: any
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/income/others`,
            params
        );

    const createApplicantExtraIncome = (
        applicationId: string,
        applicantId: string,
        params: any
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/income/others`,
            params
        );

    const updateApplicantExtraIncome = (
        applicationId: string,
        applicantId: string,
        extraIncomeId: string,
        params: any
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/income/others/${extraIncomeId}`,
            params
        );

    const deleteApplicantExtraIncome = (
        applicationId: string,
        applicantId: string,
        extraIncomeId: string
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/income/others/${extraIncomeId}`
        );

    const createOtherProperty = (
        applicationId: string,
        params: OtherProperty,
        applicantId: string
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/properties`,
            params
        );

    const updateOtherProperties = (
        applicationId: string,
        otherPropertyId: string,
        applicantId: string,
        params: OtherProperty
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/properties/${otherPropertyId}`,
            params
        );

    const deleteOtherProperty = (
        applicationId: string,
        otherPropertyId: string,
        applicantId: string
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/properties/${otherPropertyId}`
        );

    const createAsset = (
        applicationId: string,
        applicantId: string,
        params: any
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/assets`,
            params
        );

    const updateAsset = (
        applicationId: string,
        applicantId: string,
        assetId: string | number,
        params: any
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/assets/${assetId}`,
            params
        );

    const deleteAsset = (
        applicationId: string,
        applicantId: string,
        assetId: string | number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/assets/${assetId}`
        );

    const updateEmployment = (
        applicationId: string,
        applicantId: string,
        employmentId: string,
        params: any
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/income/employments/${employmentId}`,
            params
        );
    const createEmployment = (
        applicationId: string,
        applicantId: string,
        params: any
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/income/employments`,
            params
        );

    const deleteEmployment = (
        applicationId: string,
        applicantId: string,
        employmentId: string
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/income/employments/${employmentId}`
        );

    const getDocuments = (
        applicationId: number,
        applicantId: number,
        step: number
    ) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents`,
            {
                step
            }
        );

    const getDocumentsCounts = (applicationId: number, step: number) =>
        api.get(`/applications/${applicationId}/documents/counts`, { step });

    const createDocument = (document: Document) =>
        api.post(
            `/applications/${document.applicationId}/applicants/${document.applicantId}/documents`,
            document
        );

    const acquireSignedUrl = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0
        }: DocumentCompositeKey,
        data: DocumentFileCreateData
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/upload`,
            data
        );

    const acquireDownloadUrl = (
        fileId: string,
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0
        }: DocumentCompositeKey
    ) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}/download`
        );

    const updateDocument = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0
        }: DocumentCompositeKey,
        data: Partial<Document>
    ) =>
        api.patch(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}`,
            data
        );

    const updateDocumentState = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0
        }: DocumentCompositeKey,
        data: { event: Pick<Document, 'state'> }
    ) =>
        api.patch(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/event`,
            data
        );

    const uploadDocument = (signerUrl: string, file: File) =>
        externalApi.axiosInstance.put(signerUrl, file, {
            headers: {
                // 'Content-Type': file.type // Backend is not creating the gcs object with this
                'Content-Type': ''
            }
        });

    const previewDocumentFile = (
        fileId: string,
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0
        }: DocumentCompositeKey
    ) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}/download`,
            {
                forceDownload: false,
                convert: true
            }
        );

    const acquireDocumentFilePDFUrl = (
        fileId: string,
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0
        }: DocumentCompositeKey
    ) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}/download`,
            {
                forceDownload: true,
                convert: true
            }
        );

    const previewDocument = ({
        applicationId,
        applicantId,
        documentType,
        year = 0,
        entityId = 0
    }: DocumentCompositeKey) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/download`,
            {
                forceDownload: false,
                convert: true
            }
        );

    const acquireDocumentPDFUrl = ({
        applicationId,
        applicantId,
        documentType,
        year = 0,
        entityId = 0
    }: DocumentCompositeKey) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/download`,
            {
                forceDownload: true,
                convert: true
            }
        );

    const addDocumentFile = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0
        }: DocumentCompositeKey,
        data: DocumentFileCreateData
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files`,
            data
        );

    const sendDocumentBoostEmail = (applicationId: any, applicantId: any) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/documents/reminders`
        );

    const getLastDocumentBoostEmail = (applicationId: any, applicantId: any) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents/reminders`
        );

    const getDocument = ({
        applicationId,
        applicantId,
        documentType,
        year = 0,
        entityId = 0
    }: DocumentCompositeKey) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}`
        );

    const deleteFile = (
        {
            applicationId,
            applicantId,
            documentType,
            year,
            entityId
        }: DocumentCompositeKey,
        fileId: string
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}`
        );

    const getDocumentTypes = (applicationId: number, applicantId: number) =>
        api.get(
            `/applications/${applicationId}/applicants/${applicantId}/documents/types`
        );

    const createDocumentMessage = (
        {
            applicationId,
            applicantId,
            documentType,
            year,
            entityId
        }: DocumentCompositeKey,
        data: { text: string }
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/messages`,
            data
        );

    const editDocumentMessage = (
        {
            applicationId,
            applicantId,
            documentType,
            year,
            entityId
        }: DocumentCompositeKey,
        messageId: number,
        data: { text: string }
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/messages/${messageId}`,
            data
        );

    const deleteDocumentMessage = (
        {
            applicationId,
            applicantId,
            documentType,
            year,
            entityId
        }: DocumentCompositeKey,
        messageId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/messages/${messageId}`
        );

    const createCoapplicant = (applicationId: number, coapplicant: any) =>
        api.post(`/applications/${applicationId}/applicants`, coapplicant);

    const deleteCoapplicant = (applicationId: number, applicantId: number) =>
        api.delete(`/applications/${applicationId}/applicants/${applicantId}`);

    const applicationSelectRate = (
        applicationId,
        params: {
            productId: number;
            amortization: number;
        }
    ) => api.put(`/applications/${applicationId}/products`, params);

    const uploadMLSTargetProperty = (formData: FormData) => {
        return api.post(`/centris/properties/ocr`, formData, {
            headers: {
                'Content-Type': 'application/pdf'
            }
        });
    };

    const productSelection = (
        applicationId: number,
        params?: { availability: true }
    ) => api.get(`/applications/${applicationId}/selection`, params);

    const productSelectionStep = (
        applicationId: number,
        stepId: Step,
        params: Payload
    ) =>
        api.put(
            `/applications/${applicationId}/selection/steps/${stepId}`,
            params
        );

    const productSelectionStepInfo = (applicationId: number, stepId: Step) =>
        api.get(`/applications/${applicationId}/selection/steps/${stepId}`);

    const getClosingDocumentsInfos = (applicationId: number) =>
        api.get(`/applications/${applicationId}/closing-documents/infos`);

    const getClosingDocumentsMultiple = (
        applicationId: number,
        search: string
    ) =>
        api.get(
            `/applications/${applicationId}/closing-documents/documents/multiple${search}`,
            {},
            {
                responseType: 'blob'
            }
        );

    const saveClosingDocumentsInfos = (
        applicationId: number,
        infos: Partial<ClosingDocuments>
    ) =>
        api.put(
            `/applications/${applicationId}/closing-documents/infos`,
            infos
        );

    const fetchGeolocationAll = () =>
        api.get<RegionResponse>('/geolocation/all');

    const getLeadReferral = () =>
        api.get('/applications/lead-referral/eligibility');

    const updateLeadReferral = (leadReferralState: boolean) =>
        api.post(
            `applications/lead-referral/${
                leadReferralState ? 'push' : 'nopush'
            }`
        );

    const axiosInstance = () => api.axiosInstance;

    const validatePhoneNumber = (phone_number: string) =>
        externalApi.get('https://apilayer.net/api/validate?country_code=', {
            access_key: process.env.REACT_APP_NUMVERIFY_PHONE_VALIDATION_ID,
            number: phone_number,
            format: 1
        });

    const addApplicantToSalesForceQueue = (
        applicationId: number,
        addToQueue: boolean
    ) =>
        api.put(`applications/${applicationId}/call-preferences`, {
            queueOutboundCallNow: addToQueue
        });

    const setComfortableSpeakingFrench = (
        applicationId: number,
        addToQueue: boolean
    ) =>
        api.put(`applications/${applicationId}/call-preferences`, {
            queueComfortableSpeakingFrench: addToQueue,
            queueOutboundCallNow: true
        });
    const applicationSubmittedByAgent = (
        applicationId: number,
        applicationSubmittedByAgent: boolean
    ) =>
        api.put(`applications/${applicationId}/call-preferences`, {
            applicationSubmittedByAgent
        });

    const getServicingAssets = () =>
        api.get<ServicingAsset[] | null>('/servicingportalgateway/assets');

    const setFirstCallBooked = (applicationId: number) =>
        api.put(`/applications/${applicationId}/first-call-booked`);

    const createWalnutInsuranceLead = (data: WalnutLead) =>
        api.post<string>(
            'https://api.canadalistings.net/walnut/index.php',
            data,
            {
                headers: {
                    AUTHORIZATION: `Bearer ${WALNUT_INSURANCE_API_KEY}`,
                    'x-login-on-behalf': undefined
                }
            }
        );

    return {
        getAccount,
        getAccountDeletionState,
        deleteAccount,
        getAccountTracking,
        addAccountTracking,
        addCallPreferences,
        setAuthToken,
        clearAuthToken,
        fetchQuotesMatrix,
        fetchMatchedPartnerQuote,
        createAccount,
        brokerLoginAccount,
        behalfLoginAccount,
        behalfLogoutAccount,
        getBrokerAccount,
        updateAccount,
        loginAccount,
        getCommunicationPreferences,
        updateCommunicationPreferences,
        resetPassword,
        changePassword,
        activateAccount,
        fetchDashboardState,
        axiosInstance,
        updateApplicantOtherIncome,
        createApplicantExtraIncome,
        updateApplicantExtraIncome,
        deleteApplicantExtraIncome,
        createOtherProperty,
        updateOtherProperties,
        deleteOtherProperty,
        createAsset,
        updateAsset,
        deleteAsset,
        updateEmployment,
        createEmployment,
        deleteEmployment,
        api,
        createApplication,
        getApplications,
        getApplicationById,
        getApplicationSummary,
        getApplicationAdvisor,
        updateApplicantInfo,
        setOtherIncomesSpecified,
        setPropertiesSpecified,
        createRegisteredAddress,
        removeRegisteredAddress,
        updateRegisteredAddress,
        updateTargetProperty,
        updateApplicationState,
        updateApplicationSubPartner,
        acquireDownloadUrl,
        acquireSignedUrl,
        addDocumentFile,
        createDocument,
        deleteFile,
        getDocument,
        getDocumentTypes,
        getDocuments,
        getDocumentsCounts,
        updateDocument,
        sendDocumentBoostEmail,
        getLastDocumentBoostEmail,
        updateDocumentState,
        uploadDocument,
        previewDocumentFile,
        previewDocument,
        acquireDocumentFilePDFUrl,
        acquireDocumentPDFUrl,
        createDocumentMessage,
        editDocumentMessage,
        deleteDocumentMessage,
        createCoapplicant,
        deleteCoapplicant,
        applicationSelectRate,
        productSelection,
        productSelectionStep,
        productSelectionStepInfo,
        getClosingDocumentsInfos,
        getClosingDocumentsMultiple,
        saveClosingDocumentsInfos,
        fetchGeolocationAll,
        uploadMLSTargetProperty,
        getLeadReferral,
        updateLeadReferral,
        getAccountsByRealtorRole,
        validatePhoneNumber,
        addApplicantToSalesForceQueue,
        addApplicantToFrenchQueue: setComfortableSpeakingFrench,
        applicationSubmittedByAgent,
        getServicingAssets,
        setFirstCallBooked,
        createWalnutInsuranceLead
    };
};

const Client = (baseURL: string) => create(baseURL);
export { Client };
